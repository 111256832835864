import React from 'react';
import { Box } from 'pyatka-ui';
import { ProductCardLayoutType } from 'components/ProductCard/types';

// Листинг, Избранное: карточки товаров

interface ProductsGridProps {
    children: React.ReactNode;
    layout?: ProductCardLayoutType;
    xl?: number;
}

export const ProductsGrid: React.FC<ProductsGridProps> = ({ children, layout = 'old', xl = 5 }) => {
    const gap = React.useMemo(() => {
        if (layout === 'new') {
            return {
                gridRowGap: '40px',
                gridColumnGap: '18px',
            };
        }
        return {
            gridGap: {
                default: '8px',
                sm: '12px',
                lg: '16px',
                xl: '20px',
            },
        };
    }, [layout]);

    const defaultCount = layout === 'small' ? 3 : 2;

    return (
        <Box
            width="100%"
            display="grid"
            gridAutoRows="min-content"
            gridTemplateColumns={{
                default: `repeat(${defaultCount}, minmax(0, 1fr))`,
                sm: 'repeat(3, minmax(0, 1fr))',
                lg: 'repeat(4, minmax(0, 1fr))',
                xl: `repeat(${xl}, minmax(0, 1fr))`,
            }}
            {...gap}
        >
            {children}
        </Box>
    );
};
