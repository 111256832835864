export interface AppCartServiceInstance {
    // cart data getters
    cart: any;
    cart_loadings: AppCartLoadingState;
    cartEdit: any;
    cartItemsByProduct: (id: AppCartItemState['product_id']) => AppCartItemState[];
    cartItemByOffer: (id: AppCartItemState['offer_id']) => AppCartItemState | undefined;
    cartSnapshotEdit: (data: AppCartItemState[]) => void;
    cartCheckoutSync: (hash: string) => void;
    cartCounter: number;
    flushAppCart: any;
    deleteCartGroupByHash: any;
}

const statuses = ['initial', 'synced', 'updating', 'restore', 'presync'] as const;
type StatusNames = typeof statuses;
type Status = StatusNames[number];
type AppCartStatuses = {
    [key in Status]: Status;
};

export const CART_STATUSES: AppCartStatuses = statuses.reduce(
    (prev, now) => ({ ...prev, [now]: now }),
    {}
) as AppCartStatuses;

export interface AppCartState {
    status: Status;
    data: AppCartStateData;
}

export interface AppCartStateMain extends AppCartState {
    mutationIds: AppCartItemState['offer_id'][];
    preselected_cart_hash: string;
}

export interface AppCartStateData {
    [key: string]: AppCartItemState;
}

export interface AppCartItemState {
    product_id: string | number;
    offer_id: string | number;
    amount: number;
}

export interface AppCartSyncConfig {
    cart_snapshot?: AppCartStateData;
    cart_hash: AppCartStateMain['preselected_cart_hash'];
    method: keyof AppCartServices;
    id_param?: string | number;
    loading_config: AppCartLoadingState;
    payload_data: AppCartPayloadState[];
}

export interface AppCartLoadingState {
    mutation_loading: AppCartItemState['offer_id'][];
    initial_loading: boolean;
}

export interface AppCartPayloadState {
    product_id: AppCartItemState['offer_id'];
    amount: AppCartItemState['amount'];
}

export interface AppCartServices {
    [key: string]: any;
}

export interface AppCartSyncLoadingConfig {
    status: boolean;
    id?: string | number;
}
