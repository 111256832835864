import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Modal } from 'components';
import { IRenderModal, ModalInstance } from 'components/Dialog/types';
import { IOpenDialog } from 'plugins/dialog';
import { setDialogIsOpen } from 'modules/App/App.actions';
import { useAppRouter } from 'router/context';
import { handleSwipe } from 'utils/swipeControl';
import {
    optionComponentResolver,
    optionConfigResolver,
    optionKeyResolver,
    optionPropsResolver,
    optionTitleResolver,
} from './helpers';
// import './style.scss';

const renderModal = ({
    isShow,
    data,
    onClose,
    // activeModal,
    handleChangeTitle: changeTitle,
}: IRenderModal) => {
    const Body = data?.component as React.FC<any>;
    const ElementBody = data?.element ? data?.element : null;
    if (data.isOwnModal) {
        const ownBodyProps = {
            ...data.props,
            open: isShow,
        };
        return (
            <>
                <Body {...ownBodyProps} />
            </>
        );
    }

    return (
        <Modal
            key={data?.key || 0}
            open={isShow}
            onClose={onClose}
            title={data.title}
            swipeable={data?.swipeable}
            // hideModal={activeModal !== data.key}
            bgColor={data?.props?.bgColor || 'block.main'}
            config={data?.config}
            backButtonInitial={data?.props?.backButtonInitial || 0}
        >
            {ElementBody}
            {Body && <Body {...data.props} changeTitle={changeTitle} />}
        </Modal>
    );
};

export const Dialog: React.FC = () => {
    const [isShow, setIsShow] = React.useState(false);
    const [modal, setModal] = React.useState<ModalInstance | null>(null);
    const dispatch = useDispatch();
    const { location } = useAppRouter();

    const open = async (data: IOpenDialog) => {
        const { isOwnModal = false } = data;
        let option: ModalInstance = {};
        option = optionTitleResolver(option, data.title);
        option = optionKeyResolver(option, data.key);
        option = optionConfigResolver(option, data.config);
        // option = optionActionsResolver(option, data.actions);
        option = optionPropsResolver(option, data.props);
        option = await optionComponentResolver(option, data.body, data.element);
        option.isOwnModal = isOwnModal;
        option.swipeable = data?.swipeable;
        setModal({ ...option });
        setIsShow(true);
        dispatch(setDialogIsOpen(true));
    };

    const onClose = () => {
        setIsShow(false);
        dispatch(setDialogIsOpen(false));
    };

    React.useEffect(() => {
        if (isShow) {
            onClose();
        }
    }, [location.pathname]);

    useEffect(() => {
        if (modal) {
            handleSwipe(location?.pathname, !isShow);
        }
    }, [isShow]);

    useEffect(() => {
        if (__BROWSER__ && window) {
            document.addEventListener(
                'open-Dialog',
                (e: any) => {
                    open(e.detail);
                },
                false
            );
            document.addEventListener('close-Dialog', onClose, false);
        }

        return () => {
            document.removeEventListener(
                'open-Dialog',
                (e: any) => {
                    open(e.detail);
                },
                false
            );
            document.removeEventListener('close-Dialog', onClose, false);
        };
    }, []);

    const handleChangeTitle = (title: string) => {
        setModal((prev) => ({ ...prev, title }));
    };

    return (
        // isShow && (
        <React.Fragment>
            {modal &&
                renderModal({
                    isShow,
                    data: modal,
                    onClose,
                    handleChangeTitle,
                })}
        </React.Fragment>
        // )
    );
};

// {data.actions && (
//     <div>
//         <div className="actionBtns">
//             {data.actions?.map((action: any, idx: number) => (
//                 <Button
//                     key={idx}
//                     onClick={() => {
//                         action.onClick({});
//                         // setIsShow(false)
//                     }}
//                     className={action.className}
//                     width="90px"
//                     borderRadius={8}
//                 >
//                     {action.title}
//                 </Button>
//             ))}
//         </div>
//     </div>
// )}
