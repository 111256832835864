import React from 'react';
import { Box, usePyatka } from 'pyatka-ui';
import QuickPinchZoom, { make3dTransformValue } from 'react-quick-pinch-zoom';
import { ProductCardLayoutProps } from '../types';

interface ZoomImgProps {
    layout?: ProductCardLayoutProps['layout'];
    children: React.ReactNode;
}

const ZoomImg: React.FC<ZoomImgProps> = ({ children, layout = 'old' }) => {
    const { scales } = usePyatka();
    const [isZooming, setIsZooming] = React.useState(false);
    const imgRef = React.useRef<HTMLDivElement>(null);

    const onUpdate = React.useCallback(({ x, y, scale }: any) => {
        const { current } = imgRef;

        if (current) {
            const value = make3dTransformValue({ x, y, scale });

            current?.style.setProperty('transform', value);
        }
    }, []);

    if (layout === 'new' || !scales?.isMobile) {
        return children;
    }

    return (
        <QuickPinchZoom
            containerProps={{
                style: {
                    touchAction: 'auto',
                    overflow: 'visible',
                    position: isZooming ? 'relative' : 'initial',
                    zIndex: isZooming ? 999 : 'initial',
                },
            }}
            onUpdate={onUpdate}
            onZoomStart={() => setIsZooming(true)}
            onZoomEnd={() => setIsZooming(false)}
            onDragEnd={() => setIsZooming(false)}
            tapZoomFactor={0}
            zoomOutFactor={99}
            draggableUnZoomed={false}
            shouldInterceptWheel={() => false}
        >
            <Box ref={imgRef}>{children}</Box>
        </QuickPinchZoom>
    );
};

export default ZoomImg;
