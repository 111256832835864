import * as React from 'react';
import { useWindowVirtualizer } from '@tanstack/react-virtual';
import { FlexBox, usePyatka } from 'pyatka-ui';
import styled from 'styled-components';
import { ProductCard } from 'components';
import { Product } from 'modules/ProductsList/types';
import { ProductCardLayoutType } from 'components/ProductCard/types';
import { IProductCardMetric } from 'AppMetric/types';
import { nativeConfigs } from 'utils/platform';
import { useResponsiveVirtualGrid } from './useResponsiveVirtualGrid';
import ListingSuggestions from './ListingSuggestions';

type Props = {
    products: Product[];
    layout?: ProductCardLayoutType;
    desktopLayout?: ProductCardLayoutType;
    metric?: IProductCardMetric;
    xl?: number;
    source: string;
};

const VirtualGrid: React.FC<Props> = ({
    metric,
    products = [],
    layout = 'old',
    desktopLayout = 'old',
    xl = 5,
    source,
}) => {
    const { scales } = usePyatka();
    const { count, gap } = useResponsiveVirtualGrid({
        default: { count: layout === 'small' ? 3 : 2, gap: 18 },
        sm: { count: 3, gap: 18 },
        lg: { count: 4, gap: 18 },
        xl: { count: xl, gap: 18 },
    });

    const renderOption = React.useCallback(
        (index: number) => {
            const product: Product = products[`${index}`];
            return (
                <ProductCard
                    key={product?.product_id}
                    metric={metric}
                    {...product}
                    offer_id={product?.offer_id ? product?.offer_id : product?.product_id}
                    lineClamp={2}
                    appearance="grid"
                    layout={layout}
                    desktopLayout={desktopLayout}
                    effect="fadeIn"
                    product_position={index}
                    index={index}
                    source={source}
                />
            );
        },
        [desktopLayout, layout, metric, products, source]
    );

    const matrix = Array(products?.length)
        .fill(1)
        .reduce<number[][]>((acc, _curr, i) => {
            if (i % count === 0) {
                acc.push([i + 1]);
            } else {
                acc[acc.length - 1].push(i + 1);
            }
            return acc;
        }, []);

    const colsArray = new Array(count).fill(0);

    const virtualizer = useWindowVirtualizer({
        count: matrix.length,
        estimateSize: () => 306,
        overscan: 10,
    });

    const items = virtualizer.getVirtualItems();

    const calcGap = React.useMemo(() => {
        if (!scales?.isMobile) {
            if (desktopLayout === 'new') {
                return {
                    x: 30,
                    y: 70,
                };
            }
        }

        if (layout === 'small') {
            return {
                x: 8,
                y: 8,
            };
        }

        if (layout === 'new') {
            return {
                x: 18,
                y: 40,
            };
        }
        return {
            x: gap,
            y: gap,
        };
    }, [scales?.isMobile, gap, layout, desktopLayout]);

    const config = nativeConfigs();
    const showAfterRow = config?.appsflyer_segment === 'B' ? 9 : 6;

    return (
        <div style={{ width: '100%' }}>
            <div
                style={{
                    height: virtualizer.getTotalSize(),
                    width: '100%',
                    position: 'relative',
                }}
            >
                <FlexBox
                    flexDirection="column"
                    // gap="36px 0"
                    position="absolute"
                    top={0}
                    left={0}
                    width="100%"
                    style={{
                        transform: `translateY(${
                            (items[0]?.start || 0) - virtualizer.options.scrollMargin
                        }px)`,
                    }}
                >
                    {items.map((virtualRow) => (
                        <div
                            key={virtualRow.key}
                            data-index={virtualRow.index}
                            ref={virtualizer.measureElement}
                        >
                            {virtualRow.index === showAfterRow && <ListingSuggestions />}

                            <Grid isMobile={scales?.isMobile} count={count} gap={calcGap}>
                                {colsArray.map((_, i) => {
                                    const index = matrix[virtualRow.index][`${i}`];
                                    return (
                                        <React.Fragment key={index || `${virtualRow.key}-i`}>
                                            {index ? (
                                                <>{renderOption(index - 1)}</>
                                            ) : (
                                                <div style={{ width: '100%' }} />
                                            )}
                                        </React.Fragment>
                                    );
                                })}
                            </Grid>
                        </div>
                    ))}
                </FlexBox>
            </div>
        </div>
    );
};

const Grid = styled.div<any>`
    width: 100%;
    display: grid;
    grid-auto-rows: min-content;
    grid-template-columns: ${(props) => `repeat(${props.count || 2}, minmax(0, 1fr))`};
    grid-column-gap: ${(props) => `${props.gap.x}px`};
    padding-bottom: ${(props) => `${props.gap.y}px`};
`;

export default React.memo(VirtualGrid);
