import { createSelector } from 'reselect';
import dayjs from 'dayjs';
import { AppRootState } from 'store/types';
import initialState from 'modules/App/App.state';
import { isUserAuth } from 'modules/Authorization/Authorization.selectors';
import { AppErrorInfo, TabLink } from 'modules/App/types';

const getAppReducer = (state: AppRootState) => state.AppReducer || initialState;

const getAppState = createSelector([getAppReducer], (state) => state);

const getAppHeadersData = createSelector([getAppState], (state) => state.header);

export const getAppErrorPageInfo = createSelector([getAppState], (payload): null | AppErrorInfo => {
    const { appError } = payload;
    return appError;
    // appError?.status === 0 ? null : appError;
});

export const getAppTabLinks = createSelector(
    [getAppHeadersData, isUserAuth],
    (header, isAuth): TabLink[] => {
        const { tabLinks } = header;
        return (
            tabLinks.filter((tab: any) => {
                const { forUser } = tab;
                switch (forUser) {
                    case 'all':
                        return tab;
                    case 'guest':
                        if (!isAuth) {
                            return tab;
                        }
                        break;
                    case 'auth':
                        if (isAuth) {
                            return tab;
                        }
                        break;
                }
            }) || []
        );
    }
);

export const getTabLinks = (
    appearance: TabLink['appearance'] = 'all',
    state: AppRootState
): TabLink[] => {
    const tabLinks = getAppTabLinks(state);
    return (
        tabLinks.filter((tab) => {
            return tab.appearance === appearance || tab.appearance === 'all';
        }) || []
    );
    // .map((tab) => {
    //     if (tab.slug === 'cart') {
    //         const labelCount = cartCounter;
    //         return { ...tab, labelCount };
    //     } else if (tab.slug === 'wishlist') {
    //         const labelCount = getWishListItemsNumber(state);
    //         return { ...tab, labelCount };
    //     }

    // switch (tab.slug) {
    //     case 'wishlist':
    //         tab = { ...tab, labelCount: getWishListItemsNumber(state) };
    //         break;
    //     case 'cart':
    //         tab = { ...tab, labelCount: getWishListItemsNumber(state) };
    //         break;
    // }
    //     return tab;
    // });
};

export const getAuthModalStatus = createSelector([getAppState], (state) => state.authModal);

export const getAgeVerificationModalStatus = createSelector(
    [getAppState],
    (state) => state.ageVerificationModal
);

export const getAgeVerification = createSelector([getAppState], (state) => state.ageVerification);

export const getCategoryList = createSelector([getAppState], (state) => state.categoryVerification);

export const getLoading = createSelector([getAppReducer], (state) => state.loading);

export const getCategoryVerification = (id: string, state: AppRootState) => {
    const list = getCategoryList(state);
    if (id in list) {
        // eslint-disable-next-line security/detect-object-injection
        return list[id];
    }
    return false;
};

const getI18nLanguage = createSelector([getAppState], (state) => state.i18n.activeLanguage);
const getI18nFallback = createSelector([getAppState], (state) => state.i18n.fallback);
const getI18nTranslations = createSelector([getAppState], (state) => state.i18n.translations);

export const getI18nState = createSelector(
    [getI18nLanguage, getI18nFallback, getI18nTranslations],
    (activeLanguage, fallback, translations) => ({
        activeLanguage,
        fallback,
        translations,
    })
);

export const getIsDialogOpen = createSelector([getAppState], (state) => state.isDialogOpen);

export const getisClosingJmart = createSelector([getAppState], (state) => state.isClosingJmart);

export const getIsAgeModal = createSelector([getAppState], (state) => state.isAgeModal);

export const getVerifyAge = createSelector(
    [getAppState, (_, config): boolean[] => config],
    (state, config) => {
        const { age, date } = state.verifyAge;
        const [is_for_adult, is_degree_high, is_degree_low] = config;

        const isBlurred = is_degree_high || is_degree_low || is_for_adult;
        const isAfter = dayjs().isAfter(date, 'day');

        if (isBlurred) {
            if (age === 0) {
                return true;
            }

            return isAfter;
        }

        return false;
    }
);

// // selector.js
// const selectItemsByCategory = createSelector(
//     [
//       // Usual first input - extract value from `state`
//       state => state.items,
//       // Take the second arg, `category`, and forward to the output selector
//       (state, category) => category
//     ],
//     // Output selector gets (`items, category)` as args
//     (items, category) => items.filter(item => item.category === category)
//   );

//   // App.js
//   const items = selectItemsByCategory(state, 'javascript');
//   // Another way if you're using redux hook:
//   const items = useSelector(state => selectItemsByCategory(state, 'javascript'));
