import React from 'react';
import { Checkbox, FlexBox, Text } from 'pyatka-ui';
import styled from 'styled-components';

interface FilterCheckboxVariantProps {
    inStock: boolean;
    isChecked: boolean;
    option: any;
    onCheckboxClick: (variant: any, isActive?: boolean) => void;
}

const ICON_SIZE = '32px';

const FilterCheckboxVariant: React.FC<FilterCheckboxVariantProps> = ({
    option,
    isChecked = false,
    inStock = false,
    onCheckboxClick,
}) => {
    const textColors = {
        variant: inStock ? 'text.main' : 'text.disabled',
        count: inStock ? 'text.secondary' : 'text.disabled',
    };

    return (
        <StyledCheckbox
            css=""
            enterKeyHint
            width={ICON_SIZE}
            height={ICON_SIZE}
            checked={isChecked}
            borderColor="block.disabled"
            borderRadius="999rem"
            onChange={() => inStock && onCheckboxClick(option, isChecked)}
        >
            <FlexBox gap="6px" alignItems="center">
                <Text
                    fontSize="16px"
                    fontWeight={500}
                    lineHeight="20.48px"
                    color={textColors.variant}
                >
                    {option.variant}
                </Text>
                <Text fontSize="14px" fontWeight={500} lineHeight="18.2px" color={textColors.count}>
                    ({option.count})
                </Text>
            </FlexBox>
        </StyledCheckbox>
    );
};

const StyledCheckbox = styled(Checkbox)`
    margin-bottom: 0px;
    gap: 4px;
    & > div:first-of-type {
        & svg {
            width: ${ICON_SIZE};
            height: ${ICON_SIZE};
        }
    }
`;

export default React.memo(FilterCheckboxVariant);
