import React from 'react';
import { Button, Icon, Text } from 'pyatka-ui';
import { nativeCall } from 'hybrid/index';
import { useI18n } from 'hooks/useI18n';
import { WhatsAppButtonLayoutProps } from './types';
// import styles from './WhatsAppButton.module.scss';

export const WhatsAppButtonLayout: React.FC<WhatsAppButtonLayoutProps> = () => {
    const res = () => {
        nativeCall({
            type: 'whatsapp',
            payload: {},
        });
    };
    const { $t } = useI18n();

    return (
        <React.Fragment>
            <Button onClick={res} borderColor={'#ccc'} width={'100%'} borderRadius={'10px'}>
                <Icon icon={'whatsapp-icon'} />
                <Text
                    color={'text.secondary'}
                    pl={'10px'}
                    fontWeight={'500'}
                    style={{ textDecoration: 'underline' }}
                >
                    {$t('report_problem')}
                </Text>
            </Button>
        </React.Fragment>
    );
};
