import EventLib from 'events';
import React from 'react';

const NodeEvents = __SERVER__ ? EventLib : undefined;
const em = NodeEvents && new NodeEvents.EventEmitter();
const OPEN_TOOLTIP = 'open-Tooltip';
const CLOSE_TOOLTIP = 'close-Tooltip';

export interface IOpenTooltip {
    container_id?: string;
    props?: {
        [key: string]: any;
    };
    body?: string | React.FC | React.ReactNode | JSX.Element;
}

const openTooltip = (data: IOpenTooltip) => {
    if (__BROWSER__) {
        const open = new CustomEvent(OPEN_TOOLTIP, {
            detail: data,
        });
        document.dispatchEvent(open);
        return;
    }
    if (__SERVER__) {
        em && em.emit(OPEN_TOOLTIP, data);
    }
    return;
};

const closeTooltip = () => {
    if (__BROWSER__) {
        const close = new CustomEvent(CLOSE_TOOLTIP);
        document.dispatchEvent(close);
        return;
    }
};

export const AppTooltip = {
    openTooltip,
    closeTooltip,
};
