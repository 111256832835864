import React from 'react';
import { Box, FlexBox, Icon, Space, Text } from 'pyatka-ui';
import priceFormatter from 'utils/priceFormatter';
import { signs } from 'utils/symbols';
import OrderCardHeader from 'components/ClientOrderCard/components/OrderCardHeader';
import ArrowIcon from 'components/ClientOrderCard/components/ArrowIcon';
import OrderStatus from 'components/ClientOrderCard/components/OrderStatus';
import { ClientOrderCardLayoutProps } from './types';

export const ClientOrderCardQrLiteLayout: React.FC<ClientOrderCardLayoutProps> = (props) => {
    const { orderId, timestamp, orderStatusText, hasArrow, total } = props;

    return (
        <React.Fragment>
            <OrderCardHeader orderId={orderId} timestamp={timestamp} />
            <Space height={'12px'} />
            <FlexBox justifyContent={'space-between'}>
                {/* TODO: Type */}
                <FlexBox gap={'5px'}>
                    <Box minWidth={'46px'} height={'46px'} borderRadius={15} marginRight={'5px'}>
                        <Icon icon={'qr_lite'} />
                    </Box>
                </FlexBox>

                <Box
                    className={'d-flex justify-content-center flex-column pl-0 ml-2'}
                    style={{ flexGrow: '1' }}
                >
                    <Text
                        color={'#AAADB2'}
                        fontSize={{
                            default: '11px',
                            sm: '11px',
                        }}
                        lineHeight={'14px'}
                    >
                        Покупка через QR Lite
                    </Text>
                    <Space height={'2px'} />
                    <Text
                        fontWeight={'bold'}
                        fontSize={{
                            default: '15px',
                            sm: '15px',
                        }}
                    >
                        {priceFormatter(total)} {signs.tg}
                    </Text>
                </Box>
                {hasArrow && <ArrowIcon orderId={orderId} />}
            </FlexBox>

            {/* ------------------------------------------------------------------------------------- */}

            {/* ------------------------------------------------------------------------------------Statuses */}
            {orderStatusText && <OrderStatus orderStatusText={orderStatusText} />}
        </React.Fragment>
    );
};
