import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'hooks';
import { FormDataFields } from 'hooks/useForm/types';
import { useLoader } from 'contexts/useLoader';
import { Loader } from 'plugins/loader';
import { AppToaster } from 'plugins/app-toaster';
import { AppMetricEvents } from 'AppMetric';
import { isNative } from 'utils/platform';
import { AuthorizationModalLayout } from './AuthorizationModalLayout';
import { AuthorizationModalContainerProps, OtpStatus } from './types';

const formData: FormDataFields = {
    phone: '',
    otp: '',
};
const AuthorizationModalContainer: React.FC<AuthorizationModalContainerProps> & {
    Layout: typeof AuthorizationModalLayout;
} = (props) => {
    const {
        open,
        onClose,
        isLoggedIn,
        AuthorizationInit,
        AuthorizationVerifyOtp,
        OtpClear,
        otp,
        onAuthDone,
        source,
    } = props;
    const [otpStatus, setOtpStatus] = useState<OtpStatus>({
        expire: 0,
        phone: '',
        status: 'not-started',
    });
    const [expireTime, setExpireTime] = useState<string | null>(null);
    const [disableResendBtn, setDisableResendBtn] = useState(false);
    const [otpError, setOtpError] = useState('');
    const [phone, setPhone] = useState('');
    const timerIdRef = useRef<string | number | NodeJS.Timeout | undefined>();
    const [showBaseForm, setShowBaseForm] = useState(false);
    const { isLoading } = useLoader();
    const isLoadingAuth = isLoading(Loader.authBasic.indicator);
    useEffect(() => {
        if (open && !isNative()) {
            AppMetricEvents.jmart_web_authorization({ source: source || '' });
        }
    }, [open, source]);
    useEffect(() => {
        setOtpStatus(otp);
    }, [otp]);
    useEffect(() => {
        return () => {
            OtpClear();
        };
    }, [OtpClear]);

    useEffect(() => {
        let timerId: string | number | NodeJS.Timeout | undefined;
        let start = otpStatus?.expire || 120;
        setExpireTime(new Date(start * 1000).toISOString().substr(14, 5));
        if (otpStatus?.status === 'send') {
            setDisableResendBtn(true);
            const expTime = Date.now() + start * 1000;
            timerIdRef.current = setInterval(() => {
                start = start - 1;
                if (start >= 0) {
                    setExpireTime(new Date(start * 1000).toISOString().substr(14, 5));
                }
                if (expTime < Date.now()) {
                    setDisableResendBtn(false);
                    clearInterval(timerId);
                }
            }, 1000);
        }
        return () => {
            setExpireTime(null);
            clearInterval(timerIdRef.current);
        };
    }, [otpStatus]);

    const showPinSection = useMemo(() => otpStatus?.status === 'send', [otpStatus?.status]);
    const handleSubmit = (e?: any) => {
        if (e) {
            e.preventDefault();
        }
        // AuthorizationOtpInit({ mobile_phone: form.phone });
        AuthorizationInit({ type: 'otp', params: { mobile_phone: phone } });
        formChangeHandler({ name: 'phone', value: '' });
    };

    const { form, formChangeHandler, errors } = useForm(formData, {
        validators: {
            phone: {
                required: true,
                rules: ['onPhone'],
            },
            otp: {
                required: true,
                rules: ['onOtp'],
            },
        },
        submit: handleSubmit,
    });
    const onBaseFormSubmit = useCallback(
        (form: any) => {
            AuthorizationInit({ type: 'email', params: form });
            // AuthorizationBasicInit({ body: form });
        },
        [AuthorizationInit]
    );
    const onUserOtpSubmit = (e: any) => {
        e.preventDefault();
        const otpVal = e.target[0].value || '';
        if (otpVal.length === 4) {
            AuthorizationVerifyOtp({
                otp: otpVal,
                mobile_phone: phone,
            });
            // AuthorizationViaOtpInit({
            //     otp: otpVal,
            //     mobile_phone: otp.phone,
            // });
            AppMetricEvents.jmart_web_get_sms({ phone });
        }
    };
    const otpChangeHandler = (e: any) => {
        if (e.value.length <= 4 && /^\d*$/.test(e.value)) {
            setOtpError('');
            formChangeHandler(e);
        }
    };
    const phoneChangeHandler = (e: any) => {
        // if (e.value.length === 11) {
        setOtpError('');
        setPhone(e.value);
        formChangeHandler(e);
        // }
    };
    const handleResendSMS = () => {
        if (!disableResendBtn) {
            formChangeHandler({ name: 'otp', value: '' });
            handleSubmit();
        } else {
            AppToaster.info({ body: 'wait_until_time_runs_out' });
        }
    };

    const handleClose = useCallback(() => {
        OtpClear();
        clearInterval(timerIdRef.current);
        formChangeHandler({ name: 'phone', value: '' });
        formChangeHandler({ name: 'otp', value: '' });

        onClose();
    }, [OtpClear, formChangeHandler, onClose]);
    useEffect(() => {
        if (otpStatus?.status === 'send' && isLoggedIn) {
            handleClose();
            onAuthDone && onAuthDone();
        }
    }, [isLoggedIn, otpStatus?.status]);
    const layoutProps = {
        ...props,
        open,
        onClose: handleClose,
        form,
        formChangeHandler,
        formSubmitHandler: handleSubmit,
        errors,
        isLoadingAuth,
        otpStatus,
        onUserOtpSubmit,
        otpChangeHandler,
        phoneChangeHandler,
        handleResendSMS,
        showPinSection,
        otpError,
        expireTime,
        disableResendBtn,
        showBaseForm,
        setShowBaseForm,
        onBaseFormSubmit,
    };
    return <AuthorizationModalLayout {...layoutProps} />;
};

AuthorizationModalContainer.Layout = AuthorizationModalLayout;
export default React.memo(AuthorizationModalContainer);
