import React from 'react';
import { useSelector } from 'react-redux';
import { Dialog } from 'plugins/dialog';
import { getAgeVerification } from 'modules/App/App.selectors';
import { isUserVerified, saveUserAgeVerified } from 'data';
import { nativeAlert } from 'hybrid/index';
import { useI18n } from 'hooks/useI18n';

export default function MainAppAgeVerify() {
    const ageVerification = useSelector(getAgeVerification);
    const isVerified = isUserVerified();
    const { $t } = useI18n();
    const title =
        $t('section_is_intended_for_persons_who_have_reached') +
        ` ${ageVerification?.age_limit} ` +
        $t('section_is_intended_for_persons_who_have_reached_2');
    React.useEffect(() => {
        if (ageVerification && ageVerification?.age_verification === 'Y' && isVerified === null) {
            if (window.__HYBRIDCONFIG__.platform === 'REACT') {
                setTimeout(() => {
                    Dialog.openDialog({
                        key: 'AgeVerificationModal',
                        title,
                        body: 'AgeVerificationModal',
                        props: {
                            ...ageVerification,
                        },
                    });
                }, 0);
            } else {
                const res: any = nativeAlert({
                    closable: false,
                    title,
                    message: title,
                    actions: [
                        {
                            title: `${$t('im_years')} ${ageVerification?.age_limit} ${$t(
                                'est_god'
                            )}`,
                            value: 'yes',
                        },
                        {
                            title: `${$t('im_not_years')} ${ageVerification?.age_limit} ${$t(
                                'net_goda'
                            )}`,
                            value: 'no',
                        },
                    ],
                });
                saveUserAgeVerified(res?.response?.action_result);
            }
        }
    }, [ageVerification, isVerified, title]);

    return null;
}
