import React from 'react';
import { useAppRouter } from 'router/context';
import { AppLinkProps } from 'components/AppLink/types';
import { AppLinkLayout } from './AppLink.Layout';

export const AppLinkContainer: React.FC<AppLinkProps> = ({
    to,
    children,
    style,
    itemProp,
    ariaLabel,
    eventType = 'onClick',
    isExternal = false,
    preventOnClick = false,
    onClickProduct,
    onClick,
    route_state,
    ...rest
}) => {
    const { generateRouteTO, history, appRedirect } = useAppRouter();

    const generateLink = React.useCallback(() => {
        try {
            if (typeof to === 'string') {
                return to;
            }
            if (typeof to === 'object') {
                return generateRouteTO(to);
            }
        } catch (e) {
            console.log(e);
            return '/';
        }
    }, [generateRouteTO, to]);

    const handleClick = (e: any) => {
        console.log('route_state', rest);

        // For Banner Click datalayer
        if (onClick) {
            onClick(e);
        }
        e.preventDefault();
        onClickProduct && onClickProduct(e);

        if (preventOnClick) {
            return;
        }

        const destinationLink = generateLink();
        const link = `${history.location.pathname}${history.location.search}`;

        // Prevent default if on the same page
        if (link !== destinationLink) {
            appRedirect(to, route_state);
        }
    };

    return (
        <AppLinkLayout
            {...rest}
            style={style}
            itemProp={itemProp}
            ariaLabel={ariaLabel}
            eventType={eventType}
            link={generateLink()}
            onClick={handleClick}
            isExternal={isExternal}
        >
            {children}
        </AppLinkLayout>
    );
};
