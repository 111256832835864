import { all, call, put, takeLatest, select } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import {
    setI18nActiveLanguage,
    setI18nTranslationsSucceed,
    AppInit,
    setVerifyAge,
} from 'modules/App/App.actions';
import { fetchI18nTranslations } from 'modules/App/App.services';
import { SetApiInstancesHeader } from 'services';
import { fetchCities, setSelectedCity } from 'modules/Cities/Cities.actions';
import { getI18nState } from 'modules/App/App.selectors';
import { AuthorizationDataSave } from 'modules/Authorization/v2/Authorization.actions';
import { getAuthData } from 'modules/Authorization/v2/utils';
import { getAgeVerify } from 'data';
import { IAppInitPayload } from './types';

function* handlerAppInit({ payload }: PayloadAction<IAppInitPayload>) {
    const { hybridConfig, preselected_city } = payload;
    SetApiInstancesHeader('Appsflyer-segment', hybridConfig?.appsflyer_segment || 'A');
    // @ts-ignore
    yield put(setSelectedCity({ id: preselected_city }));
    //
    if (__BROWSER__) {
        const ageLocal = getAgeVerify();

        yield put(setVerifyAge(ageLocal));

        const lang = hybridConfig?.lang === 'kz' ? 'kk' : hybridConfig?.lang || 'ru';
        SetApiInstancesHeader('Accept-language', lang);
        if (hybridConfig?.appsflyer_id) {
            SetApiInstancesHeader('Appsflyer-id', hybridConfig?.appsflyer_id);
        }
        yield call(saveAuthorizationDataSaga, {
            token: !hybridConfig.is_webview ? getAuthData()?.token : hybridConfig.token,
            fetchData: true,
        });
        return;
    }
    // Server

    if (__SERVER__) {
        yield call(saveAuthorizationDataSaga, { token: hybridConfig.token, fetchData: false });
        yield put(
            setI18nActiveLanguage({
                language: hybridConfig?.lang as any,
            })
        );
        yield put(fetchCities());
        return;
    }
}

function* saveAuthorizationDataSaga(_action: { token: string | null; fetchData: boolean }) {
    const { token, fetchData } = _action;
    console.log(token);
    if (token) {
        yield put(
            AuthorizationDataSave({
                token,
                fetchData,
                ttl: 587255,
                errors: {},
            })
        );
    }
}

function* handlerSetI18n(_action: any) {
    const { translations = {} } = yield select(getI18nState as any);
    try {
        const {
            payload: { language },
        } = _action;
        if (language) {
            const lang = language === 'kz' ? 'kk' : language || 'ru';
            SetApiInstancesHeader('Accept-language', lang);
            if (translations[`${language}`]) {
                return;
            }

            const { data } = yield call(fetchI18nTranslations);
            yield put(setI18nTranslationsSucceed({ data }));
        }
        return;
    } catch (e) {
        console.log('handlerSetI18n > error', e);
    }
}

export default function* () {
    yield all([
        takeLatest(AppInit, handlerAppInit),
        takeLatest(setI18nActiveLanguage, handlerSetI18n),
    ]);
}
