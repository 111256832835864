import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import 'regenerator-runtime/runtime.js';
import {
    fetchCategories,
    fetchCategoriesFailed,
    fetchCategoriesSucceed,
} from 'modules/Categories/Categories.actions';
import { getCategoriesReq } from 'modules/Categories/Categories.services';
import { AppToaster } from 'plugins/app-toaster';
import { SetAgeVerification } from 'modules/App/App.actions';
import { Loader } from 'plugins/loader';

function* appAgeVerificationInit(data: any) {
    const { age_limit, age_warning_message, age_verification } = data;
    yield put(SetAgeVerification({ age_limit, age_warning_message, age_verification }));
}

export function* handleFetchCategories(_action: any) {
    Loader.category.on({ global: false });

    const { payload } = _action;
    if (payload.id === undefined) {
        return;
    }
    try {
        const { data } = yield call(getCategoriesReq, { category_id: payload.id });

        yield fork(appAgeVerificationInit, data);

        let childId = null;
        if (data.children.length === 0) {
            const ids = data.id_path.split('/');
            const idx = ids.at(-2);
            childId = payload.id;
            const newdata = yield call(getCategoriesReq, { category_id: idx });
            data.children = newdata.data.children;
        }

        yield put(
            fetchCategoriesSucceed({
                id: payload.id,
                items: data,
                childId,
            })
        );
    } catch (e) {
        console.log(e);
        AppToaster.error({ body: 'product_cannot_be_added_to_cart_temporarily' });
        yield put(fetchCategoriesFailed());
    }

    Loader.category.off();
}

export default function* () {
    yield all([
        takeEvery(fetchCategories, handleFetchCategories),
        // takeLatest(fetchCategoriesSucceed, handleFetchCategoriesSucceed),
    ]);
}
