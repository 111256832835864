import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import {
    SearchServiceFailed,
    SearchServiceFlush,
    SearchServiceInit,
    SearchServiceSucceed,
    SearchServiceSuggestionsInit,
    SearchServiceSuggestionsSucceed,
} from 'modules/SearchService/SearchService.actions';
import { SearchServiceState } from 'modules/SearchService/SearchService.selectors';
import {
    SearchServiceGetSuggestions,
    SearchServiceServices,
    suggestionParser,
} from 'modules/SearchService/SearchService.services';
import { SearchServiceReqPayload } from 'modules/SearchService/types';
import { Loader } from 'plugins/loader';
import { removePageFromQS } from 'modules/ProductsList/utils';
import { SetApiInstancesHeader } from 'services';
import { getFingerprint } from 'data';

function* SearchServiceInitHandler(_action: { payload: SearchServiceReqPayload }) {
    const { payload } = _action;

    const { isFirstRun, category_id, inputQuery } = payload;
    const { data, inputQuery: prev_input } = yield select(SearchServiceState);

    const { url: prevUrl, page } = data;

    const [currentUrl, newPage = 1] = removePageFromQS(payload.url);

    /* --------------------- if new query then reset filters -------------------- */
    const reset_filters_obj: boolean =
        inputQuery !== prev_input || String(category_id) !== String(data?.prev_category_id);

    /* ------------------ Flush products if search url changes ------------------ */
    if (currentUrl !== prevUrl) {
        yield put(SearchServiceFlush({ reset_filters_obj }));
    }

    /* ------------------------ If same page early return ----------------------- */
    if (
        currentUrl === prevUrl &&
        (payload?.persistProducts
            ? Number(page) >= Number(newPage)
            : Number(page) === Number(newPage))
    ) {
        return;
    }

    /* ---------------------- Other way fetch new page/url ---------------------- */
    Loader.search.on({ global: false });

    try {
        SetApiInstancesHeader('fingerprint-id', getFingerprint() || '');
        const {
            data: { data },
        } = yield call(SearchServiceServices, payload);

        yield put(
            SearchServiceSucceed({
                data: { ...data, url: currentUrl, isFirstRun, category_id },
                persistProducts: payload?.persistProducts,
                reset_filters_obj: false,
                inputQuery,
                category_id,
            })
        );
    } catch (e) {
        console.log(e);
        yield put(SearchServiceFailed({}));
    }

    Loader.search.off();
}

function* SearchServiceSuggestionsHandler(_action: any) {
    try {
        const {
            data: { data },
        } = yield call(SearchServiceGetSuggestions, _action.payload.search_value);

        yield put(
            SearchServiceSuggestionsSucceed(suggestionParser(data.hits, _action.payload.isSuper))
        );
    } catch (e) {
        console.log(e);
    }
}

export default function* () {
    yield all([takeLatest(SearchServiceInit, SearchServiceInitHandler)]);
    yield all([takeLatest(SearchServiceSuggestionsInit, SearchServiceSuggestionsHandler)]);
}
