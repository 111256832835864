import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import 'regenerator-runtime/runtime.js';
import { PayloadAction } from '@reduxjs/toolkit';
import { getSelectedCityData } from 'modules/Cities/Cities.selectors';
import {
    getSupermarkets,
    getSupermarketSearch,
    getFmcgRecommendations,
    getHotPrice,
    getTestSuper,
} from 'modules/Supermarkets/Supermarkets.services';
import { AppToaster } from 'plugins/app-toaster';
import { Loader } from 'plugins/loader';
import { SetAppError } from 'modules/App/App.actions';
import { ProductsParser } from 'modules/ProductsList/ProductsList.helpers';
import { AppCity } from 'modules/Cities/types';
import {
    fetchSupermarkets,
    fetchSupermarketsDetail,
    fetchSupermarketsDetailFailed,
    fetchSupermarketsDetailSucceed,
    fetchSupermarketsFailed,
    fetchSupermarketsSucceed,
    fetchSupermarketsSearch,
    fetchSupermarketsSearchSucceed,
    fetchSupermarketsSearchFailed,
    fetchReccomendations,
    fetchReccomendationsSucceed,
    fetchReccomendationsFailed,
    fetchSuperCategories,
    fetchSuperCategoriesSucceed,
    fetchSuperCategoriesFailed,
} from './Supermarkets.actions';
import { getSupermarketCity } from './Supermarkets.selectors';
import { IGroupedSupermarkets } from './types';
import { CONST_SUPS, OBJECT_SUPS } from './CONSTS';

// Список супермаркетов
function* handleFetchSupermarkets({ payload }: PayloadAction<any>) {
    const selectedCityData: AppCity = yield select(getSelectedCityData);

    const supermarket_city: string = yield select(getSupermarketCity);

    if (String(supermarket_city) === String(selectedCityData.cityName)) {
        return;
    }

    try {
        Loader.supermarkets.on({ global: false });
        const params = {
            city_id: selectedCityData?.cityId,
            ...payload,
        };

        const { data } = yield call(getSupermarkets, params);

        const { supermarket_list }: { supermarket_list: any[] } = data?.data;

        const grouped_supermarkets: IGroupedSupermarkets[] = CONST_SUPS.map(({ title, ids }) => ({
            title,
            list: supermarket_list
                .filter((f) => ids.includes(f.company_id))
                .sort((a, b) => b?.position - a?.position)
                .map((a) => ({
                    ...a,
                    banner: a.logos.supermarket.image.image_path || '',
                    hardcode: OBJECT_SUPS[`${a.company_id}`],
                })),
        })).filter((f) => f.list.length);

        // data?.data?.supermarket_list?.find((f) => f.company_id == id)

        yield put(
            fetchSupermarketsSucceed({
                ...data?.data,
                grouped_supermarkets,
            })
        );
    } catch (e) {
        console.log(e);
        AppToaster.error({ body: 'supermarkets_temporarily_unavailable' });
        yield put(fetchSupermarketsFailed());
    } finally {
        Loader.supermarkets.off();
    }
}

// Супермаркет
function* handleFetchSupermarket({ payload }: any) {
    Loader.supermarketDetail.on({ global: false });

    const formData = new FormData();
    formData.append('ids[]', payload.id);

    try {
        const { data } = yield call(getTestSuper, formData);

        const supermarket = data.data[payload.id];

        yield put(
            fetchSupermarketsDetailSucceed({ data: supermarket, from: payload.from || 'page' })
        );
    } catch (e: any) {
        AppToaster.warning({ body: 'supermarket_page_temporarily_not_displayed' });
        yield put(fetchSupermarketsDetailFailed());
        yield put(SetAppError(e?.data));
    } finally {
        Loader.supermarketDetail.off();
    }
}

// Поиск
function* handleFetchSupermarketSearch({ payload }: any) {
    Loader.supermarkets_search.on({ global: false });

    try {
        const { data } = yield call(getSupermarketSearch, payload);

        const list = data?.data.map((i: any) => ({ ...i, products: ProductsParser(i.products) }));

        yield put(
            fetchSupermarketsSearchSucceed({
                list,
                query: payload?.query,
            })
        );
    } catch (e) {
        AppToaster.warning({ body: 'supermarket_page_temporarily_not_displayed' });
        yield put(fetchSupermarketsSearchFailed());
    } finally {
        Loader.supermarkets_search.off();
    }
}

// Рекомендации
function* handleFetchReccomendations({ payload }: any) {
    Loader.supermarkets_recommendations.on({ global: false });

    try {
        const { data } = yield call(getFmcgRecommendations, payload);

        const list = Object.values(data?.data?.products || {});

        yield put(
            fetchReccomendationsSucceed({
                title: data?.data?.type,
                list: ProductsParser(list),
            })
        );
    } catch (e) {
        yield put(fetchReccomendationsFailed());
    } finally {
        Loader.supermarkets_recommendations.off();
    }
}

// Hot price
function* handleFetchSuperCategories({ payload }: any) {
    Loader.supermarkets_categories.on({ global: false });

    try {
        const { data } = yield call(getHotPrice, payload);

        yield put(fetchSuperCategoriesSucceed(data?.data));
    } catch (e) {
        yield put(fetchSuperCategoriesFailed());
    } finally {
        Loader.supermarkets_categories.off();
    }
}

export default function* () {
    yield all([
        takeLatest(fetchSupermarkets, handleFetchSupermarkets),
        takeLatest(fetchSupermarketsDetail, handleFetchSupermarket),
        takeLatest(fetchSupermarketsSearch, handleFetchSupermarketSearch),
        takeLatest(fetchReccomendations, handleFetchReccomendations),
        takeLatest(fetchSuperCategories, handleFetchSuperCategories),
    ]);
}
