import React, { useMemo } from 'react';
import debounce from 'lodash/debounce';
import { Box, Display, FlexBox, Input, Slider, Space, Text } from 'pyatka-ui';
import styled from 'styled-components';
import { signs } from 'utils/symbols';
import { StyledPriceInput, StyledPriceInputGap } from 'pages/ProductsList/ProductsList.styles';

import { handleRangePos } from 'pages/ProductsList/utils';
import priceFormatter from 'utils/priceFormatter';
import { useI18n } from 'hooks/useI18n';

/* ------------ Filter Price Component (Min/Max input and Slider) ----------- */
const FilterPrice = ({ variants, handlePrice, activePrice, isMobile, rangePrice }: any) => {
    const { min, max } = variants;
    const { $t } = useI18n();

    const [inputRange, setInputRange] = React.useState(activePrice);

    /* ------------------------------ Min Max State ----------------------------- */
    const [rangeMin, rangeMax] = useMemo(() => inputRange, [inputRange]);

    React.useEffect(() => setInputRange(activePrice), [activePrice]);

    //------------------ Debounce Input
    const debounceInput = React.useMemo(
        () => debounce((value: number[]) => handlePrice(...value), 1000),
        [handlePrice]
    );

    const handleInput = (val: string, pos: number) => {
        /* ------------------------ Convert string to number ------------------------ */
        const intVal: number = parseInt(val.replace(/\D/g, '')) || 0;

        /* ----------------------------- new Range input ---------------------------- */
        const newRange = handleRangePos(inputRange, intVal, pos);
        setInputRange(newRange);
        isMobile ? (rangePrice.current = newRange) : debounceInput(newRange);
    };

    const onSliderChange = (min: number, max: number) => {
        setInputRange([min, max]);
        isMobile && (rangePrice.current = [min, max]);
    };

    return (
        <Box mt={['0px', '20px']}>
            <FlexBox alignItems="center" justifyContent="space-between">
                {/* Min input */}
                <StyledPriceInput>
                    <Text
                        fontSize={{ default: '11px', lg: '9px' }}
                        lineHeight="11px"
                        fontWeight="400"
                        color="text.secondary"
                        style={{ cursor: 'pointer' }}
                    >
                        {$t('from').toCapitalizeFirstLetter()}
                    </Text>
                    <Input
                        suffix={<Text color="text.secondary">{signs.tg}</Text>}
                        borderBottom="1.5px solid"
                        color="text.main"
                        styleType="contained"
                        borderColor="block.disabled"
                        p={0}
                        pt="4px"
                        pb="6px"
                        backgroundColor="transparent"
                        value={priceFormatter(rangeMin) || ''}
                        onChange={(val) => handleInput(val as string, 0)}
                    />
                </StyledPriceInput>

                {/* Divider "-" */}
                <StyledPriceInputGap>
                    <Display md={false}>
                        <Text fontSize="18px" lineHeight="26px" color="text.secondary">
                            _
                        </Text>
                    </Display>
                </StyledPriceInputGap>

                {/*  Max input */}
                <StyledPriceInput>
                    <Text
                        fontSize={{ default: '11px', lg: '9px' }}
                        lineHeight="11px"
                        fontWeight="400"
                        color="text.secondary"
                        style={{ cursor: 'pointer' }}
                        //marginBottom="4px"
                    >
                        {$t('before').toCapitalizeFirstLetter()}
                    </Text>
                    <Input
                        suffix={<Text color="text.secondary">{signs.tg}</Text>}
                        borderBottom="1.5px solid"
                        borderColor="block.disabled"
                        backgroundColor="transparent"
                        styleType="contained"
                        p={0}
                        pt="4px"
                        pb="6px"
                        // value={String(input[1])}
                        color="text.main"
                        value={priceFormatter(rangeMax) || ''}
                        // onChange={onChangeMax}
                        onChange={(val) => handleInput(val as string, 1)}
                        marginLeft={{ default: '-3px', lg: '' }}
                    />
                </StyledPriceInput>
            </FlexBox>

            <Space marginTop={{ default: '11px', md: '20px' }} />

            <StyledSlider>
                <Slider
                    min={min}
                    max={max}
                    minValue={Number(rangeMin)}
                    maxValue={Number(rangeMax)}
                    width="100%"
                    onChange={onSliderChange}
                    onChangeCommitted={!isMobile && handlePrice}
                />
            </StyledSlider>
            <Space marginTop={{ default: '10px', md: '0' }} />
        </Box>
    );
};

const StyledSlider = styled.div`
    & input {
        &::-webkit-slider-thumb {
            background-color: #fff;
        }

        // For firefox
        &::-moz-range-thumb {
            background-color: #fff;
        }
    }
`;

export default FilterPrice;
