import React, { useCallback, useEffect } from 'react';
import { YMaps, Map, Clusterer, Placemark } from 'react-yandex-maps';
import { Box, FlexBox, Icon, LoadingIndicator, usePyatka } from 'pyatka-ui';
import { useSelector } from 'react-redux';
import {
    closeIconStyle,
    geoWrapperStyle,
    wrapperStyle,
    zoomWrapperStyle,
} from 'components/MapUserLocation/MapUserLocation.Styles';
import { AppToaster } from 'plugins/app-toaster';
import { getSelectedCityData } from 'modules/Cities/Cities.selectors';
import { YMapsProps } from 'components/YMaps/types';
import { getGeoCodeServiceV2 } from 'modules/UserProfileService/UserProfileService.services';
import userPositionMarker from '../../pages/CheckoutDeliveryV2Page/assets/userPositionMarker.svg';

const apiKey = __IS_PROD__
    ? 'dcdfda7f-83e6-47f7-a5a2-1827cbbd3cc6'
    : 'c45e66a5-d57e-4ac2-804c-477501423a69';
const YMap = (props: YMapsProps) => {
    const { onClose, points, defaultIcon, onPlacemarkClick, activeIcon, selectedPoint } = props;

    const { scales } = usePyatka();
    const isMobile = (scales.matched || []).find((el: any) => el.name === 'md');
    const [ymaps, setYmaps] = React.useState<any>();
    const [center, setCenter] = React.useState<number[]>([43.226647, 76.946938]);
    const [currentLocation, setCurrentLocation] = React.useState<number[] | null>(null);
    const [zoom, setZoom] = React.useState(10);
    const [isGeoLoading, setIsGeoLoading] = React.useState(false);
    const [activePoint, setActivePoint] = React.useState<any>();
    const cityData = useSelector(getSelectedCityData);

    const inZoom = () => setZoom((prev) => prev + 1);
    const outZoom = () => setZoom((prev) => prev - 1);
    const getCurrentLocation = useCallback((showToaster: boolean = true) => {
        setIsGeoLoading(true);
        navigator.geolocation.getCurrentPosition(
            (pos) => {
                setCenter([pos.coords?.latitude, pos.coords?.longitude]);
                setCurrentLocation([pos.coords?.latitude, pos.coords?.longitude]);
                setIsGeoLoading(false);
            },
            (_error) => {
                showToaster && AppToaster.warning({ body: 'geolocation_is_disabled' });
                setIsGeoLoading(false);
            }
        );
    }, []);

    const setActionPointOptions = useCallback((point: any) => {
        setActivePoint(point);
        const { location } = point;
        setCenter([location.latitude, location.longitude]);
        setZoom(18);
    }, []);
    const handlePlacemarkClick = useCallback(
        (point: any) => {
            onPlacemarkClick && onPlacemarkClick(point);
            setActionPointOptions(point);
        },
        [onPlacemarkClick, setActionPointOptions]
    );
    useEffect(() => {
        if (!selectedPoint) {
            getGeoCodeServiceV2({ geocode: `город ${cityData?.cityName}` }).then(({ data }) => {
                setCenter([data?.data[0].lat, data?.data[0].lon]);
            });
        }
    }, [cityData, selectedPoint, ymaps]);
    useEffect(() => {
        if (selectedPoint) {
            setActionPointOptions(selectedPoint);
        }
    }, [selectedPoint, setActionPointOptions]);
    useEffect(() => {
        getCurrentLocation(false);
    }, []);

    return (
        <>
            <YMaps
                query={{
                    // apikey: apiKey,
                    load: 'Map,Placemark,geocode,Clusterer,templateLayoutFactory',
                }}
            >
                <Map
                    onLoad={(ymaps) => setYmaps(ymaps)}
                    state={{
                        center,
                        zoom,
                    }}
                    width="100%"
                    height="100%"
                >
                    <Clusterer
                        options={{
                            clusterOpenBalloonOnClick: false,
                            clusterIcons: [
                                {
                                    size: [40, 40],
                                    offset: [-20, -20],
                                },
                            ],
                            clusterIconContentLayout: ymaps?.templateLayoutFactory.createClass(
                                '<div style="color: #FFFFFF; font-weight: bold; width: 40px; height: 40px; background: #FF7348; border-radius: 50px; border: 4px solid #FFFFFF; display: flex; align-items: center; justify-content: center">{{ properties.geoObjects.length }}</div>'
                            ),
                        }}
                    >
                        {points?.map((point) => (
                            <Placemark
                                id={point.value}
                                key={point.value}
                                onClick={() => handlePlacemarkClick(point)}
                                geometry={[point?.location?.latitude, point?.location?.longitude]}
                                options={{
                                    iconImageHref:
                                        point.value === activePoint?.value
                                            ? point?.mapParams?.activeIcon
                                            : point?.mapParams?.defaultIcon,
                                    iconLayout: 'default#image',
                                    iconImageSize:
                                        point.value === activePoint?.value ? [44, 52] : [32, 37],
                                    iconImageOffset:
                                        point.value === activePoint?.value
                                            ? [-22, -52]
                                            : [-16, -37],
                                }}
                            />
                        ))}
                    </Clusterer>
                    {currentLocation && (
                        <Placemark
                            geometry={currentLocation}
                            options={{
                                iconImageHref: userPositionMarker,
                                iconLayout: 'default#image',
                                iconImageSize: [32, 37],
                                iconImageOffset: [-16, -37],
                            }}
                        />
                    )}
                </Map>
            </YMaps>
            <FlexBox
                width="100%"
                height={isMobile ? '100vh' : '100%'}
                position="absolute"
                top="0"
                right="0"
                alignItems="center"
                justifyContent="center"
                style={{ pointerEvents: 'none' }}
            >
                <Box style={wrapperStyle}>
                    <Box style={zoomWrapperStyle}>
                        <Icon icon="plus" width={'38px'} height={'38px'} onClick={inZoom} />
                        <Icon icon="minus" width={'38px'} height={'38px'} onClick={outZoom} />
                    </Box>
                </Box>
                <Box style={closeIconStyle}>
                    <Icon icon="ymapsClose" width={'38px'} height={'38px'} onClick={onClose} />
                </Box>
                <Box style={geoWrapperStyle}>
                    {isGeoLoading ? (
                        <LoadingIndicator size="20px" />
                    ) : (
                        <Icon icon="ymaps-geo" onClick={getCurrentLocation} />
                    )}
                </Box>
            </FlexBox>
        </>
    );
};

export default YMap;
