import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { InterceptorMiddleware } from 'services/utils';
import {
    requestMiddlewares,
    responseErrMiddleware,
    responseMiddlewares,
} from 'services/middlewares';

// ------------------------------------------------------------------ On Request
const onRequest = (requestConfig: AxiosRequestConfig): AxiosRequestConfig => {
    requestConfig.headers.Cookie = ''
    const { req } = InterceptorMiddleware(requestMiddlewares)({ req: { ...requestConfig } });
    return req ? req : requestConfig;
};
// ------------------------------------------------------------------ On Request Error
const onRequestError = (error: AxiosError) => {
    return Promise.reject(error);
};
// ------------------------------------------------------------------ On Response
const onResponse = (response: AxiosResponse): AxiosResponse => {
    const { res } = InterceptorMiddleware(responseMiddlewares)({ res: response });
    return res ? res : response;
};
// ------------------------------------------------------------------ On Response Error
const onResponseError = (responseErr: AxiosError) => {
    const { res } = InterceptorMiddleware(responseErrMiddleware)({ res: responseErr.response });
    return Promise.reject(res);
};

export default { onRequest, onRequestError, onResponse, onResponseError };
