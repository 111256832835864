import React from 'react';
import { FlexBox, Text, Skeleton, usePyatka } from 'pyatka-ui';
import { Rating } from 'components';

const ProductRating: React.FC<IProductRating> = ({ avgRate = '', totalRecords = 0, isLoading }) => {
    const { scales } = usePyatka();

    const starSize = scales?.isMobile ? '11px' : '16px';

    if (isLoading) {
        <Skeleton animation width="100%" borderRadius="10px" height="12px" />;
    }

    return (
        <FlexBox
            lineHeight={{ default: '15px', md: '20px' }}
            fontSize={{ default: '12px', md: '16px' }}
            alignItems="center"
            fontWeight={500}
            gap="4px"
        >
            <Rating rating={Number(avgRate)} height={starSize} width={starSize} />
            {String(avgRate || '').length > 0 && (
                <>
                    <Text color="text.main">{String(avgRate || '').slice(0, 3)}</Text>
                    <Text color="text.secondary">({totalRecords})</Text>
                </>
            )}
        </FlexBox>
    );
};

interface IProductRating {
    avgRate: string;
    totalRecords: number;
    isLoading: boolean;
}

export default ProductRating;
