import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, FlexBox, Icon, LoadingIndicator, Text, usePyatka } from 'pyatka-ui';
import { useAppRouter } from 'router/context';
import { handleSwipe } from 'utils/swipeControl';

import { getCollectionFormMobileOverlay } from 'modules/Wishlist/Wishlist.selectors';
import { collectionFormMobileOverlaySet } from 'modules/Wishlist/Wishlist.actions';
import EditCollectionLayout from 'pages/WishListServicePage/v2/components/EditCollectionLayout';
import styles from './CollectionFormModal.module.scss';

interface ICollectionFormModalProps {
    open?: boolean;
    onClose?: () => void;
    collection_title?: string;
    layout?: 'add' | 'edit';
    onSubmitForm?: (name: string) => void;
}

const CollectionFormModal = (props: ICollectionFormModalProps) => {
    const { open, layout, onClose, collection_title = '', onSubmitForm } = props;
    const collectionOverlayData = useSelector(getCollectionFormMobileOverlay);
    const isOpen = collectionOverlayData?.open || open;
    const layout_type = collectionOverlayData?.config?.layout_type || layout;
    const [visible, setVisible] = React.useState<boolean>(false);
    const timeoutId = React.useRef<any>(null);
    const { location } = useAppRouter();
    const dispatch = useDispatch();

    const closeMobileSearch = React.useCallback(() => {
        handleSwipe(location?.pathname, true);
        onClose && onClose();
        dispatch(collectionFormMobileOverlaySet({ open: false }));
    }, [dispatch, location?.pathname]);

    React.useEffect(() => {
        closeMobileSearch();
    }, [location.search]);

    // why useMemo? write me (tg: @k4zhymukhan)
    React.useMemo(() => {
        if (isOpen) {
            handleSwipe(location?.pathname, false);
            setVisible(true);
            return;
        }

        if (!isOpen) {
            timeoutId.current = setTimeout(() => setVisible(false), 200);
        }
    }, [isOpen]);

    React.useEffect(() => {
        return () => {
            !isOpen && clearTimeout(timeoutId.current);
        };
    }, [isOpen]);

    const className = styles[isOpen ? 'a-fadeInRight' : 'a-fadeOutRight'];

    if (!visible) {
        return null;
    }

    return (
        <Box bg="block.main" className={`${styles['search-overlay']} ${className}`}>
            <Box className={styles['search-overlay__content']} p={18}>
                <FlexBox alignItems="center" mb={78}>
                    <Box onClick={closeMobileSearch}>
                        <Icon width="24px" height="24px" icon="arrowLeft" fill="block.inverse" />
                    </Box>
                </FlexBox>
                <EditCollectionLayout
                    layout_type={layout_type}
                    product_id={collectionOverlayData?.config?.product_id}
                    onSubmitForm={onSubmitForm}
                    onClose={closeMobileSearch}
                    collection_title={collection_title}
                />
            </Box>
        </Box>
    );
};

export default React.memo(CollectionFormModal);
