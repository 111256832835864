import { ApiGwRequest } from 'services';

export const GetCheckoutDeliveryData = (payload: any) => {
    return ApiGwRequest({
        url: 'cart/v2/checkout/delivery-methods',
        showError: true,
        params: payload,
    });
};

export const GetCheckoutDeliveryDataV2 = (payload: any) => {
    return ApiGwRequest({
        url: 'checkout/receipt-methods',
        showError: true,
        params: payload,
    });
};

export const GetCheckoutPaymentsData = (payload: any) => {
    return ApiGwRequest({
        url: 'cart/v1/checkout/payments',
        params: payload,
    });
};

export const CheckoutCreateOrder = (data: any) => {
    return ApiGwRequest({
        method: 'post',
        url: 'order/v1/order',
        data,
    });
};

export const CheckoutPaymentProcessingOrder = (data: any) => {
    return ApiGwRequest({
        method: 'post',
        url: 'payment/v1/payment-types/card/start',
        data,
    });
};

export const CheckoutCartHashCheck = (params: any) => {
    return ApiGwRequest({
        method: 'get',
        url: 'cart/v3/basket',
        params,
    });
};

export const CheckoutWebLoanPay = (data: any) => {
    return ApiGwRequest({
        method: 'post',
        url: 'qr-lite/web-link',
        data,
    });
};
