import { createSelector } from 'reselect';
import { AppRootState } from 'store/types';
import initialState from 'modules/Authorization/v2/Authorization.state';
// import { isNative } from 'utils/platform';

export const getAuthReducer = (state: AppRootState) => {
    return state.AuthorizationReducer || initialState;
};

export const getAuthState = createSelector([getAuthReducer], (state) => state);

export const getUserData = createSelector([getAuthState], (userAuthData) => userAuthData);
export const getUserToken = createSelector([getAuthReducer], (data) =>
    data.token ? data.token : null
);

export const getUserOtpData = createSelector([getAuthState], (state) => state.otp);

export const authErrors = createSelector([getAuthState], (state) => state.errors);
export const isUserAuth = createSelector([getAuthState], (data) => {
    const token = data.token ? data.token : null;
    const nullCases = [null, 'null', undefined, 'undefined', ''];
    const nullAccept = nullCases.includes(token);
    return nullAccept ? false : token;
});
