import React from 'react';
import { Box, Display } from 'pyatka-ui';
import { NewTabNavigation } from 'components';
import { MobileTabNavigationLayoutProps } from './types';

export const MobileTabNavigationLayout: React.FC<MobileTabNavigationLayoutProps> = () => {
    return (
        <React.Fragment>
            <Display xxl={false} md={true}>
                <Box
                    pt={1}
                    pb={2}
                    bottom={0}
                    zIndex={30}
                    borderTop={2}
                    width="100vw"
                    bg="block.main"
                    position="fixed"
                    overflow="hidden"
                    borderColor="block.secondary"
                >
                    <NewTabNavigation appearance="tab" size="sm" />
                </Box>
            </Display>
        </React.Fragment>
    );
};
