export const compareVersions = (v1: string, v2: string) => {
    if (typeof v1 !== 'string' || typeof v2 !== 'string') {
        return -1;
    }

    const arrV1 = v1.split('.');
    const arrV2 = v2.split('.');
    const length = Math.max(arrV1.length, arrV2.length);

    for (let i = 0; i < length; i++) {
        const num1 = parseInt(arrV1[i]) || 0;
        const num2 = parseInt(arrV2[i]) || 0;

        if (num1 === num2) {
            continue;
        }

        return num1 > num2 ? 1 : -1;
    }

    return 0;
};
