import React from 'react';
import { Box, Button, FlexBox, Icon, LoadingIndicator, Space, Text, usePyatka } from 'pyatka-ui';
import { ProfileAddressListLayoutItem } from 'components/ProfileAddressList/ProfileAddressListV2/ProfileAddressList.LayoutItem';
import { ProfileAddressListLayoutProps } from 'components/ProfileAddressList/ProfileAddressListV2/types';
import { StickyAddNewAddressWrapper } from 'components/ProfileAddressList/ProfileAddressListV2/ProfileAddressList.styles';
import { useI18n } from 'hooks/useI18n';

export const ProfileAddressListLayout: React.FC<ProfileAddressListLayoutProps> = ({
    addressInfos,
    addNewAddress,
    setDefaultAddress,
    editExistAddress,
    deleteExistAddress,
    isLoading,
    activeAddress,
    onSetActiveAddress,
    isMobile,
}) => {
    const { theme } = usePyatka();
    const { $t } = useI18n();

    return (
        <React.Fragment>
            {isLoading && (
                <FlexBox justifyContent="center" alignItems="center">
                    <div>
                        <Space height="100px" />
                        <LoadingIndicator size="35px" />
                        <Space height="100px" />
                    </div>
                </FlexBox>
            )}
            {!isLoading && (
                <FlexBox flexDirection={'column'}>
                    <FlexBox
                        flexDirection={'column'}
                        gap={'10px'}
                        paddingY={18}
                        overflowX={'hidden'}
                        // maxHeight={'100vh - 140px'}
                    >
                        {(addressInfos || [])?.map((address: any, idx: number) => {
                            const addressItemProps = {
                                address,
                                activeAddress,
                                editExistAddress,
                                deleteExistAddress,
                                index: idx,
                                onSetActiveAddress,
                                setDefaultAddress,
                                isMobile,
                            };
                            return (
                                <React.Fragment key={idx}>
                                    <ProfileAddressListLayoutItem {...addressItemProps} />
                                </React.Fragment>
                            );
                        })}
                    </FlexBox>

                    <Space height={isMobile ? '180px' : '110px'} />
                    <StickyAddNewAddressWrapper theme={theme}>
                        <Box style={{ cursor: 'pointer' }}>
                            <FlexBox
                                gap={'30px'}
                                alignItems={'center'}
                                mb={24}
                                onClick={addNewAddress}
                            >
                                <Box>
                                    <Icon width="24px" height="24px" icon="plus_simple" />
                                </Box>
                                <Box>
                                    <Text
                                        color={'text.main'}
                                        fontWeight={500}
                                        fontSize={{ default: 16, md: 20 }}
                                        lineHeight={'22px'}
                                    >
                                        {$t('add_new_address')}
                                    </Text>
                                </Box>
                            </FlexBox>
                            <Button
                                onClick={() => {
                                    setDefaultAddress(activeAddress?.id!);
                                }}
                                variant={'brand'}
                                width={'100%'}
                                styleType={'contained'}
                                paddingY={'17px'}
                                borderRadius={'22px'}
                                fontSize={{ default: 16, md: 20 }}
                            >
                                {$t('select')}
                            </Button>
                        </Box>
                    </StickyAddNewAddressWrapper>
                </FlexBox>
            )}
        </React.Fragment>
    );
};
