import { createSelector } from 'reselect';
import { AppRootState } from 'store/types';
import {
    IProductListSeo,
    IdPathName,
    Product,
    ProductsCheckBoxFilter,
    ProductsListInitialState,
    ProductsPriceFilter,
} from 'modules/ProductsList/types';
import { ProductsFilterParser, ProductsParser } from 'modules/ProductsList/ProductsList.helpers';
import { CategoryItem } from 'modules/Categories/types';

const ProductsListReducer = (state: AppRootState) => state.ProductsListReducer;

export const ProductsListState = createSelector([ProductsListReducer], (state) => state);

export const ProductsListData = createSelector(
    [ProductsListReducer],
    (state): ProductsListInitialState['data'] => state.data
);

export const ProductsListCategories = createSelector(
    [ProductsListData],
    (state): CategoryItem[] => {
        return (state?.categories || [])?.map((item) => ({
            title: item.name,
            parentId: String(item.parent_id),
            categoryId: String(item.id),
            category1: item?.id_path_names?.length > 0 ? item?.id_path_names[0]?.category_id : null,
            image: item.image,
            hasChildren: false,
            url: {
                name: 'home-page',
                query: { category_id: item.id },
                exact: true,
            },
            tree: item.id_path_names.map((item) => ({
                id: String(item.category_id),
                title: item.category,
            })),
        }));
    }
);

const makePathNames = (breadcrumb: IdPathName[]) => {
    const makeLinkConfig = (category_id: string) => ({
        name: 'home-page',
        query: { category_id },
    });

    const paths = breadcrumb.map((item) => {
        const res = {
            link: makeLinkConfig(String(item.category_id)),
            title: item.category,
        };
        if (Number(item.category_id) === 2207) {
            // @ts-ignore
            res.link = { name: 'store-main' };
        }
        return res;
    });

    return paths;
};

export const getPathNames = createSelector([ProductsListData], (state) => {
    if (Array.isArray(state.parentCategory) && state.parentCategory.length > 0) {
        const parentCat = state.parentCategory[0];
        return parentCat?.id_path_names ? makePathNames(parentCat.id_path_names) : null;
    }
    if (state.parentCategory?.id_path_names) {
        return makePathNames(state.parentCategory.id_path_names);
    }
    return null;
});

export const ProductsListFilters = createSelector(
    [ProductsListState],
    ({ filtersObject, activeFilters, filters_order }) => ({
        filtersObject,
        activeFilters,
        filters_order,
    })
);

export const ProductsListObjectFilters = createSelector(
    [ProductsListReducer],
    (state) => state.filtersObject
);

export const ProductsListActiveFilters = createSelector(
    [ProductsListReducer],
    (state) => state.activeFilters
);

export const ProductsListFiltersOrder = createSelector(
    [ProductsListReducer],
    (state) => state.filters_order
);

export const ProductsListCurrentPage = createSelector([ProductsListData], (data) => data.page);

export const ProductsListMaxPage = createSelector([ProductsListData], (data) => data.total_pages);

export const ProductsListTotalCount = createSelector(
    [ProductsListData],
    (data) => data.total_count
);

export const ProductsList = createSelector([ProductsListData], (data): Product[] =>
    ProductsParser(data.products)
);

export const ProductsListSeo = createSelector(
    [ProductsListState],
    (state): IProductListSeo | null => state.seo
);
