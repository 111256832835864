import React from 'react';

export const wrapperStyle: React.CSSProperties = {
    position: 'absolute',
    top: '30%',
    right: '18px',

    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    pointerEvents: 'all',
    zIndex: 999,
};
export const closeIconStyle: React.CSSProperties = {
    position: 'absolute',
    top: '18px',
    right: '18px',
    pointerEvents: 'all',
    cursor: 'pointer',
};
export const zoomWrapperStyle: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    gap: '5px',
};

export const geoWrapperStyle: React.CSSProperties = {
    position: 'absolute',
    bottom: '103px',
    right: '18px',
    pointerEvents: 'all',
};
