import React from 'react';
import { Helmet } from 'react-helmet-async';
import { MobileLayoutProps } from '../types';
import { GoBackBtn, SearchBtn, Title, HeaderWrapper } from '../components';

interface ListingHeaderProps {
    parentCategory: MobileLayoutProps['parentCategory'];
}

const ListingHeader: React.FC<ListingHeaderProps> = ({ parentCategory }) => {
    const [title, setTitle] = React.useState('');

    React.useLayoutEffect(() => {
        setTitle(parentCategory?.name || '');
    }, [parentCategory?.name]);

    return (
        <React.Fragment>
            <Helmet />
            <HeaderWrapper>
                <GoBackBtn />
                <Title title={title} />
                <SearchBtn />
            </HeaderWrapper>
        </React.Fragment>
    );
};

export default React.memo(ListingHeader);
