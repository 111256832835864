import { createSelector } from 'reselect';
import { AppRootState } from 'store/types';
import { SearchServiceInitialState } from './types';

const SearchServiceReducer = (state: AppRootState): SearchServiceInitialState =>
    state.SearchServiceReducer;

export const SearchServiceState = createSelector([SearchServiceReducer], (state) => state);

export const SearchServiceData = createSelector(
    [SearchServiceState],
    ({ data }): SearchServiceInitialState['data'] => data
);

export const SearchServiceFilters = createSelector(
    [SearchServiceState],
    ({ filtersObject, activeFilters, filters_order }) => ({
        filtersObject,
        activeFilters,
        filters_order,
    })
);

export const SearchServiceActiveFilters = createSelector(
    [SearchServiceState],
    (state) => state.activeFilters
);

export const SearchServiceProducts = createSelector(
    [SearchServiceData],
    ({ products, categories }) => {
        const [cat] = Array.isArray(categories) ? categories : [];
        return (products || []).map((item) => ({
            ...item,
            category_id: cat?.categoryId,
        }));
    }
);

export const getSearchProductCount = createSelector(
    [SearchServiceData],
    (state) => state.total_count
);

export const isFirstRenderOfSearchPage = createSelector(
    [SearchServiceReducer],
    (state) => state.firstRender
);

export const SearchServiceMobileOverlay = createSelector(
    [SearchServiceReducer],
    (state) => state.mobile_search_overlay
);

export const SearchServiceSuggestions = createSelector(
    [SearchServiceReducer],
    (state) => state.suggestions
);

export const SearchServiceInputQuery = createSelector(
    [SearchServiceReducer],
    (state) => state.inputQuery
);
