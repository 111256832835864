import React from 'react';
import { SupermarketCardLayout } from './SupermarketCard.Layout';
import { SupermarketCardLayoutSkeleton } from './SupermarketCard.LayoutSkeleton';
import { SupermarketCardProps } from './types';

export const SupermarketCardContainer: React.FC<SupermarketCardProps> & {
    Layout: typeof SupermarketCardLayout;
} = (props) => {
    return (
        <React.Fragment>
            {props.skeleton && <SupermarketCardLayoutSkeleton />}
            {!props.skeleton && <SupermarketCardLayout {...props} />}
        </React.Fragment>
    );
};

SupermarketCardContainer.Layout = SupermarketCardLayout;
