import React from 'react';
import { Button, FlexBox, Modal, Text, usePyatka } from 'pyatka-ui';
import { useI18n } from 'hooks/useI18n';

interface PromptModalProps {
    open: boolean;
    onClose(): void;
    title: string;
    description?: string;
    noTxt?: string;
    yesTxt?: string;
    onSubmit(): void;
}

const PromptModal: React.FC<PromptModalProps> = ({
    open,
    onClose,
    title,
    description = '',
    noTxt = 'no',
    yesTxt = 'yes',
    onSubmit,
}) => {
    const { $t } = useI18n();
    const { scales } = usePyatka();

    const btnStyles = {
        flex: scales?.isMobile ? 1 : 'auto',
        fontWeight: 500,
        lineHeight: '23.4px',
    };

    return (
        <Modal
            open={open}
            onClose={onClose}
            swipeable={false}
            padding={scales?.isMobile ? '0px' : '20px'}
            width={scales?.isMobile ? '270px' : '407px'}
            borderRadius={scales?.isMobile ? undefined : '40px'}
        >
            <FlexBox
                p="16px"
                alignItems="center"
                flexDirection="column"
                borderBottom="2px solid rgba(190, 190, 182, 0.2)"
            >
                <Text
                    fontWeight={700}
                    textAlign="center"
                    mb={{ default: '0px', md: '12px' }}
                    fontSize={{ default: '18px', md: '26px' }}
                    lineHeight={{ default: '23.4px', md: '33.28px' }}
                >
                    {$t(title)}
                </Text>
                {description.length > 0 && (
                    <Text
                        fontWeight={500}
                        textAlign="center"
                        color="text.secondary"
                        mb={{ default: '0px', md: '18px' }}
                        fontSize={{ default: '14px', md: '18px' }}
                        lineHeight={{ default: '18.2px', md: '23.4px' }}
                    >
                        {$t(description)}
                    </Text>
                )}
            </FlexBox>
            <FlexBox
                gap={scales?.isMobile ? '0px' : '8px'}
                flexDirection={{ default: 'row', md: 'column-reverse' }}
            >
                <Button
                    border="none"
                    onClick={onClose}
                    padding="11px 18px 11px 18px"
                    height={{ default: 'auto', md: '60px' }}
                    fontSize={{ default: '18px', md: '20px' }}
                    borderRadius={{ default: '0px', md: '24px' }}
                    bg={{ default: 'white', md: 'block.secondary' }}
                    borderRight={{ default: '2px solid rgba(190, 190, 182, 0.2)', md: 'none' }}
                    style={btnStyles}
                >
                    {$t(noTxt)}
                </Button>
                <Button
                    border="none"
                    onClick={onSubmit}
                    padding="11px 18px 11px 18px"
                    height={{ default: 'auto', md: '60px' }}
                    fontSize={{ default: '18px', md: '20px' }}
                    bg={{ default: 'white', md: '#FF7348' }}
                    color={{ default: '#FF4810', md: 'white' }}
                    borderRadius={{ default: '0px', md: '24px' }}
                    style={btnStyles}
                >
                    {$t(yesTxt)}
                </Button>
            </FlexBox>
        </Modal>
    );
};

export default React.memo(PromptModal);
