import React from 'react';
import { Box, Button, FlexBox, Icon, LoadingIndicator, Space, Text } from 'pyatka-ui';
import { useI18n } from 'hooks/useI18n';
import { StatusWrapperProps } from 'pages/ClientOrderDetailPage/types';

export const ReorderButton = (props: {
    width?: any;
    isRepeatOrderLoading: any;
    repeatOrder: ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void) | undefined;
}) => {
    const { $t } = useI18n();
    return (
        <>
            {props.isRepeatOrderLoading && <LoadingIndicator />}
            {!props.isRepeatOrderLoading && (
                <Button
                    backgroundColor={'#FF4810'}
                    color={'white'}
                    borderRadius={'29px'}
                    border={'none'}
                    onClick={props.repeatOrder}
                    p={'9px 12px 9px'}
                    width={props.width}
                    height={'40px'}
                >
                    <Text fontSize={{ default: '11px' }}>{$t('reorder_button')}</Text>
                </Button>
            )}
        </>
    );
};

export const CancelOrderButton = (props: {
    onCancelClick: ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void) | undefined;
}) => {
    const { $t } = useI18n();
    return (
        <>
            <Button
                borderRadius={'26px'}
                backgroundColor={'block.secondary'}
                border={'none'}
                p={'13px 15px'}
                width={'100%'}
                buttonPrefix={<Icon icon={'cancel_order_white'} />}
                onClick={props.onCancelClick}
            >
                <Text color={'text.main'} fontSize={{ default: '11px' }}>
                    {$t('cancel_order_button')}
                </Text>
            </Button>
        </>
    );
};

export const PriceWrapper = (props: { title: string; price: any }) => {
    // console.log('props wrapper', props);
    return (
        <FlexBox>
            {props.title === 'скидка' && <Text color={'text.secondary'}>-</Text>}
            {props.title === 'доставка' && <Text color={'text.secondary'}>+</Text>}
            <FlexBox flexDirection={'column'} gap={'1px'}>
                <Box p={{ default: '1px 8px' }} borderRadius={'14px'} backgroundColor={'#F6F6F5'}>
                    <Text
                        fontSize={{
                            default: '15px',
                        }}
                        backgroundColor={'#F6F6F5'}
                        p={'1px 8px'}
                        borderRadius={'14px'}
                        color={props.title === 'скидка' ? 'text.brand' : 'text.secondary'}
                        fontWeight={'600'}
                    >
                        {props.price} <span style={{ opacity: '0.5' }}>₸</span>
                    </Text>
                </Box>
                <Text
                    fontSize={{
                        default: '9px',
                    }}
                    color={'text.secondary'}
                >
                    {props.title}
                </Text>
            </FlexBox>
        </FlexBox>
    );
};

export const StatusWrapper = (props: StatusWrapperProps) => {
    const { $t } = useI18n();
    return (
        <FlexBox
            gap={'16px'}
            alignItems={'center'}
            justifyContent={'space-between'}
            width={props.width}
            flexDirection={props.flexDirection}
        >
            <FlexBox
                backgroundColor={props.backgroundColor}
                width={'46px'}
                height={'46px'}
                borderRadius={'50%'}
                position={'relative'}
                alignItems={'center'}
                justifyContent={'center'}
            >
                <Icon
                    icon={props?.icon || 'none'}
                    style={{
                        position: 'absolute',
                        margin: 'auto',
                        left: 0,
                        top: 0,
                        bottom: 0,
                        right: 0,
                    }}
                />
            </FlexBox>
            {props.spaceHeight && <Space height={props.spaceHeight} />}
            <FlexBox flexDirection={'column'} gap={'4px'}>
                <Text
                    color={props.textColor}
                    fontSize={{ default: props.fontSize }}
                    lineClamp={props.lineClamp}
                    fontWeight={props.textWeight}
                >
                    {$t(props.text ? props.text : '')}
                </Text>
                {props.description && (
                    <Text
                        color={props.descriptionColor}
                        fontSize={{ default: props.descriptionFontSize }}
                        lineClamp={props.lineClamp}
                    >
                        {$t(props.description)}
                    </Text>
                )}
            </FlexBox>
        </FlexBox>
    );
};

export const DeliveryInfoWrapper = (props: {
    firstTitle: string;
    secondTitle: string;
    firstTextAlign?: string;
    secondTextAlign?: string;
    width?: string;
}) => {
    const { $t } = useI18n();
    return (
        <FlexBox justifyContent={'space-between'}>
            <Text
                fontSize={{
                    default: '13px',
                }}
                color={'text.placeholder'}
                textAlign={props.firstTextAlign}
            >
                {$t(props.firstTitle)}
            </Text>
            <Text
                fontSize={{
                    default: '13px',
                }}
                color={'text.main'}
                textAlign={props.secondTextAlign}
                width={props.width}
            >
                {$t(props.secondTitle)}
            </Text>
        </FlexBox>
    );
};
