import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { ICategories, SupermarketInitialState } from 'modules/Supermarkets/types';
import initialState from './Supermarkets.state';

// ======== СПИСОК СУПЕРМАРКЕТОВ ========
const fetchSupermarkets: CaseReducer<
    SupermarketInitialState,
    PayloadAction<{ section_id?: string }>
> = (_state, _action) => {};

const fetchSupermarketsSucceed: CaseReducer<
    SupermarketInitialState,
    PayloadAction<Omit<SupermarketInitialState, 'supermarket'>>
> = (state, _action) => {
    state.supermarket_list = _action.payload.supermarket_list;
    state.section = _action.payload.section;
    state.section_id = _action.payload.section_id || null;
    state.city = _action.payload.city;
    state.grouped_supermarkets = _action.payload.grouped_supermarkets;
};

const fetchSupermarketsFailed: CaseReducer<SupermarketInitialState, PayloadAction> = (
    _state,
    _action
) => {};

// ======== СТРАНИЦА СУПЕРМАРКЕТА ========
const fetchSupermarketsDetail: CaseReducer<
    SupermarketInitialState,
    PayloadAction<{ id: string; from?: SupermarketInitialState['from'] }>
> = (_state, _action) => {};

const fetchSupermarketsDetailSucceed: CaseReducer<
    SupermarketInitialState,
    PayloadAction<{ data: SupermarketInitialState['supermarket']; from: any }>
> = (state, _action) => {
    const { payload } = _action;

    state.supermarket = payload.data;
    state.from = payload.from;
};

const fetchSupermarketsDetailFailed: CaseReducer<SupermarketInitialState, PayloadAction> = (
    _state,
    _action
) => {};

/* ------------------------- Поиск по супермаркетам ------------------------- */
const fetchSupermarketsSearch: CaseReducer<
    SupermarketInitialState,
    PayloadAction<{ query: string }>
> = (_state, _action) => {};

const fetchSupermarketsSearchSucceed: CaseReducer<
    SupermarketInitialState,
    PayloadAction<SupermarketInitialState['supermarket_search']>
> = (state, _action) => {
    const { payload } = _action;
    const { list = [], query } = payload;

    state.supermarket_search = {
        list: list.sort((a, b) => b.products.length - a.products.length),
        query,
    };
};

const fetchSupermarketsSearchFailed: CaseReducer<SupermarketInitialState, PayloadAction> = (
    _state,
    _action
) => {};

const fetchSupermarketsSearchFlush: CaseReducer<SupermarketInitialState> = (_state) => {
    _state.supermarket_search = initialState.supermarket_search;
};

const fetchReccomendations: CaseReducer<
    SupermarketInitialState,
    PayloadAction<{
        store_id: string;
        appsflyer_id: string;
    }>
> = (_state, _action) => {};

const fetchReccomendationsSucceed: CaseReducer<
    SupermarketInitialState,
    PayloadAction<{ title: string; list: any[] }>
> = (_state, _action) => {
    _state.recommendations = _action?.payload;
};

const fetchReccomendationsFailed: CaseReducer<SupermarketInitialState, PayloadAction> = (
    _state,
    _action
) => {};

const fetchSuperCategories: CaseReducer<
    SupermarketInitialState,
    PayloadAction<{
        store_id: string;
        appsflyer_id: string;
    }>
> = (_state, _action) => {};

const fetchSuperCategoriesSucceed: CaseReducer<
    SupermarketInitialState,
    PayloadAction<ICategories[]>
> = (_state, _action) => {
    _state.categories = _action?.payload;
};

const fetchSuperCategoriesFailed: CaseReducer<SupermarketInitialState, PayloadAction> = (
    _state,
    _action
) => {};

export default {
    fetchSupermarketsFailed,
    fetchSupermarketsSucceed,
    fetchSupermarkets,
    fetchSupermarketsDetail,
    fetchSupermarketsDetailSucceed,
    fetchSupermarketsDetailFailed,

    fetchSupermarketsSearch,
    fetchSupermarketsSearchSucceed,
    fetchSupermarketsSearchFailed,
    fetchSupermarketsSearchFlush,

    fetchReccomendations,
    fetchReccomendationsSucceed,
    fetchReccomendationsFailed,

    fetchSuperCategories,
    fetchSuperCategoriesSucceed,
    fetchSuperCategoriesFailed,
};

// Helpers
const supermarketItemsFormatter = (items: any[]) =>
    items?.map((item) => ({
        ...item,
        title: item.product,
        price: item.base_price,
        discountPrice: item.discount ? item.price : null,
        labels: item.power_labels,
        image: item.main_pair?.detailed?.image_path || null,
        id: item.product_id,
        offer_id: item.min_price_product_id ? item.min_price_product_id : item.product_id,
    })) || [];
