import { CaseReducer, current, PayloadAction } from '@reduxjs/toolkit';
import { NewMainPageInitialState } from 'modules/NewMainPage/types';
import { formatUrl, MainPageSectionUtils } from './utils';

const NewMainPageInit: CaseReducer<
    NewMainPageInitialState,
    PayloadAction<{ ssr: NewMainPageInitialState['ssrMode'] }>
> = () => {};

const NewMainPageSucceed: CaseReducer<
    NewMainPageInitialState,
    PayloadAction<{
        data: NewMainPageInitialState['sections'];
        ssrMode: NewMainPageInitialState['ssrMode'];
        prevCityId: NewMainPageInitialState['prevCityId'];
    }>
> = (state, { payload }) => {
    const sections = [...payload.data];
    const sectionsResult = sections
        .filter((section) => section && section?.type !== undefined)
        .map((section) => {
            const { type, order } = section;
            const current_section = { ...section };
            const result = { type, data: [], order };
            const util = MainPageSectionUtils[type.toString()];
            if (type === 'section_menu_links') {
                current_section.data = { menu_items: [] };
                return result;
            }
            if (util) {
                result.data = util(current_section);
            }
            return result;
        });
    state.sections = [...current(state.sections), ...sectionsResult];
    state.ssrMode = payload.ssrMode;
    state.prevCityId = payload.prevCityId;
};

const NewMainPageFailed: CaseReducer<NewMainPageInitialState, PayloadAction> = () => {};

// Page Menu

const NewMainPageMenuInit: CaseReducer<NewMainPageInitialState, PayloadAction> = () => {};

const NewMainPageMenuSucceed: CaseReducer<NewMainPageInitialState, PayloadAction<any[]>> = (
    state,
    { payload = [] }
) => {
    const data = {
        menu_items: payload.map((menu_item: any) => ({
            ...menu_item,
            image_url: menu_item.image,
            url: formatUrl(menu_item.link),
        })),
    };

    state.mainMenu = MainPageSectionUtils.section_menu_links({ data });
};

const NewMainPageMenuFailed: CaseReducer<NewMainPageInitialState, PayloadAction> = () => {};

const NewMainPageMenuFlush: CaseReducer<NewMainPageInitialState, PayloadAction> = (state) => {
    state.sections = [];
};

export default {
    NewMainPageFailed,
    NewMainPageSucceed,
    NewMainPageInit,
    NewMainPageMenuInit,
    NewMainPageMenuSucceed,
    NewMainPageMenuFailed,
    NewMainPageMenuFlush,
};
