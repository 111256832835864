import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Box, FlexBox, Text } from 'pyatka-ui';
import { useI18n } from 'hooks/useI18n';
interface OrderCardHeaderProps {
    orderId?: number;
    timestamp?: string;
}
const OrderCardHeader = (props: OrderCardHeaderProps) => {
    const { orderId, timestamp } = props;
    const { $t } = useI18n();

    return (
        <>
            <Helmet>
                <title>Профиль</title>
            </Helmet>
            <FlexBox alignItems={'center'} gap={'4px'}>
                <Box padding={1} borderRadius={24} bg={'block.secondary'} className={'p-1'}>
                    <Text fontSize={'11px'} color={'text.placeholder'} fontWeight={'bold'}>
                        N {orderId}
                    </Text>
                </Box>
                {/*</Col>*/}
                <Box className={'d-flex align-items-center ml-2'}>
                    <Text
                        color={'text.placeholder'}
                        fontSize={{
                            default: '11px',
                            sm: '11px',
                        }}
                    >
                        {$t('from').toLowerCase()} {timestamp}
                    </Text>
                </Box>
            </FlexBox>
        </>
    );
};

export default OrderCardHeader;
