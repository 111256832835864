import React from 'react';
import { Box, Button, FlexBox, Icon, Text } from 'pyatka-ui';
import { AppLink } from 'components';
import { useI18n } from 'hooks/useI18n';

export const BrandNavigation: React.FC = () => {
    const { $t } = useI18n();
    return (
        <FlexBox justifyContent="space-between" gap="10px">
            <BrandIcon />
            <AppLink to="/" ariaLabel={`Jusan ${$t('shop')}`}>
                <FlexBox alignItems="center" gap="12px">
                    <JmartIcon />
                    <Text
                        color="text.brand_variant"
                        fontSize="26px"
                        fontWeight={600}
                        lineHeight="33.28px"
                    >
                        {$t('shop')}
                    </Text>
                </FlexBox>
            </AppLink>
        </FlexBox>
    );
};

export const CatalogButton: React.FC = () => {
    const { $t } = useI18n();

    return (
        <AppLink to="/catalog" ariaLabel={$t('catalog').toCapitalizeFirstLetter()}>
            <Button
                px="16px"
                borderRadius="999rem"
                buttonPrefix={<CatalogIcon />}
                styleType="contained"
                bg="block.brand_variant"
                height="48px"
            >
                <Text
                    mr="4px"
                    ml="-3px"
                    color="white"
                    fontSize="20px"
                    fontWeight={600}
                    lineHeight="26px"
                >
                    {$t('catalog').toCapitalizeFirstLetter()}
                </Text>
            </Button>
        </AppLink>
    );
};

const BrandIcon = () => (
    <BoxWrapper bg="block.brand">
        <Icon icon="jysan_white" width="31px" height="31px" />
    </BoxWrapper>
);

const JmartIcon = () => (
    <BoxWrapper bg="block.brand_variant" gridRow={1}>
        <Icon icon="ux-mart" width="31px" height="31px" />
    </BoxWrapper>
);

const CatalogIcon = () => <Icon icon="catalog-btn-desktop" width="31px" height="31px" />;

const BoxWrapper = (props: any) => (
    <Box p="8.5px" borderRadius={16} {...props}>
        {props.children}
    </Box>
);
