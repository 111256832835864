import { WishlistInitialState } from 'modules/Wishlist/types';

const state: WishlistInitialState = {
    wishlistProducts: [],
    pagination: {},
    wishlistClearModal: false,
    wishlistProductIds: {},
    mobile_collection_from_overlay: false,
};
export default state;
