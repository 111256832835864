import React, { FC, memo } from 'react';
import { Helmet } from 'react-helmet-async';
import { useAppRouter } from 'router/context';
import { stripHtml } from 'utils/platform';

const ProductBreadcrumbsSeo: FC<{ path_names: any[] | null }> = ({ path_names }) => {
    const { generateRouteTO }: any = useAppRouter();

    if (!!path_names && path_names.length) {
        return (
            <Helmet>
                <script type="application/ld+json">
                    {JSON.stringify({
                        '@context': 'https://schema.org',
                        '@type': 'BreadcrumbList',
                        'itemListElement': path_names.map((path, index) => ({
                            '@type': 'ListItem',
                            'position': index + 1,
                            'name': stripHtml(path.title),
                            // eslint-disable-next-line security/detect-non-literal-fs-filename
                            'item': 'https://jmart.kz' + stripHtml(generateRouteTO(path.link)),
                        })),
                    })}
                </script>
            </Helmet>
        );
    }

    return null;
};

export default memo(ProductBreadcrumbsSeo);
