import { ApiGwRequest } from 'services';
import { NEW_MAIN_PAGE_GET_REQ_INDICATOR } from 'keys';
import { NewMainPageInitialState } from 'modules/NewMainPage/types';

export const NewMainPageFetch = (data: any) => {
    return ApiGwRequest({
        indicator: NEW_MAIN_PAGE_GET_REQ_INDICATOR,
        url: 'main-page/v2/home',
        method: 'get',
        params: {
            ...data,
        },
    });
};

export const NewMainPageMenuFetch = (data: any) => {
    return ApiGwRequest({
        indicator: NEW_MAIN_PAGE_GET_REQ_INDICATOR,
        url: 'main-page/v2/main-menu',
        method: 'get',
        params: {
            ...data,
        },
    });
};
