import { AppRouteProps } from 'router/types';
import { NewHeaderConnectProps } from 'components/NewHeader/NewHeader.Connect';

export interface NewHeaderProps extends NewHeaderConnectProps {
    route: AppRouteProps;
}

export interface MobileLayoutProps extends NewHeaderConnectProps {
    route: AppRouteProps;
}

export interface IconProps {
    fill?: string | string[];
    icon?: string;
}

export enum HeaderLayoutEnum {
    MAIN_HEADER = 'main',
    PROFILE_HEADER = 'profile',

    STORE_HEADER = 'store',
    SEARCH_HEADER = 'search-header',
    COMPANY_HEADER = 'company-header',
    LISTING_HEADER = 'listing-header',
    PRODUCT_DETAIL_HEADER = 'product-detail',
    CATALOG_HEADER = 'catalog-header',

    WISHLIST_HEADER = 'wishlist-header',
    WISHLIST_COLLECTION_HEADER = 'wishlist-collection-header',

    DEFAULT_WITHOUT_SEARCH = 'default_without_search',
}
