/* eslint-disable react/display-name */
import React, { FC } from 'react';
import { Box, Collapse, FlexBox, Space, Text } from 'pyatka-ui';
import { ShowMoreButton } from 'components';
import { ISpec } from 'pages/NewProductDetailPage/types';
import { IUseScrollTo } from 'hooks/useScrollTo';
import { useI18n } from 'hooks/useI18n';
import { isDescriptionEmpty } from '../utils/isDescriptionEmpty';

const SKELETON_PROPS = { animation: true };

const descriptionStyle = {
    lineHeight: 28,
    lineClamp: 2,
    mt: 18,
};

const initHeight = descriptionStyle.lineHeight * descriptionStyle.lineClamp + descriptionStyle.mt;

const ProductDescription: FC<IProductDescription> = ({
    description = '',
    specifications = [],
    scrollHelper,
    isLoading,
}) => {
    const [showDescription, setShowDescription] = React.useState(false);
    const [showSpec, setShowSpec] = React.useState(false);
    const specRef = React.useRef<HTMLDListElement>(null);
    const { $t } = useI18n();

    const toggleDescription = () => setShowDescription((prev) => !prev);
    const toggleSpec = () => setShowSpec((prev) => !prev);

    return (
        <Box
            ref={scrollHelper?.ref}
            bg="block.main"
            borderRadius={{ default: 0, md: '24px' }}
            padding={{ default: '24px', md: '24px 0' }}
            margin={{ default: '24px -18px 0 -18px', md: '0' }}
        >
            {!isDescriptionEmpty(description) && (
                <React.Fragment>
                    <Title isLoading={isLoading}>
                        {$t('description').toCapitalizeFirstLetter()}{' '}
                    </Title>
                    <Collapse open={showDescription} initialHeight={`${initHeight}px`}>
                        {!isLoading && (
                            <Text
                                mt={`${descriptionStyle.mt}px`}
                                color="text.primary"
                                fontWeight={{ md: 500 }}
                                fontSize={{ default: 3, md: '18px' }}
                                lineHeight={`${descriptionStyle.lineHeight}px`}
                                lineClamp={showDescription ? undefined : descriptionStyle.lineClamp}
                                dangerouslySetInnerHTML={{
                                    __html: description,
                                }}
                                // @ts-ignore
                                as="p"
                            />
                            //         <div style={{ columns: 2, columnGap: 36 }}>
                            //             <div
                            //                 dangerouslySetInnerHTML={{
                            //                     __html: description,
                            //                 }}
                            //             />
                            //         </div>
                            //     </Text>
                        )}
                    </Collapse>
                    {!isLoading && (
                        <ShowMore collapsed={showDescription} toggleCollapse={toggleDescription}>
                            {!showDescription ? $t('full_description') : $t('hide')}
                        </ShowMore>
                    )}
                    <Space height="36px" />
                </React.Fragment>
            )}

            <Collapse
                open={showSpec}
                initialHeight={`${showSpec ? specRef.current?.clientHeight : 210}px`}
            >
                <Title isLoading={isLoading}>
                    {$t('characteristics').toCapitalizeFirstLetter()}
                </Title>
                <SpecList
                    isLoading={isLoading}
                    ref={specRef}
                    list={
                        isLoading
                            ? new Array(6).fill('1')
                            : showSpec
                            ? specifications
                            : specifications.slice(0, 4)
                    }
                />
            </Collapse>
            {!isLoading && (
                <ShowMore collapsed={showSpec} toggleCollapse={toggleSpec}>
                    {$t(!showSpec ? 'all_features' : 'hide')}
                </ShowMore>
            )}
        </Box>
    );
};

// Section Title
const Title: FC<{ isLoading: boolean; children: React.ReactNode }> = ({ children, isLoading }) => (
    <Text
        //@ts-ignore
        as="h2"
        mb={3}
        color="text.main"
        fontWeight="bold"
        skeleton={isLoading}
        skeletonProps={SKELETON_PROPS}
        fontSize={{ default: 5, md: '26px' }}
    >
        {children}
    </Text>
);

// Show more button
interface ShowMoreProps {
    collapsed: boolean;
    toggleCollapse: () => void;
    children: React.ReactNode;
}

const ShowMore: FC<ShowMoreProps> = ({ collapsed, toggleCollapse, children }) => (
    <Box
        mt={{
            default: 3,
            md: '26px',
        }}
    >
        <ShowMoreButton
            fontWeight={500}
            bg="block.secondary"
            collapsed={collapsed}
            p={{ md: '6px 12px' }}
            toggleCollapse={toggleCollapse}
        >
            {children}
        </ShowMoreButton>
    </Box>
);

// Product specifications list
export const SpecList = React.forwardRef<
    HTMLDListElement,
    { list: ISpec[]; specClamp?: number; isLoading: boolean; oneLine?: boolean }
>(({ list, isLoading, oneLine = false, specClamp }, ref) => {
    const flexWrap = oneLine ? 'nowrap' : 'wrap';
    const flexDirection = oneLine ? 'column' : 'row';
    const alignItems = oneLine ? 'flex-start' : 'center';
    return (
        <FlexBox
            ref={ref}
            as="dl"
            gap="16px 60px"
            flexWrap={{
                default: 'inherit',
                md: flexWrap,
            }}
            alignItems={{
                default: 'inherit',
                md: alignItems,
            }}
            mt={{ default: '14px', md: '20px' }}
            flexDirection={{
                default: 'column',
                md: flexDirection,
            }}
        >
            {list.map((spec: ISpec, idx: number) => (
                <SpecItem
                    key={idx}
                    {...spec}
                    isLoading={isLoading}
                    oneLine={oneLine}
                    specClamp={specClamp}
                />
            ))}
        </FlexBox>
    );
});

// Product specification
const SpecItem: FC<ISpec & { isLoading: boolean; oneLine?: boolean; specClamp?: number }> = ({
    oneLine = false,
    title,
    value,
    isLoading,
    specClamp,
}) => {
    const width = oneLine ? '100%' : 'auto';
    const flex = oneLine ? '1' : '0 0 50%';
    const ddStyle = oneLine ? { flex: 1 } : {};

    return (
        <FlexBox flex="calc(50% - 60px)" alignItems="flex-start" width={width}>
            <FlexBox flex={flex} alignItems="flex-end" as="dt">
                <Text
                    // @ts-ignore
                    as="span"
                    margin={0}
                    color="text.secondary"
                    lineHeight="24px"
                    skeleton={isLoading}
                    skeletonProps={SKELETON_PROPS}
                    fontWeight={{ default: 400, md: 600 }}
                    fontSize={{ default: '15px', md: '18px' }}
                >
                    {title}
                </Text>
                <FlexBox
                    flex="1"
                    ml="5px"
                    mb="6px"
                    height={'2px'}
                    backgroundImage="linear-gradient(to right, #bebeb666 33%, rgba(255, 255, 255, 0) 0%)"
                    backgroundPosition="bottom"
                    backgroundSize="6px 1px"
                    backgroundRepeat="repeat-x"
                />
            </FlexBox>
            <Text
                // @ts-ignore
                as="dd"
                ml={2}
                mb={0}
                color="text.main"
                lineHeight="24px"
                lineClamp={specClamp}
                skeleton={isLoading}
                skeletonProps={SKELETON_PROPS}
                fontWeight={{ md: 500 }}
                fontSize={{ default: '15px', md: '18px' }}
                style={ddStyle}
            >
                {value}
            </Text>
        </FlexBox>
    );
};

interface IProductDescription {
    description: string;
    specifications: ISpec[];
    scrollHelper?: IUseScrollTo;
    isLoading: boolean;
}

export default ProductDescription;
