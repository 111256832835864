import { IReviewConfig } from 'modules/ReviewService/types';
import { Validation } from 'hooks/useForm';

export const ReviewConfig: IReviewConfig = {
    rate: {
        name: 'rate',
        type: 'rate',
        placeholder: '',
        icon: '',
    },
    photo: {
        name: 'photo',
        type: 'photo',
        placeholder: '',
        icon: '',
    },
    comment: {
        name: 'comment',
        type: 'text',
        placeholder: 'Какие еще впечатления',
        icon: 'comment',
        title: 'Ваш комментарий',
    },
    dignity: {
        name: 'dignity',
        type: 'text',
        placeholder: 'Что вам понравилось',
        icon: 'plus-background',
        title: 'Достоинства',
    },
    limitations: {
        name: 'limitations',
        type: 'text',
        placeholder: 'Что не очень',
        icon: 'minus-background',
        title: 'Недостатки',
    },
    //TODO: there is no this field in backend
    // TODO: label-l-l-l
    is_recommended: {
        name: 'is_recommended',
        label: 'Рекомендую к покупке',
        type: 'switch',
        placeholder: '',
        icon: 'recommend-on',
    },
};

export const ReviewValidators: Validation = {
    rate: {
        required: true,
        rules: ['onReviewRate'],
    },
    comment: {
        required: false,
    },
    dignity: {
        required: false,
    },
    limitations: {
        required: false,
    },
    is_recommended: {
        required: false,
    },
};
