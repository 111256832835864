import React from 'react';
import { Container, Space, Text } from 'pyatka-ui';
import { useI18n } from 'hooks/useI18n';
import techProblems from './assets/tech_problems.png';
import { TemporaryProblemLayoutProps } from './types';
// import styles from './TemporaryProblem.module.scss';

export const TemporaryProblemLayout: React.FC<TemporaryProblemLayoutProps> = () => {
    const { $t } = useI18n();

    return (
        <React.Fragment>
            <Container>
                <div
                    style={{
                        backgroundImage: `url(${techProblems})`,
                        height: '194px',
                        backgroundSize: 'contain',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                    }}
                />
                {/*<div style={{ minWidth: '194px' }}>*/}
                {/*    <Image src={techProblems} />*/}
                {/*</div>*/}
                <Space height={'25px'} />
                <div className={'text-center'}>
                    <Text style={{ fontSize: '29px', fontWeight: '700', lineHeight: '34px' }}>
                        {$t('everything_will_work_soon')}
                    </Text>
                    <Space height={'4px'} />
                    <Text color={'#898D94'}> {$t('technical_problems')}</Text>
                    <Space height={'4px'} />
                    <Text color={'#898D94'} style={{ lineHeight: '14px' }}>
                        {$t('we_actively_working_on_fix')}
                    </Text>
                </div>
            </Container>
        </React.Fragment>
    );
};
