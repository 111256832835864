import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import {
    ProductsListFailed,
    ProductsListFiltersFlush,
    ProductsListFiltersFlushSucceed,
    ProductsListFlush,
    ProductsListInit,
    ProductsListSeoFailed,
    ProductsListSeoInit,
    ProductsListSeoSucceed,
    ProductsListSucceed,
    flushParentCategory,
} from 'modules/ProductsList/ProductsList.actions';
import {
    ProductsListActiveFilters,
    ProductsListData,
} from 'modules/ProductsList/ProductsList.selectors';
import {
    ProductsListSeoServices,
    ProductsListServices,
} from 'modules/ProductsList/ProductsList.services';
import { ProductsListReqPayload } from 'modules/ProductsList/types';
import { Loader } from 'plugins/loader';
import { removePageFromQS } from './utils';

function* productsListInitHandler(_action: { payload: ProductsListReqPayload }) {
    const { payload } = _action;

    const {
        isFirstRun,
        params: { category_id },
    } = payload;
    const { url: prevUrl, page, category_id: prevCatId } = yield select(ProductsListData);

    const [currentUrl, newPage = 1] = removePageFromQS(payload.url);

    if (String(prevCatId) !== String(category_id)) {
        yield put(flushParentCategory());
    }

    /* ------------------ Flush products if search url changes ------------------ */
    if (currentUrl !== prevUrl) {
        yield put(ProductsListFlush());
        // yield put(ProductsListFiltersFlush());
    }

    /* ------------------------ If same page early return ----------------------- */
    if (currentUrl === prevUrl && (payload?.persistProducts ? page >= newPage : page == newPage)) {
        return;
    }

    /* ---------------------- Other way fetch new page/url ---------------------- */
    Loader.listing.on({ global: false });

    try {
        const {
            data: { data },
        } = yield call(ProductsListServices, payload);

        yield put(
            ProductsListSucceed({
                data: { ...data, url: currentUrl, isFirstRun, category_id },
                persistProducts: payload?.persistProducts,
            })
        );
    } catch (e) {
        console.log(e);
        yield put(ProductsListFailed({}));
    }

    Loader.listing.off();
}

function* productsListSeoInitHandler(_action: { payload: any }) {
    const { payload } = _action;

    try {
        const { data } = yield call(ProductsListSeoServices, payload);

        if (data?.data?.status === 'A') {
            yield put(ProductsListSeoSucceed(data));
        }
    } catch (e) {
        yield put(ProductsListSeoFailed({}));
    }
}

function* ProductsListFiltersFlushHandler() {
    const activeFilters = yield select(ProductsListActiveFilters);

    try {
        if (Object.keys(activeFilters).length > 0) {
            yield put(ProductsListFiltersFlushSucceed());
        }
    } catch (e) {
        yield put(ProductsListSeoFailed({}));
    }
}

export default function* () {
    yield all([takeLatest(ProductsListInit, productsListInitHandler)]);
    yield all([takeLatest(ProductsListSeoInit, productsListSeoInitHandler)]);
    yield all([takeLatest(ProductsListFiltersFlush, ProductsListFiltersFlushHandler)]);
}
