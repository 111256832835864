import React, { useEffect } from 'react';
import { TooltipRefProps } from 'react-tooltip';
import CustomTooltip from 'components/AppTooltip/Tooltip';
import { IOpenTooltip } from 'plugins/tooltip';

export const AppTooltip: React.FC = () => {
    const [tooltipData, setTooltipData] = React.useState<IOpenTooltip | null>(null);

    const tooltipRef = React.useRef<TooltipRefProps>(null);

    useEffect(() => {
        const handleOpenTooltip = (e: CustomEvent<IOpenTooltip>) => {
            setTooltipData(e.detail);

            tooltipRef.current?.open();
        };

        const handleCloseTooltip = () => {
            setTooltipData(null);

            tooltipRef.current?.close();
        };

        if (__BROWSER__ && window) {
            document.addEventListener('open-Tooltip', handleOpenTooltip as EventListener, false);
            document.addEventListener('close-Tooltip', handleCloseTooltip, false);
        }

        return () => {
            document.removeEventListener('open-Tooltip', handleOpenTooltip as EventListener, false);
            document.removeEventListener('close-Tooltip', handleCloseTooltip, false);
        };
    }, []);
    return (
        <>
            <div>
                <CustomTooltip tooltipRef={tooltipRef} {...tooltipData} />
            </div>
        </>
    );
};
