export const emptyStatusLogs = [
    {
        icon: 'tennge',
        title: 'paid',
        color: 'rgba(59,59,56,0.2)',
    },
    {
        icon: 'clockk',
        title: 'in_processing',
        color: 'rgba(59,59,56,0.2)',
    },
    {
        icon: 'vann',
        title: 'delivery_in_progress',
        color: 'rgba(59,59,56,0.2)',
    },
];

export const statusColors: any = {
    pre_status: 'status.success',
    current_status: 'block.brand',
    next_status: 'text.secondary',
};
