import { connect, ConnectedProps } from 'react-redux';
import { AppRootState } from 'store/types';
import { getPathNames } from 'modules/Categories/Categories.selectors';
import { getTabLinks } from 'modules/App/App.selectors';
import { SetAuthModalStatus } from 'modules/App/App.actions';
import { TabLink } from 'modules/App/types';

const mapState = (state: AppRootState) => ({
    tabLinks: (appearance: TabLink['appearance']) => getTabLinks(appearance, state),
    path_names: getPathNames(state),
});

const mapDispatch = {
    SetAuthModalStatus,
};

export const NewTabNavigationConnector = connect(mapState, mapDispatch);
export type NewTabNavigationConnectProps = ConnectedProps<typeof NewTabNavigationConnector>;
