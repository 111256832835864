import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
    AuthorizationMiddleware,
    isErrorPage,
    middlewarePipe,
    RedirectPageMiddleware,
    ScrollToTop,
    SwipeMiddleware,
    // CheckoutDebugMiddleware,
} from 'router/middlewares';
import { AppRouteMiddlewareLayerConfig, AppRouteMiddlewareProps } from 'router/types';
import { AppRootState } from 'store/types';
import { isUserAuth } from 'modules/Authorization/Authorization.selectors';
import { getAppErrorPageInfo } from 'modules/App/App.selectors';
import { getSelectedCityId } from 'modules/Cities/Cities.selectors';
import { isQrTrigger } from 'utils/platform';
import { isClientDebug } from 'modules/UserProfileService/UserProfileService.selectors';
import AppSubRoutes from './AppSubRoutes';

const pipe = middlewarePipe([
    isErrorPage,
    RedirectPageMiddleware,
    AuthorizationMiddleware,
    // AppErrorPageMiddleware
    // CheckoutDebugMiddleware,
    ScrollToTop,
    SwipeMiddleware,
]);

const mapState = (state: AppRootState) => ({
    isUserAuth: isUserAuth(state),
    isClientDebug: isClientDebug(state),
    errorPage: getAppErrorPageInfo(state),
    cityId: getSelectedCityId(state),
});

export const AppRouteMiddlewareContainer: React.FC<AppRouteMiddlewareProps> = (props) => {
    const { isUserAuth, errorPage, cityId, mobileOnly, isClientDebug } = props;
    const history = useHistory();

    const routerConfig = React.useMemo(() => {
        const middlewareRouterConfig: AppRouteMiddlewareLayerConfig = {
            history,
            route: props,
            isUserAuth,
            errorPage,
            cityId,
            isClientDebug,
        };
        return pipe(middlewareRouterConfig);
    }, [cityId, errorPage, history, isClientDebug, isUserAuth, props]);

    // DESKTOP QR CHECK
    if (mobileOnly && isQrTrigger) {
        history.push('/');
    }

    return <AppSubRoutes config={routerConfig} />;
};

export const AppRouteMiddleware = React.memo(connect(mapState)(AppRouteMiddlewareContainer));
