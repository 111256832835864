import React from 'react';
import { CategoryCardLayout } from 'components/CategoryCard/CategoryCard.Layout';
import { CategoryCardLayoutSkeleton } from 'components/CategoryCard/CategoryCard.LayoutSkeleton';
import { CategoryCardProps } from 'components/CategoryCard/types';

export interface CategoryCardState {}

export const CategoryCardContainer: React.FC<CategoryCardProps> = (props) => {
    const layoutProps = {
        ...props,
    };
    return (
        <React.Fragment>
            {props.skeleton && <CategoryCardLayoutSkeleton />}
            {!props.skeleton && <CategoryCardLayout {...layoutProps} />}
        </React.Fragment>
    );
};
