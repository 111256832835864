import { IProduct } from '../types';

export const EMPTY_PRODUCT: IProduct = {
    labels: {},
    min_id: 0,
    meta: {
        title: '',
        seo_desc: '',
    },
    promo_text: '',
    seo_name: '',
    type: '',
    image: '',
    offer_id: 0,
    id: 0,
    price: '',
    brand: '',
    title: '',
    feature_variations: [],
    category_ids: [],
    is_for_adult: false,
    is_degree_high: false,
    is_degree_low: false,
};

export const EMPTY_VARIANT = {
    product_id: 0,
    variant: '',
    variant_id: 0,
};

export const EMPTY_PRODUCT_CARD = {
    label: {},
    price: '',
    discountPrice: '',
    productName: '',
    image: '',
    url: '',
    isInCart: false,
    addToCartHandler: {},
    isLoading: true,
};
