import styled from 'styled-components/macro';
import { FlexBox, Text, Image } from 'pyatka-ui';

export const Card = styled(FlexBox)<{ isMobile?: boolean }>`
    background: #fff;
    border-radius: ${(props) => (props.isMobile ? '26px' : '30px')};
    padding: 20px 24px 24px;
    flex-direction: column;
`;
export const StatusItemWrapper = styled(FlexBox)`
    justify-content: center;
    align-items: center;
    align-self: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
`;
export const StatusItemTitle = styled(Text)`
    position: absolute;
    top: 100%;
    //left: -50%;
`;
export const ProductItemInfoWrapper = styled(FlexBox)<{ isLast?: boolean; isActual?: boolean }>`
    filter: ${(props) => (props?.isActual ? 'opacity(100%)' : 'opacity(25%)')};
    border-bottom: 1.1px solid;
    border-color: ${(props) => (props?.isLast ? 'transparent' : '#BEBEB633')};
    padding-bottom: ${(props) => (props?.isLast ? '0' : '10px')};
`;
export const StyledImage = styled(Image)<{ isMobile?: boolean }>`
    width: ${(props) => (props.isMobile ? '36px' : '40px')};
    height: ${(props) => (props.isMobile ? '36px' : '40px')};
`;
