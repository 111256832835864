import { CaseReducer, current, PayloadAction } from '@reduxjs/toolkit';
import { ProductsListInitialState, ProductsListReqPayload } from 'modules/ProductsList/types';
import { initialData } from './ProductsList.state';
import { getActiveFiltersQueryString, toObjectVariants } from './utils';

const ProductsListInit: CaseReducer<
    ProductsListInitialState,
    PayloadAction<ProductsListReqPayload>
> = (_state, _action) => {};

const ProductsListSucceed: CaseReducer<ProductsListInitialState, PayloadAction<{}>> = (
    _state,
    _action: any
) => {
    const { page } = _action?.payload?.data;
    const isFirstPage: boolean = page === '1';

    /* --------------------- Convert filters[] to filters{} --------------------- */
    const filtersObject: any = toObjectVariants(_action?.payload.data.filters);

    const filters_order: string[] =
        _action?.payload?.data?.filters.map((f: any) => f.feature_id) || [];

    /* ------------------------- Query string to Object ------------------------- */
    const qsObject = Object.fromEntries(new URLSearchParams(_action?.payload?.data?.url));

    // if (isFirstRun) {
    const activeFilters = getActiveFiltersQueryString(qsObject, filtersObject);

    _state.activeFilters = activeFilters;
    // if (_action?.payload?.data?.isFirstRun) {
    _state.filtersObject = filtersObject;
    _state.filters_order = filters_order;
    // }
    /* ---------------- Infinite scroll/persist Previous products --------------- */
    if (_action?.payload?.persistProducts && !isFirstPage && _state.data.products.length > 0) {
        const previousProducts = current(_state.data.products) || [];
        const newProducts = _action?.payload?.data?.products.map((p) => ({
            ...p,
            product_page: page,
        }));
        _state.data = {
            ..._action.payload.data,
            products: [...previousProducts, ...newProducts],
        };
    } else {
        _state.data = _action?.payload?.data;
    }
};

const ProductsListFailed: CaseReducer<ProductsListInitialState, PayloadAction<{}>> = (
    _state,
    _action
) => {};

const ProductsListSeoInit: CaseReducer<ProductsListInitialState, PayloadAction<any>> = (
    _state,
    _action
) => {};

const ProductsListSeoSucceed: CaseReducer<ProductsListInitialState, PayloadAction<{}>> = (
    _state,
    _action: any
) => {
    _state.seo = _action?.payload?.data;
};

const ProductsListSeoFailed: CaseReducer<ProductsListInitialState, PayloadAction<{}>> = (
    _state,
    _action
) => {
    _state.seo = null;
};

const ProductsListFlush: CaseReducer<ProductsListInitialState, PayloadAction> = (
    state,
    _action
) => {
    state.data = initialData.data;
    state.seo = initialData.seo;
    // state.filtersObject = initialData.filtersObject;
    // state.filters_order = initialData.filters_order;
    // state.activeFilters = initialData.activeFilters;
};

const ProductsListFiltersUpdate: CaseReducer<ProductsListInitialState, PayloadAction<{}>> = (
    _state,
    _action
) => {
    _state.activeFilters = _action?.payload;
};

const ProductsListFiltersFlush: CaseReducer<ProductsListInitialState, PayloadAction> = (
    _state,
    _action
) => {};

const flushParentCategory: CaseReducer<ProductsListInitialState, PayloadAction> = (
    _state,
    _action
) => {
    _state.data.parentCategory = null;
};

const ProductsListFiltersFlushSucceed: CaseReducer<ProductsListInitialState, PayloadAction> = (
    _state,
    _action
) => {
    _state.activeFilters = {};
};

export default {
    ProductsListFailed,
    ProductsListSucceed,
    ProductsListInit,
    ProductsListSeoInit,
    ProductsListSeoSucceed,
    ProductsListSeoFailed,
    ProductsListFlush,
    ProductsListFiltersUpdate,
    ProductsListFiltersFlush,
    flushParentCategory,
    ProductsListFiltersFlushSucceed,
};
