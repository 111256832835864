import React from 'react';
import { Box, Image, Text, usePyatka } from 'pyatka-ui';
import { AppLink } from 'components';
import { dispatchCategoryMetric } from 'AppMetric/helpers';
import { useAppRouter } from 'router/context';
import { useI18n } from 'hooks/useI18n';
import fallbackImg from 'shared/assets/fallback-category-img.webp';
import { CategoryBoxLayoutProps } from './types';

export const CategoryBoxLayoutNew: React.FC<CategoryBoxLayoutProps> = (props) => {
    const {
        image,
        title,
        url,
        main,
        isLoading,
        textProps,
        wrapperProps,
        appearance,
        categoryId,
        isExternal = false,
        backgroundSize,
        backgroundPosition,
    } = props;

    const { $t } = useI18n();
    const MOBILE_RESPONSIVE = '100%';
    const TABLET_RESPONSIVE = '82%';
    const DESKTOP_RESPONSIVE = '53.3%';
    const {
        theme,
        windowSize,
        scales: { isMobile },
    } = usePyatka();
    const { location } = useAppRouter();

    // ------------ image appearance
    let ratio = appearance || null;
    if (ratio === null) {
        if (windowSize.width > theme.scales[3].value) {
            ratio = DESKTOP_RESPONSIVE;
        } else if (windowSize.width > theme.scales[2].value) {
            ratio = TABLET_RESPONSIVE;
        } else {
            ratio = MOBILE_RESPONSIVE;
        }
    }

    // ----------- image background position
    const bgPosition = 'center';

    // ----------- image background size
    const bgSize = main ? 'cover' : windowSize.width > theme.scales[2].value ? 'contain' : 'cover';
    const section_id = url?.query?.section_id;

    const categoryMetricSender = React.useCallback(() => {
        dispatchCategoryMetric(categoryId || section_id, location, title, props?.category1);
    }, [title, categoryId, section_id, location]);

    return (
        <AppLink to={url} isExternal={isExternal} style={{ height: '100%', display: 'grid' }}>
            <Box
                px={'10px'}
                pb="10px"
                bg={isMobile ? '#F6F6F5' : 'white'}
                position="relative"
                borderRadius={'22px'}
                onClick={categoryMetricSender}
                {...wrapperProps}
            >
                <Box position={'relative'} top="-20px" mb="-10px">
                    <Image
                        className="image"
                        skeleton={isLoading}
                        appearance={ratio}
                        src={image}
                        fallbackImg={fallbackImg}
                        backgroundSize={backgroundSize || bgSize}
                        backgroundPosition={backgroundPosition || bgPosition}
                        effect="fadeIn"
                        new_image={true}
                    />
                </Box>

                <Text
                    fontSize={{ default: '12px', md: 3 }}
                    color={'text.main'}
                    fontWeight={'600'}
                    textAlign="center"
                    skeleton={isLoading}
                    {...textProps}
                >
                    {$t(title)}
                </Text>
            </Box>
        </AppLink>
    );
};
