import React, { useCallback, useMemo, useState } from 'react';
import {
    Box,
    Button,
    FlexBox,
    Input,
    Text,
    Textarea,
    Icon,
    Space,
    usePyatka,
    LoadingIndicator,
} from 'pyatka-ui';
import { Tooltip } from 'react-tooltip';
import {
    DeliveryBlock,
    DeliveryProductSection,
} from 'pages/CheckoutDeliveryV2Page/CheckoutDeliveryPage.LayoutDelivery';
import { Product } from 'modules/ProductsList/types';
import { AppLink, ProductLabel, ProductSection } from 'components';
import { AppToaster } from 'plugins/app-toaster';
import { signs } from 'utils/symbols';
import { useI18n } from 'hooks/useI18n';
import GiftBlock from 'pages/CheckoutDeliveryV2Page/components/GiftBlock';
import MerchInfoBlock from 'pages/CheckoutDeliveryV2Page/components/MerchInfoBlock';
import { isNative } from 'utils/platform';
import { CheckoutDeliveryPageLayoutProps } from './types';
import { PaymentBlockWrapper } from './CheckoutDeliveryPage.styles';
import CheckoutPriceInfo, {
    PriceInfo,
} from 'pages/CheckoutDeliveryV2Page/components/CheckoutPriceInfo';
// import styles from './CheckoutDeliveryPage.module.scss';

export const CheckoutDeliveryPageLayout: React.FC<CheckoutDeliveryPageLayoutProps> = (props) => {
    const { scales } = usePyatka();
    const isMobile = (scales.matched || []).find((el: any) => el.name === 'md');

    const products = props.checkout_data?.cart?.products || [];
    const checkout_data: any = props?.checkout_data;
    const deliveryPriceSection =
        checkout_data?.is_delivery &&
        checkout_data?.is_delivery_valid_address &&
        checkout_data?.shipping?.variants?.length > 0;
    return (
        <>
            <React.Fragment>
                <FlexBox
                    width={'100%'}
                    justifyContent={'space-between'}
                    flexDirection={isMobile ? 'column' : 'row'}
                    gap={isMobile ? '0' : '10px'}
                >
                    <Box width={isMobile ? '100%' : '50%'} maxWidth={isMobile ? '100%' : '50%'}>
                        {/*{isMobile && (*/}
                        {/*    <Box p={isMobile ? '10px 18px 4px' : '0'} bg={'white'}>*/}
                        {/*        <MerchInfoBlock {...checkout_data?.company} />*/}
                        {/*    </Box>*/}
                        {/*)}*/}
                        {/*<FlexBox*/}
                        {/*    flexDirection="column"*/}
                        {/*    p={isMobile ? '18px' : '0'}*/}
                        {/*    bg={'white'}*/}
                        {/*    gap={'24px'}*/}
                        {/*    borderRadius={'0 0 24px 24px'}*/}
                        {/*    mb={{ default: '0', md: '52px' }}*/}
                        {/*>*/}
                        <DeliveryBlock {...props} />
                        {/*{isMobile && <PromocodeBlock {...props} />}*/}
                        {/*<DeliveryCommentBlock {...props} />*/}
                        {/*</FlexBox>*/}
                        <FlexBox
                            mb={'24px'}
                            p={isMobile ? '12px 0' : '0'}
                            flexDirection="column"
                            gap={'24px'}
                        >
                            <FlexBox
                                flexDirection={'column'}
                                padding={isMobile ? '18px 18px 6px' : '0'}
                                borderRadius={'26px'}
                                backgroundColor={'block.main'}
                            >
                                <MerchInfoBlock {...checkout_data?.company} />
                                <Space height={'6px'} />
                                {products.map((product: Product, idx: number) => (
                                    <ProductSection
                                        key={idx}
                                        paddingBottom={deliveryPriceSection ? '15px' : '0px'}
                                        isLast={
                                            // deliveryPriceSection
                                            //     ? false
                                            //     :
                                            idx === products.length - 1
                                        }
                                        discountPrice={product?.discountPrice_formatted}
                                        title={product?.productName}
                                        image={product?.image}
                                        image_preload={product.image_preload}
                                        cartAmount={product?.amount}
                                        product_id={product?.product_id.toString()}
                                        offer_id={''}
                                        element={null}
                                        hasDiscount={Boolean(product?.discountPrice)}
                                        price={product?.price_formatted}
                                        giftBlock={
                                            Array.isArray(product?.gifts) &&
                                            Boolean(product?.gifts?.length) &&
                                            product?.gifts?.map((gift, idx) => (
                                                <GiftBlock key={idx} {...gift} />
                                            ))
                                        }
                                    />
                                ))}
                            </FlexBox>
                            {/*{deliveryPriceSection ? <DeliveryProductSection {...props} /> : ''}*/}

                            {/*{props?.checkout_data?.cart?.bonus_price ? (*/}
                            {/*    <BonusBlock*/}
                            {/*        bonus={props?.checkout_data?.cart?.bonus_price.toString()}*/}
                            {/*    />*/}
                            {/*) : (*/}
                            {/*    <></>*/}
                            {/*)}*/}
                        </FlexBox>
                        {deliveryPriceSection ? <Space height={'20px'} /> : ''}
                        {isMobile && <Space height={'200px'} />}
                    </Box>
                    <PaymentBlock {...props} />
                </FlexBox>
            </React.Fragment>
        </>
    );
};

const DeliveryCommentBlock: React.FC<CheckoutDeliveryPageLayoutProps> = (props) => {
    const { deliveryComment, setDeliveryComment } = props;
    const { $t } = useI18n();

    const handleCommentChange = (e: any) => {
        setDeliveryComment(e?.value);
    };

    return (
        <FlexBox lineHeight={'25px'}>
            <Icon icon={'comment'} height={'30px'} width={'30px'} />
            &nbsp; &nbsp;
            <Textarea
                name={'deliveryComment'}
                value={deliveryComment}
                onChange={handleCommentChange}
                placeholder={$t('comment_to_order')}
                borderBottom={'1.5px solid rgba(190, 190, 182, 0.2)'}
                rows={2}
                maxLength={500}
            />
        </FlexBox>
    );
};

const PromocodeBlock: React.FC<CheckoutDeliveryPageLayoutProps> = (props) => {
    const { $t } = useI18n();
    const [promocodeValue, setPromocode] = useState<string>(props?.promocode);
    const promocodeChangeHandler = useCallback(
        (value: string) => setPromocode(value),
        [setPromocode]
    );
    const { scales } = usePyatka();
    const isMobile = (scales.matched || []).find((el: any) => el.name === 'md');
    const applyPromocode = (e: React.SyntheticEvent) => {
        e.preventDefault();
        if (promocodeValue.length > 0) {
            props?.setPromocode(promocodeValue);
            return;
        }
        AppToaster.warning({ body: $t('enter_coupon') });
    };
    return (
        <Box mt={{ default: 0, md: 35 }}>
            <form onSubmit={applyPromocode}>
                <Input
                    fontSize={{ default: 15, md: 18 }}
                    lineHeight={'24px'}
                    fontWeight={500}
                    placeholder={$t('enter_promocode')}
                    value={promocodeValue}
                    onChange={promocodeChangeHandler}
                    borderRadius={isMobile ? '59px' : '24px'}
                    p={isMobile ? '9px 20px' : '18px 24px'}
                    pr={isMobile ? '7px' : '9px'}
                    backgroundColor={isMobile ? 'block.secondary' : '#BEBEB633'}
                    suffix={
                        <Button
                            // ml={'10px'}
                            padding={isMobile ? '8px 12px' : '12px 17px 13px 21px'}
                            border={'none'}
                            backgroundColor={'#fff'}
                            color={'#AAADB2'}
                            borderRadius={'21px'}
                            fontSize={{ default: 12, md: 16 }}
                            style={{ zIndex: 1, fontWeight: 600 }}
                            onClick={applyPromocode}
                        >
                            {$t('apply')}
                        </Button>
                    }
                />
            </form>
        </Box>
    );
};

const PaymentBlock: React.FC<CheckoutDeliveryPageLayoutProps> = (props) => {
    const { checkout_data, isValidLoanPayment } = props;
    const { $t } = useI18n();
    const { scales } = usePyatka();
    const isMobile = (scales.matched || []).find((el: any) => el.name === 'md');
    const isMobileWeb = !isNative() && isMobile;

    const getPaymentButtonTitle = (): string => {
        if (props?.isValidSubmit) {
            return isNative() ? 'go_to_payment' : isMobile ? 'by_card' : 'pay_by_card';
        } else if (!checkout_data?.is_delivery_valid_address) {
            return 'enter_address';
        } else if (checkout_data?.delivery_type === 'pickup_points') {
            return isMobileWeb ? 'select_pickup_point_crop' : 'select_pickup_point';
        }
        return isMobileWeb ? 'select_delivery_method_crop' : 'select_delivery_method';
    };
    const hasCheckoutPriceInfo =
        (checkout_data?.calculated_prices?.delivery && checkout_data?.is_delivery) ||
        checkout_data?.calculated_prices?.discount ||
        checkout_data?.calculated_prices?.base_total_price;

    const checkoutPriceInfo = useMemo(() => {
        const { calculated_prices, is_delivery, delivery_type, shipping_selected } =
            checkout_data || {};
        const { base_total_price, discount, delivery } = calculated_prices || {};
        const res: PriceInfo[] = [];

        if (base_total_price) {
            res.push({
                title: 'cost_of_products',
                price: base_total_price,
            });
        }

        if (discount) {
            res.push({
                title: 'discount',
                price: discount,
            });
        }

        if (delivery && is_delivery) {
            res.push({
                title: shipping_selected?.company === '47' ? 'delivery' : 'sellers_delivery',
                price: delivery,
                isDelivery: true,
            });
        }
        if (delivery_type && !is_delivery) {
            res.push({
                title: 'delivery',
                price: 0,
                isDelivery: true,
            });
        }

        return res;
    }, [checkout_data]);
    return (
        <>
            <PaymentBlockWrapper
                position={isMobile ? 'fixed' : 'static'}
                flexDirection={'column'}
                alignItems={'center'}
                width={isMobile ? '100%' : '446px'}
                backgroundColor={isMobile ? 'white' : '#f6f6f5'}
                borderRadius={isMobile ? '24px 24px 0 0' : '40px'}
                borderTop={'2px solid #f6f6f6'}
                height={'max-content'}
                zIndex={isMobile ? 1000 : 1}
                padding={{ default: '18px 18px 14px', md: 24 }}
            >
                {/*<Text fontSize={13} color={'text.placeholder'}>*/}
                {/*    {$t('amount_payable')}*/}
                {/*</Text>*/}
                <Text
                    fontSize={{ default: 28, md: 40 }}
                    fontWeight={600}
                    // lineHeight={'px'}
                    mb={'12px'}
                >
                    {props?.checkout_data?.calculated_prices?.total_price}{' '}
                    <span style={{ opacity: '0.5' }}>{signs.tg}</span>
                </Text>
                {(hasCheckoutPriceInfo || props?.checkout_data?.cart?.bonus_price) && (
                    <FlexBox
                        backgroundColor={isMobile ? 'block.secondary' : '#fff'}
                        borderRadius={'24px'}
                        p={'16px 26px 12px'}
                        width={'100%'}
                        flexDirection={'column'}
                    >
                        {hasCheckoutPriceInfo && (
                            <CheckoutPriceInfo priceInfo={checkoutPriceInfo} />
                        )}
                        {props?.checkout_data?.cart?.bonus_price ? (
                            <FlexBox m={'8px auto 0'} width={'100%'} justifyContent={'center'}>
                                <BonusBlock
                                    bonus={props?.checkout_data?.cart?.bonus_price.toString()}
                                />
                            </FlexBox>
                        ) : (
                            <></>
                        )}
                    </FlexBox>
                )}
                {/*<FlexBox flexDirection={'row'} gap={'5px'} alignItems={'center'}>*/}
                {/*    <FlexBox flexDirection={'column'}>*/}
                {/*        <ProductLabel variant={'saves'} hasWhiteBg={!isMobile}>*/}
                {/*            <Text color={'text.secondary'}>*/}
                {/*                {props?.checkout_data?.calculated_prices?.base_total_price}{' '}*/}
                {/*                {signs.tg}*/}
                {/*            </Text>*/}
                {/*        </ProductLabel>*/}
                {/*        <Text*/}
                {/*            color={'text.secondary'}*/}
                {/*            variant={'h9'}*/}
                {/*            textAlign={'center'}*/}
                {/*            pt={'4px'}*/}
                {/*        >*/}
                {/*            {$t('products').toLowerCase()}*/}
                {/*        </Text>*/}
                {/*    </FlexBox>*/}
                {/*    {props?.checkout_data?.calculated_prices?.delivery &&*/}
                {/*    props?.checkout_data?.is_delivery ? (*/}
                {/*        <>*/}
                {/*            <FlexBox flexDirection={'column'}>*/}
                {/*                <Text color={'text.secondary'} fontWeight={'bold'} variant={'h6'}>*/}
                {/*                    +*/}
                {/*                </Text>*/}
                {/*                <Text*/}
                {/*                    color={'text.secondary'}*/}
                {/*                    variant={'h9'}*/}
                {/*                    textAlign={'center'}*/}
                {/*                    pt={'4px'}*/}
                {/*                >*/}
                {/*                    &nbsp;*/}
                {/*                </Text>*/}
                {/*            </FlexBox>*/}
                {/*            <FlexBox flexDirection={'column'}>*/}
                {/*                <ProductLabel variant={'saves'} hasWhiteBg={!isMobile}>*/}
                {/*                    <Text color={'text.secondary'}>*/}
                {/*                        {props?.checkout_data?.calculated_prices?.delivery_price}{' '}*/}
                {/*                        {signs.tg}*/}
                {/*                    </Text>*/}
                {/*                </ProductLabel>*/}
                {/*                <Text*/}
                {/*                    color={'text.secondary'}*/}
                {/*                    variant={'h9'}*/}
                {/*                    textAlign={'center'}*/}
                {/*                    pt={'4px'}*/}
                {/*                >*/}
                {/*                    {$t('delivery').toLowerCase()}*/}
                {/*                </Text>*/}
                {/*            </FlexBox>*/}
                {/*        </>*/}
                {/*    ) : (*/}
                {/*        <></>*/}
                {/*    )}*/}
                {/*    {props?.checkout_data?.calculated_prices?.discount ? (*/}
                {/*        <>*/}
                {/*            <FlexBox flexDirection={'column'}>*/}
                {/*                <Text color={'text.secondary'} fontWeight={'bold'} variant={'h6'}>*/}
                {/*                    -*/}
                {/*                </Text>*/}
                {/*                <Text*/}
                {/*                    color={'text.secondary'}*/}
                {/*                    variant={'h9'}*/}
                {/*                    textAlign={'center'}*/}
                {/*                    pt={'4px'}*/}
                {/*                >*/}
                {/*                    &nbsp;*/}
                {/*                </Text>*/}
                {/*            </FlexBox>*/}
                {/*            <FlexBox flexDirection={'column'}>*/}
                {/*                <ProductLabel variant={'saves'} hasWhiteBg={!isMobile}>*/}
                {/*                    <Text color={'text.brand'}>*/}
                {/*                        {props?.checkout_data?.calculated_prices?.discount_price}{' '}*/}
                {/*                        {signs.tg}*/}
                {/*                    </Text>*/}
                {/*                </ProductLabel>*/}
                {/*                <Text*/}
                {/*                    color={'text.secondary'}*/}
                {/*                    variant={'h9'}*/}
                {/*                    textAlign={'center'}*/}
                {/*                    pt={'4px'}*/}
                {/*                >*/}
                {/*                    {$t('discount').toLowerCase()}*/}
                {/*                </Text>*/}
                {/*            </FlexBox>*/}
                {/*        </>*/}
                {/*    ) : (*/}
                {/*        <></>*/}
                {/*    )}*/}
                {/*</FlexBox>*/}
                {/*{!isMobile && <PromocodeBlock {...props} />}*/}

                <Space height={'24px'} />
                {/*<AgreementBlock />*/}

                <FlexBox
                    flexDirection={isMobile ? 'row' : 'column'}
                    width={'100%'}
                    gap={isMobile ? '10px' : '15px'}
                    // marginTop={'10px'}
                >
                    {!isNative() && !isValidLoanPayment && (
                        <Tooltip
                            id={'btn'}
                            openOnClick={isMobile}
                            globalCloseEvents={{ scroll: true, clickOutsideAnchor: true }}
                            style={{
                                width: 'max-content',
                                backgroundColor: '#fff',
                                color: '#201F1F',
                                padding: '10px',
                                borderRadius: '30px',
                                boxShadow: '0px 5px 24px 0px  rgba(0, 0, 0, 0.08)',
                                zIndex: 1000,
                                textAlign: 'center',
                            }}
                            opacity={1}
                        >
                            {$t('buy_loan_disabled_text')}
                        </Tooltip>
                    )}
                    {!isNative() &&
                        checkout_data?.cart?.cart_type !== 'G' &&
                        props.isValidSubmit && (
                            <Button
                                onClick={() => props?.initPayment({ isWebLoadClick: true })}
                                variant={'default'}
                                styleType={'contained'}
                                buttonSize={'large'}
                                backgroundColor={isMobile ? 'block.secondary' : '#fff'}
                                // width={isMobile ? '110%' : '100%'}
                                // width={isNative() ? '100%' : 'auto'} // Adjust width based on condition
                                minWidth={isMobile ? 'auto' : '50%'}
                                width={isMobile ? '100%' : 'auto'}
                                borderRadius={'22px'}
                                py={0}
                                disabled={
                                    !props.isValidSubmit ||
                                    props.checkoutButtonLoading ||
                                    !isValidLoanPayment
                                }
                            >
                                <Box data-tooltip-id={'btn'} py={'18px'} width="100%">
                                    <Text color={'text.main'}>
                                        {$t(isMobile ? 'pay_web_loan' : 'buy_loan')}
                                    </Text>
                                </Box>
                            </Button>
                        )}
                    <Button
                        onClick={props?.initPayment}
                        // variant={props?.isValidSubmit ? 'brand' : 'secondary'}
                        variant={'brand'}
                        // color={props?.isValidSubmit ? 'text.reverse' : 'text.secondary'}
                        color={'text.reverse'}
                        styleType={'contained'}
                        buttonSize={'large'}
                        disabled={!props.isValidSubmit || props.checkoutButtonLoading}
                        minWidth={
                            !isNative() &&
                            checkout_data?.cart?.cart_type !== 'G' &&
                            props.isValidSubmit
                                ? '50%'
                                : '100%'
                        }
                        // variation={props?.isValidSubmit ? 'brand' : 'default'}
                        // width={isMobile ? '50%' : '100%'}
                        py={{ default: '18px' }}
                        borderRadius={'22px'}
                    >
                        {props.checkoutButtonLoading ? (
                            <LoadingIndicator size={'20px'} />
                        ) : (
                            <Text color={props?.isValidSubmit ? 'text.reverse' : 'text.primary'}>
                                {$t(getPaymentButtonTitle())}
                            </Text>
                        )}
                    </Button>
                </FlexBox>
            </PaymentBlockWrapper>
        </>
    );
};

const BonusBlock: React.FC<{
    bonus: string;
}> = ({ bonus }) => {
    const { $t } = useI18n();
    return (
        <>
            <FlexBox alignItems={'center'} justifyContent={'center'} gap={'5px'}>
                <ProductLabel variant={'bonus'}>
                    {$t('before')} {bonus}%
                </ProductLabel>
                <Text color={'text.placeholder'} variant={'h8'}>
                    {$t('will_be_credited_after_14_days')}
                </Text>
            </FlexBox>
        </>
    );
};

const AgreementBlock: React.FC = () => {
    const { $t } = useI18n();
    return (
        <FlexBox gap={'12px'} alignItems={'center'}>
            <Icon icon={'dogovor'} width={'32px'} height={'28px'} />
            <Text fontSize={{ default: '11px' }} fontWeight={'400'} color={'#898D94'}>
                {$t('by_clicking_on_button_pay_agree_to_terms')}
                <AppLink
                    to={{ name: 'articles-page', params: { slug: 'oferta' } }}
                    style={{ color: '#FF4810' }}
                >
                    {$t('accession_agreements')}
                </AppLink>
            </Text>
        </FlexBox>
    );
};
