import React from 'react';
import { Box, Text, usePyatka } from 'pyatka-ui';
import { ProductCardLayoutProps } from 'components/ProductCard/types';

interface IProductDescription {
    productName: string;
    lineClamp: number;
    isLoading: boolean;
}

const ProductDescription: React.FC<
    IProductDescription & Pick<ProductCardLayoutProps, 'isZeroPrice'>
> = ({ productName, lineClamp, isLoading, isZeroPrice }) => {
    const { scales } = usePyatka();
    const line_height = scales?.isMobile ? 14 : 18;
    const line_clamp = scales?.isMobile ? 2 : lineClamp;
    const height = `${line_height * line_clamp}px`;

    return (
        <Box width="100%" height={height}>
            <Text
                // @ts-ignore
                as="h3"
                fontSize={scales?.isMobile ? '12px' : '14px'}
                color={isZeroPrice ? 'text.secondary' : 'text.main'}
                fontWeight="500"
                lineHeight={`${line_height}px`}
                lineClamp={line_clamp}
                skeleton={isLoading}
                title={productName}
                margin="0px"
                skeletonProps={{
                    animation: true,
                    width: '100%',
                    borderRadius: '12px',
                    height,
                }}
            >
                {productName}
            </Text>
        </Box>
    );
};

export default ProductDescription;
