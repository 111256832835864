import React from 'react';
import { FlexBox, Space, Text } from 'pyatka-ui';

const OrderStatus = (props: { orderStatusText: string }) => {
    const { orderStatusText } = props;
    return (
        <>
            <Space height={'8px'} />
            <FlexBox justifyContent={'between'} alignItems={'center'}>
                <Text
                    fontSize={{
                        default: '12px',
                    }}
                    // ml={'8px'}
                    color={'status.info'}
                    lineClamp={2}
                >
                    {orderStatusText}
                </Text>
            </FlexBox>
        </>
    );
};

export default OrderStatus;
