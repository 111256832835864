import { nativeCall } from 'hybrid';
import { compareVersions } from './compareVersions';
import { isNative } from './platform';
import { SWIPE_VERSION } from './versions';

let PREV_SWIPE = true;

export const handleSwipe = (path: string = '/', enabled: boolean = false) => {
    if (!isNative() || path === '/' || PREV_SWIPE === enabled) {
        return;
    }

    if (compareVersions(window?.__HYBRIDCONFIG__?.version, SWIPE_VERSION) >= 0) {
        nativeCall({
            type: 'swipe',
            payload: { enabled },
        });

        PREV_SWIPE = enabled;
    }
};
