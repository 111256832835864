import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import {
    getAllPromotions,
    getPromotionDetails,
    fetchPromotionModal,
} from 'modules/Promotions/Promotions.services';
import {
    fetchPromotionDetails,
    fetchPromotions,
    getPromotionModal,
    requestPromotionDetails,
    requestPromotions,
    getPromotionModalSucceed,
    getPromotionModalFailed,
    resetPromotionDetails,
} from 'modules/Promotions/Promotions.actions';
import { Loader } from 'plugins/loader';
import { formatUrl } from 'modules/NewMainPage/utils';
import { SetAppError } from 'modules/App/App.actions';
import { selectorPromotionsDetails } from './Promotions.selectors';
import { IFormatPromotionDetails } from './types';
import { formatterPromotions } from './utils';

function* handlerRequestPromotions() {
    Loader.promotions.on({ global: false });
    try {
        // @ts-ignore
        const result = yield call(getAllPromotions);

        const promo = formatterPromotions(result.data.data);

        yield put(fetchPromotions(promo));
    } catch (e) {
    } finally {
        Loader.promotions.off();
    }
}

function* handlerFetchPromotionDetails({ payload }: PayloadAction<string | number>) {
    try {
        const prev: IFormatPromotionDetails = yield select(selectorPromotionsDetails);

        if (String(prev?.content?.id) === String(payload)) {
            return;
        }

        yield put(resetPromotionDetails());
        Loader.promotionDetail.on({ global: false });
        const { data } = yield call(getPromotionDetails, payload);
        yield put(fetchPromotionDetails(data.data));
    } catch (e) {
        yield put(SetAppError({ ...e?.data, status: e?.status }));
    } finally {
        Loader.promotionDetail.off();
    }
}

function* handleGetPromotionModal() {
    try {
        Loader.promotionModal.on({ global: false });
        const { data } = yield call(fetchPromotionModal);

        yield put(
            getPromotionModalSucceed({
                ...data?.data?.modal,
                section_id: data?.data?.section_id,
                url: formatUrl(data?.data?.modal.url),
            })
        );
    } catch (e) {
        yield put(getPromotionModalFailed());
    } finally {
        Loader.promotionModal.off();
    }
}

export default function* () {
    yield all([
        takeLatest(requestPromotions, handlerRequestPromotions),
        takeLatest(requestPromotionDetails, handlerFetchPromotionDetails),
        takeLatest(getPromotionModal, handleGetPromotionModal),
    ]);
}
