import priceFormatter from 'utils/priceFormatter';
import { CartGroup } from 'modules/CartService/types';
import { ProductsParser } from 'modules/ProductsList/ProductsList.helpers';
import { IProductShipping } from 'modules/ProductsList/types';
import { signs } from 'utils/symbols';
import { generateAppLinkConfig } from 'modules/NewMainPage/utils';

export const CartItemFormat = (group: any): CartGroup => {
    const products = group?.products || {};
    const is_edp_group = !Object.keys(products)
        .map((pId: any) => products[pId.toString()]?.is_edp)
        .includes(false);
    const productsObjects = Object.keys(products).map((pId) => products[pId.toString()]) || [];
    const productsParsed = ProductsParser(productsObjects, true) || [];
    const group_bonus = productsParsed.reduce((prev, now) => prev + (now?.bonuses_price || 0), 0);
    const group_bonus_percentage = Math.max(
        ...productsParsed.map((product) => product?.bonus_by_percentage)
    );

    const group_min_delivery_days = productsParsed
        .filter((product) => 'min_delivery_days' in product.shippingInfo)
        .reduce((prev, now) => Math.min(prev, now.shippingInfo.min_delivery_days), Infinity);

    const product_shipping_info = productsParsed.find((product) => {
        return product?.shippingInfo?.min_delivery_days === group_min_delivery_days;
    })?.shippingInfo;
    const group_shipping_info = formatShipping(product_shipping_info);
    return {
        base_total: group?.base_total,
        base_total_price: priceFormatter(group?.base_total) || '',
        base_total_formatted: group?.base_total_formatted,
        cart_id: group?.cart_id,
        cart_type: group?.cart_type,
        group_shipping_info,
        company: {
            company: group?.company?.company,
            company_id: group?.company?.company_id,
            rating: group?.company?.rating / 100,
            logo_url: group?.company?.logo_url,
            company_page_url:
                group?.cart_type === 'G'
                    ? generateAppLinkConfig('new_markets', group?.company?.company_id)
                    : {
                          name: 'merchant',
                          query: { company_id: group?.company?.company_id },
                      },
            basket_restriction: {
                min_basket_approved: group?.company?.basket_restriction?.min_basket,
                amount: group?.company?.basket_restriction?.min_basket_amount,
                amount_formatted:
                    priceFormatter(group?.company?.basket_restriction?.min_basket_amount) || '',
                remain: group?.company?.basket_restriction?.remain_amount,
                remain_formatted:
                    priceFormatter(group?.company?.basket_restriction?.remain_amount) || '',
            },
        },
        discount: group?.discount,
        discount_price: priceFormatter(group?.discount) || '',
        discount_formatted: group?.discount_formatted,
        products_discount: group?.products_discount,
        products_discount_formatted: priceFormatter(group?.products_discount) || '',
        promotions: group?.promotions,
        total: group?.total,
        total_price: priceFormatter(group?.total) || '',
        total_formatted: group?.total_formatted,
        products: productsParsed,
        bonus_price: group_bonus,
        bonus_price_formatted: priceFormatter(group_bonus) || '',
        bonus_percentage: group_bonus_percentage,
        is_edp_group,
    };
};

const formatShipping = (ship: IProductShipping | undefined) => {
    if (!ship) {
        return null;
    }

    return {
        is_free: ship?.price === 0,
        name: 'delivery',
        date: ship?.min_delivery_date_formatted,
        city: ship?.city,
        price: ship?.price === 0 ? 'free' : `${priceFormatter(ship.price)} ${signs.tg}`,
        remain_amount: ship.remain_amount,
    };
};
