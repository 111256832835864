import styled from 'styled-components/macro';
import { FlexBox } from 'pyatka-ui';

export const BonusLabel = styled('div')`
    background: #9bd050;
    color: #fff;
    border-radius: 13px;
    padding: 2px 7px 0 7px;
    margin-right: 3px;
`;

export const PaymentBlockWrapper = styled(FlexBox)`
    //position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    // background-color: white;
    //padding: 24px;
    //z-index: 1000;
    //border-radius: 26px 26px 0 0;
`;
