import { AppInitialState } from 'modules/App/types';

const state: AppInitialState = {
    authModal: {
        open: false,
        page_case: '',
    },
    ageVerification: null,
    appError: null,
    categoryVerification: {},
    header: {
        tabLinks: [
            {
                title: 'главная',
                slug: 'home',
                title_i18n: 'home',
                linkItem: { name: 'index-page' },
                icon: 'ux-home-white',
                forUser: 'all',
                appearance: 'tab',
                tooltipName: 'главная',
                url: '/',
            },
            {
                title: 'каталог',
                slug: 'catalog',
                title_i18n: 'catalog',
                linkItem: { name: 'catalog-page' },
                icon: 'ux-catalog-white',
                forUser: 'all',
                appearance: 'tab',
                tooltipName: 'каталог',
                url: '/catalog',
            },
            {
                title: 'корзина',
                slug: 'cart',
                title_i18n: 'cart',
                tooltipName: 'корзина',
                linkItem: { name: 'cart-page' },
                icon: 'ux-cart-white',
                forUser: 'all',
                appearance: 'all',
                url: '/cart',
            },
            {
                title: 'избранное',
                slug: 'wishlist',
                title_i18n: 'wishlist',
                linkItem: { name: 'wishlist-page' },
                icon: 'ux-heart-white',
                tooltipName: 'избранное',
                forUser: 'all',
                appearance: 'nav',
                url: '/wishlist',
            },
            {
                title: 'профиль',
                slug: 'profile',
                title_i18n: 'profile',
                icon: 'ux-user-white',
                linkItem: { name: 'profile-page' },
                tooltipName: 'профиль',
                forUser: 'auth',
                appearance: 'all',
                url: '/profile',
            },
            {
                title: 'войти',
                slug: 'auth-tab',
                title_i18n: 'profile',
                icon: 'ux-user-white',
                linkItem: { name: 'profile-page' },
                forUser: 'guest',
                appearance: 'all',
                tooltipName: 'войти',
            },
            {
                title: 'меню',
                slug: 'menu-tab',
                title_i18n: 'menu-tab',
                icon: 'ux-menu',
                forUser: 'all',
                appearance: 'tab',
                tooltipName: 'меню',
            },
        ],
    },
    loading: false,
    isDialogOpen: false,
    isClosingJmart: false,
    isAgeModal: { isOpen: false, age: 0 },
    verifyAge: 0,
    i18n: {
        translations: {},
        activeLanguage: null,
        fallback: 'ru',
    },
};
export default state;
