import styled from 'styled-components/macro';

const QrWrapper = styled('img')`
    border-radius: 10%;
    overflow: hidden;
    border: 3px solid rgba(0, 0, 0, 0.2);
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.2);
`;

export default QrWrapper;
