import { createSelector } from 'reselect';
import { AppRootState } from 'store/types';
import {
    GeoCodeResultBody,
    UserProfileAddressModel,
    UserProfileServiceInitialState,
} from 'modules/UserProfileService/types';
import { getSelectedCityData } from 'modules/Cities/Cities.selectors';

const getProfileServiceReducer = (state: AppRootState) => state.UserProfileServiceReducer;

export const getProfileServiceState = createSelector([getProfileServiceReducer], (state) => state);

export const getProfileUserData = createSelector(
    [getProfileServiceState],
    (state: UserProfileServiceInitialState) => state?.user
);

export const isClientDebug = createSelector(
    [getProfileUserData],
    (user) => Boolean(user?.client_debug) || process.env.NODE_ENV === 'development'
);

export const getMainProfileAddressesDefault = createSelector(
    [getProfileServiceState],
    (profileState) =>
        profileState?.profiles?.find(
            (address: any) => address?.is_default
        ) as UserProfileAddressModel
);

const getFilteredProfileAddresses = createSelector(
    [getProfileServiceState, getSelectedCityData],
    (profileState, cityData) =>
        profileState?.profiles
            ?.filter((profile: any) => {
                return (
                    profile.values.s_city?.value?.title === cityData?.cityName &&
                    Boolean(profile.values.s_address?.value)
                );
            })
            .map((address: UserProfileAddressModel) => {
                const coordinates = address?.values?.s_s_house_number?.value;
                const is_coordinates = coordinates === '' ? null : coordinates;
                const house = address?.values?.s_coordinates?.value;
                const is_house = house === '' ? null : house;
                const is_address_incorrect = is_coordinates === null || is_house === null;
                return {
                    ...address,
                    is_address_incorrect,
                } as UserProfileAddressModel;
            })
);

export const getProfileServiceAddresses = createSelector(
    [getFilteredProfileAddresses],
    (filteredProfiles) => filteredProfiles
);

export const getProfileServiceAddressesDefault = createSelector(
    [getFilteredProfileAddresses],
    (filteredProfiles) => filteredProfiles?.find((address: any) => address?.is_default)
);

export const getProfileServiceData = createSelector(
    [
        getProfileServiceState,
        getProfileServiceAddresses,
        getProfileServiceAddressesDefault,
        getProfileUserData,
    ],
    (state, addresses, addressDefault, user) => ({
        loading: state.loading,
        addresses,
        addressDefault,
        user,
    })
);

export const getGeoCodeSearchResults = createSelector([getProfileServiceState], (profileState) => {
    return profileState.geoCodeSearchResults.map((result: any) => prepareGeoCodeResult(result));
});

export const prepareGeoCodeResult = (result: any): GeoCodeResultBody => {
    return {
        formatted_address: result.formatted,
        geo_point: result.point,
        hasHouseField: result.components.some((component: any) => component.kind === 'house'),
    };
};
export const getProfileServFormValues = createSelector(
    [getProfileServiceState],
    (state) => state.formFields
);
