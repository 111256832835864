import React, { useEffect } from 'react';
import { usePyatka, Box, Image, Text, Button, FlexBox, Space, Icon } from 'pyatka-ui';
import { useI18n } from 'hooks/useI18n';
import { AppMetricEvents } from 'AppMetric';
import { displayQR } from 'utils/onelink';
import bg from 'components/QrRedirect/assets/jusan-web-modal.png';
import { QRBox } from 'components/QrRedirect/QrRedirect.LayoutNew';
import img from './../assets/jusan-web-modal.png';

export const AppRedirectModal = (props: { onClose: () => void }) => {
    const { $t } = useI18n();
    const {
        scales: { isMobile },
    } = usePyatka();

    const onClick = (e: any) => {
        e.stopPropagation();
        const link = 'https://jusan.me/FCkd/ql5mvtr3';
        AppMetricEvents.jmart_checkout_onelink_click({ link: link });
        window.open(link, '_blank');
    };
    if (!isMobile) {
        return <WebModal {...props} />;
    }
    return (
        <FlexBox bg={'#EFF0F1'} position="relative" cursor="pointer" flexDirection="column">
            <Image
                backgroundPosition="center"
                width="100%"
                src={img}
                backgroundSize={'cover'}
                maxHeight={isMobile ? '79vh' : '55vh'}
            />

            <FlexBox
                flexDirection="column"
                justifyContent="space-between"
                bg="white"
                borderRadius={'26px 26px 0 0'}
                p="26px 24px 0"
                mt={'-20px'}
                zIndex="1"
            >
                <Box zIndex={1}>
                    <FlexBox alignItems="center" gap="20px">
                        <Text fontSize={'24px'} fontWeight={'bold'} textAlign={'center'}>
                            {$t('app_redirect_text_title')}
                        </Text>
                    </FlexBox>
                    <Text
                        textAlign={'center'}
                        color="text.secondary"
                        fontSize={'16px'}
                        lineHeight="138%"
                        my={'3'}
                    >
                        {$t('app_redirect_text_main')}
                    </Text>
                    <Button
                        onClick={onClick}
                        py="20px"
                        width="100%"
                        variant="brand"
                        borderRadius="22px"
                        styleType="contained"
                    >
                        {$t('app_redirect_btn')}
                    </Button>
                </Box>
                <Space mb={4} />
            </FlexBox>
        </FlexBox>
    );
};
const QR_ID = 'onelink-qr';
const WebModal = (props: { onClose: () => void }) => {
    const { $t } = useI18n();

    useEffect(() => {
        displayQR(QR_ID);
    }, []);

    return (
        <React.Fragment>
            <FlexBox width={'100%'} borderRadius={'32px'}>
                <Box
                    width={'100%'}
                    background="#ff7348"
                    backgroundImage={`url(${bg})`}
                    backgroundPosition={'bottom -2px right'}
                    backgroundSize={'contain'}
                    backgroundRepeat={'no-repeat'}
                    position={'relative'}
                >
                    <Box position={'absolute'} top={'20px'} right={'20px'}>
                        <Button
                            width="40px"
                            height="40px"
                            borderRadius={'45px'}
                            onClick={props.onClose}
                            backgroundColor={'#F6F6F5'}
                            borderColor={'#F6F6F5'}
                            p={'5px'}
                        >
                            <Icon icon={'close'} fill={'#AAADB2'} />
                        </Button>
                    </Box>
                    <Box p="50px">
                        <Box maxWidth={'334px'}>
                            <Text
                                color={'white'}
                                fontWeight={700}
                                // variant={'h2'}
                                fontSize={32}
                                lineHeight={'38px'}
                            >
                                {$t('app_redirect_text_title')}
                            </Text>
                            <Space height="10px" />
                            <Text fontSize={'18px'} lineHeight="23.4px" color={'white'}>
                                {$t('app_redirect_text_main')}
                            </Text>
                        </Box>
                        <Space height="24px" />
                        <QRBox
                            id={QR_ID}
                            width="150px"
                            height="150px"
                            background="white"
                            borderRadius="20px"
                            // overflow="hidden"
                            padding="17px"
                        />
                    </Box>
                </Box>
            </FlexBox>
        </React.Fragment>
    );
};
