import { connect, ConnectedProps } from 'react-redux';
import { AppRootState } from 'store/types';
import { selectorPromotionModal } from 'modules/Promotions/Promotions.selectors';
import { getIsDialogOpen } from 'modules/App/App.selectors';

const mapState = (state: AppRootState) => ({
    promotion_modal_data: selectorPromotionModal(state),
    is_dialog_open: getIsDialogOpen(state),
});

export const PromotionModalConnector = connect(mapState, {});

export type PromotionModalConnectProps = ConnectedProps<typeof PromotionModalConnector>;
