import React from 'react';
import { Box, FlexBox, Skeleton, Space } from 'pyatka-ui';

export const ProductSkeletonLayout = () => (
    <Box width="100%">
        <Space marginTop={['10px', '10px', '10px']} />
        <FlexBox alignItems="center" justifyContent="space-between">
            <Skeleton borderRadius={'5px'} width="75%" height="45px" animation />
            <Skeleton borderRadius={'50%'} width="50px" height="50px" animation />
        </FlexBox>
        <Space marginTop={['5px', '7px', '8px']} />
        <Skeleton borderRadius="5px" width="100%" height="20px" animation />
    </Box>
);
