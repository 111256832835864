import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { selectIsInFavorites } from 'modules/Wishlist/Wishlist.selectors';
import { toggleItemInWishlist } from 'modules/Wishlist/Wishlist.actions';
import { WishListTogglerLayout } from './WishListToggler.Layout';
import { WishListTogglerContainerProps } from './types';

export const WishListTogglerContainer: React.FC<WishListTogglerContainerProps> & {
    Layout: typeof WishListTogglerLayout;
} = (props) => {
    const dispatch = useDispatch();

    const { product_id, width, height, link = '/', algoliaHandler, ...rest } = props;

    // const isFavorite = isInFavorites(wishList?.data[''], product_id);
    // const isFavorite = isInFavorites(product_id);
    const isFavorite = useSelector((state) => selectIsInFavorites(state, product_id));
    // console.log('isFavorite', isFavorite);
    const { collection_id }: any = useParams();
    const toggleWishListItemWrapper = () => {
        // if (isQrTrigger) {
        //     triggerQrModal(link);
        //     return;
        // }
        dispatch(
            toggleItemInWishlist({ product_id: Number(product_id), collection_id: collection_id })
        );

        // toggleWishlistItem({ product_id: Number(product_id) });
        algoliaHandler && algoliaHandler();
        // toggleWishListItem({ product_id: Number(product_id) });
    };

    const layoutProps = {
        ...rest,
        width,
        height,
        product_id,
        isFavorite,
        toggleWishListItem: toggleWishListItemWrapper,
    };

    return (
        <React.Fragment>
            <WishListTogglerLayout {...layoutProps} />
        </React.Fragment>
    );
};

WishListTogglerContainer.Layout = WishListTogglerLayout;
