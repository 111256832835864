import React from 'react';
import { Box, Icon } from 'pyatka-ui';
import { WithDiscountProps } from 'components/WithDiscount/types';

export const WithDiscount: React.FC<WithDiscountProps> = ({
    children,
    show = true,
    size = 'default',
    style,
}) => {
    if (!show) {
        return null;
    }

    return (
        <Box position="relative" style={style}>
            <Box
                position="absolute"
                top={size === 'small' ? '-6px' : '0px'}
                style={{ lineHeight: 'normal' }}
            >
                <Icon icon={`discount${size === 'default' ? 'Line' : `-${size}`}`} fill={'red'} />
            </Box>
            {children}
        </Box>
    );
};
