import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getisClosingJmart } from 'modules/App/App.selectors';
import { setIsClosingJmart } from 'modules/App/App.actions';
import { nativeCall } from 'hybrid';
import { PromptModal } from 'components';

const CloseAppModal = () => {
    const open = useSelector(getisClosingJmart);
    const dispatch = useDispatch();

    const handleClose = () => {
        dispatch(setIsClosingJmart(false));
    };

    const handleOk = () => {
        nativeCall({
            type: 'close',
        });
    };

    return (
        <PromptModal
            open={open}
            onSubmit={handleOk}
            onClose={handleClose}
            title="close_jmart"
            description="close_jmart_check"
        />
    );
};

export default React.memo(CloseAppModal);
