import React, { useCallback, useState } from 'react';
import { FlexBox, Icon, Text } from 'pyatka-ui';
import { useSelector } from 'react-redux';
import { useAppRouter } from 'router/context';
import { useI18n } from 'hooks/useI18n';
import AuthorizationModal from 'components/AuthorizationModal';
import { isUserAuth } from 'modules/Authorization/Authorization.selectors';
import { Dialog } from 'plugins/dialog';

interface DesktopMenuIconProps {
    link: string;
    title: string;
    icon: string;
    count: number;
    showCounter: boolean;
}

const DesktopMenuIcon = (props: DesktopMenuIconProps) => {
    const { title, icon, link, count, showCounter = false } = props;

    const isLoggedIn = useSelector(isUserAuth);

    const { appRedirect } = useAppRouter();
    const { $t } = useI18n();
    const goToPage = useCallback(
        (link: string) => {
            appRedirect({
                name: link,
            });
        },
        [appRedirect]
    );

    const onClick = useCallback(
        (link: string) => {
            let source: string = '';
            switch (link) {
                case 'profile-page':
                    source = 'menu_orders_clicked';
                    break;
                case 'wishlist-page':
                    source = 'menu_wishlist_clicked';
                    break;
                default:
                    break;
            }
            if (!isLoggedIn && link !== 'cart-page') {
                console.log('ssss', source);
                Dialog.openDialog({
                    body: AuthorizationModal,
                    props: {
                        onClose: () => {
                            Dialog.closeDialog();
                        },
                        onAuthDone: () => {
                            source === 'menu_orders_clicked'
                                ? goToPage('profile-page')
                                : goToPage('wishlist-page');
                        },
                        source,
                    },
                    isOwnModal: true,
                });
                return;
            }
            goToPage(link);
        },
        [goToPage, isLoggedIn]
    );

    return (
        <>
            <FlexBox
                cursor={'pointer'}
                flexDirection={'column'}
                alignItems={'center'}
                justifyContent={'space-between'}
                onClick={() => onClick(link)}
            >
                <FlexBox
                    width={'32px'}
                    height={'32px'}
                    alignItems={'center'}
                    justifyContent={'center'}
                    position={'relative'}
                >
                    <Icon fill={title === 'orders' ? ['#AAADB2', '#fff'] : '#AAADB2'} icon={icon} />
                    {showCounter && (
                        <FlexBox
                            backgroundColor={'#FF7348'}
                            borderRadius={'999rem'}
                            border={'2px solid #fff'}
                            px={'5px'}
                            minHeight={'18px'}
                            minWidth={'18px'}
                            position={'absolute'}
                            top={'-2px'}
                            left={'70%'}
                            justifyContent={'center'}
                            alignItems={'center'}
                        >
                            <Text
                                color={'#fff'}
                                fontSize={'14px'}
                                lineHeight={'18px'}
                                fontWeight={700}
                            >
                                {count}
                            </Text>
                        </FlexBox>
                    )}
                </FlexBox>
                <Text color={'text.secondary'} fontSize={16} fontWeight={500} lineHeight={'20px'}>
                    {$t(title).toCapitalizeFirstLetter()}
                </Text>
            </FlexBox>
        </>
    );
};

export default DesktopMenuIcon;
