import { all, call, put, select, take, takeEvery, takeLatest } from 'redux-saga/effects';
import {
    addItemToCollection,
    clearReduxWishlist,
    collectionFormMobileOverlaySet,
    createNewCollection,
    deleteWishlistCollection,
    editWishlistCollection,
    fetchCollectionsSucceed,
    fetchProductsSucceed,
    fetchWishlistProductIds,
    fetchWishlistProductIdsSucceed,
    flushWishlist,
    getCollectionProducts,
    getWishlistData,
    toggleItemInWishlist,
    toggleItemInWishlistSucceed,
} from 'modules/Wishlist/Wishlist.actions';
import { Loader } from 'plugins/loader';
import {
    addWishlistService,
    clearWishlistService,
    createCollectionService,
    deleteCollectionService,
    deleteProductsService,
    deleteWishlistItemService,
    editCollectionService,
    fetchCollectionProducts,
    fetchCollections,
    fetchWishlist,
    fetchWishlistProductsService,
} from 'modules/Wishlist/Wishlist.services';
import {
    IAddToCollectionPayload,
    ICollectionEditProps,
    IFetchProductsPayload,
    IWishlistCollection,
    IWishlistPayload,
} from 'modules/Wishlist/types';
import { isUserAuth } from 'modules/Authorization/Authorization.selectors';
import { isNative } from 'utils/platform';
import { Dialog } from 'plugins/dialog';
import AuthorizationModal from 'components/AuthorizationModal';
import { nativeCall } from 'hybrid';
import {
    getCollectionsByProductId,
    getLastEditedItem,
    selectIsInFavorites,
} from 'modules/Wishlist/Wishlist.selectors';
import {
    AuthorizationDataFetch,
    AuthorizationInit,
} from 'modules/Authorization/v2/Authorization.actions';
import { AppRedux } from 'store';
import { AppToaster } from 'plugins/app-toaster';
import AddToCollectionModal from 'pages/WishListServicePage/v2/components/AddToCollectionModal';
import { wishlistToaster } from 'modules/Wishlist/utils';

// function* fetchWishlistHandler(_action: any) {
//     const { payload } = _action;
//     const { page } = payload;
//
//     Loader.favorite.on();
//     try {
//         const { data } = yield call(fetchWishlist, { page });
//         // yield put(fetchWishlistProductIdsSucceed(data?.data?.products));
//         yield put(wishlistDataSave(data?.data));
//         console.log('the data data : ', data?.data);
//
//         return data?.data;
//     } catch (e) {
//     } finally {
//         Loader.favorite.off();
//     }
// }

function* toggleWishlistItemHelper(product: IWishlistPayload) {
    const { product_id, collection_id } = product;
    const isInFavorite: boolean = yield select(selectIsInFavorites, product_id);
    try {
        if (isInFavorite) {
            const collection_ids: string[] = collection_id
                ? [collection_id]
                : yield select((state) => getCollectionsByProductId(state, product_id));
            yield call(deleteProductsService, {
                product_ids: [product_id],
                collection_ids,
            });
            // yield call(deleteWishlistItemService, product);
        } else {
            yield call(addWishlistService, { products: [product] });

            AppToaster.wish({
                children: wishlistToaster({
                    onClick: () => {
                        Dialog.openDialog({
                            body: AddToCollectionModal,
                            props: {
                                onClose: () => {
                                    Dialog.closeDialog();
                                },
                                product_id,
                            },
                            isOwnModal: true,
                        });
                    },
                }),
            });
        }
        yield put(toggleItemInWishlistSucceed({ product, isInFavorite }));
        yield put(getWishlistData({ full: false }));
        if (collection_id) {
            yield put(getCollectionProducts({ collection_id, page: 1 }));
        }
    } catch (error) {}
}

function* toggleItemInWishlistHandler(action: { payload: IWishlistPayload }) {
    const isAuth: boolean = yield select(isUserAuth);
    const { store } = AppRedux;

    if (!isAuth) {
        if (!isNative()) {
            Dialog.openDialog({
                body: AuthorizationModal,
                props: {
                    onClose: () => {
                        Dialog.closeDialog();
                    },
                    onAuthDone: () => {
                        store.dispatch(toggleItemInWishlist(action.payload));
                    },
                },
                isOwnModal: true,
            });
            return;
        }

        try {
            // @ts-ignore
            const res = yield call(nativeCall, { type: 'auth', payload: {} });
            if (res?.response?.token) {
                yield put(AuthorizationInit({ params: { token: res.response?.token } }));
                yield take(AuthorizationDataFetch.type);
                yield call(toggleWishlistItemHelper, action.payload);
            }
        } catch {
            console.log('ERROR IN TOGGLE');
        }

        return;
    }

    yield call(toggleWishlistItemHelper, action.payload);
}

function* fetchWishlistProductIdsHandler() {
    try {
        const { data } = yield call(fetchWishlistProductsService);
        yield put(fetchWishlistProductIdsSucceed(data?.data?.products));
    } catch (e) {}
}

function* flushWishlistHandler() {
    try {
        yield call(clearWishlistService);
        yield put(clearReduxWishlist({ clearAll: true }));
    } catch {}
}

function* getWishlistDataHandler(action: {
    payload: {
        full?: boolean;
    };
}) {
    // const {
    //     payload: { full },
    // } = action;
    // console.log('useAppRouter', yield call(useAppRouter));
    Loader.favoriteCollections.on();
    try {
        const {
            data: { data: collections },
        } = yield call(fetchCollections);
        yield put(fetchCollectionsSucceed({ collections }));

        if (action?.payload?.full && collections?.length > 0) {
            const collection_id = collections.find(
                (collection: IWishlistCollection) => collection.is_default
            ).id;

            yield put(getCollectionProducts({ collection_id }));
        }
        const hasPreCreated = collections.find((collection) => collection.pre_created);
        if (!hasPreCreated) {
            yield put(createNewCollection({ name: 'Моя подборка', pre_created: true }));
        }
    } catch (e) {
        console.log('error', e);
    } finally {
        Loader.favoriteCollections.off();
    }
}

function* fetchCollectionProductsHandler(action: { payload: IFetchProductsPayload }) {
    Loader.favorite.on();

    try {
        const {
            data: { data },
        } = yield call(fetchCollectionProducts, action.payload);
        yield put(fetchProductsSucceed({ data }));
    } catch (e) {
        console.log('e', e);
    } finally {
        Loader.favorite.off();
    }
}

function* createNewCollectionHandler(action: {
    payload: {
        name: string;
        pre_created?: boolean;
        product_id?: number;
    };
}) {
    const {
        payload: { name, pre_created = false, product_id },
    } = action;
    try {
        const {
            data: { data },
        } = yield call(createCollectionService, { name, pre_created });
        if (product_id) {
            yield put(
                addItemToCollection({
                    collection_id: data.id,
                    collection_name: name,
                    product_id,
                })
            );
        }
    } catch (e) {
    } finally {
        yield put(collectionFormMobileOverlaySet({ open: false }));
        yield put(getWishlistData({ full: false }));
    }
}

function* addItemToCollectionHandler(action: { payload: IAddToCollectionPayload }) {
    const {
        payload: { collection_id, collection_name, product_id },
    } = action;
    Loader.addToCollection.on();

    try {
        const productId: number = yield select(getLastEditedItem);
        // if (product_id) {
        yield call(addWishlistService, {
            products: [{ product_id: product_id || productId }],
            collection_id,
        });
        Dialog.closeDialog();
        AppToaster.wish({
            children: wishlistToaster({
                icon: 'tick-success',
                head: `Добавлено в  «${collection_name}»`,
                description: 'Нажмите, чтобы перейти к подборке',
                redirectParams: {
                    name: 'wishlist-collection-page',
                    params: { collection_id },
                },
            }),
        });
        yield put(getWishlistData({ full: false }));
        // }
    } catch (e) {
    } finally {
        Loader.addToCollection.off();
    }
}

function* editWishlistCollectionHandler(action: { payload: ICollectionEditProps }) {
    try {
        yield call(editCollectionService, action.payload);
        yield put(getWishlistData({ full: false }));
        Dialog.closeDialog();
    } catch (e) {}
}

function* deleteWishlistCollectionHandler(action: {
    payload: {
        ids: string[];
    };
}) {
    try {
        yield call(deleteCollectionService, action.payload);
        yield put(getWishlistData({ full: false }));
        Dialog.closeDialog();
    } catch (e) {}
}

export default function* () {
    yield all([
        // takeLatest(fetchWishlistData, fetchWishlistHandler),
        takeEvery(toggleItemInWishlist, toggleItemInWishlistHandler),
        takeLatest(fetchWishlistProductIds, fetchWishlistProductIdsHandler),
        // takeLatest(AuthorizationDataFetch, fetchWishlistProductIdsHandler),
        takeLatest(AuthorizationDataFetch, getWishlistDataHandler),
        takeLatest(flushWishlist, flushWishlistHandler),
        // --------------
        takeLatest(getWishlistData, getWishlistDataHandler),
        takeLatest(createNewCollection, createNewCollectionHandler),
        takeLatest(addItemToCollection, addItemToCollectionHandler),
        takeLatest(getCollectionProducts, fetchCollectionProductsHandler),
        takeLatest(editWishlistCollection, editWishlistCollectionHandler),
        takeLatest(deleteWishlistCollection, deleteWishlistCollectionHandler),
    ]);
}
