import styled from 'styled-components/macro';
import { IStyledCategoryBoxTitle } from 'components/CategoryBox/types';

export const StyledCategoryBoxTitle = styled('div')<IStyledCategoryBoxTitle>`
    position: absolute;
    top: 12px;
    right: 12px;
    /* left: 12px; */
    z-index: 1;
    width: 70%;
    white-space: initial;
    word-wrap: break-word;

    @media (max-width: ${(props) => props.breakpoints.lg}) {
        width: 82%;
        right: 8px;
        top: 8px;
    }
`;
