import slice from 'modules/App/App.slice';

export const {
    AppInit,
    SetAuthModalStatus,
    SetAgeVerificationModalStatus,
    SetAgeVerification,
    SetUserVerified,
    deleteAgeVerificationData,
    SetCategoryVerification,
    UnSetCategoryVerification,
    SetAppError,
    CleanAppError,
    setLoading,
    setI18nActiveLanguage,
    setI18nTranslationsSucceed,
    setDialogIsOpen,
    setIsClosingJmart,
    setIsAgeModal,
    setVerifyAge,
} = slice.actions;
