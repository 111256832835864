import React from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';
import { AppPageProps, AppRouteMiddlewareLayerConfig } from 'router/types';
import { AppPage } from './AppPage';

const AppSubRoutes: React.FC<{ config: AppRouteMiddlewareLayerConfig }> = ({ config }) => {
    const Component = (props: RouteComponentProps) => {
        const prop: AppPageProps = {
            ...props,
            route: config.route,
        };

        return <AppPage {...prop} />;
    };

    if (config.route.isRender) {
        return (
            <Route
                path={config.route.path}
                exact={config.route.exact}
                key={config.route.name}
                render={Component}
            />
        );
    }

    return (
        <Route
            path={config.route.path}
            exact={config.route.exact}
            key={config.route.name}
            component={Component}
        />
    );
};

export default React.memo(AppSubRoutes);
