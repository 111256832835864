import React from 'react';
import qs from 'query-string';
import { generatePath, matchPath, useHistory, useLocation } from 'react-router';
import useUrlState from '@ahooksjs/use-url-state';
import { usePyatka } from 'pyatka-ui';
import { routes } from 'router';
import { AppQueryParams, AppRouterContextInstance, AppRouterToConfig } from 'router/context/types';
import { isQrTrigger, triggerQrModal } from 'utils/platform';

const AppRouterContext = React.createContext<AppRouterContextInstance | undefined>(undefined);

export const resolveParams = (params: AppQueryParams): AppQueryParams => {
    const result = Object.keys(params)
        .sort()
        .reduce((acc, key) => {
            if (!params[`${key}`]) {
                return acc;
            }

            if (key === 'page') {
                const page = Number(params[`${key}`]);
                if (page > 1) {
                    return { ...acc, [key]: Number(params[`${key}`]) };
                }
                return { ...acc, [key]: undefined };
            }

            return { ...acc, [key]: params[`${key}`] };
        }, {});

    return result;
};

const AppRouterProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
    const location = useLocation();
    const history = useHistory();
    const { scales } = usePyatka();

    const getQueryParams = () => resolveParams(qs.parse(location.search) as AppQueryParams);

    const [queryParams, setParams] = useUrlState<AppQueryParams>(
        {},
        {
            stringifyOptions: { encode: false },
            navigateMode: scales?.isMobile ? 'replace' : 'push',
        }
    );

    const addQueryParams = (params: AppQueryParams) => {
        setParams((prev) => resolveParams({ ...prev, ...params }));
    };

    const setFilters = (params: AppQueryParams) => {
        setParams(({ category_id, company_id, ...prev }) => {
            const prevProps = Object.keys(prev).reduce(
                (acc, cur) => ({ ...acc, [cur]: undefined }),
                {}
            );

            return { ...prevProps, category_id, company_id, ...resolveParams(params) };
        });
    };

    // ================================================================================

    const getQueryLink = (params: AppQueryParams, newOnly?: boolean) => {
        const oldParams = getQueryParams();

        const all_params = newOnly
            ? { ...params }
            : {
                  ...oldParams,
                  ...params,
              };
        return qs.stringify(all_params, { encode: false });
    };

    const getPathByName = (name: string) => {
        const r = routes.find((route) => route?.name === name);
        const path = r && r.path;
        return path || '';
    };

    const generateRouteTO = (routerConfig: AppRouterToConfig): string => {
        let pathname = routerConfig.name ? getPathByName(routerConfig.name) : routerConfig.path;

        if (routerConfig.params) {
            const params = resolveParams(routerConfig.params);
            // @ts-ignore
            pathname = generatePath(pathname, params);
        }

        pathname = routerConfig.query
            ? `${pathname}?` + getQueryLink(routerConfig.query, routerConfig?.exact)
            : pathname;

        return pathname || '';
    };

    const appRedirect = (routerConfig: any, route_state: any) => {
        const routeLink = generateRouteTO(routerConfig);
        // Redirect show QR if mobile only
        if (routes.find((i) => i.name === routerConfig.name)?.mobileOnly && isQrTrigger) {
            triggerQrModal(routeLink);
            return;
        }

        // Replace current url => new url
        if (routerConfig.isReplace) {
            history.replace(routeLink);
            return;
        }

        if (typeof routerConfig === 'string') {
            // console.warn('useAppRouter : "appRedirect" use only "routerConfig" as arg');
            history.push(routerConfig, route_state);
            return;
        }

        // let route = routesByName[routerConfig?.name];
        // console.log('----- appRedirect -----', routerConfig);

        // route.fetchPageData && route.fetchPageData({dispatch, payload: routerConfig})
        history.push(routeLink, route_state);
    };

    const currentPath = React.useMemo(() => {
        const curPath = routes.find((route) => route.path && matchPath(location?.pathname, route));
        return curPath || {};
    }, [location?.pathname]);

    const getParams = React.useCallback(() => {
        if (currentPath) {
            return matchPath(location?.pathname, currentPath);
        }
        return {};
    }, [currentPath, location?.pathname]);

    React.useEffect(() => {
        if (__BROWSER__) {
            // Функция, которая будет вызываться, когда WebView получает фокус
            const a = () => {
                console.log('WebView is now active');
                // Здесь вы можете добавить дополнительный код для обработки события фокусировки
            };

            // Функция, которая будет вызываться, когда WebView теряет фокус
            const b = () => {
                console.log('WebView is now inactive');
                // Здесь вы можете добавить дополнительный код для обработки события потери фокуса
            };

            // Добавление слушателей событий для фокуса и потери фокуса
            window.addEventListener('focus', a);
            window.addEventListener('blur', b);
        }
        return () => {
            window.removeEventListener('focus', null);
            window.removeEventListener('blur', null);
        };
    }, []);

    const value: AppRouterContextInstance = {
        queryParams,
        history,
        location,
        appRedirect,
        generateRouteTO,
        addQueryParams,
        getParams,
        setFilters,
    };
    return <AppRouterContext.Provider value={value}>{children}</AppRouterContext.Provider>;
};

const useAppRouter = () => React.useContext(AppRouterContext) as AppRouterContextInstance;

export { AppRouterProvider, useAppRouter };
