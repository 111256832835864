import { createSelector } from 'reselect';
import initialState from 'modules/CartService/CartService.state';
import { CartItemFormat } from 'modules/CartService/helper';
import { AppRootState } from 'store/types';
import { CartGroup, CartServiceInitialState } from 'modules/CartService/types';

const getReducer = (state: AppRootState) => state.CartServiceReducer || initialState;
export const getCartServiceState = createSelector([getReducer], (state) => state);

export const getCartServicePlainData = createSelector(
    [getCartServiceState],
    (state: CartServiceInitialState): CartServiceInitialState['cartData'] => {
        return state.cartData;
    }
);

const getCartServiceFiltered = createSelector(
    [getCartServiceState],
    (state: CartServiceInitialState): CartServiceInitialState['cartData'] => {
        const { cartData, cartTempRemovable } = state;
        const snapshot: CartServiceInitialState['cartData'] = {};
        const group_ids = Object.keys(cartData || {});
        group_ids.forEach((groupKey) => {
            const group = cartData[groupKey.toString()];
            const products_ids = Object.keys(group.products || {});
            const products_ids_filtered = products_ids.filter(
                (id) => !cartTempRemovable.includes(id)
            );
            if (products_ids_filtered.length > 0) {
                const products_filtered: any = {};
                products_ids_filtered.forEach((id) => {
                    products_filtered[id.toString()] = group?.products[id];
                });
                snapshot[groupKey.toString()] = { ...group, products: products_filtered };
            }
        });
        return { cartData: snapshot };
    }
);

export const getCartServiceDataNew = createSelector(
    [getCartServiceFiltered],
    (cartPayload: CartServiceInitialState): CartGroup[] => {
        const { cartData } = cartPayload;
        try {
            return Object.keys(cartData || {}).map(
                (itemKey): CartGroup => CartItemFormat(cartData[itemKey.toString()])
            );
        } catch (e) {
            console.log(e);
            return [];
        }
    }
);

export const getProductsTotalCount = createSelector(
    [getCartServiceState],
    (state: CartServiceInitialState): CartServiceInitialState['cartProductsTotal'] => {
        console.log(state);
        return state.cartProductsTotal;
    }
);

export const getCartDataMetrics = createSelector(
    [getCartServiceState],
    (state: CartServiceInitialState): CartGroup[] => {
        const { cartData } = state;
        try {
            return Object.keys(cartData || {}).map(
                (itemKey): CartGroup => CartItemFormat(cartData[itemKey.toString()])
            );
        } catch (e) {
            console.log(e);
            return [];
        }
    }
);

export const getCartTempRemoves = createSelector(
    [getCartServiceState],
    (state) => state?.cartTempRemovable
);
export const getCartForgottenProducts = createSelector(
    [getCartServiceState],
    (state: CartServiceInitialState): CartServiceInitialState['forgotten_products'] => {
        return state.forgotten_products || [];
    }
);
