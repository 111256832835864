import { createInstance } from '@amplitude/analytics-browser';

// Стать партнером
const PARTNER_API_KEY = '58799ad6b5b4a2e716b69c1083f8a4f1';
const partnerInstance = createInstance();
const initPartnerAmplitude = () => partnerInstance.init(PARTNER_API_KEY);
const sendPartnerEvent = (event_name: string) => partnerInstance.track(event_name);

export const PartnerEvents = {
    init: initPartnerAmplitude,
    sendEvent: sendPartnerEvent,
};

// Каталог тим
const CATALOG_API_KEY = '97c0cadc7faa9a5b43c4921600c4f66a';
const catalogInstance = createInstance();
const initCatalogAmplitude = () => catalogInstance.init(CATALOG_API_KEY);
const sendCatalogEvent = (event_name: string, eventProperties?: Record<string, any>) => {
    const payload = { ...eventProperties };

    // appslyer_id
    if (window && window?.__HYBRIDCONFIG__?.appsflyer_id) {
        payload.appsflyer_id = window?.__HYBRIDCONFIG__?.appsflyer_id;
    }

    // platform
    if (window && window?.__HYBRIDCONFIG__?.platform) {
        let platform = 'mobile_web';
        if (window?.__HYBRIDCONFIG__?.platform === 'MOBILE') {
            platform = 'app';
        } else if (window?.outerWidth > 992) {
            platform = 'desktop';
        }
        payload.platform = platform;
    }

    // appslyer_segment
    if (window && window?.__HYBRIDCONFIG__?.appsflyer_segment) {
        payload.appsflyer_segment = window?.__HYBRIDCONFIG__?.appsflyer_segment;
    }

    console.log('[Amplitude]:', `"${event_name}";`, 'payload: ', payload);
    if (__IS_PROD__) {
        catalogInstance.track(event_name, payload);
        catalogInstance.flush();
    }
};

export const AmpEvents = {
    init: initCatalogAmplitude,
    sendEvent: sendCatalogEvent,
};
