import React from 'react';
import { Box, Button, FlexBox, Text, usePyatka } from 'pyatka-ui';
import { useSelector } from 'react-redux';
import { CartProductCounter, WithDiscount } from 'components';
import { signs } from 'utils/symbols';
import { useI18n } from 'hooks/useI18n';
import { useCartService } from 'contexts/useCartService';
import { AppCartItemState } from 'contexts/useCartService/types';
import { useAppRouter } from 'router/context';
import { getProductMinPriceInfo } from 'modules/ProductDetail/ProductDetail.selectors';
import { isQrTrigger, triggerQrModal } from 'utils/platform';
import { Dialog } from 'plugins/dialog';
import priceFormatter from 'utils/priceFormatter';
import { IUseScrollTo } from 'hooks/useScrollTo';

interface MinPriceFooterProps {
    product_id?: string;
    offer_id: number | null;
    query_id?: string;
    no_offers_limit: boolean;
    modalGetAllOffers: () => void;
    addToCartMetric: (data: any) => void;
    lastCategoryId?: number;

    inView: boolean;
    similarScroll: IUseScrollTo;
}

const MinPriceFooter: React.FC<MinPriceFooterProps> = (props) => {
    const { inView, similarScroll, lastCategoryId, ...rest } = props;

    const { scales } = usePyatka();

    const { $t } = useI18n();
    const { location, getParams } = useAppRouter();
    const { params } = getParams();
    const { cartItemsByProduct } = useCartService();
    const minPirceInfo = useSelector(getProductMinPriceInfo);

    const inCartOffer: AppCartItemState | undefined = React.useMemo(() => {
        const offers = cartItemsByProduct(String(params?.product_id)) || [];
        if (offers?.length) {
            return offers[0];
        }
    }, [cartItemsByProduct, params?.product_id]);

    const isInCart: boolean = React.useMemo(
        () => Boolean(inCartOffer && inCartOffer.amount > 0 && inCartOffer.offer_id),
        [inCartOffer]
    );

    const price = minPirceInfo?.price || location?.state?.price_formatted;
    const discountPrice = minPirceInfo?.discount_price || location?.state?.discountPrice_formatted;

    const addToCartHandler = () => {
        if (isQrTrigger) {
            triggerQrModal('/');
            return;
        }
        if (isInCart) {
            return;
        }
        Dialog.openDialog({
            body: 'OfferBlocks',
            title: 'choose_merchants',
            // swipeable: false,
            config: {
                noPadding: scales?.isMobile,
                bottomSheet: true,
                showMobileTitle: false,
                maxWidth: '471px',
            },
            props: {
                ...rest,
                isCounter: true,
                category_id: lastCategoryId,
                bgColor: 'block.main',
                product_id: params?.product_id,
                isModal: true,
            },
        });
    };

    const noOffers = !(price || discountPrice);

    return (
        <Box
            borderTopWidth={2}
            borderTopStyle="solid"
            borderTopColor="block.secondary"
            padding="10px 18px"
            position="fixed"
            bg="block.main"
            zIndex={10}
            bottom={inView ? -20 : 56}
            right={0}
            left={0}
            style={{ transition: 'bottom 0.3s' }}
        >
            <FlexBox alignItems="center" justifyContent="space-between">
                {noOffers ? (
                    <FlexBox flexDirection="column">
                        <Text fontSize="22px" fontWeight={700} lineHeight="28.16px">
                            {$t('no_in_stock')}
                        </Text>
                        <Text
                            fontSize="12px"
                            fontWeight={500}
                            lineHeight="15.36px"
                            color="text.secondary"
                        >
                            {$t('item_out_of_stock')}
                        </Text>
                    </FlexBox>
                ) : (
                    <FlexBox alignItems="center" gap="8px">
                        <FlexBox
                            gap="5px"
                            fontSize="22px"
                            lineHeight="22px"
                            fontWeight={700}
                            alignItems="flex-end"
                            whiteSpace="nowrap"
                            color={discountPrice ? 'text.brand' : 'text.main'}
                        >
                            <Text fontSize="18px" lineHeight="26px">
                                от
                            </Text>
                            <Text>{priceFormatter(discountPrice || price)}</Text>
                            <Text color={discountPrice ? '#FF7664' : 'text.secondary'}>
                                {signs.tg}
                            </Text>
                        </FlexBox>

                        <WithDiscount show={Boolean(discountPrice)}>
                            <FlexBox
                                gap="3px"
                                fontSize="16px"
                                fontWeight={700}
                                color={discountPrice ? 'text.placeholder' : 'transparent'}
                            >
                                <Text> {priceFormatter(price)} </Text>
                                <Text> {signs.tg} </Text>
                            </FlexBox>
                        </WithDiscount>
                    </FlexBox>
                )}
                <Box minWidth="133px">
                    {noOffers ? (
                        <Button
                            width="100%"
                            height="45px"
                            variant="brand"
                            padding="8px 25px"
                            borderRadius="16px"
                            bg="block.secondary"
                            color="text.main"
                            styleType="contained"
                            onClick={() => similarScroll.scrollIntoView()}
                        >
                            <Text fontSize="16px" fontWeight={600} lineHeight="20.48px">
                                {$t('similar')}
                            </Text>
                        </Button>
                    ) : isInCart ? (
                        <CartProductCounter
                            fullWidth
                            layout="new"
                            product_type={params?.product_type}
                            offer_id={String(inCartOffer?.offer_id)}
                        />
                    ) : (
                        <Button
                            width="100%"
                            height="45px"
                            variant="brand"
                            padding="8px 25px"
                            borderRadius="16px"
                            styleType="contained"
                            onClick={addToCartHandler}
                        >
                            <Text fontSize="16px" fontWeight={600} lineHeight="20.48px">
                                {$t('to_cart').toCapitalizeFirstLetter()}
                            </Text>
                        </Button>
                    )}
                </Box>
            </FlexBox>
        </Box>
    );
};

export default MinPriceFooter;
