import React from 'react';
import { Button, FlexBox, Text } from 'pyatka-ui';
import { useSelector } from 'react-redux';
import { isUserAuth } from 'modules/Authorization/Authorization.selectors';
import { selectIsInDefaultFavorites } from 'modules/Wishlist/Wishlist.selectors';

interface IWishlistTooltipProps {
    onClick: () => void;
    product_id: string;
}

const WishlistTooltip = (props: IWishlistTooltipProps) => {
    const { onClick, product_id } = props;
    const isAuth = useSelector(isUserAuth);
    const isProductInWishlist = useSelector((state) =>
        selectIsInDefaultFavorites(state, product_id)
    );

    return (
        <FlexBox flexDirection={'column'} alignItems={'center'}>
            <Text fontWeight={700} fontSize={18} mb={isAuth ? 18 : '6px'}>
                В избранном
            </Text>
            {!isAuth && (
                <Text textAlign={'center'} fontSize={16} mb={18} px={'6px'}>
                    Войдите в аккунт, чтобы добавить в список
                </Text>
            )}
            <Button
                width={'100%'}
                padding={'10px 20px'}
                borderRadius={'20px'}
                border={'none'}
                styleType={'contained'}
                variant={'secondary'}
                color={'text.main'}
                fontSize={'16px'}
                onClick={onClick}
            >
                {!isAuth ? 'Войти' : 'Добавить в список'}
            </Button>
        </FlexBox>
    );
};

export default WishlistTooltip;
