import slice from 'modules/NewMainPage/NewMainPage.slice';

export const {
    NewMainPageInit,
    NewMainPageSucceed,
    NewMainPageFailed,
    NewMainPageMenuInit,
    NewMainPageMenuSucceed,
    NewMainPageMenuFailed,
    NewMainPageMenuFlush,
} = slice.actions;
