import React from 'react';
import { FlexBox, usePyatka, Icon, Skeleton, Button, Box } from 'pyatka-ui';
import styled from 'styled-components';
import { useParams } from 'react-router';
import { AppLink, CartProductCounter, WishListToggler } from 'components';
import { ProductPriceNew } from 'components/ProductCard/NewCard/ProductCard.LayoutNew';
import { SimilarProductsButton } from 'components/ProductCard/NewCard/components/SimilarProductsButton';
import { ProductCardSmallLayoutProps } from '../types';
import { GiftBox, ProductImage } from '../components';
import { ProductDescription } from './components';
import { IAddToCartButton } from './components/AddToCartButton';

const ProductCardLayoutSmall: React.FC<ProductCardSmallLayoutProps> = ({
    lineClamp = 2,
    appearance = 'slide',
    isInCart = false,
    firstOfferInCart,
    addToCartHandler,
    // Product
    label,
    productName,
    type,
    image,
    image_preload,
    url,
    isLoading = false,
    price_formatted,
    price,
    discountPrice_formatted,
    // gift_exist = false,
    offer_id,
    algoliaHandler,
    handleClickProduct,
    isPreventLink,
    productId,
    show_add_cart = true,
    show_overlay = true,
    jdv_labels = [],
    bg,
    handleMetric,
    discount_percent,
    effect,
    labels,
    totalRecords,
    avgRate,
    isZeroPrice,
    category_ids,
}) => {
    const { scales } = usePyatka();
    const params = useParams<any>();

    const wishListSize = scales?.isMobile ? { width: '18px', height: '16px' } : {};
    // const hasPaymentTimes = Boolean(label?.installmentLabel);

    return (
        <FlexBox
            p="8px"
            width="100%"
            height="100%"
            cursor="pointer"
            position="relative"
            borderRadius="18px"
            flexDirection="column"
            justifyContent="space-between"
            bg={bg}
            onClick={handleMetric}
        >
            <ImageWrapper>
                <FlexBox
                    zIndex={1}
                    height="36px"
                    py="4px"
                    px="9px"
                    alignItems="center"
                    position="absolute"
                    justifyContent="space-between"
                    right="-4px"
                    left="-4px"
                    top="-4px"
                >
                    {/* {hasPaymentTimes && !isLoading ? (
                        <ProductLabel
                            height="16px"
                            fontSize="12px"
                            variant="credit"
                            padding="0px 8px"
                        >
                            {(label?.installmentLabel!.text || '')
                                .replace(/-/gi, '•')}
                        </ProductLabel>
                    ) : (
                        <div />
                    )} */}
                    <div />
                    <WishListToggler
                        product_id={Number(productId) || Number(offer_id)}
                        productName={productName}
                        price={discountPrice_formatted || price}
                        link={url}
                        algoliaHandler={algoliaHandler}
                        {...wishListSize}
                    />
                </FlexBox>
                <GiftBox show={false} jdv_labels={jdv_labels} isSmall bottom={{ default: 0 }} />
                <Box position={'relative'}>
                    <ProductImage
                        url={url}
                        image={image}
                        image_preload={image_preload}
                        isLoading={isLoading}
                        appearance={appearance}
                        ariaLabel={productName}
                        handleClick={handleClickProduct}
                        isPreventLink={isPreventLink}
                        effect={effect}
                        layout="small"
                        isNewImage
                        route_state={{
                            labels,
                            price_formatted,
                            totalRecords,
                            avgRate,
                            discountPrice_formatted,
                            offer_id,
                        }}
                    />
                    {params?.store_id && <Overlay />}
                </Box>
            </ImageWrapper>
            <FlexBox mt="5px" height="100%" flexDirection="column" justifyContent="flex-end">
                <AppLink
                    to={url}
                    onClickProduct={handleClickProduct}
                    preventOnClick={isPreventLink}
                    route_state={{
                        labels,
                        price_formatted,
                        totalRecords,
                        avgRate,
                        discountPrice_formatted,
                        offer_id,
                    }}
                >
                    <ProductPriceNew
                        isZeroPrice={isZeroPrice}
                        layout="small"
                        label={label}
                        isLoading={isLoading}
                        price_formatted={price_formatted}
                        discount_percent={discount_percent}
                        discountPrice_formatted={discountPrice_formatted}
                    />
                    <ProductDescription
                        isZeroPrice={isZeroPrice}
                        lineClamp={lineClamp}
                        productName={productName}
                        isLoading={isLoading}
                        params={params}
                    />
                </AppLink>
                {/* <ProductRating /> */}
            </FlexBox>

            {show_add_cart && (
                <div style={{ marginTop: 16, zIndex: 1 }}>
                    {isZeroPrice ? (
                        <SimilarProductsButton category_ids={category_ids} />
                    ) : isInCart && !isLoading ? (
                        <CartProductCounter
                            decrease_bg={'block.secondary'}
                            fullWidth
                            bg={!show_overlay ? 'block.secondary' : 'block.main'}
                            product_type={type}
                            offer_id={String(firstOfferInCart?.offer_id)}
                            is_super={true}
                        />
                    ) : (
                        <SuperCartHandler
                            bg={!show_overlay ? 'block.secondary' : 'block.main'}
                            addToCartHandler={addToCartHandler}
                            isLoading={isLoading}
                            height={scales?.isMobile ? '30px' : '39px'}
                        />
                    )}
                </div>
            )}

            {show_overlay && <Overlay />}
        </FlexBox>
    );
};

const constBtnProps = {
    p: '5px',
    border: 0,
    width: '23px',
    borderRadius: '50%',
};

interface SuperCartHandlerProps extends IAddToCartButton {
    height?: string;
}

export const SuperCartHandler: React.FC<SuperCartHandlerProps> = ({
    addToCartHandler,
    isLoading,
    bg = 'block.main',
    height = '23px',
}) => {
    const { scales } = usePyatka();
    const iconSize = scales?.isMobile ? '10px' : '16px';

    return (
        <FlexBox
            px="3px"
            py="2px"
            fontSize="11px"
            borderRadius="999rem"
            alignItems="center"
            justifyContent="center"
            width={'100%'}
            // maxHeight="34px"
            // height="100%"
            bg={bg}
            onClick={!isLoading ? addToCartHandler : undefined}
        >
            {isLoading ? (
                <Skeleton height={'20px'} width={'100%'} />
            ) : (
                <Button {...constBtnProps} height={height}>
                    <Icon
                        icon="counter-plus"
                        width={iconSize}
                        height={iconSize}
                        fill={'rgb(32, 31, 31)'}
                    />
                </Button>
            )}
        </FlexBox>
    );
};

const ImageWrapper = styled.div`
    position: relative;
    flex: 1;
    margin: -4px -4px 0 -4px;
`;
const Overlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 18px;
    background: rgba(190, 190, 182, 0.1);
    pointer-events: none;
`;

export default React.memo(ProductCardLayoutSmall);
