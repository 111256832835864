import React from 'react';
import { Box, Display, FlexBox, Icon, SelectBox, Text } from 'pyatka-ui';
import { langOptions } from 'components/LanguageSelector/const';
import { LanguageSelectorLayoutProps } from 'components/LanguageSelector/types';
import styledComponents from './LanguageSelector.styles';

const { StyledSelectBoxWrapper } = styledComponents;

export const LanguageSelectorLayout: React.FC<LanguageSelectorLayoutProps> = (props) => {
    const { changeLanguage, activeLanguageProps } = props;
    return (
        <StyledSelectBoxWrapper>
            <SelectBox onChange={changeLanguage} options={langOptions} optionsMinWidth={'197px'}>
                <FlexBox
                    flexDirection={'column'}
                    alignItems={'center'}
                    cursor={'pointer'}
                    className={'selected-item-icon'}
                >
                    <Box>
                        <Icon icon={activeLanguageProps.key} />
                    </Box>
                    <Display md={false}>
                        <Text
                            color={'text.secondary'}
                            fontSize={16}
                            fontWeight={500}
                            lineHeight={'20px'}
                        >
                            Язык
                        </Text>
                    </Display>
                </FlexBox>
            </SelectBox>
        </StyledSelectBoxWrapper>
    );
};
