import { AppToaster } from 'plugins/app-toaster';

export const nativeAlert = (payload: any) => {
    const data = {
        type: 'alert',
        payload,
    };
    console.log(`[nativeAlert][in]: ${JSON.stringify(data)}`);
    try {
        const res: any = prompt(JSON.stringify(data));
        console.log(`[nativeAlert][out]: ${res}`);
        return JSON.parse(res);
    } catch (e) {
        console.log(e);
        return e;
    }
};

export const nativeList = (payload: any) => {
    const data = {
        type: 'list',
        payload,
    };

    try {
        const res: any = prompt(JSON.stringify(data));
        return JSON.parse(res);
    } catch (e) {
        console.log(e);
        return e;
    }
};

export const nativeCall = (data: any) => {
    process.env.NODE_ENV === 'development' &&
        AppToaster.info({ head: 'native input', body: JSON.stringify(data), clearPrevious: false });
    console.log(`[nativeCall][in]: ${JSON.stringify(data)}`);
    try {
        const res: any = prompt(JSON.stringify(data));
        process.env.NODE_ENV === 'development' &&
            AppToaster.info({ head: 'native output', body: res, clearPrevious: false });
        console.log(`[nativeCall][out]: ${res}`);
        return JSON.parse(res);
    } catch (e) {
        console.log(e);
        return e;
    }
};

export const nativeAsyncCall = (data: any) => {
    return new Promise((resolve, reject) => {
        const res: any = prompt(JSON.stringify(data));
        if (res !== '') {
            resolve(JSON.parse(res));
        } else {
            reject('ERROR');
        }
    });
};
