import React from 'react';
import { Box, Button, FlexBox, Image, Text } from 'pyatka-ui';
import { useI18n } from 'hooks/useI18n';
import TimeWarningImg from '../../../assets/time_warning.png';
interface IAlcoholContainWarningProps {
    onClick: () => void;
}

const AlcoholContainWarning = (props: IAlcoholContainWarningProps) => {
    const { onClick } = props;
    const { $t } = useI18n();

    return (
        <FlexBox
            flexDirection={'column'}
            alignItems={'center'}
            mt={{ default: '-16px' }}
            px={{ md: '7px' }}
        >
            <Box
                mb={'28px'}
                width={{ default: '181px', md: '253px' }}
                height={{ default: '160px', md: '240px' }}
            >
                <Image src={TimeWarningImg} />
            </Box>
            <Text
                fontWeight={700}
                fontSize={'20px'}
                lineHeight={'26px'}
                mb={{ default: '8px', md: '6px' }}
            >
                {$t('attention')}!
            </Text>
            <Text
                px={{ default: '21.5px', md: '54.5px' }}
                fontWeight={500}
                fontSize={'16px'}
                lineHeight={'20px'}
                color={'text.secondary'}
                mb={{ default: '22px', md: '28px' }}
                textAlign={'center'}
            >
                {$t('alcohol_contain_text')}
            </Text>

            <Box width={'100%'} px={{ md: '49.5px' }}>
                <Button
                    variant="brand"
                    styleType="contained"
                    onClick={onClick}
                    padding={'17.5px 8px'}
                    fontSize={'16px'}
                    borderRadius={{
                        default: '22px',
                    }}
                    width={'100%'}
                    height={'55px'}
                >
                    {$t('clearly')?.toCapitalizeFirstLetter()}
                </Button>
            </Box>
        </FlexBox>
    );
};

export default AlcoholContainWarning;
