import React from 'react';
import { useDispatch } from 'react-redux';
import { saveUserAgeVerified } from 'data';
import { SetUserVerified } from 'modules/App/App.actions';
import { Dialog } from 'plugins/dialog';
import { AgeVerificationModalLayout } from './AgeVerificationModal.Layout';
import { AgeVerificationModalProps } from './types';

export const AgeVerificationModalContainer: React.FC<AgeVerificationModalProps> = (props) => {
    const dispatch = useDispatch();

    const approve = () => {
        saveUserAgeVerified('yes');
        dispatch(SetUserVerified(true));
        // dispatch(deleteAgeVerificationData())
        Dialog.closeDialog();
    };

    const cancel = () => {
        saveUserAgeVerified('no');
        dispatch(SetUserVerified(false));
        // dispatch(deleteAgeVerificationData())
        Dialog.closeDialog();
    };

    const layoutProps = {
        ...props,
        cancel,
        approve,
    };

    return (
        <React.Fragment>
            <AgeVerificationModalLayout {...layoutProps} />
        </React.Fragment>
    );
};
