import React from 'react';
import { Box, FlexBox, usePyatka } from 'pyatka-ui';
import { CategoryBox } from 'components';
import { CategoryBoxProps } from 'components/CategoryBox/types';
import { CategoryBoxListLayoutProps } from './types';

export const CategoryBoxListLayout: React.FC<CategoryBoxListLayoutProps> = (props) => {
    const { categories, borderRadius = 24, appearance = 'slide', isLoading, new_design } = props;

    const { scales } = usePyatka();

    return (
        <React.Fragment>
            <FlexBox
                p={{ default: '0', md: '16px 4px 16px 16px' }}
                borderRadius={borderRadius}
                width="100%"
                flexWrap="wrap"
                mr={{ default: '-8px', md: '-12px' }}
                gap={new_design ? (scales?.isMobile ? '32px 8px' : '0') : ''}
            >
                {categories.map((category: CategoryBoxProps, idx: number) => (
                    <Box
                        key={category?.title + idx}
                        width={{
                            default: 'calc(33.33% - 8px)',
                            md: 'calc(16.66% - 12px)',
                        }}
                        mr={{ default: new_design ? '' : '8px', md: '12px' }}
                        mb={{
                            default: new_design ? '' : '8px',
                            md: appearance === 'slide' ? 0 : '32px',
                        }}
                    >
                        <CategoryBox
                            new_design={new_design}
                            {...category}
                            isLoading={isLoading}
                            isExternal={category?.isExternal}
                        />
                    </Box>
                ))}
            </FlexBox>
        </React.Fragment>
    );
};
