export const ProfileServiceConfigs: any = {
    23: {
        name: '23', //s_city
        // label: 'Город',
        placeholder: '',
        type: 'text',
        validators: {
            required: false,
        },
    },
    19: {
        name: '19', //s_address
        // label: 'Адрес',
        placeholder: '',
        type: 'text',
        validators: {
            required: false,
        },
    },
    98: {
        name: '98', //s_address
        // label: 'Подъезд',
        placeholder: 'entrance',
        type: 'text',
        maxLength: 7,
        validators: {
            required: false,
        },
    },
    102: {
        name: '102', //s_flat
        // label: 'Квартира',
        placeholder: 'enter_flat_number',
        type: 'text',
        maxLength: 7,
        validators: {
            required: false,
        },
    },
    100: {
        name: '100', //s_floor
        // label: 'Этаж',
        placeholder: 'enter_floor',
        type: 'text',
        maxLength: 7,
        validators: {
            required: false,
        },
    },
    104: {
        name: '104', //s_intercom
        // label: 'Домофон',
        placeholder: 'enter_intercom',
        type: 'text',
        maxLength: 7,
        validators: {
            required: false,
        },
    },
    106: {
        name: '106', //s_comments
        // label: 'Комментарий',
        placeholder: 'enter_comment',
        type: 'text',
        validators: {
            required: false,
        },
    },
    108: {
        name: '108', //s_coordinates
        // label: 'Координаты',
        placeholder: '',
        type: 'text',
        validators: {
            required: false,
        },
    },
    112: {
        name: '112',
        // label: 'Номер дома',
        placeholder: '',
        type: 'text',
        validators: {
            required: false,
        },
    },
};

export const FormFieldsInitialData = {
    19: '',
    23: '',
    98: '',
    100: '',
    102: '',
    104: '',
    106: '',
    108: '',
    profile_id: null,
};

export const profileAddressArray = [
    // {
    //     field_name: 's_city',
    //     field_id: 23,
    //     value: 'г.',
    // },
    {
        field_name: 's_address',
        field_id: 19,
        value: '',
    },
    {
        field_name: 's_entrance',
        field_id: 98,
        value: 'Подъезд',
    },
    {
        field_name: 's_floor',
        field_id: 100,
        value: 'Этаж',
    },
    {
        field_name: 's_flat',
        field_id: 102,
        value: 'Квартира',
    },
    {
        field_name: 's_intercom',
        field_id: 104,
        value: 'Домофон',
    },
];
