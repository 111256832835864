import React from 'react';
import loadable from '@loadable/component';
import { FlexBox, LoadingIndicator } from 'pyatka-ui';
import { ErrorBoundary } from 'components';
import { AuthModalProps } from './types';

const AuthModal = loadable(() => import('./index'));

export const AuthModalAsync: React.FC<AuthModalProps> = (props) => (
    <ErrorBoundary>
        <AuthModal
            {...props}
            fallback={
                <FlexBox height="200px" flex="grid" justifyContent="center" alignItems="center">
                    <LoadingIndicator size="40px" />
                </FlexBox>
            }
        />
    </ErrorBoundary>
);
