import { IPoints } from './types';

const points = ['default', 'xs', 'sm', 'md', 'lg', 'xl'];

const parse = <T>(value: T) => {
    const object = {} as any;
    for (const point of points) {
        object[String(point)] = value;
    }
    return object;
};

export const converter = <T>(value: IPoints<T> | T) => {
    if (typeof value === 'number' || typeof value === 'string') {
        return parse<T>(value);
    }
    return value;
};

const calcSlide = (a?: number, b?: number, c?: string, d?: number) => {
    if (a && b && c && d) {
        const width = 100 / a;
        return width < (100 * b) / d ? `${b}px` : `calc(${width}% - ${c})`;
    }
};

const calcGrid = (a?: number, b?: number) => {
    if (a && b) {
        return `repeat(${a}, minmax(${b}px, 1fr))`;
    }
};

export const parseStyle = (
    innerWidth: number,
    appearance: 'slide' | 'grid',
    gap: IPoints<string> | string,
    count: IPoints<number> | number,
    minWidth: IPoints<number> | number
) => {
    const a = converter<number>(count) as IPoints<number>; // --- count
    const b = converter<number>(minWidth) as IPoints<number>; // --- minWindth
    const c = converter<string>(gap) as IPoints<string>; // --- gap

    const object: Record<string, any> = {};

    for (const point of points) {
        if (appearance === 'slide') {
            // @ts-ignore
            object[`${point}`] = calcSlide(a[`${point}`], b[`${point}`], c[`${point}`], innerWidth);
        } else {
            // @ts-ignore
            object[`${point}`] = calcGrid(a[`${point}`], b[`${point}`]);
        }
    }

    return object;
};
