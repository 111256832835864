import React from 'react';
import { Box, Button, FlexBox, Icon, Image, Space, Text } from 'pyatka-ui';
import { AppLink } from 'components';
import { useI18n } from 'hooks/useI18n';
import { EmptyContentBlockLayoutProps } from './types';
// import styles from './EmptyContentBlock.module.scss';

export const EmptyContentBlockLayout: React.FC<EmptyContentBlockLayoutProps> = (props) => {
    const { $t } = useI18n();
    const {
        image = '',
        icon = 'ic_empty_results',
        title = 'cart_empty',
        description = 'use_catalog_or_search',
        redirectText = 'go_back',
        customRedirectText = '',
        redirectTo,
        height = '70vh',
        titleColor,
    } = props;

    return (
        <React.Fragment>
            <FlexBox
                m="0 auto"
                flexDirection={'column'}
                justifyContent={'center'}
                alignItems={'center'}
                textAlign={'center'}
                height={height}
                paddingX={'60px'}
            >
                {image ? (
                    <Image src={image} width={'100px'} height={'100px'} />
                ) : (
                    <Icon icon={icon} width={'100px'} height={'100px'} />
                )}
                <Space height={'25px'} />
                <Box maxWidth={'330px'}>
                    <Text
                        textAlign={'center'}
                        color={titleColor || '#AAADB2'}
                        fontSize={{ default: '22px', sm: '36px' }}
                        fontWeight={{ default: '700' }}
                    >
                        {$t(title)}
                    </Text>
                    <Space height={'12px'} />

                    <Text
                        color={'text.secondary'}
                        fontSize={{ default: '14px', sm: '16px' }}
                        fontWeight={'500'}
                    >
                        {$t(description)}
                    </Text>
                </Box>

                {Boolean(redirectTo) && (
                    <Box>
                        <Space height={'12px'} />

                        <AppLink to={redirectTo}>
                            <Button
                                px={{ default: '22px', sm: '24px' }}
                                py={{ default: '9px', sm: '12px' }}
                                backgroundColor={'block.brand'}
                                color={'#ffffff'}
                                borderRadius={{ default: '20px', sm: '23px' }}
                                border={'none'}
                            >
                                <Text
                                    fontSize={{ default: '14px', sm: '16px' }}
                                    lineHeight={{ default: '18px', sm: '21px' }}
                                >
                                    {customRedirectText ? (
                                        <NewlineText text={customRedirectText} /> //.replace(/\n/g, '<br />')
                                    ) : (
                                        $t(redirectText)
                                    )}
                                </Text>
                            </Button>
                        </AppLink>
                    </Box>
                )}
            </FlexBox>
        </React.Fragment>
    );
};

function NewlineText({ text = ' ', ...rest }: any) {
    return text.split('\n').map((str: string) => <div {...rest}>{str}</div>);
}
