import React, { useMemo } from 'react';
import { useHistory } from 'react-router';
import { HeaderUserLocationLayout } from './HeaderUserLocation.Layout';
import { HeaderUserLocationProps } from './types';

export const HeaderUserLocationContainer: React.FC<HeaderUserLocationProps> & {
    Layout: typeof HeaderUserLocationLayout;
} = (props) => {
    const history = useHistory();
    const { cities } = props;

    const citiesOptions = useMemo(
        () =>
            cities.map((city: any) => ({
                option: city.cityName,
                value: city.cityId,
            })),
        [cities]
    );

    const city_names = useMemo(
        () =>
            cities && cities.length > 0
                ? cities.reduce((acc: any, city: any) => {
                      const id = city.cityId;
                      return {
                          ...acc,
                          [id]: city.cityName,
                      };
                  }, {})
                : {},
        [cities]
    );

    const layoutProps = {
        ...props,
        citiesOptions,
        city_names,
        history,
    };

    return (
        <React.Fragment>
            <HeaderUserLocationLayout {...layoutProps} />
        </React.Fragment>
    );
};

HeaderUserLocationContainer.Layout = HeaderUserLocationLayout;
