import React from 'react';
import { CategoryBoxLayout } from './CategoryBox.Layout';
import { CategoryBoxLayoutNew } from './CategoryBox.LayoutNew';

import { CategoryBoxProps } from './types';

export const CategoryBoxContainer: React.FC<CategoryBoxProps> & {
    Layout: typeof CategoryBoxLayout;
} = (props) => (
    <React.Fragment>
        {props?.new_design ? <CategoryBoxLayoutNew {...props} /> : <CategoryBoxLayout {...props} />}
    </React.Fragment>
);

CategoryBoxContainer.Layout = CategoryBoxLayout;
