import React from 'react';
import { Box, FlexBox, Image, Text } from 'pyatka-ui';
import { AppLink } from 'components';
import { AppMetricEvents } from 'AppMetric';
import priceFormatter from 'utils/priceFormatter';
import { signs } from 'utils/symbols';
import { useI18n } from 'hooks/useI18n';
import { SupermarketCardLayoutProps } from './types';
import css from './SupermarketCard.module.scss';

const Container: React.FC<{ isLink: boolean; companyId: any }> = ({
    isLink = false,
    companyId,
    children,
}) => {
    if (isLink) {
        return (
            <AppLink to={{ name: 'store-detail', params: { store_id: companyId } }}>
                {children}
            </AppLink>
        );
    }

    return <>{children}</>;
};

export const SupermarketCardLayout: React.FC<SupermarketCardLayoutProps> = ({
    isOnDetailPage,
    companyName,
    image,
    companyId,
    desc,
    min_basket_amount,
}) => {
    const { $t } = useI18n();
    return (
        <Container companyId={companyId} isLink={!isOnDetailPage}>
            <Box
                bg="block.main"
                className={isOnDetailPage ? css.cardWrapperDetail : css.cardWrapper}
                onClick={() =>
                    AppMetricEvents.jmart_category({
                        category_id: companyId || '',
                        category_name: companyName,
                    })
                }
            >
                <FlexBox
                    flexDirection={isOnDetailPage ? 'row-reverse' : 'row'}
                    alignItems="center"
                    gap="15px"
                >
                    <Box width={{ default: '20%' }} pl={0}>
                        <div className={isOnDetailPage ? css.cardImgDetail : `${css.cardImg}`}>
                            <Image src={image} />
                        </div>
                    </Box>
                    <Box width={{ default: '80%' }}>
                        <Text as="h2" fontWeight="bold" color="text.main" fontSize={4}>
                            {companyName}
                        </Text>
                        <Text lineClamp={1} color="text.secondary">
                            {desc}
                        </Text>
                    </Box>
                </FlexBox>
                {isOnDetailPage && min_basket_amount ? (
                    <Box width={{ default: '100%' }}>
                        <Text color="text.secondary" marginBottom={1}>
                            {$t('minimum_order_amount')}:
                        </Text>
                        <Text color="text.main">
                            {priceFormatter(min_basket_amount)} {signs.tg}
                        </Text>
                    </Box>
                ) : null}
            </Box>
        </Container>
    );
};
