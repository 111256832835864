import { NewMainPageInitialState } from 'modules/NewMainPage/types';

const state: NewMainPageInitialState = {
    sections: [],
    ssrMode: null,
    prevCityId: '',
    mainMenu: [],
};

export default state;
