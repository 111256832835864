import { CaseReducer, current, PayloadAction } from '@reduxjs/toolkit';
import { CartServiceInitialState } from 'modules/CartService/types';
import { AppCartItemState } from 'contexts/useCartService/types';

const cartServiceDataSave: CaseReducer<
    CartServiceInitialState,
    PayloadAction<{ cartData: any; products: CartServiceInitialState['cartProductsTotal'] }>
> = (state, { payload }) => {
    const { cartData, products } = payload;
    console.log(products);
    state.cartData = cartData?.groups;
    state.cartProductsTotal = products;
    // state.cartData = cartData;
};

const cartServiceDataFlush: CaseReducer<CartServiceInitialState, PayloadAction> = (
    state,
    _action
) => {
    state.cartData = {};
    state.cartProductsTotal = { count: 0, total_price: 0 };
    // AppToaster.info({ body: 'cart_is_cleared' });
};

const cartServiceTempRemovable: CaseReducer<
    CartServiceInitialState,
    PayloadAction<{ ids: AppCartItemState['offer_id'][] }>
> = (state, action) => {
    const { ids } = action.payload;
    state.cartTempRemovable.push(...ids);
};
const saveForgottenProducts: CaseReducer<
    CartServiceInitialState,
    PayloadAction<CartServiceInitialState['forgotten_products']>
> = (_state, _action) => {
    _state.forgotten_products = _action.payload;
};
export default {
    cartServiceDataSave,
    cartServiceDataFlush,
    cartServiceTempRemovable,
    saveForgottenProducts,
};
