import { routesConfig } from 'router/config';
import { prepareRouterConfig } from 'router/utils';
//
export { AppRouter } from 'router/AppRouter/AppRouter';
export const routes = prepareRouterConfig(routesConfig);

const routesByName: any = {};
routes.map((route) => {
    routesByName[route.name] = route;
});

export { routesByName };
