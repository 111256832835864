import React from 'react';
import { Text, usePyatka } from 'pyatka-ui';
import { WithDiscount } from 'components';
import { Product } from 'modules/ProductsList/types';
import { signs } from 'utils/symbols';

const ProductDiscount: React.FC<ProductDiscountProps> = ({
    price,
    discountPrice,
    fontSize = '10px',
    lineHeight = '11px',
}) => {
    const { scales } = usePyatka();

    return (
        <WithDiscount show={Boolean(discountPrice)} size={scales?.isMobile ? 'small' : 'default'}>
            <Text
                fontWeight="600"
                fontSize={fontSize}
                lineHeight={lineHeight}
                color={discountPrice ? 'text.placeholder' : 'transparent'}
            >
                {price} {signs.tg}
            </Text>
        </WithDiscount>
    );
};

export default ProductDiscount;

interface ProductDiscountProps {
    price: Product['price'];
    discountPrice: Product['discountPrice'];
    fontSize?: any;
    lineHeight?: any;
}
