import React from 'react';
import loadable from '@loadable/component';
import { FlexBox, LoadingIndicator } from 'pyatka-ui';
import { ErrorBoundary } from 'components';
import { OfferBlocksProps } from './types';

const OfferBlocks = loadable(() => import('./index'));

export const OfferBlocksAsync: React.FC<OfferBlocksProps> = (props) => (
    <ErrorBoundary>
        <OfferBlocks
            {...props}
            fallback={
                <FlexBox height="200px" flex="grid" justifyContent="center" alignItems="center">
                    <LoadingIndicator size="40px" />
                </FlexBox>
            }
        />
    </ErrorBoundary>
);
