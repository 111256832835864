import React from 'react';
import { Box } from 'pyatka-ui';
import { ClientOrderCardQrLiteLayout } from 'components/ClientOrderCard/ClientOrderCard.QrLiteLayout';
import { useAppRouter } from 'router/context';
import { ClientOrderCardLayout } from './ClientOrderCard.Layout';
import { ClientOrderCardProps } from './types';
import { ProductSkeletonLayout } from './ClientOrderSkeleton.Layout';

export const ClientOrderCardContainer: React.FC<ClientOrderCardProps> & {
    Layout: typeof ClientOrderCardLayout;
} = (props) => {
    const { appRedirect } = useAppRouter();

    const routeTo = (e: any) => {
        appRedirect({ name: 'client-order-detail-page', params: { order_id: props.orderId } });
    };
    return (
        <React.Fragment>
            <Box
                onClick={routeTo}
                borderBottom={props.notLast ? 2 : 0}
                borderColor={'#F6F6F5'}
                pb={props.notLast ? '24px' : 0}
            >
                {!props.skeleton ? (
                    props.order_source === 'QR_LITE' ? (
                        <ClientOrderCardQrLiteLayout {...props} />
                    ) : (
                        <ClientOrderCardLayout {...props} />
                    )
                ) : (
                    <ProductSkeletonLayout />
                )}
            </Box>
        </React.Fragment>
    );
};

ClientOrderCardContainer.Layout = ClientOrderCardLayout;
