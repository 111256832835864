import React from 'react';
import { SupermarketCardsLayout } from './SupermarketCards.Layout';
import { SupermarketCardsLayoutSkeleton } from './SupermarketCards.LayoutSkeleton';
import { SupermarketsCardsProps } from './types';

export const SupermarketCardsContainer: React.FC<SupermarketsCardsProps> & {
    Layout: typeof SupermarketCardsLayout;
} = (props) => {
    const { skeleton } = props;
    return (
        <React.Fragment>
            {skeleton && <SupermarketCardsLayoutSkeleton />}
            {!skeleton && <SupermarketCardsLayout {...props} />}
        </React.Fragment>
    );
};

SupermarketCardsContainer.Layout = SupermarketCardsLayout;
