import React from 'react';
import { usePyatka } from 'pyatka-ui';
import { matchPath } from 'react-router';
import GlobalStyles from 'app/MainApp.Styles';
import { AppLoaderProvider } from 'contexts/useLoader';
import { useAppRouter } from 'router/context';
import { AppCartServiceProvider } from 'contexts/useCartService';
// import { AppWishListServiceProvider } from 'contexts/useWishListService';
import { Dialog } from 'components/Dialog';
import { Loader } from 'components/Loader';
import { AppRouter, routes } from 'router';
import { AppToasterContainer, SearchDialog } from 'components';
import { AppWishlistProvider } from 'contexts/useWishlist';
import ProductDetailModal from 'pages/NewProductDetailPage/ProductDetail.Modal';
import CollectionFormModal from 'pages/WishListServicePage/v2/components/CollectionFromModal/CollectionFormModal';
import { AppTooltip } from 'components/AppTooltip';
import CloseApp from './MainApp.CloseApp';
import MainAppAgeModal from './MainApp.AgeModal';

export const MainAppLayout: React.FC<any> = () => {
    const { theme, scales } = usePyatka();

    const { location } = useAppRouter();

    const isWhiteBg = React.useMemo(() => {
        const curPath = routes.find((route) => route.path && matchPath(location?.pathname, route));
        // BackToSchool
        if (location?.pathname === '/promotions/600') {
            return false;
        }

        // iPhone 15 promo
        if (location?.pathname === '/promotions/986') {
            return true;
        }

        if (
            curPath?.name &&
            ['store-main', 'home-page', 'search-page', 'merchant', 'store-detail'].includes(
                curPath?.name
            )
        ) {
            return curPath?.isWhiteBg || false;
        }

        if (
            curPath?.name === 'index-page' ||
            curPath?.name === 'checkout-delivery' ||
            curPath?.name === 'profile-page'
        ) {
            return scales?.isMobile ? false : true;
        }

        if (
            curPath?.name &&
            ['wishlist-page', 'wishlist-collection-page', 'product-detail-page'].includes(
                curPath?.name
            )
        ) {
            return true;
        }

        if (curPath?.name === 'cart-page') {
            return scales?.isMobile ? false : true;
        }

        if (!scales?.isMobile) {
            return false;
        }

        return curPath?.isWhiteBg || false;
    }, [location?.pathname, scales?.isMobile]);
    return (
        <>
            <GlobalStyles theme={theme} isWhiteBg={isWhiteBg} />
            <AppLoaderProvider>
                <AppCartServiceProvider>
                    {/*<AppWishListServiceProvider>*/}
                    <AppWishlistProvider>
                        <CloseApp />
                        <MainAppAgeModal />
                        <SearchDialog />
                        <ProductDetailModal />
                        <CollectionFormModal />
                        <Dialog />
                        <Loader />
                        <AppRouter routes={routes} />
                    </AppWishlistProvider>
                    {/*</AppWishListServiceProvider>*/}
                </AppCartServiceProvider>
                {/*------------------------------------------------- TOAST -----------------------------------------------*/}{' '}
                <AppToasterContainer />
                <AppTooltip />
            </AppLoaderProvider>
        </>
    );
};
