import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { Loader } from 'plugins/loader';
import { ProductsListServices } from 'modules/ProductsList/ProductsList.services';
import { removePageFromQS } from 'modules/ProductsList/utils';
import { getMerchant } from './Merchants.services';
import {
    fetchMerchant,
    fetchMerchantSucceed,
    fetchMerchantFailed,
    fetchProducts,
    fetchProductsSucceed,
    fetchProductsFailed,
    merchantFlush,
} from './Merchants.actions';
import { IProductsReq } from './types';
import { getMerchantData, getMerchantsState, getProductsList } from './Merchants.selectors';

// Супермаркет
function* handleFetchMerchant({ payload }: PayloadAction<number>) {
    const { company_id } = yield select(getMerchantData);

    if (String(company_id) === String(payload)) {
        return;
    }

    Loader.merchant.on({ global: false });

    try {
        const { data } = yield call(getMerchant, payload);
        yield put(fetchMerchantSucceed(data?.data));
    } catch (e) {
        yield put(fetchMerchantFailed());
    } finally {
        Loader.merchant.off();
    }
}

function* handleFetchProducts({ payload }: PayloadAction<IProductsReq>) {
    const { infiniteScroll, url, ...params } = payload;
    const { prevUrl, page } = yield select(getMerchantsState);
    const products: any[] = yield select(getProductsList);

    const [currentUrl, newPage = 1] = removePageFromQS(url || '');

    // Flush products if search url changes
    if (currentUrl !== prevUrl) {
        yield put(merchantFlush());
    }

    // If same page early return
    const isInfinite = infiniteScroll
        ? Number(page) >= Number(newPage)
        : Number(page) === Number(newPage);

    if (currentUrl === prevUrl && isInfinite && products.length) {
        return;
    }

    Loader.listing.on({ global: false });

    try {
        const { data } = yield call(ProductsListServices, { params } as any);
        yield put(
            fetchProductsSucceed({
                ...data?.data,
                infiniteScroll,
                url: currentUrl,
            })
        );
    } catch (e) {
        yield put(fetchProductsFailed());
    } finally {
        Loader.listing.off();
    }
}

export default function* () {
    yield all([takeLatest(fetchMerchant, handleFetchMerchant)]);
    yield all([takeLatest(fetchProducts, handleFetchProducts)]);
}
