import React from 'react';
import { Box, FlexBox, Text, usePyatka } from 'pyatka-ui';
import dayjs from 'dayjs';
import { ReviewButton } from 'pages/ClientOrderDetailPage/components/ReviewButton';
import ProductItem from 'components/ClientOrderDetailCard/components/ProductItem';
import { Card } from 'components/ClientOrderDetailCard/ClientOrderDetailCard.Styles';
import { ProductWithReview } from 'modules/OrdersService/types';
import { useI18n } from 'hooks/useI18n';
import ProductGift from 'components/ClientOrderDetailCard/components/ProductGift';

type ProductsInfoBlockProps = {
    order_id: number;
    created_at: string;
    products: ProductWithReview[];
    order_source?: string;
    total_price: string;
};
const ProductsInfoBlock = (props: ProductsInfoBlockProps) => {
    const { order_id, created_at, products, total_price, order_source } = props;
    const { $t } = useI18n();
    const {
        scales: { isMobile },
    } = usePyatka();
    return (
        <Card
            isMobile={isMobile}
            maxHeight={'320px'}
            minHeight={'160px'}
            borderRadius={isMobile ? '26px 26px 0 0' : '30px'}
        >
            <FlexBox
                mb={{ default: 18, md: 21 }}
                alignItems={'center'}
                justifyContent={'space-between'}
            >
                <Text fontSize={{ default: '16px', md: '18px' }} fontWeight={600}>
                    {$t('order')} №{order_id}
                </Text>
                <Text color={'text.secondary'} fontSize={{ default: '14px', md: '16px' }}>
                    {dayjs(created_at).format('DD MMM HH:mm')}
                </Text>
            </FlexBox>
            <Box overflowX={'hidden'} overflowY={'auto'} pr={2}>
                {order_source === 'QR_LITE' ? (
                    <ProductItem
                        image={'qr_lite'}
                        element={null}
                        title={'purchase_qr_lite'}
                        price={total_price}
                        showAmount={false}
                        isLast={true}
                        product_id={''}
                    />
                ) : (
                    <FlexBox flexDirection={'column'} gap={'10px'}>
                        {products?.map((elem, idx: string | number | null | undefined) => {
                            const productSectionProps = {
                                key: idx,
                                isMobile: isMobile,
                                price: elem?.price_formatted,
                                title: elem?.productName,
                                image: elem?.image,
                                image_preload: elem?.image_preload,
                                cartAmount: elem?.amount,
                                product_id: elem?.product_id?.toString(),
                                url: elem.urlMasterId,
                                hasDiscount: Boolean(elem.discountPrice),
                                discountPrice: elem.discountPrice_formatted,
                                // cartEditable: false,
                                isLast:
                                    elem.gifts?.length === 0 ? idx === products.length - 1 : false,
                                //task/CT-698: Скрыть функционал отзывов
                                element: elem?.review ? (
                                    <ReviewButton review_info={elem.review} />
                                ) : null,
                            };
                            return (
                                <Box key={idx}>
                                    <ProductItem {...productSectionProps} />
                                    {elem.gifts?.map((gift) => (
                                        <ProductGift
                                            key={gift.id}
                                            {...gift}
                                            isLast={idx === products.length - 1}
                                        />
                                    ))}
                                </Box>
                            );
                        })}
                    </FlexBox>
                )}
            </Box>
        </Card>
    );
};

export default ProductsInfoBlock;
