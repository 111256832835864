import dayjs from 'dayjs';
import {
    CartHashData,
    CheckoutDeliveryData,
    CheckoutDeliveryDateInterval,
    CheckoutDeliveryHourInterval,
    CheckoutPickupVariantInfo,
    CheckoutShippingVariantInfo,
    InitialCheckoutDeliveryPageData,
    NativeInterval,
    NativeIntervalTimeListItem,
} from 'pages/CheckoutDeliveryV2Page/types';
import { Product } from 'modules/ProductsList/types';
import { parseProduct } from 'modules/ProductsList/ProductsList.helpers';
import priceFormatter from 'utils/priceFormatter';
import { getProductPaymentPreselectTerm } from 'data';

export const checkoutReqPayloadPrepare = (payload: {
    params?: { checkout_type: string; checkout_id: string };
    query?: { amount?: string };
}) => {
    const { params, query } = payload;
    switch (params?.checkout_type) {
        case 'cart':
            return { group_hash: params?.checkout_id };
        case 'product':
            return {
                'product[product_id]': params?.checkout_id,
                'product[amount]': query?.amount || 1,
            };
        case 'order':
            return { order_id: params?.checkout_id };
    }
};

// ================================================================================ PICKUP COLLECTOR

const collectPickupInfo = (pickup: any): CheckoutPickupVariantInfo => ({
    name: pickup?.name,
    company_id: pickup?.company_id,
    company_store_id: pickup?.company_store_id,
    store_location_id: pickup?.store_location_id,
    latitude: pickup?.latitude,
    longitude: pickup?.longitude,
    pickup_address: pickup?.pickup_address,
    pickup_phone: pickup?.pickup_phone,
    pickup_time: pickup?.pickup_time,
});

const collectPickupVariants = (pickUpInfo: any): CheckoutPickupVariantInfo[] => {
    const result: CheckoutPickupVariantInfo[] = [];
    Object.keys(pickUpInfo).map((id) => {
        const pickUpData = pickUpInfo[id.toString()];
        pickUpData.map((variant: any) => result.push(collectPickupInfo({ ...variant })));
    });
    return result;
};

const preparePostomatVariant = (postomat_variant: any, postomat_provider: string) => ({
    ...postomat_variant,
    postomat_provider,
});

const collectPostomatVariants = (postomatInfo: any) => {
    let result: any = [];
    Object.keys(postomatInfo).map((id) => {
        const postomatData = postomatInfo[id.toString()];
        Object.keys(postomatData).map((provider_name) => {
            const postomat_provider_data = postomatData[provider_name];
            const postomat_variants = postomat_provider_data?.items.map((variant: any) =>
                preparePostomatVariant(variant, postomat_provider_data?.provider_code || '')
            );

            result = [...result, ...postomat_variants];
        });
    });
    return result;
};

// ================================================================================ SHIPPING COLLECTOR

const collectShippingHourIntervalInfo = (time: any): CheckoutDeliveryHourInterval => ({
    is_available: time?.availability,
    value: time?.value,
    desc: time?.description,
});

const collectShippingIntervalsInfo = (intervals: any): CheckoutDeliveryDateInterval => ({
    desc: intervals?.description,
    date: intervals?.date,
    value: intervals?.value,
    intervals: intervals?.time_list
        .map((time: any) => collectShippingHourIntervalInfo(time))
        .filter((i: CheckoutDeliveryHourInterval) => i.is_available),
});

const collectShippingInfo = (shipping: any): CheckoutShippingVariantInfo => ({
    id: shipping?.id,
    name: shipping?.name,
    company_id: shipping?.company_id,
    can_deliver: shipping?.can_deliver,
    min_delivery_date: shipping?.min_delivery_date_formatted,
    // alcohol_contain: shipping?.alcohol_contain || false,
    price: Number(shipping.price).toFixed(),
    intervals: shipping?.shipping_intervals
        ?.map((interval: any) => collectShippingIntervalsInfo(interval))
        .filter((interval: CheckoutDeliveryDateInterval) => interval.intervals.length),
});

const collectShippingVariants = (shippingInfo: any): CheckoutShippingVariantInfo[] => {
    const infoByCompanyId = shippingInfo;

    const shippingVariants = [];

    const company_ids = Object.keys(infoByCompanyId);

    for (const company_id of company_ids) {
        const infoByShippingId = infoByCompanyId[company_id];

        const shipping_ids = Object.keys(infoByShippingId);

        for (const currentShippingId of shipping_ids) {
            const infoByProductId = infoByShippingId[currentShippingId];

            if ('products_type' in infoByProductId) {
                shippingVariants.push(
                    collectShippingInfo({ ...infoByProductId, id: currentShippingId })
                );
            } else {
                const product_ids = Object.keys(infoByProductId);

                for (const product_id of product_ids) {
                    const currentProductInfo = infoByProductId[product_id];

                    shippingVariants.push(
                        collectShippingInfo({ ...currentProductInfo, id: currentShippingId })
                    );
                }
            }
        }
    }
    return shippingVariants.filter((variant) => variant.can_deliver);

    // return Object.keys(shippingInfo)
    //     .map((id) => collectShippingInfo({ ...shippingInfo[id], id }))
    //     .filter((i) => i.can_deliver);
};

// ================================================================== Delivery Resolver
interface CheckoutDeliveryDataOmitted
    extends Omit<CheckoutDeliveryData, 'shipping_selected' | 'pickup_selected'> {}

const CheckoutDeliveryDataPrepare = (data: any): CheckoutDeliveryDataOmitted => {
    const result: CheckoutDeliveryDataOmitted = {
        pickup: { variants: [] },
        shipping: { variants: [] },
        postomat: { variants: [] },

        delivery_types: ['shipping', 'pickup', 'postomat', 'sdek'],
        filtered_delivery_types: [],
    };

    const shippingResponse = data?.shipping_data || {};

    Object.keys(shippingResponse).map((key) => {
        const deliveryObject = shippingResponse[key] || {};

        switch (key) {
            case 'pickup_info':
                result.pickup.variants = collectPickupVariants(deliveryObject);
                return;
            case 'postomat_info':
                result.postomat.variants = collectPostomatVariants(deliveryObject);
                return;
            case 'delivery_info':
                result.shipping.variants = collectShippingVariants(deliveryObject);
                return;
            // case 'sdek_info':
            //     result.sdek.variants = [];
            //     return;
            default:
                return;
        }
    });

    result.filtered_delivery_types = result.delivery_types.filter(
        (type) =>
            type !== null && Array.isArray(result[type]?.variants) && result[type].variants?.length
    );
    const pickup_points = ['pickup', 'postomat', 'sdek'];
    result.filtered_delivery_methods = result.filtered_delivery_types.reduce((acc, type) => {
        if (type === 'shipping') {
            acc.push(type);
        } else if (pickup_points.includes(type) && !acc.includes('pickup_points')) {
            acc.push('pickup_points');
        }
        return acc;
    }, []);
    return result;
};
// ================================================================== By-Product  Resolver

export const cartDataFromProductResponse = (payload: { product: any; amount: string }) => {
    const { product, amount = 1 } = payload;
    const groups = {
        [product?.product_id]: {
            products: {
                [product?.product_id]: { ...product, amount },
            },
            cart_hash: product?.product_id,
            cart_type: product?.type,
            total: parseInt(product.price) * (amount as number),
            base_total: parseInt(product?.base_price) * (amount as number),
            products_discount: parseInt(product?.discount) * (amount as number),
        },
    };
    return {
        groups,
    };
};

// ================================================================== By-Order Resolver

export const cartDataFromOrderResponse = (productsImport: any) => {
    const [firstProduct] = productsImport;
    const products: any = {};
    productsImport.forEach((p: any) => {
        products[p?.product_id.toString()] = { ...p };
    });

    const sums = productsImport.reduce(
        (prev: { base_total: number; products_discount: number }, now: any) => {
            return {
                base_total: prev.base_total + now.price * now.amount,
                products_discount: prev.products_discount + now.products_discount * now.amount,
            };
        },
        { base_total: 0, products_discount: 0 }
    );
    const groups: any = {
        ['order_group']: {
            products,
            cart_hash: firstProduct?.product_id,
            cart_type: firstProduct?.type,
            ...sums,
        },
    };
    return { groups };
};

const CartDeliveryDataPrepare = (cartData: any): CartHashData => {
    const CartProducts = (products: any): Product[] =>
        Object.keys(products || {}).map(
            (pId) => parseProduct(products[pId.toString()], false) || []
        );
    const groups = cartData?.groups;
    const [group] = Object.keys(groups || {}).map((groupId) => {
        const g = groups[groupId.toString()];
        const products = CartProducts(g?.products);
        const group_bonus = products.reduce((prev, now) => prev + (now?.bonuses_price || 0), 0);
        const group_bonus_percentage = Math.max(
            ...products.map((product) => product?.bonus_by_percentage)
        );
        const base_total = g?.base_price || g?.base_total;
        return {
            products,
            cart_hash: g?.cart_id,
            cart_type: g?.cart_type,
            alcohol_contain: g?.alcohol_contain || false,
            total: g?.total,
            total_price: priceFormatter(g?.total) || '',
            discount: g?.products_discount,
            discount_price: priceFormatter(g?.products_discount) || '',
            bonus: group_bonus,
            // bonus_price: priceFormatter(group_bonus) || '',
            bonus_price: group_bonus_percentage || '',
            basket_restriction: g?.company_basket?.basket_restriction || null,
            base_total,
        };
    });
    return group;
};

// ================================================================== Payments Resolver
const PaymentsDataPrepare = (paymentsData: any): InitialCheckoutDeliveryPageData['prices'] => ({
    ...paymentsData?.prices,
    total_price: priceFormatter(paymentsData?.prices?.total) || '',
    base_price: priceFormatter(paymentsData?.prices?.base_total) || '',
    discount_price: priceFormatter(paymentsData?.prices?.discount) || '',
});
// ================================================================== Preselected Term prepare

const PaymentPreselectedTermByCartData = (cartData: any): string => {
    const [group]: any = Object.values(cartData?.groups || {});
    if (group) {
        const [product] = Object.keys(group?.products || {});
        const terms = getProductPaymentPreselectTerm() || {};
        const preselected_term = terms[product.toString()];
        return preselected_term;
    }
    return '';
};

// ==================================================================================================
// ============================================================ CHECKOUT DATA PREPARE================
// ==================================================================================================

export const checkoutDataReformat = (args: { checkoutData: any; cartData: any }) => {
    const { checkoutData, cartData } = args;

    const deliveryInfo = CheckoutDeliveryDataPrepare(checkoutData);
    const paymentsInfo = PaymentsDataPrepare(checkoutData);
    const cartInfo = CartDeliveryDataPrepare(cartData);
    const preselected_term = PaymentPreselectedTermByCartData(cartData);
    return {
        ...deliveryInfo,
        preselected_term,
        cart: cartInfo,
        prices: paymentsInfo,
        is_valid_profile_id: checkoutData?.chosen_profile?.is_valid,
        chosen_profile_id: checkoutData?.chosen_profile?.id,
        shipping_required: checkoutData?.shipping_required,
        company: checkoutData?.company,
        user_info: checkoutData?.user_info?.user,
    };
};

// ==================================================================================================
// ============================================================ INTERVALS DATA PREPARE================
// ==================================================================================================

export const prepareIntervalsForNative = (
    intervals: CheckoutDeliveryDateInterval[]
): NativeInterval[] =>
    intervals.map((interval) => {
        const time_list = interval.intervals.map(
            (date): NativeIntervalTimeListItem => ({
                availability: date?.is_available,
                value: date?.value,
                description: date?.desc,
            })
        );
        return {
            time_list,
            description: interval?.desc,
            value: interval?.value,
            date: interval?.value,
        };
    });

// DD.MM.YYYY => (today, tomorrow, etc)
export const formatDay = (date: string, week: string) => {
    // DD.MM.YYYY => D MMMM (example: 24 авг);
    const target = dayjs(date, 'DD.MM.YYYY').format('D MMM');
    switch (target) {
        case dayjs().format('D MMMM'):
            return 'today';
        case dayjs().add(1, 'day').format('D MMMM'):
            return 'tomorrow';
        default:
            return `${week || ''} ${target || ''}`;
    }
};
