import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
    SingleStaticPageSucceed,
    StaticPagesFailed,
    StaticPagesInit,
    StaticPagesSucceed,
    StaticSinglePagesInit,
} from 'modules/Articles/Articles.actions';
import { fetchStaticPages } from 'modules/Articles/Articles.services';

function* singleStaticPagesHandler(_action: any) {
    try {
        const { data } = yield call(fetchStaticPages, _action?.payload);
        yield put(
            SingleStaticPageSucceed({
                singleStaticPage: data.data,
                slug: _action?.payload?.slug,
            })
        );
        // yield fork(APP_PROCESS_END, {
        //     action: SingleStaticPageSucceed({
        //         singleStaticPage: data.data,
        //         slug: _action?.payload?.slug,
        //     }),
        //     indicator: '',
        // });
    } catch (e) {
        yield put(StaticPagesFailed(e));
    }
}

function* staticPagesHandler(_action: any) {
    try {
        const { data } = yield call(fetchStaticPages);
        yield put(StaticPagesSucceed(data.data));
    } catch (e) {
        yield put(StaticPagesFailed(e));
    }
}

export default function* () {
    yield all([
        takeLatest(StaticPagesInit, staticPagesHandler),
        takeLatest(StaticSinglePagesInit, singleStaticPagesHandler),
    ]);
}
