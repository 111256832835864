import { InterceptorLayer, InterceptorLayerConfig } from 'services/types';

export const InterceptorMiddleware =
    (functions: InterceptorLayer[]) => (config: InterceptorLayerConfig) => {
        return functions.reduce((curValue, curFunc) => {
            return curFunc(curValue);
        }, config);
    };

// import { getAuthToken, isUserAuth } from '../../modules/auth/auth_v3/selectors';
// import { store, state } from '.';
//
// export const loadingStatus = {
//     onRequest: ({ url, loading_indicator }) => {
//         if (loading_indicator) {
//             store.dispatch(initLoadingStatus({ type: loading_indicator, indicator: url }));
//         }
//     },
//     onResponseSuccess: ({ config }) => {
//         const { url } = config;
//         store.dispatch(finishLoadingStatus({ indicator: url, is_error: false }));
//     },
//
//     onResponseError: (response) => {
//         console.log(response);
//         const { url } = response.config;
//         const { data } = response;
//         if (data.error || data.Error) {
//             store.dispatch(
//                 finishLoadingStatus({
//                     indicator: url,
//                     is_error: true,
//                     error_result: data.error ? data.error : data.Error,
//                 })
//             );
//         }
//     },
// };
//
// export const onResponse = (response) => {
//     const { data } = response;
//     const hasError = data.error !== undefined;
//     return {
//         hasError,
//     };
// };
//
// export const setAuthHeader = (config) => {
//     const is_auth = isUserAuth(state());
//     const token = getAuthToken(state());
//     const { headers } = config;
//     if (is_auth) {
//         config.headers = {
//             ...headers,
//             Authorization: token,
//         };
//     }
// };
