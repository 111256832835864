import slice from 'modules/ProductDetail/ProductDetail.slice';

export const {
    fetchProductDetails,
    removeProductDetails,
    fetchProductDetailsSucceed,
    fetchProductDetailsFailed,
    clearProductLabels,
    setProductLabels,
    setProductMinPriceInfo,
    clearProductMinPriceInfo,
    setSimilarProducts,
    clearSimilarProducts,

    // MODAL
    setProductDetailModalOpen,

    // SEO
    fetchProductDetailsSeo,
    fetchProductDetailsSeoSucceed,
    fetchProductDetailsSeoFailed,
} = slice.actions;
