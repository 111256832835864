import React from 'react';
import { useSelector } from 'react-redux';
import { Box, Container, Display, Space } from 'pyatka-ui';
import { getStaticPages } from 'modules/Articles/Articles.selectors';
import { AppLinks, ContactLinks, TermsLinks } from './NewFooter.Layout';
import { useParams } from 'react-router';

const NewFooterContainer: React.FC = () => {
    const allStaticPages = useSelector(getStaticPages);

    // LEGO-TODO
    const { promotion_id = null } = useParams();

    return promotion_id === '739' ? null : (
        <Display md={false}>
            <footer>
                <Box bg="block.main" borderTop="2px solid rgba(190, 190, 182, 0.20)" pt="12px">
                    <Container
                        style={{ maxWidth: '1036px', display: 'flex', flexDirection: 'column' }}
                    >
                        <AppLinks />
                        <TermsLinks linkList={allStaticPages.filter((item) => !item.isMobile)} />
                        <Space height="21px" />
                        <ContactLinks />
                    </Container>
                </Box>
            </footer>
        </Display>
    );
};

export default React.memo(NewFooterContainer);
