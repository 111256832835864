//Initializing Smart Script arguments
import logo from 'shared/assets/jusan-qr-logo.png';

const oneLinkURL = 'https://jusan.onelink.me/FCkd';
const mediaSource = { keys: ['utm_source'], defaultValue: 'any_source' };
const campaign = { keys: ['utm_campaign'], defaultValue: 'any_campaign_name' };
const channel = { keys: ['utm_channel'], defaultValue: 'any_channel' };
const ad = { keys: ['utm_ad'], defaultValue: 'any_ad_name' };
const adSet = { keys: ['utm_adset'], defaultValue: 'any_adset_name' };
const googleClickIdKey = 'af_sub1';
const afSub2 = { keys: ['fbclid'] };
const custom_ss_ui = { paramKey: 'af_ss_ui', defaultValue: 'true' };

export const getOneLink = (fallback?: string) => {
    //Call the function after embedding the code through a global parameter on the window object called window.AF_SMART_SCRIPT.
    //Onelink URL is generated.

    if (__BROWSER__ && window.AF_SMART_SCRIPT) {
        return window.AF_SMART_SCRIPT.generateOneLinkURL({
            oneLinkURL: oneLinkURL,
            afParameters: {
                mediaSource: mediaSource,
                campaign: campaign,
                channel: channel,
                ad: ad,
                adSet: adSet,
                googleClickIdKey: googleClickIdKey,
                afSub2: afSub2,
                afCustom: [custom_ss_ui],
            },
        });
    }

    return { clickURL: fallback || '' };
};

export const displayQR = (elementId: string) => {
    if (__BROWSER__ && window.AF_SMART_SCRIPT) {
        getOneLink();
        window.AF_SMART_SCRIPT.displayQrCode(elementId, {
            logo: logo,
            colorCode: '#00000',
        });
    }
};
