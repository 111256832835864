import React from 'react';
import { Text } from 'pyatka-ui';
import styled from 'styled-components';
import { useScrollDirection } from 'hooks';

interface TitleProps {
    title: string;
    type?: 'static' | 'animation';
}

export const Title: React.FC<TitleProps> = ({ type = 'static', title }) => {
    if (type === 'static') {
        return <StaticTitle title={title} />;
    } else if (type === 'animation') {
        return <AnimationTitle title={title} />;
    }
    return null;
};

// --- Static Title
const StaticTitle: React.FC<{ title: string }> = ({ title }) => (
    <Text skeleton={title?.length < 1} {...titleStyle}>
        {title}
    </Text>
);

// --- Animation Title
const AnimationTitle: React.FC<{ title: string }> = ({ title }) => {
    const { scrollY } = useScrollDirection();
    return (
        <TitleAnimation skeleton={title.length < 1} hideTitle={scrollY <= 30} {...titleStyle}>
            {title}
        </TitleAnimation>
    );
};

const TitleAnimation = styled(Text)<{ hideTitle: boolean }>`
    transform: ${({ hideTitle }) => (hideTitle ? 'translateY(50px)' : 'translateY(0px)')};
    transition: transform 0.3s ease-in-out;
`;

// --- common styles
const titleStyle: any = {
    color: 'text.main',
    fontSize: 4,
    lineClamp: 1,
    fontWeight: 600,
    lineHeight: '21px',
    skeletonProps: {
        borderRadius: '12px',
        width: '13em',
        height: '1.5em',
    },
    style: {
        wordBreak: 'break-all',
    },
};
