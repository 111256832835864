import React from 'react';
import { Box, FlexBox } from 'pyatka-ui';
import { EmptyContentBlock, SupermarketCard } from 'components';
import { SupermarketsCardsLayoutProps } from './types';
// import styles from './SupermarketCards.module.scss';

export const SupermarketCardsLayout: React.FC<SupermarketsCardsLayoutProps> = ({
    supermarket_list,
}) => {
    return (
        <React.Fragment>
            <div style={{ overflow: 'hidden' }}>
                <FlexBox flexWrap={'wrap'}>
                    {supermarket_list.length > 0 &&
                        supermarket_list.map((supermarket: any, idx: number) => (
                            <Box
                                key={`${supermarket.companyId}-${idx}`}
                                width={{ default: '100%', md: 'calc(33.33% - 15px)' }}
                                mr={{ default: '0', md: '15px' }}
                                mb={{ default: '15px' }}
                            >
                                <SupermarketCard
                                    companyId={supermarket.companyId}
                                    companyName={supermarket.companyName}
                                    address={supermarket.address}
                                    desc={supermarket.companyDescription}
                                    image={supermarket.image}
                                    skeleton={false}
                                />
                            </Box>
                        ))}
                </FlexBox>
            </div>
            {supermarket_list && supermarket_list.length === 0 && (
                <Box>
                    <EmptyContentBlock
                        title="empty"
                        icon="empty_box"
                        redirectText="to_main"
                        description="no_stores_available_in_this_city"
                        redirectTo={{ name: 'index-page' }}
                    />
                </Box>
            )}
        </React.Fragment>
    );
};
