import React, { useEffect, useLayoutEffect } from 'react';
import 'style/index.scss';
import TagManager from 'react-gtm-module-defer';
import { Helmet } from 'react-helmet-async';
import { AppThemeProvider } from 'pyatka-ui';
import dayjs from 'dayjs';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import SmartBanner from 'react-smartbanner';
import isEmpty from 'lodash/isEmpty';
import { Jusan_dark } from 'style/themes/Darkmode/jusan_dark';
import MainAppLoader from 'app/MainApp.Loader';
import { MainAppProps } from 'app/types';
import favicon from 'shared/assets/favicon.svg';
import Logo from 'shared/assets/logoJysan.png';
import { AppMetricEvents } from 'AppMetric';
import { AmpEvents } from 'metrics';
import SWRegister from 'utils/swRegister';
import { Jusan } from 'style/themes/Default/jusan_default';
import { getFingerprint, saveFingerprint } from 'data';
import { AppRouterProvider } from 'router/context';
import { isNative } from 'utils/platform';
import { MainAppLayout } from './MainApp.Layout';
import MainAppAgeVerify from './MainApp.AgeVerify';
// Locale
import 'dayjs/locale/ru';
import 'react-smartbanner/dist/main.css';
import { SetApiInstancesHeader } from 'services';

dayjs.locale('ru');

// const GTM_ID = 'GTM-PLG4G6M'; // test4
// const GTM_ID = 'G-20MKHW3REZ'; // prod
// const GTM_ID = 'GTM-5QTK5M8'; // prod
const GTM_ID = 'GTM-WLN3F7X4'; // prod

// const FINGERPRINT_API_KEY = 'BzSmMK5ILDTBo2ya4Ya9';
export const PROD_CONDITION = __BROWSER__ && __IS_PROD__ && process.env.NODE_ENV !== 'development';

const useIsomorphicLayoutEffect = __BROWSER__ ? useLayoutEffect : useEffect;

export const MainApp: React.FC<MainAppProps> = (_props) => {
    const initUtils = () => {
        AppMetricEvents.jmart_open();
        process.env.NODE_ENV === 'production' && SWRegister();
        TagManager.initialize({
            gtmId: GTM_ID,
        });
        AmpEvents.init();
    };

    useIsomorphicLayoutEffect(() => {
        const id = setTimeout(() => PROD_CONDITION && initUtils(), 4000);

        if (__BROWSER__) {
            // YOHE пока что не нужен. отключил временно
            // (function (w, d, u, h, s) {
            //     w._yoheSettings = { id: 'vad5nu378lrdzon561os4bw6' };
            //     h = d.getElementsByTagName('head')[0];
            //     s = d.createElement('script');
            //     s.async = 1;
            //     s.src = u;
            //     h.appendChild(s);
            // })(window, document, 'https://cdn.yohe.io/widget.js');

            try {
                const saveFingerPrint = (fingerprint: string) => {
                    window.__HYBRIDCONFIG__.fingerprint_id = fingerprint;
                    saveFingerprint(fingerprint);
                    SetApiInstancesHeader('Fingerprint-Id', fingerprint);
                };
                const fingerprint = getFingerprint();
                const isFingerprintEmpty = Boolean(isEmpty(fingerprint));
                if (isFingerprintEmpty) {
                    const fpPromise = FingerprintJS.load();
                    // Analyze the visitor when necessary.
                    fpPromise
                        .then((fp: any) => fp.get())
                        .then((result: any) => {
                            saveFingerPrint(result.visitorId);
                        });
                } else {
                    saveFingerPrint(fingerprint);
                }
            } catch (e) {
                console.log(e);
            }
        }

        return () => clearTimeout(id);
    }, []);

    return (
        <>
            <AppThemeProvider
                iconsLib={require.context('../assets/icons/normalized', false, /\.svg$/)}
                importedThemes={{ Jusan_dark, Jusan }}
                initialTheme={'Jusan'}
                ssr={{ Icon: __SERVER__ }}
            >
                {/* AGE VERIFICATION */}
                <MainAppLoader />
                {!isNative() ? (
                    <SmartBanner
                        title="Jusan"
                        price={{
                            ios: 'Бесплатно',
                            android: 'Бесплатно',
                        }}
                        storeText={{
                            ios: 'в App Store',
                            android: 'в Google Play',
                        }}
                        button="Открыть"
                    />
                ) : null}
                <MainAppAgeVerify />
                <Helmet>
                    {/* Favicon */}
                    <link rel="icon" type="image/png" href={favicon} sizes="16x16" />
                    {/* Web fallback */}
                    <meta property="al:web:should_fallback" content="false" />
                    {/* Open Graph */}
                    <meta property="og:image" content={Logo} />
                </Helmet>
                {/* <ApiEnvMode /> */}
                <div id="app-wrapper">
                    <AppRouterProvider>
                        <MainAppLayout />
                    </AppRouterProvider>
                </div>
            </AppThemeProvider>
        </>
    );
};
