import React, { useCallback, useState } from 'react';
import { Box, Button, Checkbox, FlexBox, Space, Text, Textarea, Collapse } from 'pyatka-ui';
import { useDispatch } from 'react-redux';
import { CancelReasonInit } from 'modules/OrdersService/OrdersService.actions';
import { Dialog } from 'plugins/dialog';
import { useI18n } from 'hooks/useI18n';
import { AppToaster } from 'plugins/app-toaster';
import { ICancelReason } from 'pages/ClientOrderDetailPage/types';

const DEFAULT_SELECTED_REASON: ICancelReason = {
    key: '',
    value: '',
    is_commented: false,
};

export const CancelReasonsModal = ({ reasons, order_id }: any) => {
    const [selectedReason, setSelectedReason] = useState(DEFAULT_SELECTED_REASON);
    const [cancelComment, setCancelComment] = useState('');

    const handleCommentChange = (e: any) => {
        const { value } = e;
        setCancelComment(value);
    };

    const dispatch = useDispatch();

    const { $t } = useI18n();

    // @ts-ignore
    const reasonsDescription = React.useMemo(() => {
        return (
            reasons &&
            reasons?.data &&
            reasons?.data?.map((reason: any) => {
                return {
                    key: reason?.code,
                    value: reason?.name,
                    is_commented: reason?.is_commented,
                    // eslint-disable-next-line security/detect-object-injection
                };
            })
        );
    }, [reasons]);

    const onCancelReasonClick = async () => {
        if (isValidReason()) {
            try {
                // @ts-ignore
                dispatch(
                    CancelReasonInit({ order_id, cancelReason: selectedReason?.key, cancelComment })
                );

                /* TODO: dialog should not be closed */
                Dialog.closeDialog();
            } catch (e) {
                console.error(e);
            }
        }
    };

    const isCommentRequired = React.useMemo(() => {
        return selectedReason?.is_commented;
    }, [selectedReason?.is_commented]);

    const makeAlert = (body: any) => {
        AppToaster.info({ body });
    };

    const isValidReason = useCallback(() => {
        if (selectedReason?.key?.length < 1) {
            makeAlert($t('select_cancel_reason'));
            return false;
        }

        if (isCommentRequired && cancelComment?.length < 1) {
            makeAlert($t('enter_cancel_reason_comment'));
            return false;
        }

        return true;
    }, [selectedReason, cancelComment]);

    const isValid =
        selectedReason?.key?.length > 0 && (isCommentRequired ? cancelComment.length > 0 : true);

    const onReasonClick = (elem: ICancelReason) => {
        if (elem.key === selectedReason.key) {
            setSelectedReason(DEFAULT_SELECTED_REASON);
            return;
        }

        setSelectedReason(elem);
        setCancelComment('');
    };

    // @ts-ignore
    return (
        <FlexBox flexDirection={'column'}>
            <FlexBox flexDirection={'column'}>
                {reasonsDescription &&
                    reasonsDescription?.map((elem: any, idx: number) => {
                        // @ts-ignore
                        return (
                            <Box
                                key={idx}
                                borderBottom={1}
                                borderColor={'rgba(190, 190, 182, 0.2)'}
                                pb={'8px'}
                                pt={'14px'}
                                cursor={'pointer'}
                            >
                                <Checkbox
                                    checked={elem.key === selectedReason?.key}
                                    onChange={() => onReasonClick(elem)}
                                >
                                    <FlexBox>
                                        <Text lineHeight={'22px'} fontSize={{ default: '16px' }}>
                                            {elem.value}
                                        </Text>
                                    </FlexBox>
                                </Checkbox>
                                <Box marginLeft={'36px'}>
                                    <Collapse
                                        open={isCommentRequired && elem.key === selectedReason?.key}
                                    >
                                        <Textarea
                                            name={'comment'}
                                            border={'none'}
                                            borderBottom={'1px solid rgba(190, 190, 182, 0.2)'}
                                            rows={2}
                                            maxLength={100}
                                            onChange={handleCommentChange}
                                            placeholder={$t('comment')}
                                            value={cancelComment}
                                        />
                                    </Collapse>
                                </Box>
                            </Box>
                        );
                    })}

                <Space height={'100px'} />
            </FlexBox>

            <Box
                position={'absolute'}
                bottom={0}
                width={'100%'}
                left={0}
                padding={'15px'}
                backgroundColor={'white'}
            >
                <Button
                    mt={1}
                    padding={'18px 0'}
                    variant={isValid ? 'brand' : 'secondary'}
                    color={isValid ? 'text.reverse' : 'text.secondary'}
                    styleType={'contained'}
                    borderRadius={'22px'}
                    width={'100%'}
                    // disabled={!isValid}
                    onClick={onCancelReasonClick}
                >
                    <Text color={isValid ? 'text.reverse' : 'text.primary'}>{$t('send')}</Text>
                </Button>
            </Box>
        </FlexBox>
    );
};
