import * as queryString from 'query-string';

import {
    FILTER_BEST_PRICE,
    FILTER_COMPANY_IDS,
    FILTER_HAS_DISCOUNT,
    FILTER_INDEX_PRICE,
    FILTER_MERCHANT_ID,
    FILTER_STYLE_CHECKBOX,
    FILTER_STYLE_PRICE,
    FILTER_VARIANT_KEY,
} from 'modules/ProductsList/ProductsList.helpers';

export const removePageFromQS = (url: string) => {
    const { page, ...rest } = queryString.parse(url);

    return [queryString.stringify(rest, { encode: false }), page];
};

/* -------- convert from filter[] to  { feature_id: variants[], ...} -------- */
export const toObjectVariants = (filters: any[] = []) =>
    filters.reduce(
        (
            objectFilters: any,
            { filter_style, feature_id, variants, filter: title, max, min, description }: any
        ) => {
            return {
                ...objectFilters,
                [feature_id]: {
                    filter_style: filter_style || FILTER_STYLE_PRICE,
                    feature_id,
                    variants: filter_style === FILTER_STYLE_CHECKBOX ? variants : { min, max },
                    title,
                    description,
                },
            };
        },
        {}
    );

/* --------------------------- Adskaya drochilnya --------------------------- */
export const getActiveFiltersQueryString = (
    { price_from, price_to, best_price, has_discount, ...qsObject }: any,
    filtersObject: any = {}
) => {
    return (
        Object.keys(qsObject)
            /* ---------------- Get only keys including feature_variants ---------------- */
            // @ts-ignore
            .filter((f) => f.includes(FILTER_VARIANT_KEY) || f.includes(FILTER_COMPANY_IDS))
            .map((i) => {
                return i;
            })

            /* ------------------ Reduce to one object with filters key ----------------- */
            .reduce(
                (acc: Record<string, any>, feat) => {
                    /* --------------- get feature id from feature_variants[18][0] -------------- */
                    const [_, key] = feat.includes(FILTER_COMPANY_IDS)
                        ? ['', FILTER_MERCHANT_ID]
                        : (feat.match(/\[(.*?)\]/) as string[]);
                    return {
                        ...acc,
                        [key]: {
                            ...acc[`${key}`],
                            /* --------------- find variant from variants[] of feature_id --------------- */
                            ...(filtersObject[`${key}`]
                                ? {
                                      [qsObject[`${feat}`]]: filtersObject[
                                          `${key}`
                                      ]?.variants?.find(
                                          (i: any) => i.variant_id == qsObject[`${feat}`]
                                      ),
                                  }
                                : {}),
                        },
                    };
                },
                {
                    ...(price_from || price_to
                        ? {
                              [FILTER_INDEX_PRICE]: priceObjectHandler(
                                  price_from,
                                  price_to,
                                  'min',
                                  'max'
                              ),
                          }
                        : {}),
                    ...(best_price ? { [FILTER_BEST_PRICE]: 1 } : {}),
                    ...(has_discount ? { [FILTER_HAS_DISCOUNT]: 1 } : {}),
                }
            )
    );
};

export const priceObjectHandler = (min, max, minKey = 'price_from', maxKey = 'price_to') => ({
    ...(min ? { [minKey]: min } : {}),
    ...(max ? { [maxKey]: max } : {}),
});

const cl = [FILTER_VARIANT_KEY, 'price_from', 'price_to', FILTER_COMPANY_IDS];

export const removeFeatureVariantKeys = (obj) =>
    Object.keys(obj)
        .filter((i) => cl.every((c) => !i.includes(c)))
        .reduce((a, b) => ({ ...a, [b]: obj[b] }), {});

const c = {
    'COMPANY_IDS[0]': '746',
    'category_id': '1391',
    'feature_variants[18][0]': '123976',
};
