import { ApiGwRequest } from 'services';
import { REPEAT_ORDER_REQ_INDICATOR } from 'keys';

export const repeatOrderRequest = (data: any) => {
    return ApiGwRequest({
        indicator: REPEAT_ORDER_REQ_INDICATOR,
        url: 'order/v1/order/repeat',
        method: 'post',
        data,
    });
};

export const getReasonStatuses = () => {
    return ApiGwRequest({
        url: 'order/v1/cancel-reason/customer',
        method: 'get',
    });
};

export const OpenPostomatLocker = (data: { order_id: string; locker_code: string }) => {
    return ApiGwRequest({
        url: 'parcel-locker/locker/open',
        /* TODO: check if the method is correct */
        method: 'post',
        data,
    });
};

