import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import WishlistCollectionPageLayout from 'pages/WishlistCollectionPage/WishlistCollectionPage.Layout';
import { getWishlistCollectionProducts } from 'modules/Wishlist/Wishlist.selectors';
import { AppToaster } from 'plugins/app-toaster';
import { useCartService } from 'contexts/useCartService';
import { useLoader } from 'contexts/useLoader';
import { Loader } from 'plugins/loader';
import { Dialog } from 'plugins/dialog';
import CollectionEditModal from 'pages/WishlistCollectionPage/components/CollectionEditModal';
import { isNative } from 'utils/platform';
import { nativeCall } from 'hybrid';
import { AppMetricEvents } from 'AppMetric';

const WishlistCollectionPageContainer = (props) => {
    const { getCollectionProducts, getWishlistData, clearReduxWishlist } = props;
    const { collection_id }: any = useParams();
    const { cartSnapshotEdit } = useCartService();
    const { isLoading } = useLoader();
    const isFavoriteLoading = isLoading(Loader.favorite.indicator);
    const [isAddToCartLoading, setIsAddToCartLoading] = React.useState(false);

    const collection = useSelector((state) => getWishlistCollectionProducts(state, collection_id));
    const { cartItemByOffer } = useCartService();

    const isAllProductsInCart = React.useMemo(() => {
        return collection.products.every((product) => {
            const item = cartItemByOffer(product.min_price_product_id.toString() || '');
            return item !== undefined && item.amount > 0;
        });
    }, [cartItemByOffer, collection.products]);
    const handleAddToCart = () => {
        setIsAddToCartLoading(true);
        const prepared_products = collection.products.map((product) => {
            return {
                amount: 1,
                product_id: product?.master_product_id,
                offer_id: product?.min_price_product_id,
            };
        });
        if (prepared_products.length > 0) {
            AppToaster.success({ body: 'order_added_to_cart' });
            setIsAddToCartLoading(false);
            cartSnapshotEdit(prepared_products);
        } else {
            setIsAddToCartLoading(false);
            AppToaster.warning({ body: 'products_out_of_stock' });
        }

        AppMetricEvents.jmart_add_to_cart2({
            source: 'from_collection',
            data: {
                ...prepared_products,
            },
        });
    };
    useEffect(() => {
        return () => {
            clearReduxWishlist({});
        };
    }, []);
    useEffect(() => {
        // getWishlistData({ full: false });
        // getCollectionProducts({ collection_id });
    }, [getCollectionProducts]);
    const onClickMoreBtn = () => {
        Dialog.openDialog({
            body: CollectionEditModal,
            props: {
                onClose: () => {
                    Dialog.closeDialog();
                },
                collection,
            },
            isOwnModal: true,
        });
    };
    const onShare = () => {
        if (isNative() && window) {
            nativeCall({
                type: 'share',
                payload: {
                    value: `https://jmart.kz${window?.location?.pathname}${window?.location?.search}`,
                },
            });
        } else {
            navigator.clipboard.writeText(
                `https://jmart.kz${window?.location?.pathname}${window?.location?.search}`
            );
            AppToaster.success({ body: 'Ссылка успешно скопирована' });
        }
    };
    const layoutProps = {
        ...collection,
        handleAddToCart,
        isAddToCartLoading,
        disableAddToCartBtn: isAllProductsInCart,
        collection_id,
        onClickMoreBtn,
        onShare,
        ...props,
    };
    // if (isFavoriteLoading) {
    //     return <WishListLoading />;
    // }
    // if (collection.products?.length === 0 && !isFavoriteLoading) {
    //     return (
    //         <EmptyContentBlock
    //             icon={'empty-wishlist'}
    //             title="favorites_empty"
    //             redirectText="to_main"
    //             redirectTo={{ name: 'index-page' }}
    //             titleColor={'text.main'}
    //             description={'add_what_you_like_wishlist'}
    //         />
    //     );
    // }
    return (
        <>
            <WishlistCollectionPageLayout {...layoutProps} />
        </>
    );
};

export default WishlistCollectionPageContainer;
