import React from 'react';
import { FlexBox, Icon, Image, Text } from 'pyatka-ui';
import { AppLink, Rating } from 'components';
import { AppMetricEvents } from 'AppMetric';
import { isNative } from 'utils/platform';
import { nativeCall } from 'hybrid';
interface ICompanyInfoProps {
    company?: string;
    formatted_merchant_phone?: string | null;
    company_info: any;
    company_id: number;
}
const CompanyInfo = (props: ICompanyInfoProps) => {
    const { company, formatted_merchant_phone, company_info, company_id } = props;
    const onPhoneClick = (phone: string) => {
        AppMetricEvents.call_merchant();
        if (isNative()) {
            nativeCall({
                type: 'call',
                payload: {
                    phone,
                },
            });
        }
    };

    return (
        <FlexBox alignItems={'center'} justifyContent={'space-between'}>
            <FlexBox gap={'16px'}>
                <AppLink
                    to={{
                        name: 'merchant',
                        query: {
                            company_id,
                        },
                    }}
                >
                    <Text color={'text.main'} fontWeight={'bold'}>
                        {company}
                    </Text>
                </AppLink>
                <Rating rating={company_info?.rating / 100} />
            </FlexBox>
            <FlexBox alignItems={'center'}>
                <Image
                    src={company_info?.logos?.theme?.image_path}
                    // src={undefined}
                    marginRight={'7px'}
                    width={'30px'}
                    height={'30px'}
                    hideOnError={true}
                />
                {Boolean(formatted_merchant_phone) && (
                    <div onClick={() => onPhoneClick(formatted_merchant_phone!)}>
                        <a href={`tel:${formatted_merchant_phone}`}>
                            <Icon icon={'phone'} width={'30px'} height={'30px'} />
                        </a>
                    </div>
                )}
            </FlexBox>
        </FlexBox>
    );
};

export default CompanyInfo;
