import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { ArticlesInitialState, SingleArticle } from 'modules/Articles/types';

const StaticPagesInit: CaseReducer<ArticlesInitialState, PayloadAction<{}>> = (
    _state,
    _action
) => {};

const StaticSinglePagesInit: CaseReducer<ArticlesInitialState, PayloadAction<{}>> = (
    _state,
    _action
) => {};

const SingleStaticPageSucceed: CaseReducer<
    ArticlesInitialState,
    PayloadAction<{ singleStaticPage: SingleArticle; slug: string }>
> = (_state, _action) => {
    _state.singleStaticPage = _action.payload.singleStaticPage;
};

const StaticPagesSucceed: CaseReducer<ArticlesInitialState, PayloadAction<any>> = (
    _state,
    _action
) => {
    // console.log(_action.payload);

    const prepared_payload: any = {};

    Object.keys(_action.payload).map((block_key: any) => {
        const block = _action.payload[block_key.toString()];
        block.static_pages = block.static_pages.map((page: any) => {
            page.description = '';
            return page;
        });
        prepared_payload[block_key.toString()] = block;
    });
    // @ts-ignore
    _state.staticPagesContent = prepared_payload;
};

const StaticPagesFailed: CaseReducer<ArticlesInitialState, PayloadAction<{}>> = (
    _state,
    _action
) => {};

export default {
    StaticPagesInit,
    StaticPagesSucceed,
    SingleStaticPageSucceed,
    StaticPagesFailed,
    StaticSinglePagesInit,
};
