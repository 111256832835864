import { createGlobalStyle, withTheme } from 'styled-components';
import { IThemeConfig } from 'pyatka-ui/dist/themes/types';

type GlobalThemeProps = {
    theme: IThemeConfig;
    isWhiteBg: boolean;
};

const variables = {
    appBrandColor: '#f05323',
    appGrayColor: '#f6f6f5',
    black: '#000',
    lineHeightBase: '1.5',
    headingsMarginBottom: '1rem * .5',
    paragraphMarginBottom: '1rem',
    dtFontWeight: '700',
    fontWeightBolder: 'bolder',
    linkDecoration: 'none',
    linkHoverDecoration: 'underline',
    tableCellPadding: '.75rem',
    tableCaptionColor: '#6c757d',
    tableThFontWeight: 'null',
    labelMarginBottom: '.5rem',
    bodyBg: '#000',
    bodyColor: '#111',
};
// @ts-ignore
const GlobalStyles = createGlobalStyle`
    *,
    *::before,
    *::after {
        box-sizing: border-box;
        font-family: 'JB Sans', sans-serif !important;
    }

    .display-linebreak {
        white-space: pre-line;
    }

    html {
        line-height: 1.15;
        -webkit-text-size-adjust: 100%;
        -webkit-tap-highlight-color: transparent;
    }

    article,
    aside,
    figcaption,
    figure,
    footer,
    header,
    hgroup,
    main,
    nav,
    section {
        display: block;
    }

            .article-content {
                color: ${(props: GlobalThemeProps) => props.theme?.colors?.text?.main};
            }

			body {
				margin: 0; // 1
				line-height: ${variables.lineHeightBase};
				text-align: left; // 3
				color: ${variables.bodyColor};
				//background-color: ${variables.bodyBg};
				font-family: 'JB Sans', sans-serif !important;
                background: ${(props) =>
                    props?.isWhiteBg
                        ? props.theme?.colors?.block?.main
                        : props.theme?.colors?.block?.secondary}
            }

    [tabindex='-1']:focus:not(:focus-visible) {
        outline: 0 !important;
    }

    hr {
        box-sizing: content-box; // 1
        height: 0; // 1
        overflow: visible; // 2
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin-top: 0;
        margin-bottom: ${variables.headingsMarginBottom};
    }

    p {
        margin-top: 0;
        margin-bottom: ${variables.paragraphMarginBottom};
    }

    abbr[title],
    abbr[data-original-title] {
        text-decoration: underline dotted;
        cursor: help;
        border-bottom: 0;
        text-decoration-skip-ink: none;
    }

    address {
        margin-bottom: 1rem;
        font-style: normal;
        line-height: inherit;
    }

    ol,
    ul,
    dl {
        margin-top: 0;
        margin-bottom: 1rem;
    }

    ol ol,
    ul ul,
    ol ul,
    ul ol {
        margin-bottom: 0;
    }

    dt {
        font-weight: ${variables.dtFontWeight};
    }

    dd {
        margin-bottom: 0.5rem;
        margin-left: 0;
    }

    blockquote {
        margin: 0 0 1rem;
    }

    b,
    strong {
        font-weight: ${variables.fontWeightBolder};
    }

    sub,
    sup {
        position: relative;
        line-height: 0;
        vertical-align: baseline;
    }

    sub {
        bottom: -0.25em;
    }

    sup {
        top: -0.5em;
    }

    a {
        text-decoration: ${variables.linkDecoration};
        background-color: transparent;

        &:hover {
            text-decoration: ${variables.linkDecoration};
        }
    }

    a:not([href]):not([class]) {
        color: inherit;
        text-decoration: none;

        &:hover {
            color: inherit;
            text-decoration: none;
        }
    }

    pre {
        margin-top: 0;
        margin-bottom: 1rem;
        overflow: auto;
        -ms-overflow-style: scrollbar;
    }

    figure {
        margin: 0 0 1rem;
    }

    img {
        vertical-align: middle;
        border-style: none;
    }

    svg {
        overflow: hidden;
        vertical-align: middle;
    }

    table {
        border-collapse: collapse;
    }

    caption {
        padding-top: ${variables.tableCellPadding};
        padding-bottom: ${variables.tableCellPadding};
        color: ${variables.tableCaptionColor};
        text-align: left;
        caption-side: bottom;
    }

    th {
        font-weight: ${variables.tableThFontWeight}; // 1
        text-align: inherit; // 2
        text-align: -webkit-match-parent; // 3
    }

    label {
        display: inline-block;
        margin-bottom: ${variables.labelMarginBottom};
    }

    button {
        border-radius: 0;
    }

    button:focus:not(:focus-visible) {
        outline: 0;
    }

    input,
    button,
    select,
    optgroup,
    textarea {
        margin: 0;
    }

    button,
    input {
        overflow: visible;
    }

    button,
    select {
        text-transform: none;
    }

    [role='button'] {
        cursor: pointer;
    }

    select {
        word-wrap: normal;
    }

    button,
    [type='button'],
    [type='reset'],
    [type='submit'] {
        -webkit-appearance: button;
    }

    button::-moz-focus-inner,
    [type='button']::-moz-focus-inner,
    [type='reset']::-moz-focus-inner,
    [type='submit']::-moz-focus-inner {
        padding: 0;
        border-style: none;
    }

    input[type='radio'],
    input[type='checkbox'] {
        box-sizing: border-box;
        padding: 0;
    }

    textarea {
        overflow: auto;
        resize: vertical;
    }

    fieldset {
        min-width: 0;
        padding: 0;
        margin: 0;
        border: 0;
    }

    legend {
        display: block;
        width: 100%;
        max-width: 100%;
        padding: 0;
        margin-bottom: 0.5rem;
        white-space: normal;
    }

    progress {
        vertical-align: baseline;
    }

    [type='number']::-webkit-inner-spin-button,
    [type='number']::-webkit-outer-spin-button {
        height: auto;
    }

    [type='search'] {
        outline-offset: -2px;
        -webkit-appearance: none;
    }

    [type='search']::-webkit-search-decoration {
        -webkit-appearance: none;
    }

    ::-webkit-file-upload-button {
        font: inherit;
        -webkit-appearance: button;
    }

    output {
        display: inline-block;
    }

    summary {
        display: list-item;
        cursor: pointer;
    }

    template {
        display: none;
    }

    [hidden] {
        display: none !important;
    }

    button,
    [type='button'],
    [type='reset'],
    [type='submit'] {
        &:not(:disabled) {
            cursor: pointer;
        }
    }
`;

export default withTheme(GlobalStyles);
