import React from 'react';
import { FlexBox, Text, usePyatka, Box } from 'pyatka-ui';
import { signs } from 'utils/symbols';
import { ProductPriceNewProps } from 'components/ProductCard/types';
import ProductLabels from './ProductLabels';

type ProductPriceProps = Pick<
    ProductPriceNewProps,
    'price_formatted' | 'discountPrice_formatted' | 'label' | 'isLoading' | 'layout' | 'isZeroPrice'
>;

const ProductPrice: React.FC<ProductPriceProps> = ({
    label,
    layout,
    isLoading,
    price_formatted: price,
    discountPrice_formatted: discountPrice,
    isZeroPrice,
}) => {
    const { scales } = usePyatka();
    const hasLabels = !(label?.bonusLabel || label?.installment);

    const fontSize = React.useMemo(() => {
        if (layout === 'main') {
            return { default: '15.5px', md: '24px' };
        }
        if (layout === 'small') {
            return { default: '14px', md: '24px' };
        }
        return { default: '18px', md: '24px' };
    }, [layout]);

    return (
        <FlexBox
            gap="8px"
            width="100%"
            alignItems="center"
            height={scales?.isMobile ? '27px' : '31px'}
            justifyContent={{
                default: scales?.isMobile || layout === 'main' ? 'space-between' : 'initial',
                md: layout === 'new' || layout === 'main' ? 'space-between' : 'initial',
            }}
        >
            <Text
                fontWeight="700"
                fontSize={fontSize}
                skeleton={isLoading}
                lineHeight={{ default: '18px', md: ' 30.72px' }}
                color={isZeroPrice ? 'text.secondary' : discountPrice ? 'text.brand' : 'text.main'}
                skeletonProps={{
                    animation: true,
                    width: '8em',
                    height: '24px',
                    borderRadius: '8px',
                }}
            >
                {isZeroPrice ? 'Нет в наличии' : `${discountPrice || price} ${signs.tg}`}
            </Text>
            <Box>
                <ProductLabels
                    show={!hasLabels}
                    isLoading={isLoading}
                    label={label}
                    layout={layout}
                />
            </Box>
        </FlexBox>
    );
};

export default ProductPrice;
