import { AmpEvents } from 'metrics';
import {
    anyToInt,
    appMetricCallNative,
    createClickEvent,
    AppMetricItemParser,
} from 'AppMetric/helpers';
import {
    AppItemMetric,
    AppMetricItem,
    AppMetricJmartCartCheckoutTypes,
    AppMetricJmartCartGroupCheckoutTypes,
    AppMetricJmartCartProductAddTypes,
    AppMetricJmartCartProductRemoveTypes,
    AppMetricJmartFilterClick,
    AppMetricJmartFilterOptionClick,
    AppMetricJmartJusanCreditApplyTypes,
    AppMetricJmartProductInstantBuyTypes,
    BlockClick,
    CheckoutDelivery,
    IKeywordPayload,
    JmartReviewProduct,
    JmartSupermarketDetailView,
    JmartViewCart,
    IJmartCategoryPayload,
    IBannerClickPayload,
    ICheckoutStartPayload,
    ISourceDataPayload,
    IPromotionViewPayload,
    IModalWindowClickPayload,
    ISearchFmcgPayload,
    IMerchantEventType,
    IMerchantEventPayload,
    ISupermarketCategoryClickPayload,
    AppMetricJmartCategoryView,
    IAuthPayload,
    IAuthGetSMsPayload,
    IProductShow,
} from 'AppMetric/types';
import { Product } from 'modules/ProductsList/types';

// ======== DEPRECATED ======== //

/**
 * type: `jmart_search_active`
 * @description при поиске товара
 * @deprecated
 **/
const jmart_search_active = (payload: { search_value: string }) => {
    appMetricCallNative({ type: 'jmart_search_active', payload });
};

/**
 * type: `jmart_block_click`
 * @description при клике на любом из баннеров на главной
 * @deprecated
 **/
const block_click = (props: BlockClick) => {
    const payload = {
        block_name: props.title,
        block_type: props.type,
        source: 'block_click',
    };
    appMetricCallNative({ type: 'jmart_block_click', payload });
};

/**
 * type: `jmart_product_share`
 * @description делится айтемом
 * - Title: как часто люди делятся товаром, тест кнопки
 * @deprecated
 **/
const jmart_product_share = (props: { link: string }) => {
    const payload: {
        link: string;
    } = {
        link: props.link,
    };
    appMetricCallNative({
        type: 'jmart_product_share',
        payload,
    });
};

// ========= ALL EVENTS ========= //

/**
 * type: `jmart_open`
 * @description при открытии магазина
 **/
const jmart_open = () => {
    appMetricCallNative({ type: 'jmart_open' });
};

/**
 * type: `jmart_main_view`
 * @description
 * - Title: ключевой этап воронки. замерить перформанс
 * - Action/trigger: посмотрел главную
 **/
const jmart_main_view = createClickEvent('jmart_main_view');

/**
 * type: `jmart_category` или `jmart_catalog_category2_click`
 * @description при выборе категории
 **/
const jmart_category = (payload: IJmartCategoryPayload) => {
    const type: Exclude<IJmartCategoryPayload['type'], undefined> =
        payload?.type || 'jmart_category';

    AmpEvents.sendEvent(type, payload);
    appMetricCallNative({ type, payload });
};

/**
 * type: `jmart_city_change`
 * @description
 * - Title: замерить правильно ли работает автоопределение города
 * - Action/trigger: выбирает город на главной
 **/
const jmart_city_change = (payload: { city_id: string }) => {
    appMetricCallNative({ type: 'jmart_city_change', payload });
};

/**
 * type: `jmart_product_view`
 * @description при просмотре страницы карточки товара
 **/
const jmart_product_view = (props: Product) => {
    const payload: AppItemMetric = {
        item: AppMetricItemParser(props),
    };
    appMetricCallNative({ type: 'jmart_product_view', payload });
};

/**
 * type: `jmart_add_to_cart`
 * @description при добавлении товара в корзину
 **/
const jmart_add_to_cart = (payload: ISourceDataPayload<any>) => {
    const type = 'jmart_add_to_cart';
    AmpEvents.sendEvent(type, payload);
    appMetricCallNative({ type, payload });
};

/**
 * type: `jmart_add_to_cart2`
 * @description при добавлении товара в корзину
 **/
const jmart_add_to_cart2 = (payload: ISourceDataPayload<any>) => {
    const type = 'jmart_add_to_cart2';
    AmpEvents.sendEvent(type, payload);
    appMetricCallNative({ type, payload });
};

/**
 * type: `jmart_add_to_cart_click`
 * @description при добавлении товара в корзину
 **/
const jmart_add_to_cart_click = (payload: ISourceDataPayload<any>) => {
    const type = 'jmart_add_to_cart_click';
    AmpEvents.sendEvent(type, payload);
    appMetricCallNative({ type, payload });
};

/**
 * type: `jmart_cart_clear`
 * @description при удаление корзины
 **/
const jmart_cart_clear = () => {
    appMetricCallNative({
        type: 'jmart_cart_clear',
    });
};

/**
 * type: `jmart_search_new`
 * @description вытаскивает значение из поиска
 **/
const jmart_search = (payload: IKeywordPayload) => {
    const type = 'jmart_search_new';
    AmpEvents.sendEvent(type, payload);
    appMetricCallNative({ type, payload });
};

/**
 * type: `jmart_banner_click`
 * @description при нажатии на любой из баннеров
 **/
const jmart_banner_click = (payload: IBannerClickPayload) => {
    const type = 'jmart_banner_click';
    AmpEvents.sendEvent(type, payload);
    appMetricCallNative({ type, payload });
};

/**
 * type: `jmart_product_click`
 * @description при нажатии на любой из продуктов
 **/
const product_click = (payload: ISourceDataPayload<any>) => {
    const type = 'jmart_product_click';
    AmpEvents.sendEvent(type, payload);
    appMetricCallNative({ type, payload });
};

/**
 * type: `jmart_category_click`
 * @description при нажатии на категорию на главной
 **/
const category_click = (props: { item_name: string; item_id: string | number }) => {
    const payload = {
        item_name: props.item_name,
        item_id: props.item_id,
    };
    appMetricCallNative({
        type: 'jmart_category_click',
        payload,
    });
};

/**
 * type: `jmart_ai_chat`
 * @description ChatGPT
 **/

interface JmartAIChat {
    type: 'open' | 'close' | 'send';
    payload: {
        product_id: string;
    };
}

const jmart_ai_chat = ({ type: temp_type, payload }: JmartAIChat) => {
    const type = `jmart_ai_chat_${temp_type}`;
    AmpEvents.sendEvent(type, payload);
    appMetricCallNative({ type, payload });
};

/**
 * type: `jmart_checkout_delivery`
 * @description при выборе доставки
 **/
const checkout_delivery = (props: CheckoutDelivery) => {
    const type = 'jmart_checkout_delivery';

    const payload: CheckoutDelivery = {
        delivery_type: props.delivery_type,
        delivery_price: props.delivery_price,
        source: 'checkout_delivery_type',
        order_price: props.order_price,
        checkout_type: props.checkout_type,
        category1: props.category1,
    };
    AmpEvents.sendEvent(type, payload);
    appMetricCallNative({ type, payload });
};

/**
 * type: `jmart_view_cart`
 * @description при просмотре корзины
 **/
const jmart_view_cart = (props: JmartViewCart) => {
    const type = 'jmart_view_cart';
    const payloadCart = props.items?.map((singleItem) => ({
        item: AppMetricItemParser(singleItem as any),
    }));
    const payload = {
        items: payloadCart,
        cart_price: props.cart_price,
    };
    AmpEvents.sendEvent(type, payload);
    appMetricCallNative({ type, payload });
};

/**
 * type: `jmart_checkout_start`
 * @description при нажатии на кнопку оформить в корзине
 **/
const jmart_checkout_start = (props: ICheckoutStartPayload) => {
    const type = 'jmart_checkout_start';

    const payload: ICheckoutStartPayload = {
        source: props.source,
    };

    if (props.order_price) {
        payload.order_price = props.order_price;
    }

    if (props.data) {
        payload.data = props.data;
    }

    if (props.items) {
        payload.items = (props.items || [])?.map((singleItem) => ({
            item: AppMetricItemParser(singleItem),
        }));
    }

    AmpEvents.sendEvent(type, payload);
    appMetricCallNative({ type, payload });
};

/**
 * type: `jmart_buy_now_click`
 * @description при нажатии на кнопку оформить сейчас в офферах на странице товара
 **/
const jmart_buy_now_click = (payload: Record<'product_id' | 'product_name', string | number>) => {
    const type = 'jmart_buy_now_click';
    AmpEvents.sendEvent(type, payload);
    appMetricCallNative({ type, payload });
};

/**
 * type: `jmart_main_promotion_view`
 * @description при списка просмотре акции на страница /promotions
 **/
const jmart_main_promotion_view = createClickEvent('jmart_main_promotion_view');

/**
 * type: `jmart_promotion_view`
 * @description при просмотре акции detail page
 **/
const jmart_promotion_view = (payload: IPromotionViewPayload) => {
    const type = 'jmart_promotion_view';
    AmpEvents.sendEvent(type, payload);
    appMetricCallNative({ type, payload });
};

/**
 * type: `jmart_wishlist_view`
 * @description открывает избранное
 * - Title: как часто люди просматривают виш лист
 **/
const jmart_wishlist_view = createClickEvent('jmart_wishlist_view');
const jmart_create_collection = createClickEvent('jmart_create_collection');
const jmart_delete_collection = createClickEvent('jmart_delete_collection');
const jmart_share_collection = createClickEvent('jmart_share_collection');
const jmart_add_to_collection = createClickEvent('jmart_add_to_collection');

/**
 * type: `jmart_wishlist_product_add`
 * @description нажимает на добавить в избранное
 * - Title: проверка как часто добавляют в избранное
 **/
const jmart_wishlist_product_add = (props: { product_id: number }) => {
    const type = 'jmart_wishlist_product_add';
    const payload: {
        product_id: number;
    } = {
        // product_name: '',
        product_id: anyToInt(props.product_id),
    };
    AmpEvents.sendEvent(type, payload);
    appMetricCallNative({
        type,
        payload,
    });
};

/**
 * type: `jmart_wishlist_product_delete`
 * @description нажимает удалить из избранного, либо на карточке товара, либо из экрана Избранного
 * - Title: проверка как часто удаляют из корзины
 **/
const jmart_wishlist_product_delete = (props: { product_id: number }) => {
    const type = 'jmart_wishlist_product_delete';
    const payload: {
        product_id: number;
    } = {
        product_id: anyToInt(props.product_id),
    };
    AmpEvents.sendEvent(type, payload);
    appMetricCallNative({
        type,
        payload,
    });
};

/* ---------------------- Cart ----------------------*/

/**
 * type: `jmart_supermarkets_view`
 * @description при просмотре списка супермаркетов
 **/
const jmart_supermarkets_view = (props: { section_id?: any }) => {
    const type = 'jmart_supermarkets_view';
    const payload = {
        category_id: props?.section_id,
        source: 'supermarkets',
    };
    AmpEvents.sendEvent(type, payload);
    appMetricCallNative({ type, payload });
};

/**
 * type: `jmart_supermarket_detail_view`
 * @description при просмотре детальной страницы супермаркета
 **/
const jmart_supermarket_detail_view = (props: JmartSupermarketDetailView) => {
    const type = 'jmart_supermarket_detail_view';
    const payload = {
        category_id: props.cIds,
        merchant_id: props.company_id,
        merchant_name: props.title,
        source: 'supermarket_detail',
    };
    AmpEvents.sendEvent(type, payload);
    appMetricCallNative({ type, payload });
};

/**
 * type: `jmart_category3_supermarkets_click`
 * @description при клике категории на детальной странице супермаркета
 **/
const jmart_category3_supermarkets_click = (payload: ISupermarketCategoryClickPayload) => {
    const type = 'jmart_category3_supermarkets_click';
    AmpEvents.sendEvent(type, payload);
    appMetricCallNative({ type, payload });
};

/**
 * type: `jmart_product_instant_buy`
 * @description нажимет на купить сразу из экрана товара
 * - Title: ключевой этап воронки. Замерить какие продукты покупают сразу
 * @deprecated
 **/
const jmart_product_instant_buy = (props: { [key: string]: string }) => {
    const payload: AppMetricJmartProductInstantBuyTypes = {
        product_name: props.title || '',
        product_id: anyToInt(props.id),
        product_price: anyToInt(props.price),
        product_department_name: props.product_type || '',
        product_company_id: anyToInt(props.offer_id),
    };
    appMetricCallNative({
        type: 'jmart_product_instant_buy',
        payload,
    });
};

/**
 * type: `jmart_cart_product_remove`
 * @description нажимает удалить из корины либо из корзины либо из страницы товара
 * - Title: Замерить какие продукты попадают к корзину, чтобы попытаться понять почему
 * @deprecated
 **/
const jmart_cart_product_remove = (props: { [key: string]: string }) => {
    const payload: AppMetricJmartCartProductRemoveTypes = {
        product_name: props.title || '',
        product_id: anyToInt(props.id),
        product_price: anyToInt(props.price),
        product_department_name: props.product_type || '',
        product_company_id: anyToInt(props.offer_id),
    };
    appMetricCallNative({
        type: 'jmart_cart_product_remove',
        payload,
    });
};

/**
 * type: `jmart_cart_checkout`
 * @deprecated
 **/
const jmart_cart_checkout = (props: { [key: string]: string }) => {
    const payload: AppMetricJmartCartCheckoutTypes = {
        product_name: props.title || '',
        product_id: anyToInt(props.id),
        product_price: anyToInt(props.price),
        product_department_name: props.product_type || '',
        product_company_id: anyToInt(props.offer_id),
    };
    appMetricCallNative({
        type: 'jmart_cart_checkout',
        payload,
    });
};

/**
 * type: `jmart_cart_checkout_group`
 * @deprecated
 **/
const jmart_cart_checkout_group = (props: { product_ids: number[]; pTotal: number }) => {
    const payload: AppMetricJmartCartGroupCheckoutTypes = {
        product_ids: props.product_ids,
        total_price: anyToInt(props.pTotal),
    };
    appMetricCallNative({
        type: 'jmart_cart_checkout_group',
        payload,
    });
};

/**
 * type: `jmart_delivery_type`
 * @deprecated
 **/
const jmart_delivery_type = (props: { order_delivery_type: string }) => {
    const payload: {
        order_delivery_type: string;
    } = {
        order_delivery_type: props.order_delivery_type,
    };
    appMetricCallNative({
        type: 'jmart_delivery_type',
        payload,
    });
};

const jmart_start_forgotten_anything = createClickEvent('jmart_start_forgotten_anything');
const jmart_continue_forgotten_anything = createClickEvent('jmart_continue_forgotten_anything');

/* --------------------------------- Payment ----------------------------------------- */

/**
 * type: `jmart_payment_type`
 * @description выбирает способ оплаты, кнопка далее
 * - Title: рассчитать какой спосок оплаты популярнее
 * @deprecated
 **/
const jmart_payment_type = (props: {
    selected_payment: {
        selected_type: string;
    };
}) => {
    const payload: {
        order_payment_type: string;
    } = {
        order_payment_type: props.selected_payment.selected_type[0],
    };
    appMetricCallNative({
        type: 'jmart_payment_type',
        payload,
    });
};

/**
 * type: `jmart_jusan_credit_apply`
 * @description Нажимает на "оформить рассрочку/кредит"
 * - Title: ключевой этап воронки. Индикация подачи на кредит/рассрочку
 * @deprecated
 **/
const jmart_jusan_credit_apply = (props: {
    product_ids: number[];
    product_payment_credit_type: string;
    product_credit_months: number;
}) => {
    const payload: AppMetricJmartJusanCreditApplyTypes = {
        product_ids: props.product_ids,
        product_payment_credit_type: props.product_payment_credit_type,
        product_credit_months: anyToInt(props.product_credit_months),
    };
    appMetricCallNative({
        type: 'jmart_jusan_credit_apply',
        payload,
    });
};

/**
 * type: `jmart_coupon_error`
 * @deprecated
 **/
const jmart_coupon_error = (props: { coupon_error_type: string }) => {
    const payload: {
        coupon_error_type: string;
    } = {
        coupon_error_type: props.coupon_error_type,
    };
    appMetricCallNative({
        type: 'jmart_coupon_error',
        payload,
    });
};

/**
 * type: `jmart_error_soon`
 **/
const jmart_error_soon = (payload: any) => {
    appMetricCallNative({
        type: 'jmart_error_soon',
        payload,
    });
};

/**
 * type: `jmart_review_product`
 **/
const jmart_review_product = (props: JmartReviewProduct) => {
    const payload = {
        product_id: props.product_id,
        review_reco: props.is_recommended,
        review_stars: props.rate,
        review_status: props.admin_status,
        review_push: props.review_push,
    };
    AmpEvents.sendEvent('jmart_send_feedback', payload);

    appMetricCallNative({
        type: 'jmart_send_feedback',
        payload,
    });
};
const jmart_leave_feedback = createClickEvent('jmart_leave_feedback');

const call_merchant = () => {
    appMetricCallNative({
        type: 'jmart_call_merchant',
    });
};

const jmart_modal_window_click = (payload: IModalWindowClickPayload) => {
    const type = 'jmart_modal_window_click';
    AmpEvents.sendEvent(type, payload);
    appMetricCallNative({ type, payload });
};

const jmart_web_cart_click = (props: { item: any }) => {
    appMetricCallNative({
        type: 'jmart_web_cart_click',
        payload: props.item,
    });
};

const jmart_qrlite_start = () => {
    appMetricCallNative({ type: 'jmart_qrlite_start' });
};

const jmart_partner_start = createClickEvent('jmart_partner_start');
const jmart_partner_commissions_view = createClickEvent('jmart_partner_commissions_view');

/**
 * type: `jmart_partner_sendrequest`
 * @description при нажатии на кнопку "Стать продавцом" в partner форме
 **/
const jmart_partner_sendrequest = (source: 'qrlite' | 'mp' | undefined = 'mp') => {
    const type = 'jmart_partner_sendrequest';
    const payload = { source };
    AmpEvents.sendEvent(type, payload);
    appMetricCallNative({ type, payload });
};

/**
 * type: `jmart_selection_click`
 * @description при нажатии на кнопку "все" на главной странице
 **/
const jmart_selection_click = (payload: IKeywordPayload) => {
    const type = 'jmart_selection_click';
    AmpEvents.sendEvent(type, payload);
    appMetricCallNative({ type, payload });
};

// ==== Main page menu (DepartmentBox) click

const jmart_selection_supermarket_click = (payload: { company_name: string }) => {
    const type = 'jmart_selection_supermarket_click';
    AmpEvents.sendEvent(type, payload);
    appMetricCallNative({ type, payload });
};

const jmart_become_partner_main_click = createClickEvent('jmart_become_partner_main_click');
const jmart_goods_main_click = createClickEvent('jmart_goods_main_click');
const jmart_pharmacy_main_click = createClickEvent('jmart_pharmacy_main_click');
const jmart_services_main_click = createClickEvent('jmart_services_main_click');
const jmart_supermarkets_main_click = createClickEvent('jmart_supermarkets_main_click');
const jmart_qrlite_main_click = createClickEvent('jmart_qrlite_main_click');
const jmart_diy_click = createClickEvent('jmart_diy_click');

const jmart_diy_home_view = () => {
    const type = 'jmart_diy_home_view';
    AmpEvents.sendEvent(type);
    appMetricCallNative({ type });
};

// ==== END Main page menu (DepartmentBox) click END

const jmart_catalog_footer_click = createClickEvent('jmart_catalog_footer_click');

const jmart_modal_window_view = (payload: IKeywordPayload) => {
    const type = 'jmart_modal_window_view';
    AmpEvents.sendEvent(type, payload);
    appMetricCallNative({ type, payload });
};

const jmart_filter_click = (payload: AppMetricJmartFilterClick) => {
    appMetricCallNative({ type: 'jmart_filter_click', payload });
};

const jmart_city_change_click = () => {
    appMetricCallNative({ type: 'jmart_city_change_click' });
};

const jmart_supermarket_search_banner = createClickEvent('jmart_supermarket_search_banner');
const jmart_search_main_click = createClickEvent('jmart_search_main_click');

const jmart_search_fmcg_new = (payload: ISearchFmcgPayload) => {
    const type = 'jmart_search_fmcg_new';
    AmpEvents.sendEvent(type, payload);
    appMetricCallNative({ type, payload });
};

// ================== MERCHANT EVENTS ================

const merchantMetric = (type: IMerchantEventType) => (payload: IMerchantEventPayload) => {
    appMetricCallNative({
        type,
        payload,
    });
};

const merchantPageStart = (payload: IMerchantEventPayload) => {
    const type = 'jmart_merchant_page_start';
    AmpEvents.sendEvent(type, payload);
    appMetricCallNative({ type, payload });
};

const merchant = {
    page_start: merchantPageStart,
    page_share: merchantMetric('jmart_merchant_page_share'), // unused ???
    page_phone: merchantMetric('jmart_merchant_page_phone'),
};

const jmart_zerosearch_banner_click = (payload: IKeywordPayload) => {
    const type = 'jmart_zerosearch_banner_click';
    AmpEvents.sendEvent(type, payload);
    appMetricCallNative({ type, payload });
};

const jmart_search_main_supermarkets_click = () => {
    appMetricCallNative({
        type: 'jmart_search_main_supermarkets_click',
    });
};

const jmart_zerosearch = createClickEvent('jmart_zerosearch');
const jmart_filter_view = createClickEvent('jmart_filter_view');

const jmart_filter_option_click = (payload: AppMetricJmartFilterOptionClick) => {
    appMetricCallNative({ type: 'jmart_filter_option_click', payload });
};

// ------------- Ивенты для поиска
const jmart_search_fmcg_global_suggest = (payload: IKeywordPayload) => {
    const type = 'jmart_search_fmcg_global_suggest';
    AmpEvents.sendEvent(type, payload);
    appMetricCallNative({ type, payload });
};

const jmart_suggest_fmcg_search_new = (payload: IKeywordPayload) => {
    const type = 'jmart_suggest_fmcg_search_new';
    AmpEvents.sendEvent(type, payload);
    appMetricCallNative({ type, payload });
};

const jmart_suggest_search_new = (payload: ISourceDataPayload<string>) => {
    const type = 'jmart_suggest_search_new';
    const newPayload = {
        keyword: payload.data,
        source: payload.source,
    };
    AmpEvents.sendEvent(type, newPayload);
    appMetricCallNative({ type, payload: newPayload });
};
// ------------

const jmart_history_search_new = (payload: IKeywordPayload) => {
    const type = 'jmart_history_search_new';
    AmpEvents.sendEvent(type, payload);
    appMetricCallNative({ type, payload });
};

const jmart_qrlite_become_partner_click = () => {
    appMetricCallNative({ type: 'jmart_qrlite_become_partner_click' });
};

const jmart_category_view = (payload: AppMetricJmartCategoryView) => {
    const type = 'jmart_category_view';
    AmpEvents.sendEvent(type, payload);
    appMetricCallNative({ type, payload });
};
const jmart_web_authorization = (payload: IAuthPayload) => {
    const type = 'jmart_web_authorization';
    AmpEvents.sendEvent(type, payload);
};
const jmart_web_get_sms = (payload: IAuthGetSMsPayload) => {
    const type = 'jmart_web_get_sms';
    AmpEvents.sendEvent(type, payload);
};

const jmart_product_show = (payload: IProductShow) => {
    const type = 'jmart_product_show';
    AmpEvents.sendEvent(type, payload);
    // appMetricCallNative({ type, payload });
};

const jmart_checkout_onelink_click = (payload: { link: string }) => {
    const type = 'jmart_checkout_onelink_click';
    AmpEvents.sendEvent(type, { link: payload.link });
};
const jmart_modal_webtoapp_view = createClickEvent('jmart_modal_webtoapp_view');

// ------------- Ивенты для страницы JUSAN JI и SkillBox

/**
 * type: `jmart_jusanji_open`
 * @description при открытии JusanJI page
 **/
const jmart_jusanji_open = () => {
    AmpEvents.sendEvent('jmart_jusanji_open');
    appMetricCallNative({ type: 'jmart_jusanji_open' });
};

/**
 * type: `jmart_jusanji_click_shopping`
 * @description при клике кнопку "Перейти к покупкам"
 **/
const jmart_jusanji_click_shopping = () => {
    AmpEvents.sendEvent('jmart_jusanji_click_shopping');
    appMetricCallNative({ type: 'jmart_jusanji_click_shopping' });
};

/**
 * type: `jmart_jusanji_click_promotion_rules`
 * @description при клике кнопку "Подробнее правила акции "
 **/
const jmart_jusanji_click_promotion_rules = () => {
    AmpEvents.sendEvent('jmart_jusanji_click_promotion_rules');
    appMetricCallNative({ type: 'jmart_jusanji_click_promotion_rules' });
};

/**
 * type: `jmart_skillbox_open`
 * @description при открытии SkillBox page
 **/
const jmart_skillbox_open = () => {
    AmpEvents.sendEvent('jmart_skillbox_open');
    appMetricCallNative({ type: 'jmart_skillbox_open' });
};

/**
 * type: `jmart_skillbox_click_chatgpt, jmart_skillbox_click_design, jmart_skillbox_click_start`
 * @description при клике каждом карточке на странице SkillBox
 **/
const jmart_skillbox_click_chatgpt = () => {
    AmpEvents.sendEvent('jmart_skillbox_click_chatgpt');
    appMetricCallNative({ type: 'jmart_skillbox_click_chatgpt' });
};
const jmart_skillbox_click_design = () => {
    AmpEvents.sendEvent('jmart_skillbox_click_design');
    appMetricCallNative({ type: 'jmart_skillbox_click_design' });
};
const jmart_skillbox_click_start = () => {
    AmpEvents.sendEvent('jmart_skillbox_click_start');
    appMetricCallNative({ type: 'jmart_skillbox_click_start' });
};

// -------------

export const AppMetricEvents = {
    // unused events (удалить?)
    jmart_product_instant_buy,
    jmart_cart_product_remove,
    jmart_cart_checkout,
    jmart_cart_checkout_group,
    jmart_delivery_type,
    jmart_coupon_error,
    jmart_payment_type,
    jmart_jusan_credit_apply,
    jmart_search_active,
    jmart_product_share,
    block_click,
    // events
    jmart_open,
    jmart_view_cart,
    jmart_error_soon,
    jmart_main_view,
    jmart_city_change,
    jmart_product_view,
    jmart_wishlist_view,
    jmart_wishlist_product_add,
    jmart_wishlist_product_delete,
    jmart_create_collection,
    jmart_delete_collection,
    jmart_share_collection,
    jmart_add_to_collection,
    jmart_supermarkets_view,
    jmart_supermarket_detail_view,
    jmart_category3_supermarkets_click,
    jmart_add_to_cart,
    jmart_add_to_cart2,
    jmart_cart_clear,
    jmart_checkout_start,
    jmart_buy_now_click,
    jmart_ai_chat,
    checkout_delivery,
    jmart_review_product,
    jmart_leave_feedback,
    jmart_category,
    jmart_promotion_view,
    jmart_main_promotion_view,
    jmart_search,
    jmart_banner_click,
    product_click,
    category_click,
    call_merchant,
    jmart_modal_window_click,
    jmart_web_cart_click,
    jmart_selection_click,
    jmart_partner_start,
    jmart_partner_commissions_view,
    jmart_partner_sendrequest,
    jmart_selection_supermarket_click,
    jmart_goods_main_click,
    jmart_supermarkets_main_click,
    jmart_services_main_click,
    jmart_catalog_footer_click,
    jmart_modal_window_view,
    jmart_filter_click,
    jmart_supermarket_search_banner,
    jmart_search_main_click,
    jmart_city_change_click,
    merchant,
    jmart_zerosearch_banner_click,
    jmart_search_main_supermarkets_click,
    jmart_zerosearch,
    jmart_filter_view,
    jmart_filter_option_click,
    jmart_search_fmcg_global_suggest,
    jmart_suggest_fmcg_search_new,
    jmart_history_search_new,
    jmart_search_fmcg_new,
    jmart_add_to_cart_click,
    jmart_pharmacy_main_click,
    jmart_become_partner_main_click,
    jmart_qrlite_main_click,
    jmart_diy_click,
    jmart_diy_home_view,
    jmart_qrlite_start,
    jmart_qrlite_become_partner_click,
    jmart_suggest_search_new,
    jmart_category_view,
    jmart_web_authorization,
    jmart_web_get_sms,
    jmart_start_forgotten_anything,
    jmart_continue_forgotten_anything,
    jmart_product_show,
    jmart_checkout_onelink_click,
    jmart_modal_webtoapp_view,
    jmart_jusanji_open,
    jmart_jusanji_click_shopping,
    jmart_jusanji_click_promotion_rules,
    jmart_skillbox_open,
    jmart_skillbox_click_chatgpt,
    jmart_skillbox_click_design,
    jmart_skillbox_click_start,
};
