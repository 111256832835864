import React from 'react';
import { Box, LoadingIndicator, Text, usePyatka } from 'pyatka-ui';
import { AuthSendOtpComponentProps } from 'components/AuthorizationModal/types';
import { AuthInput, SubmitButton } from 'components/AuthorizationModal/AuthorizationModal.Styles';
import { useI18n } from 'hooks/useI18n';

const AuthSendOtpComponent = (props: AuthSendOtpComponentProps) => {
    const {
        form,
        isLoadingAuth,
        onUserOtpSubmit,
        otpChangeHandler,
        handleResendSMS,
        otpError,
        expireTime,
        otpStatus,
        disableResendBtn,
    } = props;

    const { $t } = useI18n();
    const {
        scales: { isMobile },
    } = usePyatka();
    return (
        <>
            <Text
                textAlign={'center'}
                fontWeight={600}
                fontSize={{ default: 24, md: 28 }}
                mb={'12px'}
            >
                {$t('enter_sms_code')}
            </Text>

            <Text
                textAlign={'center'}
                color={'text.secondary'}
                fontWeight={600}
                fontSize={{ default: 16, md: 16 }}
                mb={{ default: 20, md: 24 }}
                style={{ whiteSpace: 'pre-wrap' }}
            >
                {$t('code_sent_to_number').addBreakLineInText({
                    afterWordIndex: 4,
                })}{' '}
                {otpStatus?.phone}
            </Text>
            <form onSubmit={onUserOtpSubmit} style={{ width: '100%' }}>
                <Box p={'0 119px'}>
                    <AuthInput
                        isMobile={isMobile}
                        placeholder={$t('code')}
                        value={form.otp}
                        onChange={otpChangeHandler}
                        // maxLength={4}
                        name={'otp'}
                        autoFocus={true}
                        p={0}
                        fontSize={{ default: 22, md: 24 }}
                        mb={{ default: 72, md: 59 }}
                        color={'text.main'}
                    />
                </Box>
                {(!form.otp || form?.otp?.length < 4) && (
                    <SubmitButton
                        isMobile={isMobile}
                        type={'button'}
                        borderRadius={24}
                        height={'60px'}
                        width={'100%'}
                        variant="brand"
                        padding={'19px'}
                        fontSize={'18px'}
                        styleType="contained"
                        onClick={handleResendSMS}
                        disabled={disableResendBtn}
                        mb={{ default: '0', md: 0 }}
                    >
                        {disableResendBtn && expireTime !== '00:00'
                            ? `${$t('request_after')} ${expireTime}`
                            : 'Запросить код'}
                    </SubmitButton>
                )}
                {form?.otp?.length === 4 && (
                    <SubmitButton
                        isMobile={isMobile}
                        borderRadius={24}
                        width={'100%'}
                        variant="brand"
                        padding={'19px'}
                        styleType="contained"
                        disabled={isLoadingAuth || Boolean(otpError)}
                        mb={{ default: '0', md: 0 }}
                    >
                        {isLoadingAuth ? <LoadingIndicator size={'1em'} /> : $t('continue')}
                    </SubmitButton>
                )}
            </form>
        </>
    );
};

export default AuthSendOtpComponent;
