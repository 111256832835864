import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Box } from 'pyatka-ui';
import { SearchBtn, GoBackBtn, Title, HeaderWrapper, ShareBtn } from '../components';
import { useParams } from 'react-router';

interface DefaultHeaderProps {
    isSearch?: boolean;
}
const DefaultHeader: React.FC<DefaultHeaderProps> = (props) => {
    const { isSearch = false } = props;
    const [title, setTitle] = React.useState('');
    // @ts-ignore
    const { promotion_id } = useParams();

    const onChangeTitle = (newState: any) => setTitle(newState?.title || '');

    return (
        <React.Fragment>
            <Helmet onChangeClientState={onChangeTitle} />
            <HeaderWrapper>
                <GoBackBtn />
                <Title title={title} />
                {/* LEGO-TODO */}
                {promotion_id === '739' && (
                    <Box mr={'10px'}>
                        <ShareBtn fill="block.inverse" />
                    </Box>
                )}
                {isSearch ? <SearchBtn /> : <Box width="36px" />}
            </HeaderWrapper>
        </React.Fragment>
    );
};

export default React.memo(DefaultHeader);
