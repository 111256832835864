import React from 'react';
import { LoadingIndicator } from 'pyatka-ui';
import { BrowserTracing } from '@sentry/tracing';
import * as Sentry from '@sentry/react';
import { PROD_CONDITION } from 'app/MainApp.Container';
export default function MainAppLoader() {
    const [appLoaded, setAppLoaded] = React.useState<boolean>(true);

    React.useLayoutEffect(() => {
        // window.addEventListener('DOMContentLoaded', () => {
        //     setAppLoaded(false);
        // });
        window.addEventListener('load', () => {
            // const PROD_CONDITION = true;

            if (PROD_CONDITION) {
                Sentry.init({
                    dsn: 'https://215e72f6b436465cac04e646ab3bdaeb@sentry.almanit.kz/46',
                    integrations: [new BrowserTracing()],
                    environment: process.env.NODE_ENV,
                    tracesSampleRate: process.env.NODE_ENV === 'production' ? 0.5 : 1,
                });
            }
            setTimeout(() => {
                setAppLoaded(false);
            }, 100);
        });
        return () => {
            window.removeEventListener('load', () => {});
        };
    }, []);

    return (
        <div>
            <div
                className="loader-container"
                style={{
                    height: appLoaded ? '100%' : '0',
                    opacity: appLoaded ? '1' : '0',
                }}
            >
                <LoadingIndicator size={'30px'} />
            </div>
        </div>
    );
}
