import { banner_sizes, banners_config } from 'modules/NewMainPage/config';
import { parseProduct } from 'modules/ProductsList/ProductsList.helpers';

const BannersFormat = ({ data }: any) => {
    const { items, size } = data;
    return {
        isLoading: false,
        config: banners_config[`size_${size}`],
        banner_size: banner_sizes[size],
        banners: items.map((item: any) => ({ ...item, url: formatUrl(item.url) })),
    };
};

const SupermarketBannerFormat = ({ data }: any) => {
    const { items } = data;
    const size = 'L';
    const banner = {
        position: '1',
        title: 'FMCG Banner',
        ...items,
        url: formatUrl(items.url),
    };

    return {
        isLoading: false,
        config: banners_config[`size_${size}`],
        banner_size: banner_sizes[size],
        banners: [banner],
    };
};

const CategoriesFormat = (category_section: any) => {
    const { image_path, title, subcategories, url, category_id } = category_section;
    const main_category = {
        main: true,
        image: image_path,
        title,
        url: formatUrl(url),
        position: 0,
        category_id,
    };
    const sub_categories = subcategories.map((cat: any) => ({
        main: false,
        image: cat.image_path,
        title: cat.title,
        url: formatUrl(cat.url),
        position: parseInt(cat.position, 10),
        category_id: cat?.category_id,
    }));

    return {
        isLoading: false,
        categories: [main_category, ...sub_categories].sort((a, b) => a.position - b.position),
    };
};

const MarketsFormat = ({ data }: any) => {
    const { items, show_more, title } = data;
    return {
        isLoading: false,
        buttonConfig: {
            title: show_more.title,
            url: formatUrl(show_more.url),
        },
        sectionTitle: title,
        markets: items.map((market: any) => ({
            image: market.image_path,
            title: market.company,
            subtitle: '',
            borderRadius: '50%',
            url: generateAppLinkConfig('new_markets', market.company_id),
        })),
    };
};

const DepartmentsFormat = ({ data }: any) => {
    const { menu_items } = data;
    return {
        isLoading: false,
        departments: menu_items.map((item: any) => ({
            id: item?.id,
            image: item.image_url,
            title: item.title,
            subtitle: '',
            url: item.url,
        })),
    };
};

const ProductsFormat = ({ data, labels }: any) => {
    const { items, title, show_more } = data;

    return {
        labels: labels || {},
        isLoading: false,
        products: items.map((product: any) => parseProduct(product)),
        sectionTitle: title,
        buttonConfig: {
            url: formatUrl(show_more?.url),
            buttonText: show_more?.title,
        },
        appearance: title === 'Гаджеты' || title === 'Ноутбуки' ? 'grid' : 'slide',
        items_per_row: title === 'Выгодная рассрочка' || title === 'Специально для вас' ? 7 : 6,
    };
};

export const MainPageSectionUtils: { [key: string]: any } = {
    section_banners: BannersFormat,
    section_supermarket_banner: SupermarketBannerFormat,
    section_categories: CategoriesFormat,
    company_section: MarketsFormat,
    section_products: ProductsFormat,
    section_menu_links: DepartmentsFormat,
    section_dynamic: ProductsFormat,
};

export const generateAppLinkConfig = (type: string, id: string | number, queryParams: any = {}) => {
    const storeSectionLink = (id: string | number) => ({
        name: id ? 'store-detail' : 'store-main',
        params: id ? { store_id: id } : null,
    });
    const promotionsLink = () => ({
        name: 'promotions-page',
        exact: true,
    });

    const params = {
        new_markets: storeSectionLink(id),
        new_category: { name: 'home-page', query: { category_id: id }, exact: true },
        new_search: {
            name: 'home-page',
            query: {
                ...queryParams,
                category_id: id,
            },
            exact: true,
        },
        new_promotion: promotionsLink(),
        new_catalog: { exact: true, name: 'catalog-page' },
        new_algolia: {
            name: 'search-page',
            query: {
                ...queryParams,
                ...(id ? { category_id: id } : {}),
            },
            exact: true,
        },
        new_super: {
            name: 'supermarkets-search-page',
            query: {
                query: queryParams?.query,
            },
            exact: true,
        },
    };

    // @ts-ignore
    return params[`${type}`];
};

export const formatUrl = (url: string) => {
    const arr = url && url.length ? url.split('/') : [];
    if (arr.length >= 3) {
        arr.splice(0, 3);
    }
    return arr.join('/');
};
