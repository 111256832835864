export const isDescriptionEmpty = (str: string = ''): boolean => {
    if (!str) {
        return true;
    }

    return (
        str.includes('null') ||
        str.includes('Описание отсутствует') ||
        str.includes('Описание отстутствует')
    );
};
