import { Box, Button, Col, Container, FlexBox, Row, Skeleton, Space, Text } from 'pyatka-ui';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { AppReAuthPageContainerProps } from 'pages/AppReAuthPage/types';
import { nativeCall } from 'hybrid';
import { isNative } from 'utils/platform';
import { useAppRouter } from 'router/context';
import { Dialog } from 'plugins/dialog';
import AuthorizationModal from 'components/AuthorizationModal';

export const AppReAuthPageContainer: React.FC<AppReAuthPageContainerProps> = (props) => {
    const { isUserAuth, AuthorizationInit } = props;
    const { appRedirect } = useAppRouter();
    const [isLoading, setIsLoading] = useState(false);
    const AuthClickHandle = React.useCallback(() => {
        setIsLoading(true);
        if (!isNative()) {
            Dialog.openDialog({
                body: AuthorizationModal,
                props: {
                    onClose: () => {
                        Dialog.closeDialog();
                        setIsLoading(false);
                    },
                },
                isOwnModal: true,
            });
            return;
        }
        const res: any = nativeCall({
            type: 'auth',
            payload: {},
        });
        if (res?.response?.token) {
            AuthorizationInit({ params: { token: res.response?.token } });

            // authFromMobile(res.response?.token);
        }
        setIsLoading(false);
    }, [AuthorizationInit]);

    const RedirectClickHandle = React.useCallback(() => {
        appRedirect({ name: 'index-page' });
    }, [appRedirect]);

    useEffect(() => {
        if (!isUserAuth) {
            AuthClickHandle();
            return;
        }
        RedirectClickHandle();
    }, [AuthClickHandle, RedirectClickHandle, isUserAuth]);

    if (isLoading) {
        return <Skeleton height="100vh" width="100%" borderRadius="8px" animation />;
    }

    return (
        <React.Fragment>
            <Helmet>
                <title>Загрузка...</title>
            </Helmet>
            <Container>
                <Row>
                    <Col xs={12} sm={{ span: 6, offset: 3 }} md={{ span: 4, offset: 4 }}>
                        <Box bg={'white'} mt={'20px'} p={'15px'} px={'-15px'} borderRadius={'10px'}>
                            <Text fontSize={6}>Ваша сессия завершилась 🔒</Text>
                            <Space height={'15px'} />
                            <FlexBox gap={'7px'}>
                                <Button
                                    onClick={AuthClickHandle}
                                    borderRadius={2}
                                    styleType="contained"
                                    variant={'success'}
                                >
                                    Войти
                                </Button>
                                <Button
                                    styleType="contained"
                                    bg={'#eaeaea'}
                                    color={'text.main'}
                                    onClick={RedirectClickHandle}
                                    borderRadius={2}
                                >
                                    На главную
                                </Button>
                            </FlexBox>
                        </Box>
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    );
};
