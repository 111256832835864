import { retry } from '@reduxjs/toolkit/query';
import { AppCartPayloadState, AppCartState, AppCartStateData } from 'contexts/useCartService/types';

export const AppCartBasePrepare = (
    cartItems: AppCartStateData,
    checkAmount: boolean = false
): AppCartPayloadState[] =>
    Object.values(cartItems || {})
        .filter((item: any) => {
            const main_condition =
                item.offer_id !== undefined && item.offer_id !== '' && item.offer_id !== '0';
            if (checkAmount) {
                return main_condition && item.amount > 0;
            }
            return main_condition;
        })
        .map((item: any) => {
            return {
                product_id: parseInt(item.offer_id),
                amount: item.amount,
            };
        });

export const AppGroupCartBasePrepare = (data: {
    reduxItems: any;
    payloadItems: AppCartPayloadState[];
}) => {
    const black_list = data.payloadItems.reduce((acc: any, item: AppCartPayloadState) => {
        acc[item.product_id] = item;
        return acc;
    }, {});
    const products_collection: any[] = [];
    Object.values(data.reduxItems).map((group: any) => {
        Object.values(group.products).map((product: any) => {
            const isProductExist = black_list[product.product_id] === undefined;
            if (isProductExist) {
                products_collection.push({
                    product_id: product.product_id,
                    amount: product.amount,
                });
            }
        });
    });
    return [...products_collection, ...data.payloadItems];
    // return data.payloadItems.reduce((prev, item) => {
    //     const payload_collection: any = [...prev];
    // Object.values(data.reduxItems).map((group: any) => {
    //     const products_collection: any[] = [];
    //     // let matchFound = false;
    //     Object.values(group.products).map((product: any) => {
    //         const isProductExist = product.product_id === Number(item.product_id);
    //         if (black_list[product.product_id] === undefined) {
    //             products_collection.push({
    //                 product_id: product.product_id,
    //                 amount: isProductExist ? item.amount : product.amount,
    //             });
    //         }
    //         // if (isMatch) {
    //         //     products_collection.push(item);
    //         //     // matchFound = true;
    //         // }
    //     });
    //     // if (matchFound) {
    //     payload_collection.push(...products_collection);
    //     // }
    // });
    // return payload_collection;
    // }, []);
};

export const AppSyncResponseFormatter = (cartData: any): AppCartStateData => {
    const res: AppCartStateData = {};
    const groups = cartData?.groups || {};
    Object.keys(groups).forEach((groupKey) => {
        const group = groups[groupKey.toString()];
        Object.keys(group.products || {}).forEach((pId) => {
            const product = group.products[pId.toString()];
            res[product.product_id] = {
                product_id: product?.master_product_id.toString(),
                offer_id: product?.product_id.toString(),
                amount: product?.amount,
            };
        });
    });
    return res;
};

export const AppSyncResponseFormatterV2Put = (cartData: any): AppCartStateData => {
    const res: AppCartStateData = {};
    const products = cartData?.products || [];
    products.forEach((product: any) => {
        res[product.product_id] = {
            product_id: product?.master_product_id.toString(),
            offer_id: product?.product_id.toString(),
            amount: product?.amount,
        };
    });
    return res;
};

export const cartPlainDataRevisionProducts = (groups: any, cartData: AppCartStateData): any => {
    const res: any = { groups: {} };
    Object.keys(groups).map((groupKey) => {
        const group_item = groups[groupKey.toString()];
        const products = Object.assign({}, group_item.products);
        Object.keys(cartData).map((cartItemKey) => {
            const cart_item = cartData[cartItemKey.toString()];
            if (cart_item.amount === 0 && products[cart_item.offer_id] !== undefined) {
                delete products[cart_item.offer_id];
            }
        });
        if (Object.keys(products).length > 0) {
            res.groups[groupKey.toString()] = { ...group_item, products };
        }
    });
    return res;
};
