import { connect, ConnectedProps } from 'react-redux';
import { AppRootState } from 'store/types';
import {
    clearReduxWishlist,
    getCollectionProducts,
    getWishlistData,
} from 'modules/Wishlist/Wishlist.actions';
import { getWishlistCollections } from 'modules/Wishlist/Wishlist.selectors';

const mapState = (state: AppRootState) => ({
    wishListCollections: getWishlistCollections(state),
});
const mapDispatch = {
    getCollectionProducts,
    getWishlistData,
    clearReduxWishlist,
};

export const WishlistCollectionPageConnector = connect(mapState, mapDispatch);
export type WishlistCollectionPageConnectProps = ConnectedProps<
    typeof WishlistCollectionPageConnector
>;
