import React from 'react';
import { Box, FlexBox, Icon, Image, Text, usePyatka } from 'pyatka-ui';
import { IWishlistCollection } from 'modules/Wishlist/types';

import { Overlay } from 'pages/WishListServicePage/v2/WishlistServicePage.Styles';
import { useI18n } from 'hooks/useI18n';
import { ToggleStatus } from 'pages/WishListServicePage/v2/components/AddToCollectionModal';

interface ICollectionItem {
    collection: IWishlistCollection;
    onClickItem: (props: { collection_id: string; collection_name: string }) => void;
    showItemStatus?: boolean;
    showPinnedStatus?: boolean;
    textAlign?: string;
    keepMobileDesign?: boolean;
    isActive?: boolean;
}

const CollectionItem = (props: ICollectionItem) => {
    const {
        collection,
        onClickItem,
        showItemStatus = false,
        showPinnedStatus = true,
        textAlign = 'start',
        keepMobileDesign = false,
        isActive = false,
    } = props;
    // const isInCollection = useSelector((state) =>
    //     isAddedProductInCollection(state, collection?.id)
    // );
    const { scales } = usePyatka();
    const isMobile = (scales.matched || []).find((el: any) => el.name === 'md');
    const showMobileLayout = isMobile || keepMobileDesign;
    const { $t } = useI18n();
    return (
        <FlexBox
            cursor={'pointer'}
            flexDirection={showMobileLayout ? 'column' : 'row'}
            gap={'12px'}
            justifyContent={'flex-start'}
            alignItems={'center'}
            borderRadius={'30px'}
            backgroundColor={showMobileLayout ? '#fff' : isActive ? '#FF7348' : '#BEBEB61A'}
            padding={showMobileLayout ? 0 : '8px 24px 8px 8px'}
            // px={'3px'}
            onClick={() =>
                onClickItem({
                    collection_id: collection.id,
                    collection_name: collection.name,
                })
            }
        >
            <FlexBox
                position={'relative'}
                alignItems={'center'}
                justifyContent={'center'}
                width={keepMobileDesign && !isMobile ? '120px' : '88px'}
                height={keepMobileDesign && !isMobile ? '120px' : '88px'}
                padding={'8px'}
                // mb={12}
                borderRadius={'23px'}
                backgroundColor={
                    showMobileLayout && (collection.pre_created || collection.productCount === 0)
                        ? '#bebeb61a'
                        : isActive
                        ? '#fff'
                        : 'none'
                }
            >
                <Image
                    src={collection?.image!}
                    width={
                        collection.pre_created
                            ? keepMobileDesign && !isMobile
                                ? '80px'
                                : '66px'
                            : '100%'
                    }
                    height={
                        collection.pre_created
                            ? keepMobileDesign && !isMobile
                                ? '80px'
                                : '66px'
                            : '100%'
                    }
                    style={{ zIndex: showMobileLayout || isActive ? 1 : '-1' }}
                />

                {((showMobileLayout && !collection.pre_created && collection.productCount !== 0) ||
                    isActive) && <Overlay />}
                {showPinnedStatus && (collection.pre_created || collection.pinned) && <Pin />}
                {showItemStatus && (
                    <FlexBox
                        position={'absolute'}
                        bottom={'-5px'}
                        right={'-6px'}
                        zIndex={100}
                        // border={'4px solid #ffffff'}
                        // borderRadius={'50%'}
                    >
                        <ToggleStatus isMobile={isMobile} />
                    </FlexBox>
                )}
            </FlexBox>

            <Box
                width={isMobile ? '103px' : keepMobileDesign ? '100%' : 'max-content'}
                style={{ wordBreak: 'break-word' }}
            >
                <Text
                    color={isActive ? 'white' : 'text.main'}
                    textAlign={textAlign}
                    // width={isMobile ? '100px' : 'max-content'}
                    fontSize={{ default: '14px', md: '18px' }}
                    fontWeight={600}
                    lineHeight={'18px'}
                    mb={{ default: '4px', md: '2px' }}
                >
                    {collection?.name.toCapitalizeFirstLetter()}
                </Text>
                <Text
                    textAlign={textAlign}
                    // width={'100px'}
                    color={isActive ? 'white' : 'text.secondary'}
                    fontSize={{ default: '12px', md: '14px' }}
                >
                    {collection.productCountText}
                </Text>
            </Box>
        </FlexBox>
    );
};
const Pin = () => (
    <FlexBox
        position={'absolute'}
        top={'8px'}
        right={'8px'}
        width={'24px'}
        height={'24px'}
        border={'3px solid #BEBEB633'}
        borderRadius={'999rem'}
        backgroundColor={'#fff'}
        alignItems={'center'}
        justifyContent={'center'}
        zIndex={1}
    >
        <Icon fill={'#AAADB2'} icon={'pin'} />
    </FlexBox>
);
export default CollectionItem;
