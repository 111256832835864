import React, { useCallback, useMemo, useState } from 'react';
import UserNavigationLayout from 'components/UserNavigation/UserNavigationLayout';
import { UserNavigationContainerType } from 'components/UserNavigation/types';
import AuthorizationModal from 'components/AuthorizationModal';
import { getUserAvatar, saveUserAvatar, USER_AVATAR_ICON } from 'data';
import { Dialog } from 'plugins/dialog';

const UserNavigationContainer: React.FC<UserNavigationContainerType> = (props) => {
    const { isLoggedIn, AuthorizationLogout } = props;
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const avatar_icon = useMemo(() => {
        if (isLoggedIn) {
            const iconFromLocalStorage = getUserAvatar(USER_AVATAR_ICON);
            if (iconFromLocalStorage) {
                return iconFromLocalStorage;
            }
            const randomDigit = Math.floor(Math.random() * 18) + 1;
            const newAvatar = `avatar-${randomDigit}`;
            saveUserAvatar(newAvatar);
            return newAvatar;
        }
        return 'userAvatar';
    }, [isLoggedIn]);
    const handleAvatarClick = useCallback(() => {
        if (!isLoggedIn) {
            Dialog.openDialog({
                body: AuthorizationModal,
                props: {
                    onClose: () => {
                        Dialog.closeDialog();
                    },
                    source: 'menu_auth_clicked',
                },
                isOwnModal: true,
            });
            return;
        }
        setIsMenuOpen((prev) => !prev);
    }, [isLoggedIn]);

    const handleLogout = useCallback(() => {
        setIsMenuOpen(false);
        AuthorizationLogout();
    }, [AuthorizationLogout]);
    const layoutProps = {
        avatar_icon,
        ...props,
        handleAvatarClick,
        handleLogout,
        isMenuOpen,
        setIsMenuOpen,
    };
    return (
        <>
            <UserNavigationLayout {...layoutProps} />
        </>
    );
};

export default UserNavigationContainer;
