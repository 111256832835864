import React from 'react';
import { Button, FlexBox, Icon, Text, Modal, Box } from 'pyatka-ui';
import { Dialog } from 'plugins/dialog';
import { IReview } from 'modules/ReviewService/types';
import { useI18n } from 'hooks/useI18n';
import { ReviewModal } from 'components';
import { AppMetricEvents } from 'AppMetric';

export const statuses = [
    'create_review',
    'on_moderation',
    'processing',
    'accepted',
    'declined',
    'complaint_processing',
    'complaint_processed',
] as const;

export type StatusName = typeof statuses;
export type Status = StatusName[number];

export interface IStatusButtonConfig {
    text: string;
    backgroundColor: string;
    color: string;
    isClickable: boolean;
    border: string;
    onClick?: () => void;
    borderColor: string;
}

interface IReviewButtonProps {
    review_info: {
        values: IReview;
    };
}

const STATUS_BUTTON_CONFIG: {
    [key in Status]: IStatusButtonConfig;
} = {
    create_review: {
        text: 'leave_review',
        backgroundColor: '#FFD65F',
        color: '#201F1F',
        isClickable: true,
        border: 'none',
        borderColor: 'none',
    },
    on_moderation: {
        text: 'review_on_moderation',
        backgroundColor: '#FFF',
        color: '#898D94',
        isClickable: false,
        border: '1.5px solid',
        borderColor: 'rgba(192, 192, 192, 0.2)',
    },
    processing: {
        text: 'review_in_progress',
        backgroundColor: '#FFF',
        color: '#898D94',
        isClickable: false,
        border: '1.5px solid #bebeb633',
        borderColor: 'none',
    },
    accepted: {
        text: 'your_review',
        backgroundColor: 'rgba(190, 190, 182, 0.15)',
        color: '#201F1F',
        isClickable: true,
        border: 'none',
        borderColor: 'none',
    },
    declined: {
        text: 'review_rejected',
        backgroundColor: '#FFF',
        color: '#FF0000',
        isClickable: true,
        border: '1.5px solid',
        borderColor: 'rgba(192, 192, 192, 0.2)',
    },
    complaint_processing: {
        text: 'review_on_moderation',
        backgroundColor: '#FFF',
        color: '#898D94',
        isClickable: false,
        border: '1.5px solid',
        borderColor: 'rgba(192, 192, 192, 0.2)',
    },
    complaint_processed: {
        text: 'review_rejected',
        backgroundColor: '#FFF',
        color: '#FF0000',
        isClickable: true,
        border: '1.5px solid',
        borderColor: 'rgba(192, 192, 192, 0.2)',
    },
};

export const ReviewButton = (props: IReviewButtonProps) => {
    const { $t } = useI18n();

    const { review_info } = props;

    const { values } = review_info;

    const config = STATUS_BUTTON_CONFIG[values.status];

    const onEditReview = () => {
        // Dialog.openDialog({
        //     body: 'ReviewModal',
        //     title: $t('product_review'),
        //     props: {
        //         reviewService: 'product-create',
        //         reviewInstructions: ['rate', 'dignity', 'limitations', 'comment', 'is_recommended'],
        //         reviewValues: values,
        //     },
        // });
        if (config.text === 'leave_review') {
            AppMetricEvents.jmart_leave_feedback();
        }
        setReviewModalOpen(true);
    };

    const [reviewModalOpen, setReviewModalOpen] = React.useState(false);

    const closeReviewModal = () => {
        setReviewModalOpen(false);
    };

    const showReasonButton =
        values?.status === 'declined' || values?.status === 'complaint_processed';

    return (
        <>
            <Modal
                open={reviewModalOpen}
                onClose={closeReviewModal}
                showCloseButton={true}
                padding="0"
                swipeable={false}
                fullScreen={true}
                positionMode="mobile"
                animationType={'none'}
                customHeader={
                    <FlexBox
                        justifyContent={'space-between'}
                        alignItems={'center'}
                        padding={'24px'}
                    >
                        <Icon icon="arrowLeftNew" onClick={closeReviewModal} />
                        <Text lineHeight={'24px'} fontSize={'18px'}>
                            {$t('Оставить отзыв')}
                        </Text>
                        <Box width={'24px'} height={'24px'} />
                    </FlexBox>
                }
            >
                <ReviewModal
                    reviewInstructions={[
                        'rate',
                        'photo',
                        'dignity',
                        'limitations',
                        'comment',
                        // 'is_recommended',
                    ]}
                    reviewService={'product-create'}
                    reviewValues={values}
                />
            </Modal>
            <FlexBox>
                <Button
                    borderRadius={'15px'}
                    backgroundColor={config?.backgroundColor}
                    color={config?.color}
                    border={config?.border}
                    borderColor={config.borderColor}
                    width={'fit-content'}
                    p={'9px'}
                    onClick={onEditReview}
                    disabled={!config?.isClickable}
                    buttonSuffix={
                        values.status === 'accepted' && (
                            <Icon fill={'#201F1F'} icon={'arrow-right'} />
                        )
                    }
                >
                    <Text fontSize={'12px'} lineHeight={'11px'}>
                        {$t(config?.text)}
                    </Text>
                </Button>
                {showReasonButton && (
                    <Button
                        ml={'4px'}
                        borderRadius={'15px'}
                        backgroundColor={'rgba(190, 190, 182, 0.15)'}
                        color={'#898D94'}
                        border={'none'}
                        width={'fit-content'}
                        p={'9px'}
                        onClick={onEditReview}
                        disabled={!config?.isClickable}
                        buttonSuffix={<Icon icon={'arrow-right'} width={'5px'} height={'8px'} />}
                    >
                        <Text fontSize={'11px'} lineHeight={'11px'}>
                            {$t('reason')}
                        </Text>
                    </Button>
                )}
            </FlexBox>
        </>
    );
};
