import SupermarketReducer from 'modules/Supermarkets/Supermarkets.state';
import AuthorizationReducer from 'modules/Authorization/v2/Authorization.state';
import AppReducer from 'modules/App/App.state';
import ProductDetailReducer from 'modules/ProductDetail/ProductDetail.state';
import CategoriesReducer from 'modules/Categories/Categories.state';
import CitiesReducer from 'modules/Cities/Cities.state';
import ArticlesReducer from 'modules/Articles/Articles.state';
import MerchantsReducer from 'modules/Merchants/Merchants.state';
import NewMainPageReducer from 'modules/NewMainPage/NewMainPage.state';
import UserProfileServiceReducer from 'modules/UserProfileService/UserProfileService.state';
import ReviewServiceReducer from 'modules/ReviewService/ReviewService.state';
import SearchServiceReducer from 'modules/SearchService/SearchService.state';
import WishlistReducer from 'modules/Wishlist/Wishlist.state';

export const state = {
    SupermarketReducer,
    AuthorizationReducer,
    AppReducer,
    ProductDetailReducer,
    CategoriesReducer,
    CitiesReducer,
    ArticlesReducer,
    MerchantsReducer,
    NewMainPageReducer,
    UserProfileServiceReducer,
    ReviewServiceReducer,
    SearchServiceReducer,
    WishlistReducer,
};
