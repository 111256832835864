import React, { useCallback, useMemo } from 'react';
import { Box, Text, usePyatka } from 'pyatka-ui';
import { UserNavigationMenuProps } from 'components/UserNavigation/types';
import {
    LogOutButton,
    MenuHeader,
    MenuList,
    MenuListLinkItem,
    MenuWrapper,
} from 'components/UserNavigation/UserNavigation.Styles';
import Avatar from 'components/UserNavigation/components/Avatar';
import { useAppRouter } from 'router/context';
import { Dialog } from 'plugins/dialog';
import { useI18n } from 'hooks/useI18n';
import { FormFieldsInitialData } from 'modules/UserProfileService/ProfileServiceConfigs';

const MENU_LIST = [
    {
        title: 'my_addresses',
        key: 'my-addresses',
    },
    {
        title: 'wishlist',
        key: 'wishlist-page',
    },
    {
        title: 'orders',
        key: 'profile-page',
    },
];
export const UserNavigationMenu = (props: UserNavigationMenuProps) => {
    const { avatar, open, user_data, onLogout, profileServiceData } = props;
    const { appRedirect } = useAppRouter();
    const { $t } = useI18n();
    const { scales } = usePyatka();
    const isMobile = (scales.matched || []).find((el: any) => el.name === 'md');
    const {
        addresses: shippingAddress,
        addressDefault: shippingAddressDefault,
        loading: isLoading,
    } = profileServiceData;

    const title = useMemo(
        () => (shippingAddressDefault === undefined ? $t('choose_address') : $t('my_addresses')),
        [$t, shippingAddressDefault]
    );
    const showAddressesWeb = useCallback(() => {
        if (shippingAddress.length === 0) {
            Dialog.openDialog({
                title: isMobile ? title : '',
                body: 'ProfileAddressForm',
                props: {
                    address: FormFieldsInitialData,
                    backButtonInitial: 1,
                },
                config: {
                    bottomSheet: true,
                    noPadding: !isMobile,
                    closeButton: false,
                },
            });
            return;
        }
        Dialog.openDialog({
            title,
            body: 'ProfileAddressList',
            props: {
                // updateList,
                addresses: shippingAddress,
            },
            config: {
                bottomSheet: true,
            },
        });
    }, [shippingAddress, title]);
    const onItemClick = useCallback(
        (key: string) => {
            if (key === 'my-addresses') {
                showAddressesWeb();
                return;
            }
            appRedirect({
                name: key,
            });
        },
        [appRedirect, showAddressesWeb]
    );
    return (
        <MenuWrapper className={open ? 'menu open' : 'menu'}>
            <MenuHeader>
                <Box>
                    <Text
                        fontSize={'20px'}
                        fontWeight={600}
                        lineHeight={'130%'}
                        mb={1}
                        skeleton={isLoading}
                        skeletonProps={{ animation: true, style: { width: 200, height: 24 } }}
                    >
                        {user_data?.firstname ? user_data.firstname : user_data?.phone}
                    </Text>
                    <Text
                        fontSize={'16px'}
                        fontWeight={500}
                        lineHeight={'128%'}
                        color={'text.secondary'}
                        skeleton={isLoading}
                        skeletonProps={{ animation: true, style: { width: 200, height: 24 } }}
                    >
                        {user_data?.firstname ? user_data?.phone || '' : 'Номер телефона'}
                    </Text>
                </Box>
                <Avatar icon={avatar} onAvatarClick={() => {}} />
            </MenuHeader>
            <MenuList gap={'24px'}>
                {MENU_LIST.map((item) => (
                    <MenuListLinkItem onClick={() => onItemClick(item.key)} key={item.key}>
                        {$t(item.title).toCapitalizeFirstLetter()}
                    </MenuListLinkItem>
                ))}
                <LogOutButton onClick={() => onLogout()} color={'text.brand_variant'}>
                    {$t('logout')}
                </LogOutButton>
            </MenuList>
        </MenuWrapper>
    );
};

export default UserNavigationMenu;
