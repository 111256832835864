import React from 'react';
import { FlexBox, Image, Text, Box, usePyatka } from 'pyatka-ui';
import { AppLink } from 'components';
import { dispatchDepartmentMetric } from 'AppMetric/helpers';
import { useI18n } from 'hooks/useI18n';
import placeholder from 'shared/assets/placeholder_1.png';
import { DepartmentBoxProps } from './types';

const DepartmentBox: React.FC<DepartmentBoxProps> = ({
    id,
    url,
    image,
    title = '',
    isLoading = false,
    isSupermarket = false,
}) => {
    const { $t } = useI18n();
    const { scales } = usePyatka();

    const departmentMetricSender = React.useCallback(() => {
        dispatchDepartmentMetric(id, title, isSupermarket);
    }, [id, isSupermarket, title]);

    // Image Props
    const imageSize = isSupermarket
        ? { default: '56px', md: '72px' }
        : { default: '46px', md: '48px' };

    const imageRadius = isSupermarket
        ? { default: '65px', md: '999rem' }
        : { default: '12px', md: '15px' };

    return (
        <Box {...(isSupermarket ? boxProps : {})} title={$t(title)}>
            <AppLink to={url}>
                <FlexBox
                    flexDirection={{ default: 'column', md: isSupermarket ? 'column' : 'row' }}
                    gap={isSupermarket && !scales?.isMobile ? '15px' : '8px'}
                    onClick={departmentMetricSender}
                    width={{ default: '100%' }}
                    justifyContent="center"
                    alignItems="center"
                >
                    <Box borderRadius="18px" bg={isSupermarket ? 'transparent' : 'block.secondary'}>
                        <Image
                            src={image}
                            width={imageSize}
                            height={imageSize}
                            skeleton={isLoading}
                            borderRadius={imageRadius}
                            fallbackImg={placeholder}
                        />
                    </Box>
                    <Text
                        color="text.main"
                        skeleton={isLoading}
                        skeletonProps={skeletonProps}
                        style={{ whiteSpace: 'initial' }}
                        {...(isSupermarket && !scales?.isMobile ? { lineClamp: 1 } : {})}
                        textAlign={{ default: 'center', md: 'left' }}
                        fontWeight={{ md: isSupermarket ? 600 : 400 }}
                        fontSize={{
                            default: isSupermarket ? '14px' : '12px',
                            md: isSupermarket ? '21px' : '17px',
                        }}
                    >
                        {$t(title)}
                    </Text>
                </FlexBox>
            </AppLink>
        </Box>
    );
};

const boxProps = {
    p: { md: '15px' },
    minWidth: { md: '223px' },
    borderRadius: { md: '36px' },
    bg: { md: 'block.secondary' },
};

const skeletonProps = {
    height: '1em',
    borderRadius: 12,
    marginTop: { default: '9px', md: 0 },
    width: { default: '100%', md: '8em' },
    marginLeft: { default: 0, md: '15px' },
};

export default React.memo(DepartmentBox);
