import EventLib from 'events';
import React from 'react';

const NodeEvents = __SERVER__ ? EventLib : undefined;
const em = NodeEvents && new NodeEvents.EventEmitter();
const OPEN_KEY = 'open-Dialog';
const CLOSE_KEY = 'close-Dialog';

export interface IDialogConfig {
    [key: string]: any;

    closeButton?: boolean;
    bottomSheet?: boolean;
    maxHeight?: string;
    maxWidth?: string;
    noPadding?: boolean;
    showMobileTitle?: boolean;
}

export interface IOpenDialog {
    key?: string;
    title?: string;
    swipeable?: boolean;
    props?: {
        [key: string]: any;
    };
    body?: string | React.FC | JSX.Element;
    element?: JSX.Element;
    config?: IDialogConfig;
    isOwnModal?: boolean;
    // actions?: any[];
}

const openDialog = (data: IOpenDialog) => {
    if (__BROWSER__) {
        const open = new CustomEvent(OPEN_KEY, {
            detail: data,
        });
        document.dispatchEvent(open);
        return;
    }
    if (__SERVER__) {
        em && em.emit(OPEN_KEY, data);
    }
    return;
};

const closeDialog = () => {
    if (__BROWSER__) {
        const close = new CustomEvent(CLOSE_KEY);
        document.dispatchEvent(close);
        return;
    }
};

export const Dialog = {
    openDialog,
    closeDialog,
};
