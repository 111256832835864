import React from 'react';
import { usePyatka } from 'pyatka-ui';
import styled from 'styled-components';
import { ProductLabel } from 'components';
import { Product } from 'modules/ProductsList/types';
import { ProductCardLayoutType } from 'components/ProductCard/types';

interface IProductLabels {
    label: Product['label'];
    isLoading: boolean;
    show: boolean;
    layout?: ProductCardLayoutType;
}

const ProductLabels: React.FC<IProductLabels> = ({
    show,
    label: { bonusLabel } = {},
    isLoading,
    layout,
}) => {
    const { scales } = usePyatka();

    if (!show || isLoading) {
        return null;
    }

    return (
        <Labels>
            {!isLoading && bonusLabel ? (
                <ProductLabel
                    variant="bonus"
                    fontSize={[layout === 'small' ? '11px' : '13px', '18px']}
                    height={scales?.isMobile ? (layout === 'small' ? '14px' : '18px') : '23px'}
                    padding={scales?.isMobile ? '0px 5px' : '3px 6px'}
                    iconSize={{
                        small: '10px',
                        big: '15px'
                    }}
                >
                    {bonusLabel?.text?.replace('Б', '')}
                </ProductLabel>
            ) : (
                <div style={{ height: 18 }} />
            )}
        </Labels>
    );
};

const Labels = styled.div`
    & [data-testid='text-wrapper'] {
        margin-right: 0;
        gap: 1px;
    }
`;

export default ProductLabels;
