import { ApiGwRequest } from 'services';

export const sendMailService = (data: any) => {
    return ApiGwRequest({
        indicator: '',
        url: 'company-onboarding/v1/company',
        method: 'post',
        data,
    });
};
