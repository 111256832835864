import React from 'react';
import { useLocation } from 'react-router';
import { AppLinkLayoutProps } from './types';

export const AppLinkLayout: React.FC<AppLinkLayoutProps> = ({
    style,
    className,
    onClick,
    link,
    children,
    itemProp,
    ariaLabel,
    eventType = 'onClick',
    isExternal = false,
    ...rest
}) => {
    const { pathname, search } = useLocation();
    const currentPath = pathname + search;
    // typeof window !== 'undefined' ? window.location.pathname + window.location.search : link;

    const custom_style = {
        ...style,
        cursor: 'pointer',
    };

    const linkProps = {
        'style': custom_style,
        className,
        'href': link || '/',
        'aria-label': ariaLabel,
        ...(itemProp ? { itemProp } : {}),
        ...rest,
    };

    // текущая страница совпадает со ссылкой
    if (!link || link === currentPath) {
        return <>{children}</>;
    }

    // внешняя ссылка
    if (isExternal) {
        return (
            <a {...linkProps} target="_blank" rel="noopener noreferrer">
                {children}
            </a>
        );
    }

    if (eventType === 'onClick') {
        return (
            <a onClick={onClick} {...linkProps}>
                {children}
            </a>
        );
    }

    if (eventType === 'onMouseDown') {
        return (
            // @ts-ignore
            <div onMouseDown={onClick} {...linkProps}>
                {children}
            </div>
        );
    }

    return <>{children}</>;
};
