import slice from 'modules/Supermarkets/Supermarkets.slice';

export const {
    fetchSupermarketsFailed,
    fetchSupermarketsSucceed,
    fetchSupermarkets,
    fetchSupermarketsDetail,
    fetchSupermarketsDetailFailed,
    fetchSupermarketsDetailSucceed,

    fetchSupermarketsSearch,
    fetchSupermarketsSearchSucceed,
    fetchSupermarketsSearchFailed,
    fetchSupermarketsSearchFlush,
    fetchReccomendations,
    fetchReccomendationsFailed,
    fetchReccomendationsSucceed,

    fetchSuperCategories,
    fetchSuperCategoriesSucceed,
    fetchSuperCategoriesFailed,
} = slice.actions;
