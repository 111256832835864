import { isNative, nativeConfigs } from 'utils/platform';
import { getUserAuthData } from 'data';
import { RemoveApiInstancesHeader, SetApiInstancesHeader } from 'services';

export const getAuthData = () => {
    // if (isNative() && nativeConfigs()?.token !== null) {
    //     return {
    //         token: nativeConfigs()?.token,
    //         ttl: 587255,
    //         errors: {},
    //     };
    // }
    return getUserAuthData();
};
export const setAuthorizationHeader = (token: string) => {
    const token_encoded = token; //isAuthJWT ? token : btoa(`${token}:`);
    const value = `Bearer ${token_encoded}`;
    SetApiInstancesHeader('Authorization', value);
};

export const removeAuthorizationHeader = () => {
    RemoveApiInstancesHeader('Authorization');
};
