import React, { useCallback } from 'react';
import { Box, FlexBox, Icon, Text } from 'pyatka-ui';
import { useI18n } from 'hooks/useI18n';
import { useAppRouter } from 'router/context';

interface IWishlistToasterTemplateProps {
    icon: string;
    head?: string;
    description?: string;
    onClickToaster?: () => void;
    redirectParams?: any;
}

const WishlistToasterTemplate = (props: IWishlistToasterTemplateProps) => {
    const {
        icon = 'new_fill_heart',
        head = '',
        description = '',
        onClickToaster,
        redirectParams,
    } = props;
    const { $t } = useI18n();
    const { appRedirect } = useAppRouter();
    const handleCLick = useCallback(() => {
        if (onClickToaster) {
            onClickToaster();
            return;
        }
        if (redirectParams) {
            appRedirect(redirectParams);
        }
    }, [appRedirect, onClickToaster, redirectParams]);
    return (
        <FlexBox
            alignItems={'center'}
            gap={'18px'}
            style={{ boxShadow: '0 5px 18px 0 rgba(0, 0, 0, 0.08)' }}
            borderRadius={'18px'}
            width={'97%'}
            margin={'0 auto'}
            backgroundColor={'#fff'}
            p={'13px 14px 15px 18px'}
            onClick={handleCLick}
        >
            <Icon icon={icon} width={'30px'} />
            <FlexBox flexDirection={'column'} gap={'2px'}>
                {head && (
                    <Text
                        color={'text.main'}
                        fontWeight={600}
                        fontSize={'14px'}
                        lineHeight={'18px'}
                    >
                        {$t(head)}
                    </Text>
                )}

                {description && (
                    <Text color={'text.secondary'} fontSize={'12px'} lineHeight={'15px'}>
                        {$t(description)}
                    </Text>
                )}
            </FlexBox>

            {(onClickToaster || redirectParams) && (
                <Box marginLeft={'auto'}>
                    <Icon fill={'text.placeholder'} icon={'arrow-right'} />
                </Box>
            )}
        </FlexBox>
    );
};

export default WishlistToasterTemplate;
