import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import {
    IFormatPromotion,
    IFormatPromotionDetails,
    IPromotion,
    IPromotionDetails,
} from 'modules/Promotions/types';
import { generateAppLinkConfig } from 'modules/NewMainPage/utils';
import { FILTER_COMPANY_IDS, parseProduct } from 'modules/ProductsList/ProductsList.helpers';
import { filterMerchant } from 'pages/ProductsList/utils';

dayjs.extend(customParseFormat);

export const PROMOTION_TYPE_PRODUCT = 'product';
export const PROMOTION_TYPE_CATEGORY = 'category';
export const PROMOTION_TYPE_COMPANY = 'company';
export const PROMOTION_TYPE_SUPERMARKET_SECTION = 'supermarket_section';

const formatterDate = (date: string) => {
    return dayjs(date, 'DD.MM.YYYY').format('DD MMMM YYYY');
};

const formatterDateToMS = (date: string) => {
    return dayjs(date, 'DD.MM.YYYY').toDate().getTime();
};

const formatterDateToDM = (date: string) => {
    return dayjs(date, 'DD.MM.YYYY').format('DD MMMM');
};

export const calculateDeadline = (start: string, end: string) => {
    const percent = Math.floor(
        (100 * (new Date().getTime() - formatterDateToMS(start))) /
            (formatterDateToMS(end) - formatterDateToMS(start))
    );

    return percent > 100 ? 100 : percent;
};

export const formatterPromotions = (promotions: IPromotion[]): IFormatPromotion[] => {
    return promotions?.map((promotion) => {
        const { company_ids = [] } = promotion;

        const companys = company_ids || [];

        const merchant_filters = filterMerchant(
            null,
            companys?.reduce((a, v) => ({ ...a, [v]: v }), {})
        );

        return {
            ...promotion,
            company_ids: companys,
            link: {
                name: 'promotion-details-page',
                params: {
                    promotion_id: promotion.id,
                },
                query: merchant_filters,
            },
            start_date_time: formatterDateToDM(promotion.start_date_time),
            end_date_time: formatterDateToDM(promotion.end_date_time),
            deadline: calculateDeadline(promotion.start_date_time, promotion.end_date_time),
        };
    });
};

const formatterDetailsInfo = (promotionDetails: IPromotionDetails) => {
    const { items, ...otherDetails } = promotionDetails;
    return {
        ...otherDetails,
        end_date_time: formatterDate(otherDetails.end_date_time),
        start_date_time: formatterDate(otherDetails.start_date_time),
        deadline: calculateDeadline(otherDetails.start_date_time, otherDetails.end_date_time),
    };
};

const formatterCategories = (categories: any[]) => {
    return categories?.map((cat) => {
        const { category, image_path, category_id, ...otherCategory } = cat;
        return {
            ...otherCategory,
            main: false,
            image: cat.image_path,
            title: cat.category,
            categoryId: cat.category_id,
            url: generateAppLinkConfig('new_category', cat.category_id),
        };
    });
};

const formatterProducts = (products: any[]) => {
    return products.map((p) => {
        return {
            ...parseProduct(p, false),
            type: p?.type,
        };
    });
};

const formatterCompanies = (companies: any[]) => {
    return companies?.map((c) => {
        const { company_id, company, image_path, ...otherCompany } = c;
        return {
            ...otherCompany,
            companyId: c.company_id,
            companyName: c.company,
            image: c.image_path,
            skeleton: false,
        };
    });
};

const formatterSupermarketSections = (supermarkets: any[]) => {
    return supermarkets?.map((m) => {
        return {
            cId: m.subcategory_id,
            title: m.name,
            icon: m.image_url,
            skeleton: false,
            label: m.label,
            customTo: `/store?section_id=${m.subcategory_id}`,
        };
    });
};

const formatterItemsByType = (promotionDetails: IPromotionDetails) => {
    if (promotionDetails.type === PROMOTION_TYPE_CATEGORY) {
        return formatterCategories(promotionDetails.items);
    } else if (promotionDetails.type === PROMOTION_TYPE_COMPANY) {
        return formatterCompanies(promotionDetails.items);
    } else if (promotionDetails.type === PROMOTION_TYPE_SUPERMARKET_SECTION) {
        return formatterSupermarketSections(promotionDetails.items);
    } else if (promotionDetails.type === PROMOTION_TYPE_PRODUCT) {
        return formatterProducts(promotionDetails.items);
    }
    return [];
};

export const formatterPromotionDetails = (
    promotionDetails: IPromotionDetails
): IFormatPromotionDetails => ({
    content: formatterDetailsInfo(promotionDetails || {}),
    items: formatterItemsByType(promotionDetails || {}),
});
