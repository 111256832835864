import { ApiGwRequest } from 'services';

export const getAllPromotions = () => {
    return ApiGwRequest({
        indicator: '',
        url: 'promotion/v1/promotion-block',
        method: 'get',
    });
};

export const getPromotionDetails = (id: number | string) => {
    return ApiGwRequest({
        indicator: '',
        url: `promotion/v1/promotion-block/${id}`,
        method: 'get',
    });
};

export const getCartBonus = (data: any) => {
    return ApiGwRequest({
        indicator: '',
        url: 'promotion/v2/cart-bonus',
        method: 'post',
        data,
    });
};

export const fetchPromotionModal = () => {
    return ApiGwRequest({
        indicator: '',
        url: 'main-page/v1/section-modal',
        method: 'get',
    });
};
