import styled from 'styled-components/macro';
import { Button, Input } from 'pyatka-ui';

export const AuthInput = styled(Input)<{ isMobile?: boolean }>`
    border: none;
    border-bottom: 2px solid rgba(190, 190, 182, 0.4);
    //padding: 0 10px 10px;
    // padding-bottom: 4px;
    height: 57px;
    // height: ${(props) => (!props.isMobile ? '57px' : '36px')};

    input,
    input::placeholder {
        text-align: center;
    }
`;
export const SubmitButton = styled(Button)<{ isMobile?: boolean }>`
    background: #ff7348;
    height: ${(props) => (!props.isMobile ? '60px' : '55px')};
    font-size: ${(props) => (!props.isMobile ? '18px' : '16px')};

    &[disabled='disabled'],
    &:disabled {
        background: #f6f6f5;
        color: #aaadb2;
    }
`;
