import React from 'react';
import { Box, usePyatka } from 'pyatka-ui';
import { BoxProps } from 'pyatka-ui/dist/components-shared/Box/types';
import { useDispatch, useSelector } from 'react-redux';
import { ProductCardList } from 'components';
import { fetchSimilarProducts } from 'modules/ProductDetail/ProductDetail.services';
import { Loader } from 'plugins/loader';
import { ProductsParser } from 'modules/ProductsList/ProductsList.helpers';
import { useLoader } from 'contexts/useLoader';
import { EMPTY_PRODUCT_CARD } from 'pages/NewProductDetailPage/components/constants';
import { useI18n } from 'hooks/useI18n';
import { GtmEvents } from 'metrics';
import { AppMetricEvents } from 'AppMetric';
import { ProductCardListProps } from 'components/ProductCardList/types';
import { setSimilarProducts } from 'modules/ProductDetail/ProductDetail.actions';
import { getSimilarProducts } from 'modules/ProductDetail/ProductDetail.selectors';

interface ISimilarProducts {
    product_id?: string;
    product_name?: string;
    title?: string;
    cardLayout?: ProductCardListProps['cardLayout'];
    appearance?: ProductCardListProps['appearance'];
    minWidth?: ProductCardListProps['minWidth'];
    count?: number;
    boxProps?: Omit<BoxProps, 'color'>;
    similarScroll?: any;
}

const SimilarProducts: React.FC<ISimilarProducts> = ({
    product_id,
    product_name,
    title = 'may_be_interested',
    appearance = 'slide',
    cardLayout = 'old',
    count = 4,
    minWidth = 150,
    boxProps,
    similarScroll,
}) => {
    const dispatch = useDispatch();
    const { scales } = usePyatka();
    const products = useSelector(getSimilarProducts) || [];
    const { $t } = useI18n();
    const { isLoading } = useLoader();
    const isSkeleton = isLoading(Loader.similarProducts.indicator);

    React.useEffect(() => {
        if (product_id) {
            const initialContent = async (id: string) => {
                Loader.similarProducts.on({ global: false });
                try {
                    const { data } = await fetchSimilarProducts(id);
                    const parsedItems = ProductsParser(data.data);
                    dispatch(setSimilarProducts(parsedItems));

                    GtmEvents.viewItemList(parsedItems, `Similar Products: ${product_id}`);
                } catch (e) {
                    console.log('[SimilarProducts]: ', e);
                }
                Loader.similarProducts.off();
            };
            initialContent(product_id);
        }
    }, [product_id, dispatch]);

    const productClickMetric = (data: any) => {
        AppMetricEvents.product_click({
            source: 'similar',
            data,
        });
    };

    const addToCartMetric = (data: any) => {
        AppMetricEvents.jmart_add_to_cart2({
            source: 'similar',
            data,
        });
    };

    const addToCartClickMetric = (data: any) => {
        AppMetricEvents.jmart_add_to_cart_click({
            source: 'similar',
            data: {
                ...data,
                master_product_id: Number(product_id),
                master_product_name: product_name,
            },
        });
    };

    const metric = {
        product_click: productClickMetric,
        add_to_cart: addToCartMetric,
        add_to_cart_click: addToCartClickMetric,
    };

    // --- IF NO PRODUCTS
    if (!isSkeleton && products.length === 0) {
        return <React.Fragment />;
    }

    const commonProps: Omit<ProductCardListProps, 'products'> = {
        appearance,
        cardLayout,
        desktopCardLayout: 'main',
        btnStyle: cardLayout === 'new' ? 'secondary' : 'default',
        buttonConfig: { url: '', buttonText: '' },
        gap: scales?.isMobile ? '8px' : '20px',
        minWidth,
        count,
        source: 'recommendations',
    };

    const boxLayoutProps: Omit<BoxProps, 'color'> =
        (cardLayout === 'small' || cardLayout === 'new') && scales?.isMobile
            ? {
                  py: { default: '24px', md: '32px' },
                  px: '18px',
                  mx: '-18px',
                  borderRadius: '24px',
                  bg: 'block.main',
              }
            : {
                  my: { default: '24px', md: '32px' },
              };

    return (
        <Box {...boxLayoutProps} {...boxProps} ref={similarScroll?.ref}>
            {isSkeleton ? (
                <ProductCardList
                    {...commonProps}
                    products={Array(4).fill(EMPTY_PRODUCT_CARD)}
                    isLoading={isSkeleton}
                    sectionTitle=""
                />
            ) : (
                <ProductCardList
                    {...commonProps}
                    metric={metric}
                    products={products}
                    sectionTitle={$t(title)}
                    titleStyle={{
                        fontSize: scales?.isMobile ? 18 : 32,
                        lineHeight: scales?.isMobile ? '23.4px' : '40.96px',
                        fontWeight: 700,
                        marginBottom: 30,
                    }}
                    sliderStyle={{ px: { default: '18px', md: '0px' } }}
                    wrapperStyle={{ mx: { default: '-18px', md: '-8px' } }}
                />
            )}
        </Box>
    );
};

export default React.memo(SimilarProducts);
