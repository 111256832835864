import { connect, ConnectedProps } from 'react-redux';
import { AppRootState } from 'store/types';
import { ProductsListData } from 'modules/ProductsList/ProductsList.selectors';
import { getWishlistCount } from 'modules/Wishlist/Wishlist.selectors';
import { getProductsTotalCount } from 'modules/CartService/CartService.selectors';

const mapState = (state: AppRootState) => ({
    parentCategory: ProductsListData(state).parentCategory,
});

const mapDispatch = {};

export const NewHeaderConnector = connect(mapState, mapDispatch);
export type NewHeaderConnectProps = ConnectedProps<typeof NewHeaderConnector>;
