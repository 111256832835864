import React from 'react';
import { MobileTabNavigationLayout } from './MobileTabNavigation.Layout';
import { MobileTabNavigationProps } from './types';

export const MobileTabNavigationContainer: React.FC<MobileTabNavigationProps> & {
    Layout: typeof MobileTabNavigationLayout;
} = () => {
    return (
        <React.Fragment>
            <MobileTabNavigationLayout />
        </React.Fragment>
    );
};

MobileTabNavigationContainer.Layout = MobileTabNavigationLayout;
