import { ApiGwRequest, ApiFormDataRequest } from 'services';
import { IReview } from 'modules/ReviewService/types';

/* TODO: fix the type of data  */

export const createProductReview = (data: IReview) => {
    return ApiGwRequest({
        indicator: '',
        url: 'review/v1/review/product',
        method: 'post',
        data,
    });
};

export const createPhotoProductReview = (data: IReview) => {
    return ApiFormDataRequest({
        indicator: '',
        url: 'review/v1/review/product',
        method: 'post',
        data,
    });
};

export const editProductReview = (data: IReview) => {
    return ApiGwRequest({
        indicator: '',
        url: 'review/v1/review/product',
        method: 'put',
        data,
    });
};

export const createMerchReview = (data: IReview) => {
    return ApiGwRequest({
        indicator: '',
        url: 'review/v1/review/merch',
        method: 'post',
        data,
    });
};

export const editMerchReview = (data: IReview) => {
    return ApiGwRequest({
        indicator: '',
        url: 'review/v1/review/merch',
        method: 'put',
        data,
    });
};

export const fetchProductReviews = ({ id, params }: any) => {
    return ApiGwRequest({
        url: `review/v1/reviews/master-product/${id}`,
        method: 'get',
        indicator: '',
        params,
    });
};
