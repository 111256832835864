import React from 'react';
import { ProductBreadcrumbsProps } from 'components/ProductBreadcrumbs/types';
import { ProductBreadcrumbsLayout } from './ProductBreadcrumbs.Layout';
import ProductBreadcrumbsSeo from './ProductBreadcrumbs.Seo';

export const ProductBreadcrumbsContainer: React.FC<ProductBreadcrumbsProps> & {
    Seo: typeof ProductBreadcrumbsSeo;
} = (props) => <ProductBreadcrumbsLayout {...props} />;

ProductBreadcrumbsContainer.Seo = ProductBreadcrumbsSeo;
