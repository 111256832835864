import { ApiGwRequest, SetApiInstancesHeader } from 'services';
import { getFingerprint, getUserToken } from 'data';
import { getHybridConfig } from 'utils/platform';

const ALGOLIA_APP_ID = 'TB5JLM74FK';
const ALGOLIA_SEARCH_KEY = '4486b5b036e160793ff57260583f8a10';
const ALGOLIA_INDEX = 'jmart_catalog_index';
const ALGOLIA_API = 'https://insights.algolia.io/1/events';
const ALGOLIA_TOKEN_HEADER = 'X-Algolia-User-Token';

interface IEvent {
    eventType?: string;
    eventName?: string;
    objectIDs?: string[];
    queryID?: string;
    positions?: number[];
}

const getAlgoliaUserToken = () => {
    const hybrid = getHybridConfig();

    const fingerprint = getFingerprint();
    const appsflyer_id = String(hybrid?.appsflyer_id).trim();
    const emptyUser = getUserToken();

    if (fingerprint) {
        return fingerprint;
    }

    if (appsflyer_id.length && appsflyer_id !== 'empty') {
        return appsflyer_id;
    }

    return emptyUser;
};

const sendAlgoliaEvent = async (params: IEvent) => {
    try {
        const userToken = getAlgoliaUserToken();

        // if (!userToken) {
        //     throw new Error("User token doesn't exist");
        // }

        const data = {
            events: [
                {
                    ...params,
                    index: ALGOLIA_INDEX,
                    userToken,
                    // timestamp: Date.now(),
                },
            ],
        };

        console.log('[Algolia Event]:', data);

        SetApiInstancesHeader('X-Algolia-Application-Id', ALGOLIA_APP_ID);
        SetApiInstancesHeader('X-Algolia-API-Key', ALGOLIA_SEARCH_KEY);
        SetApiInstancesHeader(ALGOLIA_TOKEN_HEADER, userToken);

        ApiGwRequest({
            url: ALGOLIA_API,
            method: 'POST',
            data,
        });
    } catch (error) {
        console.error('[Algolia Event Error]: ', error);
    }
};

const AlgoliaEvents = {
    getToken: getAlgoliaUserToken,
    sendEvent: sendAlgoliaEvent,
    TOKEN_HEADER: ALGOLIA_TOKEN_HEADER,
};

export default AlgoliaEvents;
