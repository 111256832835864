import { createSelector } from 'reselect';
import { AppRootState } from 'store/types';
import { NewMainPageInitialState } from './types';

const getReducer = (state: AppRootState) => state.NewMainPageReducer;

const getNewMainPageState = createSelector([getReducer], (state): NewMainPageInitialState => state);

export const getNewMainPageSectionsData = createSelector(
    [getNewMainPageState],
    ({ sections, mainMenu }) => {
        const new_sections = [...sections];
        return new_sections
            .map((section) => ({
                ...section,
                data: section.type === 'section_menu_links' ? mainMenu : section.data,
            }))
            .sort((a: any, b: any) => a.order - b.order);
    }
);

export const getMainPageSsrMode = createSelector([getNewMainPageState], (state) => state.ssrMode);

export const getMainPagePrevCityId = createSelector([getNewMainPageState], (state) =>
    String(state.prevCityId)
);
