import TagManager from 'react-gtm-module-defer';

const isGtmLoaded = () => {
    const gtmStartedEvent = (window as any)?.dataLayer?.find(
        (element: any) => element['gtm.start']
    );
    if (!gtmStartedEvent) {
        return false; // Not even the GTM inline config has executed
    } else if (!gtmStartedEvent['gtm.uniqueEventId']) {
        return false; // GTM inline config has run, but main GTM js is not loaded (likely AdBlock, NoScript, URL blocking, etc.)
    }
    return true; // GTM is fully loaded and working
};

/* dataLayer template */
const sendDataLayer = (dataLayer: any) => {
    console.log('[AppGtmMetric]:', {
        sent: isGtmLoaded(),
        name: dataLayer.event,
        ecommerce: dataLayer?.ecommerce,
    });
    if (isGtmLoaded()) {
        return TagManager.dataLayer({ dataLayer });
    }
};

const createDataLayer = (event: string, data: any, rest = {}) => {
    const items = Array.isArray(data) ? [...data] : [data];
    return {
        event,
        ecommerce: {
            ...rest,
            items,
        },
    };
};

// view_promotion - показ баннеров на главной
const viewPromotion = async ({ title, position, id }: any = {}) => {
    try {
        const dataLayer = createDataLayer('view_promotion', [], {
            promotion_id: id,
            promotion_name: title,
            creative_name: title,
            creative_slot: position,
            location_id: window.location.href,
        });

        sendDataLayer(dataLayer);
    } catch (e) {
        console.warn(e);
    }
};

// select_promotion - клик по баннеру на главной
const selectPromotion = async ({ title, position, order, id }: any) => {
    try {
        const dataLayer = createDataLayer('select_promotion', [], {
            creative_name: title,
            creative_slot: position || order,
            promotion_id: id,
            location_id: window.location.href,
            promotion_name: title,
        });

        sendDataLayer(dataLayer);
    } catch (e) {
        console.warn(e);
    }
};

// view_item - страница товара
const viewItem = async (data: any) => {
    const {
        title,
        productName,
        product_id,
        productId,
        price,
        price_formatted = '',
        price_raw,
        brand,
    } = data;
    try {
        const dataLayer = createDataLayer('view_item', {
            item_name: title || productName,
            item_id: product_id || productId,
            price: price_raw || price || price_formatted,
            currency: 'KZT',
            item_brand: brand,
        });

        sendDataLayer(dataLayer);
    } catch (e) {
        console.warn(e);
    }
};

// select_item - клик по карточке товара
const selectItem = async (data: any, item_list_name: string) => {
    const { title, product_id, price, item_category } = data;
    try {
        const dataLayer = createDataLayer(
            'select_item',
            {
                item_name: title,
                item_id: product_id,
                price,
                item_category,
                currency: 'KZT',
                item_list_name,
                location_id: window.location.href,
            },
            {
                item_list_name,
            }
        );

        sendDataLayer(dataLayer);
    } catch (e) {
        console.warn(e);
    }
};

// view_cart - страница корзины
const viewCart = async (data: any[]) => {
    try {
        const dataLayer = createDataLayer(
            'view_cart',
            data.map((item) => ({
                item_name: item?.productName,
                item_id: item?.product_id,
                discount: item?.discountPrice,
                price: item?.price,
                currency: 'KZT',
                quantity: item?.amount,
            }))
        );

        sendDataLayer(dataLayer);
    } catch (e) {
        console.warn(e);
    }
};

// add_to_cart - добавить в корзину
const addToCart = async (data: any) => {
    const { product_id, amount } = data;
    try {
        const dataLayer = createDataLayer('add_to_cart', {
            item_id: product_id,
            quantity: amount,
        });

        sendDataLayer(dataLayer);
    } catch (e) {
        console.warn(e);
    }
};

// remove_from_cart - удалить из корзины
const removeFromCart = async (data: any) => {
    try {
        let payload = [];

        if (Array.isArray(data)) {
            payload = data.map((item) => ({
                item_id: item.product_id,
                quantity: item.amount,
            }));
        } else {
            payload = [
                {
                    item_id: data?.product_id,
                    quantity: data?.amount,
                },
            ];
        }

        const dataLayer = createDataLayer('remove_from_cart', payload);

        sendDataLayer(dataLayer);
    } catch (e) {
        console.warn(e);
    }
};

// add_to_wishlist - добавить в избранное
const addToWishlist = async (data: any) => {
    const { product_id, productName, price } = data;
    try {
        const dataLayer = createDataLayer('add_to_wishlist', {
            item_id: product_id,
            // item_list_name: 'Wishlist',
            item_name: productName,
            currency: 'KZT',
            price,
        });

        sendDataLayer(dataLayer);
    } catch (e) {
        console.warn(e);
    }
};

// view_item_list
const viewItemList = async (data: any[], item_list_name: string) => {
    try {
        const list = Array.isArray(data) && data.length > 6 ? data.slice(0, 6) : data;
        const dataLayer = createDataLayer(
            'view_item_list',
            list.map((item, index) => {
                const categories = Array.isArray(item?.category_ids) ? [...item.category_ids] : [];
                const item_category = item?.category_name || categories[categories.length - 1];

                return {
                    item_name: item?.productName,
                    item_id: item?.product_id,
                    discount: item?.discountPrice || 0,
                    price: item?.price,
                    currency: 'KZT',
                    item_category,
                    item_list_name,
                    index,
                };
            })
        );

        sendDataLayer(dataLayer);
    } catch (e) {
        console.warn(e);
    }
};

// form_merchant_success
const formMerchantSuccess = async (source: string) => {
    try {
        const dataLayer = createDataLayer('form_merchant_success', [], {
            location_id: source,
        });

        sendDataLayer(dataLayer);
    } catch (e) {
        console.warn(e);
    }
};

// begin_checkout
interface IBeginCheckout {
    item_id: string;
    item_name: any;
    price: string | number;
    item_category: number | null;
    item_category2: number | null;
    item_list_name: string;
    quantity: number;
}
const beginCheckout = async (payload: IBeginCheckout[]) => {
    try {
        const dataLayer = createDataLayer('begin_checkout', payload, {
            currency: 'KZT',
        });

        sendDataLayer(dataLayer);
    } catch (e) {
        console.warn(e);
    }
};

const GtmEvents = {
    viewPromotion,
    selectPromotion,
    viewItem,
    selectItem,
    viewCart,
    addToCart,
    removeFromCart,
    addToWishlist,
    viewItemList,
    formMerchantSuccess,
    beginCheckout,
};

export default GtmEvents;
