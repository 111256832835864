import loadable from '@loadable/component';
import { AppRouteMiddlewareLayer } from 'router/types';

const AppErrorPage = loadable(() => import('pages/AppErrorPage'), { ssr: true });

const isErrorPage: AppRouteMiddlewareLayer = (config) => {
    const { route, errorPage } = config;

    if (errorPage !== null) {
        return { ...config, route: { ...route, component: AppErrorPage } };
    }

    return config;
};

export { isErrorPage as default };
