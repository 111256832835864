import React, { useRef } from 'react';
import { Display, FlexBox, Icon, Input } from 'pyatka-ui';
import { AppMetricEvents } from 'AppMetric';
import { useAppRouter } from 'router/context';
import { SearchBarLayoutProps } from './types';
import { useSupermarket } from './hooks';
import { isSuperSearch } from './utils';
import { StoreSearchTag } from './SearchBar.DumbLayout';

const SearchBarLayout: React.FC<SearchBarLayoutProps> = ({
    search_value,
    placeholder,
    mobileBg,
    // other
    onChange,
    onKeywordSearch,
    clearSearch,
    setInputFocus,
    autoFocus = false,
    supermarket,
    store_id,
}) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const { location } = useAppRouter();

    const searchMetric = React.useCallback(() => {
        if (!search_value) {
            return;
        }

        if (store_id || isSuperSearch(location?.pathname)) {
            AppMetricEvents.jmart_search_fmcg_new({
                query: search_value,
                company_id: store_id,
                company_name: String(supermarket?.company || ''),
            });
        } else {
            AppMetricEvents.jmart_search({ keyword: search_value });
        }
    }, [location?.pathname, search_value, store_id, supermarket?.company]);

    const handleSearch = () => {
        searchMetric();
        onKeywordSearch();
    };

    const onKeyPress = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter') {
            handleSearch();
        }
    };

    const onFocus = React.useCallback(() => {
        setInputFocus(true);
        if (location?.pathname === '/') {
            AppMetricEvents.jmart_search_main_click();
        }

        if (isSuperSearch(location?.pathname)) {
            AppMetricEvents.jmart_search_main_supermarkets_click();
        }
    }, [location?.pathname, setInputFocus]);

    return (
        <Input
            // @ts-ignore
            autoFocus={autoFocus}
            // @ts-ignore
            onFocus={onFocus}
            onBlur={() => setInputFocus(false)}
            placeholder={placeholder}
            bg={{ default: mobileBg, md: 'block.secondary' }}
            color="text.main"
            value={search_value}
            onChange={onChange as any}
            onKeyPress={onKeyPress}
            prefix={
                store_id ? (
                    <Display md={false}>
                        <StoreSearchTag title={supermarket?.company} store_id={store_id} />
                    </Display>
                ) : null
            }
            suffix={
                <SearchSuffix
                    search_value={search_value}
                    onSearch={handleSearch}
                    onClear={clearSearch}
                />
            }
            p={3}
            py={{ default: 2, md: '13px' }}
            borderRadius={{ default: '16px', md: '999rem' }}
            fontSize={[3]}
            ref={inputRef}
        />
    );
};

interface SearchSuffixProps {
    onSearch: () => void;
    onClear: () => void;
    search_value: string;
}

const SearchSuffix: React.FC<SearchSuffixProps> = ({ onSearch, onClear, search_value }) => (
    <>
        {search_value.length > 0 && (
            <Display md={true} xxl={false}>
                <ClearIcon onClick={onClear} />
            </Display>
        )}
        <Display md={false} xxl={true}>
            <SearchIcon onClick={onSearch} />
        </Display>
    </>
);

const SearchIcon: React.FC<{ onClick: SearchSuffixProps['onSearch'] }> = ({ onClick }) => (
    <FlexBox
        mr={{ default: 2, md: 3 }}
        width="20px"
        height="20px"
        cursor="pointer"
        alignItems="center"
        justifyContent="center"
        onClick={onClick}
    >
        <Icon icon="search" fill="text.secondary" width="100%" />
    </FlexBox>
);

const ClearIcon: React.FC<{ onClick: SearchSuffixProps['onClear'] }> = ({ onClick }) => (
    <FlexBox
        p={1}
        mr={2}
        width="18px"
        height="18px"
        cursor="pointer"
        onClick={onClick}
        borderRadius="50%"
        alignItems="center"
        bg="block.placeholder"
        justifyContent="center"
    >
        <Icon icon="close_x_white" fill="white" width="100%" />
    </FlexBox>
);

export default React.memo(SearchBarLayout);
