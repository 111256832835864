import { FetchPageData } from 'router/types';
import {
    fetchProductDetails,
    fetchProductDetailsSeo,
} from 'modules/ProductDetail/ProductDetail.actions';
import { FetchWithAnchorValidate } from 'router/utils';
import { getProductReviews } from 'modules/ReviewService/ReviewService.actions';

export const NewProductDetailPageFetch: FetchPageData = ({ dispatch, payload = {} }) => {
    FetchWithAnchorValidate(() => {
        dispatch(
            fetchProductDetails({
                id: payload?.params?.product_id,
                fetchCategories: payload?.params?.fetchCategories,
            })
        );

        dispatch(fetchProductDetailsSeo({ id: payload?.params?.product_id }));
        dispatch(getProductReviews({ id: payload?.params?.product_id, params: { page: 1 } }));
    }, true);
};
