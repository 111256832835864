import {
    FILTER_COMPANY_IDS,
    FILTER_INDEX_PRICE,
    FILTER_VARIANT_KEY,
} from 'modules/ProductsList/ProductsList.helpers';

import { priceObjectHandler } from 'modules/ProductsList/utils';

export const handleParams = (params: any) =>
    Object.entries(params).reduce((acc, [key, val]) => {
        if (val) {
            return { ...acc, [key]: val };
        }
        return acc;
    }, {});

export const prepareFilters = (params: any) => {
    let preparedParams = { ...params };

    if (params?.filter) {
        const filter_query = ProductListQueryFilterParse(params.filter);
        preparedParams = { ...preparedParams, ...filter_query };
        delete filter_query.filter;
    }

    return preparedParams;
};

export const ProductListQueryFilterParse = (filter: string) => {
    const features = filter.split('_');
    const featuresArray = features.map((f: string) => f.split('-'));
    const featuresNestedObj: {
        [key: string]: string[];
    } = featuresArray.reduce((obj: any, fValues: string[]) => {
        return {
            ...obj,
            [fValues[0]]: fValues.slice(1),
        };
    }, {});
    const featuresSerializedObj: {
        [key: string]: string;
    } = {};
    for (const [key, value] of Object.entries(featuresNestedObj)) {
        value.forEach((v: string, idx: number) => {
            featuresSerializedObj[`${FILTER_VARIANT_KEY}[${key}][${idx}]`] = v;
        });
    }
    return featuresSerializedObj;
};

export const getOptionStyles = (active: boolean) => ({
    bg: active ? '#fff3f0' : 'tranparent',
    color: active ? 'text.brand' : 'text.main',
    fontWeight: active ? 'bold' : 'normal',
    borderRadius: 10,
    py: 2,
    px: 3,
    justifyContent: 'space-between',
    alignItems: 'center',
});

export const getDefaultPriceFilter = (filters: any) => {
    let res: number[] = [];
    const priceFilter = filters?.find((filter: any) => filter?.filter_type === 'price');
    if (priceFilter) {
        const { variants } = priceFilter;
        res = [variants?.min, variants?.max];
    }
    return res;
};

export const isPriceFilterActive = (defaultPrice: number[], filterPrice: number[]) => {
    let res = false;
    if (filterPrice?.length === 0) {
        return res;
    }

    if (defaultPrice?.length >= 2 && filterPrice?.length >= 2) {
        const [default_min, default_max] = defaultPrice;
        const [filter_min, filter_max] = filterPrice;

        res = default_min !== filter_min || default_max !== filter_max;
    }

    return res;
};

export const handleRangePos = (arr: number[], val: number, pos: number) =>
    pos === 0 ? [val, arr[1]] : [arr[0], val];

export const addRemoveFeature = (variant: any, feature_id: any, activeFilters: any = {}) => {
    /* ------------------------- Deep cloning of object ------------------------- */
    const deepClone = JSON.parse(JSON.stringify(activeFilters));

    /* --------------------------- add/delete checkbox -------------------------- */
    if (deepClone[feature_id] && deepClone[feature_id][variant.variant_id]) {
        delete deepClone[feature_id][variant?.variant_id];
    } else {
        deepClone[feature_id] = {
            ...activeFilters[feature_id],
            [variant?.variant_id]: variant,
        };
    }

    /* ------------------------ delete empty object keys ------------------------ */
    Object.keys(deepClone).map((i) => !Object.values(deepClone[i]).length && delete deepClone[i]);

    return deepClone;
};

/* -------------------------------- На выходе ------------------------------- */
/* ---------------- {   feature_variants[18][0]: "118103"   } --------------- */
export const filterFeature = (key: any, feature: any = {}) =>
    Object.keys(feature).reduce((str, filterIndex, index) => {
        const { min, max } = feature;
        if (key == FILTER_INDEX_PRICE) {
            return {
                ...str,
                ...priceObjectHandler(min, max),
            };
        }
        const featureKey = `${FILTER_VARIANT_KEY}[${key}][${index}]`;
        return { ...str, [featureKey]: filterIndex };
    }, {});

export const filterMerchant = (key: any, feature: any = {}) =>
    Object.keys(feature).reduce((str, filterIndex, index) => {
        return { ...str, [`${FILTER_COMPANY_IDS}[${index}]`]: filterIndex };
    }, {});
