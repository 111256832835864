import axios from 'axios';
import { getPlatform } from 'utils/platform';
// import { getSelectedLanguageStorage } from 'data';
// const lang = getSelectedLanguageStorage() === 'kk' ? 'kz' : getSelectedLanguageStorage();
export const headers = {
    'Storefront-Api-Access-Key': '91356a96edd62b929a9e5573d6585261',
    'Content-Type': 'application/json',
    'Accept-language': 'ru',
    'Platform': getPlatform().toUpperCase(),
};

export const createApiInstance = (instanceConfig?: any) => {
    const config = {
        headers,
        baseURL: __API_BASE_URL__,
        ...instanceConfig,
    };
    return axios.create(config);
};
