import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import 'regenerator-runtime/runtime.js';
import { getCities, getCityPolygon } from 'modules/Cities/Cities.services';
import { AppToaster } from 'plugins/app-toaster';
import { saveSelectedCityStorage } from 'data';
import { SetApiInstancesHeader } from 'services';
import { NewMainPageMenuFlush } from 'modules/NewMainPage/NewMainPage.actions';
import { getCitiesState } from 'modules/Cities/Cities.selectors';
import { getMainProfileAddressesDefault } from 'modules/UserProfileService/UserProfileService.selectors';
import { ProfileAddressEditBody, UserProfileAddressModel } from 'modules/UserProfileService/types';
import { updateGWProfileAddress } from 'modules/UserProfileService/UserProfileService.actions';
import {
    fetchCities,
    fetchCitiesFailed,
    fetchCitiesSucceed,
    fetchCityPolygon,
    fetchCityPolygonFailed,
    fetchCityPolygonSucceed,
    setSelectedCity,
} from './Cities.actions';

function* handleFetchCities() {
    try {
        const { data } = yield call(getCities);
        yield put(fetchCitiesSucceed({ cities: data?.data }));
    } catch (e: any) {
        console.log(e);
        AppToaster.warning({ body: 'cities_temporarily_not_displayed' });
        yield put(fetchCitiesFailed(e?.toString()));
    }
}

function* handleSetCity({ payload }: any) {
    if (payload) {
        const { flush, ...rest } = payload;
        const preparedData = { ...rest };
        SetApiInstancesHeader('City-Id', preparedData?.id || '443');
        __BROWSER__ && saveSelectedCityStorage(preparedData);
        if (flush) {
            const aa: UserProfileAddressModel = yield select(getMainProfileAddressesDefault);
            if (aa) {
                const udpdating_add: ProfileAddressEditBody = {
                    fields:
                        Object.values(aa?.values || {}).map((value) => ({
                            field_id: Number(value.field_id),
                            value: value.value,
                        })) || [],
                    profile_id: aa?.profile_id,
                    is_default: false,
                };
                yield put(updateGWProfileAddress(udpdating_add));
            }
            yield put(NewMainPageMenuFlush());
        }
    }
}

function* handleFetchCityPolygon({ payload: { cityId } }: { payload: { cityId: number } }) {
    try {
        const { selectedCity, cityPolygonData } = yield select(getCitiesState);
        const ifPolygonExists =
            cityPolygonData?.cityId === Number(selectedCity) && Boolean(cityPolygonData?.polygon);
        if (!ifPolygonExists) {
            const { data } = yield call(getCityPolygon, cityId);
            yield put(
                fetchCityPolygonSucceed({
                    cityId: data?.data.city_id,
                    polygon: data?.data?.polygon,
                })
            );
        }
    } catch (e: any) {
        console.log(e);
        // AppToaster.warning({ body: 'cities_temporarily_not_displayed' });
        yield put(fetchCityPolygonFailed(e?.toString()));
    }
}

export default function* () {
    yield all([
        takeLatest(fetchCities, handleFetchCities),
        takeLatest(setSelectedCity, handleSetCity),
        takeLatest(fetchCityPolygon, handleFetchCityPolygon),
    ]);
}
