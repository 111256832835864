/*
 * response: {
 *   title: Доставка / Самовывоз / Постамат
 *   address: null / pickup_address / postomat_address.street + postomat_address.building_number
 *   schedule_desc: null / pickup_time / postomat_schedule.desc
 *   shipping_date: deliveryDate / null / null
 *   shipping_interval: interval / null / null
 * }
 *
 * */

import dayjs from 'dayjs';

const parseShippingDate = (shipping_date: string = '') => {
    const [date, time] = shipping_date?.split(' ');
    return {
        date: dayjs(date, 'YYYY-MM-DD').format('D MMMM'),
        time,
    };
};

const parseDeliveryInterval = (delivery_interval: string = '') => {
    const [date, time] = delivery_interval?.split(',');

    return {
        date: dayjs(date, 'DD.MM.YYYY').format('D MMMM'),
        time: time?.replace('-', 'до'),
    };
};

const isValidString = (date: string) => {
    return typeof date === 'string' && Boolean(date.length);
};

const parsePickupInfo = (pickupInfo: any) => {
    return {
        title: 'Самовывоз',
        address: pickupInfo?.pickup_address || pickupInfo?.address,
        schedule_desc: pickupInfo?.pickup_time,
        shipping_date: null,
        shipping_interval: null,
        coordinates: {
            lat: pickupInfo?.lat,
            lon: pickupInfo?.lon,
            city: pickupInfo?.city?.title,
            city_id: pickupInfo?.city?.id,
        },
    };
};

const parseShippingInfo = (shippingInfo: any) => {
    const deliveryDate = isValidString(shippingInfo?.shipping_date)
        ? parseShippingDate(shippingInfo?.shipping_date)
        : isValidString(shippingInfo?.client_time_interval)
        ? parseDeliveryInterval(shippingInfo?.client_time_interval)
        : { date: '', time: '' };

    return {
        title: 'Доставка',
        address: null,
        schedule_desc: null,
        shipping_date: deliveryDate.date,
        shipping_interval: deliveryDate.time,
        coordinates: {
            lat: shippingInfo?.store_location_info?.latitude,
            lon: shippingInfo?.store_location_info?.longitude,
            city: shippingInfo?.store_location_info?.city,
            city_id: shippingInfo?.store_location_info?.city_id,
        },
    };
};
const parsePostomatInfo = (postomatInfo: any) => {
    const getPostomatAddress = (postomat_address) => {
        const isAddressValid = (postomat_address) => {
            return (
                Boolean(postomat_address) &&
                Boolean(postomat_address?.street) &&
                Boolean(postomat_address?.building_number)
            );
        };

        return isAddressValid(postomat_address)
            ? `${postomatInfo?.postomat_address?.street}, ${postomatInfo?.postomat_address?.building_number}`
            : null;
    };

    return {
        title: 'Постамат',
        address: getPostomatAddress(postomatInfo?.postomat_address),
        schedule_desc: postomatInfo?.postomat_schedule?.desc,
        shipping_date: null,
        shipping_interval: null,
        coordinates: {
            lat: postomatInfo?.location?.latitude,
            lon: postomatInfo?.location?.longitude,
            city: postomatInfo?.store_location_info?.city,
            city_id: postomatInfo?.store_location_info?.city_id,
        },
    };
};

const parseWithoutDelivery = (info: any) => {
    return null;
};

const DeliveryTypeToParser = {
    postomat: parsePostomatInfo,
    pickup: parsePickupInfo,
    shipping_jpost: parseShippingInfo,
    shipping_merchant: parseShippingInfo,
    without_delivery: parseWithoutDelivery,
};

export const prepareDeliveryInfo = (
    delivery_type:
        | 'postomat'
        | 'pickup'
        | 'shipping_jpost'
        | 'shipping_merchant'
        | 'without_delivery',
    deliveryInfo: any
) => {
    if (delivery_type && deliveryInfo) {
        return DeliveryTypeToParser[delivery_type](deliveryInfo);
    }
    return null;
};

export const isInterval1BeforeInterval2 = (interval1: string, interval2: string) => {
    if (!interval2) {
        return false;
    }
    const [date1Str, time1Str] = interval1?.trim().split(',');
    const [date2Str, time2Str] = interval2?.trim().split(',');

    const [_startDate1Str, endDate1Str] = time1Str?.trim().split('-');
    const [startDate2Str, _endDate2Str] = time2Str?.trim().split('-');

    const endDate1 = new Date(
        dayjs(date1Str, 'DD.MM.YYYY').format('YYYY-MM-DD') + 'T' + endDate1Str?.trim()
    );
    const startDate2 = new Date(date2Str + 'T' + startDate2Str?.trim());
    return endDate1 < startDate2;
};
