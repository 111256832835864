import React from 'react';
import { Box, Image, usePyatka } from 'pyatka-ui';
import { BoxProps } from 'pyatka-ui/dist/components-shared/Box/types';
import { AppLink } from 'components';
import { AppLinkProps } from 'components/AppLink/types';
import { ProductCardLayoutProps } from 'components/ProductCard/types';
import ZoomImg from './ZoomImg';

interface ProductImageProps
    extends Pick<ProductCardLayoutProps, 'effect' | 'layout' | 'route_state'> {
    url: AppLinkProps['to'];
    isLoading: boolean;
    image: string;
    image_preload?: string;
    appearance: string;
    ariaLabel: string;
    handleClick: () => void;
    isClothes?: boolean;
    isNewImage?: boolean;
    isPreventLink: boolean;
}

const ProductImage: React.FC<ProductImageProps> = ({
    url,
    isLoading,
    image,
    image_preload = '',
    appearance,
    ariaLabel,
    handleClick,
    isClothes = false,
    isNewImage = false,
    isPreventLink = false,
    layout = 'old',
    effect,
    route_state,
}) => {
    const { scales } = usePyatka();

    const aspectRatio: string = React.useMemo(() => {
        if (isClothes) {
            return '40/49';
        }

        return '1/1';
    }, [isClothes]);

    const boxProps: Omit<BoxProps, 'color'> = React.useMemo(() => {
        if (layout === 'old') {
            return { mb: scales?.isMobile ? '18px' : '8px' };
        }

        return {};
    }, [layout, scales?.isMobile]);

    const imgStyles: React.CSSProperties = React.useMemo(() => {
        if (layout === 'old') {
            return { marginBottom: scales?.isMobile ? 11 : 8 };
        }

        return {};
    }, [layout, scales?.isMobile]);

    return (
        <Box {...boxProps}>
            <AppLink
                to={url}
                ariaLabel={ariaLabel}
                onClickProduct={handleClick}
                preventOnClick={isPreventLink}
                route_state={route_state}
            >
                <ZoomImg layout={layout}>
                    <Image
                        effect={effect}
                        src={image}
                        preloadSrc={image_preload}
                        borderRadius={14}
                        skeleton={isLoading}
                        backgroundSize="contain"
                        appearance={appearance === 'slide' ? '100%' : '71%'}
                        // Фоновый цвет всегда белый
                        backgroundOnLoad="white"
                        bg="block.main"
                        new_image={isNewImage}
                        style={{
                            aspectRatio,
                            ...imgStyles,
                        }}
                    />
                </ZoomImg>
            </AppLink>
        </Box>
    );
};

export default React.memo(ProductImage);
