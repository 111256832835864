import React from 'react';
import { usePyatka, Modal } from 'pyatka-ui';
import dayjs from 'dayjs';
import { AppMetricEvents } from 'AppMetric';
import { getSearchPromo, saveSearchPromo } from 'data';
import { useAppRouter } from 'router/context';
import { IPromotionModal } from 'modules/Promotions/types';
import { PromotionModalLayout } from './PromotionModal.Layout';

const PromotionModal = ({
    is_opened,
    is_dialog_open,
    url,
    section_id,
    promo_key,
    savePromoTimestamp,
    ...restProps
}: IPromotionModal & IModal) => {
    const { appRedirect } = useAppRouter();

    const [isShow, setIsShow] = React.useState(false);

    const {
        scales: { isMobile },
    } = usePyatka();

    const handleSubmit = () => {
        try {
            savePromoTimestamp();
        } catch (e) {
            console.log(e);
        }
    };

    // Открыть модалку и сохранить device_id/fingerprint
    React.useEffect(() => {
        const handler = setTimeout(() => {
            if (!is_opened && !is_dialog_open) {
                setIsShow(true);
            }
        }, 2000);
        return () => clearTimeout(handler);
    }, [is_dialog_open]);

    React.useEffect(() => {
        if (isShow) {
            AppMetricEvents.jmart_modal_window_view({ keyword: restProps?.title });
        }
    }, [isShow]);

    // закрыть модалку (backdrop or swipe)
    const handleClose = (e: any) => {
        if (e) {
            e.stopPropagation();
        }
        handleSubmit();
        setIsShow(false);
    };

    // кнопка Попробовать
    const handleClickBtn = () => {
        setIsShow(false);
        appRedirect(url);
        AppMetricEvents.jmart_modal_window_click({ [promo_key]: 'click' });
        handleSubmit();
    };

    return (
        <Modal
            width={isMobile ? 'auto' : '450px'}
            positionMode={isMobile ? 'mobile' : 'default'}
            open={isShow}
            onClose={handleClose}
            swipeable={true}
            padding={'0'}
            borderRadius={isMobile ? '26px 26px 0 0' : '26px'}
            style={{
                overflow: 'hidden',
            }}
        >
            <PromotionModalLayout
                {...restProps}
                isMobile={isMobile}
                handleClickBtn={handleClickBtn}
                handleClose={handleClose}
            />
        </Modal>
    );
};

export const PromotionModalContainer = React.memo((props: any) => {
    const { promotion_modal_data, isMobile } = props;

    /* --------------------------- If no promo return --------------------------- */
    if (!promotion_modal_data) {
        return null;
    }

    const { endDate, periodicity, promo_key } = promotion_modal_data;

    /* -------------------------- Promo last seen date -------------------------- */
    const seen_date = getSearchPromo(promo_key);

    /* -------------------- Check if periodicity hours passed ------------------- */
    const is_opened = React.useMemo(
        () => Math.abs(Date.now() - seen_date) / 36e5 <= Number(periodicity),
        [seen_date]
    );

    /* ------------- Ранний выход если акция кончилась или ее видели ------------ */
    if (is_opened || Date.now() > new Date(endDate).getTime()) {
        return null;
    }

    const savePromoTimestamp = () => saveSearchPromo(promo_key, Date.now());

    return (
        <PromotionModal
            {...promotion_modal_data}
            is_opened={is_opened}
            isMobile={isMobile}
            savePromoTimestamp={savePromoTimestamp}
            is_dialog_open={props.is_dialog_open}
        />
    );
});

interface IModal {
    is_dialog_open: boolean;
    is_opened: boolean;
    period: string;
    promo_key: string;
    savePromoTimestamp: () => void;
    isMobile: boolean;
}

const formatDate = (st: any, en: any): string =>
    dayjs(st).format('DD.MM') + ' - ' + dayjs(en).format('DD.MM');
