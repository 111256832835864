import React from 'react';
import * as Sentry from '@sentry/react';
import { ErrorBoundaryProps } from '@sentry/react/types/errorboundary';
import ErrorMsg from './ErrorMsg';
// import React, { Component, ErrorInfo, ReactNode } from 'react';
// import { Container, Col, Row, Button, FlexBox } from 'pyatka-ui';
// import { TemporaryProblem } from 'components';

// interface Props {
//     children?: ReactNode;
//     name?: string;
//     route?: any;
// }

// interface State {
//     hasError: boolean;
// }

// class ErrorBoundary extends Component<Props, State> {
//     public static getDerivedStateFromError(_: Error): State {
//         return { hasError: true };
//     }

//     public state: State = {
//         hasError: false,
//     };

//     public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
//         console.error(
//             '[ERROR-BOUNDARY] error:',
//             `[${this.props.name}]`,
//             error,
//             errorInfo,
//             this.props.route
//         );
//     }

//     public render() {
//         if (this.state.hasError) {
//             return (
//                 <Container>
//                     <Row>
//                         <Col>
//                             <TemporaryProblem />
//                             {/* <FlexBox alignItems="center" justifyContent="center" mt={3}>
//                                 <Button
//                                     styleType="contained"
//                                     variant="brand"
//                                     onClick={() => window.location.reload()}
//                                 >
//                                     Обновить
//                                 </Button>
//                             </FlexBox> */}
//                         </Col>
//                     </Row>
//                 </Container>
//             );
//         }

//         return this.props.children;
//     }
// }

const ErrorBoundary: React.FC<ErrorBoundaryProps> = ({ children }) => (
    <Sentry.ErrorBoundary fallback={ErrorMsg}>{children}</Sentry.ErrorBoundary>
);

export default ErrorBoundary;
