import React from 'react';
import { Box, FlexBox, Icon, Image, Text } from 'pyatka-ui';
import { CartProductAmountLabel } from 'components/ProductSection/ProductSection.styles';
import { useI18n } from 'hooks/useI18n';

interface IGiftCardProps {
    description: string;
    imageUrl: string;
    gift_count: number;
}

const GiftBlock = (props: IGiftCardProps) => {
    const { imageUrl, description, gift_count } = props;
    const { $t } = useI18n();

    return (
        <FlexBox
            justifyContent={'space-between'}
            alignItems={'center'}
            gap={'13px'}
            paddingLeft={'20px'}
            mt={'12px'}
        >
            <Box minWidth={'36px'} height={'36px'} position={'relative'}>
                <Image src={imageUrl} borderRadius={12} />
                <Box
                    height={'22px'}
                    width={'22px'}
                    position={'absolute'}
                    top={'-7px'}
                    right={'-7px'}
                    zIndex={100}
                >
                    <Icon width={'18px'} height={'18px'} icon={'gift-round'} />
                </Box>
            </Box>

            <FlexBox flex={1} alignItems={'center'} gap={'16px'} justifyContent={'space-between'}>
                <FlexBox flex={1} flexDirection={'column'} gap={'4px'} maxWidth={'90%'}>
                    <Text
                        color={'text.secondary'}
                        fontSize={'12px'}
                        lineHeight={'15px'}
                        fontWeight={'500'}
                    >
                        {$t('gift_to_order').toCapitalizeFirstLetter()}
                    </Text>
                    <Text
                        lineClamp={2}
                        fontSize={'12px'}
                        lineHeight={'15px'}
                        style={{ overflowWrap: 'anywhere' }}
                    >
                        {description}
                    </Text>
                </FlexBox>

                <FlexBox height={'31px'} alignItems={'center'}>
                    <Box backgroundColor={'block.secondary'} borderRadius={'38px'}>
                        <CartProductAmountLabel color={'text.secondary'}>
                            <Text color={'text.secondary'}>
                                {gift_count} {$t('sht')}
                            </Text>
                        </CartProductAmountLabel>
                    </Box>
                </FlexBox>
            </FlexBox>
        </FlexBox>
    );
};

export default GiftBlock;
