import React from 'react';
import { FlexBox } from 'pyatka-ui';
import { FlexProps } from 'pyatka-ui/dist/components-shared/FlexBox/types';
import { StyledProps } from 'styled-components';

interface HeaderWrapperProps extends FlexProps, StyledProps<any> {
    children: React.ReactNode;
    overflow?: string;
    height?: string;
}

export const HeaderWrapper: React.FC<HeaderWrapperProps> = ({ children, ...rest }) => {
    return (
        <FlexBox
            height="45px"
            overflow="hidden"
            alignItems="center"
            justifyContent="space-between"
            {...rest}
        >
            {children}
        </FlexBox>
    );
};
