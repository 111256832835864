import { InterceptorLayer } from 'services/types';
import { AppToaster } from 'plugins/app-toaster';

const onErrResponse: InterceptorLayer = (config) => {
    if (!__IS_PROD__) {
        AppToaster.error({
            head: 'Dev Debug: Network error',
            body: config?.res?.data.message,
            clearPrevious: false,
        });
        return config;
    }

    return config;
};

export default { onErrResponse };
