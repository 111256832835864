import React, { useCallback, useEffect, useState } from 'react';
import { Box } from 'pyatka-ui';
import { Map, Placemark, YMaps, YMapsApi } from 'react-yandex-maps';
import defaultPickup from 'pages/CheckoutDeliveryV2Page/assets/defaultPickup.svg';
import { getGeoCodeServiceV2 } from 'modules/UserProfileService/UserProfileService.services';
import { YMapsProps } from './types';

const API_KEY = 'dcdfda7f-83e6-47f7-a5a2-1827cbbd3cc6';

export const LocationYMaps = (props: YMapsProps) => {
    const { points, selectedCity } = props;

    const [center, setCenter] = React.useState<number[]>([43.226647, 76.946938]);
    const [bounds, setBounds] = React.useState<number[]>([]);
    const [containerSize, setContainerSize] = React.useState<number[]>([]);

    // --------------- ZOOM CONTROL --------------- //
    const [zoom, setZoom] = React.useState(14);
    const [ymaps, setYmaps] = useState<YMapsApi | null>(null);
    const getGeocodePosition = useCallback(async () => {
        if (points.length === 0 && selectedCity) {
            const { data } = await getGeoCodeServiceV2({ geocode: `город${selectedCity.trim()}` });
            setZoom(12);
            setCenter([data?.data[0].lat, data?.data[0].lon]);
        }
    }, [points, selectedCity]);

    const calculateCenterAndZoom = useCallback(() => {
        const p = points.map((p) => [p.lat, p.lon]).sort((a, b) => a[1] - b[1]);
        if (ymaps && p.length === 2) {
            const result = ymaps.util.bounds.getCenterAndZoom(p, containerSize, undefined, {
                inscribe: true,
                preciseZoom: false,
                margin: [0, 0, 0, 350],
            });
            setCenter([result.center[0], result.center[1]]);
            setZoom(result.zoom);
        } else if (p.length === 1) {
            setCenter(p[0]);
        }
    }, [ymaps, points, containerSize]);

    useEffect(() => {
        calculateCenterAndZoom();
        getGeocodePosition();
    }, [getGeocodePosition, calculateCenterAndZoom]);

    return (
        <Box position="relative" width={'100%'}>
            <>
                <YMaps
                    query={{
                        // apikey: API_KEY,
                        coordorder: 'latlong',
                        load: 'Map,Placemark,geocode,control.ZoomControl,util.bounds',
                    }}
                >
                    <Map
                        state={{ zoom, center, margin: [20] }}
                        width={'100%'}
                        height={'100%'}
                        onLoad={(ymaps) => {
                            setYmaps(ymaps);
                        }}
                        instanceRef={(ref) => {
                            if (ref) {
                                setBounds(ref?._bounds);
                                setContainerSize(ref?._containerSize);
                            }
                        }}
                    >
                        {points.length > 0 &&
                            points.map((point, index) => (
                                <Placemark
                                    key={index}
                                    geometry={[point?.lat, point?.lon]}
                                    options={{
                                        iconImageHref: point.icon || defaultPickup,
                                        iconLayout: 'default#image',
                                        iconImageSize: [32, 37],
                                        iconImageOffset: [-16, -37],
                                    }}
                                />
                            ))}
                    </Map>
                </YMaps>
            </>
        </Box>
    );
};
