import React from 'react';
import { Button, Icon, Text } from 'pyatka-ui';
import { ShowMoreButtonProps } from './types';

const DEFAULT_PADDING = { default: '8px 16px', md: '10px 18px' };

const ShowMoreButton: React.FC<ShowMoreButtonProps> = ({
    children,
    collapsed,
    toggleCollapse,
    color = 'text.primary',
    bg = 'block.main',
    fontWeight = 600,
    p = DEFAULT_PADDING,
}) => (
    <Button
        p={{ ...DEFAULT_PADDING, ...p }}
        border={0}
        backgroundColor={bg}
        borderRadius="999rem"
        onClick={toggleCollapse}
        buttonSuffix={
            <Icon
                width="18px"
                height="16px"
                fill="rgb(32, 31, 31)"
                icon={collapsed ? 'arrowUp' : 'arrowDown'}
            />
        }
    >
        <Text fontSize={{ default: '0.875em', md: '1em' }} fontWeight={fontWeight} color={color}>
            {children}
        </Text>
    </Button>
);

export default ShowMoreButton;
