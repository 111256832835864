import React from 'react';
import { useDispatch } from 'react-redux';
import { Display, FlexBox, Icon, Text } from 'pyatka-ui';
import { AppLink } from 'components';
import { useAppRouter } from 'router/context';
import { SearchServiceMobileOverlaySet } from 'modules/SearchService/SearchService.actions';
import { DumbSearchBarProps } from './types';
import { useSupermarket } from './hooks';

const SearchBarDumbLayout: React.FC<DumbSearchBarProps> = ({
    search_value,
    placeholder,
    mobileBg,
    supermarket,
    store_id,
}) => {
    const dispatch = useDispatch();
    const { location } = useAppRouter();
    const showSearchResult = location.pathname === '/search';

    const openMobileSearch = () => dispatch(SearchServiceMobileOverlaySet(true));

    return (
        <Display md={true}>
            <FlexBox
                p={3}
                px="12px"
                gap="10px"
                height="40px"
                bg={mobileBg}
                alignItems="center"
                borderRadius="999rem"
                id="search-dumb-layout"
                onClick={openMobileSearch}
                justifyContent="space-between"
                py={{ default: 2, md: '13px' }}
                flexDirection={showSearchResult ? 'row' : 'row-reverse'}
            >
                {!showSearchResult && (
                    <FlexBox
                        mx="4px"
                        width="17px"
                        height="17px"
                        flexShrink={0}
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Icon icon="search" fill="text.secondary" width="100%" />
                    </FlexBox>
                )}
                <Text
                    lineClamp={1}
                    fontSize={{ default: '14px' }}
                    color={search_value ? 'text.main' : 'text.secondary'}
                    style={{ wordBreak: 'break-word', flex: 1, textAlign: 'left' }}
                >
                    {search_value || placeholder}
                </Text>

                {store_id && <StoreSearchTag title={supermarket?.company} store_id={store_id} />}
            </FlexBox>
        </Display>
    );
};

export default React.memo(SearchBarDumbLayout);

export const StoreSearchTag = ({
    store_id,
    title,
}: {
    store_id: string;
    title?: string | null;
}) => (
    <AppLink
        to={`/store/${store_id}`}
        onClick={(e) => e.stopPropagation()}
        style={{
            padding: '8px 12px',
            marginRight: '-12px',
        }}
    >
        <FlexBox
            bg={['white', '#BEBEB633']}
            p={['4px 8px', '8px 12px']}
            maxWidth={['80px', 'initial']}
            borderRadius="28px"
            justifyContent="flex-end"
            alignItems={'center'}
            height={['auto', '36px']}
            m={['', '6px']}
        >
            <Text
                textAlign="left"
                fontWeight={600}
                // @ts-ignore
                color={['text.main', '#898D94']}
                fontSize={[12, '16px']}
                lineClamp={1}
            >
                {title}
            </Text>
        </FlexBox>
    </AppLink>
);
