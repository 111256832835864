import React from 'react';
import { Box } from 'pyatka-ui';
import { MainBody, MobileTabNavigation, NewFooter, NewHeader } from 'components';

import { DefaultLayoutLayoutProps } from './types';

export const DefaultLayoutLayout: React.FC<DefaultLayoutLayoutProps> = (props) => {
    const { children, route }: any = props;

    return (
        <React.Fragment>
            <Box>
                {/* <Header /> */}
                <NewHeader route={route} />
                <MobileTabNavigation />
                <MainBody>{children}</MainBody>
                {/*<FooterNew />*/}
                <NewFooter />
            </Box>
        </React.Fragment>
    );
};
