import React from 'react';
import { Box, Image, Text, usePyatka } from 'pyatka-ui';
import { AppLink } from 'components';
import { dispatchCategoryMetric } from 'AppMetric/helpers';
import { useAppRouter } from 'router/context';
import { useI18n } from 'hooks/useI18n';
import { StyledCategoryBoxTitle } from './CategoryBox.styles';
import { CategoryBoxLayoutProps } from './types';

const MOBILE_RESPONSIVE = '100%';
const TABLET_RESPONSIVE = '82%';
const DESKTOP_RESPONSIVE = '53.3%';

export const CategoryBoxLayout: React.FC<CategoryBoxLayoutProps> = ({
    image,
    title,
    url,
    main,
    isLoading,
    textProps,
    wrapperProps,
    appearance,
    categoryId,
    isExternal = false,
    backgroundSize,
    backgroundPosition,
    // @ts-ignore
    category1,
}) => {
    const { $t } = useI18n();
    const { theme, windowSize } = usePyatka();
    const { location } = useAppRouter();

    // ------------ image appearance
    const ratio = React.useMemo(() => {
        let ratio = appearance || null;
        if (ratio === null) {
            if (windowSize.width > theme.scales[3].value) {
                ratio = DESKTOP_RESPONSIVE;
            } else if (windowSize.width > theme.scales[2].value) {
                ratio = TABLET_RESPONSIVE;
            } else {
                ratio = MOBILE_RESPONSIVE;
            }
        }

        return ratio;
    }, [appearance, theme.scales, windowSize.width]);

    // ----------- image background position
    const bgPosition = main ? 'center' : 'left bottom';

    // ----------- image background size
    const bgSize = main ? 'cover' : windowSize.width > theme.scales[2].value ? 'contain' : 'cover';
    const section_id = url?.query?.section_id;

    const categoryMetricSender = React.useCallback(() => {
        dispatchCategoryMetric(categoryId || section_id, location, title, category1);
    }, [categoryId, section_id, location, title, category1]);

    return (
        <AppLink to={url} isExternal={isExternal}>
            <Box
                position="relative"
                borderRadius={12}
                border={main ? '1px solid transparent' : '2px solid rgba(201, 213, 225, 0.2)'}
                onClick={categoryMetricSender}
                {...wrapperProps}
            >
                <Image
                    className="image"
                    skeleton={isLoading}
                    src={image || ''}
                    appearance={ratio}
                    borderRadius={12}
                    backgroundSize={backgroundSize || bgSize}
                    backgroundPosition={backgroundPosition || bgPosition}
                />
                <StyledCategoryBoxTitle breakpoints={theme.breakpoints}>
                    <Text
                        fontSize={{ default: 2, md: 3 }}
                        color={main ? 'text.inverse' : 'text.main'}
                        fontWeight={main ? 'bold' : 'regular'}
                        textAlign="right"
                        skeleton={isLoading}
                        {...textProps}
                    >
                        {$t(title)}
                    </Text>
                </StyledCategoryBoxTitle>
            </Box>
        </AppLink>
    );
};
