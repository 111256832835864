import React from 'react';
import { Box, Button, FlexBox, Icon, Image, Space, Text, usePyatka } from 'pyatka-ui';
import { AppLink } from 'components';
import { useI18n } from 'hooks/useI18n';
import { EmptyContentBlockLayoutProps } from './types';
// import styles from './EmptyContentBlock.module.scss';

export const EmptyContentBlockNewLayout: React.FC<EmptyContentBlockLayoutProps> = (props) => {
    const { $t } = useI18n();
    const {
        scales: { isMobile = false },
    } = usePyatka();
    const {
        icon = 'ic_empty_results',
        image = '',
        title = 'cart_empty',
        description = 'use_catalog_or_search',
        redirectText = 'go_back',
        customRedirectText = '',
        redirectTo,
        height = '70vh',
    } = props;
    return (
        <React.Fragment>
            <FlexBox
                m="0 auto"
                flexDirection={'column'}
                justifyContent={'center'}
                alignItems={'center'}
                textAlign={'center'}
                height={height}
                width={'100%'}
            >
                {image ? (
                    <Image
                        src={image}
                        width={isMobile ? '120px' : '160px'}
                        height={isMobile ? '120px' : '160px'}
                    />
                ) : (
                    <Icon
                        icon={icon}
                        width={isMobile ? '120px' : '160px'}
                        height={isMobile ? '120px' : '160px'}
                    />
                )}
                <Space height={'36px'} />
                <FlexBox
                    flexDirection={'column'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    width={'40%'}
                >
                    <Text
                        textAlign={'center'}
                        color={'text.main'}
                        fontSize={{ default: '36px' }}
                        fontWeight="600"
                    >
                        {$t(title)}
                    </Text>
                    <Space height={'10px'} />

                    <Text
                        width={'40%'}
                        textAlign={'center'}
                        color={'text.secondary'}
                        fontSize={{ default: '16px' }}
                    >
                        {$t(description)}
                    </Text>
                </FlexBox>

                {Boolean(redirectTo) && (
                    <Box>
                        <Space height={'12px'} />

                        <AppLink to={redirectTo}>
                            <Button
                                px={3}
                                py={2}
                                backgroundColor={'block.brand'}
                                color={'#ffffff'}
                                borderRadius={'15px'}
                                border={'none'}
                            >
                                <Text fontSize={{ default: '11px' }}>
                                    {customRedirectText ? (
                                        <NewlineText text={customRedirectText} /> //.replace(/\n/g, '<br />')
                                    ) : (
                                        $t(redirectText)
                                    )}
                                </Text>
                            </Button>
                        </AppLink>
                    </Box>
                )}
            </FlexBox>
        </React.Fragment>
    );
};

function NewlineText({ text = ' ', ...rest }: any) {
    // eslint-disable-next-line react/jsx-key
    return text.split('\n').map((str: string) => <div {...rest}>{str}</div>);
}
