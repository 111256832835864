import { useSelector } from 'react-redux';
import React from 'react';
import { getI18nState } from 'modules/App/App.selectors';

const debug_statues = {
    good: '[✅]:',
    bad: '[🚨]:',
    ok: '[👌]:',
};

const DEBUG_MODE = false;

export const useI18n = () => {
    const { activeLanguage, translations, fallback } = useSelector(getI18nState);

    const doFancyDebug = (
        value: string,
        options: { type: 'good' | 'bad' | 'ok'; debug?: boolean }
    ) => `${DEBUG_MODE ? debug_statues[options.type] : ''}${value}`;

    const $t = React.useCallback(
        (key: string) => {
            const word = translations[activeLanguage?.toString()]?.words[key?.toString()];
            const word_f = translations[fallback?.toString()]?.words[key?.toString()];
            return word !== undefined
                ? doFancyDebug(word, { type: 'good' })
                : word_f !== undefined
                ? doFancyDebug(word_f, { type: 'bad' })
                : key !== undefined
                ? doFancyDebug(key, { type: 'ok' })
                : '';
            // return word !== undefined ? word : word_f !== undefined ? word_f : key;
        },
        [fallback, activeLanguage, translations]
    );

    $t.prototype = String.prototype;
    // eslint-disable-next-line no-extend-native
    String.prototype.toCapitalizeFirstLetter = function ({ each } = { each: false }) {
        if (!each) {
            return this.charAt(0).toUpperCase() + this.toLowerCase().slice(1);
        }
        return this.split(' ')
            .map((word: string) => word.charAt(0).toUpperCase() + word.toLowerCase().slice(1))
            .join(' ');
    };
    // eslint-disable-next-line no-extend-native
    String.prototype.addBreakLineInText = function ({
        afterWordIndex,
    }: {
        afterWordIndex: number | number[];
    }) {
        const words = this.split(' ');
        if (typeof afterWordIndex === 'number') {
            afterWordIndex = [afterWordIndex];
        }

        for (const index of afterWordIndex) {
            if (index >= 0 && index < words.length - 1) {
                // eslint-disable-next-line security/detect-object-injection
                words[index] += '\n' + words[index + 1];
                words[index + 1] = '';
            }
        }

        return words.join(' ');
    };
    return {
        $t,
        language: activeLanguage || fallback,
    };
};
