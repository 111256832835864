import React from 'react';
import { Button } from 'pyatka-ui';
import { useParams } from 'react-router';
import { AlgoliaEvents } from 'metrics';
import { isQrTrigger, triggerQrModal } from 'utils/platform';
import { useI18n } from 'hooks/useI18n';
import { Dialog } from 'plugins/dialog';
import { useAppRouter } from 'router/context';
import { OfferProps } from '../types';
import ProductCounterModal from './ProductCounterModal';

const AddToCartButton: React.FC<OfferProps> = (props) => {
    const { isInCart } = props;
    const { $t } = useI18n();

    return isInCart ? (
        <Button
            border="none"
            color="text.secondary"
            backgroundColor="block.secondary"
            {...BUTTON_STYLE}
        >
            {$t('in_cart')}
        </Button>
    ) : (
        <ButtonWrapper {...props}>
            <Button styleType="contained" variant="brand" {...BUTTON_STYLE}>
                {$t('select')}
            </Button>
        </ButtonWrapper>
    );
};

export default AddToCartButton;

// ==============  Button Wrapper =============== //
type ParamsType = {
    product_id: string;
    product_type: string;
};

export const ButtonWrapper: React.FC<OfferProps> = (props) => {
    const { children, query_id, offer, saveCheckoutPaymentInfo, isCounter, onClick, time } = props;
    const { queryParams } = useAppRouter();
    const { product_id, product_type } = useParams<ParamsType>();

    const algoliaConversionHandler = () => {
        if (query_id || queryParams?.query_id) {
            const objectIDs = product_id ? product_id : offer?.master_product_id || '';

            AlgoliaEvents.sendEvent({
                eventType: 'conversion',
                eventName: 'Product Purchased',
                objectIDs: [objectIDs.toString()],
                queryID: String(query_id || queryParams?.query_id),
            });
        }
    };

    const handleClick = () => {
        if (onClick) {
            onClick();
        }

        if (isQrTrigger) {
            triggerQrModal({
                name: 'product-detail-page',
                params: {
                    product_id: product_id || offer?.product_id,
                    product_type,
                },
            });

            return;
        }

        if (isCounter) {
            Dialog.openDialog({
                key: 'productAmount',
                config: {
                    bottomSheet: true,
                },
                element: (
                    <ProductCounterModal
                        offer={offer}
                        paymentTime={time}
                        saveCheckoutPaymentInfo={saveCheckoutPaymentInfo}
                        algoliaConversionHandler={algoliaConversionHandler}
                    />
                ),
            });

            return;
        }

        algoliaConversionHandler();
        saveCheckoutPaymentInfo && saveCheckoutPaymentInfo(offer, 1);
    };

    return <div onClick={handleClick}>{children}</div>;
};

export const BUTTON_STYLE = {
    borderRadius: {
        default: '999rem',
        md: '26px',
    },
    fontSize: {
        default: '12px',
        md: '14px',
    },
    width: {
        default: '90px',
        md: '100px',
    },
    height: {
        default: '32px',
        md: '36px',
    },
    style: {
        fontWeight: 600,
        fontFamily: 'JB Sans',
    },
};
