import slice from 'modules/ReviewService/ReviewService.slice';

export const {
    SetReviewModalState,
    SubmitReview,
    //REVIEWS
    getProductReviews,
    getProductReviewsSucceed,
    getProductReviewsFailed,
    clearProductReviewInfo,
} = slice.actions;
