// @ts-ignore
import Cookies from 'js-cookie';
import dayjs from 'dayjs';
import { DataManager, DataManagerPayload, UserInfoData } from 'data/types';
import { AuthUserData } from 'modules/Authorization/types';
import { AppSelectedCity } from 'modules/Cities/types';
import { AppI18n } from 'modules/App/types';
// ============================================================================================================================
// --------------------------------------------- USER DATA MODE -----------------------------------------------------
export const USER_DATA_MODE = __IS_PROD__ ? 'PROD' : 'TEST';
export const DataEnvKey = (KEY: string) => `${USER_DATA_MODE}-${KEY}`;
// --------------------------------------------- USER DATA MANAGER: keys -----------------------------------------------------
const AUTH_KEY = 'APP-USER-AUTH-DATA-v1';
const CART_KEY = 'APP-LOCAL-CART';
const CART_BY_ONE_KEY = 'APP-LOCAL-CART-BY-ONE';
const CHECKOUT_KEY = 'APP-USER-CHECKOUT-DATA-new';
const APP_USER_FLOW = 'APP-USER-FLOW-DATA';
const USER_INFORMATION_KEY = 'APP-USER-INFORMATION';
const PROMO_CODES_KEY = 'APP-USER-PROMOCODE';
const PRE_SELECTED_TERM = 'APP-USER-PRE_SELECTED_TERM';
const ONBOARDING_FMCG_KEY = 'APP-ONBOARDING-FMCG';
const FORGOTTEN_MODAL_SHOWN = 'APP-FORGOTTEN-MODAL-SHOWN';
const APP_AGE_VERIFY = 'APP-AGE-VERIFY-V2';
export const USER_AVATAR_ICON = 'APP-USER-AVATAR-ICONG';
/* ----------------- Дефолт значение ключа для промо поиска ----------------- */

const FINGERPRINT = 'fingerprint';
const PAYMENT_ID = 'PAYMENT_ID';
export const SELECTED_CITY = 'APP-SELECTED-CITY-V3';
export const HYBRID_CONFIG_KEY = 'APP-HYBRID-CONFIG-V1-1';
export const SELECTED_LANGUAGE = 'APP-SELECTED-LANGUAGE';
export const APP_USER_SEARCH_HISTORY = 'APP-USER-SEARCH-HISTORY-DATA';

// ============================================================================================================================
// --------------------------------------------- USER DATA MANAGER: utils -----------------------------------------------------
// TODO: Deprecated
const prepareCookies = (newOptions: any) => ({
    path: '/',
    expires: 1,
    ...newOptions,
});
const clientOnlyError = () => {
    __BROWSER__ && console.warn('APP: Use UserData in CLIENT side only!');
};
// --------------------------------------------- USER DATA MANAGER: setter ----------------------------------------------------
const set: DataManager<DataManagerPayload> = (payload) => {
    const {
        key,
        data = {},
        cookieOptions = {
            cookieEnabled: false,
            customCookieOptions: {},
        },
        use_env_key = true,
        use_json_parse = true,
    } = payload;
    const DATA_KEY = use_env_key ? DataEnvKey(key) : key;
    if (__BROWSER__) {
        const preparedData = use_json_parse ? JSON.stringify(data) : data;
        localStorage.setItem(DATA_KEY, preparedData);

        if (cookieOptions !== undefined) {
            if (cookieOptions?.cookieEnabled) {
                Cookies.set(DATA_KEY, preparedData, {
                    ...prepareCookies(cookieOptions?.customCookieOptions),
                });
            }
        }
    } else {
        clientOnlyError();
    }
};
// --------------------------------------------- USER DATA MANAGER: getter ----------------------------------------------------
const get: DataManager<DataManagerPayload> = (payload) => {
    const {
        key,
        cookieOptions = {
            cookieEnabled: false,
            customCookieOptions: {},
        },
        use_env_key = true,
        use_json_parse = true,
    } = payload;
    let preparedData = {};

    const DATA_KEY = use_env_key ? DataEnvKey(key) : key;
    if (__BROWSER__) {
        preparedData = use_json_parse
            ? {
                  ...JSON.parse(localStorage.getItem(DATA_KEY) || '{}'),
              }
            : localStorage.getItem(DATA_KEY);

        if (cookieOptions !== undefined) {
            if (cookieOptions?.cookieEnabled) {
                preparedData = use_json_parse
                    ? {
                          ...JSON.parse(Cookies.get(DATA_KEY) || '{}'),
                      }
                    : Cookies.get(DATA_KEY);
            }
        }
    } else {
        clientOnlyError();
    }
    return preparedData && Object.entries(preparedData).length > 0 ? preparedData : null;
};
// --------------------------------------------- USER DATA MANAGER: getter ----------------------------------------------------
const remove: DataManager<DataManagerPayload> = (payload) => {
    const { key, use_env_key } = payload;
    const DATA_KEY = use_env_key ? DataEnvKey(key) : key;
    if (__BROWSER__) {
        localStorage.removeItem(DATA_KEY);
        Cookies.remove(DATA_KEY);
    } else {
        clientOnlyError();
    }
};
export const UserData = {
    get,
    set,
    remove,
};

// ============================================================================================================================
// ============================================================================================================================
// --------------------------------------------- USER DATA MANAGER: Checkout ---------------------------------------------
// ============================================================================================================================
// ============================================================================================================================

export const savePaymentId = (id: any) => {
    localStorage.setItem(PAYMENT_ID, id);
};
export const getPaymentId = () => {
    return localStorage.getItem(PAYMENT_ID);
};

export const getShippingInterval = (): any => {
    const checkout: any = UserData.get({ key: CHECKOUT_KEY }) || {};
    if (checkout.shipping_interval) {
        return checkout.shipping_interval;
    }
    return [];
};

export const deleteShippingIntervalAll = (): any => {
    // UserData.set(CHECKOUT_KEY, {});
    const checkout: any = UserData.get({ key: CHECKOUT_KEY }) || {};
    if ('shipping_interval' in checkout) {
        delete checkout.shipping_interval;
    }
    UserData.set({ key: CHECKOUT_KEY, data: checkout });
};

export const saveCheckoutProcessType = (data: string): void => {
    const checkout: any = UserData.get({ key: CHECKOUT_KEY }) || {};
    checkout.process_type = data;
    UserData.set({ key: CHECKOUT_KEY, data: checkout });
};

export const getCheckoutProcessType = (): any => {
    const checkout: any = UserData.get({ key: CHECKOUT_KEY }) || {};
    if (checkout.process_type) {
        return checkout.process_type;
    }
    return '';
};

export const flushShippingIntervals = (): void => {
    const checkout: any = UserData.get({ key: CHECKOUT_KEY }) || {};
    const checkout_data = { ...checkout };
    if (
        checkout_data &&
        checkout_data.shipping_interval &&
        checkout_data.shipping_interval.length > 0
    ) {
        checkout_data.shipping_interval = [];
    }
    UserData.set({ key: CHECKOUT_KEY, data: { ...checkout_data } });
};

// ============================================================================================================================
// ============================================================================================================================
// --------------------------------------------- USER DATA MANAGER: Cart ---------------------------------------------
// ============================================================================================================================
// ============================================================================================================================
export const addItemToCart = (data: any): void => {
    const { product_id, amount } = data;
    const cart: any = UserData.get({ key: CART_KEY }) || {};

    if (data.product_id in cart) {
        cart[product_id].amount += amount;
    } else {
        cart[product_id] = data;
    }
    UserData.set({ key: CART_KEY, data: cart });
    return;
};

export const getCart = (): any => {
    return UserData.get({ key: CART_KEY });
};

export const deleteItemFromCart = (data: any) => {
    const { product_id, amount } = data;
    const cart: any = UserData.get({ key: CART_KEY }) || {};

    if (amount > 0) {
        cart[product_id] = data;
    } else if (amount === 0) {
        delete cart[product_id];
    }
    UserData.set({ key: CART_KEY, data: cart });
};

export const deleteCartAll = () => {
    UserData.set({ key: CART_KEY, data: {} });
};
// ============================================================================================================================
// ============================================================================================================================
// --------------------------------------------- USER DATA MANAGER: CartByOne ---------------------------------------------
// ============================================================================================================================
// ============================================================================================================================
export const addItemToCartByOneLocal = (data: any): void => {
    const { product_id } = data;
    const cart: any = UserData.get({ key: CART_BY_ONE_KEY }) || {};
    if (typeof data === 'object' && Object.keys(data).length === 0) {
        UserData.set({ key: CART_BY_ONE_KEY, data: {} });
    } else {
        const product: any = {};
        product[`${product_id}`] = data;
        cart.product = product;
        UserData.set({ key: CART_BY_ONE_KEY, data: cart });
    }
};

export const getCartLocalByOne = (): any => {
    const cart_by_one: any = UserData.get({ key: CART_BY_ONE_KEY });
    return cart_by_one && cart_by_one.product;
};

// ============================================================================================================================
// ============================================================================================================================
// --------------------------------------------- USER DATA MANAGER: Authorization ---------------------------------------------
// ============================================================================================================================
// ============================================================================================================================
export const saveUserAuthData = (data: AuthUserData): void => {
    const expires = data.ttl ? data.ttl / (60 * 60 * 24) : 3;
    UserData.set({
        key: AUTH_KEY,
        data,
        cookieOptions: {
            customCookieOptions: {
                expires,
            },
        },
    });
    return;
};

export const getUserAuthData = (): any => {
    const userData = UserData.get({ key: AUTH_KEY });
    // @ts-ignore
    const token = userData && userData.token;
    if (token) {
        return UserData.get({ key: AUTH_KEY });
    }
    return null;
};

export const flushUserAuthData = () => {
    return UserData.remove({ key: AUTH_KEY, use_env_key: true });
};

// ============================================================================================================================
// ============================================================================================================================
// --------------------------------------------- USER DATA MANAGER: User Information ----------------------------------------
// ============================================================================================================================
// ============================================================================================================================

export const saveUserAgeVerified = (isVerified: string) => {
    const userData: any = UserData.get({ key: USER_INFORMATION_KEY }) || {};
    userData.isVerified = isVerified;
    UserData.set({
        key: USER_INFORMATION_KEY,
        data: userData,
        cookieOptions: {
            cookieEnabled: true,
            customCookieOptions: {
                expires: 365,
            },
        },
    });
};

export const getUserInformation = (): UserInfoData | any => {
    return UserData.get({ key: USER_INFORMATION_KEY }) || {};
};
export const isUserVerified = (): any => {
    const DATA_KEY = `${USER_DATA_MODE}-${USER_INFORMATION_KEY}`;
    if (
        __BROWSER__ &&
        (Cookies.get(DATA_KEY) === undefined || localStorage.getItem(DATA_KEY) === undefined)
    ) {
        Cookies.remove(DATA_KEY);
        localStorage.removeItem(DATA_KEY);
    }
    return UserData.get({ key: USER_INFORMATION_KEY })?.isVerified || null;
};

// ============================================================================================================================
// ============================================================================================================================
// --------------------------------------------- USER DATA MANAGER: Selected City ----------------------------------------
// ============================================================================================================================
// ============================================================================================================================

export const saveSelectedCityStorage = (newData: AppSelectedCity) => {
    const cityData: any = getSelectedCityStorage() || {};
    const data = { ...cityData, ...newData };
    UserData.set({
        key: SELECTED_CITY,
        data,
        cookieOptions: {
            cookieEnabled: true,
            customCookieOptions: {
                expires: 365,
            },
        },
    });
};

export const getSelectedCityStorage = () => {
    return (
        UserData.get({
            key: SELECTED_CITY,
            cookieOptions: {
                cookieEnabled: true,
            },
        }) || null
    );
    // return 'Алматы';
};

// ============================================================================================================================
// ============================================================================================================================
// --------------------------------------------- USER DATA MANAGER: Hybrid Config ----------------------------------------
// ============================================================================================================================
// ============================================================================================================================

export const saveHybridConfig = (key: string, newData: HYBRID_CONFIG) => {
    // const hybridConfig: any = UserData.get(HYBRID_CONFIG) || {};
    UserData.set({
        key: key,
        use_env_key: false,
        data: newData,
        cookieOptions: {
            cookieEnabled: false,
            customCookieOptions: {
                expires: 30,
            },
        },
        use_json_parse: false,
    });
};

export const getHybridConfig = (key: string) => {
    return UserData.get({ key, use_env_key: false, use_json_parse: false }) || null;
};

// ============================================================================================================================
// ============================================================================================================================
// --------------------------------------------- USER DATA MANAGER: PROMO CODES -----------------------------------------------
// ============================================================================================================================
// ============================================================================================================================

export const getCartPromocode = () => {
    const promocodes: any = UserData.get({ key: PROMO_CODES_KEY }) || {};
    if (Object.keys(promocodes).length) {
        if ('unknown' in promocodes) {
            return promocodes.unknown.name;
        }
        if ('valid' in promocodes) {
            return promocodes.valid.name;
        }
    }
    return '';
};

export const savePromocode = (status: string, data: any) => {
    const promocodes: any = UserData.get({ key: PROMO_CODES_KEY }) || {};
    promocodes[status] = data;
    UserData.set({ key: PROMO_CODES_KEY, data: promocodes });
};

export const deleteInvalidPromocode = () => {
    const promocodes: any = UserData.get({ key: PROMO_CODES_KEY }) || {};
    if ('unknown' in promocodes) {
        delete promocodes.unknown;
    }
    UserData.set({ key: PROMO_CODES_KEY, data: promocodes });
};

export const deleteValidPromocode = () => {
    const promocodes: any = UserData.get({ key: PROMO_CODES_KEY }) || {};
    if ('valid' in promocodes) {
        delete promocodes.valid;
    }
    UserData.set({ key: PROMO_CODES_KEY, data: promocodes });
};

export const getValidPromocode = () => {
    const promocodes: any = UserData.get({ key: PROMO_CODES_KEY }) || {};
    if ('valid' in promocodes) {
        return [promocodes.valid.name];
    }
    return [];
};

// ============================================================================================================================
// ============================================================================================================================
// --------------------------------------------- USER DATA MANAGER: UseUserFlow ---------------------------------------------
// ============================================================================================================================
// ============================================================================================================================

export const AppModulesStorage = {
    keyResolver: (module_name: string) => `${APP_USER_FLOW}-M-${module_name}`,
    get: (module_name: string) => {
        return UserData.get({ key: AppModulesStorage.keyResolver(module_name) });
    },
    set: (module_name: string, data: any) => {
        const newBackup = Object.keys(data || {}).length > 0 ? { ...data } : {};
        UserData.set({ key: AppModulesStorage.keyResolver(module_name), data: newBackup });
    },
};
// ============================================================================================================================
// ============================================================================================================================
// --------------------------------------------- USER DATA MANAGER: SELECTED ADDRESS ------------------------------------------
// ============================================================================================================================
// ============================================================================================================================

export const getSelectedAddressId = () => {
    const userData: any = UserData.get({ key: USER_INFORMATION_KEY }) || {};
    return userData?.selected_address_id || null;
};

// ============================================================================================================================
// ============================================================================================================================
// --------------------------------------------- USER DATA MANAGER: LOCALIZATION     ------------------------------------------
// ============================================================================================================================
// ============================================================================================================================

export const saveSelectedLanguageStorage = (language: AppI18n['activeLanguage']) => {
    UserData.set({
        key: SELECTED_LANGUAGE,
        data: { language },
        cookieOptions: {
            cookieEnabled: true,
            customCookieOptions: {
                expires: 365,
            },
        },
    });
};

export const getSelectedLanguageStorage = () => {
    return UserData.get({ key: SELECTED_LANGUAGE })?.language || 'ru';
};

export const getUserSearchHistory = () => {
    return JSON.parse(UserData.get({ key: APP_USER_SEARCH_HISTORY, use_json_parse: false })) || [];
};

export const setUserSearchHistory = (search: { link: string; title: string }) => {
    const old_history: any = getUserSearchHistory();

    const unique = old_history
        .filter((f: any) => String(f.title) !== String(search.title))
        .slice(0, 9);

    const data = [search, ...unique];

    UserData.set({
        key: APP_USER_SEARCH_HISTORY,
        data,
    });
};

export const flushUserSearchHistory = () => {
    UserData.set({
        key: APP_USER_SEARCH_HISTORY,
        data: [],
        use_json_parse: false,
    });
};

// ============================================================================================================================
// ============================================================================================================================
// --------------------------------------------- USER DATA MANAGER: Fingerprint ----------------------------------------
// ============================================================================================================================
// ============================================================================================================================

export const saveFingerprint = (data: string) => {
    UserData.set({
        key: FINGERPRINT,
        data,
        cookieOptions: {
            cookieEnabled: true,
            customCookieOptions: {
                expires: 365,
            },
        },
    });
};

export const getFingerprint = () =>
    JSON.parse(UserData.get({ key: FINGERPRINT, use_json_parse: false })) || '';

// ============================================================================================================================
// ============================================================================================================================
// --------------------------------------------- USER DATA MANAGER: Product Checkout's Preselected Term ----------------------------------------
// ============================================================================================================================
// ============================================================================================================================

interface IPreselectedTerm {
    [offer_id: string]: string;
}

export const saveProductPaymentPreselectTerm = (newData: IPreselectedTerm) => {
    const isInvalid = Object.values(newData)[0] === '0';
    if (isInvalid) {
        return;
    }
    const preselected_terms_storage: IPreselectedTerm = getProductPaymentPreselectTerm() || {};
    const data = { ...preselected_terms_storage, ...newData };
    UserData.set({
        key: PRE_SELECTED_TERM,
        data,
    });
};

export const getProductPaymentPreselectTerm = (): IPreselectedTerm | null => {
    return (
        UserData.get({
            key: PRE_SELECTED_TERM,
        }) || null
    );
    // return 'Алматы';
};

export const getUserToken = () => {
    const userToken: string = UserData.get({ key: 'USER_TOKEN', use_json_parse: false }) || '';
    if (!userToken || !userToken.includes('empty')) {
        const rnd = Math.floor(Math.random() * 10001);
        const rnd2 = Math.floor(Math.random() * 100001);
        const data = `empty${rnd}${Date.now()}${rnd2}`;
        UserData.set({ key: 'USER_TOKEN', data, use_json_parse: false });
        return data;
    }
    return userToken;
};

export const saveSearchPromo = (key: string, data: number) => UserData.set({ key, data });

export const chatGTPView = (str = '') => {
    const options = {
        key: 'chat-gpt-animation-done',
        use_env_key: false,
        use_json_parse: false,
    };

    if (str === 'done') {
        UserData.set({ ...options, data: 'done' });
    } else {
        return UserData.get(options) === 'done';
    }
};

export const getSearchPromo = (key: string) => {
    return Number(UserData.get({ key, use_json_parse: false }));
};

export const removeSearchPromo = (key: string) => UserData.remove({ key });

// ============================================================================================================================
// ============================================================================================================================
// ----------------------------------------- USER DATA MANAGER: ONBOARDING FMCG     -------------------------------------------
// ============================================================================================================================
// ============================================================================================================================
export const setOnboardingFMCG = () => UserData.set({ key: ONBOARDING_FMCG_KEY, data: true });

export const getOnboardingFMCG = () =>
    UserData.get({ key: ONBOARDING_FMCG_KEY, use_json_parse: false });

export const saveUserAvatar = (data: string) =>
    UserData.set({
        key: USER_AVATAR_ICON,
        data,
    });

export const getUserAvatar = (key: string) => {
    return JSON.parse(UserData.get({ key, use_json_parse: false }));
};

export const removeUserAvatar = (key: string) => UserData.remove({ key });

export const setForgottenModalShown = () =>
    UserData.set({ key: FORGOTTEN_MODAL_SHOWN, data: true });
export const getForgottenModalShown = () =>
    UserData.get({ key: FORGOTTEN_MODAL_SHOWN, use_json_parse: false });
export const removeForgottenModalShown = () =>
    UserData.remove({ key: FORGOTTEN_MODAL_SHOWN, use_env_key: true });

// ============================================================================================================================
// ============================================================================================================================
// --------------------------------------------- USER DATA MANAGER: AGE VERIFY ----------------------------------------
// ============================================================================================================================
// ============================================================================================================================

export const setAgeVerify = ({ age = 18, date }: { age: number; date: string }) => {
    UserData.set({
        key: APP_AGE_VERIFY,
        data: {
            age,
            date: date || dayjs().format('YYYY-MM-DD'),
        },
    });
};

export const getAgeVerify = () =>
    UserData.get({ key: APP_AGE_VERIFY }) || { age: 0, date: dayjs().format('YYYY-MM-DD') };
