import slice from 'modules/ProductsList/ProductsList.slice';

export const {
    ProductsListInit,
    ProductsListSucceed,
    ProductsListFailed,
    ProductsListSeoInit,
    ProductsListSeoSucceed,
    ProductsListSeoFailed,
    ProductsListFlush,
    ProductsListFiltersUpdate,
    ProductsListFiltersFlush,
    flushParentCategory,
    ProductsListFiltersFlushSucceed,
} = slice.actions;
