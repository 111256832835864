import * as queryString from 'querystring';
import { FetchPageData } from 'router/types';

import { SearchServiceInit } from 'modules/SearchService/SearchService.actions';

export const AlgoliaFetch: FetchPageData = ({ dispatch, payload = {} }) => {
    // Fetch products list
    if (payload?.query) {
        const { isFirstRun = true, category_id, inputQuery } = payload.params;
        // const params = prepareFilters(payload.query);
        dispatch(
            SearchServiceInit({
                isFirstRun,
                params: payload.query,
                persistProducts: payload?.params?.persistProducts || false,
                url: payload?.params?.url || '?' + queryString.stringify(payload.query),
                inputQuery,
                category_id,
            })
        );
    }
};
