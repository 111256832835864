import React from 'react';
import { usePyatka } from 'pyatka-ui';
import { useAppRouter } from 'router/context';
import { setUserSearchHistory } from 'data';
import { SuggestionsAndHistory } from './components';

import SearchBarLayout from './SearchBar.Layout';
import { useSupermarket } from './hooks';
import { isSuperSearch } from './utils';
import { SearchInputLayoutProps } from './types';

const SearchBarInputLayout: React.FC<SearchInputLayoutProps> = ({
    // common props
    search_value = '',
    placeholder,
    mobileBg,
    // other
    autoFocus,
    setSearchValue,
    supermarket,
    store_id,
}) => {
    const { scales } = usePyatka();
    const [input_focus, setInputFocus] = React.useState<boolean>(false);

    const { generateRouteTO, queryParams = {}, location, appRedirect } = useAppRouter();
    const { category_id, query } = queryParams;

    const onKeywordSearch = () => {
        const searchQuery = search_value.trim();

        // Редирект со страницы поиска, если строка поиска пустая
        if (!searchQuery && query) {
            redirectToHome();
            return;
        }

        if (searchQuery) {
            // Дебаг страница
            if (searchQuery === 'incodewetrust') {
                appRedirect({ name: 'debug-page' });
                return;
            }

            // Поиск по всем супермаркетам
            if (isSuperSearch(location.pathname)) {
                appRedirect({
                    name: 'supermarkets-search-page',
                    query: { query: searchQuery },
                });
                return;
            }

            // Глобальный поиск
            const prevParams = store_id ? { company_id: store_id } : {};
            const link = generateRouteTO({
                name: 'search-page',
                query: { ...prevParams, query: searchQuery },
                exact: true,
            });

            // Записывать в историю поиску только если не поиск по супермаркетам
            if (!store_id) {
                setUserSearchHistory({
                    title: String(searchQuery),
                    link: String(encodeURI(link)),
                });
            }

            appRedirect(link);
            return;
        }
    };

    const redirectToHome = () => {
        // на страницу супермаркета
        if (store_id) {
            appRedirect({ name: 'store-detail', params: { store_id } });
            return;
        }
        // на страницу категории
        if (category_id) {
            appRedirect({
                name: 'home-page',
                query: { category_id },
                isReplace: true,
                exact: true,
            });
            return;
        }
        // на главную
        appRedirect({ name: 'index-page' });
    };

    const clearSearch = React.useCallback(() => setSearchValue(''), [setSearchValue]);

    const handleInputChange = React.useCallback(
        (value: string) => {
            if (value.length < 255) {
                setSearchValue(value);
            }
        },
        [setSearchValue]
    );

    return (
        <>
            <SearchBarLayout
                // common
                search_value={search_value}
                placeholder={placeholder}
                mobileBg={mobileBg}
                // other
                autoFocus={autoFocus}
                clearSearch={clearSearch}
                onChange={handleInputChange}
                setInputFocus={setInputFocus}
                onKeywordSearch={onKeywordSearch}
                supermarket={supermarket}
                store_id={store_id}
            />

            {input_focus && !scales?.isMobile ? <SuggestionsAndHistory isDesktop /> : null}
        </>
    );
};

export default React.memo(SearchBarInputLayout);
