export const colors = {
    status: {
        error: '#FF0000',
        warning: '#FFD65F',
        success: '#9BD050',
        info: '#357edd',
    },
    text: {
        main: '#201F1F',
        secondary: '#898D94',
        brand: '#FF4810',
        placeholder: '#AAADB2',
        disabled: '#CDCFD1',
        inverse: '#FFFFFF',
        brand_variant: '#FF7348',
    },
    block: {
        main: '#FFFFFF',
        inverse: '#000000',
        secondary: '#F6F6F5',
        placeholder: '#AAADB2',
        brand: '#FF4810',
        brand_variant: '#FF7348',
        secondary_variant: '#E5E5E2',
        disabled: '#BEBEB666',
    },
};
