import React, { useEffect, useRef, useState } from 'react';
import ReactModal from 'react-modal';
import { Box, usePyatka } from 'pyatka-ui';
import styles from 'components/Modal/Modal.module.scss';
import { ModalLayout } from './Modal.Layout';

export function ModalContainer(props) {
    const {
        open,
        children,
        config = {},
        onClose,
        title = '',
        backButtonInitial = 0,
        swipeable = true,
        bgColor,
    } = props;

    const {
        closeButton = true,
        bottomSheet = false,
        maxHeight,
        maxWidth = 'initial',
        noPadding = false,
        overlayHeader = false,
        showMobileTitle = true,
    } = config;

    const { useSwipeable, scales } = usePyatka();
    const isMobile = (scales.matched || []).find((el: any) => el.name === 'md');
    /* ----------------------- Initial position for scroll ---------------------- */
    const [scrollY, setScrollY] = useState(0);

    /* --------------------------- Some old state shit -------------------------- */
    const [closeBtn, setCloseBtn] = React.useState(closeButton);
    const [modalTitle, setModalTitle] = React.useState(title);

    const scrollRef = useRef(null);
    const contentRef = useRef(null);

    const handlers = useSwipeable({
        delta: 0, // min distance(px) before a swipe starts. *See Notes*
        trackTouch: true, // track touch input
        trackMouse: true, // track mouse input
        rotationAngle: 0, // set a rotation angle

        onSwiped: () => {
            /* -------------------- Return if contentRef doesnt exist ------------------- */
            if (!contentRef.current) {
                return;
            }

            /* ----------------------- Reset styles for contentRef ---------------------- */
            contentRef.current.style.transition = '';
            contentRef.current.style.transform = '';
        },

        onSwipedDown: (eventData) => {
            /* ----------------------- If swipe delta < 100 return ---------------------- */
            if (eventData.deltaY - scrollY < 100) {
                return;
            }
            /* ----------------------------- Dont know this ----------------------------- */
            if (eventData.initial[1] === 0) {
                return;
            }
            /* ---------------------- Return if scroll doesnt exist --------------------- */
            if (!scrollRef.current) {
                return;
            }
            /* ----------------------- Close modal ifscrollTop = 0 ---------------------- */
            if (scrollRef.current.scrollTop < 1) {
                onRequestClose();
            }
        },

        onSwipeStart: () => {
            /* ----------------------- Set initial swipe position ----------------------- */
            setScrollY(scrollRef.current.scrollTop);
            /* -------------------- Return if contentRef doesnt exist ------------------- */
            if (!contentRef.current) {
                return;
            }
            /* ---------------------- Reset Transition for Content ---------------------- */
            contentRef.current.style.transition = 'none';
        },

        onSwiping: (eventData) => {
            if (eventData.initial[1] === 0) {
                return;
            }
            /* -------------------- Return if contentRef doesnt exist ------------------- */
            if (!scrollRef.current) {
                return;
            }
            /* ------------ Return if dir != 'down' || scrollRef doesnt exist ----------- */
            if (eventData.dir !== 'Down' || scrollRef.current.scrollTop > 0) {
                return;
            }
            /* ------------------------- Modal translateY styles ------------------------ */
            contentRef.current.style.transform = `translateY(${eventData.deltaY - scrollY}px)`;
        },
    });

    /* --------------------------- Ref for contentRef --------------------------- */
    const refPassthrough = (el) => {
        if (swipeable && scales?.isMobile) {
            // call useSwipeable ref prop with el
            handlers.ref(el);
            // set myRef el so you can access it yourself
            contentRef.current = el;
        }
    };

    /* ------------------------------ Some old shit ----------------------------- */
    const [backButtonTrigger, setBackButtonTrigger] = useState(backButtonInitial);
    useEffect(() => {
        setBackButtonTrigger(backButtonInitial);
    }, [backButtonInitial]);
    const closeButtonHandler = () => onRequestClose();

    const toggleBackButton = (val: number) => setBackButtonTrigger(val);

    const toggleCloseBtn = (bool: boolean) => setCloseBtn(bool);
    useEffect(() => {
        setCloseBtn(closeButton);
    }, [closeBtn, closeButton]);
    useEffect(() => {
        if (title !== modalTitle) {
            setModalTitle(title);
        }
    }, [title]);

    const changeTitle = (title: string) => setModalTitle(title);

    const onRequestClose = () => onClose && onClose();

    const layoutProps = {
        toggleCloseBtn,
        changeTitle,
        toggleBackButton,
        backButtonTrigger,
        modalTitle,
        closeButtonHandler,
        scrollRef,
        closeBtn,
        overlayHeader,
        bottomSheet,
        maxHeight,
        noPadding,
        children,
        isMobile,
        showMobileTitle,
    };

    return (
        <ReactModal
            className={{
                base: `${styles.baseContent}  ${config?.full_height ? styles.full_height : ''} ${
                    noPadding ? styles.noPadding : ''
                }`,
                afterOpen: styles.baseContentAfterOpen,
                beforeClose: styles.baseContentBeforeClose,
            }}
            isOpen={open}
            onRequestClose={onRequestClose}
            shouldCloseOnOverlayClick={true}
            preventScroll={true}
            closeTimeoutMS={500}
            overlayClassName={styles.overlay}
            contentElement={(props, children) => (
                <Box
                    bg={bgColor}
                    {...props}
                    ref={refPassthrough}
                    style={{
                        // background: bgColor, // пяточным компонентам лучше задавать цвета через пропсы
                        touchAction: 'pan-y',
                        minWidth: isMobile ? '100vw' : '580px',
                        width: isMobile ? '100%' : 'initial',
                        maxWidth: isMobile ? 'initial' : maxWidth,
                    }}
                >
                    {children}
                </Box>
            )}
        >
            <ModalLayout {...layoutProps}>{children}</ModalLayout>
        </ReactModal>
    );
}

/* --------------------------------- Язычок --------------------------------- */

// {
//     scales?.isMobile && closeBtn ? (
//         <>
//             <Space height={'10px'} />
//             <div
//                 style={{
//                     width: '100%',
//                     textAlign: 'center',
//                 }}
//             >
//                 <Icon icon={'drag'} width={'40px'} height={'5px'} onClick={closeButtonHandler} />
//             </div>
//         </>
//     ) : null;
// }
