import { ApiGwRequest } from 'services';
import { AUTH_BASIC_REQ_INDICATOR } from 'keys';
import { IAuthEmailPasswordPayload, IAuthOtpPayload } from 'modules/Authorization/v2/types';

export const authWithEmailPasswordService = (payload: IAuthEmailPasswordPayload) => {
    return ApiGwRequest({
        method: 'post',
        url: 'user/v1/auth/sign-in',
        data: payload,
    });
};
export const authInitiateOtpService = (payload: IAuthOtpPayload) => {
    return ApiGwRequest({
        method: 'post',
        url: 'user/v1/auth/sign-in-by-otp',
        indicator: AUTH_BASIC_REQ_INDICATOR,
        data: payload,
    });
};
export const authVerifyOtpService = (payload: IAuthOtpPayload) => {
    return ApiGwRequest({
        method: 'post',
        url: 'user/v1/auth/sign-in-by-otp-verify',
        // indicator: AUTH_BASIC_REQ_INDICATOR,
        data: payload,
    });
};
export const checkAuthToken = (token: string) => {
    return ApiGwRequest({
        method: 'get',
        url: 'auth-check/token',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};
