import slice from 'modules/Authorization/v2/Authorization.slice';

export const {
    AuthorizationInit,
    AuthorizationDataSave,
    AuthorizationOtpSucceed,
    AuthorizationOtpFailed,
    AuthorizationFailed,
    AuthorizationDataFlush,
    OtpClear,
    AuthorizationVerifyOtp,
    AuthorizationLogout,
    AuthorizationDataFetch,
} = slice.actions;
