import React, { useCallback, useEffect, useState } from 'react';
import QRCode from 'qrcode';
import { useAppRouter } from 'router/context';
import { QrRedirectLayout } from './QrRedirect.Layout';
import { QrRedirectLayoutNew } from './QrRedirect.LayoutNew';

export const QrRedirectContainer: React.FC<{
    path: string;
    useAppRouterContext?: boolean;
    title?: string;
    layout?: 'new' | 'old';
}> = ({ path, useAppRouterContext = true, title = '', layout = 'new' }) => {
    const context = useAppRouter();
    const { generateRouteTO } = context;

    const generateLink = useCallback(
        (to: string) => {
            try {
                if (typeof to === 'string') {
                    return to;
                }
                if (typeof to === 'object') {
                    return generateRouteTO(to);
                }
            } catch (e) {
                console.log(e);
                return '/';
            }
        },
        [generateRouteTO]
    );

    const [qr, setQr] = useState('');

    const generateQr = React.useCallback(() => {
        let link = '';
        if (useAppRouterContext) {
            const dest = generateLink(path);
            link = `https://jmart.kz${dest}`;
        } else {
            link = path;
        }
        QRCode.toDataURL(link, (_, url) => setQr(url));
    }, [generateLink, path, useAppRouterContext]);

    useEffect(() => {
        generateQr();
    }, [generateQr]);

    return (
        <React.Fragment>
            {layout === 'new' ? (
                <QrRedirectLayoutNew qr={qr} title={title} />
            ) : (
                <QrRedirectLayout qr={qr} title={title} />
            )}
        </React.Fragment>
    );
};
