import React from 'react';
import { DeliveryPriceLayout } from './DeliveryPrice.Layout';
import { DeliveryPriceProps } from './types';

export const DeliveryPriceContainer: React.FC<DeliveryPriceProps> & {
    Layout: typeof DeliveryPriceLayout;
} = (props) => {
    const { children, ...otherProps } = props;
    return (
        <React.Fragment>
            <DeliveryPriceLayout {...otherProps}>{children}</DeliveryPriceLayout>
        </React.Fragment>
    );
};

DeliveryPriceContainer.Layout = DeliveryPriceLayout;
