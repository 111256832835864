import dayjs from 'dayjs';
import { CustomValidation, CustomValidators } from 'hooks/useForm/types';

const validateEmail: CustomValidation['isValid'] = (email: string) => {
    const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
};

const validatePhone: CustomValidation['isValid'] = (phone: string) => {
    const phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{5})$/;
    return !!phone.match(phoneno);
};

const validateBin: CustomValidation['isValid'] = (bin) => {
    return /^\d+$/.test(bin) && bin?.length === 12;
};

const checkIIN: CustomValidation['isValid'] = (iin: string) => {
    const currYear = new Date().getFullYear();
    const parsedDates = iin.slice(0, 6).match(/.{1,2}/g);
    if (parsedDates === null) {
        return false;
    }
    const [y, m, d] = parsedDates;
    const idx = Number(iin.slice(6, 7));

    if (idx <= 0 || idx > 6) {
        return false;
    }
    if (idx > 4 && 2000 + Number(y) > currYear) {
        return false;
    }

    if (Number(m) > 12 || Number(m) === 0) {
        return false;
    }

    return !(Number(d) > 31 || Number(d) === 0);
};

const isLegalLoanAge: CustomValidation['isValid'] = (iin: string) => {
    const birthday = iin.slice(0, 6);
    const formatBirthday = dayjs(birthday, 'YYMMDD');
    const years = dayjs().diff(formatBirthday, 'years');
    return years >= 18;
};

export const validates: CustomValidators = {
    onName: {
        msg: 'fill_in_field',
        isValid: (val: string) => (val ? val.length > 0 : false),
    },
    onPromo: {
        msg: 'fill_in_field',
        isValid: (_val: string) => false,
    },
    onEmail: {
        msg: 'invalid_email',
        isValid: (val: string) => validateEmail(val),
    },
    onIIN: {
        msg: 'invalid_iin',
        isValid: (iin: string) => {
            if (iin && !isNaN(parseInt(iin))) {
                if (iin.length === 12) {
                    return checkIIN(iin);
                }
            }
            return false;
        },
    },
    onLoanIIN: {
        msg: 'loan_is_available_only_to_persons_from_21_to_63_years_old',
        isValid: (iin: string) => (iin ? isLegalLoanAge(iin) : false),
    },
    onGender: {
        msg: 'choose_your_gender',
        isValid: (val: any) => val !== null,
    },
    onAddress: {
        msg: 'type_address',
        isValid: (val: string) => (val ? val.length > 0 : false),
    },
    onAlcho: {
        msg: 'enter_license',
        isValid: () => true,
    },
    onIIK: {
        msg: 'enter_iik',
        isValid: () => true,
    },
    onPassword: {
        msg: 'password_must_be_than_6_characters_long',
        isValid: (val) => val.length > 6,
    },
    onPhone: {
        msg: 'invalid_number',
        isValid: (phone) => {
            if (phone) {
                const cleanPhone = phone.replace(/[^0-9]/g, '');
                return validatePhone(cleanPhone);
            }
            return false;
        },
    },
    onNewPhone: {
        msg: 'enter_new_number',
        isValid: (_phone) => false,
    },
    onOtp: {
        msg: 'sms_code_must_be_4_digits',
        isValid: (val) => val.length === 4,
    },
    onCheckbox: {
        msg: 'field_is_required',
        isValid: (val) => Boolean(val),
    },
    onAgree: {
        msg: 'consent_to_process_your_personal_data_is_required',
        isValid: (val) => Boolean(val),
    },
    onReviewRate: {
        msg: 'necessary_to_specify_rating',
        isValid: (rate) => {
            return Boolean(rate);
        },
    },
    onBIN: {
        msg: 'bin_iin_12_required',
        isValid: (val) => {
            // console.log('onPassword', val);
            return validateBin(val);
        },
    },
};
