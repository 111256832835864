import React from 'react';
import { SwitchToggleLayout } from './SwitchToggle.Layout';
import { SwitchToggleProps } from './types';

export const SwitchToggleContainer: React.FC<SwitchToggleProps> & {
    Layout: typeof SwitchToggleLayout;
} = (props) => {
    return (
        <React.Fragment>
            <SwitchToggleLayout {...props} />
        </React.Fragment>
    );
};

SwitchToggleContainer.Layout = SwitchToggleLayout;
