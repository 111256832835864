import { ProductsListInitialState } from 'modules/ProductsList/types';

export const initialData = {
    data: {
        url: '',
        page: 1,
        filters: [],
        total_count: 0,
        products: [],
        items_per_page: 12,
        total_pages: 0,
        category_id: 0,
        parentCategory: null,
        categories: [],
    },
    seo: null,
    activeFilters: {},
    filtersObject: {},
    filters_order: [],
};

const state: ProductsListInitialState = initialData;

export default state;
