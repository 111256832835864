import React from 'react';
import { Box, Space } from 'pyatka-ui';
import { BoxProps } from 'pyatka-ui/dist/components-shared/Box/types';
import SuggestionsList from './SuggestionsList';
import UserSearchHistory from './UserSearchHistory';

interface Props {
    isDesktop?: boolean;
}

const SuggestionsAndHistory: React.FC<Props> = ({ isDesktop = false }) => {
    const desktopProps: Omit<BoxProps, 'color'> = {
        left: 0,
        right: 0,
        zIndex: 99999,
        bg: 'block.main',
        scrollView: '400px',
        borderRadius: '15px',
        position: 'absolute',
        // @ts-ignore
        boxShadow: '0px 6px 24px -12px rgba(0,0,0,0.2)',
    };

    return (
        <Box px={{ default: 1, md: 3 }} py={3} {...(isDesktop ? desktopProps : {})}>
            <SuggestionsList isMobile={!isDesktop} />
            <Space height="16px" />
            <UserSearchHistory />
        </Box>
    );
};

export default React.memo(SuggestionsAndHistory);
