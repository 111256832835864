import React from 'react';
import { Box, Display, Icon } from 'pyatka-ui';
import { ProductSliderProps } from './types';
import { converter, parseStyle } from './utils';

const DEFAULT_COUNT = 6;
const DEFAULT_GAP = '8px';
const DEFAULT_MIN_WIDTH = 150;
const DEFAULT_APPEARANCE = 'slide';

export const ProductSlider: React.FC<ProductSliderProps> = (props) => {
    const {
        children,
        gap = DEFAULT_GAP,
        count = DEFAULT_COUNT,
        minWidth = DEFAULT_MIN_WIDTH,
        appearance = DEFAULT_APPEARANCE,
        sliderStyle = {},
        wrapperStyle = {},
    } = props;

    const sliderRef = React.useRef<any>(null);

    const [isNotFit, setIsFit] = React.useState(true);

    const [navButtons, setNavButtons] = React.useState({ isPrev: false, isNext: true });

    const variant = React.useMemo(() => {
        if (__SERVER__) {
            return;
        }
        if (typeof window === undefined) {
            return {};
        }
        if (appearance === 'slide') {
            return {
                gridGap: converter<string>(gap),
                gridAutoColumns: parseStyle(window.innerWidth, appearance, gap, count, minWidth),
                gridAutoFlow: 'column',
            };
        }
        return {
            gridGap: converter<string>(gap),
            gridTemplateColumns: parseStyle(window.innerWidth, appearance, gap, count, minWidth),
            gridAutoRows: 'min-content',
        };
    }, [appearance, count, minWidth, gap]);

    const onTouchMove = (v: 'prev' | 'next') => {
        if (!sliderRef.current) {
            return;
        }

        const scrollWidth = sliderRef.current.scrollWidth;
        const clientWidth = sliderRef.current.clientWidth;
        let current = sliderRef.current.scrollLeft;

        if (v === 'next') {
            current += clientWidth;
            sliderRef.current.scrollLeft = current;
        } else {
            current -= clientWidth;
            sliderRef.current.scrollLeft = current;
        }
        setNavButtons({
            isPrev: current > 0,
            isNext: scrollWidth >= current + clientWidth,
        });
    };
    // --- if count = children
    React.useEffect(
        () => setIsFit(sliderRef?.current?.scrollWidth > sliderRef?.current?.clientWidth),
        []
    );

    return (
        <Box position="relative" {...(wrapperStyle as any)}>
            {isNotFit && navButtons.isPrev && (
                <Display lg={false} xxl={true}>
                    <NavButton v="prev" onTouchMove={onTouchMove} />
                </Display>
            )}

            <Box
                ref={sliderRef}
                display="grid"
                overflowX="auto"
                hideScrollBar
                {...variant}
                {...(sliderStyle as any)}
                style={{
                    scrollBehavior: 'smooth',
                    overflowY: 'hidden',
                }}
            >
                {children}
            </Box>

            {isNotFit && navButtons.isNext && (
                <Display lg={false} xxl={true}>
                    <NavButton v="next" onTouchMove={onTouchMove} />
                </Display>
            )}
        </Box>
    );
};

interface NavButtonProps {
    v?: 'prev' | 'next';
    onTouchMove: (v: 'prev' | 'next') => void;
}
const NavButton: React.FC<NavButtonProps> = (props) => {
    const { v = 'prev', onTouchMove } = props;

    return (
        <Box
            onClick={() => onTouchMove(v)}
            style={{
                padding: '16px',
                cursor: 'pointer',
                borderRadius: '50px',
                backgroundColor: 'white',

                alignItems: 'center',
                display: 'inline-flex',
                justifyContent: 'center',

                zIndex: 10,
                top: '50%',
                position: 'absolute',
                left: v === 'prev' ? '-9px' : 'auto',
                right: v === 'next' ? '-9px' : 'auto',
                transform: v === 'next' ? 'translateY(-50%) rotate(180deg)' : 'translateY(-50%)',
            }}
            border="5px solid"
            borderColor="block.secondary"
        >
            <Icon icon="arrowBack" />
        </Box>
    );
};
