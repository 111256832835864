import React, { RefObject } from 'react';
import { Tooltip, TooltipRefProps } from 'react-tooltip';
import { IOpenTooltip } from 'plugins/tooltip';

interface CustomTooltipProps extends IOpenTooltip {
    tooltipRef: RefObject<TooltipRefProps>;
}

const CustomTooltip = (props: CustomTooltipProps) => {
    const { container_id, props: bodyProps, tooltipRef } = props;
    const Body = props?.body as React.FC<any>;

    return (
        <Tooltip
            ref={tooltipRef}
            id={container_id}
            clickable
            openOnClick
            globalCloseEvents={{ scroll: true, clickOutsideAnchor: true }}
            style={{
                width: '203px',
                backgroundColor: '#fff',
                color: '#201F1F',
                padding: '18px 12px 12px',
                borderRadius: '30px',
                boxShadow: '0px 5px 24px 0px  rgba(0, 0, 0, 0.08)',
                zIndex: 1000,
            }}
            opacity={1}
        >
            {Body && <Body {...bodyProps} />}
        </Tooltip>
    );
};

export default CustomTooltip;
