/* eslint-disable react/no-danger */
import React from 'react';
import { Button, FlexBox, Text } from 'pyatka-ui';
import { useI18n } from 'hooks/useI18n';
import { AgeVerificationModalLayoutProps } from './types';

export const AgeVerificationModalLayout: React.FC<AgeVerificationModalLayoutProps> = ({
    age_limit,
    age_warning_message,
    cancel,
    approve,
}) => {
    const { $t } = useI18n();
    return (
        <div style={{ background: '#f5f5f5', borderRadius: 12, padding: 24 }}>
            <div dangerouslySetInnerHTML={{ __html: age_warning_message || '' }} />
            <Text as="p" color="gray" fontSize={2} style={{ margin: '16px 0 24px' }}>
                {$t('to_receive_products_show_courier_identification_document_to_confirm_your_age')}
            </Text>
            <FlexBox gap="16px">
                <Button
                    width="100%"
                    height="50px"
                    buttonSize="large"
                    borderRadius="8px"
                    borderColor="#b70039"
                    color="#b70039"
                    onClick={cancel}
                >
                    {$t('im_not_years')} {age_limit} {$t('net_goda')}
                </Button>
                <Button
                    width="100%"
                    height="50px"
                    buttonSize="large"
                    borderRadius="8px"
                    styleType="contained"
                    bg="#20c997"
                    onClick={approve}
                >
                    {$t('im_years')} {age_limit} {$t('est_god')}
                </Button>
            </FlexBox>
        </div>
    );
};
