import React from 'react';
import { WhatsAppButtonLayout } from './WhatsAppButton.Layout';
import { WhatsAppButtonProps } from './types';

export const WhatsAppButtonContainer: React.FC<WhatsAppButtonProps> & {
    Layout: typeof WhatsAppButtonLayout;
} = () => {
    return (
        <React.Fragment>
            <WhatsAppButtonLayout />
        </React.Fragment>
    );
};

WhatsAppButtonContainer.Layout = WhatsAppButtonLayout;
