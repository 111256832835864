import { AppRouteMiddlewareLayer } from 'router/types';
import AppReAuthPage from 'pages/AppReAuthPage';

const isAuthPage: AppRouteMiddlewareLayer = (config) => {
    const { route, isUserAuth } = config;
    if (!route.isPublic) {
        if (isUserAuth) {
            return config;
        }
        return {
            ...config,
            route: { ...route, component: AppReAuthPage },
        };
    }
    return config;
};

export { isAuthPage as default };
