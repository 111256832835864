import React, { useEffect } from 'react';
import { Box, Display, FlexBox, Icon, Text, usePyatka } from 'pyatka-ui';
import styled from 'styled-components/macro';
import { css } from 'styled-components';
import { ProfileAddress } from 'components';
import { useI18n } from 'hooks/useI18n';
import {
    CheckoutDeliveryDateInterval,
    CheckoutDeliveryHourInterval,
    CheckoutDataHookOutput,
} from '../types';
import { formatDay } from '../helper';
import WarningBlock from './WarningBlock';

interface ShippingBlockProps {
    checkout_data?: CheckoutDataHookOutput['checkout_data'];
    setIntervalData: CheckoutDataHookOutput['setIntervalData'];
}

const ShippingBlock: React.FC<ShippingBlockProps> = (props) => {
    const { checkout_data, setIntervalData } = props;

    const { $t } = useI18n();

    const date = checkout_data?.shipping_selected?.interval_date;
    const time = checkout_data?.shipping_selected?.interval_time;
    const intervalsRef = React.useRef<HTMLDivElement | null>(null);

    const { variant, variants } = React.useMemo(() => {
        // --- get all the variants
        const variants = checkout_data?.shipping?.variants || [];

        // --- get active variant
        const variant = variants?.find((v) => v.id === checkout_data?.shipping_selected?.company);

        return { variant, variants };
    }, [checkout_data]);

    const { dates, times, activeDate, defaultDate } = React.useMemo(() => {
        // --- get intervalsDate from active variant
        const dates = variant?.intervals || [];

        // --- get active interval date
        const activeDate = dates?.find((i) => i.value === date);

        // --- get default interval date
        const defaultDate = dates?.length > 0 ? dates[0] : null;

        // --- get intervalsTime from intervalDate
        const times = (activeDate || defaultDate)?.intervals || [];

        return { dates, times, activeDate, defaultDate };
    }, [date, variant]);

    React.useEffect(() => {
        setIntervalData((activeDate || defaultDate)?.value || null, time || null);
    }, []);

    const onChangeDate = (date: string) => {
        setIntervalData(date, null);
        if (intervalsRef?.current) {
            intervalsRef.current.scrollLeft = 0;
        }
    };

    const onChangeTime = (time: string) => {
        setIntervalData(date || null, time);
    };

    return (
        <React.Fragment>
            <FlexBox paddingBottom={'12px'} borderBottom={'2px solid #BEBEB633'}>
                <ProfileAddress withBtnLayout={true} />
            </FlexBox>

            {/* POKA HZ */}
            {variants.length > 1 && <Box />}

            {variants.length === 1 && (
                <React.Fragment>
                    <Intervals
                        title="Дата доставки"
                        intervals={dates}
                        activeInterval={date}
                        onChange={onChangeDate}
                        type="date"
                    />

                    <Intervals
                        title="Время доставки"
                        intervals={times}
                        activeInterval={time}
                        onChange={onChangeTime}
                        intervalsRef={intervalsRef}
                        type="time"
                    />
                </React.Fragment>
            )}

            {variants.length === 0 && <WarningBlock text={$t('no_delivery_options')} />}
        </React.Fragment>
    );
};

interface IntervalsProps {
    title?: string;
    intervals?: CheckoutDeliveryDateInterval[] | CheckoutDeliveryHourInterval[];
    activeInterval?: string | null;
    onChange: (value: string) => void;
    intervalsRef?: React.MutableRefObject<HTMLDivElement | null>;
    type: 'date' | 'time';
}

const Intervals: React.FC<IntervalsProps> = (props) => {
    const { title, intervals, activeInterval, type, onChange, intervalsRef } = props;
    const [isNotFit, setIsFit] = React.useState(true);
    const [navButtons, setNavButtons] = React.useState({ isPrev: false, isNext: true });
    // const sliderRef = React.useRef<any>(null);
    const sliderRef = React.useRef<any>(null);
    const { scales } = usePyatka();
    const isMobile = (scales.matched || []).find((el: any) => el.name === 'md');
    const { $t } = useI18n();
    const onTouchMove = (v: 'prev' | 'next') => {
        if (!sliderRef?.current) {
            return;
        }

        const scrollWidth = sliderRef.current.scrollWidth;
        const clientWidth = sliderRef.current.clientWidth;
        let current = sliderRef.current.scrollLeft;

        if (v === 'next') {
            current += clientWidth;
            sliderRef.current.scrollLeft = current;
        } else {
            current -= clientWidth;
            sliderRef.current.scrollLeft = current;
        }
        setNavButtons({
            isPrev: current > 0,
            isNext: scrollWidth >= current + clientWidth,
        });
    };
    useEffect(() => {
        setIsFit(
            sliderRef?.current
                ? sliderRef?.current?.scrollWidth > sliderRef?.current?.clientWidth
                : false
        );
    }, [sliderRef]);
    useEffect(() => {
        if (intervalsRef) {
            // eslint-disable-next-line prettier/prettier
            // intervalsRef?.current = sliderRef.current;
        }
    }, [intervalsRef]);
    return (
        <Box>
            <Text fontSize={{ default: 12, md: 18 }} color="text.secondary">
                {title}
            </Text>
            <Wrapper
                isMobile={isMobile}
                position={'relative'}
                hasPrev={navButtons.isPrev}
                hasNext={navButtons.isNext}
                mx={'-18px'}
            >
                {isNotFit && navButtons.isPrev && (
                    <Display md={false} lg={true} xxl={true}>
                        <NavButton v="prev" onTouchMove={onTouchMove} />
                    </Display>
                )}
                <FlexBox
                    ref={sliderRef}
                    mt="12px"
                    gap="6px"
                    alignItems="center"
                    overflowY="auto"
                    hideScrollBar
                >
                    {intervals?.map((interval, idx) => {
                        const active = activeInterval === interval?.value;
                        const available = (interval as CheckoutDeliveryHourInterval).is_available;

                        if (type === 'time' && !available) {
                            return null;
                        }

                        return (
                            <Box
                                marginLeft={idx === 0 ? '18px' : 0}
                                p={{ default: '8px 12px', md: '10px 16px' }}
                                fontSize={{ default: '12px', md: '18px' }}
                                borderRadius="38px"
                                whiteSpace="nowrap"
                                key={interval?.value}
                                onClick={() => onChange(interval?.value)}
                                color={active ? 'text.inverse' : 'text.main'}
                                backgroundColor={active ? 'block.brand_variant' : 'block.secondary'}
                                cursor={'pointer'}
                            >
                                {type === 'date'
                                    ? $t(
                                          formatDay(interval?.value, interval?.desc)
                                      ).toCapitalizeFirstLetter()
                                    : interval?.value}
                            </Box>
                        );
                    })}
                </FlexBox>
                {isNotFit && navButtons.isNext && (
                    <Display md={false} lg={true} xxl={true}>
                        <NavButton v="next" onTouchMove={onTouchMove} />
                    </Display>
                )}
            </Wrapper>
        </Box>
    );
};

export default React.memo(ShippingBlock);

const NavButton: React.FC<{
    v?: 'prev' | 'next';
    onTouchMove: (v: 'prev' | 'next') => void;
}> = (props) => {
    const { v = 'prev', onTouchMove } = props;

    return (
        <Box
            onClick={() => onTouchMove(v)}
            style={{
                padding: '16px',
                cursor: 'pointer',
                borderRadius: '50px',
                backgroundColor: '#F6F6F5',

                alignItems: 'center',
                display: 'inline-flex',
                justifyContent: 'center',

                zIndex: 10,
                top: '50%',
                position: 'absolute',
                left: v === 'prev' ? '-40px' : 'auto',
                right: v === 'next' ? '-40px' : 'auto',
                transform: v === 'next' ? 'translateY(-50%) rotate(180deg)' : 'translateY(-50%)',
            }}
        >
            <Icon icon="arrowBack" width={'19px'} height={'19px'} />
        </Box>
    );
};
const Wrapper = styled(Box)<{ isMobile?: boolean; hasNext?: boolean; hasPrev?: boolean }>`
    ${({ isMobile }) =>
        !isMobile &&
        css`
            &:after {
                content: '';
                position: absolute;
                top: -5px;
                height: 120%;
                width: 100%;
                pointer-events: none;
                overflow-y: auto;
                ${({ hasPrev, hasNext }) =>
                    (hasPrev || hasNext) &&
                    css`
                        ${hasPrev ? 'box-shadow: inset 55px 0px 25px -25px #fff;' : ''}
                        ${hasNext ? 'box-shadow: inset -60px 0px 25px -25px #fff;' : ''}
                        ${hasPrev && hasNext
                            ? 'box-shadow: inset 55px 0px 25px -25px #fff, inset -60px 0px 25px -25px #fff;'
                            : ''}
                    `}
            }
        `}
`;
