import React from 'react';
import { Box } from 'pyatka-ui';
import { useAppRouter } from 'router/context';
import { useI18n } from 'hooks/useI18n';

import SearchBarDumbLayout from './SearchBar.DumbLayout';
import SearchBarInputLayout from './SearchBar.InputLayout';

import { useLoadSuggestions, useSupermarket } from './hooks';
import { SearchBarContainerProps, SearchBarCommonProps } from './types';

const SearchBarContainer: React.FC<SearchBarContainerProps> = ({
    dumb_layout = false,
    secondary = false,
    autoFocus = false,
    ...boxProps // rest
}) => {
    const { $t } = useI18n();
    const { supermarket, store_id } = useSupermarket();

    // App Router methods/values
    const { queryParams = {}, location } = useAppRouter();
    const { query } = queryParams;

    // SEARCH VALUE
    const [search_value, setSearchValue] = React.useState<string>(String(query || ''));
    useLoadSuggestions(search_value);

    React.useEffect(() => {
        if (query) {
            setSearchValue(String(query));
        }

        return () => {
            setSearchValue('');
        };
    }, [query]);

    // COMMON PROPS
    const placeholder = React.useMemo(() => {
        if (location.pathname.includes('/store')) {
            return supermarket?.company && store_id
                ? `${$t('search_in_placeholder')} ${supermarket?.company}`
                : $t('search_in_supermarkets_and_pharmacies');
        }

        if (location.pathname.includes('/search')) {
            return supermarket?.company && store_id
                ? `${$t('search_in_placeholder')} ${supermarket?.company}`
                : $t('search_placeholder');
        }
        if (location.pathname.includes('/profile')) {
            return `${$t('search_in_jmart')} `;
        }

        return $t('search_placeholder');
    }, [location.pathname, store_id, $t, supermarket?.company]);

    const common_props: SearchBarCommonProps = {
        search_value,
        placeholder,
        mobileBg: secondary ? 'block.secondary' : 'block.main',
        supermarket,
        store_id,
    };

    if (dumb_layout) {
        return (
            <Box position="relative" {...boxProps}>
                <SearchBarDumbLayout {...common_props} />
            </Box>
        );
    }

    return (
        <Box position="relative" {...boxProps}>
            <SearchBarInputLayout
                {...common_props}
                autoFocus={autoFocus}
                setSearchValue={setSearchValue}
            />
        </Box>
    );
};

export default React.memo(SearchBarContainer);
