import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import {
    fetchProductDetails,
    fetchProductDetailsFailed,
    fetchProductDetailsSeo,
    fetchProductDetailsSeoFailed,
    fetchProductDetailsSeoSucceed,
    fetchProductDetailsSucceed,
} from 'modules/ProductDetail/ProductDetail.actions';
import {
    getGWProductDetails,
    getGWProductDetailsSeo,
} from 'modules/ProductDetail/ProductDetail.services';
import { handleFetchCategories } from 'modules/Categories/Categories.saga';
import { Loader } from 'plugins/loader';
import { SetAppError } from 'modules/App/App.actions';

function* fetchProductDetailsHandler(_action: any) {
    try {
        Loader.product.on({ global: false });
        const { payload } = _action;
        const { fetchCategories = true } = payload;

        const products = yield call(getGWProductDetails, payload.id);
        const category_id =
            products.data?.data?.category_ids[products.data?.data?.category_ids.length - 1];

        yield put(fetchProductDetailsSucceed(products.data?.data));

        // Fetch categories for desktop
        if (fetchCategories) {
            yield fork(handleFetchCategories, { payload: { id: category_id } });
        }
    } catch (e) {
        yield put(fetchProductDetailsFailed());
        yield put(SetAppError({ ...e, ...e?.data?.data }));
    } finally {
        Loader.product.off();
    }
}

function* fetchProductDetailsSeoHandler(_action: any) {
    try {
        const { payload } = _action;
        const { data } = yield call(getGWProductDetailsSeo, payload.id);

        if (data?.data?.status === 'A') {
            yield put(fetchProductDetailsSeoSucceed(data?.data));
        }
    } catch (e) {
        yield put(fetchProductDetailsSeoFailed());
    }
}

export default function* () {
    yield all([
        takeLatest(fetchProductDetails, fetchProductDetailsHandler),
        takeLatest(fetchProductDetailsSeo, fetchProductDetailsSeoHandler),
    ]);
}
