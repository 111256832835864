import React from 'react';
import { FlexBox, Text, Box } from 'pyatka-ui';
import { useI18n } from 'hooks/useI18n';
import { sortOptions } from 'features/Listing/sorting';
import { SortValueType } from 'features/Listing/sorting/types';
import { ApplyButton } from './ApplyButton';
import { ModalSortLayoutProps } from './types';

export const ModalSortLayout: React.FC<ModalSortLayoutProps> = ({
    activeSortValue,
    handleClick,
    applySort,
}) => {
    const { $t } = useI18n();

    return (
        <>
            <FlexBox mb="18px" flexDirection="column" gap="22px" justifyContent="center">
                {Object.entries(sortOptions).map(([key, value]) => {
                    const isActive = key === activeSortValue;

                    const boxStyles = {
                        border: isActive ? '10px solid' : '2px solid',
                        borderColor: isActive ? 'block.brand' : 'rgba(190, 190, 182, 0.4)',
                    };

                    return (
                        <FlexBox
                            key={key}
                            gap="12px"
                            onClick={() => handleClick(key as SortValueType)}
                        >
                            <Box
                                width="32px"
                                height="32px"
                                borderRadius="18.581px"
                                {...boxStyles}
                            />
                            <Text lineClamp={1} lineHeight="32px">
                                {$t(value)}
                            </Text>
                        </FlexBox>
                    );
                })}
            </FlexBox>
            <ApplyButton onApply={applySort} />
        </>
    );
};
