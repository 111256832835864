import React from 'react';
import { Box, FlexBox, Icon, Text, Space, usePyatka, Button } from 'pyatka-ui';
import { FILTER_BEST_PRICE, FILTER_HAS_DISCOUNT, FILTER_STYLE_CHECKBOX } from 'shared/modules/ProductsList/ProductsList.helpers';
import { FilterPrice } from 'pages/ProductsList/components';
import { ProductsListMobileFilters } from 'pages/ProductsList/ProductsList.MobileFilters';
import { StyledSticky } from 'pages/ProductsList/ProductsList.styles';
import { useI18n } from 'hooks/useI18n';
import FilterSwitch from '../../pages/ProductsList/components/FilterSwitch';
import {
    ModalListingFiltersLayoutProps,
    ICheckboxVariant,
    CheckboxComponentProps,
    PriceComponentProps,
} from './types';

export const ModalListingFiltersLayout: React.FC<ModalListingFiltersLayoutProps> = ({
    filtersObject,
    activeFilters,
    filters_order = [],
    onCheckboxClick,
    handlePrice,
    switchToggler,
    handleSingleFeat,
    single_feat,
    updateFilters,
    resetSingleFeat,
    active_filters_names,
    submitFilters,
    closeButtonHandler,
    is_merchant_page = false,
    is_search_page = false,
    product_count = 0,
}) => {
    const { $t } = useI18n();

    if (single_feat) {
        return (
            <>
                <FlexBox
                    left={18}
                    right={18}
                    height="28px"
                    bg="block.main"
                    alignItems="center"
                    position="absolute"
                    justifyContent="space-between"
                >
                    <Box onClick={resetSingleFeat}>
                        <Icon icon="arrowLeft" fill="block.inverse" width="24px" height="24px" />
                    </Box>

                    <Text fontSize="16px" fontWeight={600} lineHeight="20.48px">
                        {single_feat?.title}
                    </Text>
                    <Box width="24px" />
                </FlexBox>
                <Space height="28px" />
                {/* всегда чекбокс, цена в root модалке */}
                <ProductsListMobileFilters
                    filter_style={FILTER_STYLE_CHECKBOX}
                    feature_id={String(single_feat?.feature_id)}
                    variants={single_feat?.variants}
                    handlePrice={handlePrice}
                    updateFilters={updateFilters}
                    closeButtonHandler={closeButtonHandler}
                    is_merchant_page={is_merchant_page}
                    is_search_page={is_search_page}
                />
            </>
        );
    }

    return (
        <>
            <FlexBox gap="6px" flexWrap="wrap">
                {active_filters_names.map(({ feature_id, ...filter }) => (
                    <FilterChip
                        key={feature_id}
                        onClick={() => onCheckboxClick(filter, feature_id)}
                    >
                        {filter.variant}
                    </FilterChip>
                ))}
                {active_filters_names.length > 0 && (
                    <FilterChip secondary onClick={() => updateFilters({})}>
                        {$t('clear_filters')}
                    </FilterChip>
                )}
            </FlexBox>
            {filters_order.map((feature_id: number | string) => {
                const isCheckbox: boolean =
                    String(filtersObject[`${feature_id}`]?.filter_style) === FILTER_STYLE_CHECKBOX;

                const isSwitch =
                    feature_id === FILTER_BEST_PRICE || feature_id === FILTER_HAS_DISCOUNT;

                if (isCheckbox) {
                    return (
                        <CheckboxComponent
                            key={feature_id}
                            {...filtersObject[`${feature_id}`]}
                            active={activeFilters[`${feature_id}`]}
                            onCheckboxClick={onCheckboxClick}
                            onClickAllBtn={() => handleSingleFeat(filtersObject[`${feature_id}`])}
                        />
                    );
                }

                if (isSwitch) {
                    return (
                        <FilterSwitch
                            name={filtersObject[`${feature_id}`].title}
                            description={filtersObject[`${feature_id}`].description}
                            key={feature_id}
                            // filter={filtersObject[`${feature_id}`]}
                            active={activeFilters[`${feature_id}`]}
                            switchToggler={() => switchToggler(feature_id)}
                        />
                    );
                }

                return (
                    <PriceComponent
                        key={feature_id}
                        {...filtersObject[`${feature_id}`]}
                        active={activeFilters[`${feature_id}`]}
                        handlePrice={handlePrice}
                    />
                );
            })}

            <Space height="32px" />

            <ApplyButton onClick={submitFilters} productCount={product_count} />
        </>
    );
};

const FilterChip: React.FC<{
    onClick: () => void;
    children: React.ReactNode;
    secondary?: boolean;
    hasIcon?: boolean;
}> = ({ onClick, children, secondary = false, hasIcon = true }) => {
    const bg = secondary ? 'block.secondary' : 'block.brand_variant';
    const color = secondary ? 'text.main' : 'text.inverse';
    const iconColor = secondary ? 'text.placeholder' : 'text.inverse';

    return (
        <FlexBox
            bg={bg}
            gap="9px"
            height="30px"
            onClick={onClick}
            alignItems="center"
            borderRadius="999rem"
            pl={hasIcon ? '10px' : '12px'}
            pr={hasIcon ? '11px' : '12px'}
        >
            <Text fontSize="12px" fontWeight={500} color={color} lineHeight="15.36px">
                {children}
            </Text>
            {hasIcon && <Icon icon="close" fill={iconColor} width={10} />}
        </FlexBox>
    );
};

const CheckboxComponent: React.FC<CheckboxComponentProps> = ({
    variants = [],
    title,
    feature_id,
    active = {},
    onCheckboxClick,
    onClickAllBtn,
}) => {
    const { $t } = useI18n();

    return (
        <Box my={2}>
            <FlexBox id="feature_header" justifyContent="space-between" mt={4} mb="22px">
                <Text fontWeight={600} fontSize="16px" lineHeight="20.48px">
                    {title}
                </Text>
                <FlexBox gap="6px" alignItems="center" onClick={onClickAllBtn} mr="2px">
                    <Text fontSize="12px" fontWeight={500} lineHeight="15.36px">
                        {$t('all')}
                    </Text>
                    <Icon icon="arrow-right" width={7} height={10} />
                </FlexBox>
            </FlexBox>
            <FlexBox overflow="hidden" flexWrap="wrap" gap="6px" maxHeight="72px" id="feature_body">
                {variants.map((variant: ICheckboxVariant) => (
                    <FilterChip
                        hasIcon={false}
                        key={variant?.variant_id}
                        secondary={!active[variant?.variant_id]}
                        onClick={() =>
                            onCheckboxClick(
                                variant,
                                String(feature_id),
                                Boolean(active[variant?.variant_id])
                            )
                        }
                    >
                        {variant?.variant}
                    </FilterChip>
                ))}
            </FlexBox>
        </Box>
    );
};

const PriceComponent: React.FC<PriceComponentProps> = ({
    variants,
    title,
    active = {},
    handlePrice,
}) => {
    const rangePrice = React.useRef<number[]>([]);

    const price = React.useMemo(() => {
        if (Object.keys(active).length) {
            // [min, max]
            return Object.values(active).map(Number);
        }
        return [variants?.min, variants?.max].map(Number);
    }, [active, variants?.max, variants?.min]);

    return (
        <Box mt="20px" className="feature_wrapper">
            <Text fontWeight={600} fontSize="16px" lineHeight="20.48px" mb="30px">
                {title}
            </Text>
            <FlexBox
                gap="6px"
                flexWrap="wrap"
                maxHeight="84px"
                overflow="hidden"
                className="feature_body"
            >
                <FilterPrice
                    rangePrice={rangePrice}
                    handlePrice={handlePrice}
                    variants={variants}
                    activePrice={price}
                />
            </FlexBox>
        </Box>
    );
};

const ApplyButton: React.FC<{ onClick: () => void; productCount: number }> = ({
    onClick,
    productCount = 0,
}) => {
    const { $t, language = 'ru' } = useI18n();
    const { theme } = usePyatka();

    let products = 'товаров';
    switch (productCount) {
        case 1:
            products = 'товар';
            break;
        case 2:
        case 3:
        case 4:
            products = 'товара';
            break;
        default:
            products = 'товаров';
    }

    const text = React.useMemo(() => {
        if (language === 'kk') {
            return `${productCount} тауарды көрсету`;
        }
        return `Показать ${productCount} ${products}`;
    }, [language, productCount, products]);

    return (
        <StyledSticky theme={theme} isSticky>
            <Button
                height="55px"
                variant="brand"
                padding="18px 16px"
                onClick={onClick}
                borderRadius="22px"
                styleType="contained"
                style={{ flex: 1 }}
            >
                <Text fontSize="16px" fontWeight={600} lineHeight="20.48px">
                    {productCount ? text : $t('apply')}
                </Text>
            </Button>
        </StyledSticky>
    );
};
