import React from 'react';
import { matchPath, useLocation } from 'react-router';
import { Button, FlexBox, Icon, Text, usePyatka } from 'pyatka-ui';
import { AppLink } from 'components';
import { AppMetricEvents } from 'AppMetric';
import { useI18n } from 'hooks/useI18n';
import { SectionTitleProps } from './types';
import { SectionTitleLabel } from './SectionTitle.Label';

const SectionTitle: React.FC<SectionTitleProps> = ({
    url,
    style,
    labels,
    sectionText,
    font_size,
    isLoading = false,
    // buttonColor = 'block.main',
}) => {
    const { scales } = usePyatka();
    const { pathname } = useLocation();
    const { $t } = useI18n();

    const isUrlExist = React.useMemo(() => {
        if (typeof url === 'object') {
            return !!Object.keys(url).length;
        }
        return !!url;
    }, [url]);

    if (!sectionText && !isUrlExist) {
        return null;
    }

    const isMainPage = matchPath(pathname, {
        path: '/',
        exact: true,
    });

    const metricHandlers = () => {
        if (isMainPage) {
            AppMetricEvents.jmart_selection_click({ keyword: sectionText || '' });
        }
    };

    const hasLabel = labels?.text?.trim() && labels?.type && labels?.text !== 'undefined';
    const fontSize = font_size
        ? font_size
        : isMainPage
        ? { default: '18px', md: '32px' }
        : { default: 3, md: 7 };
    const showIcon = scales?.isMobile;

    return (
        <FlexBox
            width="100%"
            alignItems="center"
            mb={{ default: '8px', md: 0 }}
            justifyContent="space-between"
        >
            <FlexBox alignItems="center" overflow="hidden">
                <Text
                    // @ts-ignore
                    as="h2"
                    fontWeight="700"
                    color="text.main"
                    fontSize={fontSize}
                    skeleton={isLoading}
                    skeletonProps={skeletonProps}
                    mr={{ default: '5px', md: '10px' }}
                    style={{
                        marginBlockStart: 0,
                        marginBlockEnd: 0,
                        ...style,
                        display: 'block',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                    }}
                >
                    {sectionText}
                </Text>
                {hasLabel && <SectionTitleLabel labels={labels} />}
            </FlexBox>
            {isUrlExist && (
                <AppLink to={url} style={{ marginLeft: '5px' }}>
                    <Button
                        border={0}
                        borderRadius="999rem"
                        onClick={metricHandlers}
                        // backgroundColor={buttonColor}
                        backgroundColor="block.secondary"
                        px={{ default: '4px', md: showIcon ? '4px' : '15px' }}
                        py={{ default: '4px', md: showIcon ? '4px' : '10px' }}
                        width={{ default: '28px', md: showIcon ? '40px' : 'auto' }}
                        height={{ default: '26px', md: showIcon ? '38px' : '43px' }}
                        buttonSuffix={!showIcon && <Icon width="12px" icon="arrow-right" />}
                    >
                        {showIcon ? (
                            <Icon height="12px" icon="arrow-right" />
                        ) : (
                            <Text fontSize="18px" lineHeight="23.4px" fontWeight={500}>
                                {$t('all')}
                            </Text>
                        )}
                    </Button>
                </AppLink>
            )}
        </FlexBox>
    );
};

const skeletonProps = {
    borderRadius: '12px',
    width: { default: '50%', md: '70%' },
    height: { default: '1.5em', md: '2em' },
};

export default React.memo(SectionTitle);
