import React from 'react';
import { FlexBox, LoadingIndicator } from 'pyatka-ui';
import { ErrorBoundary } from 'components';
import { AppPageProps } from 'router/types';

const _AppPage: React.FC<AppPageProps> = (props) => {
    const { route } = props;

    const component = React.createElement(route.component, {
        ...props,
        fallback: (
            <FlexBox height="200px" flex="grid" justifyContent="center" alignItems="center">
                <LoadingIndicator size="40px" />
            </FlexBox>
        ),
    });

    if (route.layout) {
        return (
            <route.layout {...props}>
                <ErrorBoundary>{component}</ErrorBoundary>
            </route.layout>
        );
    }

    return <ErrorBoundary>{component}</ErrorBoundary>;
};

export const AppPage = React.memo(_AppPage);
