import React from 'react';
import { Container, FlexBox, Icon, Text, usePyatka } from 'pyatka-ui';
import AddToCartBlock from 'pages/WishlistCollectionPage/components/AddToCartBlock';
import CollectionsBlock from 'pages/WishListServicePage/v2/components/CollectionsBlock';
import { BreadcrumbItemProps } from 'features/Listing/components/Breadcrumbs/types';

import WishlistProducts from 'pages/WishListServicePage/v2/components/WishlistProducts';
import { useI18n } from 'hooks/useI18n';
import { AppMetricEvents } from 'AppMetric';

const WishlistCollectionPageLayout = (props: any) => {
    const { $t } = useI18n();
    const { scales } = usePyatka();

    const isDesktop = scales?.name === 'lg' || scales?.name === 'xl' || scales?.name === 'xxl';
    const {
        name,
        products,
        productCountText,
        totalPrice,
        isAddToCartLoading,
        handleAddToCart,
        disableAddToCartBtn,
        wishListCollections,
        collection_id,
        onClickMoreBtn,
        onShare,
    } = props;
    const breadcrumbItems: BreadcrumbItemProps[] = [
        { title: 'Избранное', link: '/wishlist' },
        { title: 'name', link: '' },
    ];
    const productClickMetric = (data: any) => {
        AppMetricEvents.product_click({
            source: 'favorite',
            data,
        });
    };

    const addToCartMetric = (data: any) => {
        AppMetricEvents.jmart_add_to_cart2({
            source: 'favorite',
            data,
        });
    };

    const addToCartClickMetric = (data: any) => {
        AppMetricEvents.jmart_add_to_cart_click({
            source: 'favorite',
            data,
        });
    };

    const metric = {
        product_click: productClickMetric,
        add_to_cart: addToCartMetric,
        add_to_cart_click: addToCartClickMetric,
    };
    return (
        <Container style={{ paddingTop: '12px', paddingBottom: '130px' }}>
            {isDesktop ? (
                <>
                    {/*{breadcrumbItems.length > 0 && (*/}
                    {/*    <>*/}
                    {/*        <Breadcrumbs items={breadcrumbItems} isLoading={false} />*/}
                    {/*        <Space height="1.5rem" />*/}
                    {/*    </>*/}
                    {/*)}*/}
                    {/*<Breadcrumbs items={breadcrumbItems} />*/}
                    <CollectionsBlock
                        collections={wishListCollections}
                        activeCollectionId={collection_id}
                    />
                    <FlexBox alignItems="center" justifyContent={'space-between'}>
                        <Text fontSize={28} fontWeight={700} lineHeight={'35.84px'}>
                            {name}
                        </Text>
                        <FlexBox gap={'20px'}>
                            <FlexBox
                                gap={'10px'}
                                borderRadius={'24px'}
                                backgroundColor={'block.secondary'}
                                padding={'12px 18px'}
                                justifyContent={'center'}
                                alignItems={'center'}
                                cursor={'pointer'}
                                onClick={onShare}
                            >
                                <Text fontSize={16} lineHeight={'20.48px'}>
                                    {$t('share')}
                                </Text>
                                <Icon icon={'ic_Share'} width="24px" fill="#201F1F" />
                            </FlexBox>
                            <FlexBox
                                width={'48px'}
                                height={'48px'}
                                onClick={onClickMoreBtn}
                                borderRadius={'24px'}
                                backgroundColor={'block.secondary'}
                                justifyContent={'center'}
                                alignItems={'center'}
                                cursor={'pointer'}
                            >
                                <Icon icon={'more-horizontal'} />
                            </FlexBox>
                        </FlexBox>
                    </FlexBox>
                </>
            ) : (
                products?.length > 0 && (
                    <>
                        <AddToCartBlock
                            productAmount={productCountText}
                            price={totalPrice}
                            isDisabledAddToCart={isAddToCartLoading || disableAddToCartBtn}
                            handleAddToCart={handleAddToCart}
                        />
                    </>
                )
            )}
            <WishlistProducts wishlistProducts={products} metric={metric} isDesktop={isDesktop} />
            {/*<ProductsCardsList*/}
            {/*    // metric={metric}*/}
            {/*    products={products || []}*/}
            {/*    isLoading={false}*/}
            {/*    layout="new"*/}
            {/*/>*/}
        </Container>
    );
};

export default WishlistCollectionPageLayout;
