import { createSelector } from 'reselect';
import { AppRootState } from 'store/types';
import { ProductsParser } from 'modules/ProductsList/ProductsList.helpers';
import { Product } from 'modules/ProductsList/types';
import { MerchantsInitialState } from './types';

const getMerchantsReducer = (state: AppRootState): MerchantsInitialState => state.MerchantsReducer;

export const getMerchantsState = createSelector([getMerchantsReducer], (state) => state);

export const getProductsList = createSelector([getMerchantsState], (state): Product[] =>
    ProductsParser(state.products || [])
);

export const getMerchantData = createSelector([getMerchantsState], (state) => state.data);

export const getCategoriesList = createSelector([getMerchantsState], (state) => state.categories);

export const getParentCategory = createSelector(
    [getMerchantsState],
    (state) => state.parentCategory
);

export const getTotalPages = createSelector([getMerchantsState], (state) => state.total_pages);

export const getFiltersOrder = createSelector([getMerchantsState], (state) => state.filters_order);

export const getActiveFilters = createSelector(
    [getMerchantsState],
    (state) => state.active_filters
);

export const getFiltersObject = createSelector(
    [getMerchantsState],
    (state) => state.filters_object
);

export const getMerchantProductsCount = createSelector(
    [getMerchantsState],
    (data) => data.total_count
);

export const getMerchantFilters = createSelector(
    [getMerchantsState],
    ({ filters_object, active_filters, filters_order }) => ({
        filtersObject: filters_object,
        activeFilters: active_filters,
        filters_order,
    })
);
