import React from 'react';
import { useCartService } from 'contexts/useCartService';
import { AppToaster } from 'plugins/app-toaster';
import { useI18n } from 'hooks/useI18n';
import { CartProductCounterLayout } from './CartProductCounter.Layout';
import { CartProductCounterProps } from './types';
import { CartProductCounterLayoutNew } from './CartProductCounter.LayoutNew';

export const CartProductCounterContainer: React.FC<CartProductCounterProps> = (props) => {
    const {
        layout = 'old',
        fullWidth = false,
        offer_id,
        product_type = 'G',
        outControl = false,
        amount,
        setAmount,
        bg = 'block.secondary',
        is_super,
        decrease_bg = 'block.main',
    } = props;
    const { cartItemByOffer, cartEdit, cart_loadings } = useCartService();
    const { $t } = useI18n();

    const cartItem = React.useMemo(() => {
        return offer_id && cartItemByOffer(offer_id.toString());
    }, [cartItemByOffer, offer_id]);

    const cartItemAmountChanged = (val: number | string) => {
        const limitVal = product_type === 'G' ? 100 : 10;
        if (Number(val) <= limitVal) {
            cartEdit({ ...cartItem, amount: val });
        } else {
            AppToaster.warning({
                body: `${$t('you_cannot_order_more_than')} ${limitVal} ${$t(
                    'units_of_this_product'
                )}`,
            });
        }
    };

    const decreaseAmount = (e: React.MouseEvent<HTMLElement>) => {
        if (outControl) {
            if (amount && amount > 1) {
                setAmount && setAmount(amount - 1);
            }
        } else {
            if (cartItem) {
                cartItemAmountChanged(Number(cartItem?.amount) - 1);
            }
        }
        e?.stopPropagation();
    };

    const increaseAmount = (e: React.MouseEvent<HTMLElement>) => {
        if (outControl) {
            if (amount) {
                setAmount && setAmount(amount + 1);
            }
        } else {
            if (cartItem) {
                cartItemAmountChanged(Number(cartItem?.amount) + 1);
            }
        }
        e?.stopPropagation();
    };

    const cartAmount = outControl ? amount : cartItem ? cartItem.amount : 0;

    if (layout === 'new') {
        return (
            <CartProductCounterLayoutNew
                bg={bg}
                fullWidth={fullWidth}
                amount={cartAmount || 0}
                decreaseAmount={decreaseAmount}
                increaseAmount={increaseAmount}
                isLoading={cart_loadings.mutation_loading.some((id) => `${id}` === `${offer_id}`)}
                disabled={false}
            />
        );
    }

    return (
        <React.Fragment>
            <CartProductCounterLayout
                bg={bg}
                fullWidth={fullWidth}
                amount={cartAmount || 0}
                decreaseAmount={decreaseAmount}
                increaseAmount={increaseAmount}
                isLoading={cart_loadings.mutation_loading.some((id) => `${id}` === `${offer_id}`)}
                disabled={false}
                is_super={is_super}
                decrease_bg={decrease_bg}
            />
        </React.Fragment>
    );
};
