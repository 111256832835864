import React from 'react';

const UP_MAX = 3;

const useScrollDirection = (up_max: number = UP_MAX) => {
    const [scrollDirection, setScrollDirection] = React.useState(() => {
        if (typeof window !== 'undefined') {
            return {
                scrollY: window.pageYOffset,
                direction: window.pageYOffset ? 'down' : 'up',
            };
        }
        return { direction: 'none', scrollY: 0 };
    });

    React.useEffect(() => {
        let depth = 0;
        let lastScrollY = window.pageYOffset;

        const updateScrollDirection = () => {
            const scrollY = window.pageYOffset;
            const scrollMaxY = (document?.body?.scrollHeight || 0) - (window?.innerHeight || 0);

            if (scrollY >= 0 && scrollY <= scrollMaxY) {
                scrollY > lastScrollY ? (depth = 0) : depth++;
                const direction = scrollY <= up_max ? 'up' : depth >= up_max ? 'up' : 'down';
                lastScrollY = scrollY > 0 ? scrollY : 0;

                setScrollDirection({ scrollY, direction });
            }
        };
        window.addEventListener('scroll', updateScrollDirection); // add event listener

        return () => window.removeEventListener('scroll', updateScrollDirection); // clean up
    }, []);

    return scrollDirection;
};

export default useScrollDirection;
