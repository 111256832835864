import React from 'react';
import { FlexBox } from 'pyatka-ui';
import { ProductLabel } from 'components';

export const SectionTitleLabel: React.FC<LabelProps> = ({ labels }) => {
    if (labels?.type === 'bonus') {
        return (
            <ProductLabel
                variant="bonus"
                iconSize={{ small: '11px', big: '20px' }}
                fontSize={{ default: '12.5px', md: '22px' }}
                padding={{ default: '2.3px 6px', md: '2px 10px' }}
            >
                {labels?.text?.replace('Б', '')}
            </ProductLabel>
        );
    }

    if (labels?.type === 'installment') {
        return (
            <FlexBox bg="#FF7348" {...labelStyle}>
                {labels?.text?.replaceAll('-', '•')}
            </FlexBox>
        );
    }

    if (labels?.type === 'discount') {
        return (
            <FlexBox bg="#FF0000" {...labelStyle}>
                {labels?.text}
            </FlexBox>
        );
    }

    return null;
};

interface LabelProps {
    labels?: { text?: string; type?: string };
}

const labelStyle: any = {
    padding: { default: '1px 6px', md: '0px 10px' },
    fontSize: { default: '12px', md: '22px' },
    borderRadius: '999rem',
    whiteSpace: 'nowrap',
    color: 'white',
    fontWeight: 700,
};
