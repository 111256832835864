import React from 'react';
import { Switch } from 'react-router-dom';
import { AppRouteMiddleware } from 'router/AppRouter/AppRouteMiddleware';
import { AppRouteProps, AppRouterProps } from 'router/types';

const _AppRouter: React.FC<AppRouterProps> = ({ routes }) => (
    <Switch>
        {routes &&
            routes.map((route: AppRouteProps) => (
                <AppRouteMiddleware {...route} key={route.name} />
            ))}
    </Switch>
);

export const AppRouter = React.memo(_AppRouter);
