import React from 'react';
import { MapUserLocationLayout } from './MapUserLocation.Layout';
import { MapUserLocationProps } from './types';

export const MapUserLocationContainer: React.FC<MapUserLocationProps> & {
    Layout: typeof MapUserLocationLayout;
} = (props) => {
    return (
        <React.Fragment>
            <MapUserLocationLayout {...props} />
        </React.Fragment>
    );
};

MapUserLocationContainer.Layout = MapUserLocationLayout;
