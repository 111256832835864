import * as queryString from 'querystring';
import { FetchPageData } from 'router/types';

import { ProductsListInit, ProductsListSeoInit } from 'modules/ProductsList/ProductsList.actions';

export const ProductListFetch: FetchPageData = ({ dispatch, payload = {} }) => {
    // Если не страница поиска (?query = пусто)
    if (!payload?.query?.query) {
        dispatch(ProductsListSeoInit(payload?.query?.category_id));
    }
    // Fetch products list
    if (payload?.query) {
        const { isFirstRun = true, category_id = '', signal } = payload.params;
        // const params = prepareFilters(payload.query);
        dispatch(
            ProductsListInit({
                isFirstRun,
                params: payload.query,
                persistProducts: payload?.params?.persistProducts || false,
                url: payload?.params?.url || '?' + queryString.stringify(payload.query),
                category_id,
                signal,
            })
        );
    }
};
