import styled from 'styled-components/macro';
import { Box, Button, FlexBox, Text } from 'pyatka-ui';

export const UserPanel = styled(Box)`
    position: relative;
`;
export const AvatarWrapper = styled(Box)<{ cursor?: string }>`
    cursor: ${(props) => (props.cursor ? props.cursor : 'auto')};
`;
export const MenuWrapper = styled(Box)`
    position: absolute;
    pointer-events: none;
    top: -15px;
    right: -32px;
    border-radius: 30px;
    background: #fff;
    box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.05);
    z-index: 1;
    padding: 8px;
    width: 324px;
    opacity: 0;
    transition: 0.3s ease-in-out;

    &.open {
        pointer-events: auto;
        opacity: 1;
    }
`;
export const MenuHeader = styled(FlexBox)`
    border-radius: 22px;
    background: #f6f6f5;
    padding: 7px 24px 8px 24px;
    justify-content: space-between;
`;
export const MenuList = styled(FlexBox)`
    flex-direction: column;
    padding: 24px;
    gap: 24px;
`;
export const MenuListLinkItem = styled(Text)`
    font-size: 18px;
    cursor: pointer;

    &:hover {
        color: #898d94;
    }
`;
export const LogOutButton = styled(Button)`
    justify-content: flex-start;
    border: none;
    padding: 0;
    font-size: 18px;
`;
