import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import {
    CancelReasonInit,
    CancelReasonSucceed,
    OrdersDetailInit,
    OrdersServiceClear,
    OrdersServiceFailed,
    OrdersServiceInit,
    OrdersServiceSucceed,
} from 'modules/OrdersService/OrdersService.actions';
import {
    dispatchCancelReason,
    OrdersServiceSimpleService,
} from 'modules/OrdersService/OrdersService.services';
import { AppToaster } from 'plugins/app-toaster';
import { Loader } from 'plugins/loader';
import { isUserAuth } from 'modules/Authorization/Authorization.selectors';
import { OrdersListReqPayload } from 'modules/OrdersService/types';
import { getOrdersPagination } from 'modules/OrdersService/OrdersService.selectors';

function* handler(action: { payload: OrdersListReqPayload }) {
    const isAuth = yield select(isUserAuth);
    const { payload } = action;
    const { params, props } = payload;
    const { currPage: page } = yield select(getOrdersPagination);
    const url = new URL(window.location.href);
    const newPage = url.searchParams.get('page');
    // const [currentUrl, newPage = 1] = removePageFromQS(props.url);
    console.log('!!! SAGA', page, newPage);
    if (String(page) === String(newPage)) {
        return;
    }
    if (Number(newPage) === 1 || !newPage) {
        yield put(OrdersServiceClear());
    }
    Loader.orders.on({ global: false });
    try {
        // const servicePayload = order_id ? order_id : params;
        const { data } = yield call(OrdersServiceSimpleService, params);
        // if (order_id) {
        //     yield put(OrdersServiceSucceed({ order: data?.data }));
        // } else {
        yield put(OrdersServiceSucceed({ data: data?.data }));
        // }
    } catch (e) {
        console.log(e);
        if (isAuth) {
            AppToaster.warning({ body: 'product_have_not_loaded_yet_wait' });
        }
        yield put(OrdersServiceFailed());
    } finally {
        Loader.orders.off();
    }
}

function* handleGetOrderDetail(_action: any) {
    const isAuth = yield select(isUserAuth);
    const { payload = {} } = _action;
    console.log('!!!! ORDER DETAOL');
    Loader.orderDetail.on({ global: false });
    try {
        const { data } = yield call(OrdersServiceSimpleService, payload);
        yield put(OrdersServiceSucceed({ order: data.data }));
    } catch (e) {
        console.log(e);
        if (isAuth) {
            AppToaster.warning({ body: 'product_have_not_loaded_yet_wait' });
        }
        yield put(OrdersServiceFailed());
    } finally {
        Loader.orderDetail.off();
    }
}

function* cancelReasonHandler(_action: any) {
    const { payload = {} } = _action;

    const cancelOrderPayload = { ...payload, cancelReasonOther: payload?.cancelComment };

    try {
        yield call(dispatchCancelReason, cancelOrderPayload);
        // @ts-ignore
        yield put(CancelReasonSucceed({ order_id: payload?.order_id }));

        AppToaster.info({ body: 'request_has_been_sent' });
    } catch (e) {
        AppToaster.warning({ body: e?.data?.data?.message });
    }
}

function* cancelReasonSucceedHandler(_action: any) {
    const { payload } = _action;

    console.log('the payload : ', payload);

    try {
        yield put(OrdersDetailInit({ order_id: payload?.order_id }));
    } catch (e) {}
}

export default function* () {
    yield all([
        takeLatest(OrdersServiceInit, handler),
        takeLatest(CancelReasonInit, cancelReasonHandler),
        takeEvery(CancelReasonSucceed, cancelReasonSucceedHandler),
        takeEvery(OrdersDetailInit, handleGetOrderDetail),
    ]);
}
