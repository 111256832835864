import React from 'react';
import { Box, FlexBox, Image, Space, Text } from 'pyatka-ui';
import priceFormatter from 'utils/priceFormatter';
import { signs } from 'utils/symbols';
import { AppLink } from 'components';
import OrderCardHeader from 'components/ClientOrderCard/components/OrderCardHeader';
import ArrowIcon from 'components/ClientOrderCard/components/ArrowIcon';
import OrderStatus from 'components/ClientOrderCard/components/OrderStatus';
import { useAppRouter } from 'router/context';
import { AppMetricEvents } from 'AppMetric';
import { ClientOrderCardLayoutProps } from './types';

interface Statuses {
    [key: string]: string[];
}

interface StatusColors {
    [key: string]: string;
}

// const STATUS_COLORS: StatusColors = {
//     Q: 'blue',
//     L: 'green',
//     S: 'brand',
//     N: 'grey',
//     O: '#ccc',
//     K: 'brand',
// };

export const ClientOrderCardLayout: React.FC<ClientOrderCardLayoutProps> = (props) => {
    const { orderId, timestamp, orderStatus, orderStatusText, hasArrow, products } = props;
    // const statuses: Statuses = {
    //     L: ['statusTenge', 'tickStatus', 'tickBox', 'delayStatus'],
    //     Q: ['deliveryStatus'],
    //     S: ['canceledStatus'],
    //     N: ['statusTenge', 'tickBoxOff', 'tickStatusOff', 'deliveryStatusOff'],
    //     O: ['statusTenge', 'tickBoxOff', 'tickStatusOff', 'deliveryStatusOff'],
    //     K: ['statusTenge', 'tickStatus', 'tickBox', 'deliveryStatus'],
    // };

    const { appRedirect } = useAppRouter();

    const singleProduct = products?.length === 1;
    const hiddenProductsNum = products && products.length > 3 ? products.length - 3 : null;
    const slicedProducts = products?.slice(0, 3);

    const routeToProduct = (e: any, to: any, product: any) => {
        e.stopPropagation();
        appRedirect(to);

        const { category_ids = [] } = product;
        const [category1, category2, category3] = category_ids || [];

        AppMetricEvents.product_click({
            source: 'profile',
            data: {
                product_id: product?.product_id,
                product_name: product?.productName,
                category1: category1 || null,
                category2: category2 || null,
                category3: category3 || null,
                jdv_labels: product?.jdv_labels || [],
            },
        });
    };

    return (
        <React.Fragment>
            {/* Order Card header  */}
            <OrderCardHeader orderId={orderId} timestamp={timestamp} />
            {/* ----------------------------------------------------------------------------------- */}
            <Space height={'12px'} />

            {/* ------------------------------------------------------------------------------------- Products Images ---------------  */}
            <FlexBox justifyContent={'space-between'}>
                {/* TODO: Type */}
                <FlexBox
                    // justifyContent={'space-around'}
                    // style={{ border: '1px solid' }}
                    gap={'5px'}
                >
                    {slicedProducts?.map((product: any, idx: number) => (
                        <Box
                            key={idx}
                            marginRight={'5px'}
                            onClick={(e) => routeToProduct(e, product.urlMasterId, product)}
                        >
                            <Image key={idx} src={product?.image} width={'52px'} />
                        </Box>
                    ))}
                    {hiddenProductsNum && (
                        <FlexBox
                            // gap={'13px'}
                            width={'52px'}
                            height={'52px'}
                            justifyContent={'center'}
                            alignItems={'center'}
                            border={'2px solid #F6F6F5'}
                            borderRadius={'12px'}
                        >
                            <Box>
                                <Text fontWeight={700} color={'#AAADB2'}>
                                    + {hiddenProductsNum}
                                </Text>
                            </Box>
                        </FlexBox>
                    )}
                </FlexBox>

                {/* Single product layout */}
                {singleProduct && (
                    <Box
                        className={'d-flex justify-content-center flex-column pl-0 ml-2'}
                        style={{ flexGrow: '1' }}
                    >
                        <Text
                            color={'#AAADB2'}
                            fontSize={{
                                default: '11px',
                                sm: '11px',
                            }}
                            lineHeight={'14px'}
                        >
                            {products && products[0].productName}
                        </Text>
                        <Space height={'2px'} />
                        <Text
                            fontWeight={'bold'}
                            fontSize={{
                                default: '15px',
                                sm: '15px',
                            }}
                        >
                            {products && priceFormatter(products[0].price)} {signs.tg}
                        </Text>
                    </Box>
                )}
                {/*{hiddenProductsNum && (*/}
                {/*    <FlexBox*/}
                {/*        width={'52px'}*/}
                {/*        height={'52px'}*/}
                {/*        justifyContent={'center'}*/}
                {/*        alignItems={'center'}*/}
                {/*        border={'2px solid #F6F6F5'}*/}
                {/*        borderRadius={'12px'}*/}
                {/*    >*/}
                {/*        <Box>*/}
                {/*            <Text fontWeight={700} color={'#AAADB2'}>*/}
                {/*                + {hiddenProductsNum}*/}
                {/*            </Text>*/}
                {/*        </Box>*/}
                {/*    </FlexBox>*/}
                {/*)}*/}
                {/* Link to detail page  */}
                {hasArrow && <ArrowIcon orderId={orderId} />}
            </FlexBox>

            {/* ------------------------------------------------------------------------------------- */}

            {/* ------------------------------------------------------------------------------------Statuses */}
            {orderStatusText && <OrderStatus orderStatusText={orderStatusText} />}
        </React.Fragment>
    );
};
