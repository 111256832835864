import * as React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { loadableReady } from '@loadable/component';
import { MainApp } from 'app/MainApp.Container';
import { createUniversalHistory } from 'router/history';
import { AppRedux } from 'store';
import { getSelectedCityStorage } from 'data';
import { AppInit } from 'modules/App/App.actions';
// import { AppInit } from 'modules/App/App.actions';
// import { hydrate, render } from 'react-dom';
// const renderMethod = module.hot ? render : hydrate;

const history = createUniversalHistory();
const { store, rootSaga } = AppRedux;
store.runSaga(rootSaga);
const preselected_city = getSelectedCityStorage();
// console.log(preselected_city);
store.dispatch(
    AppInit({
        hybridConfig: window.__HYBRIDCONFIG__,
        preselected_city: preselected_city?.id || null,
    })
);

// const saveHybridConfigKeys = () => {
//     const config: any = window.__HYBRIDCONFIG__ || {};
//     const keys = Object.keys(config);
//     if (keys.length > 0) {
//         keys.map((key) => {
//             saveHybridConfig(`jusan_${key}`, config[key.toString()]);
//         });
//     }
// };
// saveHybridConfigKeys();

performance.addEventListener('resourcetimingbufferfull', (event: Event) => {
    console.log('WARNING: Resource Timing Buffer is FULL! Increasing buffer size to 500.', event);
});

loadableReady(() =>
    render(
        <Provider store={store}>
            <Router history={history}>
                <HelmetProvider>
                    <MainApp />
                </HelmetProvider>
            </Router>
        </Provider>,
        document.getElementById('app')
    )
);

if (process.env.NODE_ENV === 'development') {
    if (module.hot) {
        module.hot.accept();
    }
    if (!window.store) {
        window.store = store;
    }
}
