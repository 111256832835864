import { ApiGwRequest } from 'services';
import { OrderItem } from 'modules/OrdersService/types';
import { MY_ORDERS_GET_REQ_INDICATOR } from 'keys';

export const OrdersServiceSimpleService = (payload?: OrderItem) => {
    const hasOrderId = payload?.order_id ? `/${payload.order_id}` : '';
    return ApiGwRequest({
        indicator: MY_ORDERS_GET_REQ_INDICATOR,
        url: `order/v1/my/order${hasOrderId}`,
        method: 'get',
        params: payload || null,
    });
};

export const dispatchCancelReason = (payload: {
    order_id: string;
    cancelReason: string;
    cancelReasonOther: string;
}) => {
    const hasOrderId = payload?.order_id ? `/${payload.order_id}` : '';
    const { cancelReason, cancelReasonOther } = payload;
    return ApiGwRequest({
        url: `order/v1/cancel-order/customer${hasOrderId}`,
        method: 'put',
        data: {
            cancelReason,
            cancelReasonOther,
        },
    });
};
