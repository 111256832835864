import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { ProductDetailInitialState } from 'modules/ProductDetail/types';

const fetchProductDetails: CaseReducer<
    ProductDetailInitialState,
    PayloadAction<{ id: string | number }>
> = (state, _action) => {
    state.product_details = {};
};

const removeProductDetails: CaseReducer<ProductDetailInitialState, PayloadAction> = (
    state,
    _action
) => {
    state.product_details = {};
};
const fetchProductDetailsSucceed: CaseReducer<ProductDetailInitialState, PayloadAction<any>> = (
    _state,
    _action
) => {
    const { payload } = _action;
    _state.product_details = payload;
};

const fetchProductDetailsFailed: CaseReducer<ProductDetailInitialState, PayloadAction> = (
    _state,
    _action
) => {
    const { payload } = _action;
    _state.error = payload;
};

// FETCH SEO
const fetchProductDetailsSeo: CaseReducer<
    ProductDetailInitialState,
    PayloadAction<{ id: string | number }>
> = (state, _action) => {
    state.product_seo = null;
};

const fetchProductDetailsSeoSucceed: CaseReducer<ProductDetailInitialState, PayloadAction<any>> = (
    _state,
    _action
) => {
    const { payload } = _action;
    _state.product_seo = payload;
};

const fetchProductDetailsSeoFailed: CaseReducer<ProductDetailInitialState, PayloadAction> = (
    _state,
    _action
) => {
    _state.product_seo = null;
    _state.product_seo_error = {
        message: 'Не удалось загрузить Open graph',
    };
};

const setProductLabels: CaseReducer<
    ProductDetailInitialState,
    PayloadAction<ProductDetailInitialState['labels']>
> = (_state, _action) => {
    _state.labels = _action.payload;
};

const clearProductLabels: CaseReducer<
    ProductDetailInitialState,
    PayloadAction<ProductDetailInitialState['labels']>
> = (_state, _action) => {
    _state.labels = [];
};

const setProductMinPriceInfo: CaseReducer<
    ProductDetailInitialState,
    PayloadAction<ProductDetailInitialState['min_pirce_info']>
> = (_state, _action) => {
    _state.min_pirce_info = _action.payload;
};

const clearProductMinPriceInfo: CaseReducer<ProductDetailInitialState, PayloadAction> = (
    _state,
    _action
) => {
    _state.min_pirce_info = {};
};

const setSimilarProducts: CaseReducer<
    ProductDetailInitialState,
    PayloadAction<ProductDetailInitialState['similar_products']>
> = (_state, _action) => {
    _state.similar_products = _action.payload;
};

const clearSimilarProducts: CaseReducer<ProductDetailInitialState, PayloadAction> = (
    _state,
    _action
) => {
    _state.similar_products = [];
};

interface ProductDetailModalOpenPayload {
    isOpen: boolean;
    data?: ProductDetailInitialState['productDetail'];
}

// SUPERMARKET PRODUCT DETAIL MODAL
const setProductDetailModalOpen: CaseReducer<
    ProductDetailInitialState,
    PayloadAction<ProductDetailModalOpenPayload>
> = (state, { payload }) => {
    state.isDetailModalOpen = payload.isOpen;
    state.productDetail = payload.data || null;
};

export default {
    fetchProductDetails,
    removeProductDetails,
    fetchProductDetailsSucceed,
    fetchProductDetailsFailed,
    setProductLabels,
    clearProductLabels,
    setProductMinPriceInfo,
    clearProductMinPriceInfo,
    setSimilarProducts,
    clearSimilarProducts,

    // MODAL
    setProductDetailModalOpen,

    // SEO
    fetchProductDetailsSeo,
    fetchProductDetailsSeoSucceed,
    fetchProductDetailsSeoFailed,
};
