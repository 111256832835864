import { connect, ConnectedProps } from 'react-redux';
import { SubmitReview } from 'modules/ReviewService/ReviewService.actions';
import { isClientDebug } from 'modules/UserProfileService/UserProfileService.selectors';
import { AppRootState } from 'store/types';

const mapState = (state: AppRootState) => ({
    // client_debug: false,
    client_debug: isClientDebug(state),
});

const mapDispatch = {
    SubmitReview,
};

export const ReviewModalConnector = connect(mapState, mapDispatch);
export type ReviewModalConnectProps = ConnectedProps<typeof ReviewModalConnector>;
