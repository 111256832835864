import { FetchPageData } from 'router/types';
import {
    fetchSupermarketsDetail,
    fetchReccomendations,
    fetchSuperCategories,
} from 'modules/Supermarkets/Supermarkets.actions';

export const SupermarketDetailPageFetch: FetchPageData = ({ dispatch, payload }) => {
    if (__BROWSER__) {
        payload?.params?.fetchIds &&
            dispatch(fetchSupermarketsDetail({ id: payload?.params?.store_id }));
        dispatch(fetchReccomendations(payload?.params));
        dispatch(fetchSuperCategories(payload?.params));
    }
};
