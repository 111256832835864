import React from 'react';
import isEqual from 'lodash/isEqual';
import { AppMetricEvents } from 'AppMetric';
import { EmptyContentBlock } from 'components';
import { EmptyContentBlockProps } from 'components/EmptyContentBlock/types';
import { useAppRouter } from 'router/context';

type ProductsEmptyProps = Pick<EmptyContentBlockProps, 'customRedirectText' | 'redirectTo'>;

const ProductsEmpty: React.FC<ProductsEmptyProps> = ({ redirectTo, customRedirectText }) => {
    const { location } = useAppRouter();

    React.useEffect(() => {
        if (location?.pathname === '/search') {
            AppMetricEvents.jmart_zerosearch();
        }
    }, []);

    return (
        <EmptyContentBlock
            height="55vh"
            icon="ic_empty_results"
            title="no_products_found"
            description="try_changing_your_search_options"
            redirectText="to_main"
            redirectTo={redirectTo}
            customRedirectText={customRedirectText}
        />
    );
};

export default React.memo(ProductsEmpty, (prev, next) => isEqual(prev, next));
