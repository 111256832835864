import React from 'react';
import styled from 'styled-components';
import { Carousel, Display, FlexBox } from 'pyatka-ui';
import { DepartmentBoxListProps } from './types';
import DepartmentBox from './DepartmentBox';

const DepartmentBoxList: React.FC<DepartmentBoxListProps> = ({
    departments = [],
    isSupermarket = false,
    isLoading = false,
}) => (
    <React.Fragment>
        {/* Mobile */}
        <Display xxl={false} md={true}>
            <StyledCarousel>
                <Carousel
                    showDots={true}
                    scrollAlign="start"
                    carouselType="magnetic"
                    activeDotsColor="block.brand_variant"
                    defaultDotsColor="block.secondary_variant"
                    slidesToShowGroup={{ mobile: 4, tablet: 6, desktop: 6 }}
                >
                    {departments.map((item) => (
                        <DepartmentBox
                            key={item.title}
                            {...item}
                            isLoading={isLoading}
                            isSupermarket={isSupermarket}
                        />
                    ))}
                </Carousel>
            </StyledCarousel>
        </Display>

        {/* Tablet / Desktop */}
        <Display md={false}>
            <FlexBoxList
                mx="-1px"
                gap="20px"
                width="100%"
                overflow="auto"
                justifyContent={isSupermarket ? 'initial' : 'space-between'}
            >
                {departments.map((item) => (
                    <DepartmentBox
                        key={item.title}
                        {...item}
                        isLoading={isLoading}
                        isSupermarket={isSupermarket}
                    />
                ))}
            </FlexBoxList>
        </Display>
    </React.Fragment>
);

const StyledCarousel = styled.div`
    & div[data-testid='dots'] {
        margin: 12px 0 2px;
        & div {
            width: 6px;
            height: 6px;
            margin: 0 4px;
            aspect-ratio: 1/1;
        }
    }
`;

const FlexBoxList = styled(FlexBox)`
    &::-webkit-scrollbar {
        display: none;
    }
`;

export default React.memo(DepartmentBoxList);
