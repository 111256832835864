import React from 'react';
import { Box, FlexBox, Icon, Text } from 'pyatka-ui';

import { flushUserSearchHistory, getUserSearchHistory } from 'data';
import { AppLink } from 'components';
import { AppMetricEvents } from 'AppMetric';
import { useAppRouter } from 'router/context';
import { useI18n } from 'hooks/useI18n';

const UserSearchHistory: React.FC = () => {
    const { $t } = useI18n();
    const { location, queryParams } = useAppRouter();
    const { store_id, company_id } = queryParams;

    /* -------------- Костыль чтобы обновлять данные с localStorage ------------- */
    const [refresh, setRefresh] = React.useState<boolean>(false);
    const [history, setHistory] = React.useState<any[]>([]);

    React.useEffect(() => {
        const historyData: any[] = getUserSearchHistory() || [];
        const newHistory = historyData
            .filter((item) => !!item)
            .filter((item) => !String(item?.link || '').includes('algolia'));
        setHistory(newHistory);
    }, [refresh]);

    const flushHistory = () => {
        setRefresh((prev) => !prev);
        flushUserSearchHistory();
    };

    const handleMetric = (keyword?: string) => {
        AppMetricEvents.jmart_history_search_new({ keyword: keyword || '' });
    };

    const isStore = location?.pathname?.includes('store') || store_id || company_id;
    const isStoreSearch = location?.pathname === '/supermarkets_search';

    if (isStore || isStoreSearch || !history.length) {
        return null;
    }

    return (
        <>
            <FlexBox alignItems="center" justifyContent="space-between" m={2}>
                <Text color="text.secondary">{$t('search_history')}</Text>
                <Box cursor="pointer" onMouseDown={flushHistory}>
                    <Text color="block.brand">{$t('clear_search_history')}</Text>
                </Box>
            </FlexBox>
            <FlexBox flexDirection="column">
                {history.map(({ title, link }: any, idx) => (
                    <AppLink to={link} key={idx} eventType="onMouseDown">
                        <FlexBox
                            px={2}
                            py="12px"
                            alignItems="center"
                            justifyContent="space-between"
                            style={{ borderColor: '#F6F6F5' }}
                            onMouseDown={() => handleMetric(title)}
                            borderBottom={idx + 1 === history.length ? 'none' : '1.5px solid'}
                        >
                            <FlexBox alignItems="center" justifyContent="center">
                                <Box p={1} mr="3" bg="#D9D9D9" display="grid" borderRadius="50%">
                                    <Icon fill="white" width="16px" height="16px" icon="history" />
                                </Box>
                                <Text color="text.main" fontWeight="bold">
                                    {title}
                                </Text>
                            </FlexBox>
                            <Icon
                                width="7px"
                                height="14px"
                                icon="arrowRight"
                                fill="text.secondary"
                            />
                        </FlexBox>
                    </AppLink>
                ))}
            </FlexBox>
        </>
    );
};

export default React.memo(UserSearchHistory);
