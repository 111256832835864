import React from 'react';
import isEqual from 'lodash/isEqual';
import { EmptyContentBlockNewLayout } from 'components/EmptyContentBlock/EmptyContentBlock.NewLayout';
import { EmptyContentBlockLayout } from './EmptyContentBlock.Layout';
import { EmptyContentBlockProps } from './types';

const EmptyContentBlockContainer: React.FC<EmptyContentBlockProps> = (props) => {
    if (props.layout && props.layout === 'new') {
        return <EmptyContentBlockNewLayout {...props} />;
    }
    return <EmptyContentBlockLayout {...props} />;
};

export default React.memo(EmptyContentBlockContainer, (prev, next) => isEqual(prev, next));
