import React from 'react';
import { Box, Breadcrumbs, Text } from 'pyatka-ui';
import { AppLink } from 'components';
import { CategoryPathNames } from 'modules/Categories/types';
import { ProductBreadcrumbsLayoutProps } from './types';

interface IBreadcrumbItem {
    link: CategoryPathNames['link'] | string | null;
    title: CategoryPathNames['title'];
}

const BreadcrumbItem: React.FC<IBreadcrumbItem> = ({ link, title }) => {
    const textProps = { as: 'span', variant: 'h6', itemProp: 'name', color: 'text.secondary' };
    if (link) {
        return (
            <AppLink itemProp="item" to={link}>
                <Text {...textProps}>{title}</Text>
            </AppLink>
        );
    }

    return <Text {...textProps}>{title}</Text>;
};

export const ProductBreadcrumbsLayout: React.FC<ProductBreadcrumbsLayoutProps> = ({
    path_names = [],
    isLoading,
    showTitle = true,
}) => {
    if (!path_names) {
        return null;
    }

    const rest = path_names.slice(0, -1);
    const last = path_names[path_names.length - 1];
    const tempPathNames = showTitle ? rest : [...rest, { ...last, link: null }];
    const moreThanOnce = tempPathNames.length > 1;
    // const breadcrumbsTitle = last?.title;

    return (
        <Box margin={showTitle ? '32px 0' : '20px 0'}>
            {!isLoading && moreThanOnce && (
                <Breadcrumbs>
                    {tempPathNames.map(({ link, title }, idx) => (
                        <BreadcrumbItem link={link} title={title} key={idx} />
                    ))}
                </Breadcrumbs>
            )}
            {isLoading && (
                <Breadcrumbs>
                    {[...Array(3)].map((_el: any, idx: number) => (
                        <Text
                            key={idx}
                            as="span"
                            itemProp="name"
                            skeleton={isLoading}
                            skeletonProps={{ width: '6em', height: '1em' }}
                        />
                    ))}
                </Breadcrumbs>
            )}
            {/* {showTitle && (
                <Text
                    as="h1"
                    variant="h1"
                    fontWeight="700"
                    style={{ marginTop: 8 }}
                    skeleton={isLoading || !breadcrumbsTitle}
                    skeletonProps={{ width: '22%', borderRadius: '12px' }}
                    color={'text.main'}
                >
                    {breadcrumbsTitle}
                </Text>
            )} */}
        </Box>
    );
};
