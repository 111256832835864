import { FetchPageData } from 'router/types';
import { fetchCategories } from 'modules/Categories/Categories.actions';

export const CatalogPageFetch: FetchPageData = ({ dispatch, payload }) => {
    if (!payload?.params?.hasGDS) {
        dispatch(fetchCategories({ id: '2879' }));
    }
    if (!payload?.params?.hasSRVS) {
        dispatch(fetchCategories({ id: '1240' }));
    }
};
