import { FetchPageData } from 'router/types';
import { NewMainPageInit, NewMainPageMenuInit } from 'modules/NewMainPage/NewMainPage.actions';
import { getPromotionModal } from 'modules/Promotions/Promotions.actions';

export const NewMainPageFetch: FetchPageData = ({ dispatch }) => {
    dispatch(getPromotionModal());
    dispatch(NewMainPageInit({ ssr: null }));
    // dispatch(NewMainPageInit({ ssr: __SERVER__ ? 1 : 0 }));
    dispatch(NewMainPageMenuInit());
};
