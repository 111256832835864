import React from 'react';
import { FlexBox, Icon, LoadingIndicator, Text } from 'pyatka-ui';
import { useI18n } from 'hooks/useI18n';

type ActionButtonProps = {
    onClick: () => void;
    flexDirection?: any;
    icon: string;
    title: string;
    isLoading?: boolean;
    width?: string;
    iconBackgroundColor?: string;
};
const ActionButton = (props: ActionButtonProps) => {
    const {
        onClick,
        flexDirection = 'row',
        icon,
        title,
        isLoading = false,
        width = '100%',
        iconBackgroundColor = '#fff',
    } = props;
    const { $t } = useI18n();

    return (
        <FlexBox
            width={width}
            flexDirection={flexDirection}
            flexWrap={'wrap'}
            alignItems={'center'}
            justifyContent={'center'}
            gap={'6px'}
            onClick={onClick}
            cursor={'pointer'}
            minHeight={'50px'}
        >
            {isLoading ? (
                <LoadingIndicator />
            ) : (
                <>
                    <FlexBox
                        width={'50px'}
                        height={'50px'}
                        background={iconBackgroundColor}
                        borderRadius={'50%'}
                        alignItems={'center'}
                        justifyContent={'center'}
                    >
                        <Icon icon={icon} />
                    </FlexBox>
                    <Text
                        textAlign={'center'}
                        color={'text.secondary'}
                        fontSize={{ default: '14px', md: '16px' }}
                        fontWeight={500}
                        lineHeight={'16px'}
                    >
                        {$t(title)}
                    </Text>
                </>
            )}
        </FlexBox>
    );
};

export default ActionButton;
