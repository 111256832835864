import React from 'react';
import { ColorProps, FontSizeProps, FontWeightProps, SpaceProps } from 'styled-system';

export const VARIANTS = ['bonus', 'price', 'credit', 'saves', 'loan', 'transparent'] as const;

export interface ProductCardLabelProps
    extends FontWeightProps,
        FontSizeProps,
        ColorProps,
        SpaceProps {
    variant: (typeof VARIANTS)[number];
    children: React.ReactNode;
    paymentTimes?: string;
    isLoading?: boolean;
    height?: string;
    iconSize?: {
        big?: string;
        small?: string;
    };
    style?: React.CSSProperties;
    icon?: string;
    hasWhiteBg?: boolean;
}

export interface ProductCardLabelLayoutProps extends ProductCardLabelProps {}

// -------------- Styled Components types
export interface IStyledProductLabel {
    variant: ProductCardLabelProps['variant'];
}
