import React from 'react';
import { FlexBox, Icon, Text } from 'pyatka-ui';

const WarningBlock: React.FC<{ text?: string }> = (props) => {
    return (
        <FlexBox
            p="8px"
            gap="10px"
            alignItems="center"
            border="1px solid"
            borderRadius="20px"
            borderColor="background.disabled"
        >
            <Icon icon="sing-info" width="30px" />
            <Text>{props?.text}</Text>
        </FlexBox>
    );
};

export default WarningBlock;
