import React, { useCallback, useMemo } from 'react';
import { SelectBoxOptionProps } from 'pyatka-ui/dist/components/SelectBox/types';
import { Helmet } from 'react-helmet-async';
import { LanguageSelectorLayout } from 'components/LanguageSelector/LanguageSelector.Layout';
import { LanguageSelectorProps } from 'components/LanguageSelector/types';
import { AppI18n } from 'modules/App/types';
import { langOptions } from 'components/LanguageSelector/const';
import { getSelectedLanguageStorage } from 'data';

export const LanguageSelectorContainer: React.FC<LanguageSelectorProps> = (props) => {
    const { setI18nActiveLanguage, activeLanguage } = props;
    const changeLanguage = useCallback(
        (activeLanguage: SelectBoxOptionProps | SelectBoxOptionProps[]) => {
            if ('key' in activeLanguage) {
                const language = activeLanguage?.key as AppI18n['activeLanguage'];
                setI18nActiveLanguage({ language });
            }
        },
        [setI18nActiveLanguage]
    );
    const activeLanguageProps = useMemo(
        () => langOptions.find((lang) => lang.key === activeLanguage) || langOptions[0],
        [activeLanguage]
    );
    const layoutProps = {
        ...props,
        activeLanguageProps,
        changeLanguage,
    };

    return (
        <>
            <Helmet
                htmlAttributes={{
                    lang: getSelectedLanguageStorage(),
                }}
            />
            <LanguageSelectorLayout {...layoutProps} />
        </>
    );
};
