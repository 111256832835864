import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import {
    NewMainPageFailed,
    NewMainPageInit,
    NewMainPageMenuFailed,
    NewMainPageMenuInit,
    NewMainPageMenuSucceed,
    NewMainPageSucceed,
} from 'modules/NewMainPage/NewMainPage.actions';
import { NewMainPageFetch, NewMainPageMenuFetch } from 'modules/NewMainPage/NewMainPage.services';
import { getSelectedCityId } from 'modules/Cities/Cities.selectors';
import { Loader } from 'plugins/loader';
import { getMainPagePrevCityId, getMainPageSsrMode } from './NewMainPage.selectors';
import { NewMainPageInitialState } from './types';

function* NewMainPageInitHandler({
    payload: { ssr = null },
}: PayloadAction<{ ssr: NewMainPageInitialState['ssrMode'] }>) {
    try {
        const selectedCityId: string = yield select(getSelectedCityId);
        const prevCityId: string = yield select(getMainPagePrevCityId);
        const prevSsrMode: NewMainPageInitialState['ssrMode'] = yield select(getMainPageSsrMode);

        if (selectedCityId === prevCityId && ssr === prevSsrMode) {
            return;
        }

        Loader.main.on();

        const params = {
            city_id: selectedCityId,
        };

        if (ssr !== null) {
            // @ts-ignore
            params.ssr = selectedCityId !== prevCityId && prevCityId ? null : ssr;
        }

        const { data } = yield call(NewMainPageFetch, params);
        yield put(
            NewMainPageSucceed({
                data: data.data,
                ssrMode: ssr,
                prevCityId: selectedCityId,
            })
        );
    } catch (e) {
        yield put(NewMainPageFailed());
    } finally {
        Loader.main.off();
    }
}

function* NewMainPageMenuInitHandler() {
    const city_id: string = yield select(getSelectedCityId);
    const prevCityId: string = yield select(getMainPagePrevCityId);

    if (city_id === prevCityId) {
        return;
    }

    try {
        const { data } = yield call(NewMainPageMenuFetch, { city_id });
        yield put(NewMainPageMenuSucceed(data.data));
    } catch (e) {
        yield put(NewMainPageMenuFailed());
    }
}

export default function* () {
    yield all([
        takeLatest(NewMainPageInit, NewMainPageInitHandler),
        takeLatest(NewMainPageMenuInit, NewMainPageMenuInitHandler),
    ]);
}
