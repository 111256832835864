import { CaseReducer, PayloadAction, current } from '@reduxjs/toolkit';
import { getActiveFiltersQueryString, toObjectVariants } from 'modules/ProductsList/utils';
import { ICompany, IProductsReq, MerchantsInitialState, ProductsPayload } from './types';
import initialState from './Merchants.state';

const fetchMerchant: CaseReducer<MerchantsInitialState, PayloadAction<number>> = () => {};

const fetchMerchantSucceed: CaseReducer<MerchantsInitialState, PayloadAction<ICompany>> = (
    state,
    { payload }
) => {
    state.data = payload;
};

const fetchMerchantFailed: CaseReducer<MerchantsInitialState, PayloadAction> = () => {};

const fetchProducts: CaseReducer<MerchantsInitialState, PayloadAction<IProductsReq>> = () => {};

const fetchProductsSucceed: CaseReducer<MerchantsInitialState, PayloadAction<ProductsPayload>> = (
    state,
    { payload }
) => {
    const isFirstPage: boolean = Number(payload.page) === 1;

    // FILTERS
    const filters_object: MerchantsInitialState['filters_object'] = toObjectVariants(
        payload.filters
    );
    const filters_order: MerchantsInitialState['filters_object'] =
        payload.filters.map((f: any) => f.feature_id) || [];

    state.filters_object = filters_object;
    state.filters_order = filters_order;

    // Query string to Object
    const qsObject = Object.fromEntries(new URLSearchParams(payload?.url));
    state.active_filters = getActiveFiltersQueryString(qsObject, filters_object);

    // PRODUCTS
    const newProducts: ProductsPayload['products'] = Array.isArray(payload.products)
        ? payload.products
        : Object.values(payload.products);

    // Infinite scroll/persist Previous products
    if (!isFirstPage && state.products.length > 0 && payload?.infiniteScroll) {
        const previousProducts = current(state.products) || [];
        state.products = [...previousProducts, ...newProducts];
    } else {
        state.products = newProducts;
    }

    state.categories = payload.categories;
    state.parentCategory = payload.parentCategory;
    state.page = payload.page;
    state.total_pages = payload.total_pages;
    state.total_count = payload.total_count;
    state.prevUrl = payload?.url || '';
};

const updateMerchantFilters: CaseReducer<
    MerchantsInitialState,
    PayloadAction<MerchantsInitialState['active_filters']>
> = (state, { payload }) => {
    state.active_filters = payload;
};

const fetchProductsFailed: CaseReducer<MerchantsInitialState, PayloadAction> = () => {};

const merchantFlush: CaseReducer<MerchantsInitialState, PayloadAction> = (state) => {
    // state.data = initialState.data;
    state.categories = initialState.categories;
    state.products = initialState.products;
    state.filters_object = initialState.filters_object;
    state.filters_order = initialState.filters_order;
    state.prevUrl = initialState.prevUrl;
    state.page = initialState.page;
    state.total_pages = initialState.total_pages;
    state.total_count = initialState.total_count;
};

export default {
    fetchMerchant,
    fetchMerchantSucceed,
    fetchMerchantFailed,
    fetchProducts,
    fetchProductsSucceed,
    updateMerchantFilters,
    fetchProductsFailed,
    merchantFlush,
};
