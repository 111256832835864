import styled from 'styled-components/macro';

export const StyledSticky = styled('div')<{ isSticky: boolean }>`
    transition: transform 0.5s;
    visibility: ${(props) => (props.isSticky ? '' : 'hidden')};
    position: ${(props) => (props.isSticky ? 'absolute' : '')};
    width: ${(props) => (props.isSticky ? '100%' : '')};
    bottom: ${(props) => (props.isSticky ? '0' : '')};
    padding: ${(props) => (props.isSticky ? '18px' : '')};
    background-color: ${(props) =>
        props.isSticky ? props?.theme?.colors?.block.main : ''}; //from theme-prop
    left: ${(props) => (props.isSticky ? '0' : '')};
    display: ${(props) => (props.isSticky ? 'flex' : '')};
    justify-content: ${(props) => (props.isSticky ? 'center' : '')};
`;

export const StyledPriceInput = styled('div')`
    display: flex;
    flex-direction: column;
    flex: 1 0 42%;
    font-size: 18px;
    line-height: 23.4px;
    font-weight: 600;
`;

export const StyledPriceInputGap = styled('div')`
    display: flex;
    flex: 0 0 17px;
    justify-content: center;
    align-content: center;
    @media (min-width: 992px) {
        height: 38px;
        flex: 0 0 27px;
    }
`;
