import React from 'react';
import { Button, FlexBox, Icon, Modal, usePyatka } from 'pyatka-ui';
import { AuthorizationModalLayoutProps } from 'components/AuthorizationModal/types';

import AuthSendNumberComponent from 'components/AuthorizationModal/components/AuthSendNumberComponent';
import AuthSendOtpComponent from 'components/AuthorizationModal/components/AuthSendOtpComponent';
import AuthBaseFormComponent from 'components/AuthorizationModal/components/AuthBaseFormComponent';

export const AuthorizationModalLayout: React.FC<AuthorizationModalLayoutProps> = (props) => {
    const { open, onClose, showPinSection, showBaseForm, setShowBaseForm } = props;
    const {
        scales: { isMobile },
    } = usePyatka();

    return (
        <Modal
            open={open}
            onClose={onClose}
            borderRadius={isMobile ? '26px 26px 0 0' : '40px'}
            positionMode={isMobile ? 'mobile' : 'default'}
            width={isMobile ? '100%' : '407px'}
            padding={isMobile ? '18px 24px' : '22px 30px'}
        >
            <Button
                width={'40px'}
                height={'40px'}
                p={0}
                onClick={onClose}
                borderRadius="50%"
                border={'none'}
                backgroundColor={'block.secondary'}
                marginLeft={'auto'}
                mb={{ default: '26px', md: 0 }}
            >
                <Icon fill="text.placeholder" icon={'close'} />
            </Button>

            {showBaseForm ? (
                <AuthBaseFormComponent {...props} />
            ) : (
                <FlexBox flexDirection={'column'} alignItems={'center'}>
                    {!showPinSection ? (
                        <AuthSendNumberComponent {...props} />
                    ) : (
                        <AuthSendOtpComponent {...props} />
                    )}
                </FlexBox>
            )}
            {!__IS_PROD__ && (
                <Button
                    onClick={() => setShowBaseForm(!showBaseForm)}
                    styleType={'outlined'}
                    variant={'brand'}
                    border={'none'}
                    p={'4px 0 0 0'}
                >
                    {showBaseForm ? 'Быстрый вход по OTP' : 'У вас уже есть аккаунт?'}
                </Button>
            )}
        </Modal>
    );
};
