import React from 'react';
import { Icon } from 'pyatka-ui';
import { nativeCall } from 'hybrid/index';
import { isNative } from 'utils/platform';
import { IconProps } from '../types';
import { AppToaster } from 'plugins/app-toaster';

export const ShareBtn: React.FC<IconProps> = ({ fill, icon = 'ic_Share', ...props }) => {
    const onShare = () => {
        if (isNative() && window) {
            nativeCall({
                type: 'share',
                payload: {
                    value: `https://jmart.kz${window?.location?.pathname}${window?.location?.search}`,
                },
            });
        } else {
            navigator.clipboard.writeText(
                `https://jmart.kz${window?.location?.pathname}${window?.location?.search}`
            );
            AppToaster.success({ body: 'Ссылка успешно скопирована' });
        }
    };
    return <Icon icon={icon} width="24px" fill={fill} onClick={onShare} {...props} />;
};
