import React from 'react';
import { Box, Text, Button } from 'pyatka-ui';
import styled from 'styled-components';
import { useI18n } from 'hooks/useI18n';

export const ApplyButton = ({ text = 'apply', onApply }: IApplyButton) => {
    const { $t } = useI18n();
    return (
        <Wrapper>
            <Button
                width="100%"
                height="55px"
                variant="brand"
                onClick={onApply}
                styleType="contained"
                borderRadius="22px"
            >
                <Text fontSize="16px" fontWeight={600} lineHeight="20.48px">
                    {$t(text)}
                </Text>
            </Button>
        </Wrapper>
    );
};

const Wrapper = styled(Box)`
    bottom: 12px;
    left: 24px;
    right: 24px;
    position: absolute;
`;

interface IApplyButton {
    text?: string;
    onApply: () => void;
}
