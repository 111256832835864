import React from 'react';
import { usePyatka } from 'pyatka-ui';
import { useDispatch } from 'react-redux';
import { useCartService } from 'contexts/useCartService';
import { isNative, isQrTrigger, triggerQrModal } from 'utils/platform';
import { Dialog } from 'plugins/dialog';
import { AppCartItemState } from 'contexts/useCartService/types';
import { AlgoliaEvents, GtmEvents } from 'metrics';
import { AppMetricEvents } from 'AppMetric';
import { useAppRouter } from 'router/context';
import { filterCompanyIds } from 'components/OfferBlocks/helper';
import { setProductDetailModalOpen } from 'modules/ProductDetail/ProductDetail.actions';
import ProductCardLayout from './ProductCard.Layout';
import ProductCardLayoutNew from './NewCard/ProductCard.LayoutNew';
import { ProductCardContainerProps } from './types';
import ProductCardLayoutSmall from './SmallCard/ProductCard.LayoutSmall';
import ProductCardLayoutMain from './MainCard/ProductCard.LayoutMain';

const ProductCardContainer: React.FC<ProductCardContainerProps> = (props) => {
    const {
        product_id,
        offer_id,
        url: redux_url,
        type,
        query_id,
        productName,
        price,
        layout = 'old',
        desktopLayout = 'old',
        position,
        metric,
        category_ids = [],
        product_position,
        product_page = 1,
        source = '',
    } = props;

    const dispatch = useDispatch();
    const { cartEdit, cartItemsByProduct } = useCartService();
    const { scales } = usePyatka();
    const { queryParams } = useAppRouter();

    const isSupermarketCard = type === 'G' && scales?.isMobile;
    const isZeroPrice = price === 0;

    const url = React.useMemo(() => {
        const company_ids = filterCompanyIds(queryParams || {}) || {};
        if (queryParams?.company_id) {
            return {
                ...redux_url,
                query: { ...(redux_url?.query || {}), company_id: queryParams?.company_id },
                exact: true,
            };
        }

        return Object.keys(company_ids).length
            ? { ...redux_url, query: { ...(redux_url?.query || {}), ...company_ids }, exact: true }
            : redux_url;
    }, [queryParams, redux_url]);

    // First offer in cart
    const inCartOffer: AppCartItemState | undefined = React.useMemo(() => {
        const offers = cartItemsByProduct(String(product_id)) || [];
        if (offers?.length) {
            return offers[0];
        }
    }, [cartItemsByProduct, product_id]);

    // If exists in cart
    const isInCart: boolean = React.useMemo(
        () => Boolean(inCartOffer && inCartOffer.amount > 0 && inCartOffer.offer_id),
        [inCartOffer]
    );

    const algoliaHandler = () => {
        if (query_id && position) {
            AlgoliaEvents.sendEvent({
                eventType: 'click',
                eventName: 'Product Clicked',
                objectIDs: [product_id.toString()],
                queryID: query_id,
                positions: [position],
            });
        }
    };

    const handleClickProduct = (_card?: string) => {
        // window.history.pushState(null, '', generateRouteTO(url));
        if (scales?.isMobile && isSupermarketCard) {
            dispatch(
                setProductDetailModalOpen({
                    isOpen: true,
                    data: {
                        type,
                        query_id,
                        productName,
                        url: props.url,
                        label: props.label,
                        avgRate: props.avgRate,
                        offer_id: props.offer_id,
                        productId: props.productId,
                        image_urls: props.image_urls,
                        totalRecords: props.totalRecords,
                        category_ids: props.category_ids,
                        jdv_labels:
                            props.jdv_labels || [props?.promo_labels_admin?.text || ''] || [],
                        price_formatted: props.price_formatted,
                        discountPrice_formatted: props.discountPrice_formatted,
                        price,
                    },
                })
            );
        }
        const category_id = category_ids[category_ids?.length - 1];
        GtmEvents.selectItem(
            {
                title: productName,
                price,
                product_id,
                item_category: category_id,
            },
            source
        );
        algoliaHandler();
    };

    const addToCartHandler = React.useCallback(
        (e: React.MouseEvent<HTMLElement>) => {
            e?.stopPropagation();
            if (!isNative()) {
                AppMetricEvents.jmart_web_cart_click({
                    item: { product_id, offer_id, productName, type, price },
                });
                if (query_id || queryParams?.query_id) {
                    AlgoliaEvents.sendEvent({
                        eventType: 'conversion',
                        eventName: 'Product Purchased',
                        objectIDs: [String(product_id)],
                        queryID: String(query_id || queryParams?.query_id),
                    });
                }
            }

            // --- В супермаркетах add_to_cart_click не должен срабатывать
            if (metric && metric?.add_to_cart_click && type !== 'G') {
                metric.add_to_cart_click({ product_id, product_name: productName });
            }

            if (!offer_id) {
                return;
            }
            if (isQrTrigger) {
                triggerQrModal(url);
                return;
            }
            if (isInCart) {
                return;
            }

            if (type === 'G') {
                if (metric && metric?.add_to_cart) {
                    const [category1, category2, category3] = category_ids || [];
                    metric.add_to_cart({
                        product_id,
                        product_name: productName,
                        price: String(price),
                        category1: category1 || null,
                        category2: category2 || null,
                        category3: category3 || null,
                        jdv_labels:
                            props.jdv_labels || [props?.promo_labels_admin?.text || ''] || [],
                    });
                }

                cartEdit({ product_id, offer_id, amount: 1 });

                AlgoliaEvents.sendEvent({
                    eventType: 'conversion',
                    eventName: 'Product Purchased',
                    objectIDs: [product_id.toString()],
                    queryID: query_id,
                });
                return;
            }

            const category_id = category_ids[category_ids?.length - 1];

            Dialog.openDialog({
                body: 'OfferBlocks',
                title: 'choose_merchants',

                config: {
                    bottomSheet: true,
                    noPadding: scales?.isMobile,
                    maxWidth: '471px',
                    showMobileTitle: false,
                },
                props: {
                    product_id,
                    offer_id,
                    isOnlyOne: true,
                    isCounter: false,
                    bgColor: 'block.main',
                    query_id,
                    queryParams,
                    category_id,
                    isModal: true,
                    productName,
                    addToCartMetric: metric?.add_to_cart,
                },
            });
        },
        [
            cartEdit,
            category_ids,
            isInCart,
            metric,
            offer_id,
            price,
            productName,
            product_id,
            props.jdv_labels,
            props.promo_labels_admin,
            queryParams,
            query_id,
            scales?.isMobile,
            type,
            url,
        ]
    );

    const handleMetric = () => {
        if (metric && metric.product_click) {
            const [category1, category2, category3] = category_ids || [];
            metric.product_click({
                product_id,
                product_name: productName,
                category1: category1 || null,
                category2: category2 || null,
                category3: category3 || null,
                jdv_labels: props.jdv_labels || [props?.promo_labels_admin?.text || ''] || [],
                product_position,
                product_page,
            });
        }
    };

    const cardLayout = scales?.isMobile ? layout : desktopLayout;

    if (cardLayout === 'main') {
        return (
            <ProductCardLayoutMain
                {...props}
                url={url}
                isInCart={isInCart}
                isZeroPrice={isZeroPrice}
                firstOfferInCart={inCartOffer}
                isPreventLink={isSupermarketCard}
                addToCartHandler={addToCartHandler}
                algoliaHandler={algoliaHandler}
                handleClickProduct={handleClickProduct}
                handleMetric={handleMetric}
            />
        );
    }

    if (cardLayout === 'new') {
        return (
            <ProductCardLayoutNew
                {...props}
                url={url}
                isInCart={isInCart}
                isZeroPrice={isZeroPrice}
                firstOfferInCart={inCartOffer}
                isPreventLink={isSupermarketCard}
                addToCartHandler={addToCartHandler}
                algoliaHandler={algoliaHandler}
                handleClickProduct={handleClickProduct}
                handleMetric={handleMetric}
            />
        );
    }

    if (cardLayout === 'small') {
        return (
            <ProductCardLayoutSmall
                {...props}
                url={url}
                isInCart={isInCart}
                isZeroPrice={isZeroPrice}
                firstOfferInCart={inCartOffer}
                isPreventLink={isSupermarketCard}
                addToCartHandler={addToCartHandler}
                algoliaHandler={algoliaHandler}
                handleClickProduct={handleClickProduct}
                handleMetric={handleMetric}
            />
        );
    }

    return (
        <ProductCardLayout
            {...props}
            url={url}
            isInCart={isInCart}
            isZeroPrice={isZeroPrice}
            firstOfferInCart={inCartOffer}
            isPreventLink={isSupermarketCard}
            addToCartHandler={addToCartHandler}
            algoliaHandler={algoliaHandler}
            handleClickProduct={handleClickProduct}
            handleMetric={handleMetric}
        />
    );
};

export default React.memo(ProductCardContainer);
