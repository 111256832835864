import { AppRouteMiddlewareLayer } from 'router/types';
import CheckoutDeliveryV2Page from 'pages/CheckoutDeliveryV2Page';

const isCheckoutV2Page: AppRouteMiddlewareLayer = (config) => {
    const { route, isClientDebug } = config;

    // console.log('IS CLIENT DEBUG : ', isClientDebug);

    if (route.name === 'checkout-delivery') {
        if (isClientDebug) {
            return {
                ...config,
                route: {
                    ...route,
                    component: CheckoutDeliveryV2Page,
                },
            };
        }

        return config;
    }

    return config;
};

export { isCheckoutV2Page as default };
