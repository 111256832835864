import React, { Children } from 'react';
import { Display, FlexBox, Icon, Text } from 'pyatka-ui';
import { useI18n } from 'hooks/useI18n';
import { DeliveryPriceLayoutProps } from './types';

export const DeliveryPriceLayout: React.FC<DeliveryPriceLayoutProps> = (props) => {
    const { isFree, children, HLine } = props;
    const color = isFree
        ? ['status.success', '#FFF', 'status.success']
        : ['text.secondary', '#FFF', 'text.secondary'];
    const { $t } = useI18n();

    return (
        <FlexBox alignItems="center">
            <Display md={false}>
                <Icon fill={color} icon="shipping" width={'17px'} height={'14px'} />
            </Display>
            <Display md={true} xxl={false}>
                <Icon fill={color} icon="shipping" width={'13px'} height={'11px'} />
            </Display>
            <Text
                variant="h8"
                color={color[0]}
                margin="0 7px"
                fontWeight={500}
                fontSize={{ default: '1em', md: '15px' }}
            >
                {Children.toArray(children).map((c) => $t(c.toString()))}
            </Text>
            {HLine && <FlexBox height="1.5px" flex="1" bg="block.disabled" />}
        </FlexBox>
    );
};
