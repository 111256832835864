import { useCallback, useEffect, useMemo, useState } from 'react';
import { AxiosPromise } from 'axios';
import isBoolean from 'lodash/isBoolean';
import { useDispatch } from 'react-redux';
import {
    CheckoutDataHookOutput,
    CheckoutDeliveryPageData,
} from 'pages/CheckoutDeliveryV2Page/types';
import {
    GetCheckoutDeliveryData,
    GetCheckoutDeliveryDataV2,
} from 'pages/CheckoutDeliveryV2Page/services';
import {
    cartDataFromOrderResponse,
    cartDataFromProductResponse,
    checkoutDataReformat,
    checkoutReqPayloadPrepare,
} from 'pages/CheckoutDeliveryV2Page/helper';
import { CartServiceGet, CartServiceGetV3 } from 'modules/CartService/CartService.services';
import priceFormatter from 'utils/priceFormatter';
import { CheckoutDeliveryPageConnectProps } from 'pages/CheckoutDeliveryV2Page/CheckoutDeliveryPage.Connect';
import { SetAppError } from 'modules/App/App.actions';
import { AppMetricEvents } from 'AppMetric';
import { fetchProductByOfferId } from 'modules/ProductDetail/ProductDetail.services';
import { CheckoutDeliveryPageHookShippingData } from 'pages/CheckoutDeliveryV2Page/CheckoutDeliveryPage.HookShippingData';
import { OrdersServiceSimpleService } from 'modules/OrdersService/OrdersService.services';
import { OrderItem } from 'modules/OrdersService/types';

export const CheckoutDeliveryPageHookCheckoutData = (
    props: {
        params: any;
        coupon: string;
        query: any;
    } & CheckoutDeliveryPageConnectProps
): CheckoutDataHookOutput => {
    const {
        delivery_type,
        setDeliveryType,
        setShippingCompany,
        setShippingIntervalDate,
        setShippingIntervalTime,
        setStoreLocationId,
        setPostomatId,
        setIntervalData,
        shipping_selected_data,
        pickup_selected_data,
        postomat_selected_data,
    } = CheckoutDeliveryPageHookShippingData();
    const { params, coupon = '', profileServiceData, query } = props;
    //----------------------------------------------------------------------- state
    const [promocode, setPromocode] = useState<string>(coupon);
    const [deliveryComment, setDeliveryComment] = useState<string>('');

    const [checkoutResponse, setCheckoutResponse] = useState<any>({});
    const [cartResponse, setCartResponse] = useState<any>({});
    const [reqStatus, setReqStatus] = useState<CheckoutDataHookOutput['reqStatus']>('initial');

    const checkout_data = useMemo((): CheckoutDeliveryPageData | null => {
        const data = checkoutDataReformat({
            checkoutData: checkoutResponse,
            cartData: cartResponse,
        });

        // if (data?.delivery_types.length > 0 && delivery_type === null) {
        //     setDeliveryType(data?.delivery_types[0]); fix
        // }

        if (data?.filtered_delivery_types?.length === 1 && delivery_type === null) {
            setDeliveryType(data?.filtered_delivery_types[0]);
        }

        // @ts-ignore
        const shipping_selected = shipping_selected_data(data);
        // @ts-ignore
        const pickup_selected = pickup_selected_data(data);

        const postomat_selected = postomat_selected_data(data);

        const is_delivery = delivery_type === 'shipping';
        const is_delivery_valid_address =
            data?.chosen_profile_id === profileServiceData?.addressDefault?.profile_id;
        const total =
            // @ts-ignore
            parseInt(data?.prices.total || 0) +
            // @ts-ignore
            parseInt(is_delivery ? shipping_selected.price || 0 : 0);
        const base_total = data?.cart?.base_total;
        const discount = data?.prices.discount + (data.cart?.discount || 0);
        const basket = checkoutResponse?.company?.basket_restriction;
        const basket_restriction = basket
            ? {
                  min_basket_approved: basket?.min_basket,
                  amount: basket.min_basket_amount,
                  amount_formatted: priceFormatter(basket.min_basket_amount) || '',
                  remain: basket.remain_amount,
                  remain_formatted: priceFormatter(basket.remain_amount) || '',
              }
            : null;

        const result = {
            ...data,
            basket_restriction,
            delivery_type,
            shipping_selected,
            pickup_selected,
            postomat_selected,
            //
            is_delivery,
            is_delivery_valid_address,

            calculated_prices: {
                total,
                total_price: priceFormatter(total) || '',
                base_total,
                base_total_price: priceFormatter(base_total) || '',
                delivery: shipping_selected.price || 0,
                delivery_price: priceFormatter(shipping_selected.price) || '',
                discount: discount,
                discount_price: priceFormatter(discount) || '',
            },
        };
        return reqStatus === 'fetched' ? result : null;
    }, [
        checkoutResponse,
        cartResponse,
        delivery_type,
        shipping_selected_data,
        pickup_selected_data,
        postomat_selected_data,
        profileServiceData?.addressDefault?.profile_id,
        reqStatus,
        setDeliveryType,
    ]);

    //----------------------------------------------------------------------- Request Payloads
    const checkoutPayload = useMemo(() => {
        const payload = checkoutReqPayloadPrepare({ params, query });
        return { ...payload, coupon_code: promocode };
    }, [query, params, promocode]);

    const cartPayload = useMemo(() => {
        return { cart_hash: params?.checkout_id };
    }, [params?.checkout_id]);

    const productPayload = useMemo(() => {
        return params?.checkout_id;
    }, [params?.checkout_id]);

    const orderPayload = useMemo(() => {
        return { order_id: params?.checkout_id };
    }, [params?.checkout_id]);

    //----------------------------------------------------------------------- Request ALL
    const fetchAll = useCallback(
        (props: {
            cartPayload: any;
            checkoutPayload: any;
            productPayload: any;
            orderPayload: any;
        }) => {
            setReqStatus('loading');
            GetCheckoutDeliveryDataV2(props?.checkoutPayload)
                .then((res) => res.data.data)
                .then((data) => {
                    setCheckoutResponse(data);
                    setCartResponse({ groups: { id: data?.basket } });
                    setReqStatus('fetched');
                })
                .catch(() => {
                    console.log('????');
                    setReqStatus('error');
                });
        },
        []
    );
    //----------------------------------------------------------------------- Effect
    useEffect(() => {
        if (!profileServiceData?.loading) {
            fetchAll({ checkoutPayload, cartPayload, productPayload, orderPayload });
        }
    }, [
        productPayload,
        checkoutPayload,
        cartPayload,
        fetchAll,
        profileServiceData?.loading,
        orderPayload,
    ]);

    //-------------------------------------------------- OUTPUT
    return {
        checkout_data,
        setDeliveryType,
        reqStatus,
        setReqStatus,
        setPromocode,
        promocode,
        //
        deliveryComment,
        setDeliveryComment,

        setShippingCompany,
        setShippingIntervalDate,
        setShippingIntervalTime,
        setStoreLocationId,
        setIntervalData,
        setPostomatId,
    };
};
