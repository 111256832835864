import React from 'react';
import { Box, Button, Icon } from 'pyatka-ui';
import { useDispatch } from 'react-redux';
import { isNative } from 'utils/platform';
import { SearchBar } from 'components';
import LanguageSelector from 'components/LanguageSelector';
import HeaderUserLocation from 'components/HeaderUserLocation';
import { setIsClosingJmart } from 'modules/App/App.actions';
import { HeaderWrapper } from '../components';

const MainHeader: React.FC = () => (
    <HeaderWrapper overflow="visible" gap="10px" height="48px">
        {!isNative() && <LanguageSelector />}
        <HeaderUserLocation layout="main" />

        <Box width="100%" py="40px">
            <SearchBar dumb_layout secondary />
        </Box>

        {isNative() && <CloseButton />}
    </HeaderWrapper>
);

const CloseButton = () => {
    const dispatch = useDispatch();

    const handleClose = () => {
        dispatch(setIsClosingJmart(true));
    };

    return (
        <div>
            <Button
                p="0px"
                width="40px"
                height="40px"
                border="none"
                bg="block.secondary"
                borderRadius="999rem"
                onClick={handleClose}
            >
                <Icon icon="close" fill="#AAADB2" width="12px" />
            </Button>
        </div>
    );
};

export default React.memo(MainHeader);
