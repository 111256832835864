import React from 'react';
import { Box, Image, LoadingIndicator, Text, usePyatka } from 'pyatka-ui';
import { AuthInput, SubmitButton } from 'components/AuthorizationModal/AuthorizationModal.Styles';
import { AuthSendNumberComponentProps } from 'components/AuthorizationModal/types';
import { useI18n } from 'hooks/useI18n';
import card from 'shared/assets/card.png';

const AuthSendNumberComponent = (props: AuthSendNumberComponentProps) => {
    const {
        form,
        formSubmitHandler,
        formChangeHandler,
        isLoadingAuth,
        errors,
        phoneChangeHandler,
    } = props;
    const { $t } = useI18n();
    const {
        scales: { isMobile },
    } = usePyatka();
    return (
        <>
            <Box mb={12}>
                <Image width={150} height={150} src={card} />
            </Box>
            <Text
                textAlign={'center'}
                fontWeight={600}
                fontSize={{ default: 24, md: 28 }}
                mb={'8px'}
            >
                {$t('enter_phone_number')}
            </Text>
            <Text
                textAlign={'center'}
                color={'text.secondary'}
                fontWeight={600}
                fontSize={{ default: 16, md: 16 }}
                mb={{ default: 20, md: 24 }}
                style={{ whiteSpace: 'pre-wrap' }}
            >
                {$t('use_for_bonuses_app').addBreakLineInText({
                    afterWordIndex: 2,
                })}
            </Text>

            <form onSubmit={formSubmitHandler} style={{ width: '100%' }}>
                <Box p={{ default: 0, md: '0 10px' }}>
                    <AuthInput
                        isMobile={isMobile}
                        value={form?.phone}
                        placeholder={'+7'}
                        name={'phone'}
                        autoFocus={true}
                        p={0}
                        onChange={phoneChangeHandler}
                        fontSize={'40px'}
                        mb={24}
                        type={'tel'}
                        color={'text.main'}
                    />
                </Box>

                <SubmitButton
                    isMobile={isMobile}
                    borderRadius={24}
                    width={'100%'}
                    height={'60px'}
                    variant="brand"
                    padding={'19px'}
                    styleType="contained"
                    disabled={isLoadingAuth || errors?.phone || !form?.phone}
                    mb={0}
                >
                    {isLoadingAuth ? <LoadingIndicator size={'1em'} /> : $t('get_sms_code')}
                </SubmitButton>
            </form>
        </>
    );
};

export default AuthSendNumberComponent;
