import React from 'react';
import { Col, Row } from 'pyatka-ui';
import { SupermarketCard } from 'components';

export const SupermarketCardsLayoutSkeleton: React.FC<any> = () => (
    <div style={{ overflow: 'hidden' }}>
        <Row xs={1} lg={3} style={{ marginRight: -10, marginLeft: -10 }}>
            {[1, 2, 3].map((supermarket: any, idx: number) => (
                <Col key={`${supermarket.companyId}-${idx}`} style={{ padding: 10 }}>
                    {/*// @ts-ignore*/}
                    <SupermarketCard skeleton={true} />
                </Col>
            ))}
        </Row>
    </div>
);
