import React from 'react';
import dayjs from 'dayjs';
import { Box, FlexBox, Icon, Image, Text, Modal } from 'pyatka-ui';
import { AppLink, ProductLabel, Rating, VerificationModal } from 'components';
import { useI18n } from 'hooks/useI18n';
import { useCartService } from 'contexts/useCartService';
import { StoreInfoProps } from './types';

export const formatDay = (date: string) => {
    switch (date) {
        case dayjs().format('D MMMM'):
            return 'today';
        case dayjs().add(1, 'day').format('D MMMM'):
            return 'tomorrow';
        case dayjs().add(2, 'day').format('D MMMM'):
            return 'day_after_tomorrow';
        default:
            return date;
    }
};

const StoreInfo: React.FC<StoreInfoProps> = ({
    name,
    address,
    deliveryCity,
    deliveryDate,
    rating,
    image = '',
    company_url = '',
    bonus,
    hideDeliveryInfo = false,
    cart_items,
    onDeleteCartClick,
}) => {
    const { $t } = useI18n();

    const showDeleteGroupButton = Array.isArray(cart_items) && Boolean(cart_items.length > 1);

    return (
        <>
            <FlexBox justifyContent="space-between" alignItems="center">
                <Box>
                    <FlexBox alignItems="center" gap="10px">
                        <AppLink to={company_url}>
                            <Text
                                // mt="1px"
                                variant="h5"
                                fontWeight="600"
                                lineHeight="21px"
                                color="text.secondary"
                                lineClamp={1}
                            >
                                {name}
                            </Text>
                        </AppLink>
                        {bonus && <ProductLabel variant={'bonus'}> {bonus} </ProductLabel>}
                        <FlexBox alignItems={'center'}>
                            <Icon width={'12px'} height={'12px'} icon={'star-filled'} />
                            &nbsp;
                            <Text color="text.main">{rating?.toFixed(1)}</Text>
                        </FlexBox>

                        {/*{rating && <Rating rating={rating} />}*/}
                    </FlexBox>

                    {deliveryCity && deliveryDate && !hideDeliveryInfo && (
                        <FlexBox alignItems="center" color="text.secondary" fontSize={2} mt="3px">
                            <Text color={'text.placeholder'} mr="5px">
                                {$t('delivery_from_c')}
                                {deliveryCity}
                            </Text>
                            <Icon width="8px" height="6.5px" icon="arrowRightSmall" />
                            <Text color={'text.placeholder'} ml="5px" title={deliveryDate}>
                                {$t(formatDay(deliveryDate))}
                            </Text>
                        </FlexBox>
                    )}

                    {address && (
                        <FlexBox mt="2px">
                            <Text color="text.placeholder" fontSize={2}>
                                {address}
                            </Text>
                        </FlexBox>
                    )}
                </Box>
                {/*<Image src="https://via.placeholder.com/150x150" />*/}
                {/*{image && (*/}
                {/*    <AppLink to={company_url}>*/}
                {/*        <Box width="35px">*/}
                {/*            <Image src={image} borderRadius="50%" />*/}
                {/*        </Box>*/}
                {/*    </AppLink>*/}
                {/*)}*/}
                {showDeleteGroupButton && (
                    <Box>
                        <Icon
                            width={'24px'}
                            height={'24px'}
                            icon={'cart-bin'}
                            onClick={() => onDeleteCartClick({ cart_items })}
                        />
                    </Box>
                )}
            </FlexBox>
        </>
    );
};

export default StoreInfo;
