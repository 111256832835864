import React from 'react';
import { Box, FlexBox, Icon } from 'pyatka-ui';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { getCollectionById, getCollectionTitle } from 'modules/Wishlist/Wishlist.selectors';
import { Dialog } from 'plugins/dialog';
import CollectionEditModal from 'pages/WishlistCollectionPage/components/CollectionEditModal';
import { GoBackBtn, ShareBtn, HeaderWrapper, Title } from '../components';

const WishlistCollectionHeader: React.FC = () => {
    const { collection_id }: any = useParams();

    const title = useSelector((state) => getCollectionTitle(state, collection_id));
    const collection = useSelector((state) => getCollectionById(state, collection_id));
    const onClickMoreBtn = () => {
        Dialog.openDialog({
            body: CollectionEditModal,
            props: {
                onClose: () => {
                    Dialog.closeDialog();
                },
                collection,
            },
            isOwnModal: true,
        });
    };
    return (
        <HeaderWrapper>
            <GoBackBtn fill="#201F1F" />
            <Title title={title || ''} />

            <FlexBox ml={1} gap="10px">
                <ShareBtn fill="#201F1F" />
                <Box onClick={onClickMoreBtn}>
                    <Icon icon={'more-horizontal'} />
                </Box>
            </FlexBox>
        </HeaderWrapper>
    );
};

export default React.memo(WishlistCollectionHeader);
