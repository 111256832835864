import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { usePyatka } from 'pyatka-ui';
import { Dialog } from 'plugins/dialog';
import {
    deleteGWProfileAddress,
    updateGWProfileAddress,
} from 'modules/UserProfileService/UserProfileService.actions';

import {
    FormFieldsInitialData,
    profileAddressArray,
} from 'modules/UserProfileService/ProfileServiceConfigs';
import { ProfileAddressAddBody, ProfileAddressEditBody } from 'modules/UserProfileService/types';
import { useLoader } from 'contexts/useLoader';
import { useI18n } from 'hooks/useI18n';
import { isNative } from 'utils/platform';
import { nativeAlert } from 'hybrid';
import { AppToaster } from 'plugins/app-toaster';
import { ProfileAddressListContainerProps, ProfileAddressOfList } from './types';
import { ProfileAddressListLayout } from './ProfileAddressList.Layout';

export const ProfileAddressListContainer: React.FC<ProfileAddressListContainerProps> = (props) => {
    const { profileServiceData, toggleCloseBtn } = props;
    const { addresses } = profileServiceData;
    const dispatch = useDispatch();
    const { indicators } = useLoader();
    const { scales } = usePyatka();
    const isMobile = (scales.matched || []).find((el: any) => el.name === 'md');
    const isLoading = useMemo(
        () => indicators?.profileAddresses?.status,
        [indicators?.profileAddresses?.status]
    );
    const profileAddresses = useMemo(() => {
        // list
        return (addresses || []).map((item: any) => ({
            name: item.name,
            profile_id: item.profile_id,
            fields: item.values,
            is_default: item.is_default,
        }));
    }, [addresses]);
    const { $t } = useI18n();

    //    List of Infos
    const getAddressDescription = (address: any) => {
        return profileAddressArray
            .map((addr) =>
                address?.fields[addr.field_name]?.value
                    ? `${addr.value} ${address?.fields[addr.field_name]?.value}`
                    : ''
            )
            .filter((val) => val !== '')
            .join(', ');
    };

    const addressInfos = useMemo(
        (): ProfileAddressOfList[] =>
            profileAddresses.map((address: any) => ({
                // name: address.name || '',
                id: address.profile_id,
                // description: address.fields.s_address.value,
                city: address.fields.s_city.value.title,
                description: getAddressDescription(address),
                is_default: address.is_default,
            })),
        [profileAddresses]
    );

    const addressForm = useCallback(
        (
            profile_id: number,
            withTitle: boolean
        ): ProfileAddressEditBody | ProfileAddressAddBody | null => {
            // one of list to edit
            const address = profileAddresses.find(
                (address: any) => address.profile_id === profile_id
            );

            if (address) {
                const fields = Object.keys(address.fields).map((key: any) => {
                    const field = {
                        field_id: address.fields[key].field_id,
                        value: address.fields[key].value,
                        title: '',
                    };
                    if (withTitle) {
                        field.title = address.fields[key].title;
                    }

                    return field;
                });
                const res = {
                    profile_id: profile_id,
                    name: address.name,
                    fields: [...fields],
                    is_default: true,
                };
                if (!address.name) {
                    res.name = address.fields?.s_address?.value;
                }
                return res;
            }
            return null;
        },
        [profileAddresses]
    );

    useEffect(() => {
        toggleCloseBtn(true);
    }, [toggleCloseBtn]);

    // Layout > Button
    const addressFileds = {
        ...FormFieldsInitialData,
    };
    const addNewAddress = () => {
        Dialog.openDialog({
            title: '',
            swipeable: true,
            body: 'ProfileAddressForm',
            props: {
                address: addressFileds,
                backButtonInitial: 1,
            },
            config: {
                bottomSheet: true,
                noPadding: !isMobile,
                closeButton: false,
            },
        });
    };

    // Item > Row
    const setDefaultAddress = (profile_id: ProfileAddressOfList['id']) => {
        if (profile_id) {
            const address = addressForm(profile_id, false);
            dispatch(updateGWProfileAddress(address as ProfileAddressEditBody));
            Dialog.closeDialog();
        } else {
            AppToaster.warning({ body: 'choose_address' });
        }
    };

    // Item > FormField
    const editExistAddress = (profile_id: ProfileAddressOfList['id']) => {
        const address = addressForm(profile_id, true);
        const addressFields: { [key: string]: string } = {};
        address &&
            address.fields.map((field) => {
                addressFields[field.field_id] = field.value;
            });
        Dialog.openDialog({
            title: '',
            body: 'ProfileAddressForm',
            swipeable: true,
            props: {
                address: addressFields,
                profile_id,
                isEdit: true,
                backButtonInitial: 0,
            },
            config: {
                bottomSheet: true,
                noPadding: !isMobile,
                closeButton: false,
            },
        });
    };

    // Item > FormField
    const deleteExistAddress = (profile_id: ProfileAddressOfList['id']) => {
        if (isNative()) {
            const res: any = nativeAlert({
                closable: false,
                title: $t('Подтвердите'),
                message: $t('Вы уверены, что хотите удалить выбранный адрес?'),
                actions: [
                    {
                        title: $t('yes'),
                        value: 'yes',
                    },
                    {
                        title: $t('no'),
                        value: 'no',
                    },
                ],
            });
            const result = res?.response?.action_result;
            if (result === 'yes') {
                dispatch(deleteGWProfileAddress({ profile_id }));
            }
            return;
        }
        dispatch(deleteGWProfileAddress({ profile_id }));
    };
    const [activeAddress, setActiveAddress] = useState<ProfileAddressOfList | null>(null);
    useEffect(() => {
        setActiveAddress(addressInfos.find((address) => address.is_default) || null);
    }, [addressInfos]);
    const onSetActiveAddress = (address: ProfileAddressOfList) => {
        setActiveAddress(address);
    };
    const layoutProps = {
        isLoading,
        addressInfos,
        addNewAddress,
        setDefaultAddress,
        editExistAddress,
        deleteExistAddress,
        activeAddress,
        onSetActiveAddress,
        isMobile,
    };
    return (
        <>
            <ProfileAddressListLayout {...layoutProps} />
        </>
    );
};
