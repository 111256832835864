import { connect, ConnectedProps } from 'react-redux';
import { AppRootState } from 'store/types';
import { getCities, getSelectedCityData, getSelectedCityId } from 'modules/Cities/Cities.selectors';
import { setSelectedCity } from 'modules/Cities/Cities.actions';

const mapState = (state: AppRootState) => ({
    cities: getCities(state),
    selectedCityId: getSelectedCityId(state),
    selectedCityData: getSelectedCityData(state),
});

const mapDispatch = {
    setSelectedCity,
};

export const HeaderUserLocationConnector = connect(mapState, mapDispatch);
export type HeaderUserLocationConnectProps = ConnectedProps<typeof HeaderUserLocationConnector>;
