import React from 'react';
import { Box, FlexBox, Icon, Text, usePyatka } from 'pyatka-ui';
import { useI18n } from 'hooks/useI18n';

interface DeliveryTypeBlockProps {
    icon: string;
    title: string;
    onClick: () => void;
    isActive?: boolean;
}

const DeliveryTypeBlock = (props: DeliveryTypeBlockProps) => {
    const { icon, title, onClick, isActive = false } = props;
    const { $t } = useI18n();
    const { scales } = usePyatka();
    const isMobile = (scales.matched || []).find((el: any) => el.name === 'md');
    return (
        <FlexBox
            width={'100%'}
            maxWidth={'50%'}
            height={'58px'}
            p={{ default: '10px 12px 5px', md: '14px 10px 12px' }}
            backgroundColor={isActive ? '#FFF2ED' : 'block.secondary'}
            borderRadius={'14px'}
            flexDirection={isMobile ? 'column' : 'row'}
            alignItems={isMobile ? '' : 'center'}
            gap={isMobile ? '4px' : '12px'}
            onClick={onClick}
            cursor={'pointer'}
        >
            <Box width={{ default: '24px', md: '32px' }} height={{ default: '24px', md: '32px' }}>
                <Icon icon={icon} width={'100%'} height={'100%'} />
            </Box>
            <Text fontSize={{ default: 12, md: 16 }} lineHeight={'15.36px'}>
                {$t(title)}
            </Text>
        </FlexBox>
    );
};

export default DeliveryTypeBlock;
