import { connect, ConnectedProps } from 'react-redux';
import { AppRootState } from 'store/types';
import {
    SetAgeVerificationModalStatus,
    SetCategoryVerification,
    UnSetCategoryVerification,
} from 'modules/App/App.actions';
import { fetchCategories } from 'modules/Categories/Categories.actions';
import { getAgeVerificationModalStatus, getCategoryVerification } from 'modules/App/App.selectors';

const mapState = (state: AppRootState) => ({
    ageVerificationModal: getAgeVerificationModalStatus(state),
    categoryVerification: (id: any) => getCategoryVerification(id, state),
});
const mapDispatch = {
    SetAgeVerificationModalStatus,
    fetchCategories,
    SetCategoryVerification,
    UnSetCategoryVerification,
};

export const AgeVerificationModalConnector = connect(mapState, mapDispatch);
export type AgeVerificationModalConnectProps = ConnectedProps<typeof AgeVerificationModalConnector>;
