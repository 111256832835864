import { ApiGwRequest } from 'services';

export const fetchI18nTranslations = () => {
    return ApiGwRequest({
        url: 'dictionary/v1/translation',
        method: 'get',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    });
};

export const getSegmentByAppsflyer = (id: string) => {
    return ApiGwRequest({
        url: `appsflyer/v1/segment-by-appsflyer/${id}`,
        method: 'get',
        timeout: 500,
    });
};
