import { createSelector } from 'reselect';
import { ProductsParser } from 'modules/ProductsList/ProductsList.helpers';
import initialState from 'modules/Wishlist/Wishlist.state';
import { IWishlistCollection, WishlistInitialState } from 'modules/Wishlist/types';
import { CollectionBaseParser } from 'modules/Wishlist/utils';
import priceFormatter from 'utils/priceFormatter';

const getReducer = (state: any) => state.WishlistReducer || initialState;

const getWishlistState = createSelector([getReducer], (state: WishlistInitialState) => state);

export const getWishlistProducts = createSelector([getWishlistState], (state) => {
    return state.wishlistProducts ? ProductsParser(state.wishlistProducts) : [];
});

export const getWishlistPagination = createSelector([getWishlistState], (state) => {
    return state?.pagination;
});

export const getWishlistCount = createSelector([getWishlistPagination], (pagination) => {
    return pagination?.total || 0;
});

export const getWishlistModalIsOpen = createSelector([getWishlistState], (state) => {
    return state?.wishlistClearModal;
});
export const selectWishlistProductIds = createSelector([getWishlistState], (state) => {
    return state.wishlistProductIds;
});
export const selectIsInFavorites = createSelector(
    [getWishlistState, (_state, productId) => productId],
    (state, productId) => {
        // const collection = state.collections?.find((collection) => collection.is_default);
        // return Boolean(
        //     collection?.product_ids?.find((product_id) => String(product_id) === String(productId))
        // );
        // return wishlistProductIds.find((product) => product.product_id === productId);
        return Boolean(
            Object.keys(state.wishlistProductIds).find(
                (product_id) => String(product_id) === String(productId)
            )
        );
    }
);
export const wishlistProductsCount = createSelector([getWishlistState], (state) => {
    const collection = state.collections?.find((collection) => collection.is_default);
    // return collection?.product_ids?.length || 0;
    return Object.keys(state.wishlistProductIds).length || 0;
});
export const getWishlistCollectionProducts = createSelector(
    [getWishlistState, (_state, collectionId) => collectionId],
    (state, collectionId) => {
        const collection = state.collections?.find((collection) => collection?.id === collectionId);
        // const products = ProductsParser(state.products?.[collectionId.toString()]);
        const products = ProductsParser(state.products);
        return {
            ...CollectionBaseParser(collection, products),
            totalDiscountPrice: products.reduce((accumulator, currentProduct) => {
                const discount = currentProduct.discountPrice || 0;
                return accumulator + Number(discount);
            }, 0),
            totalPrice: priceFormatter(
                products.reduce(
                    (accumulator, currentProduct) => accumulator + Number(currentProduct.price),
                    0
                )
            ),
            products,
        };
    }
);
export const getWishlistCollections = createSelector(
    [getWishlistState],
    (state): IWishlistCollection[] => {
        return state?.collections
            ?.map((collection) => {
                return CollectionBaseParser(collection);
            })
            .sort((collection) => (collection.pinned ? -1 : 1))
            .filter((collection) => !collection.is_default)
            .sort((collection) => (collection.pre_created ? -1 : 1));
    }
);
export const getWishlistAllProducts = createSelector([getWishlistState], (state) => {
    // const collection = state.collections?.find((collection) => collection.is_default);
    return ProductsParser(state.products);
    // return collection?.id ? ProductsParser(state.products?.[collection?.id]) : [];
    // ProductsParser(Object.values(state?.products || {}).flat());
    // collection?.id ? ProductsParser(state.products[collection?.id]) : [];
});
export const getWishlistProductsPagination = createSelector([getWishlistState], (state) => {
    return state.pagination;
});
export const getCollectionFormMobileOverlay = createSelector(
    [getWishlistState],
    (state) => state.mobile_collection_from_overlay
);
export const getLastEditedItem = createSelector(
    [getWishlistState],
    (state) => state.lastEditedItem
);

export const isAddedProductInCollection = createSelector(
    [getWishlistState, (_state, collectionId) => collectionId],
    (state, collectionId) => {
        return true;
        // return Boolean(
        //     state.products.collectionId.find((product) => product.product_id === state.pendingItem)
        // );
    }
);
export const getCollectionTitle = createSelector(
    [getWishlistState, (_state, collectionId) => collectionId],
    (state, collectionId) => {
        return state.collections
            ?.find((collection) => collection.id === collectionId)
            ?.name.toCapitalizeFirstLetter();
    }
);
export const getCollectionById = createSelector(
    [getWishlistState, (_state, collectionId) => collectionId],
    (state, collectionId) => {
        return CollectionBaseParser(
            state.collections?.find((collection) => collection.id === collectionId),
            state.products
        );
    }
);
export const getDefaultCollection = createSelector([getWishlistState], (state) => {
    return CollectionBaseParser(
        state.collections?.find((collection) => collection.is_default),
        state.products
    );
});
export const selectIsInDefaultFavorites = createSelector(
    [getDefaultCollection, (_state, productId) => productId],
    (defaultCollection, productId) => {
        return Boolean(
            defaultCollection?.product_ids?.find(
                (product_id) => String(product_id) === String(productId)
            )
        );
    }
);
export const getCollectionsByProductId = createSelector(
    [getWishlistState, (_state, productId) => productId],
    (state, productId) => {
        return state.collections
            .filter((collection) => collection.product_ids.includes(productId))
            .map((collection) => collection.id);
    }
);
