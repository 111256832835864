import React from 'react';
import { Box, Display, FlexBox, Icon, Skeleton, Text } from 'pyatka-ui';
import styled from 'styled-components/macro';
import { useLocation } from 'react-router';
import { AppMetricEvents } from 'AppMetric';
import { generateAppLinkConfig } from 'modules/NewMainPage/utils';
import { AppLink } from 'components';
import { useI18n } from 'hooks/useI18n';
import { useAppRouter } from 'router/context';
import { useScrollDirection } from 'hooks';
import { getActiveFiltersList } from 'components/ModalListingFilters/ModalListingFilters.Container';
import { sortOptions } from 'features/Listing/sorting';
import { SortValueType } from 'features/Listing/sorting/types';

interface ListingMobileHeaderProps {
    isLoading?: boolean;
    isCardsLoading: boolean;
    isMerchantPage?: boolean;
    categories?: any[];
    activeSortValue: SortValueType;
    activeFilters: any;
    filtersObject: any;
    onSortClick: () => void;
    handleModalFiltersOpen: () => void;
}

const ListingMobileHeader: React.FC<ListingMobileHeaderProps> = ({
    isLoading = false,
    isCardsLoading = false,
    isMerchantPage = false,
    categories = [],
    activeSortValue,
    activeFilters = {},
    filtersObject = {},
    onSortClick,
    handleModalFiltersOpen,
}) => {
    const { direction: scrollDirection } = useScrollDirection(3);
    const isCatLoading = Boolean(isLoading && !categories?.length);

    return (
        <Display md xxl={false}>
            <BoxWrapper className={categories.length ? scrollDirection : ''}>
                <Box className="categories">
                    {(isCatLoading || categories.length) && !isMerchantPage ? (
                        <Box backgroundColor="block.main" pt="12px" pb="6px" px="18px">
                            <CategoriesCarousel isLoading={isCatLoading} categories={categories} />
                        </Box>
                    ) : null}
                </Box>

                <MobileFilterButton
                    activeSortValue={activeSortValue}
                    filtersObject={filtersObject}
                    handleModalFiltersOpen={handleModalFiltersOpen}
                    count={getActiveFiltersList(activeFilters).length}
                    onSortClick={onSortClick}
                    isCardsLoading={isCardsLoading}
                />
            </BoxWrapper>
        </Display>
    );
};

interface MobileFilterButtonProps
    extends Pick<
        ListingMobileHeaderProps,
        | 'isCardsLoading'
        | 'filtersObject'
        | 'activeSortValue'
        | 'onSortClick'
        | 'handleModalFiltersOpen'
    > {
    count: number;
}

const MobileFilterButton: React.FC<MobileFilterButtonProps> = ({
    count = 0,
    isCardsLoading = false,
    filtersObject,
    activeSortValue,
    onSortClick,
    handleModalFiltersOpen,
}) => {
    const { $t } = useI18n();

    if (!isCardsLoading && !Object.keys(filtersObject).length) {
        return null;
    }

    const metric = () => {
        AppMetricEvents.jmart_filter_view();
    };

    return (
        <FlexBox
            bg="block.main"
            padding="12px 18px"
            // mb="6px"
            alignItems="center"
            justifyContent="space-between"
        >
            {isCardsLoading ? (
                [1, 2].map((key) => (
                    <Skeleton key={key} animation width="25%" height="2em" borderRadius="12px" />
                ))
            ) : (
                <>
                    <FlexBox alignItems="center" onClick={onSortClick} gap="4px">
                        <Icon icon="sort" />
                        <Text fontSize="12px" fontWeight={500} lineHeight="15px">
                            {$t(sortOptions[String(activeSortValue) as SortValueType])}
                        </Text>
                    </FlexBox>
                    <FlexBox
                        alignItems="center"
                        gap={count ? 0 : '4px'}
                        onClick={handleModalFiltersOpen}
                    >
                        <Text fontSize="12px" fontWeight={500} lineHeight="15px">
                            {$t('filters')}
                        </Text>
                        {count ? (
                            <FlexBox
                                top="-12px"
                                right="-2px"
                                width="15px"
                                height="15px"
                                id="filters_count"
                                borderRadius="50%"
                                alignItems="center"
                                position="relative"
                                justifyContent="center"
                                bg="block.brand_variant"
                            >
                                <Text color="text.inverse" fontSize="10px" fontWeight={700}>
                                    {count}
                                </Text>
                            </FlexBox>
                        ) : null}

                        <Icon icon="filters" onClick={metric} />
                    </FlexBox>
                </>
            )}
        </FlexBox>
    );
};

const BoxWrapper = styled(Box)`
    position: sticky;
    top: 44px;
    z-index: 11;
    max-width: 100%;
    width: 100%;
    margin: 0 -18px 6px;
    left: 0;
    right: 0;

    &.down {
        transform: translateY(-48px);
        transition: transform 0.3s ease-in-out 0s;
    }
    &.up {
        transform: translateY(0);
        transition: transform 0.3s ease-in-out 0s;
    }
    .categories {
        overflow: hidden;
    }
`;

interface CategoriesCarouselProps extends Pick<ListingMobileHeaderProps, 'categories'> {
    isLoading: boolean;
}

const CategoriesCarousel: React.FC<CategoriesCarouselProps> = ({
    categories = [],
    isLoading = false,
}) => {
    const { queryParams } = useAppRouter();
    const { category_id, query, company_id } = queryParams;

    return (
        <FlexBox
            hideScrollBar
            flexWrap="nowrap"
            flexDirection="row"
            overflowX="auto"
            margin="0 -18px"
            padding="0 18px"
            gap="4px"
        >
            {isLoading && (
                <FlexBox gap="10px">
                    {[...Array(5)].map((_el: any, idx: number) => (
                        <Skeleton
                            borderRadius="12px"
                            width="10em"
                            height="2em"
                            key={idx}
                            animation={true}
                        />
                    ))}
                </FlexBox>
            )}

            {categories && categories.length
                ? categories.map((category: any, idx: number) => {
                      const isActive: boolean = category_id === category.categoryId;

                      /* --------------------------- for search requests -------------------------- */
                      const app_link = generateAppLinkConfig(
                          query ? 'new_algolia' : 'new_search',
                          category.categoryId,
                          {
                              query,
                              company_id,
                              hot_price: queryParams?.hot_price,
                          }
                      );

                      return (
                          <AppLink key={idx} to={app_link}>
                              <CategoryItem
                                  isLoading={isLoading}
                                  isActive={isActive}
                                  category={category}
                              />
                          </AppLink>
                      );
                  })
                : ''}
        </FlexBox>
    );
};

interface CategoryItemProps {
    isLoading: boolean;
    isActive: boolean;
    category: any;
}

const CategoryItem: React.FC<CategoryItemProps> = ({ category, isActive, isLoading }) => {
    const location = useLocation();

    const metricSender = () => {
        const category_id = String(category?.categoryId);
        const category_name = String(category?.title);

        if (location?.pathname === '/search') {
            AppMetricEvents.jmart_category({
                category_id,
                category_name,
                type: 'jmart_category_search',
                keyword: new URLSearchParams(location?.search).get('query'),
            });
            return;
        }

        AppMetricEvents.jmart_category({
            category_id,
            category_name,
            category1: String(category?.category1),
        });
    };

    return (
        <Box
            padding="8px 12px"
            whiteSpace="nowrap"
            borderRadius="999rem"
            onClick={metricSender}
            backgroundColor={isActive ? 'block.placeholder' : 'block.secondary'}
        >
            <Text
                fontSize="13px"
                lineHeight="16px"
                fontWeight={500}
                skeleton={isLoading}
                skeletonProps={{ width: '6em' }}
                color={isActive ? 'text.inverse' : 'text.main'}
            >
                {category.title}
            </Text>
        </Box>
    );
};

export default React.memo(ListingMobileHeader);
