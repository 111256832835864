import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
    addGWProfileAddress,
    deleteGWProfileAddress,
    fetchGeoCodeService,
    fetchGeoCodeServiceSucceed,
    getGWProfileData,
    saveGWUserProfileData,
    updateGWProfileAddress,
} from 'modules/UserProfileService/UserProfileService.actions';
import {
    createGWProfile,
    deleteGWProfile,
    getGeoCodeService,
    getGWProfiles,
    updateGWProfile,
} from 'modules/UserProfileService/UserProfileService.services';
import { Loader } from 'plugins/loader';
import { AppToaster } from 'plugins/app-toaster';
import { AuthorizationDataFetch } from 'modules/Authorization/v2/Authorization.actions';
import { ProfileAddressEditBody } from './types';

function* fetchGeoCodeServiceHandler(_action: any) {
    const { payload } = _action;
    const { geocode } = payload;
    try {
        if (geocode !== '') {
            const { data } = yield call(getGeoCodeService, payload);
            yield put(fetchGeoCodeServiceSucceed(data));
        }
    } catch (e) {
        yield put(fetchGeoCodeServiceSucceed([]));
    }
}

function* deleteGWProfileAddressHandler(_action: any) {
    Loader.profileAddresses.on();
    const { payload } = _action;
    try {
        const { data } = yield call(deleteGWProfile, payload);
        yield put(getGWProfileData());
        AppToaster.success({ body: 'address_deleted' });
        // const { profiles, user } = data?.data;
        // yield put(saveGWUserProfileData({ profiles, user }));
    } catch (e) {
        AppToaster.error({ body: e?.data?.data?.message || 'failed_to_delete_address' });
    } finally {
        Loader.profileAddresses.off();
    }
}

function* getGWProfileDataHandler(_action: any) {
    Loader.profileAddresses.on();
    try {
        const { data } = yield call(getGWProfiles);
        const { profiles, user } = data?.data;
        yield put(saveGWUserProfileData({ profiles, user }));
    } catch (e) {}
    Loader.profileAddresses.off();
}

function* addGWProfileAddressHandler(_action: any) {
    Loader.profileAddresses.on();
    try {
        const { payload } = _action;
        const { data } = yield call(createGWProfile, payload);
        const { profiles, user } = data?.data;
        yield put(saveGWUserProfileData({ profiles, user }));
        AppToaster.success({ body: 'address_added' });
    } catch (e) {
        if (e?.status === 422) {
            const error = e?.data?.data?.fields[0];
            error && AppToaster.warning({ body: error, clearPrevious: false });
        }
    }
    Loader.profileAddresses.off();
}

function* updateGWProfileAddressHandler(_action: { payload: ProfileAddressEditBody }) {
    Loader.profileAddresses.on();
    const { payload } = _action;
    try {
        const cityId = payload.fields.find((f) => f.field_id === 23);
        const { data } = yield call(updateGWProfile, payload, cityId?.value?.id || null);
        const { profiles, user } = data?.data;
        yield put(saveGWUserProfileData({ profiles, user }));
        AppToaster.success({ body: 'address_changed' });
    } catch (e) {
        if (e?.status === 422) {
            const error = e?.data?.data?.fields[0];
            error && AppToaster.warning({ body: error, clearPrevious: false });
        }
    }
    Loader.profileAddresses.off();
}

export default function* () {
    yield all([
        takeLatest(fetchGeoCodeService, fetchGeoCodeServiceHandler),
        //
        takeLatest(getGWProfileData, getGWProfileDataHandler),
        takeLatest(AuthorizationDataFetch, getGWProfileDataHandler),
        takeLatest(deleteGWProfileAddress, deleteGWProfileAddressHandler),
        takeLatest(addGWProfileAddress, addGWProfileAddressHandler),
        takeLatest(updateGWProfileAddress, updateGWProfileAddressHandler),
    ]);
}
