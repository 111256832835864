import React from 'react';
import { FlexBox, Icon, Text } from 'pyatka-ui';
import { useI18n } from 'hooks/useI18n';
import { ProfileAddressNewLayoutProps } from './types';

export const ProfileAddressNewLayout: React.FC<ProfileAddressNewLayoutProps> = (props) => {
    const { showAddressesHandler, isLoading, withBtnLayout } = props;
    return (
        <React.Fragment>
            <ProfileSelectLayout
                withBtnLayout={withBtnLayout}
                icon={'profile-address-pin'}
                onClick={showAddressesHandler}
            >
                <Text
                    skeleton={isLoading}
                    skeletonProps={{ animation: true, style: { width: 200, height: 24 } }}
                    lineClamp={1}
                    fontSize={{ default: 16, md: 20 }}
                    fontWeight={500}
                >
                    {props.addressName}
                </Text>
            </ProfileSelectLayout>
        </React.Fragment>
    );
};

export const ProfileSelectLayout: React.FC<{
    icon: string;
    onClick: () => void;
    withBtnLayout?: boolean;
}> = (props) => {
    const { withBtnLayout = false } = props;
    const { $t } = useI18n();
    return (
        <FlexBox
            width="100%"
            alignItems="center"
            justifyContent="space-between"
            onClick={props?.onClick}
            gap={'20px'}
            cursor={'pointer'}
        >
            <FlexBox maxWidth="100%" gap="12px" alignItems={'center'}>
                <FlexBox gap="6px" color="text.main" flexDirection="column" width="100%">
                    <Text
                        fontSize={props?.children ? 12 : 16}
                        color={'text.secondary'}
                        fontWeight={500}
                    >
                        {$t('delivery_address')}
                    </Text>
                    {props?.children}
                </FlexBox>
            </FlexBox>
            {withBtnLayout ? (
                <FlexBox
                    background={'#FF7348'}
                    p={'5.5px 10px'}
                    color={'#fff'}
                    borderRadius={'99px'}
                    fontWeight={600}
                    fontSize={'12px'}
                    lineHeight={'15.36px'}
                >
                    {$t('change')}
                </FlexBox>
            ) : (
                <FlexBox style={{ transform: 'rotate(90deg)' }}>
                    <Icon icon={'orderArrow'} width={26} height={26} />
                </FlexBox>
            )}
        </FlexBox>
    );
};
