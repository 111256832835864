import { CaseReducer, current, PayloadAction } from '@reduxjs/toolkit';
import { OrderItem, OrdersServiceInitialState, Pagination } from 'modules/OrdersService/types';
import { Product } from 'modules/ProductsList/types';

const OrdersServiceInit: CaseReducer<OrdersServiceInitialState, PayloadAction<{}>> = (
    _state,
    _action
) => {};
const OrdersDetailInit: CaseReducer<OrdersServiceInitialState, PayloadAction<{}>> = (
    _state,
    _action
) => {};

const OrdersServiceSucceed: CaseReducer<
    OrdersServiceInitialState,
    PayloadAction<{ data: any; orders?: OrderItem; order?: Product; pagination?: Pagination }>
> = (_state, _action) => {
    if (_action?.payload?.order) {
        // @ts-ignore
        _state.orderItem = _action.payload?.order;
    }
    const newProducts = _action?.payload?.data?.items;

    if (!_action?.payload?.order) {
        // @ts-ignore
        _state.pagination = _action.payload?.data?.pagination;
    }

    // const hasNoParentId = _action?.payload?.data?.params.parent_order_id === 0;
    // if (hasNoParentId) {
    //     _state.orders = _action?.payload?.data?.items;
    // }
    if (newProducts) {
        const state = current(_state);
        const oldProducts = state?.orders || [];
        if (_state.pagination.current_page === 1) {
            _state.orders = newProducts;
        } else {
            _state.orders = [...oldProducts, ...newProducts];
        }
        _state.pagination = _action.payload?.data?.pagination;
    }
};

const OrdersServiceFailed: CaseReducer<OrdersServiceInitialState> = (_state, _action) => {};

const OrderDetailClear: CaseReducer<OrdersServiceInitialState, PayloadAction> = (
    _state,
    _action
) => {
    // @ts-ignore
    _state.orderItem = {};
};

const OrdersServiceClear: CaseReducer<OrdersServiceInitialState, PayloadAction> = (
    _state,
    _action
) => {
    // @ts-ignore
    _state.orderItem = {};
    _state.orders = [];
    _state.pagination = {};
};

const CancelReasonInit: CaseReducer<OrdersServiceInitialState, PayloadAction<{}>> = (
    _state,
    _action
) => {};

const CancelReasonSucceed: CaseReducer<OrdersServiceInitialState, PayloadAction> = (
    _state,
    _action
) => {};

const CancelReasonClear: CaseReducer<OrdersServiceInitialState, PayloadAction> = (
    _state,
    _action
) => {
    // @ts-ignore
    _state.cancelReason = {};
};
export default {
    OrdersServiceFailed,
    OrdersServiceSucceed,
    OrdersServiceInit,
    OrdersServiceClear,
    CancelReasonInit,
    CancelReasonSucceed,
    CancelReasonClear,
    OrderDetailClear,
    OrdersDetailInit,
};
