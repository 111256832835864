import slice from 'modules/UserProfileService/UserProfileService.slice';

export const {
    fetchGeoCodeService,
    fetchGeoCodeServiceSucceed,
    //
    getGWProfileData,
    addGWProfileAddress,
    updateGWProfileAddress,
    deleteGWProfileAddress,
    //
    saveGWUserProfileData,
} = slice.actions;
