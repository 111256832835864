import { createSelector } from 'reselect';
import { AppRootState } from 'store/types';
import { ISupermarket, SupermarketInitialState } from './types';
import initialState from './Supermarkets.state';

const getSupermarketsRaw = (state: AppRootState): SupermarketInitialState =>
    state.SupermarketReducer || initialState;

// Список супермаркетов
export const getSupermarkets = createSelector([getSupermarketsRaw], (data) => {
    const { supermarket_list = [], section, city, grouped_supermarkets } = data;

    const list: ISupermarket[] = supermarket_list
        .map((item) => ({
            position: item.position,
            name: item.company,
            store_id: item.company_id,
            rating: item.rating / 100 || 0,
            reviews: 0,
            logo: item?.logos?.theme?.image?.image_path || '',
            banner: item?.logos?.supermarket?.image?.image_path || '',
        }))
        .sort((a, b) => b.position - a.position);

    return {
        section: section || '',
        selected_city: city || '',
        supermarket_list: list || [],
        grouped_supermarkets,
    };
});

// Супермаркет
export const getDetailedSupermarket = createSelector([getSupermarketsRaw], (data) => {
    const { supermarket } = data;
    if (supermarket === null) {
        return null;
    }

    const {
        categories = [],
        company_id,
        items,
        logo = {},
        products_count,
        title,
        min_basket_amount,
        nearest_delivery,
        ...REST
    } = supermarket || {};
    return {
        ...REST,

        company_id: company_id || null,
        title: title || null,
        min_basket_amount: min_basket_amount || null,
        nearest_delivery: nearest_delivery || null,
        image: logo?.image?.image_path || '',
        products_count: products_count || null,
        categories: detailedSupermarketCategoryItemFormat(categories),
        items: detailedSupermarketProductItemFormat(items),
    };
});

export const getSupermarketsSearchData = createSelector(
    [getSupermarketsRaw],
    (state) => state.supermarket_search
);

export const getRecommendations = createSelector(
    [getSupermarketsRaw],
    (data) => data?.recommendations
);

export const getSectionId = createSelector(
    [getSupermarketsRaw],
    (state) => state.section_id || null
);

export const getSupermarketCity = createSelector([getSupermarketsRaw], (data) => {
    const { city = '' } = data;
    return city;
});

export const getSuperCategories = createSelector([getSupermarketsRaw], (data) => {
    return data.categories;
});
// Helpers
const detailedSupermarketCategoryItemFormat = (list: any[]) => {
    return list?.map((item) => {
        return {
            cId: item.category_id || null,
            title: item.category || null,
            icon: item.main_pair?.detailed?.image_path || null,
        };
    });
};

const detailedSupermarketProductItemFormat = (list: any[]) => {
    return list?.map((item) => {
        const min_id = item?.min_price_product_id ? item.min_price_product_id : null;
        const offer_id = min_id ? min_id : null;
        return {
            id: item.id || null,
            min_id: item?.min_price_product_id,
            title: item.title || null,
            wishlist_id: min_id,
            product_type: 'G',
            price: item?.discount ? item.base_price : item.price,
            discountPrice: item?.discount ? item.discountPrice : null,
            image: item.image || null,
            offer_id,
            footerCase: 'cart_service',
            labels: labelItemFormat(item.labels),
        };
    });
};

const labelItemFormat = (list: any[]) => {
    return (
        list?.map((item) => {
            return {
                text: item.label_text || null,
                color: item.label_color_text || null,
                bgColor: item.label_color || null,
            };
        }) || []
    );
};
