import React from 'react';
import { Box, FlexBox, Icon, Text } from 'pyatka-ui';
import { useSelector } from 'react-redux';
import { AppLink } from 'components';
import { setUserSearchHistory } from 'data';
import { AppMetricEvents } from 'AppMetric';
import { useAppRouter } from 'router/context';
import { SearchServiceSuggestions } from 'modules/SearchService/SearchService.selectors';
import { ISuggestion } from 'modules/SearchService/types';
import { isSuperSearch } from 'components/SearchBar/utils';
import { useI18n } from 'hooks/useI18n';
import { useSupermarket } from '../../hooks';

interface SuggestionsListProps {
    isMobile?: boolean;
}

const SuggestionsList: React.FC<SuggestionsListProps> = ({ isMobile = false }) => {
    const { generateRouteTO, location } = useAppRouter();
    const { supermarket, store_id } = useSupermarket();
    const supermarket_name = store_id ? ` (${supermarket?.company})` : '';
    const suggestions = useSelector(SearchServiceSuggestions);

    const handleSuggestionClick = (suggestion: ISuggestion) => () => {
        const link = generateLink(suggestion);

        // Записывать в историю поиску только если не поиск по супермаркетам
        if (!store_id && !suggestion?.fmcg) {
            setUserSearchHistory({
                title: String(suggestion.query),
                link: String(encodeURI(generateRouteTO(link))),
            });
        }

        if (suggestion?.fmcg) {
            AppMetricEvents.jmart_search_fmcg_global_suggest({ keyword: suggestion.query });
        } else if (store_id || isSuperSearch(location?.pathname)) {
            AppMetricEvents.jmart_suggest_fmcg_search_new({ keyword: suggestion.query });
        } else {
            AppMetricEvents.jmart_suggest_search_new({ data: suggestion.query, source: 'search' });
        }
    };

    const generateLink = ({ to }: ISuggestion) => ({
        ...to,
        query: {
            ...to.query,
            ...(store_id ? { company_id: store_id } : {}),
        },
    });

    return (
        <FlexBox flexDirection="column" mx="-1px">
            {suggestions
                .filter((item) => (store_id ? !item.fmcg : true))
                .slice(0, 5)
                .map((suggestion, index) => (
                    <Box
                        key={`${suggestion.query}-${index}`}
                        onMouseDown={handleSuggestionClick(suggestion)}
                    >
                        <AppLink
                            to={generateLink(suggestion)}
                            eventType={isMobile ? 'onClick' : 'onMouseDown'}
                        >
                            <SuggestionItem
                                fmcg={suggestion?.fmcg}
                                isLast={index === suggestions.length - 1}
                            >
                                {suggestion.query + supermarket_name}
                            </SuggestionItem>
                        </AppLink>
                    </Box>
                ))}
        </FlexBox>
    );
};

interface SuggestionItemProps {
    children: string;
    isLast: boolean;
    fmcg: boolean;
}

const SuggestionItem: React.FC<SuggestionItemProps> = ({ children, isLast, fmcg }) => {
    const { $t } = useI18n();

    if (fmcg) {
        return (
            <FlexBox
                pl="0"
                py="6px"
                pr="10px"
                alignItems="center"
                justifyContent="space-between"
                bg="block.secondary"
                borderRadius="18px"
                mb="4px"
            >
                <FlexBox alignItems="center" justifyContent="center" gap="6px">
                    <Box>
                        <Icon width="46px" height="46px" icon="product_credit" />
                    </Box>
                    <FlexBox flexDirection="column">
                        <Text
                            fontSize="16px"
                            fontWeight="600"
                            color="text.main"
                            lineHeight="20.48px"
                            lineClamp={1}
                        >
                            {children}
                        </Text>
                        <Text
                            fontSize="12px"
                            fontWeight="500"
                            lineHeight="15.36px"
                            color="text.secondary"
                        >
                            {$t('150_min_delivery')}
                        </Text>
                    </FlexBox>
                </FlexBox>
                <div>
                    <Icon width="7px" height="14px" icon="arrowRight" />
                </div>
            </FlexBox>
        );
    }

    return (
        <FlexBox
            p="11px"
            py="14px"
            alignItems="center"
            justifyContent="space-between"
            borderBottom={isLast ? 'none' : '1.5px solid'}
            borderColor="block.secondary"
        >
            <FlexBox alignItems="center" justifyContent="center" gap="16px">
                <Box p={1} bg="#D9D9D9" display="grid" borderRadius="5px">
                    <Icon fill="white" width="16px" height="16px" icon="search" />
                </Box>
                <Text color="text.main" fontWeight="bold">
                    {children}
                </Text>
            </FlexBox>
            <div>
                <Icon width="7px" height="14px" icon="arrowRight" />
            </div>
        </FlexBox>
    );
};

export default React.memo(SuggestionsList);
