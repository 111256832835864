import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { CategoryInitialState, CategoryItem } from 'modules/Categories/types';
import { generateAppLinkConfig } from 'modules/NewMainPage/utils';

// --------------------------------------------------------------------------- utils
const prepareCategoriesItems = (items: any[]): CategoryItem[] => {
    return items
        .sort((a, b) => a.position - b.position)
        .map((item: any): CategoryItem => {
            const {
                category: title,
                parent_id: parentId,
                category_id: categoryId,
                id_path_names,
                has_children,
                main_pair,
                id_path,
            } = item;

            const tree = id_path.split('/').map((id: string) => ({
                id,
                title: id_path_names[`${id}`],
            }));

            const image = main_pair && main_pair.detailed && main_pair.detailed.image_path;
            return {
                tree,
                title,
                image,
                parentId,
                categoryId,
                category1: Object.keys(id_path_names || {})[0] || null,
                hasChildren: has_children !== undefined,
                url: generateAppLinkConfig('new_category', categoryId),
            };
        });
};

const fetchCategories: CaseReducer<CategoryInitialState, PayloadAction<{ id: string }>> = () => {};

const fetchCategoriesSucceed: CaseReducer<
    CategoryInitialState,
    PayloadAction<{ id: string; items: any; childId: any }>
> = (state, { payload }) => {
    const { items, id, childId } = payload;
    state.categoriesNames[`${id}`] = items?.category;
    state.categories[`${id}`] = prepareCategoriesItems(items.children);
    state.childId = childId;

    // Breadcrumb
    state.breadcrumb = [];
    const categoriesSplit = items?.id_path.split('/');
    const categoriesSplitLength = categoriesSplit.length;
    if (categoriesSplitLength > 1) {
        state.breadcrumb =
            categoriesSplit.map((id: string) => ({ id, title: items?.id_path_names[`${id}`] })) ||
            [];
    } else if (categoriesSplitLength === 1) {
        state.breadcrumb = [{ id: items?.category_id, title: items?.category }];
    }
};

const fetchCategoriesFailed: CaseReducer<CategoryInitialState, PayloadAction> = () => {};

export default {
    fetchCategories,
    fetchCategoriesSucceed,
    fetchCategoriesFailed,
};
