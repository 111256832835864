import { Dialog } from 'plugins/dialog';

export const isNative = () => __BROWSER__ && window.__HYBRIDCONFIG__.platform === 'MOBILE';
export const notIOS = () => __BROWSER__ && window?.__HYBRIDCONFIG__?.channel !== 'ios'; 

// export const isQrTrigger = __IS_PROD__ ? !isNative() : false;
export const isQrTrigger = false;
// export const isQrTrigger = !isNative();
export const isAuthJWT = isNative();
export const nativeConfigs = (): any => __BROWSER__ && window.__HYBRIDCONFIG__;
// tick2
export const getHybridConfig = (debug = false): HYBRID_CONFIG => ({
    platform: debug ? 'MOBILE' : 'REACT',
    channel: debug ? 'ios' : 'none',
    version: debug ? '3.9.0' : 'none',
    theme: debug ? 'white' : 'none',
    lang: 'ru',
    // token: 'eyJraWQiOiIxIiwiYWxnIjoiUlMyNTYifQ.eyJzdWIiOiJqbWFydCIsImF1ZCI6ImptYXJ0LWF1dGgiLCJpc3MiOiJjZXJiZXJ1cyIsImlhdCI6MTcwMDk5NTM1OCwiYWRkX2luZm8iOnsicGhvbmUiOiI3Nzc1NTAxMTA1MCIsImlpbiI6Ijk3MTEwNDM1MDEyNCIsImxhc3RfbmFtZSI6ItCi06jQm9CV0JHQldCg0JTQq9CV0JIiLCJmaXJzdF9uYW1lIjoi0JDQm9CY0KjQldCgIiwibWlkZGxlX25hbWUiOiLQkNCh0prQkNCg0rDQm9CrIn0sImp0aSI6ImY5NzlkM2FlLTVjNzUtNDg1Ni1hNGU5LWIzYmMxMTQyMDNjYyJ9.SZImqrL03hq1wFd8jJh4Cfh1KiVs5cONZuK_4mZ53l_4ETrXPw155CzVmUWljXV3dzaDIc1riz73JQIaQFHZjEkxNqAz4tMQTUZBfkmxUZOFd3CjF1g4bGPUxMjiajLmE1GGae9F-daXRPNmGSN1H94Wyfrx7LvPGPIA8FE4UcEedTEg4GmeN1PQcSPFbbVJnOJ9xkZWR8uU0XwclDi6BJl-4RquKW3gzJGQtjgFTDO1tizmyYbF1FPkE82c6QZg-bjyw8f8PJtErJP44IH8375kmPKcpzYdonpFBOsjes8ut88pWziPc-pdlCjpEtUxIXmfL5KGFCrI0N2QpFG4hQjonE6l0og9Evcd9iBhsu0zwPpIPC8I67tZwwSyoaGUZ7Y8hcj-T1JLNfbtrkfqyzEh-v54v7QS86hzvsc1OD2EchD3t9dyp92JiP4g1BvU3jnVmnzm7TqdKHNj7MGR2GQja_ksbdXzsJVJI6pARkfOY1GQCAslEHIScE9icbxQGAt2EhKNd36FuWzjP30TxQgRHOhF6VEHAIAXDP1jhGpvITMYHyzJZ485PPwz0lQVzSgUJtPOZsKoa8dIzeq3sBCNLht4Hakm1XdxN3kl-OxmpWgUDoqQP9_EimqAsBdzmJHd--eJQnb8m-PC2bZT1_U9ym4bMqcLihGiQomalMU',
    token: null,
    // token: debug
    //     ? 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJhdXRoIiwibG9naW4iOiJ1c2VyXzcxIiwiZW1haWwiOiJkZXZfdGVzdF9hZG1pbjFAZW1haWwuY29tIiwiaWQiOjcxLCJ1c2VyX3R5cGUiOiJBIiwidXNlcl9ncm91cHMiOlsiYWRtaW5pc3RyYXRvciIsImtvbnRlbnQtbWVuZWR6aGVyeSIsIldlYi1cdTA0MzBkbWluIiwic2VvIiwiYWNjZXNzLXNlY3VyaXR5IiwibGVhZC1kZXZlbG9wZXJzIiwiZGV2ZWxvcGVycyIsImZ1bGwtYWRtaW4iLCJDb250ZW50IG1hbmFnZXJzIChkb3N0YXZrYSkiLCJDb250ZW50IG1hbmFnZXJzIChQVlopIiwidGVzdGlyb3ZzaGNoaWtpIiwiQ29udGVudCBtYW5hZ2VycyAobWFya2V0aW5nKSIsIkNvbnRlbnQgbWFuYWdlcnMgKHRhcmlmZikiXSwiaWF0IjoxNjg4NTU3MDkyLCJleHAiOjE2ODg1NjQyOTIsImxhbmdfY29kZSI6InJ1IiwiY2l0eV9pZCI6NDQzLCJjb21wYW55X2lkcyI6W10sImlzcyI6InVzZXItc2VydmljZS5qbWFydCJ9.yhjeGJSoFdsCsiPm9B0OI8SWHkE0-7D4K23vYKjjm4i2qKE8XRZ2Jt5d_LbWr0i3q31ToGqeS4CCMNcZMcKNmQ'
    //     : null,
    tokenJWT: null,
    device_id: 'empty',
    city_id: 443,
    page_anchor: '',
    is_webview: false,
    source: 'DEFAULT',
    appsflyer_id: null,
    // appsflyer_id: '1700135141285-8545360886721084520',
});

// Открыть модалку для редиректа
export const triggerQrModal = (path: any) =>
    Dialog.openDialog({
        title: ' ',
        body: 'QrRedirect',
        props: {
            path,
            bgColor: '#ff7348',
        },
        swipeable: true,
        config: {
            noPadding: true,
            bottomSheet: true,
            overlayHeader: true,
        },
    });

const regex = /(<([^>]+)>)/gi;
export const stripHtml = (str: string = ''): string => str.replace(regex, '');
export const getPlatform = () => {
    if (__BROWSER__ && window?.__HYBRIDCONFIG__?.platform) {
        let platform = 'mobile_web';
        if (window?.__HYBRIDCONFIG__?.platform === 'MOBILE') {
            platform = 'app';
        } else if (window?.outerWidth > 992) {
            platform = 'desktop';
        }
        return platform;
    }
    return '';
};
