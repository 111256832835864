import { SelectBoxOptionProps } from 'pyatka-ui/dist/components/SelectBox/types';
import { Box, FlexBox, Icon, Text } from 'pyatka-ui';
import React from 'react';

export const DefaultComponent = (text: string, icon: string) => {
    return function Component() {
        return (
            <FlexBox
                className={'item'}
                alignItems={'center'}
                gap={'12px'}
                cursor={'pointer'}
                color={'text.main'}
            >
                <Box>
                    <Icon icon={icon} />
                </Box>
                <Text fontWeight={500} lineHeight={'133%'}>
                    {text}
                </Text>
            </FlexBox>
        );
    };
};

export const langOptions: SelectBoxOptionProps[] = [
    {
        value: DefaultComponent('Русский', 'ru'),
        key: 'ru',
    },
    {
        value: DefaultComponent('Қазақша', 'kk'),
        key: 'kk',
    },
    // {
    //     value: 'en',
    //     title: 'Английский',
    // },
];
