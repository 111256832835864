import React from 'react';
import { Box, Container, Text } from 'pyatka-ui';
import { CheckoutDeliveryPageLayout } from 'pages/CheckoutDeliveryV2Page/CheckoutDeliveryPage.Layout';

import { useI18n } from 'hooks/useI18n';

const CheckoutDeliveryPageDesktopLayout = (props) => {
    const { $t } = useI18n();
    return (
        <Box width={'100%'} position={'relative'}>
            <Container>
                <Box p={{ default: '48px 25px', lg: '48px 112px 0' }}>
                    <Text fontSize={'32px'} lineHeight={'41px'} fontWeight={700} mb={34}>
                        {$t('checkout_delivery_page_title')}
                    </Text>
                    <CheckoutDeliveryPageLayout {...props} />
                </Box>
            </Container>
        </Box>
    );
};

export default CheckoutDeliveryPageDesktopLayout;
