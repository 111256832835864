import React from 'react';
import { Box, FlexBox, usePyatka, Space } from 'pyatka-ui';
import { AppLink, CartProductCounter, WishListToggler } from 'components';
import { SimilarProductsButton } from 'components/ProductCard/NewCard/components/SimilarProductsButton';
import {
    AddToCartButton,
    GiftBox,
    ProductDescription,
    ProductImage,
    ProductLabels,
    ProductPrice,
    ProductRating,
} from './components';
import { ProductCardLayoutProps } from './types';

const ProductCardLayout: React.FC<ProductCardLayoutProps> = ({
    lineClamp = 2,
    appearance = 'slide',
    isInCart = false,
    firstOfferInCart,
    addToCartHandler,
    // Product
    label,
    productName,
    type,
    image,
    image_preload,
    url,
    isLoading = false,
    price_formatted,
    price,
    discountPrice_formatted,
    gift_exist = false,
    offer_id,
    algoliaHandler,
    handleClickProduct,
    isPreventLink,
    productId,
    handleMetric,
    jdv_labels,
    promo_labels_admin,
    effect,
    totalRecords,
    avgRate,
    labels,
    isZeroPrice,
    category_ids,
}) => {
    const { scales } = usePyatka();

    const wishListSize = scales?.isMobile ? { width: '16px', height: '16px' } : {};
    const hasLabels = !(label?.bonusLabel || label?.installment);

    return (
        <FlexBox
            width="100%"
            height="100%"
            cursor="pointer"
            borderRadius={24}
            position="relative"
            flexDirection="column"
            justifyContent="space-between"
            padding={{ default: '10px', md: '16px' }}
            backgroundColor="block.main"
            onClick={handleMetric}
        >
            <Box position="relative">
                <ProductImage
                    url={url}
                    image={image}
                    image_preload={image_preload}
                    isLoading={isLoading}
                    appearance={appearance}
                    ariaLabel={productName}
                    handleClick={handleClickProduct}
                    isPreventLink={isPreventLink}
                    effect={effect}
                    layout="old"
                    route_state={{
                        labels,
                        price_formatted,
                        totalRecords,
                        avgRate,
                        discountPrice_formatted,
                        offer_id,
                    }}
                />
                <GiftBox
                    show={gift_exist}
                    jdv_labels={jdv_labels}
                    promo_labels_admin={promo_labels_admin}
                />
            </Box>

            <Box
                zIndex={1}
                position="absolute"
                top={{ default: '10px', md: '18px' }}
                right={{ default: '12px', md: '17px' }}
            >
                <WishListToggler
                    product_id={Number(productId) || Number(offer_id)}
                    productName={productName}
                    price={discountPrice_formatted || price}
                    link={url}
                    algoliaHandler={algoliaHandler}
                    {...wishListSize}
                />
            </Box>
            <Box>
                <AppLink
                    to={url}
                    preventOnClick={isPreventLink}
                    onClickProduct={handleClickProduct}
                    route_state={{
                        labels,
                        price_formatted,
                        totalRecords,
                        avgRate,
                        discountPrice_formatted,
                        offer_id,
                    }}
                >
                    <ProductPrice
                        isZeroPrice={isZeroPrice}
                        label={label}
                        price={price_formatted || price}
                        discountPrice={discountPrice_formatted}
                        isLoading={isLoading}
                    />
                    <ProductLabels show={!hasLabels} isLoading={isLoading} label={label} />
                    <ProductDescription
                        isZeroPrice={isZeroPrice}
                        lineClamp={lineClamp}
                        productName={productName}
                        isLoading={isLoading}
                    />
                </AppLink>
                <ProductRating
                    isLoading={isLoading}
                    avgRate={avgRate}
                    totalRecords={totalRecords}
                />
                <Space height="10px" />
            </Box>

            {isZeroPrice ? (
                <SimilarProductsButton category_ids={category_ids} />
            ) : isInCart && !isLoading ? (
                <CartProductCounter
                    fullWidth
                    offer_id={String(firstOfferInCart?.offer_id)}
                    product_type={type}
                />
            ) : (
                <AddToCartButton addToCartHandler={addToCartHandler} isLoading={isLoading} />
            )}
        </FlexBox>
    );
};

export default React.memo(ProductCardLayout);
