import { ApiGwRequest } from 'services';
import { CATEGORIES_GET_REQ_INDICATOR } from 'keys';

export const getCategoriesReq = (params: any) => {
    const { category_id, ...otherParams } = params;

    return ApiGwRequest({
        indicator: CATEGORIES_GET_REQ_INDICATOR,
        url: `catalog/v1/sra-categories/${category_id}`,
        method: 'get',
        params: otherParams,
    });
};
