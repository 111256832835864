import { createSelector } from 'reselect';
import { AppRootState } from 'store/types';
import priceFormatter from 'utils/priceFormatter';
import { generateLabels } from 'modules/ProductsList/ProductsList.helpers';
import { ProductDetailInitialState } from './types';

const getProductDetailsState = (state: AppRootState): ProductDetailInitialState =>
    state.ProductDetailReducer;

export const getProductReviewData = createSelector(
    [getProductDetailsState],
    (state) => state.product_review_data
);

export const getDetailsSeo = (state: AppRootState) => {
    return state.ProductDetailReducer.product_seo;
};

const getLabels = (state: AppRootState) => {
    return state.ProductDetailReducer.labels;
};

export const getProductLabels = createSelector([getLabels], (labels) => generateLabels(labels));

const getMinPriceInfo = (state: AppRootState) => {
    return state.ProductDetailReducer.min_pirce_info;
};

export const getProductMinPriceInfo = createSelector(
    [getMinPriceInfo],
    (minPriceInfo) => minPriceInfo
);

const getSimilar = (state: AppRootState) => {
    return state.ProductDetailReducer.similar_products;
};

export const getSimilarProducts = createSelector(
    [getSimilar],
    (similar_products) => similar_products
);

const getDetails = (state: AppRootState) => {
    return state.ProductDetailReducer.product_details;
};

const getProductDetails = createSelector([getDetails], (items) => items);

// ------------------------------------------------------------------------------------------------- Images
export const getProductImages = createSelector([getProductDetails], (items): string[] => {
    if (items.image_urls) {
        const images: string[] = [...items.image_urls];
        return images;
    }
    return [];
});

// ------------------------------------------------------------------------------------------------- ProductInfo
const getVariants = (variants: any) => {
    const result = [];
    for (const option in variants) {
        const { variant, product_id, product } = variants[`${option}`];
        result.push({ product, variant, product_id, variant_id: product_id });
    }
    return result;
};

const getFeatures = (features: any) => {
    const result = [];
    for (const feature in features) {
        const variants = getVariants(features[`${feature}`].variants);
        const { feature_id, description } = features[`${feature}`];
        result.push({
            feature_id,
            description,
            variants,
        });
    }

    return result;
};

export const getProductInfo = createSelector(
    [getProductDetails, getProductImages],
    (item: any, images: any) => {
        const product = {
            brand: '',
            title: '',
            feature_variations: [{}],
            offer_id: '',
            price: '',
            seo_name: '',
            image: '',
            id: '',
            min_id: '',
            type: '',
            labels: [],
            promo_text: null,
        };
        if (item.product_id) {
            let feature_variations;
            if (item.variation_features_variants) {
                feature_variations = getFeatures(item.variation_features_variants);
            }
            const image = (images && images[0]) || null;

            return {
                brand: item.brand ? item.brand : '',
                meta: {
                    title: item.page_title || item.product,
                    seo_desc: item.meta_description || item.product,
                },
                is_for_adult: item.is_for_adult,
                is_degree_high: item.is_degree_high,
                is_degree_low: item.is_degree_low,
                title: item.product,
                id: item.product_id,
                min_id: item.min_price_product_id,
                offer_id: item.min_price_product_id,
                price: priceFormatter(item.price),
                price_raw: item.price,
                seo_name: item.seo_name as string,
                promo_text: item.promo_text !== '' ? item.promo_text : null,
                type: item.product_types,
                image,
                category_ids: item.category_ids,
                product_id: item.product_id,
                feature_variations: feature_variations ? feature_variations : [],
                labels: generateLabels(item.labels),
            };
        }
        return product;
    }
);

// ------------------------------------------------------------------------------------------------- Specifications
export const getProductSpecifications = createSelector([getProductDetails], (items: any) => {
    const { product_features = {} } = items;

    return Object.keys(product_features).map((key) => ({
        title: product_features[`${key}`].description,
        value: product_features[`${key}`].variant.join(', '),
    }));
});

// ------------------------------------------------------------------------------------------------- Product Descriptions
export const getProductDescriptions = createSelector([getProductDetails], (items: any) => {
    const { full_description, meta_description } = items;
    return { full: full_description, short: meta_description };
});

// SUPERMARKET MODAL DATA SELECTOR
export const getIsDetailModalOpen = createSelector([getProductDetailsState], (state) => {
    return state.isDetailModalOpen;
});

export const getProductDetailModal = createSelector([getProductDetailsState], (state) => {
    return state.productDetail;
});
