import { ApiGwRequest } from 'services';
import { ISuggestion, SearchServiceReqPayload } from 'modules/SearchService/types';
import { generateAppLinkConfig } from 'modules/NewMainPage/utils';
import { AlgoliaEvents } from 'metrics';

export const SearchServiceServices = (payload: SearchServiceReqPayload) => {
    const userToken = AlgoliaEvents.getToken();
    return ApiGwRequest({
        url: 'listing/v1/search',
        method: 'get',
        headers: {
            [AlgoliaEvents.TOKEN_HEADER]: userToken,
        },
        params: payload.params,
    });
};

export const SearchServiceGetSuggestions = (query: string) => {
    const userToken = AlgoliaEvents.getToken();
    return ApiGwRequest({
        url: `listing/v1/suggestion?query=${query}`,
        method: 'get',
        headers: {
            [AlgoliaEvents.TOKEN_HEADER]: userToken,
        },
    });
};

export const suggestionParser = (hits: ISuggestion[], isSuper: boolean = false) =>
    hits
        .map((hit) => ({
            ...hit,
            fmcg: !!hit?.fmcg,
            to: generateAppLinkConfig(isSuper || !!hit?.fmcg ? 'new_super' : 'new_algolia', '', {
                query: hit.query,
            }),
        }))
        .filter((hit) => (isSuper ? !hit.fmcg : true));
