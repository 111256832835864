import { signs } from 'utils/symbols';
import priceFormatter from 'utils/priceFormatter';
import { FILTER_COMPANY_IDS } from 'modules/ProductsList/ProductsList.helpers';
import { IOffer, IOfferFormat, IPaymentTimes, ITerms, IShipping } from './types';

export const offerFormat = (offers: IOffer[]): IOfferFormat[] => {
    return offers?.map((offer: IOffer) => {
        return {
            ...offer,
            price: priceFormatter(offer?.base_price),
            discountPrice: priceFormatter(offer?.price),
            shipping: getShipping(offer?.shipping),
            payment_types: getPaymentTerms(offer),
            gift: {
                gift_title: offer?.gift_title,
                gift_notes: offer?.gift_notes,
                gift_image_url: offer?.gift_image_url,
                gift_description: offer?.gift_description,
            },
        };
    });
};

export const getShipping = (shipping?: { [key: string]: IShipping }) => {
    if (!shipping) {
        return [];
    }

    return Object.entries(shipping).map(([key, value]: any) => {
        const is_free = value?.price === 0;
        return {
            is_free,
            type: key,
            date: value?.date,
            name: value?.title,
            price: is_free ? 'shipping_cost_free' : `${value?.price} ${signs.tg}`,
        };
    });
};

// --- get all payment times
export const getPaymentTimes = (offers?: IOfferFormat[], isMobile?: boolean): IPaymentTimes => {
    if (!offers || offers?.length < 1) {
        return [];
    }

    const times = [] as string[];
    for (const offer of offers) {
        const payment_types = offer?.payment_types || {};
        for (const key in payment_types) {
            if (times.includes(key)) {
                continue;
            }
            if (payment_types[String(key)]?.term_type === 'installment') {
                times.push(key);
            }
        }
    }

    if (offers.some((offer) => offer?.payment_types?.loan)) {
        times.push('loan');
    }

    return ['card', ...times];
    // return isMobile ? ['card', ...times] : times;
};

// --- get all offer terms (кредит, рассрочка)
export const getPaymentTerms = (offer?: IOffer): ITerms => {
    let payment_types: any = {};

    // installment
    if (offer?.installment?.terms) {
        payment_types = { ...offer?.installment?.terms };
    }

    // loan
    if (offer?.loan?.terms && offer?.loan?.terms['36']) {
        payment_types.loan = offer?.loan?.terms['36'];
    }

    // card
    if (offer?.card) {
        payment_types.card = { bonus: offer?.card_bonus };
    }

    return payment_types;
};

export const getOfferByTime = (offers?: IOfferFormat[], time: string = 'card'): IOfferFormat[] => {
    if (offers && offers?.length < 1) {
        return [];
    }

    return offers?.filter((offer) => offer?.payment_types[String(time)]) as IOfferFormat[];
};

// --- get company ids from query params
export const filterCompanyIds = (queryParams: any = {}) =>
    Object.fromEntries(Object.entries(queryParams).filter(([k]) => k.includes(FILTER_COMPANY_IDS)));
