import React, { useEffect, useRef, useState } from 'react';
import { Box, FlexBox, LoadingIndicator } from 'pyatka-ui';
import { useDispatch, useSelector } from 'react-redux';
import { useI18n } from 'hooks/useI18n';
import { Product } from 'modules/ProductsList/types';
import { EmptyContentBlock, ProductsCardsList } from 'components';
import { useLoader } from 'contexts/useLoader';
import { Loader } from 'plugins/loader';
import { getCollectionProducts } from 'modules/Wishlist/Wishlist.actions';
import { useIntersectionObserver } from 'pages/ProductsList/useIntersectionObserver';
import { getWishlistProductsPagination } from 'modules/Wishlist/Wishlist.selectors';
import { useParams } from 'react-router';

interface IWishlistProductsProps {
    wishlistProducts: Product[];
    metric: any;
    isDesktop?: boolean;
}

const INTERSECTION_CONFIG = {
    root: null,
    rootMargin: '0px',
    threshold: 0.25,
};
const WishlistProducts = (props: IWishlistProductsProps) => {
    const { wishlistProducts, metric, isDesktop = false } = props;
    const { collection_id }: any = useParams();

    const { $t } = useI18n();
    const { isLoading } = useLoader();
    const isProductsLoading = isLoading(Loader.favorite.indicator);
    const dispatch = useDispatch();
    const pagination = useSelector(getWishlistProductsPagination);
    const scrollRef = useRef(null);
    const isIntersecting = useIntersectionObserver(scrollRef, INTERSECTION_CONFIG);
    const [isFirstLoading, setIsFirstLoading] = useState(true);

    // useEffect(() => {
    //     // console.log("wishlistProducts", )
    //     if (wishlistProducts.length < 1) {
    //         dispatch(getCollectionProducts({ page: 1 }));
    //         setIsFirstLoadingDone(true);
    //     }
    // }, [wishlistProducts.length]);
    // useEffect(() => {
    //     console.log('isIntersecting', isIntersecting);
    //     if (
    //         isFirstLoadingDone &&
    //         isIntersecting &&
    //         !isProductsLoading &&
    //         pagination?.currentPage >= 1 &&
    //         pagination?.currentPage + 1 <= pagination?.lastPage
    //     ) {
    //         dispatch(getCollectionProducts({ page: pagination.currentPage + 1 }));
    //     }
    // }, [isIntersecting, isProductsLoading, pagination.currentPage, pagination?.lastPage]);

    // if (wishlistProducts?.length === 0 && !isProductsLoading) {
    //     return (
    //         <EmptyContentBlock
    //             icon={'empty-wishlist'}
    //             title="favorites_empty"
    //             redirectText="to_main"
    //             redirectTo={{ name: 'index-page' }}
    //             titleColor={'text.main'}
    //             description={'add_what_you_like_wishlist'}
    //         />
    //     );
    // }
    useEffect(() => {
        if (isFirstLoading) {
            dispatch(getCollectionProducts({ collection_id, page: 1 }));
            setIsFirstLoading(false);
        } else if (
            isIntersecting &&
            !isProductsLoading &&
            pagination?.currentPage >= 1 &&
            pagination?.currentPage + 1 <= pagination?.lastPage
        ) {
            dispatch(getCollectionProducts({ collection_id, page: pagination.currentPage + 1 }));
        }
    }, [isIntersecting, collection_id, isFirstLoading]);

    return (
        <Box>
            {/*{isProductsLoading && wishlistProducts?.length === 0 && <WishListLoading />}*/}
            {!isProductsLoading && wishlistProducts?.length === 0 && (
                <Box mt={{ default: '-30%', md: '0' }}>
                    <EmptyContentBlock
                        icon={'empty-wishlist'}
                        title="favorites_empty"
                        redirectText="to_main"
                        redirectTo={{ name: 'index-page' }}
                        titleColor={'text.main'}
                        description={'add_what_you_like_wishlist'}
                    />
                </Box>
            )}
            {wishlistProducts?.length > 0 && (
                <ProductsCardsList
                    metric={metric}
                    products={wishlistProducts || []}
                    isLoading={false}
                    layout="new"
                    source="wishlist"
                />
            )}
            <div ref={scrollRef} style={{ height: '0' }} />
            <FlexBox py={3} justifyContent={'center'}>
                {isProductsLoading && <LoadingIndicator />}
            </FlexBox>
        </Box>
    );
};

export default WishlistProducts;
