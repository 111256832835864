import React from 'react';
import { Box, Modal, Space, Text } from 'pyatka-ui';
import { VerificationModal } from 'components';
import { useI18n } from 'hooks/useI18n';

interface IDeleteCollectionConfirmModalProps {
    openModal: boolean;
    closeModal: () => void;
    handleAccept: () => void;
    handleReject: () => void;
}

const DeleteCollectionConfirmModal = (props: IDeleteCollectionConfirmModalProps) => {
    const { openModal, closeModal, handleAccept, handleReject } = props;
    const { $t } = useI18n();

    return (
        <Modal padding={'20px 0 0 0'} width={'fit-content'} open={openModal} onClose={closeModal}>
            <VerificationModal onAccept={handleAccept} onReject={handleReject}>
                <Box padding={'0 25px 0 25px'}>
                    {/*{children}*/}
                    <Text textAlign={'center'} fontWeight={'700'} fontSize={'18px'}>
                        {$t('delete_collection')}?
                    </Text>
                    <Space height={'5px'} />
                    <Text
                        textAlign={'center'}
                        fontSize={'14px'}
                        lineHeight={'19px'}
                        color={'text.secondary'}
                    >
                        {$t('want_to_delete_collection')}
                    </Text>
                </Box>
            </VerificationModal>
        </Modal>
    );
};

export default DeleteCollectionConfirmModal;
