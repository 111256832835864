import React from 'react';
import { FlexBox, usePyatka, Box } from 'pyatka-ui';
import styled from 'styled-components';
import { AppLink, WishListToggler } from 'components';
import { ProductCardLayoutProps } from '../types';
import { ProductDescription } from '../NewCard/components';
import { GiftBox, ProductImage, ProductRating } from '../components';
import { ProductPriceNew } from '../NewCard/ProductCard.LayoutNew';

const CLOTHES = [43613];

const ProductCardLayoutMain: React.FC<ProductCardLayoutProps> = ({
    lineClamp = 2,
    appearance = 'slide',
    // Product
    label,
    productName,
    image,
    image_preload,
    url,
    isLoading = false,
    price_formatted,
    price,
    discountPrice_formatted,
    gift_exist = false,
    offer_id,
    algoliaHandler,
    handleClickProduct,
    isPreventLink,
    productId,
    category_ids = [],
    discount_percent,
    // image_urls = [],
    handleMetric,
    jdv_labels,
    promo_labels_admin,
    effect,
    labels,
    avgRate = '',
    totalRecords = 0,
    isZeroPrice,
}) => {
    const { scales } = usePyatka();

    const wishListSize = scales?.isMobile ? { width: '20px', height: '18px' } : {};
    const isClothes = category_ids.some((category) => CLOTHES.includes(category)) || isLoading;
    // const imagesList = (image_urls || []).length > 1 ? [...(image_urls || [])] : [image];

    return (
        <FlexBox
            width="100%"
            height="100%"
            cursor="pointer"
            position="relative"
            flexDirection="column"
            justifyContent="space-between"
            backgroundColor="block.main"
            onClick={handleMetric}
        >
            <ImageWrapper isMobile={scales?.isMobile}>
                <FlexBox
                    pt={{ default: '12px', md: '16px' }}
                    px={{ default: '12px', md: '16px' }}
                    justifyContent="space-between"
                    alignItems="center"
                    position="absolute"
                    height="36px"
                    pb="6px"
                    zIndex={2}
                    right={0}
                    left={0}
                    top={0}
                >
                    <div />

                    <WishListToggler
                        product_id={Number(productId) || Number(offer_id)}
                        productName={productName}
                        price={discountPrice_formatted || price}
                        link={url}
                        algoliaHandler={algoliaHandler}
                        {...wishListSize}
                    />
                </FlexBox>
                <GiftBox
                    show={gift_exist}
                    jdv_labels={jdv_labels}
                    promo_labels_admin={promo_labels_admin}
                />
                <Box>
                    <ProductImage
                        url={url}
                        key={image}
                        image={image}
                        image_preload={image_preload}
                        isLoading={isLoading}
                        appearance={appearance}
                        ariaLabel={productName}
                        handleClick={handleClickProduct}
                        isPreventLink={isPreventLink}
                        isClothes={isClothes}
                        effect={effect}
                        layout="main"
                        isNewImage
                        route_state={{
                            labels,
                            price_formatted,
                            totalRecords,
                            avgRate,
                            discountPrice_formatted,
                            offer_id,
                        }}
                    />

                    {!isLoading && <div className="product-overlay" />}
                </Box>
            </ImageWrapper>
            <FlexBox
                height="100%"
                flexDirection="column"
                justifyContent="flex-start"
                mt={scales?.isMobile ? '12px' : '20px'}
            >
                <AppLink
                    to={url}
                    onClickProduct={handleClickProduct}
                    preventOnClick={isPreventLink}
                    route_state={{
                        labels,
                        price_formatted,
                        totalRecords,
                        avgRate,
                        discountPrice_formatted,
                        offer_id,
                    }}
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        // justifyContent: 'space-between',
                        justifyContent: 'flex-start',
                        flex: 1,
                    }}
                >
                    <ProductPriceNew
                        isZeroPrice={isZeroPrice}
                        layout="main"
                        label={label}
                        isLoading={isLoading}
                        price_formatted={price_formatted}
                        discount_percent={discount_percent}
                        discountPrice_formatted={discountPrice_formatted}
                    />
                    <ProductDescription
                        isZeroPrice={isZeroPrice}
                        lineClamp={lineClamp}
                        productName={productName}
                        isLoading={isLoading}
                    />
                    <ProductRating
                        isLoading={isLoading}
                        avgRate={avgRate}
                        totalRecords={totalRecords}
                    />
                </AppLink>
            </FlexBox>
        </FlexBox>
    );
};

const ImageWrapper = styled.div<{ isMobile: boolean }>`
    position: relative;
    flex: 1;

    & li {
        border: none;
    }

    & .product-overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: ${(props) => (props.isMobile ? '14px' : '30px')};
        background: rgba(190, 190, 182, 0.1);
        pointer-events: none;
        z-index: 10;
    }
`;

export default React.memo(ProductCardLayoutMain);
