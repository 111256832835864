import React, { useEffect } from 'react';
import { Icon, usePyatka } from 'pyatka-ui';
import { useSelector } from 'react-redux';
import { AppMetricEvents } from 'AppMetric';
import { GtmEvents } from 'metrics';
import { isNative } from 'utils/platform';
import WishlistTooltip from 'components/WishListToggler/WishlistTooltip';
import { AppTooltip } from 'plugins/tooltip';
import { isUserAuth } from 'modules/Authorization/Authorization.selectors';
import { Dialog } from 'plugins/dialog';
import AddToCollectionModal from 'pages/WishListServicePage/v2/components/AddToCollectionModal';
import { WishListTogglerLayoutProps } from './types';

export const WishListTogglerLayout: React.FC<WishListTogglerLayoutProps> = (props) => {
    const {
        product_id,
        productName,
        price,
        isFavorite,
        toggleWishListItem,
        width = '26px',
        height = '25px',
    } = props;
    const { scales } = usePyatka();
    const isMobile = (scales.matched || []).find((el: any) => el.name === 'md');
    const CONTAINER_ID = `wishlist-container-${product_id}`;

    const isAuth = useSelector(isUserAuth);

    const toggleItem = () => {
        toggleWishListItem({
            product_id,
        });
        if (!isFavorite) {
            AppMetricEvents.jmart_wishlist_product_add({
                product_id: product_id,
            });
            GtmEvents.addToWishlist({ product_id, productName, price });
        } else {
            AppMetricEvents.jmart_wishlist_product_delete({
                product_id: product_id,
            });
        }
    };
    const handleClick = (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        if (!isMobile && !isFavorite) {
            AppTooltip.openTooltip({
                container_id: CONTAINER_ID,
                body: WishlistTooltip,
                props: {
                    onClick: !isAuth ? toggleItem : handleAddToCollection,
                },
            });
        } else {
            AppTooltip.closeTooltip();
        }
        if (isNative() || isMobile) {
            toggleItem();
            return;
        }
        if (!isMobile && isAuth) {
            toggleItem();
            return;
        }
    };

    const handleAddToCollection = () => {
        Dialog.openDialog({
            body: AddToCollectionModal,
            props: {
                onClose: () => {
                    Dialog.closeDialog();
                },
            },
            isOwnModal: true,
        });
    };
    useEffect(() => {
        return () => {
            AppTooltip.closeTooltip();
        };
    }, []);

    return (
        <>
            <div onClick={handleClick} data-tooltip-id={CONTAINER_ID}>
                <Icon
                    fill={isFavorite ? 'block.brand_variant' : 'block.placeholder'}
                    icon={isFavorite ? 'new_fill_heart' : 'new_heart'}
                    width={width}
                    height={height}
                />
            </div>
        </>
    );
};
