import React from 'react';
import { FlexBox, usePyatka } from 'pyatka-ui';
import { ProductLabel } from 'components';
import { Product } from 'modules/ProductsList/types';
import { signs } from 'utils/symbols';

interface IProductLabels {
    label: Product['label'];
    isLoading: boolean;
    show: boolean;
}

const ProductLabels: React.FC<IProductLabels> = ({
    show,
    label: { bonusLabel, installment } = {},
    isLoading,
}) => {
    const { scales } = usePyatka();

    if (!show || isLoading) {
        return null;
    }

    return (
        <FlexBox
            gap="2px"
            alignItems="flex-start"
            mt={['0px', '5px', '11px']}
            mb={['5px', '6px', '11px']}
        >
            {!isLoading && bonusLabel && (
                <ProductLabel
                    variant="bonus"
                    padding={scales?.isMobile ? '2px 8px' : '3px 6px'}
                    fontSize={{ default: '11px', md: '15px' }}
                >
                    {bonusLabel?.text?.replace('Б', '')}
                </ProductLabel>
            )}
            {!isLoading && installment && (
                <ProductLabel
                    variant="price"
                    padding={scales?.isMobile ? '2px 8px' : '3px 10px'}
                    isLoading={isLoading}
                    paymentTimes={`x${installment?.term}`}
                    fontSize={{ default: '11px', md: '15px' }}
                >
                    {installment.price_formatted} {signs.tg}
                </ProductLabel>
            )}
        </FlexBox>
    );
};

export default ProductLabels;
