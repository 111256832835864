import { AppRouteProps } from 'router/types';
import * as Layouts from 'layouts';

export const RoutesSortByPath = (routes: AppRouteProps[]) => {
    const getPathLength = (item: string) => item.split('/').length;
    return routes.sort((a, b) => {
        const leftPath = getPathLength(a.path);
        const rightPath = getPathLength(b.path);
        if (leftPath > rightPath) {
            return -1;
        }
        if (leftPath < rightPath) {
            return 1;
        }
        return 0;
    });
};

const RouteNormalize = (route: AppRouteProps): AppRouteProps => ({
    ...route,
    exact: 'exact' in route ? route.exact : false,
    isPublic: 'isPublic' in route ? route.isPublic : true,
    scrollToTop: 'scrollToTop' in route ? route.scrollToTop : true,
});

const RoutesLayout = (routes: AppRouteProps[]) => {
    return routes.map(
        (route: AppRouteProps): AppRouteProps => ({
            ...route,
            layout: 'layout' in route ? route.layout : Layouts.DefaultLayout,
        })
    );
};

const RoutesPrepare = (
    routesConfig: AppRouteProps[],
    parentPath: string,
    parentIsPublic: boolean | null | undefined
): AppRouteProps[] => {
    const resultRoutes = RoutesSortByPath(routesConfig);
    return resultRoutes.map((route) => {
        if (route.redirect) {
            return route;
        }
        const newRoute = RouteNormalize(route);
        // sub-route path  -> combining with parent router path
        if (parentPath) {
            newRoute.path = parentPath + newRoute.path;
        }
        // sub-route isPublic  -> combining with parent router path
        if (parentIsPublic !== null) {
            if (newRoute.isPublic !== parentIsPublic) {
                newRoute.isPublic = parentIsPublic;
            }
        }
        // preparing sub routes recursive
        if (newRoute.routes) {
            newRoute.routes =
                RoutesPrepare(newRoute.routes, newRoute.path, newRoute.isPublic) || [];
        }
        return { ...newRoute };
    });
};

export const FetchWithAnchorValidate = (callBack: Function, callbackForce?: boolean) => {
    if (__BROWSER__) {
        const pageAnchor = window.__PRELOADED_STATE__.page;
        const { pathname, search } = window.location;
        if (pageAnchor !== `${pathname}${search}` || callbackForce) {
            window.__PRELOADED_STATE__.page = `${pathname}${search}`;
            callBack();
        }
        return;
    }
    callBack();
    return;
};

export const prepareRouterConfig = (routesConfig: AppRouteProps[]): AppRouteProps[] =>
    RoutesLayout(RoutesPrepare(routesConfig, '', null));
