import { isNative } from 'utils/platform';
import { nativeCall } from 'hybrid/index';
import { AppMetricEvents } from 'AppMetric/index';
import { ItemMetric } from 'AppMetric/types';
import { AmpEvents } from 'metrics';
import { Product } from 'modules/ProductsList/types';

const METRIC_TYPE = 'appMetric';

interface appMetricPayload {
    [key: string]: any;
}

interface appMetricEventSource extends appMetricPayload {
    source?: string;
}

interface appMetricDataTypes {
    type: string;
    payload?: appMetricPayload | appMetricEventSource;
    source_url?: string | boolean;
}

export const appMetricCallNative = (props: appMetricDataTypes) => {
    console.log(`[AppMetric]: "${props.type}"; payload: `, props.payload);
    if (isNative() && __IS_PROD__) {
        const appMetricBody: appMetricDataTypes = {
            type: `${!__IS_PROD__ ? 'test' : ''}${METRIC_TYPE}`,
            payload: props,
            source_url: __BROWSER__ && window && window.location.pathname,
        };
        nativeCall(appMetricBody);
    }
};

// --------------------
export const anyToInt = (data: any) => {
    if (!data) {
        return 0;
    }
    if (typeof data === 'string') {
        return parseInt(data.replace(/[^\d]*/g, ''));
    }
    return data;
};

enum PathnameEnum {
    MAIN = '/',
    CATALOG = '/catalog',
    PRODUCT_DETAIL = '/products',
    SUPERMARKETS = '/store',
}

// ------------ CATEGORY BOX METRIC ------------- //

export const dispatchCategoryMetric = (
    category_id: string | undefined,
    location: any,
    title: string,
    category1?: string | null
) => {
    if (!category_id || location?.pathname === PathnameEnum.SUPERMARKETS) {
        return;
    }

    if (location?.pathname === PathnameEnum.MAIN) {
        AppMetricEvents.category_click({
            item_id: category_id as string,
            item_name: title,
        });
        return;
    }

    if (location?.pathname === PathnameEnum.CATALOG) {
        AppMetricEvents.jmart_category({
            type: 'jmart_catalog_category2_click',
            category_id: category_id,
            category_name: title,
            category1,
        });
        return;
    }

    AppMetricEvents.jmart_category({
        category_id: category_id,
        category_name: title,
        source: 'catalog',
        category1,
    });
};

// -------------- DEPARTMENT METRIC --------------- //
export const dispatchDepartmentMetric = (id?: number, company_name?: string, isStore?: boolean) => {
    switch (id) {
        // --- Товары
        case 64:
            AppMetricEvents.jmart_goods_main_click();
            break;
        // --- Продукты
        case 65:
            AppMetricEvents.jmart_supermarkets_main_click();
            break;
        // --- Услуги
        case 66:
            AppMetricEvents.jmart_services_main_click();
            break;
        // --- Аптеки
        case 78:
            AppMetricEvents.jmart_pharmacy_main_click();
            break;
        // --- Стать продавцом
        case 79:
            AppMetricEvents.jmart_become_partner_main_click();
            break;
        // --- QR Lite
        case 80:
            AppMetricEvents.jmart_qrlite_main_click();
            break;
        // --- DIY /home
        case 82:
            AppMetricEvents.jmart_diy_click();
            break;
        // --- other
        default:
            if (company_name && isStore) {
                AppMetricEvents.jmart_selection_supermarket_click({ company_name });
            }
    }
};

export const createClickEvent = (type: string) => () => {
    AmpEvents.sendEvent(type);
    appMetricCallNative({ type });
};

export const AppMetricItemParser = (product: Product): ItemMetric => ({
    product_id: product?.product_id || product?.productId,
    product_name: product?.productName,
    category_path: product?.category_ids,
    product_price: String(
        product?.discountPrice_formatted ||
            product?.discountPrice ||
            product?.price ||
            product?.price_formatted
    ),
    // @ts-ignore
    merchant_id: product?.company_id,
    // @ts-ignore
    promotions: product?.promotions,
    // @ts-ignore
    merchant_name: product?.merchant_name,
    // @ts-ignore
    category_name: product?.category_name,
    type: '',
});
