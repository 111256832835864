import { ApiGwRequest } from 'services';
import { PRODUCT_DETAILS_GET_REQ_INDICATOR, PRODUCT_DETAILS_SEO_GET_REQ_INDICATOR } from 'keys';

export const getGWProductDetails = (id: string) => {
    return ApiGwRequest({
        indicator: PRODUCT_DETAILS_GET_REQ_INDICATOR,
        url: `catalog/v1/products/${id}`,
        method: 'get',
        showError: true,
    });
};

export const getGWProductDetailsSeo = (id: string) => {
    return ApiGwRequest({
        indicator: PRODUCT_DETAILS_SEO_GET_REQ_INDICATOR,
        url: 'seo/v1/render',
        method: 'post',
        data: {
            object_type: 'P',
            object_id: id,
        },
    });
};

export const fetchSimilarProducts = (product_id: string, limit = 10) => {
    return ApiGwRequest({
        url: 'listing/v1/recommendations/products',
        method: 'get',
        params: { product_id, limit },
    });
};

// С этим товаром покупают
export const fetchCrossProducts = (product_id: string | number) => {
    return ApiGwRequest({
        url: `listing/v1/products/cross-products/${product_id}`,
        method: 'get',
    });
};

export const fetchOffersById = (id: string, params?: any) => {
    return ApiGwRequest({
        indicator: PRODUCT_DETAILS_GET_REQ_INDICATOR,
        url: `catalog/v1/child-products/by-parent-id/${id}?page=1&per_page=500`,
        method: 'get',
        params,
    });
};

export const fetchProductByOfferId = (id: string) => {
    return ApiGwRequest({
        indicator: PRODUCT_DETAILS_GET_REQ_INDICATOR,
        url: 'catalog/v1/products/by_ids',
        method: 'get',
        params: {
            'product_id[]': id,
        },
    });
};
