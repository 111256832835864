import { CaseReducer, current, PayloadAction } from '@reduxjs/toolkit';
import {
    Instruction,
    IReview,
    ReviewService,
    ReviewServiceInitialState,
} from 'modules/ReviewService/types';

const SetReviewModalState: CaseReducer<
    ReviewServiceInitialState,
    PayloadAction<{
        instructions?: Instruction[];
        values?: IReview;
    }>
> = (_state, _action) => {
    const { payload } = _action;

    if (payload.instructions) {
        _state.instructions = payload.instructions;
    }

    if (payload.values) {
        _state.values = payload.values;
    }
};

const SubmitReview: CaseReducer<
    ReviewServiceInitialState,
    PayloadAction<{ serviceName: ReviewService; review: IReview }>
> = (state, action) => {};

const getProductReviews: CaseReducer<
    ReviewServiceInitialState,
    PayloadAction<{ id: string | number; params: any }>
> = (_state, _action) => {
    if (Number(_action?.payload?.params?.page) === 1) {
        _state.product_review_data = {
            reviews: {},
        };
    }

    // const old_product_details = current(_state?.product_details);
    // const old_product_id = old_product_details?.product_id;
    // const new_product_id = _action?.payload?.id;
    //
    // if (String(old_product_id) !== String(new_product_id)) {
    //     _state.product_review_data = {
    //         reviews: {},
    //     };
    // }
};

// TODO: payload action type
const getProductReviewsSucceed: CaseReducer<ReviewServiceInitialState, PayloadAction<any>> = (
    _state,
    _action
) => {
    const { payload } = _action;

    const curr = current(_state?.product_review_data?.reviews);

    const previousReviews = Array.isArray(curr) ? curr : [];

    const newReviews = payload?.data.items;
    _state.product_review_data = {
        rates: payload?.data?.rates,
        pagination: payload?.data?.pagination,
        reviews: [...previousReviews, ...newReviews],
        image_collection: payload?.data?.imageCollection,
    };

    _state.product_review_info = {
        rates: payload?.data?.rates,
    };
};

const getProductReviewsFailed: CaseReducer<ReviewServiceInitialState, PayloadAction> = (
    _state,
    _action
) => {
    _state.product_review_data.reviews = [];
};

const clearProductReviewInfo: CaseReducer<ReviewServiceInitialState, PayloadAction> = (
    _state,
    _action
) => {
    _state.product_review_info = {};
};

export default {
    SetReviewModalState,
    SubmitReview,

    //REVIEWS
    getProductReviews,
    getProductReviewsSucceed,
    getProductReviewsFailed,
    clearProductReviewInfo,
};
