import React from 'react';
import { toast } from 'react-toastify';
import { AppToasterProps, AppToasterTypes } from 'components/AppToaster/types';
import { AppToasterTemplate } from 'components';

const toasterOptions = {
    closeButton: false,
    hideProgressBar: true,
};

const showToast = (options: Omit<AppToasterProps, 'icon'>, icon: string) => {
    const props = { ...options, icon };
    const { clearPrevious = true } = props;
    if (clearPrevious) {
        toast.dismiss();
    }
    toast.info(<AppToasterTemplate {...props} />, { ...toasterOptions });
};
const showCustom = (options: AppToasterProps) => {
    const props = { ...options, isCustom: true };
    toast.info(<AppToasterTemplate {...props} />, {
        ...toasterOptions,
    });
};
export const AppToaster: AppToasterTypes = {
    info: (options: Omit<AppToasterProps, 'icon'>) => showToast(options, 'sing-info'),
    warning: (options: Omit<AppToasterProps, 'icon'>) => showToast(options, 'sing-warning'),
    error: (options: Omit<AppToasterProps, 'icon'>) => showToast(options, 'sing-error'),
    success: (options: Omit<AppToasterProps, 'icon'>) => showToast(options, 'sing-success'),
    wish: (options: AppToasterProps) => showCustom(options),
};
