import React from 'react';
import { Carousel, FlexBox, usePyatka, Box } from 'pyatka-ui';
import styled from 'styled-components';
import { AppLink, CartProductCounter, ProductLabel, WishListToggler } from 'components';
import { SimilarProductsButton } from 'components/ProductCard/NewCard/components/SimilarProductsButton';
import { ProductCardLayoutProps, ProductPriceNewProps } from '../types';
import { GiftBox, ProductImage, ProductRating } from '../components';
import { SuperCartHandler } from '../SmallCard/ProductCard.LayoutSmall';
import ZoomImg from '../components/ZoomImg';
import { AddToCartButton, ProductDescription, DiscountPercent, ProductPrice } from './components';

const CLOTHES = [43613];

const ProductCardLayoutNew: React.FC<ProductCardLayoutProps> = ({
    lineClamp = 2,
    appearance = 'slide',
    btnStyle = 'default',
    isInCart = false,
    firstOfferInCart,
    addToCartHandler,
    // Product
    label,
    productName,
    type,
    image,
    image_preload,
    url,
    isLoading = false,
    price_formatted,
    price,
    discountPrice_formatted,
    gift_exist = false,
    offer_id,
    algoliaHandler,
    handleClickProduct,
    isPreventLink,
    productId,
    category_ids = [],
    discount_percent,
    image_urls = [],
    handleMetric,
    jdv_labels,
    promo_labels_admin,
    effect,
    avgRate = '',
    totalRecords = 0,
    labels,
    isZeroPrice,
}) => {
    const { scales } = usePyatka();

    const wishListSize = scales?.isMobile
        ? { width: '20px', height: '18px' }
        : { width: '29px', height: '26px' };
    const hasPaymentTimes = Boolean(label?.installmentLabel);
    const isClothes = category_ids.some((category) => CLOTHES.includes(category)) || isLoading;
    const imagesList = (image_urls || []).length > 1 ? [...(image_urls || [])] : [image];

    return (
        <FlexBox
            width="100%"
            height="100%"
            cursor="pointer"
            position="relative"
            flexDirection="column"
            justifyContent="space-between"
            backgroundColor="block.main"
            onClick={handleMetric}
        >
            <ImageWrapper isMobile={scales?.isMobile} hasMargin={imagesList.length > 1}>
                <FlexBox
                    pb="6px"
                    pt={scales?.isMobile ? '12px' : '20px'}
                    px={scales?.isMobile ? '12px' : '20px'}
                    position={isClothes ? 'absolute' : 'relative'}
                    height={scales?.isMobile ? '36px' : '46px'}
                    justifyContent="space-between"
                    alignItems="center"
                    zIndex={2}
                    right={0}
                    left={0}
                    top={0}
                >
                    {hasPaymentTimes && !isLoading ? (
                        <ProductLabel
                            padding={scales?.isMobile ? '0px 6px' : '0 8px'}
                            height={scales?.isMobile ? '20px' : '26px'}
                            fontSize={{ default: '13px', md: '18px' }}
                            variant="credit"
                            marginRight="-8px"
                            style={{
                                marginTop: '1px',
                            }}
                        >
                            {(label?.installmentLabel!.text || '').replace(/-/gi, '•')}
                        </ProductLabel>
                    ) : (
                        <div />
                    )}
                    <WishListToggler
                        product_id={Number(productId) || Number(offer_id)}
                        productName={productName}
                        price={discountPrice_formatted || price}
                        link={url}
                        algoliaHandler={algoliaHandler}
                        {...wishListSize}
                    />
                </FlexBox>
                <GiftBox
                    show={gift_exist}
                    hasMargin={imagesList.length > 1}
                    jdv_labels={jdv_labels}
                    promo_labels_admin={promo_labels_admin}
                />
                <Box>
                    <ZoomImg>
                        <Carousel
                            showDots
                            activeDotsColor="#AAADB2"
                            defaultDotsColor="#bebeb666"
                            carouselType={scales?.isMobile ? 'magnetic' : 'hover'}
                            slidesToShowGroup={{ mobile: 1, tablet: 1, desktop: 1 }}
                        >
                            {imagesList.map((image: string) => (
                                <ProductImage
                                    url={url}
                                    key={image}
                                    image={image}
                                    image_preload={image_preload}
                                    isLoading={isLoading}
                                    appearance={appearance}
                                    ariaLabel={productName}
                                    handleClick={handleClickProduct}
                                    isPreventLink={isPreventLink}
                                    isClothes={isClothes}
                                    effect={effect}
                                    layout="new"
                                    isNewImage
                                    route_state={{
                                        labels,
                                        price_formatted,
                                        totalRecords,
                                        avgRate,
                                        discountPrice_formatted,
                                        offer_id,
                                    }}
                                />
                            ))}
                        </Carousel>
                    </ZoomImg>

                    {!isLoading && <div className="product-overlay" />}
                </Box>
            </ImageWrapper>
            <FlexBox
                mt={scales?.isMobile ? '12px' : '8px'}
                height="100%"
                flexDirection="column"
                justifyContent="flex-start"
            >
                <AppLink
                    to={url}
                    onClickProduct={handleClickProduct}
                    preventOnClick={isPreventLink}
                    route_state={{
                        labels,
                        price_formatted,
                        totalRecords,
                        avgRate,
                        discountPrice_formatted,
                        offer_id,
                    }}
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        // justifyContent: 'space-between',
                        justifyContent: 'flex-start',
                        flex: 1,
                    }}
                >
                    <ProductPriceNew
                        label={label}
                        isLoading={isLoading}
                        isZeroPrice={isZeroPrice}
                        price_formatted={price_formatted}
                        discount_percent={discount_percent}
                        discountPrice_formatted={discountPrice_formatted}
                    />
                    <ProductDescription
                        isZeroPrice={isZeroPrice}
                        lineClamp={lineClamp}
                        productName={productName}
                        isLoading={isLoading}
                    />
                </AppLink>
                <ProductRating
                    isLoading={isLoading}
                    avgRate={avgRate}
                    totalRecords={totalRecords}
                />
            </FlexBox>
            <div style={{ marginTop: scales?.isMobile ? 16 : 25, height: 43 }}>
                {isZeroPrice ? (
                    <SimilarProductsButton category_ids={category_ids} />
                ) : isInCart && !isLoading ? (
                    <CartProductCounter
                        offer_id={String(firstOfferInCart?.offer_id)}
                        product_type={type}
                        fullWidth={btnStyle === 'secondary'}
                        decrease_bg="block.secondary"
                    />
                ) : btnStyle === 'secondary' ? (
                    <SuperCartHandler
                        bg="block.secondary"
                        addToCartHandler={addToCartHandler}
                        isLoading={isLoading}
                        height="32px"
                    />
                ) : (
                    <AddToCartButton addToCartHandler={addToCartHandler} isLoading={isLoading} />
                )}
            </div>
        </FlexBox>
    );
};

export const ProductPriceNew: React.FC<ProductPriceNewProps> = ({
    label,
    isLoading,
    layout = 'new',
    price_formatted,
    discount_percent,
    discountPrice_formatted,
    isZeroPrice,
}) => (
    <>
        <ProductPrice
            label={label}
            isZeroPrice={isZeroPrice}
            layout={layout}
            isLoading={isLoading}
            price_formatted={price_formatted}
            discountPrice_formatted={discountPrice_formatted}
        />

        <DiscountPercent
            layout={layout}
            price_formatted={price_formatted}
            discount_percent={discount_percent}
            discountPrice_formatted={discountPrice_formatted}
        />
    </>
);

interface ImageWrapperProps {
    hasMargin: boolean;
    isMobile?: boolean;
}

const ImageWrapper = styled.div<ImageWrapperProps>`
    position: relative;
    margin-bottom: ${(props) => (!props.hasMargin ? '15px' : 0)};
    flex: 1;

    & li {
        border: none;
    }

    img {
        width: auto;
    }

    & .product-overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        margin-bottom: 0px;
        bottom: ${(props) => (props.hasMargin ? '15px' : 0)};
        border-radius: ${(props) => (props.isMobile ? '15px' : '30px')};
        background: rgba(190, 190, 182, 0.1);
        pointer-events: none;
        z-index: 10;
    }
`;

export default React.memo(ProductCardLayoutNew);
