import slice from 'modules/Promotions/Promotions.slice';

export const {
    requestPromotions,
    fetchPromotions,
    requestPromotionDetails,
    fetchPromotionDetails,
    resetPromotionDetails,
    getPromotionModal,
    getPromotionModalSucceed,
    getPromotionModalFailed,
} = slice.actions;
