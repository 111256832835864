import React from 'react';
import { Helmet } from 'react-helmet-async';
import { GoBackBtn, ShareBtn, Title, HeaderWrapper } from '../components';

const CompanyHeader: React.FC = () => {
    const [title, setTitle] = React.useState('');
    const onChangeTitle = (newState: any) => setTitle(newState?.title || '');

    return (
        <React.Fragment>
            <Helmet onChangeClientState={onChangeTitle} />
            <HeaderWrapper>
                <GoBackBtn />
                <Title title={title} type="animation" />
                <ShareBtn />
            </HeaderWrapper>
        </React.Fragment>
    );
};

export default React.memo(CompanyHeader);
