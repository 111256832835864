import React from 'react';
import Modal from 'react-modal';
import { FlexBox, Icon, Text, usePyatka } from 'pyatka-ui';
import styles from 'components/Modal/Modal.module.scss';
import { useI18n } from 'hooks/useI18n';
import { ModalLayoutProps } from './types';

Modal.setAppElement('#app');

export const ModalLayout: React.FC<ModalLayoutProps> = ({
    toggleCloseBtn,
    changeTitle,
    toggleBackButton,
    backButtonTrigger,
    children,
    modalTitle,
    closeButtonHandler,
    scrollRef,
    closeBtn,
    bottomSheet = false,
    maxHeight,
    noPadding = false,
    overlayHeader = false,
    isMobile,
    showMobileTitle = true,
}: any) => {
    const showBottomSheet = isMobile && bottomSheet;

    const childrenWithProps = React.Children.map(children, (child) => {
        // Checking isValidElement is the safe way and avoids a typescript
        if (React.isValidElement(child)) {
            return React.cloneElement(child, {
                toggleCloseBtn,
                changeTitle,
                toggleBackButton,
                backButtonTrigger,
                closeButtonHandler,
            });
        }
        return child;
    });

    return (
        <div style={{ maxHeight }}>
            {showBottomSheet && (
                <div
                    style={{
                        width: '100%',
                        textAlign: 'center',
                        zIndex: 999,
                        position: noPadding ? 'absolute' : 'initial',
                    }}
                    onClick={closeButtonHandler}
                >
                    <Icon
                        icon="drag"
                        height="5px"
                        // @ts-ignore
                        style={{ width: '2.625rem' }}
                    />
                </div>
            )}

            <ModalHeader
                modalTitle={modalTitle}
                closeButtonHandler={closeButtonHandler}
                closeBtn={showBottomSheet ? false : closeBtn}
                overlayHeader={overlayHeader}
                showMobileTitle={showMobileTitle}
                isMobile={isMobile}
            />

            <div
                ref={scrollRef}
                id="styleScroll"
                className={`${styles.body} scroll-bar`}
                style={{ paddingBottom: showBottomSheet ? '0px' : undefined }}
            >
                <div className={styles.content}>{childrenWithProps}</div>
            </div>
        </div>
    );
};

const ModalHeader: React.FC<
    Pick<
        ModalLayoutProps,
        | 'modalTitle'
        | 'closeBtn'
        | 'closeButtonHandler'
        | 'overlayHeader'
        | 'showMobileTitle'
        | 'isMobile'
    >
> = ({
    modalTitle = '',
    closeBtn,
    closeButtonHandler,
    overlayHeader = false,
    showMobileTitle = true,
    isMobile = false,
}) => {
    const { $t } = useI18n();
    if (!closeBtn && !modalTitle) {
        return null;
    }

    const showTitle = String(modalTitle || '').length > 0 && (isMobile ? showMobileTitle : true);

    return (
        <FlexBox
            position={overlayHeader ? 'absolute' : 'initial'}
            top={0}
            left={0}
            right={0}
            zIndex={10}
            py={{
                default: closeBtn ? '20px' : '8px 0 12px',
                md: 2,
            }}
            alignItems="center"
        >
            <FlexBox
                width="100%"
                alignItems="flex-end"
                justifyContent="space-between"
                px={{ default: '10px', md: 0 }}
            >
                {showTitle && (
                    <FlexBox flexGrow={1} mb="0px">
                        <Text
                            lineClamp={1}
                            fontWeight="700"
                            color="text.main"
                            fontSize={{ default: 22, md: '28px' }}
                            lineHeight={{ default: '28.16px', md: '35.84px' }}
                        >
                            {$t(modalTitle)}
                        </Text>
                    </FlexBox>
                )}

                {closeBtn && <CloseBtn closeButtonHandler={closeButtonHandler} />}
            </FlexBox>
        </FlexBox>
    );
};

export const CloseBtn: React.FC<Pick<ModalLayoutProps, 'closeButtonHandler'>> = ({
    closeButtonHandler,
}) => (
    <FlexBox
        bg="block.secondary"
        width="36px"
        height="36px"
        cursor="pointer"
        borderRadius="50%"
        alignItems="center"
        justifyContent="center"
        onClick={closeButtonHandler}
        marginLeft="auto"
    >
        <Icon icon="close" fill="text.placeholder" />
    </FlexBox>
);
