import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware, { END } from 'redux-saga';
import { createBrowserHistory, createMemoryHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import { rootReducer, rootSaga } from 'modules';

const clientStore = (): any => {
    const history = createBrowserHistory();
    return {
        history,
        config: {
            preloadedState: window.__PRELOADED_STATE__?.state,
        },
    };
};

const serverStore = (url: string): any => {
    const history = createMemoryHistory({ initialEntries: [url] });
    return {
        history,
        config: {
            preloadedState: {},
        },
    };
};

export const configureAppRedux = (payload?: { initialUrl: string }): any => {
    const universalPayload = __BROWSER__ ? clientStore() : serverStore(payload?.initialUrl || '/');

    const sagaMiddleware = createSagaMiddleware();
    const middlewares = [routerMiddleware(universalPayload.history), sagaMiddleware];

    const mainConfig: any = {
        reducer: rootReducer,
        middleware: middlewares,
        ...universalPayload.config,
    };

    const store: any = configureStore(mainConfig);
    store.runSaga = sagaMiddleware.run;
    store.close = () => store.dispatch(END);

    return {
        store,
        history: universalPayload?.history,
        rootSaga,
    };
};

export const AppRedux = configureAppRedux();
