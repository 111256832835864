import {
    CartServiceClear,
    CartServiceClearV3,
    CartServiceDelete,
    CartServiceDeleteV3,
    CartServiceGet,
    CartServiceGetCountV3,
    CartServiceGetV3,
    CartServicePostByProductsV3,
    CartServicePut,
    CartServicePutAmountV3,
    CartServicePutV3,
} from 'modules/CartService/CartService.services';
import { AppCartServices } from 'contexts/useCartService/types';

// export const
export const AppCartSyncServices: AppCartServices = {
    getCount: CartServiceGetCountV3,
    postByProducts: CartServicePostByProductsV3,
    get: CartServiceGetV3,
    put: CartServicePutV3,
    putAmount: CartServicePutAmountV3,
    clear: CartServiceClearV3,
    clearGroup: CartServiceClearV3,

    // get: CartServiceGet,
    // put: CartServicePut,
    // delete: CartServiceDelete,
    // clear: CartServiceClear,
    //
    // delete: CartServiceDeleteV3,
    // clear: CartServiceClearV3,
};
