import { MerchantsInitialState } from './types';

const state: MerchantsInitialState = {
    data: {
        company: '',
        company_id: 0,
        phone_for_client: '',
        total: 0,
        logo: null,
    },
    products: [],
    prevUrl: '',
    active_filters: {},
    filters_object: {},
    filters_order: [],
    parentCategory: null,
    categories: [],
    page: 1,
    total_pages: 0,
    total_count: 0,
};

export default state;
