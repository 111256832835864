import React, { useState, useMemo } from 'react';
import { ReviewModalLayoutMerch } from 'components/ReviewModal/ReviewModal.LayoutMerch';
import { ReviewModalLayoutProduct } from 'components/ReviewModal/ReviewModal.LayoutProduct';
import { useForm } from 'hooks';
import { ReviewConfig, ReviewValidators } from 'modules/ReviewService/config';

import { Instruction, IReview, IReviewField } from 'modules/ReviewService/types';
import { AppMetricEvents } from 'AppMetric';
import { formatAddressesForNative } from 'components/ProfileAddress/helper';
import { ReviewModalProps } from './types';
import { ReviewModalLayout } from './ReviewModal.Layout';

export const ReviewModalContainer: React.FC<ReviewModalProps> & {
    Layout: typeof ReviewModalLayout;
} = (props) => {
    const { reviewService, SubmitReview, reviewValues, reviewInstructions } = props;

    const [type, method] = reviewService.split('-');
    const [inputPhotos, setInputPhotos] = useState<any>([]);
    const [uploadedPhotos, setUploadedPhotos] = useState<any>(reviewValues?.images || []);

    const appendPhotos = (newPhotos: any) => {
        let idx = inputPhotos.length || 0;

        const newFormattedPhotos = [];

        for (const file of newPhotos) {
            const formattedPhoto = {
                key: `${idx}-${file.name}`,
                file: file,
            };

            newFormattedPhotos.push(formattedPhoto);

            idx += 1;
        }

        // const newFormattedPhotos = newPhotos.map((file: any, index: number) => ({
        //     key: `${index}-${file.name}`,
        //     file: file,
        // }));

        const newArr = [...inputPhotos, ...newFormattedPhotos];
        const res = newArr.length > 5 ? newArr.slice(0, 5) : newArr;
        setInputPhotos(res);
    };

    const handleDelete = (key: string) => {
        const newArr = inputPhotos.filter((file: any) => file.key !== key);
        setInputPhotos(newArr);
    };

    const onFormSubmit = (form: IReview) => {
        AppMetricEvents.jmart_review_product({
            product_id: form?.product_id,
            is_recommended: form?.is_recommended,
            rate: form?.rate,
            admin_status: false, // TODO: currently is always false. (Потому что сейчас пользователь отправляет только а) новые отзывы б) редактирует отклоненные отзывы)
            review_push: false, //TODO: after push-notifications will be added
        });

        SubmitReview({
            order_id: reviewValues?.order_id,
            serviceName: reviewService,
            review: getReviewPayload(),
            // cli  TODO: проверку на сервис отзывов для Client Debug
        });
    };

    /*  сейчас в review validators есть все поля, не обязательно только те что в данной инструкции */
    const { form, formChangeHandler, formSubmitHandler, errors } = useForm(reviewValues, {
        validators: ReviewValidators,
        submit: onFormSubmit,
    });

    const getFormDataPayload = React.useCallback(() => {
        const formData = new FormData();

        if (form !== undefined) {
            console.log('1');
            for (const key in form) {
                console.log('2 ', key);
                if (key === 'is_recommended') {
                    const is_recommended_val = form[key] ? '1' : '0';
                    formData.append('is_recommended', is_recommended_val);
                } else if (key !== 'images') {
                    formData.append(key, form[key]);
                }
            }

            //TODO: addition check for limit of 5
            for (const photoFile of inputPhotos) {
                console.log('3', photoFile);
                formData.append('photos[]', photoFile.file);
            }
        }
        console.log(formData.get('photos[]'));
        return formData;
    }, [form, inputPhotos]);

    const getReviewPayload = React.useCallback(() => {
        return getFormDataPayload();

        // delete form.images;
        // return form;
    }, [form, getFormDataPayload]);

    const isReviewEditable = reviewValues?.status === 'create_review';
    console.log(uploadedPhotos, reviewValues?.status);
    const merchProps = {
        ...props,
        form,
    };

    const getConfigInstructions = (instructions: Instruction[]): IReviewField[] => {
        return instructions.map((item: Instruction) => {
            return ReviewConfig[item];
        });
    };

    const productProps = {
        ...props,
        form,
        formChangeHandler,
        formSubmitHandler,
        isReviewEditable,
        configInstructions: getConfigInstructions(reviewInstructions),
        errors,
        appendPhotos,
        handleDelete,
        uploadedPhotos,
        inputPhotos,
        reviewValues,
    };

    return (
        <React.Fragment>
            {type === 'merch' && <ReviewModalLayoutMerch {...merchProps} />}
            {type === 'product' && <ReviewModalLayoutProduct {...productProps} />}
        </React.Fragment>
    );
};

ReviewModalContainer.Layout = ReviewModalLayout;
