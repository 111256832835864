import { AppRouteMiddlewareLayer } from 'router/types';

const RouterRedirectCheck: AppRouteMiddlewareLayer = (config) => {
    const { route, history } = config;
    if (route.redirect) {
        history.push(route.redirect);
    }
    return config;
};

export { RouterRedirectCheck as default };
