import React from 'react';
import { Text, Variants } from 'pyatka-ui';
import styled, { css } from 'styled-components/macro';
import { space, SpaceProps } from 'styled-system';
import { IStyledProductLabel } from 'components/ProductLabel/types';

// --------------------------- Fill css by variants
// const handleVariant = (variant: IStyledProductLabel['variant']) => {
//     switch (variant) {
//         // BONUS
//         case VARIANTS[0]:
//             return css`
//                 background-color: #9bd050;
//                 border-radius: 13px;
//             `;
//         //  PRICE
//         case VARIANTS[1]:
//             return css`
//                 background-color: #ff7348;
//                 border-radius: 9px;
//                 margin-right: 3px;
//             `;
//         //  CREDIT
//         case VARIANTS[2]:
//             return css`
//                 background-color: #ff7348;
//                 border-radius: 9px;
//             `;
//         //   SAVES
//         case VARIANTS[3]:
//             return css`
//                 background-color: #f6f6f5;
//                 border-radius: 17px;
//             `;
//         //   LOAN
//         case VARIANTS[4]:
//             return css`
//                 background-color: #f9cb46;
//                 border-radius: 9px;
//                 margin-right: 3px;
//             `;
//         //    TRANS
//         case VARIANTS[5]:
//             return css`
//                 background-color: transparent;
//             `;
//     }
// };

const VARIANTS_LABEL: IVariantLabel = {
    bonus: 'success',
    price: 'brand',
    credit: 'brand',
    saves: 'secondary',
    loan: 'warning',
    transparent: 'trans',
};

interface IVariantLabel {
    [key: string]: string;
}

export const StyledProductLabelWrapper = styled('div')<SpaceProps>`
    ${space};
    display: inline-flex;
    align-items: center;
    line-height: 0;
`;

export const StyledProductLabel = styled((props) => <Text {...props} />)<IStyledProductLabel>`
    // font-family: 'Inter', serif;
    display: inline-flex;
    align-items: center;
    white-space: nowrap;
    gap: 4px;
    border-radius: 999rem;
    margin-right: 3px;

    img {
        margin-top: -1px;
    }

    ${(props) => {
        // @ts-ignore
        return css`
            ${(Variants as never)[props.styleType][VARIANTS_LABEL[props.variant]]}
            ${() => {
                return props.variant === 'credit' || props.variant === 'price'
                    ? `background-color: ${props.theme?.colors?.block?.brand_variant}`
                    : null;
            }}
            ${() => {
                return props.hasWhiteBg ? 'background-color: #fff' : null;
            }}
        `;
    }};
`;
