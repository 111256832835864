import { ReviewServiceInitialState } from 'modules/ReviewService/types';

export const DEFAULT_REVIEW_VALUES = {
    rate: 0,
    comment: '',
    dignity: '',
    limitations: '',
    is_recommended: false,
    company_id: -1,
    order_id: -1,
    product_id: -1,
    status: 'create_review',
    type: '',
};

const state: ReviewServiceInitialState = {
    instructions: [],
    values: DEFAULT_REVIEW_VALUES,
    product_review_data: {
        reviews: {},
    },
    product_review_info: {},
};
export default state;
