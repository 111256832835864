import React from 'react';
import { MobileLayoutProps, HeaderLayoutEnum } from './types';
import {
    MainHeader,
    DefaultHeader,
    ProductDetailHeader,
    CompanyHeader,
    SearchHeader,
    ListingHeader,
    CatalogHeader,
    ProfileHeader,
    WishlistHeader,
    WishlistCollectionHeader,
} from './MobileHeaders';

export const MobileLayout: React.FC<MobileLayoutProps> = (props) => {
    const { route, parentCategory } = props;

    switch (route.headerLayout) {
        // main-page
        case HeaderLayoutEnum.MAIN_HEADER:
            return <MainHeader />;

        // profile-page
        case HeaderLayoutEnum.PROFILE_HEADER:
            return <ProfileHeader />;

        // product-detail page
        case HeaderLayoutEnum.PRODUCT_DETAIL_HEADER:
            return <ProductDetailHeader />;

        // store-detail-page, merchant-page
        case HeaderLayoutEnum.COMPANY_HEADER:
            return <CompanyHeader />;

        // supermarkets-search-page, search-page
        case HeaderLayoutEnum.SEARCH_HEADER:
            return <SearchHeader />;

        // listing-page
        case HeaderLayoutEnum.LISTING_HEADER:
            return <ListingHeader parentCategory={parentCategory} />;

        // store-page
        case HeaderLayoutEnum.STORE_HEADER:
            return <SearchHeader />;

        // catalog-page
        case HeaderLayoutEnum.CATALOG_HEADER:
            return <CatalogHeader />;

        // wishlist-page
        case HeaderLayoutEnum.WISHLIST_HEADER:
            return <WishlistHeader />;
        case HeaderLayoutEnum.WISHLIST_COLLECTION_HEADER:
            return <WishlistCollectionHeader />;

        case HeaderLayoutEnum.DEFAULT_WITHOUT_SEARCH:
            return <DefaultHeader />;

        // other pages (partner-page, promotion-page, wishlist-page, etc)
        default:
            return <DefaultHeader isSearch />;
    }
};
