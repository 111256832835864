import React from 'react';
import { Box, Text, FlexBox } from 'pyatka-ui';
import { useAppRouter } from 'router/context';
import { ProductPriceNewProps } from 'components/ProductCard/types';
import { ProductDiscount } from '.';

type DiscountPercentProps = Pick<
    ProductPriceNewProps,
    'price_formatted' | 'discountPrice_formatted' | 'discount_percent' | 'layout'
>;

const sizes: Record<Exclude<DiscountPercentProps['layout'], undefined>, React.CSSProperties> = {
    new: {
        height: '19px',
        fontSize: '13px',
    },
    main: {
        height: '16px',
        fontSize: '12px',
    },
    small: {
        height: '14px',
        fontSize: '11px',
    },
    old: {},
};

const DiscountPercent: React.FC<DiscountPercentProps> = ({
    price_formatted: price,
    discountPrice_formatted: discountPrice,
    discount_percent,
    layout = 'new',
}) => {
    const { queryParams } = useAppRouter();

    const isWhite = Boolean(queryParams?.company_id);

    if (!discount_percent || !discountPrice) {
        return null;
    }

    const styles = sizes[`${layout}`];

    return (
        <FlexBox justifyContent="space-between" alignItems="center" mt={{ default: 0, md: '6px' }}>
            <ProductDiscount
                price={price}
                discountPrice={discountPrice}
                fontSize={{ default: styles.fontSize, md: '20px' }}
                lineHeight={{ default: styles.height, md: '26px' }}
            />

            <Box
                px="6px"
                zIndex={9}
                borderRadius="999rem"
                height={{ default: styles.height, md: '23px' }}
                backgroundColor={isWhite ? 'block.main' : 'block.secondary'}
            >
                <Text
                    fontWeight={700}
                    color="text.brand"
                    lineHeight={{ default: styles.height, md: '23px' }}
                    fontSize={{ default: styles.fontSize, md: '18px' }}
                >
                    -{discount_percent}
                </Text>
            </Box>
        </FlexBox>
    );
};

export default DiscountPercent;
