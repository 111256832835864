import React, { useCallback, useState } from 'react';
import { Box, Button, FlexBox, Icon, Image, Modal, Text, usePyatka } from 'pyatka-ui';
import { useDispatch, useSelector } from 'react-redux';
import { getWishlistCollections } from 'modules/Wishlist/Wishlist.selectors';
import CollectionItem from 'pages/WishListServicePage/v2/components/CollectionItem';
import { Overlay } from 'pages/WishListServicePage/v2/WishlistServicePage.Styles';
import { useI18n } from 'hooks/useI18n';
import {
    addItemToCollection,
    collectionFormMobileOverlaySet,
} from 'modules/Wishlist/Wishlist.actions';
import EditCollectionLayout from 'pages/WishListServicePage/v2/components/EditCollectionLayout';
import { AppMetricEvents } from 'AppMetric';

interface IAddToCollectionModalProps {
    open: boolean;
    onClose: () => void;
    product_id: number;
}

const AddToCollectionModal = (props: IAddToCollectionModalProps) => {
    const { open, onClose, product_id } = props;
    const { $t } = useI18n();
    const { scales } = usePyatka();
    const isMobile = (scales.matched || []).find((el: any) => el.name === 'md');
    const collections = useSelector(getWishlistCollections);
    const dispatch = useDispatch();
    const [openNewCollectionLayout, setOpenNewCollectionLayout] = useState(false);
    const onCreateCollectionClick = useCallback(() => {
        if (!isMobile) {
            setOpenNewCollectionLayout(true);
            return;
        }
        dispatch(
            collectionFormMobileOverlaySet({
                open: true,
                config: { product_id, layout_type: 'add' },
            })
        );
    }, [dispatch, product_id]);
    const onAddToCollection = useCallback(
        (props: { collection_id: string; collection_name: string }) => {
            const { collection_id, collection_name } = props;
            AppMetricEvents.jmart_add_to_collection();
            dispatch(addItemToCollection({ product_id, collection_id, collection_name }));
        },
        [dispatch, product_id]
    );
    const handleClose = () => {
        onClose();
        setOpenNewCollectionLayout(false);
    };
    return (
        <Modal
            open={open}
            onClose={handleClose}
            positionMode={isMobile ? 'mobile' : 'default'}
            width={isMobile ? '100%' : '458px'}
            customHeader={isMobile ? <ModalHeader /> : undefined}
            padding={isMobile ? '8px 18px 18px' : '30px'}
            borderRadius={isMobile ? '24px 24px 0 0' : '40px'}
            swipeable
        >
            {openNewCollectionLayout ? (
                <EditCollectionLayout
                    layout_type={'add'}
                    product_id={product_id}
                    onClose={handleClose}
                />
            ) : (
                <>
                    <FlexBox alignItems={'center'} mb={{ default: 18, md: 30 }}>
                        <Text
                            fontSize={{ default: 18, md: 26 }}
                            fontWeight={700}
                            width={'100%'}
                            textAlign={isMobile ? 'start' : 'center'}
                        >
                            {$t('choose_collection').toCapitalizeFirstLetter()}
                        </Text>
                        {!isMobile && (
                            <FlexBox
                                onClick={handleClose}
                                alignItems={'center'}
                                justifyContent={'center'}
                                backgroundColor={'block.secondary'}
                                width={40}
                                height={40}
                                borderRadius={'50%'}
                                marginLeft={'auto'}
                            >
                                <Icon fill={'#AAADB2'} icon={'close'} />
                            </FlexBox>
                        )}
                    </FlexBox>
                    <Box overflowX={'hidden'}>
                        {collections?.length === 1 ? (
                            <FlexBox
                                cursor={'pointer'}
                                gap={'14px'}
                                alignItems={'center'}
                                width={'100%'}
                                onClick={() =>
                                    onAddToCollection({
                                        collection_id: collections[0].id,
                                        collection_name: collections[0].name,
                                    })
                                }
                            >
                                <FlexBox
                                    position={'relative'}
                                    alignItems={'center'}
                                    justifyContent={'center'}
                                    width={'60px'}
                                    height={'60px'}
                                    padding={'6px 7px'}
                                    borderRadius={'16px'}
                                    backgroundColor={
                                        collections[0].pre_created ? '#bebeb61a' : 'none'
                                    }
                                >
                                    <Image
                                        src={collections[0]?.image!}
                                        width={collections[0].pre_created ? '47px' : '100%'}
                                        height={collections[0].pre_created ? '47px' : '100%'}
                                    />
                                    {!collections[0].pre_created && <Overlay />}
                                </FlexBox>
                                <FlexBox flexDirection={'column'} gap={'2px'}>
                                    <Text
                                        fontSize={{ default: 16, md: 22 }}
                                        fontWeight={600}
                                        lineHeight={'20px'}
                                    >
                                        {$t(collections[0].name)}
                                    </Text>
                                    <Text
                                        color={'text.secondary'}
                                        fontSize={{ default: 14, md: 16 }}
                                        lineHeight={'18px'}
                                    >
                                        {collections[0].productCountText}
                                    </Text>
                                </FlexBox>
                                <FlexBox marginLeft={'auto'}>
                                    <ToggleStatus isMobile={isMobile} hasWhiteBorder={false} />
                                </FlexBox>
                            </FlexBox>
                        ) : (
                            <FlexBox
                                flexWrap={'wrap'}
                                justifyContent={
                                    collections.length > 3 ? 'space-between' : 'space-around'
                                }
                                style={{
                                    columnGap: isMobile ? '10px' : '19px',
                                    rowGap: isMobile ? '18px' : '30px',
                                }}
                            >
                                {collections?.map((collection) => (
                                    <Box
                                        key={collection.id}
                                        width={collections.length > 3 ? '29%' : 'inherit'}
                                    >
                                        <CollectionItem
                                            keepMobileDesign={true}
                                            collection={collection}
                                            onClickItem={onAddToCollection}
                                            showPinnedStatus={false}
                                            showItemStatus={true}
                                            textAlign={'center'}
                                        />
                                    </Box>
                                ))}
                            </FlexBox>
                        )}
                        <Button
                            width={'100%'}
                            backgroundColor={'block.secondary'}
                            fontSize={{ default: '16px', md: '18px' }}
                            padding={isMobile ? '16px' : '18.5px'}
                            border={'none'}
                            borderRadius={isMobile ? '22px' : '24px'}
                            mt={isMobile ? 18 : 30}
                            onClick={onCreateCollectionClick}
                        >
                            {$t('create_new')}
                        </Button>
                    </Box>
                </>
            )}
        </Modal>
    );
};
export const ModalHeader = () => (
    <FlexBox justifyContent="center">
        <Box bg="block.disabled" width="42px" height="5px" borderRadius="999rem" />
    </FlexBox>
);
export const ToggleStatus = (props: {
    isInCollection?: boolean;
    isMobile: boolean;
    hasWhiteBorder?: boolean;
}) => {
    const { isInCollection = false, isMobile, hasWhiteBorder = true } = props;
    return (
        <FlexBox
            alignItems={'center'}
            justifyContent={'center'}
            backgroundColor={'block.secondary'}
            width={isMobile ? 32 : 40}
            height={isMobile ? 32 : 40}
            borderRadius={'50%'}
            border={hasWhiteBorder ? '4px solid #fff' : 'none'}
        >
            <Icon icon={isInCollection ? 'tick-success' : 'plus_bold'} />
        </FlexBox>
    );
};
export default AddToCollectionModal;
