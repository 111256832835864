import { InterceptorLayer } from 'services/types';

const onRequest: InterceptorLayer = (config) => {
    const { req } = config;
    console.log(req?.url, req?.headers);
    // console.log('City-Id:', req?.headers['City-Id'], '; ----> ', req?.url, req?.params);
    return config;
};

const onResponse: InterceptorLayer = (config) => {
    const { res } = config;
    if (res?.data && res?.data?.success !== undefined) {
        if (res?.data?.success) {
            return config;
        }
        throw new Error(res?.data?.data?.message);
    }
    return config;
};
export default { onRequest, onResponse };
