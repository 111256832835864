import styled from 'styled-components/macro';
import { Box } from 'pyatka-ui';
const breakPoint = '991px';

const StyledSelectBoxWrapper = styled(Box)`
    #options {
        box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.05);
        border-radius: 24px;
        padding: 16px 24px 25px;
        margin-top: 25px;
        font-size: 18px;

        & > div:not(:last-child) {
            margin-bottom: 16px;
        }
    }

    .item {
        margin-bottom: 0;
    }
    .item:hover {
        color: #898d94;
    }
    @media only screen and (max-width: ${breakPoint}) {
        svg {
            width: 24px;
            height: 24px;
        }
        #options {
            margin-top: 20px;
            border-radius: 15px;
            padding: 16px;
            font-size: 14px;
            min-width: 135px;

            & > div:not(:last-child) {
                margin-bottom: 8px;
            }
        }
    }
`;
export default { StyledSelectBoxWrapper };
