export const banners_config: {
    [key: string]: any;
} = {
    size_L: {
        carouselType: 'swipe',
        dots_config: {
            color: 'rgba(255, 255, 255, 0.65)',
            active_color: '#FFF',
            dots_position: 'inside',
            show_dots: true,
        },
        group: { mobile: 1, tablet: 1, desktop: 1 },
        showNavButton: true,
        border_radius: { default: 26, md: 48 },
        gap: '4px',
        isScrollable: false,
    },
    size_M: {
        carouselType: 'default',
        dots_config: {
            color: 'rgba(255, 255, 255, 0.65)',
            active_color: '#FFF',
            dots_position: 'inside',
            show_dots: false,
        },
        group: { mobile: 1.2, tablet: 2, desktop: 3 },
        showNavButton: false,
        border_radius: { default: 26, md: 30 },
        gap: '5px',
        isScrollable: true,
    },
    size_S: {
        carouselType: 'default',
        dots_config: {
            color: 'rgba(255, 255, 255, 0.65)',
            active_color: '#FFF',
            dots_position: 'inside',
            show_dots: false,
        },
        showNavButton: false,
        group: { mobile: 2.3, tablet: 4.8, desktop: 7 },
        border_radius: { default: 26, md: 30 },
        gap: '3.5px',
        isScrollable: true,
    },
};

export const banner_sizes: {
    [key: string]: any;
} = {
    S: 'small',
    M: 'medium',
    L: 'large',
};
