import React from 'react';
import { Box, LoadingIndicator } from 'pyatka-ui';
import Modal from 'react-modal';
import { LoaderBody } from 'components/Loader/styles';
import styles from 'components/Modal/Modal.module.scss';
import loaderStyles from 'components/Loader/Loader.module.scss';
import { useLoader } from 'contexts/useLoader';

export const Loader: React.FC = () => {
    const { isGlobalLoading } = useLoader();
    return (
        <React.Fragment>
            <Modal
                isOpen={isGlobalLoading}
                className={loaderStyles.wrapper}
                overlayClassName={styles.overlay}
                bodyOpenClassName={styles.bodyOpen}
            >
                <LoaderBody>
                    <Box
                        backgroundColor={'block.main'}
                        style={{
                            // backgroundColor: 'block.main',
                            padding: '7px',
                            borderRadius: '50%',
                        }}
                    >
                        <LoadingIndicator size={'2em'} />
                    </Box>
                </LoaderBody>
            </Modal>
        </React.Fragment>
    );
};
