import React from 'react';
import { FlexBox, Icon, Text, usePyatka } from 'pyatka-ui';
import { useDispatch } from 'react-redux';
import { StyledInput } from 'pages/WishListServicePage/v2/WishlistServicePage.Styles';
import { SubmitButton } from 'components/AuthorizationModal/AuthorizationModal.Styles';
import { useI18n } from 'hooks/useI18n';
import { FormDataFields } from 'hooks/useForm/types';
import { createNewCollection } from 'modules/Wishlist/Wishlist.actions';
import { useForm } from 'hooks';
import { AppMetricEvents } from 'AppMetric';

const formData: FormDataFields = {
    name: '',
};

interface IEditCollectionLayoutProps {
    layout_type?: 'add' | 'edit';
    product_id?: number;
    collection_title?: string;
    onSubmitForm?: (name: string) => void;
    onClose: () => void;
}

const EditCollectionLayout = (props: IEditCollectionLayoutProps) => {
    const { layout_type, product_id, onSubmitForm, collection_title = '', onClose } = props;
    const { $t } = useI18n();
    const {
        scales: { isMobile },
    } = usePyatka();
    const dispatch = useDispatch();

    const handleSubmit = (e?: any) => {
        formChangeHandler({ name: 'name', value: '' });
        if (e) {
            e.preventDefault();
        }
        if (layout_type === 'add') {
            AppMetricEvents.jmart_create_collection();
            dispatch(
                createNewCollection({
                    name: form.name,
                    product_id,
                })
            );
        }
        if (layout_type === 'edit') {
            onSubmitForm && onSubmitForm(form.name);
        }
        onClose();
    };

    const { form, formChangeHandler, errors } = useForm(formData, {
        validators: {
            name: {
                required: true,
            },
        },
        submit: handleSubmit,
    });
    return (
        <>
            <FlexBox alignItems={'center'} mb={{ default: 54, md: 80 }}>
                <Text
                    fontSize={{ default: 22, md: 26 }}
                    fontWeight={700}
                    width={'100%'}
                    textAlign={'center'}
                >
                    {$t(layout_type === 'add' ? 'enter_title' : 'enter_new_title')}
                </Text>
                {!isMobile && (
                    <FlexBox
                        onClick={onClose}
                        alignItems={'center'}
                        justifyContent={'center'}
                        backgroundColor={'block.secondary'}
                        width={40}
                        height={40}
                        borderRadius={'50%'}
                        marginLeft={'auto'}
                    >
                        <Icon fill={'#AAADB2'} icon={'close'} />
                    </FlexBox>
                )}
            </FlexBox>

            <form onSubmit={handleSubmit} style={{ padding: '0 14px 8px', width: '100%' }}>
                <StyledInput
                    isMobile={isMobile}
                    value={form?.name}
                    placeholder={layout_type === 'add' ? $t('collection') : collection_title}
                    name={'name'}
                    autoFocus={true}
                    p={0}
                    onChange={formChangeHandler}
                    fontSize={{ default: 18, md: 24 }}
                    mb={{ default: 64, md: 80 }}
                    type={'text'}
                    color={'text.main'}
                />

                <SubmitButton
                    isMobile={isMobile}
                    borderRadius={24}
                    width={'100%'}
                    height={'60px'}
                    variant="brand"
                    padding={'19px'}
                    styleType="contained"
                    disabled={errors?.name || form?.name?.length <= 3}
                    mb={{ default: ' 25px', md: 0 }}
                >
                    {$t(layout_type === 'add' ? 'create_collection' : 'rename')}
                    {/*{isLoadingAuth ? <LoadingIndicator size={'1em'} /> : $t('get_sms_code')}*/}
                </SubmitButton>
            </form>
        </>
    );
};

export default EditCollectionLayout;
