import React from 'react';
import { Box, Modal, Space, Text } from 'pyatka-ui';
import { VerificationModal } from 'components';
import { useI18n } from 'hooks/useI18n';

interface ICartClearConfirmModalProps {
    open: boolean;
    onClose: () => void;
    handleAccept: () => void;
    handleReject: () => void;
}

const CartClearConfirmModal = (props: ICartClearConfirmModalProps) => {
    const { open, handleAccept, handleReject, onClose } = props;
    const { $t } = useI18n();
    return (
        <Modal padding={'20px 0 0 0'} width={'fit-content'} open={open} onClose={onClose}>
            <VerificationModal onAccept={handleAccept} onReject={handleReject}>
                <Box padding={'0 25px 0 25px'}>
                    <Text textAlign={'center'} fontWeight={'700'} fontSize={'18px'}>
                        {$t('clear_cart')}
                    </Text>
                    <Space height={'5px'} />
                    <Text
                        textAlign={'center'}
                        fontSize={'14px'}
                        lineHeight={'19px'}
                        color={'text.secondary'}
                    >
                        {$t('current_cart_will_be_cleared_continue')}
                    </Text>
                </Box>
            </VerificationModal>
        </Modal>
    );
};

export default CartClearConfirmModal;
