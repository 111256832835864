import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    FlexBox,
    Icon,
    LoadingIndicator,
    Space,
    Text,
    Textarea,
    Image,
    usePyatka,
} from 'pyatka-ui';
import styled from 'styled-components/macro';

import LightBoxModal from 'pages/NewProductDetailPage/components/LightBoxModal';
import { Rating, SwitchToggle } from 'components';
import { useLoader } from 'contexts/useLoader';
import { Loader } from 'plugins/loader';
import { IReviewField } from 'modules/ReviewService/types';
import { useI18n } from 'hooks/useI18n';
import { AppToaster } from 'plugins/app-toaster';
import photo_plus from './assets/photo_plus.png';
import { ReviewModalLayoutProps } from './types';
import styles from './ReviewModal.module.scss';

//max file size in bytes
const MAX_FILE_SIZE = 6000000;

export const ReviewModalLayoutProduct: React.FC<ReviewModalLayoutProps> = (props) => {
    const {
        configInstructions,
        form,
        formChangeHandler,
        formSubmitHandler,
        isReviewEditable,
        errors,
        appendPhotos,
        handleDelete,
        inputPhotos,
        uploadedPhotos,
        reviewValues,
    } = props;

    const { isLoading } = useLoader();
    const { $t } = useI18n();

    const handleChange = ({ name, value }: any) => {
        formChangeHandler({ name: name, value });
    };

    const ReviewFields: any = {
        rate: ReviewRating,
        photo: ReviewPhoto,
        text: ReviewInput,
        switch: ReviewSwitch,
    };

    //Проверка на размер файла + Тостер при превышении лимита (6 мб)
    const handleFileInput = (e: any) => {
        const files = Array.from(e.target.files);

        const filteredFiles = files.filter((file: any) => file.size < MAX_FILE_SIZE);

        if (filteredFiles?.length < files.length) {
            AppToaster.warning({ body: 'Фото, превышающие лимит не будут загружены' });
        }

        appendPhotos(filteredFiles);
    };

    const [lightboxData, setLighboxData] = useState({ isOpen: false, imgIdx: 0 });

    const handleClose = () => {
        setLighboxData({ ...lightboxData, isOpen: false });
    };

    const { scales } = usePyatka();

    const showCommentAdmin = form?.status === 'declined' || form?.status === 'complaint_processed';

    return (
        <Box
            minHeight={'550px'}
            backgroundColor={'block.secondary'}
            // padding: '26px',
        >
            <FlexBox
                alignItems={'center'}
                backgroundColor={'block.main'}
                marginBottom={'12px'}
                paddingBottom={'20px'}
                paddingRight={'26px'}
                paddingLeft={'26px'}
                borderBottomLeftRadius={'26px'}
                borderBottomRightRadius={'26px'}
            >
                <Image width={'46px'} src={reviewValues?.product_image} borderRadius={10} />
                <Text lineClamp={'2'} marginLeft={'16px'} fontSize={'14px'}>
                    {' '}
                    {reviewValues?.product_name}
                </Text>
            </FlexBox>

            <Box
                borderTopLeftRadius={'26px'}
                borderTopRightRadius={'26px'}
                paddingLeft={'24px'}
                paddingRight={'24px'}
                backgroundColor={'block.main'}
            >
                <form
                    style={{ pointerEvents: isReviewEditable ? 'auto' : 'none' }}
                    onSubmit={formSubmitHandler}
                >
                    <FlexBox
                        flexDirection={'column'}
                        height={'100%'}
                        justifyContent={'space-between'}
                        width={'100%'}
                    >
                        <Box>
                            {showCommentAdmin && (
                                <ReviewDeclineReason reason={form?.comment_admin || ''} />
                            )}

                            <Space height={'24px'} />
                            {configInstructions.map((instruction: IReviewField) => {
                                const value = form[instruction.name];
                                const props = {
                                    value,
                                    name: instruction.name,
                                    placeholder: $t(instruction.placeholder),
                                    icon: instruction.icon,
                                    errorMessage: errors[instruction.name] || '',
                                    handleChange,
                                    errors,
                                    isReviewEditable,
                                    title: instruction.title,
                                    inputPhotos,
                                    handleDelete,
                                    uploadedPhotos,
                                    setLighboxData,
                                    handleFileInput,
                                    lightboxData,
                                    handleClose,
                                };

                                return (
                                    <FlexBox
                                        width={'100%'}
                                        key={instruction.name}
                                        alignItems={'center'}
                                        justifyContent={'center'}
                                        mb={instruction.name === 'photo' ? 0 : '41px'}
                                    >
                                        {ReviewFields[instruction.type](props)}
                                    </FlexBox>
                                );
                            })}
                        </Box>

                        {form?.status === 'create_review' && (
                            <div
                                style={{
                                    position: 'absolute',
                                    bottom: 0,
                                    width: '100%',
                                    left: 0,
                                    padding: '15px',
                                    backgroundColor: 'white',
                                    zIndex: 111,
                                }}
                            >
                                <Button
                                    mt={1}
                                    padding={'18px 0'}
                                    variant={'brand'}
                                    backgroundColor={'block.brand'}
                                    styleType={'contained'}
                                    borderRadius={'22px'}
                                    width={'100%'}
                                    disabled={isLoading(Loader.review.indicator)}
                                >
                                    {isLoading(Loader.review.indicator) ? (
                                        <LoadingIndicator size={'18px'} />
                                    ) : (
                                        <Text>{$t('send')}</Text>
                                    )}
                                </Button>
                            </div>
                        )}
                    </FlexBox>
                </form>
                {/* ------------------------------PHOTOS SECTION------------------------------------ */}

                <Space height={'150px'} />
            </Box>
        </Box>
    );
};

const ReviewSwitch = (props: any) => {
    const { handleChange, value } = props;
    const { $t } = useI18n();

    const handleSwitchClick = (value: boolean) => {
        handleChange({ name: 'is_recommended', value });
    };

    return (
        <FlexBox justifyContent={'center'} alignContent={'center'} width={'100%'}>
            <Icon icon={`is_recommended${value ? '' : '-off'}`} />
            <FlexBox
                justifyContent={'space-between'}
                alignItems={'center'}
                width={'100%'}
                marginLeft={'14px'}
            >
                <Text color={'text.main'} lineHeight={'30px'}>
                    {' '}
                    {$t('recommended_for_purchase')}
                </Text>
                <SwitchToggle initialValue={value} onClick={handleSwitchClick} />
            </FlexBox>
        </FlexBox>
    );
};

const ReviewRating = (props: any) => {
    const { handleChange, value, errorMessage, errors } = props;

    const valueDict: { [key: number]: string } = {
        0: 'Оцените товар',
        1: 'Плохо',
        2: 'Удовлетворительно',
        3: 'Средне',
        4: 'Хорошо',
        5: 'Отлично',
    };

    const handleRatingChange = (value: number) => {
        handleChange({ name: 'rate', value });
    };

    const generateStyle = () => {
        return errors?.rate ? styles.ratingAnimation : '';
    };

    return (
        <FlexBox flexDirection={'column'} alignItems={'center'}>
            <Text lineHeight={'22px'} fontWeight={'600'} fontSize={'16px'}>
                {' '}
                {valueDict[value]}{' '}
            </Text>
            <Space height={'8px'} />

            <div className={generateStyle()}>
                <Rating
                    onSetRating={handleRatingChange}
                    isEditable={true}
                    height={'35px'}
                    width={'37px'}
                    rating={Number(value) || 0}
                />
            </div>

            <Text mt={'8px'} color={'status.error'} fontSize={'11px'}>
                {' '}
                {errorMessage}{' '}
            </Text>
        </FlexBox>
    );
};

const ReviewInput = (props: any) => {
    const { handleChange, icon, isReviewEditable } = props;

    return (
        <React.Fragment>
            <FlexBox alignItems={'flex-start'} width={'100%'}>
                {Boolean(icon) && <Icon width={'28px'} height={'28px'} icon={props.icon || ''} />}
                <Box marginLeft={'16px'} width={'100%'}>
                    <Text fontSize={'14px'} fontWeight={'600'} lineHeight={'18px'}>
                        {props?.title}
                    </Text>
                    <Space height={'8px'} />
                    {isReviewEditable ? (
                        <Textarea
                            // marginLeft={'16px'}
                            {...props}
                            border={'none'}
                            focusOutlineColor={'transparent'}
                            onChange={handleChange}
                            placeholder={props.placeholder}
                            maxRows={4}
                            maxLength={250}
                            borderBottom={'1.5px solid rgba(190, 190, 182, 0.2)'}
                            pb={'10px'}
                        />
                    ) : (
                        <Text
                            // marginLeft={'16px'}
                            paddingTop={'5px'}
                            color={'text.main'}
                            lineHeight={'19px'}
                            fontSize={'15px'}
                            width={'calc(100%-47px)'}
                        >
                            {props.value}
                        </Text>
                    )}
                </Box>
            </FlexBox>
        </React.Fragment>
    );
};

const ReviewDeclineReason = (props: { reason: string }) => {
    const { reason = '' } = props;
    const { $t } = useI18n();

    return (
        <React.Fragment>
            <FlexBox alignItems={'center'} mt={'8px'}>
                <Icon icon={'review-decline-reason'} width={'46px'} height={'46px'} />
                <Box ml={'16px'}>
                    <Text
                        fontSize={'14px'}
                        lineHeight={'16px'}
                        color={'text.secondary'}
                        fontWeight={700}
                    >
                        {$t('your_review_is_rejected')}
                    </Text>
                    {reason && reason.length > 0 && (
                        <Text fontSize={'11px'} lineHeight={'15px'} color={'text.secondary'}>
                            {' '}
                            {$t('reason')}: {reason}{' '}
                        </Text>
                    )}
                </Box>
            </FlexBox>
        </React.Fragment>
    );
};
const ReviewPhoto = (props) => {
    const {
        isReviewEditable,
        inputPhotos,
        handleDelete,
        uploadedPhotos,
        setLighboxData,
        handleFileInput,
        lightboxData,
        handleClose,
    } = props;
    return (
        <FlexBox
            flexDirection={'column'}
            width={'100%'}
            justifyContent={'flex-start'}
            mb={uploadedPhotos.length > 0 ? '41px' : 20}
        >
            {isReviewEditable && (
                <FlexBox alignContent={'center'} width={'100%'}>
                    <Icon width={'28px'} height={'28px'} icon={'camera'} />
                    <Box>
                        <Text color={'text.main'} lineHeight={'30px'} marginLeft={'16px'}>
                            Добавьте до 5 фото
                        </Text>
                    </Box>
                </FlexBox>
            )}
            <ImagesWrapper>
                {inputPhotos?.map((file: any) => (
                    <div key={file.key} style={{ position: 'relative', display: 'inline-block' }}>
                        <img
                            width={'60px'}
                            height={'60px'}
                            key={file.key}
                            alt="not found"
                            src={URL.createObjectURL(file.file)}
                            style={{ objectFit: 'cover', borderRadius: '10px' }}
                        />
                        {isReviewEditable && (
                            <div
                                style={{
                                    position: 'absolute',
                                    top: '-10px',
                                    right: '-10px',
                                    backgroundColor: '#f6f6f5',
                                    width: '20px',
                                    height: '20px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    borderRadius: '50%',
                                }}
                                onClick={() => handleDelete(file.key)}
                            >
                                <Icon width={'10px'} height={'10px'} icon={'close_x'} />
                            </div>
                        )}
                    </div>
                ))}

                {!isReviewEditable &&
                    uploadedPhotos?.map((photo: string, imgIdx: number) => (
                        <img
                            key={imgIdx}
                            width={'60px'}
                            height={'60px'}
                            src={photo}
                            style={{ objectFit: 'cover', borderRadius: '10px' }}
                            onClick={() => setLighboxData({ isOpen: true, imgIdx })}
                        />
                    ))}

                {/* Change the length condition */}
                {isReviewEditable && inputPhotos?.length < 5 && (
                    <>
                        <label htmlFor={'reviewPhotosUpload'} style={{ display: 'inline-block' }}>
                            <Image width={'60px'} height={'60px'} src={photo_plus} />
                        </label>
                        <input
                            id={'reviewPhotosUpload'}
                            type={'file'}
                            accept="image/*"
                            multiple={true}
                            // capture={'camera'}
                            name={'review-image'}
                            onChange={handleFileInput}
                            style={{ display: 'none' }}
                        />
                    </>
                )}
            </ImagesWrapper>
            <LightBoxModal
                is_open={lightboxData.isOpen}
                handleClose={handleClose}
                galleryPhotos={uploadedPhotos}
                first_open={lightboxData.imgIdx}
                is_bounded={true}
            />
        </FlexBox>
    );
};
export const ImagesWrapper = styled('div')<{
    pt?: string;
    pb?: string;
    marginLeft?: string | number;
}>`
    gap: 6px;
    display: flex;
    overflow-x: scroll;
    padding-top: ${(props) => props?.pt || '15px'};
    padding-bottom: ${(props) => props?.pb || '0px'};
    margin-left: ${(props) => props?.marginLeft || '0px'};

    &::-webkit-scrollbar {
        display: none;
        -webkit-appearance: none;
        width: 0px;
        height: 0px;
    }
`;
