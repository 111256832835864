import { ModalInstance } from './types';

const components = import('components');

export const collectComponents = async (type: string) => {
    const loadComponents = async () => {
        return await components;
    };
    const config: any = await loadComponents();
    return config[`${type}`];
};

export const optionTitleResolver = (option: ModalInstance, title?: string) => {
    if (!title) {
        return option;
    }
    if (typeof title === 'string') {
        option.title = title;
        return option;
    }
    throw new Error('title must be string!');
};

export const optionConfigResolver = (option: ModalInstance, config?: object) => {
    if (!config) {
        return option;
    }
    if (typeof config === 'object') {
        option.config = config;
        return option;
    }
    throw new Error('title must be string!');
};

export const optionComponentResolver = async (
    option: ModalInstance,
    component: any,
    element?: any
) => {
    if (!component && !element) {
        return option;
    }
    try {
        if (element) {
            option.element = element;
            return option;
        }
        if (typeof component === 'string') {
            option.component = await collectComponents(component);
            return option;
        }
        /* ------------------- Передаем компонент как Node Element ------------------ */
        option.component = component;
        return option;
    } catch (e) {
        throw new Error('Component not found!');
    }
};

// export const optionActionsResolver = (option: ModalInstance, actions: Object) => {
//     if (!actions) {
//         return option;
//     }
//     if (typeof actions === 'object') {
//         option.actions = actions;
//         return option;
//     }
//     throw new Error('actions must be an object!');
// };

export const optionPropsResolver = (option: ModalInstance, props?: Object) => {
    if (!props) {
        return option;
    }
    if (typeof props === 'object') {
        option.props = props;
        return option;
    }
    throw new Error('props must be an object!');
};

export const optionKeyResolver = (option: ModalInstance, key?: Object) => {
    if (!key) {
        return option;
    }
    if (typeof key === 'string') {
        option.key = key;
        return option;
    }
    throw new Error('key must be an string!');
};
