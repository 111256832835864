import { CaseReducer, current, PayloadAction } from '@reduxjs/toolkit';
import { SearchServiceInitialState, SearchServiceReqPayload } from 'modules/SearchService/types';
import priceFormatter from 'utils/priceFormatter';
import {
    getInstallmentPrice,
    generateLabels,
    getDiscountLabel,
} from 'modules/ProductsList/ProductsList.helpers';
import { getActiveFiltersQueryString, toObjectVariants } from 'modules/ProductsList/utils';
import { initialData } from './SearchService.state';

const hits_per_page = 12;

/* -------------------------- Position for algolia -------------------------- */
const getPosition = (position_on_page: number, page: number): number =>
    position_on_page + hits_per_page * page;

const ProductsParser = (
    products: any[] = [],
    algolia_data: {
        query_id: string;
        page: number;
    }
): any[] =>
    products.map(
        (
            {
                product,
                image_url,
                image_urls,
                gift_exist,
                product_id,
                min_price_product_id,
                base_price,
                price,
                labels = [],
                product_types,
                offers = [],
                discount,
                installment_price,
                category_ids = [],
                ...item
            },
            index
        ) => {
            const discountPrice = discount ? price : null;
            const { page, query_id } = algolia_data;
            const percent = discount ? (1 - discountPrice / base_price) * 100 : 0;

            return {
                ...item,
                image: image_url,
                image_urls: image_urls || [],
                productName: product,
                price: base_price,
                price_formatted: priceFormatter(base_price) || '',
                gift_exist,
                discountPrice,
                discountPrice_formatted: priceFormatter(discountPrice) || '',
                productId: product_id,
                product_id,
                offers: offers,
                offer_id: product_types === 'G' ? min_price_product_id : product_id,
                type: product_types,
                category_ids,

                url: {
                    name: 'product-detail-page',
                    params: {
                        product_id,
                        product_type: product_types || item.type || 'none',
                    },
                    query: { query_id },
                    exact: true,
                },

                label: {
                    installment: getInstallmentPrice(installment_price),
                    ...generateLabels(labels),
                },
                query_id,
                position: getPosition(index + 1, page - 1),
                discount_percent: getDiscountLabel(percent, base_price - price || 0),
            };
        }
    );

const CategoriesParser = (arr: any[] = []): any[] =>
    arr.map((a) => ({
        ...a,
        categoryId: a.id,
        title: a.name,
    }));

const SearchServiceInit: CaseReducer<
    SearchServiceInitialState,
    PayloadAction<SearchServiceReqPayload>
> = (_state, _action) => {};

const SearchServiceSucceed: CaseReducer<SearchServiceInitialState, PayloadAction<{}>> = (
    _state,
    _action: any
) => {
    const { page, query_id } = _action?.payload?.data;
    const isFirstPage: boolean = page === '1';

    _state.firstRender = false;

    /* --------------------- Convert filters[] to filters{} --------------------- */
    const filtersObject: any = toObjectVariants(_action?.payload.data.filters);

    const filters_order: string[] =
        (_action?.payload?.data?.filters || []).map((f: any) => f.feature_id) || [];

    // /* ------------------------- Query string to Object ------------------------- */
    const qsObject = Object.fromEntries(new URLSearchParams(_action?.payload?.data?.url));

    // if (isFirstRun) {
    const activeFilters = getActiveFiltersQueryString(qsObject, filtersObject);
    _state.activeFilters = activeFilters;
    // }

    _state.inputQuery = _action?.payload?.inputQuery;

    _state.filtersObject = filtersObject;
    _state.filters_order = filters_order;

    const products = ProductsParser(_action?.payload?.data?.products || [], { query_id, page });
    const categories = CategoriesParser(_action?.payload?.data?.categories || []);

    /* ---------------- Infinite scroll/persist Previous products --------------- */
    if (_action?.payload?.persistProducts && !isFirstPage && _state.data.products.length > 0) {
        const previousProducts = current(_state.data.products) || [];
        // const newProducts = _action?.payload?.data?.products;
        _state.data = {
            ..._action.payload.data,
            products: [...previousProducts, ...products],
            categories,
        };
    } else {
        _state.data = { ..._action?.payload?.data, products, categories };
    }
};

const SearchServiceFailed: CaseReducer<SearchServiceInitialState, PayloadAction<{}>> = (
    _state,
    _action
) => {};

const SearchServiceFlush: CaseReducer<
    SearchServiceInitialState,
    PayloadAction<{
        reset_filters_obj: boolean;
    }>
> = (state, _action) => {
    state.data = initialData.data;
    if (_action?.payload?.reset_filters_obj) {
        state.filtersObject = initialData.filtersObject;
        state.filters_order = initialData.filters_order;
    }
};

const SearchServiceFiltersUpdate: CaseReducer<SearchServiceInitialState, PayloadAction<{}>> = (
    _state,
    _action
) => {
    _state.activeFilters = _action?.payload;
};

const SearchServiceFiltersFlush: CaseReducer<SearchServiceInitialState, PayloadAction> = (
    _state,
    _action
) => {};

const SearchServiceFiltersFlushSucceed: CaseReducer<SearchServiceInitialState, PayloadAction> = (
    _state,
    _action
) => {
    _state.activeFilters = {};
};

const SearchServiceMobileOverlaySet: CaseReducer<
    SearchServiceInitialState,
    PayloadAction<boolean>
> = (_state, _action) => {
    _state.mobile_search_overlay = _action.payload;
};
// mobile_search_overlay

const SearchServiceSuggestionsInit: CaseReducer<
    SearchServiceInitialState,
    PayloadAction<{
        search_value: string;
        isSuper: boolean;
    }>
> = (_state, _action) => {};

const SearchServiceSuggestionsSucceed: CaseReducer<
    SearchServiceInitialState,
    PayloadAction<any[]>
> = (_state, _action) => {
    _state.suggestions = _action.payload;
};

const SearchServiceSuggestionsFlush: CaseReducer<SearchServiceInitialState> = (_state, _action) => {
    _state.suggestions = [];
};

export default {
    SearchServiceFailed,
    SearchServiceSucceed,
    SearchServiceInit,
    SearchServiceFlush,
    SearchServiceFiltersUpdate,
    SearchServiceFiltersFlush,
    SearchServiceFiltersFlushSucceed,
    SearchServiceMobileOverlaySet,
    SearchServiceSuggestionsSucceed,
    SearchServiceSuggestionsInit,
    SearchServiceSuggestionsFlush,
};
