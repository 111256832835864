import { ApiGwRequest } from 'services';
import {
    GetGeoCodeServiceBody,
    ProfileAddressAddBody,
    ProfileAddressEditBody,
} from 'modules/UserProfileService/types';

export const getGeoCodeService = (data: GetGeoCodeServiceBody) => {
    return ApiGwRequest({
        url: 'geocoding/get-locality',
        method: 'get',
        params: { ...data },
    });
};

export const getGeoCodeServiceV2 = (data: GetGeoCodeServiceBody & { cityID?: string }) => {
    return ApiGwRequest({
        url: 'geo/api/v2/geo-code',
        method: 'get',
        params: {
            city_id: data.cityID,
            address: data.geocode,
            precision: 'all',
        },
    });
};
export const getGeoCodeReverseService = (data: { latitude: number; longitude: number }) => {
    return ApiGwRequest({
        url: 'geo/api/v2/geo-code-reverse',
        method: 'get',
        params: {
            ...data,
            precision: 'exact',
        },
    });
};

export const getGWProfiles = () => {
    return ApiGwRequest({
        indicator: '',
        url: 'user/v2/profile',
        method: 'get',
    });
};

export const deleteGWProfile = (data: any) => {
    return ApiGwRequest({
        indicator: '',
        url: 'user/v2/profile',
        method: 'delete',
        data,
    });
};

export const createGWProfile = (payload: ProfileAddressAddBody) => {
    return ApiGwRequest({
        indicator: '',
        url: 'user/v2/profile',
        method: 'post',
        data: payload,
    });
};

export const updateGWProfile = (payload: ProfileAddressEditBody, CityId: number | null = null) => {
    const headers: Record<string, string | number> = {};
    if (CityId) {
        headers['City-Id'] = CityId;
    }
    const req = ApiGwRequest({
        method: 'put',
        // indicator: PROFILE_EDIT_REQ_INDICATOR,
        url: 'user/v2/profile',
        data: payload,
        headers,
    });
    return req;
};
