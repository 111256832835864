import React from 'react';
import { MainBodyLayoutProps } from './types';
import styles from './MainBody.module.scss';

export const MainBodyLayout: React.FC<MainBodyLayoutProps> = (props) => {
    const { children } = props;
    return (
        <React.Fragment>
            <div id="main_body" className={`${styles.wrapper} ${props?.className}`}>
                {children}
            </div>
        </React.Fragment>
    );
};
