import React from 'react';
import { Box, FlexBox, Text } from 'pyatka-ui';
import { useI18n } from 'hooks/useI18n';
interface IPaymentMethodInfoProps {
    payment_method?: PaymentInfo;
}
const PaymentMethodInfo = (props: IPaymentMethodInfoProps) => {
    const { payment_method } = props;
    const { $t } = useI18n();

    return (
        <FlexBox justifyContent={'space-between'}>
            <Text
                fontSize={{
                    default: '13px',
                }}
                color={'text.placeholder'}
            >
                {$t('payment_method')}
            </Text>

            <FlexBox gap={'5px'} alignItems={'center'}>
                <Box backgroundColor={'block.brand_variant'} borderRadius={'14px'}>
                    <Text
                        fontSize={{
                            default: '13px',
                        }}
                        p={'1px 5px'}
                        color={'white'}
                    >
                        {payment_method?.payment}
                    </Text>
                </Box>
                {payment_method?.term ? (
                    <Text
                        fontSize={{
                            default: '13px',
                        }}
                        color={'text.secondary'}
                    >
                        <span
                            style={{
                                fontWeight: '700',
                                paddingRight: '3px',
                            }}
                        >
                            &times;{payment_method?.term}
                        </span>{' '}
                        {$t('month')}.
                    </Text>
                ) : (
                    ''
                )}
            </FlexBox>
        </FlexBox>
    );
};

export default PaymentMethodInfo;
