import React from 'react';
import { FlexBox, Image, Text, Box, Button, Space } from 'pyatka-ui';
import { useDispatch, useSelector } from 'react-redux';
import { getProductInfo } from 'modules/ProductDetail/ProductDetail.selectors';
import { IOfferFormat } from 'components/OfferBlocks/types';
import { Dialog } from 'plugins/dialog';
import { useAppRouter } from 'router/context';
import { useI18n } from 'hooks/useI18n';
import { signs } from 'utils/symbols';
import { CartProductCounter } from 'components';
import { isUserAuth } from 'modules/Authorization/Authorization.selectors';
import { nativeCall } from 'hybrid';
import { authFromMobile } from 'modules/Authorization/Authorization.actions';
import { AppMetricEvents } from 'AppMetric';
import { GtmEvents } from 'metrics';
import { IOfferHook } from 'components/OfferBlocks/OfferBlocks.Hook';
import { saveProductPaymentPreselectTerm } from 'data';
import { isNative } from 'utils/platform';
import AuthorizationModal from 'components/AuthorizationModal';
import { AuthorizationInit } from 'modules/Authorization/v2/Authorization.actions';

interface IProductCounter {
    offer: IOfferFormat;
    algoliaConversionHandler: () => void;
    saveCheckoutPaymentInfo: (offer: IOfferFormat, amount: number) => void;
    paymentTime?: IOfferHook['time'];
}

const ProductCounterModal: React.FC<IProductCounter> = (props) => {
    const { saveCheckoutPaymentInfo, offer, algoliaConversionHandler, paymentTime } = props;

    const { $t } = useI18n();
    const dispatch = useDispatch();
    const { appRedirect } = useAppRouter();
    const isAuth = useSelector(isUserAuth);
    const product = useSelector(getProductInfo);

    const [amount, setAmount] = React.useState(1);

    const { image, title, type } = product;

    const goToCheckout = () => {
        Dialog.closeDialog();
        const [category1, category2, category3] = offer?.category_ids || [];
        AppMetricEvents.jmart_checkout_start({
            source: 'product',
            data: {
                price: offer?.discountPrice,
                category1: category1 || null,
                category2: category2 || null,
                category3: category3 || null,
            },
        });
        AppMetricEvents.jmart_buy_now_click({
            product_id: String(offer.product_id),
            product_name: title,
        });
        GtmEvents.beginCheckout([
            {
                item_id: String(offer.product_id),
                item_name: title,
                price: offer?.discountPrice || offer?.price || 0,
                item_category: category1 || null,
                item_category2: category2 || null,
                item_list_name: 'Страница продукта (Оформить сейчас)',
                quantity: amount,
            },
        ]);
        algoliaConversionHandler();
        saveProductPaymentPreselectTerm({
            [offer?.product_id]: Number(paymentTime) || '',
        });
        appRedirect({
            name: 'checkout-delivery',
            params: { checkout_type: 'product', checkout_id: offer.product_id },
            query: { amount },
        });
    };

    const signIn = () => {
        if (!isNative()) {
            Dialog.openDialog({
                body: AuthorizationModal,
                props: {
                    onClose: () => {
                        Dialog.closeDialog();
                    },
                    onAuthDone: () => goToCheckout(),
                    source: 'buy_now_clicked',
                },
                isOwnModal: true,
            });
            return;
        }
        const res: any = nativeCall({
            type: 'auth',
            payload: {},
        });

        if (res?.response?.token) {
            dispatch(AuthorizationInit({ params: { token: res.response?.token } }));
            // dispatch(authFromMobile(res.response?.token));
            goToCheckout();
        }
    };

    const checkoutNow = () => {
        if (!isAuth) {
            signIn();
            return;
        }
        goToCheckout();
    };

    const addProductToCart = () => {
        Dialog.closeDialog();
        algoliaConversionHandler();
        saveCheckoutPaymentInfo(offer, amount);
    };

    return (
        <>
            <Box my="16px">
                <FlexBox gap="16px" alignItems="center">
                    <Image noLazy src={image} width="100%" maxWidth="60px" appearance="100%" />

                    <Box color="text.main">
                        <Text fontSize={1} fontWeight={500}>
                            {title}
                        </Text>
                        <Text
                            mt="3px"
                            mb="8px"
                            color="text.secondary"
                            fontSize={1}
                            fontWeight={500}
                        >
                            {offer?.company_name}
                        </Text>

                        <FlexBox fontSize={3} gap="2px" fontWeight={700}>
                            <Text> {offer?.discountPrice} </Text>
                            <Text> {signs.tg} </Text>
                        </FlexBox>
                    </Box>

                    <Box maxWidth="95px">
                        <CartProductCounter
                            fullWidth
                            outControl
                            amount={amount}
                            product_type={type}
                            setAmount={setAmount}
                            offer_id={String(offer?.product_id)}
                        />
                    </Box>
                </FlexBox>

                <FlexBox gap="10px" mt="36px">
                    <Button
                        onClick={addProductToCart}
                        bg={type === 'G' ? 'block.brand' : 'block.secondary'}
                        color={type === 'G' ? 'text.inverse' : 'text.main'}
                        {...styleBtn}
                    >
                        {$t('add_to_cart')}
                    </Button>

                    {type !== 'G' && (
                        <Button
                            onClick={checkoutNow}
                            bg="block.brand"
                            color="text.inverse"
                            {...styleBtn}
                        >
                            {$t('checkout_now')}
                        </Button>
                    )}
                </FlexBox>
                <Space height="8px" />
            </Box>
        </>
    );
};

const styleBtn: any = {
    width: '100%',
    height: '55px',
    styleType: 'contained',
    borderRadius: 22,
    style: { fontWeight: 600, fontSize: '16px', lineHeight: '20.48px' },
};

export default ProductCounterModal;
