import React, { useRef, useEffect } from 'react';
import { AppMetricEvents } from 'AppMetric';
import { useAppRouter } from 'router/context';
import ProductCardContainer from './ProductCard.Container';
import withBlur from './withBlur';

const WrapperForIntersections = (props: any) => {
    const itemRef = useRef<HTMLDivElement>(null);
    const { queryParams } = useAppRouter();

    useEffect(() => {
        const product_id = props?.product_id || props?.productId;

        if (!product_id) {
            return;
        }

        let timeout: null | number = null;
        let observerRefValue: Element | null = null; // <-- variable to hold ref value

        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    const categories = Array.isArray(props?.category_ids)
                        ? [...props.category_ids]
                        : [];
                    const category = categories[categories.length - 1];
                    timeout = window.setTimeout(() => {
                        AppMetricEvents.jmart_product_show({
                            source: props?.source || (queryParams.query ? 'search' : 'listing'),
                            master_product_id: props.master_product_id || product_id,
                            product_id,
                            category,
                            position: props?.index + 1,
                        });
                    }, 2000);
                } else {
                    if (timeout) {
                        clearTimeout(timeout);
                    }
                }
            },
            {
                threshold: 0.5,
            }
        );

        if (itemRef.current) {
            observer.observe(itemRef.current);
            observerRefValue = itemRef.current; // <-- save ref value
        }

        return () => {
            if (observerRefValue) {
                observer.unobserve(observerRefValue); // <-- use saved value
            }
        };
    }, []);

    return (
        <div ref={itemRef}>
            <ProductCardContainer {...props} />
        </div>
    );
};

export default withBlur(WrapperForIntersections);
