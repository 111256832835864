import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { IPromotionDetails, PromotionsInitialState } from 'modules/Promotions/types';

const requestPromotions: CaseReducer<PromotionsInitialState, PayloadAction> = (
    _state,
    _action
) => {};

const fetchPromotions: CaseReducer<PromotionsInitialState, PayloadAction<any>> = (
    _state,
    _action
) => {
    // @ts-ignore
    _state.promotions = _action.payload;
};

const requestPromotionDetails: CaseReducer<
    PromotionsInitialState,
    PayloadAction<string | number>
> = () => {};

const fetchPromotionDetails: CaseReducer<PromotionsInitialState, PayloadAction<any>> = (
    _state,
    _action
) => {
    _state.promotionDetails = _action.payload;
};

const resetPromotionDetails: CaseReducer<PromotionsInitialState> = (_state) => {
    _state.promotionDetails = {} as IPromotionDetails;
};

const getPromotionModal: CaseReducer<PromotionsInitialState> = () => {};

const getPromotionModalSucceed: CaseReducer<PromotionsInitialState, PayloadAction<any>> = (
    _state,
    _action
) => {
    _state.promotion_modal_data = _action.payload;
};

const getPromotionModalFailed: CaseReducer<PromotionsInitialState> = (_state, _action) => {};

export default {
    requestPromotions,
    fetchPromotions,
    requestPromotionDetails,
    fetchPromotionDetails,
    resetPromotionDetails,
    getPromotionModal,
    getPromotionModalSucceed,
    getPromotionModalFailed,
};
