import React, { useRef } from 'react';
import { Box, FlexBox } from 'pyatka-ui';
import { UserPanel } from 'components/UserNavigation/UserNavigation.Styles';
import { UserNavigationLayoutProps } from 'components/UserNavigation/types';
import Avatar from 'components/UserNavigation/components/Avatar';
import UserNavigationMenu from 'components/UserNavigation/components/UserNavigationMenu';
import { useI18n } from 'hooks/useI18n';

const UserNavigationLayout: React.FC<UserNavigationLayoutProps> = (props) => {
    const {
        avatar_icon,
        profileServiceData,
        isLoggedIn,
        userData,
        isMenuOpen,
        setIsMenuOpen,
        handleAvatarClick,
        handleLogout,
    } = props;

    const menuRef = useRef<HTMLDivElement | null>(null);
    const { $t } = useI18n();

    return (
        <>
            <UserPanel ref={menuRef} onMouseLeave={() => (isLoggedIn ? setIsMenuOpen(false) : {})}>
                {isLoggedIn ? (
                    <FlexBox alignItems={'center'} gap={'18px'}>
                        <Box width={'2px'} backgroundColor={'#BEBEB633'} height={'32px'} />
                        <Avatar
                            cursor={'pointer'}
                            icon={avatar_icon}
                            onAvatarClick={handleAvatarClick}
                            isLoggedIn={Boolean(isLoggedIn)}
                        />
                    </FlexBox>
                ) : (
                    <Box
                        cursor={'pointer'}
                        onClick={handleAvatarClick}
                        borderRadius={'25px'}
                        color={'text.secondary'}
                        backgroundColor={'#F6F6F5'}
                        padding={'14px 16px'}
                    >
                        {$t('log_in')}
                    </Box>
                )}
                <UserNavigationMenu
                    open={isMenuOpen}
                    avatar={avatar_icon}
                    user_data={userData}
                    onLogout={handleLogout}
                    profileServiceData={profileServiceData}
                />
            </UserPanel>
        </>
    );
};
export default UserNavigationLayout;
