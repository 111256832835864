import React, { useCallback, useEffect } from 'react';
import { Box, Button, FlexBox, Icon, LoadingIndicator, usePyatka } from 'pyatka-ui';
import { YMaps, Map, Polygon } from 'react-yandex-maps';
import styled from 'styled-components/macro';
import { AppToaster } from 'plugins/app-toaster';
import { useI18n } from 'hooks/useI18n';
import {
    getGeoCodeReverseService,
    getGeoCodeServiceV2,
} from 'modules/UserProfileService/UserProfileService.services';
import styles from './MapUserLocation.module.scss';
import { MapStatus, MapUserLocationLayoutProps } from './types';
import {
    geoWrapperStyle,
    zoomWrapperStyle,
    closeIconStyle,
    wrapperStyle,
} from './MapUserLocation.Styles';

const MAX_ZOOM = 19;
const MIN_ZOOM = 12;

// const apiKey = '9d84da15-97ef-45d2-88e9-93e0b843c51d';
// const apiKey = '002fa74b-0c87-4915-bf02-da9ba8c6f782';
const apiKey = __IS_PROD__
    ? 'dcdfda7f-83e6-47f7-a5a2-1827cbbd3cc6'
    : 'c45e66a5-d57e-4ac2-804c-477501423a69';

export const MapUserLocationLayout: React.FC<MapUserLocationLayoutProps> = (props) => {
    const { params, actions, polygon = [], showSelectBtn = true } = props;
    const { $t } = useI18n();

    const { scales } = usePyatka();
    const isMobile = (scales.matched || []).find((el: any) => el.name === 'md');

    const [mapStatus, setMapStatus] = React.useState(MapStatus.NEVER);
    const [ymaps, setYmaps] = React.useState<any>();
    const [address, setAddress] = React.useState<any>();
    const [houseNumber, setHouseNumber] = React.useState('');
    const [hasHouseVal, setHasHouseVal] = React.useState(false);
    const [isGeoLoading, setIsGeoLoading] = React.useState(false);

    // -------------------- ZOOM CONTROL ------------------------ //
    const [zoom, setZoom] = React.useState(15);
    const inZoom = useCallback(() => {
        setZoom((prev) => prev + 1);
    }, []);

    const outZoom = useCallback(() => {
        setZoom((prev) => prev - 1);
    }, []);

    // -------------------- CENTER CONTROL ---------------------- //
    const [center, setCenter] = React.useState<number[]>([55.75, 37.57]);
    const [addrCoordinates, setAddrCoordinates] = React.useState<number[]>([]);
    // ---------- GET CURRENT LOCATION ----------- //
    const getCurrentLocation = useCallback(() => {
        setIsGeoLoading(true);
        navigator.geolocation.getCurrentPosition(
            (pos) => {
                setCenter([pos.coords?.latitude, pos.coords?.longitude]);
                setIsGeoLoading(false);
            },
            (_error) => {
                AppToaster.warning({ body: 'geolocation_is_disabled' });
                setIsGeoLoading(false);
            }
        );
    }, []);

    const onActionTick = useCallback(() => {
        setMapStatus(MapStatus.MOVING);
    }, []);

    const selectAddress = useCallback(() => {
        const long_lat =
            addrCoordinates && addrCoordinates?.length >= 2
                ? `${addrCoordinates[0]} ${addrCoordinates[1]}`
                : '';
        actions.onSetLocation({ address, long_lat, houseNumber, hasHouseVal });
    }, [actions, address, addrCoordinates, hasHouseVal, houseNumber]);
    useEffect(() => {
        getGeoCodeServiceV2({ geocode: `город ${params.city}` }).then(({ data }) => {
            setCenter([data?.data[0].lat, data?.data[0].lon]);
        });
    }, [params.city]);
    const getAddressValue = useCallback(
        (e: any) => {
            setMapStatus(MapStatus.MOVED);
            const [longitude, latitude] = e.get('map').getCenter();
            setAddrCoordinates([longitude, latitude]);
            getGeoCodeReverseService({
                latitude,
                longitude,
            })
                .then((resp) => {
                    const res = resp?.data?.data[0];
                    const address = res?.address || '';
                    const houseNumber = res?.components.find((item) => item.kind === 'house')?.name;
                    setAddress(address);
                    setHouseNumber(houseNumber);
                    setHasHouseVal(Boolean(houseNumber));
                    // const long_lat =
                    //     center && center?.length >= 2 ? `${center[0]} ${center[1]}` : '';
                    const long_lat = `${longitude} ${latitude}`;
                    !showSelectBtn &&
                        actions.onSetLocation({ address, long_lat, houseNumber, hasHouseVal });
                })
                .catch((e) => {
                    setAddress('');
                    setHasHouseVal(false);
                    setHouseNumber(null);
                });
        },
        [actions, hasHouseVal, showSelectBtn]
    );

    const generateStyle = mapStatus === MapStatus.MOVED ? styles.sticky : '';

    return (
        <MapWrapper
            isMobile={isMobile}
            position="relative"
            // height={isMobile ? '100vh' : '"100%"'}
            borderRadius={isMobile ? '0' : '0 12px 12px 0'}
        >
            <YMaps
                query={{
                    // apikey: apiKey,
                    load: 'Map,Placemark',
                }}
            >
                <Map
                    // onLoad={(ymaps) => setYmaps(ymaps)}
                    state={{
                        center: center,
                        zoom: zoom,
                    }}
                    width="100%"
                    height={'100%'}
                    onActionBegin={() => {
                        setMapStatus(MapStatus.MOVING);
                    }}
                    onActionEnd={getAddressValue}
                    // minZoom={MIN_ZOOM}
                    // maxZoom={MAX_ZOOM}
                >
                    <Polygon
                        geometry={[polygon]}
                        properties={{
                            hintContent: 'Polygon',
                        }}
                        options={{
                            fillColor: '#cccccc30',
                            strokeColor: '#aaadb2',
                            fill: true,
                            interactivityModel: 'default#transparent',
                            strokeWidth: 2,
                        }}
                        fillRule={'nonZero'}
                    />
                </Map>
            </YMaps>
            <FlexBox
                width="100%"
                height={isMobile ? '100vh' : '100%'}
                position="absolute"
                top="0"
                right="0"
                alignItems="center"
                justifyContent="center"
                style={{ pointerEvents: 'none' }}
            >
                <Box style={wrapperStyle}>
                    <Box style={zoomWrapperStyle}>
                        <Icon icon="plus" width={'38px'} height={'38px'} onClick={inZoom} />
                        <Icon icon="minus" width={'38px'} height={'38px'} onClick={outZoom} />
                    </Box>
                </Box>
                <Box style={closeIconStyle}>
                    <Icon
                        icon="ymapsClose"
                        width={'38px'}
                        height={'38px'}
                        onClick={actions.onClose}
                    />
                </Box>
                <Box style={geoWrapperStyle}>
                    {isGeoLoading ? (
                        <LoadingIndicator size="20px" />
                    ) : (
                        <Icon icon="ymaps-geo" onClick={getCurrentLocation} />
                    )}
                </Box>
                <div id="map-controls" style={{ width: '100%', height: '100%' }}>
                    {address && mapStatus !== MapStatus.MOVING && <div id="address">{address}</div>}
                    <div id="marker" className={mapStatus === MapStatus.MOVING ? 'move' : ''} />
                </div>
            </FlexBox>
            {showSelectBtn && (
                <div className={`${styles.stickyContent} ${generateStyle}`}>
                    <Button
                        variant={'brand'}
                        margin={'0 auto'}
                        width={isMobile ? '100%' : '40%'}
                        styleType={'contained'}
                        paddingY={'17px'}
                        borderRadius={'22px'}
                        fontSize={'16px'}
                        onClick={selectAddress}
                    >
                        {$t('select')}
                    </Button>
                </div>
            )}
        </MapWrapper>
    );
};
const MapWrapper = styled(Box)<{ isMobile: boolean }>`
    height: 100vh;
    ${({ isMobile }) =>
        !isMobile &&
        `
   height: calc(75vh - 120px)
  `}
`;
