import React, { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { FlexBox, LoadingIndicator, usePyatka, Modal, Box, Button, Icon } from 'pyatka-ui';
import { Helmet } from 'react-helmet-async';
import { CheckoutDeliveryPageHookCheckoutData } from 'pages/CheckoutDeliveryV2Page/CheckoutDeliveryPage.HookCheckoutData';
import { useAppRouter } from 'router/context';
// import { isNative } from 'utils/platform';
import { nativeCall } from 'hybrid/index';
// import { CheckoutDeliveryPageLayout } from './CheckoutDeliveryPage.Layout';
import { AppToaster } from 'plugins/app-toaster';
import { signs } from 'utils/symbols';
import { AppMetricEvents } from 'AppMetric';
import { useCartService } from 'contexts/useCartService';
import { isNative } from 'utils/platform';
import { useI18n } from 'hooks/useI18n';
import { prepareIntervalsForNative } from 'pages/CheckoutDeliveryV2Page/helper';
import {
    CheckoutCartHashCheck,
    CheckoutCreateOrder,
    CheckoutPaymentProcessingOrder,
    CheckoutWebLoanPay,
} from 'pages/CheckoutDeliveryV2Page/services';
import { Loader } from 'plugins/loader';
import CheckoutDeliveryPageDesktopLayout from 'pages/CheckoutDeliveryV2Page/CheckoutDeliveryPage.DesktopLayout';
import { CheckoutDeliveryPageLayout } from 'pages/CheckoutDeliveryV2Page/CheckoutDeliveryPage.Layout';
import { CheckoutDeliveryPageLayoutError } from 'pages/CheckoutDeliveryV2Page/CheckoutDeliveryPage.LayoutError';
import { AppRedirectModal } from 'pages/CheckoutDeliveryV2Page/components/AppRedirectModal';
import AlcoholContainWarning from 'pages/CheckoutDeliveryV2Page/components/AlcoholContainWarning';
import { CheckoutDeliveryPageProps } from './types';

export const CheckoutDeliveryPageContainer: React.FC<CheckoutDeliveryPageProps> = (props) => {
    const { city_id, profileServiceData, isClientDebug } = props;

    const [appRedirectModal, setAppRedirectModal] = useState(false);

    const [isPaymentInit, setIsPaymentInit] = React.useState<boolean>(false);
    const [checkoutButtonLoading, setCheckoutButtonLoading] = React.useState<boolean>(false);
    const [showAlcoholWarningModal, setShowAlcoholWarningModal] = React.useState<boolean>(false);

    const { addressDefault: shippingAddressDefault } = profileServiceData;
    const appRouter = useAppRouter();
    const params = useParams();
    const { cartSnapshotEdit } = useCartService();

    const { $t } = useI18n();
    const { scales } = usePyatka();
    const isMobile = (scales.matched || []).find((el: any) => el.name === 'md');

    const hookOutput = CheckoutDeliveryPageHookCheckoutData({
        ...props,
        params,
        coupon: appRouter?.queryParams?.coupon as string,
        query: appRouter?.queryParams,
    });
    const { checkout_data, reqStatus, promocode, deliveryComment, setIntervalData, setReqStatus } =
        hookOutput;

    const deliveryValidation = useMemo(
        () =>
            checkout_data?.shipping_required &&
            checkout_data?.is_valid_profile_id &&
            checkout_data?.is_delivery &&
            checkout_data?.is_delivery_valid_address,
        [
            checkout_data?.is_delivery,
            checkout_data?.is_delivery_valid_address,
            checkout_data?.is_valid_profile_id,
            checkout_data?.shipping_required,
        ]
    );

    const CheckoutNativePayload = useMemo(() => {
        const delivery_valid = deliveryValidation;
        const payload = {
            promocode,
            preselected_term: checkout_data?.preselected_term,
            comment: deliveryComment,
            // @ts-ignore
            cart_price: checkout_data?.cart?.total,
            profile_id: delivery_valid ? shippingAddressDefault?.profile_id : 0,
            shipping: delivery_valid
                ? {
                      shipping_id: parseInt(checkout_data?.shipping_selected?.company as string),
                      cost: checkout_data?.shipping_selected?.price,
                      interval: {
                          date: checkout_data?.shipping_selected?.interval_date
                              ? checkout_data?.shipping_selected?.interval_date
                              : '',
                          time: checkout_data?.shipping_selected?.interval_time
                              ? checkout_data?.shipping_selected?.interval_time
                              : '',
                      },
                  }
                : null,
            store_location_id:
                !checkout_data?.is_delivery && checkout_data?.delivery_type === 'pickup'
                    ? checkout_data?.pickup_selected?.store_location_id
                    : 0,
            ...(checkout_data?.delivery_type === 'postomat' && {
                postomat: {
                    postomat_id: checkout_data?.postomat_selected.postomat_id,
                    postomat_provider: checkout_data?.postomat_selected.postomat_provider,
                },
            }),

            city_id: parseInt(city_id),
        };

        switch (params?.checkout_type) {
            case 'cart':
                return { ...payload, cart_hash: params?.checkout_id };
            case 'product':
                return {
                    ...payload,
                    product: {
                        product_id: params?.checkout_id,
                        amount: appRouter?.queryParams?.amount || 1,
                    },
                };

            case 'order': {
                return {
                    ...payload,
                    order_id: params?.checkout_id,
                };
            }
        }
    }, [
        deliveryValidation,
        promocode,
        deliveryComment,
        checkout_data?.cart?.total,
        checkout_data?.shipping_selected?.company,

        checkout_data?.delivery_type,
        checkout_data?.shipping_selected?.price,
        checkout_data?.shipping_selected?.interval_date,
        checkout_data?.shipping_selected?.interval_time,
        checkout_data?.is_delivery,
        checkout_data?.pickup_selected?.store_location_id,
        checkout_data?.postomat_selected?.postomat_id,
        shippingAddressDefault?.profile_id,
        city_id,
        params?.checkout_type,
        params?.checkout_id,
        appRouter?.queryParams?.amount,
    ]);
    const isValidLoanPayment = useMemo(() => {
        return (
            checkout_data?.calculated_prices?.total &&
            checkout_data?.calculated_prices?.total >= 10000 &&
            checkout_data?.calculated_prices?.total <= 3000000 &&
            checkout_data?.cart.cart_type !== 'G'
        );
    }, [checkout_data?.calculated_prices?.total, checkout_data?.cart.cart_type]);
    const validateCheckoutSubmit = useCallback(
        (alert: boolean = true): boolean => {
            const checkout_payload = CheckoutNativePayload?.payload;
            const makeAlert = (obj: { body?: string; head?: string }) => {
                if (alert) {
                    AppToaster.warning(obj);
                }
            };
            if (checkout_data === null || checkout_data === undefined) {
                makeAlert({ body: $t('data_not_generated') });
                return false;
            }

            if (checkout_data.basket_restriction !== null) {
                if (!checkout_data?.basket_restriction?.min_basket_approved) {
                    makeAlert({
                        head: `${$t('minimum_order_amount')} - ${
                            checkout_data?.basket_restriction?.amount_formatted
                        } ${signs.tg}`,
                        body: `${$t('not_enough_to_complete')} - ${
                            checkout_data?.basket_restriction?.remain_formatted
                        } ${signs.tg}`,
                    });
                    return false;
                }
            }

            if (!checkout_data?.shipping_required) {
                // if shipping not required
                return true;
            }
            if (checkout_data?.delivery_type === 'pickup') {
                if (checkout_data?.pickup?.variants?.length > 0) {
                    if (checkout_payload?.store_location_id === 0) {
                        makeAlert({ body: $t('specify_pickup_point') });
                        return false;
                    }
                    return true;
                }
                makeAlert({ body: $t('no_pickup_points') });
                return false;
            }

            if (checkout_data?.delivery_type === 'postomat') {
                if (checkout_data?.postomat?.variants?.length > 0) {
                    if (checkout_payload?.postomat?.postomat_id === 0) {
                        makeAlert({ body: 'Выберите постамат' });
                        return false;
                    }
                    return true;
                }
                makeAlert({ body: 'Нет точек для постамата' });
                return false;
            }

            const isAddressValid = !(
                profileServiceData.addressDefault?.profile_id === undefined ||
                checkout_data.chosen_profile_id !== profileServiceData.addressDefault?.profile_id
            );

            if (checkout_data?.delivery_type === 'shipping') {
                if (
                    !checkout_data?.shipping_selected?.interval_date ||
                    !checkout_data?.shipping_selected?.interval_time
                ) {
                    makeAlert({ body: 'Выберите время доставки' });
                    return false;
                }

                if (checkout_data?.shipping?.variants?.length > 0) {
                    if (isAddressValid) {
                        return true;
                    }
                    makeAlert({ body: $t('enter_delivery_address') });
                    return false;
                }

                makeAlert({ body: $t('no_delivery_options') });
                return false;
            }

            if (!checkout_data?.delivery_type) {
                if (!isAddressValid) {
                    makeAlert({ body: $t('enter_delivery_address') });
                    return false;
                }
                makeAlert({ body: $t('choose_delivery_type') });
            }

            return false;
        },
        [
            CheckoutNativePayload?.payload,
            checkout_data,
            profileServiceData.addressDefault?.profile_id,
        ]
    );

    const asyncInitDos = async () => {
        try {
            const category1 = checkout_data?.cart.products[0].category_ids[0] || '';
            const checkout_type =
                params?.checkout_type === 'product'
                    ? appRouter?.queryParams?.partnerRedirect === 1
                        ? 'partner'
                        : 'product'
                    : params?.checkout_type;
            console.log(checkout_type);
            AppMetricEvents.checkout_delivery({
                delivery_type: checkout_data?.delivery_type,
                delivery_price: checkout_data?.shipping_selected?.price,
                promocode_id: promocode,
                order_price: checkout_data?.cart?.total,
                checkout_type,
                category1,
            });
        } catch (e) {
            console.log(e);
        }
    };
    const openShippingIntervalNative = () => {
        const company = checkout_data?.shipping_selected.company;

        const variant = checkout_data?.shipping?.variants?.find(
            (variant) => variant.id === company
        );
        const data = prepareIntervalsForNative(variant?.intervals || []);
        const res = nativeCall({
            type: 'shipping',
            payload: {
                closeable: true,
                title: $t('delivery_date_and_time'),
                data,
            },
        });
        const { response } = res;
        if (response && response?.selected_data) {
            setIntervalData(
                res?.response?.selected_data?.date_value,
                res?.response?.selected_data?.time_value
            );
        }
    };
    const onPaymentClick: CheckoutDeliveryPageProps['initPayment'] = (payload) => {
        if (checkout_data?.delivery_type === 'shipping') {
            if (
                !checkout_data?.shipping_selected?.interval_date &&
                !checkout_data?.shipping_selected?.interval_time
            ) {
                openShippingIntervalNative();
                return;
            }
        }
        initPayment(payload);
    };

    const WebPaymentInit = (data: any) => {
        Loader.checkoutOrderPayment.on({ global: true });
        CheckoutCreateOrder({ ...data, payment_id: 20 })
            .then((resA) => {
                console.log(resA);
                CheckoutPaymentProcessingOrder({ order_id: `${resA.data?.data?.order_id}` })
                    .then((resB) => {
                        window.location.href = resB?.data?.data?.payment_link;
                    })
                    .catch((_e) => {
                        AppToaster.error({ body: 'Ошибка инициализация оплаты' });
                    })
                    .finally(() => {
                        Loader.checkoutOrderPayment.off();
                    });
            })
            .catch((_e) => {
                AppToaster.error({ body: 'Ошибка при создании заказа' });
                Loader.checkoutOrderPayment.off();
            });
    };
    const WebLoanPaymentInit = (data: any) => {
        Loader.checkoutOrderPayment.on({ global: true });
        const payload = {
            merchantId: {
                tin: checkout_data?.company?.bin,
                companyId: checkout_data?.company?.company_id,
                storeLocationId: null,
            },
            amount: null,
            marketTransferRequest: {
                type: 'checkout',
                payload: { ...data },
            },
        };
        CheckoutWebLoanPay(payload)
            .then((resA) => {
                console.log(resA);
                const baseURL = resA?.data?.data?.web_url;
                const phone = checkout_data?.user_info?.phone.replace(/\D/g, '').replace(/^8/, '7');
                const iin = checkout_data?.user_info?.user_iin || null;

                const queryParams = new URLSearchParams({ phone, iin });
                // if (userIIN) {
                //     queryParams.append('iin', userIIN);
                // }

                window.location.href = `${baseURL}&${queryParams}`;
            })
            .catch((_e) => {
                AppToaster.error({ body: 'Ошибка инициализация оплаты' });
                Loader.checkoutOrderPayment.off();
            })
            .finally(() => {
                Loader.checkoutOrderPayment.off();
            });
    };
    // CheckoutWebLoanPay
    const initPayment: CheckoutDeliveryPageProps['initPayment'] = React.useCallback(
        (payload) => {
            const { isWebLoadClick = false } = payload;
            if (isWebLoadClick) {
                // asyncInitDos().then((_r) => {});
                // if (isClientDebug) {
                WebLoanPaymentInit(CheckoutNativePayload);
                // } else {
                //     setAppRedirectModal(true);
                // }
                AppMetricEvents.jmart_modal_webtoapp_view();
                return;
            }
            if (validateCheckoutSubmit(true)) {
                asyncInitDos().then((_r) => {});
                if (isNative()) {
                    const goToPayment = () => {
                        nativeCall({
                            type: 'checkout',
                            payload: CheckoutNativePayload,
                        });
                    };
                    if (params?.checkout_type === 'cart') {
                        if (!isPaymentInit) {
                            goToPayment();
                            setIsPaymentInit(true);
                        } else {
                            setCheckoutButtonLoading(true);
                            CheckoutCartHashCheck({
                                cart_hash: params?.checkout_id,
                            })
                                .then((res) => res.data.data)
                                .then((data) => {
                                    if (data.count === 0 || data.groups.length === 0) {
                                        throw new Error();
                                    } else {
                                        goToPayment();
                                    }
                                })
                                .catch(() => {
                                    setReqStatus('error');
                                    cartSnapshotEdit([]);
                                })
                                .finally(() => {
                                    setCheckoutButtonLoading(false);
                                });
                        }
                    } else {
                        goToPayment();
                    }
                    // console.log(isPaymentInitted);
                } else {
                    WebPaymentInit(CheckoutNativePayload);
                }
            }
        },
        [CheckoutNativePayload, asyncInitDos, validateCheckoutSubmit]
    );

    const layoutProps = {
        ...props,
        ...hookOutput,
        shippingAddressDefault,
        CheckoutNativePayload,
        isValidSubmit: validateCheckoutSubmit(false),
        initPayment: onPaymentClick,
        checkoutButtonLoading,
        isValidLoanPayment,
    };

    const checkAppVersion = React.useCallback(() => {
        if (params?.checkout_type === 'cart') {
            return;
        }
        if (!isNative()) {
            return;
        }
        const version = String(window.__HYBRIDCONFIG__.version);
        const version_1 = new RegExp(/[3-9]+\.([8-9]|\d{2,})+\.[0-9]/g);
        const version_validate = version_1.test(version);
        if (version_validate) {
            return;
        }
        AppToaster.warning({ body: $t('jusan_app_needs_to_be_updated') });
        appRouter?.appRedirect({
            name: 'index',
        });
    }, [$t, appRouter, params?.checkout_type]);

    React.useEffect(() => {
        checkAppVersion();
    }, [checkAppVersion]);
    React.useEffect(() => {
        if (reqStatus === 'fetched') {
            setShowAlcoholWarningModal(Boolean(checkout_data?.cart?.alcohol_contain));
        }
    }, [reqStatus]);

    return (
        <React.Fragment>
            <Helmet>
                <title>{$t('checkout_delivery_page_title')}</title>
                <meta name="robots" content="noindex" />
            </Helmet>
            <>
                {reqStatus === 'loading' ? <CheckoutLoading /> : ''}
                {reqStatus === 'fetched' ? (
                    !isMobile && !isNative() ? (
                        <CheckoutDeliveryPageDesktopLayout {...layoutProps} />
                    ) : (
                        <CheckoutDeliveryPageLayout {...layoutProps} />
                    )
                ) : (
                    ''
                )}
                {reqStatus === 'error' ? <CheckoutDeliveryPageLayoutError /> : ''}
            </>
            {/*{process.env.NODE_ENV === 'development' || profileServiceData?.user?.id === 172129 ? (*/}
            {/*    <pre>*/}
            {/*        <code>{JSON.stringify(CheckoutNativePayload, null, 2)}</code>*/}
            {/*    </pre>*/}
            {/*) : (*/}
            {/*    ''*/}
            {/*)}*/}
            <Modal
                open={appRedirectModal}
                onClose={() => setAppRedirectModal(false)}
                width={isMobile ? '100%' : '752px'}
                positionMode={isMobile ? 'mobile' : 'default'}
                swipeable={true}
                padding={'0'}
                borderRadius={isMobile ? '26px 26px 0 0' : '32px'}
                style={{
                    overflow: 'hidden',
                    // width: isMobile ? '100%' : 'auto',
                }}
            >
                <AppRedirectModal onClose={() => setAppRedirectModal(false)} />
            </Modal>
            <Modal
                open={showAlcoholWarningModal}
                onClose={() => {
                    setShowAlcoholWarningModal(false);
                }}
                positionMode={isMobile ? 'mobile' : 'default'}
                width={isMobile ? '100%' : '452px'}
                customHeader={isMobile ? <ModalHeader /> : undefined}
                borderRadius={isMobile ? '26px 26px 0 0' : '30px'}
                padding={isMobile ? '8px 24px 20px' : '18px 18px 20px'}
                swipeable
            >
                <Button
                    width={'32px'}
                    height={'32px'}
                    p={0}
                    onClick={() => {
                        setShowAlcoholWarningModal(false);
                    }}
                    borderRadius="45px"
                    border={'none'}
                    backgroundColor={'block.secondary'}
                    marginLeft={'auto'}
                >
                    <Icon fill="text.placeholder" icon={'close'} />
                </Button>
                <AlcoholContainWarning
                    onClick={() => {
                        setShowAlcoholWarningModal(false);
                    }}
                />
            </Modal>
        </React.Fragment>
    );
};

const CheckoutLoading: React.FC = () => {
    return (
        <FlexBox justifyContent={'center'} py={'30px'}>
            <LoadingIndicator />
        </FlexBox>
    );
};
const ModalHeader = () => (
    <FlexBox justifyContent="center">
        <Box bg="block.disabled" width="42px" height="5px" borderRadius="999rem" />
    </FlexBox>
);
