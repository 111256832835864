import React from 'react';
import { Box, Icon } from 'pyatka-ui';
import { useDispatch } from 'react-redux';
import { SearchServiceMobileOverlaySet } from 'modules/SearchService/SearchService.actions';
import { IconProps } from '../types';

export const SearchBtn: React.FC<IconProps> = (props) => {
    const { fill } = props;
    const dispatch = useDispatch();
    const openSearchDialog = () => dispatch(SearchServiceMobileOverlaySet(true));
    return (
        <Box p={2} onClick={openSearchDialog} id="search-icon-button">
            <Icon icon="new-search" height="20px" width="20px" fill={fill} />
        </Box>
    );
};
