import { createElement } from 'react';
import WishlistToasterTemplate from 'pages/WishListServicePage/v2/components/WishlistToasterTemplate';
import { ICollectionProducts, IWishlistCollection } from 'modules/Wishlist/types';
import wishlist from 'shared/assets/wishlist.png';
import heart_default from 'shared/assets/heart_default.png';

export const wishlistToaster = (props: {
    icon?: string;
    head?: string;
    description?: string;
    onClick?: () => void;
    redirectParams?: any;
}) => {
    const {
        icon = 'new_fill_heart',
        head = 'added_to_wishlist',
        description = 'click_to_add_wishlist',
        onClick,
        redirectParams,
    } = props;
    return createElement(WishlistToasterTemplate, {
        icon,
        head,
        description,
        onClickToaster: onClick,
        redirectParams,
    });
};
export const CollectionBaseParser = (
    collection: IWishlistCollection,
    products?: ICollectionProducts
) => {
    // const collectionProducts = products?.[collection?.id];
    const productCount = Number(products?.length || collection?.product_ids?.length || 0);
    let products_amount: string;
    switch (productCount) {
        case 1:
            products_amount = 'товар';
            break;
        case 2:
        case 3:
        case 4:
            products_amount = 'товара';
            break;
        default:
            products_amount = 'товаров';
    }
    const image = collection?.pre_created ? wishlist : collection?.preview_img || heart_default;

    return {
        ...collection,
        productCount,
        productCountText:
            productCount > 100 ? '100+ товаров' : `${productCount} ${products_amount}`,
        image,
        name: collection?.name?.toCapitalizeFirstLetter(),
    };
};
