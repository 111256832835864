import React from 'react';
import { Container, Col, Row } from 'pyatka-ui';
import { FallbackRender } from '@sentry/react/types/errorboundary';
import { TemporaryProblem } from 'components';

const ErrorMsg: FallbackRender = ({ error, componentStack }) => {
    console.log('[ERROR-MSG]', error.toString(), componentStack);

    return (
        <Container>
            <Row>
                <Col>
                    <TemporaryProblem />
                    <div
                        style={{
                            height: '150px',
                            overflow: 'scroll',
                            padding: '15px',
                            border: '1px solid rgb(0 0 0 / 41%)',
                            marginTop: '15px',
                            userSelect: 'all',
                            borderRadius: '10px',
                        }}
                    >
                        {error.toString()}
                        <hr />
                        {componentStack}
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default ErrorMsg;
