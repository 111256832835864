// register serviceWorker
export default function SWRegister() {
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker
            .register('/service-worker.js')
            .then((registration) => {
                console.log('SW registered: ', registration);
            })
            .catch((registrationError) => {
                console.log('SW registration failed: ', registrationError);
            });
        navigator.serviceWorker.addEventListener('installed', (event: any) => {
            if (event.isUpdate) {
                if (window.confirm('New app update is available!. Click OK to refresh')) {
                    window.location.reload();
                }
            }
        });
    }
}
