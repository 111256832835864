import { nativeCall } from 'hybrid';
import { AppRouteMiddlewareLayer } from 'router/types';
import { compareVersions } from 'utils/compareVersions';
import { isNative } from 'utils/platform';
import { SWIPE_VERSION } from 'utils/versions';

let PREV_SWIPE: boolean = true;

const Swipe: AppRouteMiddlewareLayer = (config) => {
    const { route } = config;

    if (isNative() && Boolean(route?.disableSwipe) !== PREV_SWIPE) {
        // --- check jusan app version
        if (compareVersions(String(window.__HYBRIDCONFIG__.version), SWIPE_VERSION) >= 0) {
            // --- send prompt to the jusan app
            nativeCall({
                type: 'swipe',
                payload: { enabled: !route?.disableSwipe },
            });
            // --- save the previous swipe
            PREV_SWIPE = Boolean(route?.disableSwipe);
        }
    }

    return config;
};

export { Swipe as default };
