import { connect, ConnectedProps } from 'react-redux';
import { AppRootState } from 'store/types';
import { getProfileServiceData } from 'modules/UserProfileService/UserProfileService.selectors';

const mapState = (state: AppRootState) => ({
    profileServiceData: getProfileServiceData(state),
});

const mapDispatch = {};

export const ProfileAddressListConnector = connect(mapState, mapDispatch);
export type ProfileAddressListConnectProps = ConnectedProps<typeof ProfileAddressListConnector>;
