import React from 'react';
import { ProductCard } from 'components';
import { Product } from 'modules/ProductsList/types';
import { nativeConfigs } from 'utils/platform';
import { ProductCardsListProps } from './types';
import { loadingProducts } from './const';
import { ProductsGrid } from './ProductsCardsList.styles';
import VirtualGrid from './helper/VirtualGrid';
import ProductsEmpty from './ProductsCardsList.Empty';
import ListingSuggestions from './helper/ListingSuggestions';

// Используется в Algolia, ProductsList, MerchantPage, WishListServicePage, PromotionDetailWithProducts
const ProductsCardsList: React.FC<ProductCardsListProps> = ({
    metric,
    products = [],
    isLoading,
    isVirtual = false,
    layout = 'old',
    desktopLayout = 'old',
    xl = 5,
    customRedirectText,
    customRedirect,
    isSupermarket,
    noEmpty = false,
    source,
}) => {
    const showEmptyContent = products.length === 0 && !isLoading;
    const isFirstRun = React.useRef(true);

    React.useEffect(() => {
        isFirstRun.current = false;
    }, []);

    if (showEmptyContent && !isFirstRun.current && !noEmpty) {
        return (
            <ProductsEmpty
                redirectTo={isSupermarket ? customRedirect : { name: 'index-page' }}
                customRedirectText={customRedirectText}
            />
        );
    }

    if (isLoading) {
        return (
            <div style={{ width: '100%' }}>
                <ProductsGrid layout={layout} xl={xl}>
                    {loadingProducts.map((product: any, idx: number) => (
                        <ProductCard
                            key={idx}
                            {...product}
                            lineClamp={2}
                            index={idx}
                            isLoading
                            layout={layout}
                            desktopLayout={desktopLayout}
                        />
                    ))}
                </ProductsGrid>
            </div>
        );
    }

    if (isVirtual) {
        return (
            <VirtualGrid
                products={products}
                metric={metric}
                layout={layout}
                desktopLayout={desktopLayout}
                xl={xl}
                source={source}
            />
        );
    }

    const config = nativeConfigs();
    const showAfterRow = config?.appsflyer_segment === 'B' ? 18 : 12;

    return (
        <div style={{ width: '100%' }}>
            <ProductsGrid layout={layout} xl={xl}>
                {products.map((product: Product, index: number) => (
                    <React.Fragment key={product?.product_id}>
                        {index === showAfterRow && <ListingSuggestions xl={xl} />}
                        <ProductCard
                            {...product}
                            metric={metric}
                            offer_id={product?.offer_id ? product?.offer_id : product?.product_id}
                            lineClamp={2}
                            appearance="grid"
                            layout={layout}
                            desktopLayout={desktopLayout}
                            index={index}
                            effect="fadeIn"
                            source={source}
                        />
                    </React.Fragment>
                ))}
            </ProductsGrid>
        </div>
    );
};

export default React.memo(ProductsCardsList);
