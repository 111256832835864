import { CategoryInitialState } from 'modules/Categories/types';

const state: CategoryInitialState = {
    categories: {},
    childId: null,
    breadcrumb: [],
    title: '',
    categoriesNames: {},
};
export default state;
