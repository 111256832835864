import React from 'react';
import { FlexBox } from 'pyatka-ui';
import { FavoriteBtn, GoBackBtn, ShareBtn, HeaderWrapper } from '../components';

const ProductDetailHeader: React.FC = () => (
    <HeaderWrapper>
        <GoBackBtn fill="#201F1F" />
        <FlexBox ml={1} gap="18px">
            <ShareBtn fill="#201F1F" />
            <FavoriteBtn />
        </FlexBox>
    </HeaderWrapper>
);

export default React.memo(ProductDetailHeader);
