import { UserProfileServiceInitialState } from 'modules/UserProfileService/types';
import { FormFieldsInitialData } from 'modules/UserProfileService/ProfileServiceConfigs';

const state: UserProfileServiceInitialState = {
    loading: false,
    formFields: FormFieldsInitialData,
    profiles: [],
    layout: 'list',
    formLayoutMode: null,
    geoCodeSearchResults: [],
    user: {},
    profile_addresses: [],
};

export default state;
