import React from 'react';
import { useSelector } from 'react-redux';
import { Box, FlexBox, Text, usePyatka } from 'pyatka-ui';
import { AppLink } from 'components';
import { SearchServiceSuggestions } from 'modules/SearchService/SearchService.selectors';
import { useI18n } from 'hooks/useI18n';
import { useAppRouter } from 'router/context';
import { AppMetricEvents } from 'AppMetric';
import searchImg from './search-img.png';

const ListingSuggestions = ({ xl = 5 }) => {
    const { $t } = useI18n();
    const { scales } = usePyatka();
    const { location, queryParams } = useAppRouter();
    const suggestions = useSelector(SearchServiceSuggestions);

    const list = React.useMemo(() => {
        const query = String(queryParams?.query || '').toLocaleLowerCase();
        return (suggestions || [])
            .filter((item) => item.query.toLocaleLowerCase() !== query)
            .slice(0, 4);
    }, [suggestions, queryParams]);

    if (!scales?.isMobile || list.length === 0 || queryParams?.company_id !== undefined) {
        return null;
    }

    if (location.pathname !== '/search') {
        return null;
    }

    const clickSuggestion = (data: string) => {
        AppMetricEvents.jmart_suggest_search_new({ data, source: 'listing' });
    };

    return (
        <Box
            gridColumn={{
                default: '1/3',
                sm: '1/4',
                lg: '1/5',
                xl: `1/${xl + 1}`,
            }}
        >
            <FlexBox flexDirection="column" bg="block.secondary" mx="-18px">
                <Box bg="block.main" borderRadius="0 0 24px 24px" height="24px" mb="0px" />
                <FlexBox flexDirection="column" px="24px">
                    <FlexBox alignItems="center" gap="12px" my="6px">
                        <img src={searchImg} style={{ width: '46px', height: '46px' }} alt="" />
                        <Text fontSize="16px" fontWeight={700} lineHeight="20.48px">
                            {$t('search_suggest_pagination')}
                        </Text>
                    </FlexBox>
                    <FlexBox
                        gap="4px"
                        padding="8px"
                        bg="block.main"
                        flexWrap="wrap"
                        borderRadius="18px"
                        mr="6px"
                    >
                        {list.map((item) => (
                            <Box
                                bg="block.secondary"
                                key={item.query}
                                padding="7.23px 10.85px"
                                borderRadius="999rem"
                                height="30px"
                                onClick={() => clickSuggestion(item.query)}
                            >
                                <AppLink to={item.to}>
                                    <Text
                                        fontSize="14px"
                                        fontWeight={500}
                                        color="text.main"
                                        lineHeight="18.2px"
                                    >
                                        {item.query}
                                    </Text>
                                </AppLink>
                            </Box>
                        ))}
                    </FlexBox>
                </FlexBox>
                <Box bg="block.main" borderRadius="24px 24px 0 0" height="24px" mt="13.22px" />
            </FlexBox>
        </Box>
    );
};

export default ListingSuggestions;
