import React from 'react';
import { Button, FlexBox, Icon, LoadingIndicator, Text, usePyatka } from 'pyatka-ui';
import { CartProductCounterLayoutProps } from './types';

export const CartProductCounterLayout: React.FC<CartProductCounterLayoutProps> = (props) => {
    const {
        fullWidth = false,
        amount,
        isLoading,
        disabled,
        increaseAmount,
        decreaseAmount,
        bg,
        is_super,
        decrease_bg = 'block.main',
    } = props;
    const { theme, scales } = usePyatka();

    const constBtnProps = {
        p: '5px',
        border: 0,
        width: '23px',
        height: '23px',
        borderRadius: '50%',
    };

    const fillColor = theme?.colors?.text?.main;

    const iconSize = scales?.isMobile ? '10px' : '16px';

    return (
        <FlexBox
            py="2px"
            fontSize="11px"
            alignItems="center"
            borderRadius="999rem"
            minWidth={{ md: '128px' }}
            bg={bg || 'block.secondary'}
            justifyContent="space-between"
            px={{ default: '7px', md: '8px' }}
            height={{ default: '34px', md: '43px' }}
            width={fullWidth ? '100%' : 'fit-content'}
        >
            <Button {...constBtnProps} bg={decrease_bg} onClick={decreaseAmount}>
                {scales?.isMobile ? (
                    <Icon
                        icon={Number(amount) <= 1 && !is_super ? 'cart-delete' : 'counter-minus'}
                        fill={fillColor}
                        width={iconSize}
                    />
                ) : (
                    <Icon icon={'counter-minus'} fill={fillColor} width={iconSize} />
                )}
            </Button>
            <Text
                py={'6px'}
                // width={'18px'}
                // px={Number(amount) === 0 ? '12px' : '19px'}
                width="34px"
                textAlign="center"
                color={isLoading ? 'text.secondary' : 'text.main'}
                fontSize={{ default: '14px', md: '16px' }}
                className={isLoading || Number(amount) === 0 ? 'blinkingHeart' : ''}
                lineHeight="18px"
                fontWeight="600"
                // @ts-ignore
                justifyContent="center"
            >
                {Number(amount) === 0 ? (
                    <FlexBox justifyContent="center">
                        <LoadingIndicator size="18px" />
                    </FlexBox>
                ) : (
                    Number(amount)
                )}
            </Text>
            <Button {...constBtnProps} disabled={disabled} onClick={increaseAmount}>
                <Icon icon="counter-plus" width={iconSize} height={iconSize} fill={fillColor} />
            </Button>
        </FlexBox>
    );
};
