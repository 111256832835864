import React from 'react';
import { FlexBox, Text } from 'pyatka-ui';
import { signs } from 'utils/symbols';
import { useI18n } from 'hooks/useI18n';

export interface PriceInfo {
    title: string;
    price: string | number;
    isDelivery?: boolean;
}

interface CheckoutPriceInfoProps {
    priceInfo: PriceInfo[];
}

const CheckoutPriceInfo = (props: CheckoutPriceInfoProps) => {
    const { priceInfo } = props;
    const { $t } = useI18n();

    return (
        <FlexBox flexDirection={'column'} gap={'8px'} width={'100%'}>
            {priceInfo.map((item) => (
                <FlexBox key={item.title} justifyContent={'space-between'}>
                    <Text color={'text.secondary'} fontSize={'14px'} lineHeight={'18px'}>
                        {$t(item.title)}
                    </Text>
                    <Text
                        fontWeight={item.isDelivery ? 700 : 500}
                        fontSize={'14px'}
                        lineHeight={'18px'}
                        color={
                            item.isDelivery
                                ? String(item.price) === '0'
                                    ? '#9BD050'
                                    : '#FF7664'
                                : 'text.main'
                        }
                    >
                        {String(item.price) === '0'
                            ? $t('checkout_free')
                            : `${item.price} ${signs.tg}`}
                    </Text>
                </FlexBox>
            ))}
        </FlexBox>
    );
};

export default CheckoutPriceInfo;
