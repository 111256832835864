import React from 'react';
import { Collapse, FlexBox, Text } from 'pyatka-ui';
import { ShowMoreButton } from 'components';
import { useI18n } from 'hooks/useI18n';
import { isDescriptionEmpty } from '../utils/isDescriptionEmpty';

interface ProductDescriptionProps {
    descriptions: string;
}

const Description: React.FC<ProductDescriptionProps> = (props) => {
    const { descriptions } = props;
    const { $t } = useI18n();

    const [collapsed, setCollapsed] = React.useState(false);

    const togggleCollapse = () => setCollapsed((prev) => !prev);

    if (isDescriptionEmpty(descriptions)) {
        return null;
    }

    return (
        <FlexBox pt="14px" pb="18px" flexDirection="column">
            <Collapse open={collapsed} initialHeight="44px">
                <Text
                    fontWeight={500}
                    lineHeight="22px"
                    color="text.primary"
                    lineClamp={collapsed ? undefined : 2}
                    fontSize={{ default: '14px', md: '18px' }}
                    dangerouslySetInnerHTML={{ __html: descriptions }}
                />
            </Collapse>

            <FlexBox mx="auto" mt="16px">
                <ShowMoreButton
                    bg="block.secondary"
                    collapsed={collapsed}
                    toggleCollapse={togggleCollapse}
                >
                    {collapsed ? $t('hide') : $t('full_description')}
                </ShowMoreButton>
            </FlexBox>
        </FlexBox>
    );
};

export default Description;
