import { connect, ConnectedProps } from 'react-redux';
import { AppRootState } from 'store/types';
import {
    getProfileServiceData,
    getProfileUserData,
} from 'modules/UserProfileService/UserProfileService.selectors';
import { isUserAuth } from 'modules/Authorization/Authorization.selectors';
import {
    deleteGWProfileAddress,
    getGWProfileData,
    updateGWProfileAddress,
} from 'modules/UserProfileService/UserProfileService.actions';

const mapState = (state: AppRootState) => ({
    profileServiceData: getProfileServiceData(state),
    isAuth: isUserAuth(state),
    userData: getProfileUserData(state),
});
const mapDispatch = {
    deleteGWProfileAddress,
    getGWProfileData,
    updateGWProfileAddress,
};
export const ProfileAddressConnector = connect(mapState, mapDispatch);
export type ProfileAddressConnectProps = ConnectedProps<typeof ProfileAddressConnector>;
