import React from 'react';

export interface MapUserLocationProps extends MapUserLocationLayoutProps {}

export interface MapUserLocationLayoutProps {
    children?: React.FC;
    params: {
        center: string;
        city: string;
    };
    actions: {
        onClose: () => void;
        onPageReady?: () => void;
        onIsMoving?: () => void;
        onIsLoading?: () => void;
        onSetLocation: (arg: {
            address: string | null;
            long_lat: string | null;
            hasHouseVal?: boolean;
            houseNumber?: any;
        }) => void;
    };
    polygon: number[][];
    showSelectBtn?: boolean;
}

export enum MapStatus {
    NEVER = 'never',
    MOVING = 'moving',
    MOVED = 'moved',
}

export enum ButtonStatus {
    DEFAULT = 'default',
    SELECT = 'select',
}
