import ToasterMiddleware from 'services/middlewares/toaster';
import CheckSuccess from 'services/middlewares/checkSuccess';
import { InterceptorLayer } from 'services/types';
import CheckAuth from './checkAuth';

const requestMiddlewares: InterceptorLayer[] = [CheckSuccess.onRequest];
const responseMiddlewares: InterceptorLayer[] = [CheckSuccess.onResponse];

const responseErrMiddleware: InterceptorLayer[] = [
    ToasterMiddleware.onErrResponse,
    CheckAuth.onErrResponse,
];

export { requestMiddlewares, responseMiddlewares, responseErrMiddleware };
