import React from 'react';
import { DefaultLayoutLayout } from './DefaultLayout.Layout';
import { DefaultLayoutProps } from './types';

export const DefaultLayoutContainer: React.FC<DefaultLayoutProps> & {
    Layout: typeof DefaultLayoutLayout;
} = (props) => {
    const { children, ...rest } = props;

    return (
        <React.Fragment>
            <DefaultLayoutLayout {...rest}>{children}</DefaultLayoutLayout>
        </React.Fragment>
    );
};

DefaultLayoutContainer.Layout = DefaultLayoutLayout;
