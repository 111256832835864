import { ApiGwRequest } from 'services';
import { IFetchProductsPayload, IWishlistPayload } from 'modules/Wishlist/types';

export const fetchWishlist = (params: any) => {
    return ApiGwRequest({
        url: 'cart/v3/favorite',
        method: 'get',
        params: params,
    });
};
export const addWishlistItemService = (payload: { favorite: IWishlistPayload[] }) => {
    return ApiGwRequest({
        url: 'cart/v3/favorite',
        method: 'post',
        data: payload,
    });
};

export const deleteWishlistItemService = (payload: IWishlistPayload) => {
    const { product_id } = payload;

    return ApiGwRequest({
        url: `cart/v3/favorite/${product_id}`,
        method: 'delete',
    });
};
export const clearWishlistService = () => {
    return ApiGwRequest({
        url: 'cart/v3/favorite',
        method: 'delete',
    });
};
export const fetchWishlistProductsService = () => {
    return ApiGwRequest({
        url: 'cart/v3/favorite/product-ids',
        method: 'get',
    });
};
export const fetchCollections = () => {
    return ApiGwRequest({
        url: 'cart/v4/favorite/collection',
        method: 'get',
    });
};
export const fetchCollectionProducts = (params: IFetchProductsPayload) => {
    return ApiGwRequest({
        url: 'cart/v4/favorite',
        method: 'get',
        params: params,
    });
};

export const addWishlistService = (payload: {
    products: IWishlistPayload[];
    collection_id?: string;
}) => {
    return ApiGwRequest({
        url: 'cart/v4/favorite',
        method: 'post',
        data: payload,
    });
};
export const createCollectionService = (payload: { name: string; pre_created?: boolean }) => {
    return ApiGwRequest({
        url: 'cart/v4/favorite/collection',
        method: 'post',
        data: payload,
    });
};
export const editCollectionService = (payload: { id: string; name?: string; pinned?: boolean }) => {
    return ApiGwRequest({
        url: 'cart/v4/favorite/collection',
        method: 'put',
        data: payload,
    });
};
export const deleteCollectionService = (params: { ids: string[] }) => {
    return ApiGwRequest({
        url: 'cart/v4/favorite/collection',
        method: 'delete',
        params: params,
    });
};
export const deleteProductsService = (params: {
    collection_id: string[];
    product_ids: string[];
}) => {
    return ApiGwRequest({
        url: '/cart/v4/favorite',
        method: 'delete',
        data: params,
    });
};
