import React, { useCallback, useEffect, useState } from 'react';
import { Box, Button, FlexBox, Icon, Modal, Skeleton, usePyatka } from 'pyatka-ui';
import { useDispatch } from 'react-redux';
import { CollectionsWrapper, Title } from 'pages/WishListServicePage/v2/WishlistServicePage.Styles';
import { useI18n } from 'hooks/useI18n';
import useSlide from 'features/Listing/components/Categories/useSlide';
import { UnstyledBtn } from 'features/Listing/components/Categories/Categories.styles';
import CollectionItem from 'pages/WishListServicePage/v2/components/CollectionItem';
import { IWishlistCollection } from 'modules/Wishlist/types';
import { useAppRouter } from 'router/context';
import { collectionFormMobileOverlaySet } from 'modules/Wishlist/Wishlist.actions';
import { useLoader } from 'contexts/useLoader';
import { Loader } from 'plugins/loader';
import {
    ModalHeader,
    ToggleStatus,
} from 'pages/WishListServicePage/v2/components/AddToCollectionModal';
import EditCollectionLayout from 'pages/WishListServicePage/v2/components/EditCollectionLayout';

interface ICollectionsBlockProps {
    collections: IWishlistCollection[];
    activeCollectionId?: string;
}

const CollectionsBlock = (props: ICollectionsBlockProps) => {
    const { collections = [], activeCollectionId } = props;
    const { $t } = useI18n();
    const dispatch = useDispatch();
    const { scales } = usePyatka();
    const isMobile = (scales.matched || []).find((el: any) => el.name === 'md');
    const { appRedirect } = useAppRouter();
    const scrollRef = React.useRef<HTMLDivElement>(null);
    const { ref, goNext, goPrev, scrollCheck, showLeft, showRight } = useSlide(collections?.length);
    const [isOpenCreateCollectionModal, setIsOpenCreateCollectionModal] = useState(false);
    const { isLoading } = useLoader();
    const isCollectionsLoading = isLoading(Loader.favoriteCollections.indicator);
    const onClickCollection = useCallback(
        (props: { collection_id: string }) => {
            appRedirect({
                name: 'wishlist-collection-page',
                params: { collection_id: props.collection_id },
            });
        },
        [appRedirect]
    );
    const onCreateCollectionClick = useCallback(
        (isWeb?: boolean) => {
            // AppMetricEvents.jmart_create_collection();
            if (isWeb) {
                setIsOpenCreateCollectionModal(true);
                return;
            }
            dispatch(
                collectionFormMobileOverlaySet({
                    open: true,
                    config: { layout_type: 'add' },
                })
            );
        },
        [dispatch]
    );
    useEffect(() => {
        !isCollectionsLoading && scrollCheck();
    }, [isCollectionsLoading, scrollCheck]);
    useEffect(() => {
        scrollRef.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'nearest',
            inline: 'center',
        });
    }, [collections]);
    return (
        <>
            <Box mb={24}>
                {isMobile && (
                    <FlexBox justifyContent={'space-between'} alignItems={'center'} mb={18}>
                        <Title> {$t('collections')}</Title>
                        <Button
                            p={'5px 6px 5px 10px'}
                            border={'none'}
                            fontSize={'12px'}
                            borderRadius={'15px'}
                            backgroundColor={'block.secondary'}
                            onClick={() => {
                                onCreateCollectionClick();
                            }}
                        >
                            <FlexBox gap={'4px'} alignItems={'center'}>
                                {$t('create').toCapitalizeFirstLetter()}

                                <Box>
                                    <Icon
                                        fill={'#AAADB2'}
                                        icon={'arrow-right'}
                                        width={'6px'}
                                        height={'9px'}
                                    />
                                </Box>
                            </FlexBox>
                        </Button>
                    </FlexBox>
                )}
                {isCollectionsLoading || collections.length === 0 ? (
                    <CollectionsWrapper gap={isMobile ? '6px' : '20px'} width={'max-content'}>
                        <CollectionSkeleton isMobile={isMobile} />
                        <CollectionSkeleton isMobile={isMobile} />
                        <CollectionSkeleton isMobile={isMobile} />
                        <CollectionSkeleton isMobile={isMobile} />
                        <CollectionSkeleton isMobile={isMobile} />
                    </CollectionsWrapper>
                ) : (
                    <Box position={'relative'}>
                        {!isMobile && showLeft && collections?.length > 0 && (
                            <ArrowBtn onClick={goPrev} isLeft />
                        )}
                        <CollectionsWrapper
                            gap={isMobile ? '6px' : '20px'}
                            ref={ref}
                            onScroll={scrollCheck}
                            alignItems={isMobile ? 'flex-start' : 'center'}
                        >
                            {collections?.map((collection) => (
                                <Box
                                    key={collection.id}
                                    ref={collection?.id === activeCollectionId ? scrollRef : null}
                                >
                                    <CollectionItem
                                        collection={collection}
                                        onClickItem={onClickCollection}
                                        isActive={collection?.id === activeCollectionId}
                                    />
                                </Box>
                            ))}
                            {!isMobile && collections.length > 0 && (
                                <Box
                                    cursor={'pointer'}
                                    onClick={() => {
                                        onCreateCollectionClick(true);
                                    }}
                                >
                                    <ToggleStatus isMobile={false} />
                                </Box>
                            )}
                        </CollectionsWrapper>

                        {!isMobile && showRight && collections.length > 0 && (
                            <ArrowBtn onClick={goNext} />
                        )}
                    </Box>
                )}
            </Box>
            <Modal
                open={isOpenCreateCollectionModal}
                onClose={() => setIsOpenCreateCollectionModal(false)}
                positionMode={isMobile ? 'mobile' : 'default'}
                width={isMobile ? '100%' : '458px'}
                customHeader={isMobile ? <ModalHeader /> : undefined}
                padding={isMobile ? '8px 0 0' : '30px'}
                borderRadius={isMobile ? '24px 24px 0 0' : '24px'}
                swipeable
            >
                <EditCollectionLayout
                    layout_type={'add'}
                    // product_id={product_id}
                    onClose={() => setIsOpenCreateCollectionModal(false)}
                />
            </Modal>
        </>
    );
};
const CollectionSkeleton = ({ isMobile }: { isMobile: boolean }) => (
    <Skeleton
        width={isMobile ? '100px' : '195px'}
        height={isMobile ? '140px' : '104px'}
        borderRadius={'30px'}
        animation
    />
);
const ArrowBtn = (props: any) => (
    <UnstyledBtn {...props}>
        <svg
            width="65"
            height="65"
            viewBox="0 0 65 65"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle
                cx="26.5"
                cy="26.5"
                r="29.5"
                transform="matrix(-1 0 0 1 59 6)"
                fill="white"
                stroke="#F6F6F5"
                strokeWidth="6"
            />
            <path
                d="M33.2308 23L42 32.5M42 32.5L33.2308 42M42 32.5H23"
                stroke="#898D94"
                strokeWidth="2"
            />
        </svg>
    </UnstyledBtn>
);
export default CollectionsBlock;
