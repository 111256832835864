import React from 'react';
import { Box, Space } from 'pyatka-ui';
import { isNative } from 'utils/platform';
import { SearchBar } from 'components';
import LanguageSelector from 'components/LanguageSelector';
import HeaderUserLocation from 'components/HeaderUserLocation';
import { GoBackBtn, HeaderWrapper } from '../components';

const ProfileHeader: React.FC = () => (
    <>
        <HeaderWrapper overflow="visible" gap="10px">
            <GoBackBtn />
            <Box width="100%">
                <SearchBar dumb_layout secondary />
            </Box>
            {!isNative() && <LanguageSelector />}
            <HeaderUserLocation layout="main" />
        </HeaderWrapper>
        <Space height={'16px'} />
    </>
);

export default React.memo(ProfileHeader);
