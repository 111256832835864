import React from 'react';
import { MainBodyLayout } from './MainBody.Layout';
import { MainBodyProps } from './types';

export const MainBodyContainer: React.FC<MainBodyProps> & {
    Layout: typeof MainBodyLayout;
} = (props) => {
    return (
        <React.Fragment>
            <MainBodyLayout {...props} />
        </React.Fragment>
    );
};

MainBodyContainer.Layout = MainBodyLayout;
