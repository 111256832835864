import { AppRouteMiddlewareLayer } from 'router/types';

const ScrollToTop: AppRouteMiddlewareLayer = (config) => {
    const { route } = config;

    if (route.scrollToTop && __BROWSER__) {
        const scrollOptions = {
            top: 0,
            left: 0,
            behavior: route.scrollToTop === 'instant' ? route.scrollToTop : 'smooth',
        };
        // @ts-ignore
        window.scrollTo(scrollOptions);
    }
    return config;
};

export { ScrollToTop as default };
