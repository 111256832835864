import React from 'react';
import { Display, FlexBox, Icon, Text, usePyatka } from 'pyatka-ui';
import { StyledProductLabel } from 'components/ProductLabel/ProductLabel.styles';
import { ProductCardLabelLayoutProps, VARIANTS } from './types';

export const ProductLabelLayout: React.FC<ProductCardLabelLayoutProps> = (props) => {
    const {
        fontWeight = '700',
        fontSize = '0.875em',
        iconSize = {},
        padding = '',
        children,
        color = 'text.inverse',
        paymentTimes,
        variant,
        marginRight,
        isLoading,
        height = 'auto',
        icon = 'bonus',
        hasWhiteBg = false,
        ...rest
    } = props;

    const { theme } = usePyatka();

    // --------- const variants {bonus, price, credit, saves}
    const IS_BONUS = variant === VARIANTS[0];
    const IS_PRICE = variant === VARIANTS[1];
    const IS_CREDIT = variant === VARIANTS[2];
    const IS_SAVES = variant === VARIANTS[3];
    const IS_TRANS = variant === VARIANTS[5];

    const _fontSize = '0.875em';

    const _paddingLabel = IS_CREDIT ? '2px 5px' : '2px 9px';
    const _fontWeight = IS_PRICE ? '600' : fontWeight;
    const _fontColor = IS_SAVES ? 'status.error' : IS_TRANS ? 'transparent' : color;

    return (
        <FlexBox
            lineHeight={0}
            alignItems="center"
            marginRight={marginRight}
            height={`${height} !important`}
            {...rest}
        >
            <StyledProductLabel
                theme={theme}
                padding={padding || _paddingLabel}
                color={_fontColor}
                fontSize={fontSize || _fontSize}
                height={`${height} !important`}
                fontWeight={_fontWeight}
                variant={variant}
                styleType="contained"
                verticalAlign="center"
                hasWhiteBg={hasWhiteBg}
            >
                {children}
                {IS_BONUS && (
                    <React.Fragment>
                        <Display md={false}>
                            <Icon
                                icon={icon}
                                height={iconSize?.big || '12px'}
                                width={Math.ceil(Number(iconSize?.big) || 12) + 'px'}
                            />
                        </Display>
                        <Display md={true} xxl={false}>
                            <Icon icon={icon} height={iconSize?.small || '9px'} />
                        </Display>
                    </React.Fragment>
                )}
            </StyledProductLabel>
            {isLoading && (
                <Text
                    color="text.secondary"
                    variant="h7"
                    fontWeight="600"
                    skeleton={isLoading}
                    skeletonProps={{ width: '2.5em' }}
                />
            )}
            {paymentTimes && (
                <Text
                    color="text.secondary"
                    fontSize={{ default: '11px', md: '15px' }}
                    fontWeight="600"
                >
                    {paymentTimes}
                </Text>
            )}
        </FlexBox>
    );
};
