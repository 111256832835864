import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { WishListServiceInitialState } from 'modules/WishListService/types';

const wishListServiceDataSave: CaseReducer<
    WishListServiceInitialState,
    PayloadAction<{ wishListAllData: any; wishListGroupedData: any }>
> = (state, { payload }) => {
    const { wishListAllData, wishListGroupedData } = payload;
    state.wishListAllData = wishListAllData;
    state.wishListGroupedData = wishListGroupedData;
};

const wishListServiceDataFlush: CaseReducer<WishListServiceInitialState, PayloadAction> = (
    state,
    _action
) => {
    state.wishListAllData = [];
};

export default {
    wishListServiceDataSave,
    wishListServiceDataFlush,
};
