import { StaticPage } from './types';

export const contacts: StaticPage[] = [
    {
        id: 'payment_and_delivery',
        title: 'payment_and_delivery',
        link: { name: 'articles-page', params: { slug: 'oplata-i-dostavka' } },
    },
    {
        id: 'vozvrat',
        title: 'exchange_and_return',
        link: { name: 'articles-page', params: { slug: 'vozvrat' } },
    },
    {
        id: 'kontakty',
        title: 'contacts',
        link: { name: 'articles-page', params: { slug: 'kontakty' } },
    },
    {
        id: 'oferta',
        title: 'offer',
        link: { name: 'articles-page', params: { slug: 'oferta' } },
    },
    {
        id: 'dogovor',
        title: 'contract_for_sellers',
        link: {
            name: 'articles-page',
            params: { slug: 'dogovor-okazaniya-uslug-torgovoy-platformy' },
        },
    },
    {
        id: 'whatsapp',
        title: 'write_to_whatsapp',
        isMobile: true,
    },
    {
        id: 'partner',
        title: 'become_partner',
        link: { name: 'info-page-welcome' },
    },
    {
        id: 'qrlite',
        title: 'QR Lite',
        link: { name: 'qrlite' },
    },
];
