import slice from './Merchants.slice';

export const {
    fetchMerchant,
    fetchMerchantSucceed,
    fetchMerchantFailed,
    fetchProducts,
    fetchProductsSucceed,
    updateMerchantFilters,
    fetchProductsFailed,
    merchantFlush,
} = slice.actions;
