import React from 'react';
import { useSelector } from 'react-redux';

import {
    SearchServiceFilters,
    getSearchProductCount,
} from 'modules/SearchService/SearchService.selectors';
import {
    ProductsListFilters,
    ProductsListTotalCount,
} from 'modules/ProductsList/ProductsList.selectors';
import {
    getMerchantFilters,
    getMerchantProductsCount,
} from 'modules/Merchants/Merchants.selectors';
import { addRemoveFeature } from 'pages/ProductsList/utils';
import { useDebounce } from 'hooks';
import { FILTER_INDEX_PRICE } from 'modules/ProductsList/ProductsList.helpers';
import { AppMetricEvents } from 'AppMetric';
import { ModalListingFiltersLayoutProps, ModalListingFiltersProps } from './types';
import { ModalListingFiltersLayout } from './ModalListingFilters.Layout';

export const ModalListingFiltersContainer: React.FC<ModalListingFiltersProps> = (props) => {
    const [single_feat, setSingleFeat] = React.useState(null);

    const {
        is_search_page = false,
        is_merchant_page = false,
        updateFilters,
        closeButtonHandler,
    } = props;

    const isFirstRun = React.useRef(true);

    /* --------------------------------- Filters -------------------------------- */
    const {
        activeFilters = {},
        filtersObject,
        filters_order,
    }: any = useSelector(
        is_search_page
            ? SearchServiceFilters
            : is_merchant_page
            ? getMerchantFilters
            : ProductsListFilters
    );

    const product_count = useSelector(
        is_search_page
            ? getSearchProductCount
            : is_merchant_page
            ? getMerchantProductsCount
            : ProductsListTotalCount
    );

    const [modalFilters, setModalFilters] = React.useState(activeFilters);

    React.useEffect(() => {
        setModalFilters(activeFilters);
    }, [activeFilters]);

    const debouncedFilters: any = useDebounce<any>(modalFilters, 750);

    const active_filters_names = getActiveFiltersList(modalFilters);

    /* ---------------------------- Debounce filters ---------------------------- */
    React.useMemo(() => {
        !isFirstRun.current && updateFilters(debouncedFilters);

        if (isFirstRun.current) {
            isFirstRun.current = false;
        }
    }, [debouncedFilters, updateFilters]);

    const handleSingleFeat = (feature: any) => setSingleFeat(feature);

    const resetSingleFeat = () => setSingleFeat(null);

    const onCheckboxClick = (variant: any, feature_id: string, isActive?: boolean) => {
        setModalFilters((prev: any) => addRemoveFeature(variant, feature_id, prev));
        if (isActive) {
            AppMetricEvents.jmart_filter_option_click({
                feature_id: feature_id,
                variant: variant?.variant,
                variant_id: variant?.variant_id,
            });
        }
    };

    const switchToggler = (featureId: string | number) => {
        setModalFilters((prev: any) => ({
            ...prev,
            [featureId]: prev[`${featureId}`] ? 0 : 1,
        }));
    };

    function priceHandler() {
        const args = arguments;
        setModalFilters((prev: any) => ({
            ...prev,
            [FILTER_INDEX_PRICE]: {
                min: args[0],
                max: args[1],
            },
        }));
    }

    const submitFilters = () => {
        // updateFilters(debouncedFilters);
        closeButtonHandler();
    };

    const layoutProps: ModalListingFiltersLayoutProps = {
        ...props,
        product_count,
        resetSingleFeat,
        active_filters_names,
        activeFilters: modalFilters,
        filtersObject,
        filters_order,
        updateFilters,
        onCheckboxClick,
        handlePrice: priceHandler,
        switchToggler,
        single_feat,
        handleSingleFeat,
        submitFilters,
    };

    return <ModalListingFiltersLayout {...layoutProps} />;
};

export const getActiveFiltersList = (filters: any = {}) => {
    if (!Object.keys(filters || {}).length) {
        return [];
    }
    return Object.keys(filters).reduce((acc: any[], feature_id) => {
        if (feature_id !== FILTER_INDEX_PRICE) {
            const filter = Object.keys(filters[`${feature_id}`]).map((i) => ({
                ...filters[`${feature_id}`][`${i}`],
                feature_id,
            }));

            return [...acc, ...filter];
        }

        return acc;
    }, []);
};
