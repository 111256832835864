import React, { useEffect } from 'react';
import { Box, Button, FlexBox, Image, Space, Text, usePyatka } from 'pyatka-ui';
import styled from 'styled-components';
import { useI18n } from 'hooks/useI18n';
import { displayQR, getOneLink } from 'utils/onelink';
import bg from './assets/jusan-web-modal.png';

export const QrRedirectLayoutNew: React.FC<{ qr: string; title: string }> = () => {
    const {
        scales: { isMobile },
    } = usePyatka();

    if (isMobile) {
        return <MobileModal />;
    }

    return <WebModal />;
};

const QR_ID = 'onelink-qr';

const WebModal: React.FC = () => {
    useEffect(() => {
        displayQR(QR_ID);
    }, []);

    return (
        <React.Fragment>
            <FlexBox width={'760px'}>
                <Box margin={'0 auto'} width={'50%'} background="#ff7348">
                    <Box p="50px">
                        <TextContent desktop />
                        <Space height="24px" />
                        <QRBox
                            id={QR_ID}
                            width="150px"
                            height="150px"
                            background="white"
                            borderRadius="20px"
                            // overflow="hidden"
                            padding="17px"
                        />
                    </Box>
                </Box>
                <Box width={'50%'} />
                <FlexBox
                    id="wrapper-image"
                    position={'absolute'}
                    right={'0'}
                    top={'0'}
                    bottom={'0'}
                    left={'50%'}
                    overflow={'hidden'}
                    justifyContent={'center'}
                    backgroundImage={`url(${bg})`}
                    backgroundSize={'cover'}
                />
            </FlexBox>
        </React.Fragment>
    );
};

export const QRBox = styled(Box)`
    & canvas {
        width: 100%;
        display: none;
    }

    & canvas:first-of-type {
        display: block;
    }
`;

const MobileModal: React.FC = () => {
    const { $t } = useI18n();

    return (
        <React.Fragment>
            <Image src={bg} />
            <Box
                p={'30px 24px'}
                marginTop={'-10px'}
                textAlign={'center'}
                borderRadius={'26px 26px 0 0'}
                bg={'block.main'}
                position={'relative'}
                zIndex={'9'}
                top="-16px"
                mb={'-16px'}
            >
                <TextContent />
                <Space height={'40px'} />
                <Button
                    // @ts-ignore
                    as="a"
                    href={getOneLink().clickURL}
                    target="_blank"
                    rel="noreferrer"
                    height="55px"
                    variant="brand"
                    padding="18px 16px"
                    borderRadius="22px"
                    width="100%"
                    styleType="contained"
                    style={{ flex: 1 }}
                >
                    <Text fontSize="16px" fontWeight={600} lineHeight="20.48px">
                        {$t('download_app')}
                    </Text>
                </Button>
            </Box>
        </React.Fragment>
    );
};

const TextContent: React.FC<{ desktop?: boolean }> = ({ desktop = false }) => {
    const { $t } = useI18n();

    return (
        <Box maxWidth={desktop ? '250px' : '100%'} m={desktop ? 0 : '0 auto'}>
            <Text
                color={desktop ? 'white' : 'text.main'}
                fontWeight={700}
                // variant={'h2'}
                fontSize={['24px', '20px']}
                lineHeight={['26px', '20px']}
            >
                {$t('more_opportunitites_in_jusan')}
            </Text>
            <Space height="16px" />
            <Text fontSize={'16px'} lineHeight="20.48px" color={desktop ? 'white' : '#898D94'}>
                {$t('download_jusan_app_to_continue')}
            </Text>
        </Box>
    );
};
