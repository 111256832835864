import React, { useCallback, useEffect, useMemo } from 'react';
import { usePyatka } from 'pyatka-ui';
import { isNative } from 'utils/platform';
import { formatAddressesForNative } from 'components/ProfileAddress/helper';
import { Dialog } from 'plugins/dialog';
import { nativeCall } from 'hybrid/index';
import { FormFieldsInitialData } from 'modules/UserProfileService/ProfileServiceConfigs';
import { ProfileAddressAddBody, ProfileAddressEditBody } from 'modules/UserProfileService/types';
import { useI18n } from 'hooks/useI18n';
import { useLoader } from 'contexts/useLoader';
import { ProfileAddressNewLayout } from './ProfileAddress.NewLayout';
import { ProfileAddressProps } from './types';

export const ProfileAddressContainer: React.FC<ProfileAddressProps> = (props) => {
    const { userData, isAuth, profileServiceData } = props;
    const { scales } = usePyatka();
    const isMobile = (scales.matched || []).find((el: any) => el.name === 'md');
    const {
        addresses: shippingAddress,
        addressDefault: shippingAddressDefault,
        // loading: isLoading,
    } = profileServiceData;
    const { indicators } = useLoader();
    const isLoading = indicators.profileAddresses.status;
    const { $t } = useI18n();
    const defaultAddress = useMemo(() => {
        if (shippingAddress?.length === 0) {
            return {
                addressName: $t('enter_address'),
            };
        }
        if (shippingAddressDefault === undefined) {
            return {
                addressName: $t('choose_address'),
            };
        }
        return {
            addressName: shippingAddressDefault?.values?.s_address?.value,
        };
    }, [shippingAddress?.length, shippingAddressDefault, $t]);

    const title = useMemo(
        () => (shippingAddressDefault === undefined ? $t('choose_address') : $t('my_addresses')),
        [$t, shippingAddressDefault]
    );

    const showAddressesHandler = useCallback((): void => {
        if (shippingAddress.length === 0) {
            Dialog.openDialog({
                body: 'ProfileAddressForm',
                swipeable: true,
                props: {
                    address: FormFieldsInitialData,
                    backButtonInitial: 1,
                },
                config: {
                    bottomSheet: true,
                    noPadding: !isMobile,
                    closeButton: false,
                },
            });
            return;
        }
        showAddressesWeb();
    }, [shippingAddress]);

    useEffect(() => {
        if (!isLoading) {
            if (shippingAddressDefault === undefined) {
            }
        }
    }, [isLoading, shippingAddress, shippingAddressDefault, showAddressesHandler]);

    const showAddressesWeb = () => {
        Dialog.openDialog({
            title,
            body: 'ProfileAddressList',
            props: {
                // updateList,
                addresses: shippingAddress,
            },
            config: {
                bottomSheet: true,
            },
        });
    };

    const layoutProps = {
        ...props,
        showAddressesHandler,
        isLoading,
        ...defaultAddress,
        userData,
    };

    return <>{isAuth && <ProfileAddressNewLayout {...layoutProps} />}</>;
};
