import React from 'react';
import { Skeleton, Space } from 'pyatka-ui';
import styles from './SupermarketCard.module.scss';

export const SupermarketCardLayoutSkeleton: React.FC = () => (
    <div className={styles.wrapperSkeleton}>
        <Skeleton height={24} animation />
        <Space my={3} />
        <Skeleton height={40} animation />
    </div>
);
