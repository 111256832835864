import React from 'react';
import { Box, Display, FlexBox, Space, Text } from 'pyatka-ui';
import { useI18n } from 'hooks/useI18n';
import { ProductsListSorterProps, SortValueType } from './types';
import { sortOptions } from './const';

type SortOption = [SortValueType, string];

const ProductsListSorter: React.FC<ProductsListSorterProps> = ({
    handleSortChange,
    activeSortValue,
    isLoading = false,
}) => {
    const { $t } = useI18n();

    return (
        <Display md={false}>
            <FlexBox flexDirection="row" alignSelf="start" whiteSpace="nowrap">
                <Text
                    fontSize="18px"
                    fontWeight={500}
                    color="text.main"
                    paddingRight="20px"
                    skeleton={isLoading}
                    skeletonProps={{ width: '8em', height: '1em', borderRadius: '12px' }}
                >
                    {$t('sort_by')}
                </Text>
                <FlexBox flexDirection="row" flexWrap="wrap" alignSelf="start" width="100%">
                    {Object.entries(sortOptions).map((entry) => {
                        const [key, value] = entry as SortOption;
                        const isActive = key === activeSortValue;
                        const itemColor = isActive ? 'text.brand_variant' : 'text.secondary';

                        return (
                            <Box
                                key={key}
                                ml="20px"
                                cursor="pointer"
                                whiteSpace="nowrap"
                                onClick={() => handleSortChange(key)}
                            >
                                <Text
                                    fontSize="18px"
                                    color={itemColor}
                                    fontWeight={isActive ? 600 : 500}
                                    skeleton={isLoading}
                                    skeletonProps={{
                                        width: '6em',
                                        height: '1em',
                                        borderRadius: '12px',
                                    }}
                                >
                                    {$t(value)}
                                </Text>
                            </Box>
                        );
                    })}
                </FlexBox>
            </FlexBox>
            <Space marginTop="30px" />
        </Display>
    );
};

export default ProductsListSorter;
