import React from 'react';
import { Space, Button, FlexBox, Icon, Image, Text, Box } from 'pyatka-ui';
import { IPromotionModal } from 'modules/Promotions/types';

export const PromotionModalLayout = ({
    handleClickBtn,
    handleClose,
    title,
    main_text,
    button_text,
    modal_image,
    promo_period,
    isMobile,
    show_date,
}: IPromotionModal & any) => {
    return (
        <FlexBox
            bg={'#EFF0F1'}
            position="relative"
            onClick={handleClickBtn}
            cursor="pointer"
            flexDirection="column"
        >
            <Image
                backgroundPosition="center"
                width="100%"
                src={modal_image}
                backgroundSize={'cover'}
                maxHeight={isMobile ? '79vh' : '55vh'}
            />
            <CloseButton handleClose={handleClose} />

            <FlexBox
                flexDirection="column"
                justifyContent="space-between"
                bg="white"
                borderRadius={'26px 26px 0 0'}
                p="26px 24px 0"
                mt={'-20px'}
                zIndex="1"
            >
                <Box zIndex={1}>
                    <FlexBox alignItems="center" gap="20px">
                        <Text fontSize={'24px'} fontWeight={'bold'}>
                            {title}
                        </Text>
                        {show_date !== 0 && (
                            <Text
                                color="text.brand"
                                fontSize={'17px'}
                                textAlign="right"
                                lineClamp={'1'}
                                style={{
                                    flex: 'none',
                                }}
                            >
                                {promo_period}
                            </Text>
                        )}
                    </FlexBox>
                    <Text color="text.secondary" fontSize={'16px'} lineHeight="138%" my={'3'}>
                        {main_text}
                    </Text>
                    <Button
                        py="20px"
                        width="100%"
                        variant="brand"
                        borderRadius="22px"
                        styleType="contained"
                    >
                        {button_text}
                    </Button>
                </Box>
                <Space mb={4} />
            </FlexBox>
        </FlexBox>
    );
};

const CloseButton = ({ handleClose }: any) => (
    <FlexBox
        bg="white"
        width="32px"
        height="32px"
        cursor="pointer"
        borderRadius="50%"
        alignItems="center"
        justifyContent="center"
        onClick={handleClose}
        position="absolute"
        right="16px"
        top="16px"
        zIndex={3}
    >
        <Icon icon="close" fill="#AAADB2" />
    </FlexBox>
);
