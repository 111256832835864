export const phoneLinks = [
    { icon: 'phoneFooter', link: 'https://wa.me/+77009907711', text: '+7 (700) 990 77 11' },
    { icon: 'mailFooter', link: 'mailto:info@jmart.kz', text: 'info@jmart.kz' },
];

export const socialLinks = [
    { icon: 'instagramFooter', link: 'https://www.instagram.com/jusanshopkz' },
    { icon: 'whatsappFooter', link: 'https://wa.me/+77009907711' },
    { icon: 'facebookFooter', link: 'https://www.facebook.com/jusanshopkz' },
];
