import React from 'react';
import { Box, FlexBox, Icon, Space, Text } from 'pyatka-ui';
import { useI18n } from 'hooks/useI18n';
interface IBonusInfoProps {
    bonuses_total?: string | number;
    bonus_text?: string;
}
const BonusInfo = (props: IBonusInfoProps) => {
    const { bonuses_total, bonus_text } = props;
    const { $t } = useI18n();
    return (
        <>
            <Space height={'30px'} />
            <FlexBox
                justifyContent={'center'}
                alignItems={'center'}
                gap={'12px'}
                p={{ default: '0px 38px' }}
            >
                <Box
                    backgroundColor={'status.success'}
                    borderRadius={'19px'}
                    p={{ default: '1px 8px' }}
                    color={'white'}
                >
                    <FlexBox alignItems={'center'} gap={'5px'}>
                        <Text color={'#FFF'} fontWeight={'700'} fontSize={{ default: '15px' }}>
                            +{bonuses_total}
                        </Text>{' '}
                        <Icon icon={'bonus'} />
                    </FlexBox>
                </Box>
                <Text fontSize={{ default: '11px' }} fontWeight={'400'} color={'text.placeholder'}>
                    {bonus_text
                        ?.split(' ')
                        .map((b: string) => $t(b))
                        .join(' ')}
                </Text>
            </FlexBox>
        </>
    );
};

export default BonusInfo;
