import { connect, ConnectedProps } from 'react-redux';
import { AppRootState } from 'store/types';
import { SetAuthModalStatus } from 'modules/App/App.actions';
import { getAuthModalStatus } from 'modules/App/App.selectors';

import {
    authErrors,
    getUserOtpData,
    isUserAuth,
} from 'modules/Authorization/v2/Authorization.selectors';
import {
    AuthorizationInit,
    AuthorizationVerifyOtp,
    OtpClear,
} from 'modules/Authorization/v2/Authorization.actions';

const mapState = (state: AppRootState) => ({
    authModal: getAuthModalStatus(state),
    otp: getUserOtpData(state),
    authErrors: authErrors(state),
    isLoggedIn: isUserAuth(state),
});
const mapDispatch = {
    SetAuthModalStatus,
    AuthorizationInit,
    AuthorizationVerifyOtp,
    OtpClear,
    // AuthorizationBasicInit,
};

export const AuthorizationModalConnector = connect(mapState, mapDispatch);
export type AuthorizationModalConnectProps = ConnectedProps<typeof AuthorizationModalConnector>;
