import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Box } from 'pyatka-ui';
import { useSelector } from 'react-redux';
import { wishlistProductsCount } from 'modules/Wishlist/Wishlist.selectors';
import { SearchBtn, GoBackBtn, Title, HeaderWrapper, FavoriteClearBtn } from '../components';

interface WishlistHeaderProps {}

const DefaultHeader: React.FC<WishlistHeaderProps> = (props) => {
    const {} = props;

    const [title, setTitle] = React.useState('');
    const onChangeTitle = (newState: any) => setTitle(newState?.title || '');

    // const { wishlistCount } = useWishlist();
    const wishlistCount = useSelector(wishlistProductsCount);
    const showClearIcon = wishlistCount > 0;

    return (
        <React.Fragment>
            <Helmet onChangeClientState={onChangeTitle} />
            <HeaderWrapper>
                <GoBackBtn />
                <Title title={'Избранное'} />
                <SearchBtn />

                {/*{showClearIcon ? <FavoriteClearBtn /> : <Box width={'24px'} />}*/}
            </HeaderWrapper>
        </React.Fragment>
    );
};

export default React.memo(DefaultHeader);
