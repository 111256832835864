import React from 'react';
import { Box, FlexBox, Space, Text, usePyatka } from 'pyatka-ui';
import priceFormatter from 'utils/priceFormatter';
import { signs } from 'utils/symbols';
import {
    DeliveryInfoWrapper,
    PriceWrapper,
} from 'pages/ClientOrderDetailPage/ClientOrderWrapper.Layout';
import PaymentMethodInfo from 'pages/ClientOrderDetailPage/components/PaymentMethodInfo';
import BonusInfo from 'pages/ClientOrderDetailPage/components/BonusInfo';
import { Card } from 'components/ClientOrderDetailCard/ClientOrderDetailCard.Styles';
import { useI18n } from 'hooks/useI18n';
import { OrderItem } from 'modules/OrdersService/types';

type TotalPriceInfoBlockProps = {
    hasToShowPriceBlock: boolean;
    filteredPriceBlock: [string, string | number | undefined][];
    order: OrderItem;
};
const TotalPriceInfoBlock = (props: TotalPriceInfoBlockProps) => {
    const { hasToShowPriceBlock, filteredPriceBlock, order } = props;
    const { $t } = useI18n();
    const {
        scales: { isMobile },
    } = usePyatka();
    return (
        <Card
            isMobile={isMobile}
            marginTop={isMobile ? '-8px' : 0}
            borderRadius={isMobile ? '0 0 26px 26px' : '0px'}
        >
            <FlexBox
                pt={isMobile ? '10px' : 0}
                flexDirection={'column'}
                alignItems={'center'}
                justifyContent={'center'}
            >
                <Text
                    fontSize={{ default: '13px', md: '14px' }}
                    lineHeight={{ default: '16px', md: '18px' }}
                    color={'#AAADB3'}
                >
                    {$t('total_amount')}
                </Text>
                <Text
                    color={'text.main'}
                    fontSize={{ default: '40px', md: '44px' }}
                    fontWeight={600}
                    lineHeight={{ default: '50px', md: '56px' }}
                >
                    {priceFormatter(order?.total)}{' '}
                    <span style={{ opacity: '0.5' }}>{signs.tg}</span>
                </Text>
                {hasToShowPriceBlock && (
                    <>
                        <Space height={'8px'} />
                        <FlexBox justifyContent={'center'} textAlign={'center'}>
                            {filteredPriceBlock.map((priceItem: any[]) => {
                                return (
                                    <PriceWrapper
                                        key={priceItem[0]}
                                        title={priceItem[0]}
                                        price={priceItem[1]}
                                    />
                                );
                            })}
                        </FlexBox>
                    </>
                )}
                <Box
                    width={'100%'}
                    mt={{ default: '24px', md: '30px' }}
                    p={'20px'}
                    bg={'block.secondary'}
                    borderRadius={'25px'}
                >
                    {Boolean(order?.payment_method?.payment) && (
                        <PaymentMethodInfo payment_method={order.payment_method} />
                    )}

                    {order.shipping?.has_shipping && (
                        <Box>
                            <Space height={'9px'} />
                            <DeliveryInfoWrapper
                                firstTitle={'address'}
                                secondTitle={order?.address!}
                                firstTextAlign={'start'}
                                secondTextAlign={'end'}
                                width={'70%'}
                            />
                        </Box>
                    )}
                </Box>
                {Boolean(order?.showBonuses) && (
                    <BonusInfo bonus_text={order?.bonusText} bonuses_total={order?.bonuses_total} />
                )}
            </FlexBox>
        </Card>
    );
};

export default TotalPriceInfoBlock;
