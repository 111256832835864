import { SupermarketInitialState } from 'modules/Supermarkets/types';

const state: SupermarketInitialState = {
    supermarket_list: [],
    section: null,
    section_id: '',
    supermarket: null,
    from: '',
    city: '',
    supermarket_search: {
        query: '',
        list: [],
    },
    grouped_supermarkets: [],
    recommendations: { title: '', list: [] },
    categories: [],
};

export default state;
