import { createSelector } from 'reselect';
import initialState from 'modules/Categories/Categories.state';
import {
    CategoryInitialState,
    CategoryItem,
    CategoryItems,
    CategoryPathNames,
} from 'modules/Categories/types';
import { AppRootState } from 'store/types';

export const getCategoriesState = (state: AppRootState): CategoryInitialState => {
    return state.CategoriesReducer || initialState;
};

export const getCategories = createSelector(
    [getCategoriesState],
    (state) => state.categories || {}
);

export const getCategoriesNames = createSelector(
    [getCategoriesState],
    (state) => state.categoriesNames
);

export const getPathNames = createSelector(
    [getCategoriesState],
    (state): CategoryPathNames[] | null => {
        const makeLinkConfig = (id: any) => ({ name: 'home-page', query: { category_id: id } });
        const paths: any = state.breadcrumb.map(({ title, id }: any) => {
            // console.log(id);
            const res = {
                link: makeLinkConfig(id),
                title,
            };
            if (id === '2207') {
                // @ts-ignore
                res.link = { name: 'store-main' };
            }
            return res;
        });
        return paths || null;
    }
);

export const getAllCategories = createSelector([getCategories], (items): CategoryItems => {
    return items;
});

export const getCategoriesById = (id: string, state: AppRootState): CategoryItem[] | [] => {
    const categories = getAllCategories(state);
    const breadcrumb = getPathNames(state);
    const selected_categories = categories[id.toString()] ? categories[id.toString()] : false;
    if (selected_categories) {
        if (selected_categories.length === 0) {
            let idx = '';
            if (breadcrumb) {
                idx = String(breadcrumb[breadcrumb.length - 2]);
            }
            return categories[idx.toString()] || [];
        }
    }
    return selected_categories || [];
};
