import slice from 'modules/SearchService/SearchService.slice';

export const {
    SearchServiceInit,
    SearchServiceSucceed,
    SearchServiceFailed,
    SearchServiceFlush,
    SearchServiceFiltersUpdate,
    SearchServiceFiltersFlush,
    SearchServiceFiltersFlushSucceed,
    SearchServiceMobileOverlaySet,
    SearchServiceSuggestionsInit,
    SearchServiceSuggestionsSucceed,
    SearchServiceSuggestionsFlush,
} = slice.actions;
