import { ApiGwRequest } from 'services';

export const CartServiceGet = (payload?: any) => {
    return ApiGwRequest({
        url: 'cart/v2/cart',
        method: 'get',
        params: payload?.params,
        // signal: controller.signal,
    });
};

export const CartServiceGetV3 = (payload?: any) => {
    return ApiGwRequest({
        url: 'cart/v3/basket',
        method: 'get',
        params: payload?.params,
        // signal: controller.signal,
    });
};

export const CartServiceGetCountV3 = (payload?: any) => {
    return ApiGwRequest({
        url: 'cart/v3/basket/products-count',
        method: 'get',
        params: payload?.params,
        // signal: controller.signal,
    });
};

export const CartServicePostByProductsV3 = ({ data }: any) => {
    return ApiGwRequest({
        url: 'cart/v3/basket/by-products',
        method: 'post',
        data: {
            ...data,
            mode: 0,
        }, // signal: controller.signal,
    });
};

export const CartServiceClear = () => {
    return ApiGwRequest({
        url: 'cart/v2/cart',
        method: 'delete',
    });
};
export const CartServiceClearV3 = ({ id_param }: any) => {
    console.log('servce params ', id_param);
    return ApiGwRequest({
        url: 'cart/v3/basket',
        method: 'delete',
        params: { cart_hash: id_param },
    });
};

export const CartServiceDelete = ({ id_param }: any | undefined) => {
    return ApiGwRequest({
        url: `cart/v2/cart/product/${id_param}`,
        method: 'delete',
    });
};

export const CartServiceDeleteV3 = ({ id_param }: any | undefined) => {
    return ApiGwRequest({
        url: `cart/v3/basket/product/${id_param}`,
        method: 'delete',
    });
};

export const CartServicePut = ({ data }: any) => {
    return ApiGwRequest({
        url: 'cart/v2/cart',
        method: 'put',
        data,
    });
};

export const CartServicePutV3 = ({ data }: any) => {
    return ApiGwRequest({
        url: 'cart/v3/basket',
        method: 'put',
        data: {
            ...data,
            mode: 0,
        },
    });
};

export const CartServicePutAmountV3 = ({ data }: any) => {
    return ApiGwRequest({
        url: 'cart/v3/basket/amount',
        method: 'put',
        data: {
            ...data,
            mode: 0,
        },
    });
};
export const fetchForgottenProducts = (
    company_id: number,
    category_ids: (number | undefined)[],
    page?: string
) => {
    const queryParams = category_ids.map((id) => `category_ids[]=${id}`).join('&');
    return ApiGwRequest({
        url: `listing/v1/products/forgotten?${queryParams}`,
        method: 'get',
        params: { company_id, page },
    });
};
