export const indicators = [
    'auth',
    'main',
    'listing',
    'merchant',
    'product',
    'category',
    'search',
    'mapGeo',
    'review',
    'offer',
    'productReviews',
    'profileAddresses',
    'cart',
    'orders',
    'orderDetail',
    'similarProducts',
    'mail',
    'supermarkets',
    'supermarketDetail',
    'promotions',
    'promotionDetail',
    'authBasic',
    'wishlist',
    'postomatOpen',
    'promotionModal',
    'favorite',
    'favoriteCollections',
    'addToCollection',
    'supermarkets_search',
    'checkoutOrderPayment',
    'supermarkets_recommendations',
    'lego_fetch_claims',
    'forgottenProducts',
    'supermarkets_categories',
] as const;

export type IndicatorName = typeof indicators;
export type Indicator = IndicatorName[number];
export type IndicatorState = {
    [key in Indicator]: IndicatorModel;
};

interface IndicatorModel {
    status: boolean;
    global: boolean;
    payload?: any;
}

export interface IndicatorManagerPayload extends IndicatorModel {
    indicator: Indicator;
}

export interface IndicatorManagerConfig {
    global?: IndicatorModel['global'];
    payload?: any;
}

interface IndicatorManager {
    on: (config?: IndicatorManagerConfig) => void;
    off: () => void;
    indicator: Indicator;
}

export type IndicatorManagers = {
    [key in Indicator]: IndicatorManager;
};
