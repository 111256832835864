import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    SearchServiceSuggestionsFlush,
    SearchServiceSuggestionsInit,
} from 'modules/SearchService/SearchService.actions';
import { SearchServiceInputQuery } from 'modules/SearchService/SearchService.selectors';
import { useAppRouter } from 'router/context';
import { useDebounce } from 'hooks';
import { isSuperSearch } from '../utils';

const useLoadSuggestions = (searchQuery: string) => {
    const { location } = useAppRouter();
    const dispatch = useDispatch();
    const inputQuery = useSelector(SearchServiceInputQuery);
    const debouncedQuery: string = useDebounce<string>(searchQuery, 225);

    React.useEffect(() => {
        const asynSuggestions = async (search_value: string) => {
            try {
                dispatch(
                    SearchServiceSuggestionsInit({
                        search_value,
                        isSuper: isSuperSearch(location.pathname),
                    })
                );
            } catch (error) {
                console.log(error);
            }
        };

        const flushSuggestions = () => dispatch(SearchServiceSuggestionsFlush());

        if (!debouncedQuery) {
            flushSuggestions();
        }

        if (debouncedQuery.trim().length > 2 && debouncedQuery !== inputQuery) {
            asynSuggestions(debouncedQuery);
        }
    }, [debouncedQuery]);
};

export default useLoadSuggestions;
