import loadable from '@loadable/component';
import { AppRouteProps } from 'router/types';
// --------------- FETCHERS ---------------
import { ProductListFetch } from 'pages/ProductsList/ProductList.Fetch';
import { NewMainPageFetch } from 'pages/NewMainPage/NewMainPage.Fetch';
import { CatalogPageFetch } from 'pages/CatalogPage/CatalogPage.Fetch';
import { SupermarketsFetch } from 'pages/Supermarkets/Supermarkets.Fetch';
import { SupermarketDetailPageFetch } from 'pages/SupermarketDetailPage/SupermarketDetailPage.Fetch';
import { NewProductDetailPageFetch } from 'pages/NewProductDetailPage/NewProductDetailPage.Fetch';
import { MerchantPageFetch } from 'pages/MerchantPage/MerchantPage.Fetch';
import { ArticlesFetch } from 'pages/Articles/Articles.Fetch';
import { NewPromotionsPageFetch } from 'pages/NewPromotionsPage/NewPromotionsPage.Fetch';
import { NewPromotionDetailPageFetch } from 'pages/NewPromotionDetailPage/NewPromotionDetailPage.Fetch';
import { AlgoliaFetch } from 'pages/Algolia/Algolia.Fetch';
import { SupermarketsSearchFetch } from 'pages/SupermarketsSearch/SupermarketsSearch.Fetch';

import { isNative } from 'utils/platform';
import WishlistCollectionPage from 'pages/WishlistCollectionPage';
import * as Layouts from '../layouts';

const NewMainPage = loadable(() => import('../pages/NewMainPage'), { ssr: true });
const DIYPage = loadable(() => import('../pages/DIYPage'), { ssr: true });
const CatalogPage = loadable(() => import('../pages/CatalogPage'), { ssr: true });
const ProductsList = loadable(() => import('../pages/ProductsList'), { ssr: true });
const NewProductDetailPage = loadable(() => import('../pages/NewProductDetailPage'), { ssr: true });
const MerchantPage = loadable(() => import('../pages/MerchantPage'), { ssr: true });
const Supermarkets = loadable(() => import('../pages/Supermarkets'), { ssr: true });
const SupermarketDetailPage = loadable(() => import('../pages/SupermarketDetailPage'), {
    ssr: true,
});
const WishListServicePage = loadable(() => import('../pages/WishListServicePage'), { ssr: true });
const CartServicePage = loadable(() => import('../pages/CartServicePage'), { ssr: true });
const ClientProfilePage = loadable(() => import('../pages/ClientProfilePage'), { ssr: true });
const ClientOrderDetailPage = loadable(() => import('../pages/ClientOrderDetailPage'), {
    ssr: true,
});
const ContactsPage = loadable(() => import('../pages/ContactsPage'), { ssr: true });
const QRLitePage = loadable(() => import('../pages/Articles/QRLite'), { ssr: true });
const QRLiteCustomer = loadable(() => import('../pages/Articles/QRLiteCustomer'), { ssr: true });
const Articles = loadable(() => import('../pages/Articles'), { ssr: true });
const NewPromotionsPage = loadable(() => import('../pages/NewPromotionsPage'), { ssr: true });
const NewPromotionDetailPage = loadable(() => import('../pages/NewPromotionDetailPage'), {
    ssr: true,
});
const Partner = loadable(() => import('../pages/Partner'), { ssr: true });
const PartnerWelcome = loadable(() => import('../pages/PartnerWelcome'), { ssr: true });
const PartnerCommissions = loadable(() => import('../pages/PartnerCommissions'), { ssr: true });
const CheckoutDeliveryV2Page = loadable(() => import('../pages/CheckoutDeliveryV2Page'), {
    ssr: true,
});
const QRPage = loadable(() => import('../pages/QRPage'), { ssr: true });
const ProductReviewsPage = loadable(() => import('../pages/ProductReviewsPage'), { ssr: true });
const DebugPage = loadable(() => import('../pages/DebugPage'), { ssr: true });
const Algolia = loadable(() => import('../pages/Algolia'), { ssr: true });
const NotFound = loadable(() => import('../pages/NotFound'), { ssr: true });
const SupermarketsSearch = loadable(() => import('../pages/SupermarketsSearch'), { ssr: true });
const JusanJIPage = loadable(() => import('../pages/JusanJIPage'), { ssr: true });
const SkillboxPage = loadable(() => import('../pages/SkillboxPage'), { ssr: true });

export const routesConfig: AppRouteProps[] = [
    {
        path: '/',
        component: NewMainPage,
        name: 'index-page',
        exact: true,
        fetchPageData: NewMainPageFetch,
        headerLayout: 'main',
        isRender: true,
        disableSwipe: true,
    },
    {
        path: '/home',
        component: DIYPage,
        name: 'diy-page',
        exact: true,
        // fetchPageData: NewMainPageFetch,
        headerLayout: 'catalog-header',
        isRender: true,
        // disableSwipe: true,
    },
    {
        path: '/catalog',
        component: CatalogPage,
        name: 'catalog-page',
        fetchPageData: CatalogPageFetch,
        headerLayout: 'catalog-header',
        exact: true,
        isRender: true,
        scrollToTop: 'instant',
    },
    {
        path: '/products',
        component: ProductsList,
        name: 'home-page',
        exact: true,
        scrollToTop: false,
        isRender: true,
        fetchPageData: ProductListFetch,
        isWhiteBg: true,
        headerLayout: 'listing-header',
    },
    {
        path: '/products/:product_id/:product_type/:slug?',
        component: NewProductDetailPage,
        name: 'product-detail-page',
        fetchPageData: NewProductDetailPageFetch,
        scrollToTop: 'instant',
        headerLayout: 'product-detail',
        isWhiteBg: true,
        isRender: true,
    },
    {
        path: '/merchant',
        component: MerchantPage,
        name: 'merchant',
        exact: true,
        scrollToTop: false,
        isRender: true,
        fetchPageData: MerchantPageFetch,
        isWhiteBg: true,
        headerLayout: 'company-header',
    },
    {
        path: '/store',
        component: Supermarkets,
        name: 'store-main',
        fetchPageData: SupermarketsFetch,
        isRender: true,
        isWhiteBg: true,
        headerLayout: 'store',
        scrollToTop: 'instant',
    },
    {
        path: '/store/:store_id',
        component: SupermarketDetailPage,
        name: 'store-detail',
        fetchPageData: SupermarketDetailPageFetch,
        isWhiteBg: true,
        headerLayout: 'company-header',
    },
    {
        path: '/wishlist',
        component: WishListServicePage,
        name: 'wishlist-page',
        mobileOnly: true,
        isWhiteBg: true,
        isPublic: false,
        headerLayout: 'wishlist-header',
    },
    {
        path: '/wishlist/:collection_id',
        component: WishlistCollectionPage,
        name: 'wishlist-collection-page',
        // scrollToTop: 'instant',
        headerLayout: 'wishlist-collection-header',
        isWhiteBg: true,
        isPublic: false,
        // isRender: true,
    },
    {
        path: '/cart',
        component: CartServicePage,
        name: 'cart-page',
        mobileOnly: true,
    },
    {
        path: '/profile',
        component: ClientProfilePage,
        name: 'profile-page',
        isPublic: false,
        headerLayout: 'profile',
        mobileOnly: true,
        scrollToTop: false,
    },
    {
        path: '/order/:order_id',
        component: ClientOrderDetailPage,
        name: 'client-order-detail-page',
        isPublic: false,
        mobileOnly: true,
    },
    {
        path: '/articles/kontakty',
        component: ContactsPage,
        isRender: true,
        name: 'contacts',
    },
    {
        path: '/articles/qrlite',
        component: QRLitePage,
        isRender: true,
        name: 'qrlite',
        layout: Layouts.EmptyLayout,
        scrollToTop: 'instant',
        hasNav: true,
    },
    {
        path: '/articles/qrlitecustomer',
        component: QRLiteCustomer,
        isRender: true,
        name: 'qrlitecustomer',
        layout: Layouts.EmptyLayout,
        scrollToTop: 'instant',
        hasNav: true,
    },
    {
        path: '/articles/:slug',
        component: Articles,
        fetchPageData: ArticlesFetch,
        name: 'articles-page',
    },
    {
        path: '/promotions',
        component: NewPromotionsPage,
        fetchPageData: NewPromotionsPageFetch,
        name: 'promotions-page',
        isRender: true,
    },
    {
        path: '/promotions/:promotion_id',
        component: NewPromotionDetailPage,
        fetchPageData: NewPromotionDetailPageFetch,
        name: 'promotion-details-page',
        isWhiteBg: true,
        isRender: true,
    },
    {
        path: '/jusanji',
        component: JusanJIPage,
        isRender: true,
        name: 'jusanji',
        layout: Layouts.EmptyLayout,
        scrollToTop: 'instant',
        hasNav: true,
    },
    {
        path: '/skillbox',
        component: SkillboxPage,
        isRender: true,
        name: 'skillbox',
        layout: Layouts.EmptyLayout,
        scrollToTop: 'instant',
        hasNav: true,
    },
    {
        path: '/partner',
        component: Partner,
        name: 'info-page',
    },
    {
        path: '/partner-welcome',
        component: PartnerWelcome,
        name: 'info-page-welcome',
        layout: Layouts.EmptyLayout,
        scrollToTop: 'instant',
        hasNav: true,
    },
    {
        path: '/partner-commissions',
        component: PartnerCommissions,
        name: 'info-page-commissions',
        layout: isNative() ? Layouts.DefaultLayout : Layouts.EmptyLayout,
        scrollToTop: 'instant',
        hasNav: true,
    },
    {
        path: '/checkout/delivery/:checkout_type/:checkout_id',
        component: CheckoutDeliveryV2Page,
        name: 'checkout-delivery',
        isPublic: false,
        mobileOnly: false,
        headerLayout: 'default_without_search',
    },
    {
        path: '/QR',
        component: QRPage,
        layout: Layouts.EmptyLayout,
        name: 'qr-page',
    },
    {
        path: '/reviews/:product_id/:product_type/',
        component: ProductReviewsPage,
        name: 'reviews-page',
        isWhiteBg: true,
    },
    {
        path: '/debug',
        component: DebugPage,
        isRender: true,
        name: 'debug-page',
    },
    {
        path: '/search',
        component: Algolia,
        name: 'search-page',
        fetchPageData: AlgoliaFetch,
        scrollToTop: false,
        isRender: true,
        isWhiteBg: true,
        headerLayout: 'search-header',
    },
    {
        path: '/supermarkets_search',
        component: SupermarketsSearch,
        name: 'supermarkets-search-page',
        fetchPageData: SupermarketsSearchFetch,
        headerLayout: 'search-header',
        scrollToTop: false,
        isRender: true,
        isWhiteBg: true,
    },
    {
        path: '*',
        component: NotFound,
        isRender: true,
        name: 'not-found',
    },
];
