import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { AppSelectedCity, CitiesInitialState, City } from 'modules/Cities/types';

const fetchCities: CaseReducer<CitiesInitialState, PayloadAction> = (_state, _action) => {};

const fetchCitiesSucceed: CaseReducer<CitiesInitialState, PayloadAction<{ cities: City[] }>> = (
    _state,
    _action
) => {
    _state.cities = _action?.payload?.cities;
};

const fetchCitiesFailed: CaseReducer<CitiesInitialState, PayloadAction<{}>> = (_state, _action) => {
    _state.error = _action.payload;
};

const setSelectedCity: CaseReducer<CitiesInitialState, PayloadAction<AppSelectedCity>> = (
    _state,
    _action
) => {
    const { payload } = _action;
    _state.selectedCity = payload?.id || '443';
};
const fetchCityPolygon: CaseReducer<CitiesInitialState, PayloadAction<{ cityId: number }>> = (
    _state,
    _action
) => {};

const fetchCityPolygonSucceed: CaseReducer<
    CitiesInitialState,
    PayloadAction<{ cityId: number; polygon: { lat: number; lon: number }[] }>
> = (_state, _action) => {
    const polygon = _action.payload.polygon.map((item) => [item.lat, item.lon]);
    _state.cityPolygonData = { cityId: _action.payload.cityId, polygon };
};

const fetchCityPolygonFailed: CaseReducer<CitiesInitialState, PayloadAction<{}>> = (
    _state,
    _action
) => {
    _state.error = _action.payload;
};
export default {
    fetchCities,
    fetchCitiesFailed,
    fetchCitiesSucceed,
    setSelectedCity,
    fetchCityPolygon,
    fetchCityPolygonSucceed,
    fetchCityPolygonFailed,
};
