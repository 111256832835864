import styled from 'styled-components/macro';
import { FlexBox, Input } from 'pyatka-ui';
import { css } from 'styled-components';

export const MapWrapper = styled('div')`
    overflow: hidden;
    border-radius: 15px;
    height: 78vh;
`;

export const GeoSearchWrapper = styled(FlexBox)`
    position: relative;
    align-items: center;
    justify-content: center;
    background: #f6f6f5;
    padding: 8px 12px 8px 30px;
    border-radius: 18px;
    width: 100%;
    gap: 12px;
    margin-bottom: 24px;

    input {
        border-radius: 12px;
        padding: 2px;
        // width: 100%;
        font-weight: normal;
        font-size: 16px;
    }

    input::placeholder {
        color: #898d94;
        font-size: 16px;
        font-weight: normal;
        line-height: 20px;
    }
`;

export const GeoSearchPoint = styled(FlexBox)<{ isMobile?: boolean }>`
    cursor: pointer;
    flex-direction: column;
    gap: 4px;

    &:hover,
    &:active {
        ${(props) =>
            props.isMobile &&
            css`
                .city {
                    color: #aaadb2;
                }

                .address {
                    color: #898d94;
                }
            `}
    }
`;
export const StyledGeoSearchResultItem = styled(FlexBox)<{ isMobile?: boolean }>`
    cursor: pointer;
    border-radius: 30px;

    #button {
        display: none;
    }

    #postomat-btn {
        color: #201f1f;
        background-color: #f6f6f5;
        border: none;
    }

    &:hover,
    &:active,
    &.active {
        background: ${(props) => (props.isMobile ? '#fff' : '#F6F6F5')};

        #button {
            display: block;
        }

        #postomat-btn {
            color: #fff;
            background-color: #ff4810;
        }
    }
`;
export const MapHeader = styled('div')`
    position: absolute;
    z-index: 1000;
    padding: 10px;
    display: flex;
    align-items: center;

    & > * {
        background-color: white;
        border-radius: 12px;
        padding: 12px 16px;
        line-height: 20px;
    }
`;

export const StyledInput = styled(Input)`
    border: none;

    & input:-webkit-autofill ~ label {
        top: 5px !important;
        transform: translateY(-25%) scale(0.7) !important;
    }

    & input:focus + .focused {
        top: 5px !important;
        transform: translateY(-25%) scale(0.7) !important;
    }

    label {
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 17px;
    }

    & input {
        padding: 1rem 0 0.2rem !important;
        caret-color: #ff4810;
        border-bottom: 2px solid #bebeb633;

        &:focus {
            border-bottom: 2px solid #aaadb2;
        }
    }
`;
export const AddressInput = styled(StyledInput)`
    & input {
        padding: 0 0 0.2rem !important;
    }
`;
export const ModalWrapperDesktop = styled(FlexBox)`
    max-height: calc(75vh - 103px);
`;
