import React from 'react';
import { Box, Modal, Icon, FlexBox, Text, Button, Space, usePyatka } from 'pyatka-ui';
import { useI18n } from 'hooks/useI18n';
import { YMapsCollapseProps } from './types';

export const YMapsCollapse: React.FC<YMapsCollapseProps> = (props) => {
    const { collapse, closeCollapse, selectPoint } = props;
    const {
        scales: { isMobile },
    } = usePyatka();
    const { $t } = useI18n();
    return (
        <Modal
            clickableBackground={isMobile}
            width={'100%'}
            positionMode={isMobile ? 'mobile' : 'default'}
            open={Boolean(collapse)}
            onClose={closeCollapse}
            disableBackdropClick
            padding="8px 18px 18px"
            overlayBackground="transparent"
        >
            <Box>
                <Box
                    width="40px"
                    height="5px"
                    margin="0 auto"
                    borderRadius="8px"
                    bg="block.secondary"
                />
                <Space mb={'18px'} />

                <FlexBox flexDirection="column" gap="8px" mb={30}>
                    <FlexBox gap="16px" alignItems="center">
                        <FlexBox
                            height="32px"
                            minWidth="32px"
                            alignItems="center"
                            justifyContent="center"
                        >
                            <Icon
                                width="24px"
                                height="24px"
                                icon={collapse?.mapParams?.collapseIcon || ''}
                            />
                        </FlexBox>
                        <FlexBox width={'100%'} flexDirection="column">
                            <Text
                                lineClamp={1}
                                fontWeight="bold"
                                style={{ wordWrap: 'break-word' }}
                                fontSize={{ default: 3 }}
                                mb={'2px'}
                            >
                                {collapse?.title || ''}
                            </Text>
                            <Text
                                color="text.secondary"
                                fontSize={{ default: 2 }}
                                style={{ wordWrap: 'break-word' }}
                            >
                                {collapse?.type === 'pickup' && $t('shop')}{' '}
                                {collapse?.subtitle || ''}
                            </Text>
                        </FlexBox>
                    </FlexBox>

                    <FlexBox gap="16px" alignItems={'center'}>
                        <FlexBox
                            minWidth="32px"
                            height="32px"
                            alignItems="center"
                            justifyContent="center"
                        >
                            <Box
                                width="10px"
                                height="10px"
                                bg="status.success"
                                borderRadius={'50%'}
                            />
                        </FlexBox>

                        <FlexBox alignItems="center">
                            {/*<Text fontWeight="500" color="text.secondary">*/}
                            {/*    Ежедневно:*/}
                            {/*</Text>*/}
                            <Text fontWeight="500" fontSize={{ default: 2 }}>
                                {collapse?.time || ''}{' '}
                            </Text>
                        </FlexBox>
                    </FlexBox>
                </FlexBox>

                <Button
                    width="100%"
                    variant="brand"
                    padding="17px 0"
                    styleType="contained"
                    borderRadius="22px"
                    onClick={() => selectPoint(collapse)}
                >
                    Выбрать
                </Button>
            </Box>
        </Modal>
    );
};
