import { createSelector } from 'reselect';
import { AppRootState } from 'store/types';
import { SingleArticle, StaticPage } from 'modules/Articles/types';
import { contacts } from './const';

const getReducer = (state: AppRootState) => state.ArticlesReducer;
export const getArticlesState = createSelector([getReducer], (state) => state);

export const getSinglePageStatic = createSelector([getArticlesState], (state): SingleArticle => {
    // @ts-ignore
    const { singleStaticPage = {} } = state;
    return {
        title: singleStaticPage.title,
        id: singleStaticPage.id,
        description: singleStaticPage.description,
    };
});

export const getStaticPages = createSelector([getArticlesState], (state): StaticPage[] => {
    // @ts-ignore
    // const { singleStaticPage = {} } = state;
    const { staticPagesContent = [] } = state;
    // @ts-ignore
    const tag = Object.values(staticPagesContent)?.[0]?.slug;
    const staticPagesArr = staticPagesContent[tag]?.static_pages;

    const formatStaticPages = (staticPagesArr || []).map((item: StaticPage) => {
        return {
            id: item?.id,
            title: item?.title,
            link: { name: 'articles-page', params: { slug: item?.slug } },
        };
    });

    return [...contacts, ...formatStaticPages];
});
