import React, { useEffect, useMemo, useState } from 'react';
import { Modal } from 'pyatka-ui';
import { useSelector } from 'react-redux';
import ClientOrderDetailCardLayout from 'components/ClientOrderDetailCard/ClientOrderDetailCard.Layout';
import { ClientOrderDetailCardContainerProps } from 'components/ClientOrderDetailCard/types';
import { PostomatModal } from 'pages/ClientOrderDetailPage/components/PostomatModal';
import { getReasonStatuses, repeatOrderRequest } from 'pages/ClientOrderDetailPage/services';
import { OrderRepeatProductCart } from 'pages/ClientOrderDetailPage/types';
import { AppToaster } from 'plugins/app-toaster';
import { Dialog } from 'plugins/dialog';
import { CancelReasonsModal } from 'pages/ClientOrderDetailPage/components/CancelReasonsModal';
import { useI18n } from 'hooks/useI18n';
import { useCartService } from 'contexts/useCartService';
import { useLoader } from 'contexts/useLoader';
import { Loader } from 'plugins/loader';
import { isCanceled } from 'modules/OrdersService/OrdersService.selectors';
import { useAppRouter } from 'router/context';
import { isNative } from 'utils/platform';
import { nativeCall } from 'hybrid';

export const ClientOrderDetailCardContainer = (props: ClientOrderDetailCardContainerProps) => {
    const { order, OrdersDetailInit } = props;
    const { $t } = useI18n();
    const { appRedirect } = useAppRouter();

    const [isRepeatOrderLoading, setIsRepeatOrderLoading] = React.useState(false);
    const [reasons, setReasons] = React.useState<any>([]);

    const { cartSnapshotEdit } = useCartService();
    const { isLoading } = useLoader();
    const isFetching = isLoading(Loader.orderDetail.indicator);
    const isCanceledReason = useSelector(isCanceled);
    useEffect(() => {
        (async () => {
            try {
                if (order?.order_id) {
                    const response = await getReasonStatuses();
                    setReasons(response?.data);
                }
            } catch (e) {
                console.error(e);
            }
        })();
    }, [order?.order_id]);

    const repeatOrder = async () => {
        try {
            setIsRepeatOrderLoading(true);
            const reorder = await repeatOrderRequest({ order_id: order.order_id });
            const products = reorder.data.data.products;
            const products_list = Object.entries(products);
            const prepared_products = products_list.map(([_key, value]) => {
                const product = value as OrderRepeatProductCart;

                return {
                    amount: product?.amount,
                    product_id: product?.product?.master_product_id,
                    offer_id: product?.product?.product_id,
                };
            });

            if (prepared_products.length > 0) {
                AppToaster.success({ body: 'order_added_to_cart' });
                setIsRepeatOrderLoading(false);
                cartSnapshotEdit(prepared_products);
            } else {
                setIsRepeatOrderLoading(false);
                AppToaster.warning({ body: 'products_out_of_stock' });
            }
        } catch (e) {
            setIsRepeatOrderLoading(false);
            AppToaster.warning({ body: 'error_has_occurred_repeat_again' });
        }
    };
    const onCancelClick = () => {
        Dialog.openDialog({
            key: '',
            title: $t('cancelling_order_reasons'),
            body: CancelReasonsModal,
            props: { reasons, order_id: order.order_id },
        });
    };
    const onCanBePainClick = () => {
        appRedirect({
            name: 'checkout-delivery',
            params: {
                checkout_type: 'order',
                checkout_id: order.order_id,
            },
        });
    };
    const showQRBlock = useMemo(
        () =>
            Boolean(order.status) &&
            order.status === 'M' &&
            order?.delivery_type_name === 'postomat',
        [order?.delivery_type_name, order.status]
    );
    /* ------------------------------------------ POSTOMAT MODAL ----------------------------------- */
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalView, setModalView] = useState<'qr' | 'code'>('qr');
    const onOpenPostomatModal = (view: 'code' | 'qr') => {
        switch (view) {
            case 'code':
                setIsModalOpen(true);
                setModalView(view);
                break;
            case 'qr':
                if (isNative()) {
                    const res = nativeCall({
                        type: 'app_qr',
                    });
                    if (res?.status === 'success') {
                        OrdersDetailInit({ order_id: order.order_id });
                    }
                }
                break;
        }
    };

    const closePostomatModal = () => {
        setIsModalOpen(false);
    };

    const modalProps = {
        modalView,
        closePostomatModal,
        order_id: props.order?.order_id?.toString() || '',
        OrdersDetailInit,
    };
    // ----------------------------------------------
    const hasShipping = useMemo(
        () => props.order?.shipping?.has_shipping,
        [props.order?.shipping?.has_shipping]
    );
    const hasToShowPriceBlock = useMemo(
        () =>
            Boolean(Number(props.order?.shipping?.shipping_cost)) ||
            Boolean(Number(props.order?.discount)),
        [props.order?.discount, props.order?.shipping?.shipping_cost]
    );
    const priceBlockObj = useMemo(
        () => ({
            товары: props.order?.subtotal && props.order?.subtotal,
            доставка: props.order?.shipping?.shipping_cost && props.order?.shipping?.shipping_cost,
            скидка: props.order?.discount && props.order?.discount,
        }),
        [props.order]
    );
    const filteredPriceBlock = useMemo(
        () => Object.entries(priceBlockObj).filter((e) => Boolean(Number(e[1]))),
        [priceBlockObj]
    );

    const layoutProps = {
        hasShipping,
        hasToShowPriceBlock,
        filteredPriceBlock,
        isRepeatOrderLoading,
        repeatOrder,
        onCancelClick,
        isCanceledReason,
        onCanBePainClick,
        showQRBlock,
        onOpenPostomatModal,
        ...props,
    };
    return (
        <>
            {!isFetching && <ClientOrderDetailCardLayout {...layoutProps} />}
            <Modal
                open={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                fullScreen={true}
                showCloseButton={true}
            >
                <PostomatModal {...modalProps} />
            </Modal>
        </>
    );
};
