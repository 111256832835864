import { connect, ConnectedProps } from 'react-redux';
import { AppRootState } from 'store/types';
import {
    getProfileServiceData,
    getProfileUserData,
} from 'modules/UserProfileService/UserProfileService.selectors';
import { isUserAuth } from 'modules/Authorization/Authorization.selectors';
import { AuthorizationLogout } from 'modules/Authorization/v2/Authorization.actions';

const mapState = (state: AppRootState) => ({
    isLoggedIn: isUserAuth(state),
    userData: getProfileUserData(state),
    profileServiceData: getProfileServiceData(state),
});
const mapDispatch = {
    AuthorizationLogout,
};

export const UserNavigationConnector = connect(mapState, mapDispatch);
export type UserNavigationConnectProps = ConnectedProps<typeof UserNavigationConnector>;
