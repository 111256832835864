import React, { useState } from 'react';
import { Container, Display, FlexBox, Icon, Text } from 'pyatka-ui';
import { useDispatch } from 'react-redux';
import { AppLink } from 'components';
import { nativeCall } from 'hybrid/index';
import { NewTabNavigationLayoutProps } from 'components/NewTabNavigation/types';
import { Dialog } from 'plugins/dialog';
import { useAppRouter } from 'router/context';
import { authFromMobile } from 'modules/Authorization/Authorization.actions';
import { isNative, isQrTrigger, triggerQrModal } from 'utils/platform';
import { useI18n } from 'hooks/useI18n';
import { AppMetricEvents } from 'AppMetric';
import { TabLink } from 'modules/App/types';
import AuthorizationModal from 'components/AuthorizationModal';
import { AuthorizationInit } from 'modules/Authorization/v2/Authorization.actions';

enum TabSlug {
    AUTH = 'auth-tab',
    MENU = 'menu-tab',
    CATALOG = 'catalog',
    PROFILE = 'profile',
    CART = 'cart',
    HOME = 'home',
}

const labelProps = {
    p: '2px',
    right: '-8px',
    top: '-4px',
    height: '18px',
    minWidth: '18px',
    borderRadius: '999rem',
    alignItems: 'center',
    justifyContent: 'center',
};

const IconWrapper: React.FC<{
    tab: TabLink;
    isActive: boolean;
}> = ({ tab, isActive }) => {
    const countExist: boolean = tab.labelCount !== undefined && tab.labelCount! > 0;

    const fillIcon = React.useMemo(() => {
        const color = isActive ? 'block.brand' : 'block.placeholder';

        if (tab.slug === TabSlug.CATALOG) {
            return [color, color, 'white'];
        }

        if (tab.slug === TabSlug.HOME) {
            return [color, 'white'];
        }

        return color;
    }, [isActive, tab.slug]);

    return (
        <FlexBox
            position="relative"
            alignItems="center"
            justifyContent="center"
            transition="1200ms ease"
        >
            {countExist && (
                <FlexBox
                    bg="block.brand"
                    borderWidth={2}
                    borderStyle="solid"
                    position="absolute"
                    style={{ borderColor: '#FFFFFF' }}
                    {...labelProps}
                >
                    <Text color="text.inverse" fontSize="10px" fontWeight="bold">
                        {tab.labelCount}
                    </Text>
                </FlexBox>
            )}
            <Icon icon={tab.icon} fill={fillIcon} width="30px" height="30px" />
        </FlexBox>
    );
};

const Tab: React.FC<any> = (props: any) => {
    const { $t } = useI18n();
    const { tab, handleAuthClick, handleMenuClick, appearance, location } = props;
    // TODO ADD CHECK IF TAB IS ACTIVE
    const isActive = appearance === 'tab' && location?.pathname === tab.url;

    const onTabClick = () => {
        if (tab.slug === TabSlug.AUTH) {
            handleAuthClick(tab);
            return;
        }
        if (tab.slug === TabSlug.MENU) {
            handleMenuClick();
            return;
        }
        if (tab.slug === TabSlug.CATALOG) {
            AppMetricEvents.jmart_catalog_footer_click();
            return;
        }
    };

    return (
        <AppLink
            to={tab.linkItem}
            preventOnClick={tab.slug === TabSlug.AUTH || tab.slug === TabSlug.MENU}
        >
            <FlexBox
                px={1}
                transition="0.2s"
                borderRadius="6px"
                alignItems="center"
                onClick={onTabClick}
                flexDirection="column"
                style={{ cursor: 'pointer' }}
            >
                <IconWrapper tab={tab} isActive={isActive} />
                <Text
                    fontSize="10px"
                    lineHeight="12px"
                    fontWeight={isActive ? '600' : '500'}
                    color={isActive ? 'text.brand' : 'text.secondary'}
                >
                    {$t(tab.title_i18n.replace('-', '_')).toCapitalizeFirstLetter()}
                </Text>
            </FlexBox>
        </AppLink>
    );
};

export const NewTabNavigationLayout: React.FC<NewTabNavigationLayoutProps> = (props) => {
    const { tabs, appearance, size } = props;
    const dispatch = useDispatch();

    const { location, appRedirect }: any = useAppRouter();

    const isTab = appearance === 'tab' || appearance === 'all';
    const handleAuthClick = (tab: TabLink) => {
        if (!isNative()) {
            Dialog.openDialog({
                body: AuthorizationModal,
                props: {
                    onClose: () => {
                        Dialog.closeDialog();
                    },
                    onAuthDone: () => {
                        appRedirect(tab.linkItem);
                    },
                },
                isOwnModal: true,
            });
            // && !__IS_PROD__
            // Dialog.openDialog({
            //     key: 'AuthModal',
            //     title: 'Авторизация по:',
            //     body: 'AuthModal',
            // });
            return;
        }
        if (isQrTrigger) {
            triggerQrModal(location?.pathname + window?.location?.search || '/');
            return;
        }
        const res: any = nativeCall({
            type: 'auth',
            payload: {},
        });
        if (res?.response?.token) {
            dispatch(AuthorizationInit({ params: { token: res.response?.token } }));
            appRedirect(tab.linkItem);
            // dispatch(authFromMobile(res.response?.token));
            // console.log()
            // setTimeout(() => {
            //     // console.log('locasd', location.pathname);
            //     !location?.pathname.startsWith('/cart') && appRedirect({ name: 'profile-page' });
            // }, 100);
        }
        return;
    };
    const handleMenuClick = () => {
        if (!isNative()) {
            triggerQrModal(window.location.pathname + window.location.search || '/');
            return;
        }

        nativeCall({
            type: 'menu',
        });
        return;
    };

    const tabProps = {
        handleAuthClick,
        handleMenuClick,
        appearance,
        size,
        isTab,
        location,
    };

    return (
        <Container>
            <FlexBox alignItems="center" justifyContent="space-between" gap="0px">
                <Display md={true} xxl={false}>
                    {tabs.map((tab, idx) => (
                        <Tab {...tabProps} tab={tab} key={idx} />
                    ))}
                </Display>
            </FlexBox>
        </Container>
    );
};
