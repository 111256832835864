import React from 'react';
import { FlexBox, Icon } from 'pyatka-ui';

interface MainGeoProps {
    metric: () => void;
    onClickGeo: () => void;
}

const SIZE = '40px';

export const MainGeo: React.FC<MainGeoProps> = ({ metric, onClickGeo }) => {
    const onClick = () => {
        metric();
        onClickGeo();
    };

    return (
        <FlexBox
            width={SIZE}
            height={SIZE}
            borderRadius="99px"
            bg="block.secondary"
            onClick={onClick}
        >
            <Icon icon="geo" width={SIZE} height={SIZE} fill={['transparent']} />
        </FlexBox>
    );
};
