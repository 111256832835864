import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import {
    GetGeoCodeServiceBody,
    ProfileAddressAddBody,
    ProfileAddressEditBody,
    UserDataModel,
    UserProfileAddressModel,
    UserProfileServiceInitialState,
} from 'modules/UserProfileService/types';
import { FormFieldsInitialData } from 'modules/UserProfileService/ProfileServiceConfigs';

// ----------------------------- Actions Geo Coder

const fetchGeoCodeService: CaseReducer<
    UserProfileServiceInitialState,
    PayloadAction<GetGeoCodeServiceBody>
> = (_state, _action) => {
    const { payload } = _action;
    const { geocode } = payload;
    if (geocode === '') {
        _state.geoCodeSearchResults = [];
    }
};

const fetchGeoCodeServiceSucceed: CaseReducer<UserProfileServiceInitialState, PayloadAction<{}>> = (
    _state,
    _action
) => {
    const { payload } = _action;
    _state.geoCodeSearchResults = payload;
};

// ----------------------------- Actions Address|User

const saveGWUserProfileData: CaseReducer<
    UserProfileServiceInitialState,
    PayloadAction<{ user: UserDataModel; profiles: UserProfileAddressModel[] }>
> = (_state, _action) => {
    _state.loading = false;
    _state.user = _action?.payload?.user;
    _state.profiles = _action?.payload?.profiles;
    _state.formFields = FormFieldsInitialData;
};

// ----------------------------- Triggers

const getGWProfileData: CaseReducer<UserProfileServiceInitialState, PayloadAction> = (
    _state,
    _action
) => {
    _state.loading = true;
};

const deleteGWProfileAddress: CaseReducer<UserProfileServiceInitialState, PayloadAction<any>> = (
    _state,
    _action
) => {
    _state.loading = true;
};

const addGWProfileAddress: CaseReducer<
    UserProfileServiceInitialState,
    PayloadAction<ProfileAddressAddBody>
> = (_state, _action) => {
    _state.loading = true;
};

const updateGWProfileAddress: CaseReducer<
    UserProfileServiceInitialState,
    PayloadAction<ProfileAddressEditBody>
> = (_state, _action) => {
    _state.loading = true;
};

export default {
    fetchGeoCodeService,
    fetchGeoCodeServiceSucceed,
    //
    getGWProfileData,
    deleteGWProfileAddress,
    addGWProfileAddress,
    updateGWProfileAddress,
    //
    saveGWUserProfileData,
};
