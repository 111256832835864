import { ApiGwRequest } from 'services';
import { StaticPage } from 'modules/Articles/types';

export const fetchStaticPages = (payload?: StaticPage) => {
    const hasSlug = payload?.slug ? `/${payload.slug}` : '';
    return ApiGwRequest({
        indicator: 'ARTICLES',
        url: `main-page/v1/static-pages${hasSlug}`,
        method: 'get',
    });
};
