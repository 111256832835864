import React from 'react';
import { Box } from 'pyatka-ui';
import { SearchBar } from 'components';
import { GoBackBtn, HeaderWrapper } from '../components';

const SearchHeader: React.FC = () => (
    <HeaderWrapper>
        <GoBackBtn />
        <Box width="100%">
            <SearchBar dumb_layout secondary />
        </Box>
    </HeaderWrapper>
);

export default React.memo(SearchHeader);
