export default (price: any) => {
    if (typeof price === 'number') {
        return price.toLocaleString('ru-RU', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        });
    } else if (typeof price === 'string') {
        return Number(price.replace('₸', '').replace(/\s+/g, '').replace(',', '.')).toLocaleString(
            'ru-RU',
            {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
            }
        );
    }
};
