import React from 'react';
import { Box, Button, FlexBox, Text } from 'pyatka-ui';
import { BoxProps } from 'pyatka-ui/dist/components-shared/Box/types';
import { CartProductCounter, WithDiscount } from 'components';
import { signs } from 'utils/symbols';
import { useI18n } from 'hooks/useI18n';
import { ProductDetailInitialState } from 'modules/ProductDetail/types';
import { isNative, isQrTrigger, triggerQrModal } from 'utils/platform';
import { useCartService } from 'contexts/useCartService';
import { AlgoliaEvents } from 'metrics';
import { useAppRouter } from 'router/context';
import { AppMetricEvents } from 'AppMetric';
import { AppCartItemState } from 'contexts/useCartService/types';

interface AddToCartFooterProps extends BoxProps {
    discountPrice?: string;
    price?: string | number;
    sendMetric: () => void;
    data: ProductDetailInitialState['productDetail'];
}

const AddToCartFooter: React.FC<AddToCartFooterProps> = ({
    discountPrice = '',
    price = 0,
    sendMetric,
    data,
    ...rest
}) => {
    const showDiscount = Boolean(discountPrice);
    const { queryParams } = useAppRouter();
    const { cartEdit, cartItemsByProduct } = useCartService();
    const { $t } = useI18n();

    // First offer in cart
    const inCartOffer: AppCartItemState | undefined = React.useMemo(() => {
        const offers = cartItemsByProduct(String(data?.productId)) || [];
        if (offers?.length) {
            return offers[0];
        }
    }, [cartItemsByProduct, data?.productId]);

    // If exists in cart
    const isInCart: boolean = React.useMemo(
        () => Boolean(inCartOffer && inCartOffer.amount > 0 && inCartOffer.offer_id),
        [inCartOffer]
    );

    const handleClick = () => {
        if (!isNative()) {
            AppMetricEvents.jmart_web_cart_click({
                item: {
                    product_id: data?.productId,
                    offer_id: data?.offer_id,
                    productName: data?.productName,
                    type: data?.type,
                    price,
                },
            });

            if (data?.query_id || queryParams?.query_id) {
                AlgoliaEvents.sendEvent({
                    eventType: 'conversion',
                    eventName: 'Product Purchased',
                    objectIDs: [String(data?.productId)],
                    queryID: String(data?.query_id || queryParams?.query_id),
                });
            }
        }

        if (!data?.offer_id) {
            return;
        }

        if (isQrTrigger) {
            triggerQrModal(data?.url);
            return;
        }

        if (isInCart) {
            return;
        }

        if (sendMetric) {
            sendMetric();
        }

        cartEdit({ product_id: data?.productId, offer_id: data?.offer_id, amount: 1 });

        AlgoliaEvents.sendEvent({
            eventType: 'conversion',
            eventName: 'Product Purchased',
            objectIDs: [String(data?.productId || '')],
            queryID: data?.query_id,
        });
    };

    return (
        <Box
            borderTopWidth={2}
            borderTopStyle="solid"
            borderTopColor="block.secondary"
            padding="12px 18px"
            position="fixed"
            bg="block.main"
            zIndex={100}
            bottom={0}
            right={0}
            left={0}
            {...rest}
        >
            <FlexBox alignItems="center" justifyContent="space-between">
                <FlexBox alignItems="center" gap="8px">
                    <Text
                        fontSize="26px"
                        fontWeight={700}
                        lineHeight="33.28px"
                        color={showDiscount ? 'text.brand' : 'text.main'}
                    >
                        {discountPrice || price} {signs.tg}
                    </Text>
                    <WithDiscount show={showDiscount}>
                        <Text
                            fontSize="16px"
                            fontWeight="700"
                            color={showDiscount ? 'text.placeholder' : 'transparent'}
                        >
                            {price} {signs.tg}
                        </Text>
                    </WithDiscount>
                </FlexBox>

                <Box minWidth="133px">
                    {isInCart ? (
                        <CartProductCounter
                            fullWidth
                            layout="new"
                            product_type={data?.type}
                            offer_id={String(inCartOffer?.offer_id)}
                        />
                    ) : (
                        <Button
                            width="100%"
                            height="45px"
                            variant="brand"
                            padding="8px 25px"
                            borderRadius="16px"
                            onClick={handleClick}
                            styleType="contained"
                        >
                            <Text fontSize="16px" fontWeight={600} lineHeight="20.48px">
                                {$t('to_cart').toCapitalizeFirstLetter()}
                            </Text>
                        </Button>
                    )}
                </Box>
            </FlexBox>
        </Box>
    );
};

export default AddToCartFooter;
