import React from 'react';
import { Dialog } from 'plugins/dialog';
import { ModalSortLayout } from './ModalSort.Layout';
import { ModalSortContainerProps } from './types';

export const ModalSortContainer: React.FC<ModalSortContainerProps> = ({
    activeSortValue,
    handleSortChange,
}) => {
    const [active, setActive] = React.useState(activeSortValue);

    const applySort = React.useCallback(() => {
        handleSortChange(active);
        Dialog.closeDialog();
    }, [active, handleSortChange]);

    return (
        <ModalSortLayout activeSortValue={active} applySort={applySort} handleClick={setActive} />
    );
};
