import React from 'react';
import { FlexBox, Icon, Text } from 'pyatka-ui';
import { BoxProps } from 'pyatka-ui/dist/components-shared/Box/types';
import { ProductLabel } from 'components';
import { useI18n } from 'hooks/useI18n';
import { IProduct } from '../types';

const styles = {
    fontWeight: 700,
    lineHeight: { default: '18.2px' },
    fontSize: { default: '14px', md: '20px' },
    padding: '4px 10px',
};

interface ProductLabelsProps extends Pick<IProduct, 'labels'> {
    reviews?: any;
    isDesktop?: boolean;
    clickRating?: () => void;
}

const ProductLabels: React.FC<ProductLabelsProps> = ({
    labels,
    reviews,
    clickRating,
    isDesktop = false,
}) => {
    const { $t } = useI18n();
    const { installmentLabel, bonusLabel } = labels;

    if (isDesktop) {
        return (
            <>
                {bonusLabel && (
                    <Wrapper label="bonus_up_to">
                        <ProductLabel
                            {...styles}
                            icon="bonus2"
                            variant="bonus"
                            iconSize={{
                                big: '14px',
                                small: '12px',
                            }}
                        >
                            {bonusLabel.text?.replace('Б', '')}
                        </ProductLabel>
                    </Wrapper>
                )}
                {installmentLabel && (
                    <Wrapper label="installment_up_to">
                        <ProductLabel {...styles} variant="credit">
                            {(installmentLabel.text || '').replaceAll('-', '•').replace('до ', '')}
                        </ProductLabel>
                    </Wrapper>
                )}

                <Wrapper
                    cursor="pointer"
                    onClick={clickRating}
                    label={`${reviews?.totalRecords || 0} ${$t('reviews_card')}`}
                >
                    <FlexBox alignItems="center" gap="5px">
                        <Text mt="4px" fontSize="22px" lineHeight="28.16px" fontWeight={700}>
                            {reviews?.avgRate ? Number(reviews.avgRate).toFixed(1) : 0}
                        </Text>
                        <Icon icon="star-filled" width="22px" height="22px" />
                    </FlexBox>
                </Wrapper>
            </>
        );
    }

    return (
        <FlexBox alignItems="center" gap="6px">
            {installmentLabel && (
                <ProductLabel {...styles} variant="credit">
                    {installmentLabel.text?.replaceAll('-', '•')}
                </ProductLabel>
            )}
            {bonusLabel && (
                <ProductLabel
                    {...styles}
                    icon="bonus2"
                    variant="bonus"
                    iconSize={{
                        big: '14px',
                        small: '12px',
                    }}
                >
                    {(bonusLabel.text || '').replace('Б', '')}
                </ProductLabel>
            )}
        </FlexBox>
    );
};

interface WrapperProps extends Omit<BoxProps, 'color'> {
    children: React.ReactNode;
    label: string;
    onClick?: () => void;
}

const Wrapper: React.FC<WrapperProps> = ({ children, label, onClick, ...rest }) => {
    const { $t } = useI18n();

    return (
        <FlexBox
            flexDirection="column"
            bg="block.secondary"
            alignItems="center"
            borderRadius="20px"
            onClick={onClick}
            width="100%"
            gap="6px"
            p="12px"
            {...rest}
        >
            {children}
            <Text color="text.secondary" fontSize="16px" lineHeight="20.48px" fontWeight={500}>
                {$t(label)}
            </Text>
        </FlexBox>
    );
};

export default React.memo(ProductLabels);
