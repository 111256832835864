import { connect, ConnectedProps } from 'react-redux';
import { AppRootState } from 'store/types';
import { getI18nState } from 'modules/App/App.selectors';
import { setI18nActiveLanguage } from 'modules/App/App.actions';

const mapState = (state: AppRootState) => ({
    translations: getI18nState(state).translations,
    activeLanguage: getI18nState(state).activeLanguage,
});

const mapDispatch = {
    setI18nActiveLanguage,
};
export const LanguageSelectorConnector = connect(mapState, mapDispatch);
export type LanguageSelectorConnectProps = ConnectedProps<typeof LanguageSelectorConnector>;
