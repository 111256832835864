import { SearchServiceInitialState } from 'modules/SearchService/types';

export const initialData = {
    data: {
        products: [],
        filters: [],
        categories: [],
        page: 1,
        items_per_page: 12,
        total_count: 0,
        total_pages: 0,
        algolia_index: '',
        product_ids: [],
        algolia_filters: '',
        algolia_facet_filters: [],
        url: '',
        redirect_url: undefined,
    },
    inputQuery: '',
    activeFilters: {},
    filtersObject: {},
    category_id: null,
    mobile_search_overlay: false,
    suggestions: [],
    filters_order: [],
    firstRender: true,
};

const state: SearchServiceInitialState = initialData;

export default state;
