import React from 'react';
import { Box, Carousel, FlexBox, Icon, Image, Modal, Space, Text, usePyatka } from 'pyatka-ui';
import { useDispatch, useSelector } from 'react-redux';
import {
    getIsDetailModalOpen,
    getProductDetailModal,
} from 'modules/ProductDetail/ProductDetail.selectors';
import { setProductDetailModalOpen } from 'modules/ProductDetail/ProductDetail.actions';
import { ProductLabel, WishListToggler } from 'components';
import { GtmEvents } from 'metrics';
import { AppMetricEvents } from 'AppMetric';
import { AddToCartFooter, OthersBuyTogether, SimilarProducts } from './components';

const ProductDetailModal = () => {
    const open = useSelector(getIsDetailModalOpen);
    const data = useSelector(getProductDetailModal);
    const dispatch = useDispatch();
    const { scales } = usePyatka();

    const handleClose = () => {
        dispatch(setProductDetailModalOpen({ isOpen: false }));
    };

    React.useEffect(() => {
        if (open) {
            GtmEvents.viewItem(data);
            AppMetricEvents.jmart_product_view(data as any);
        }
    }, [open]);

    const addToCartMetric = () => {
        if (data !== null) {
            const category1 =
                data?.category_ids && data?.category_ids?.length > 0 ? data?.category_ids[0] : null;
            AppMetricEvents.jmart_add_to_cart2({
                source: 'product',
                data: {
                    ...data,
                    product_name: data?.productName,
                    category1,
                },
            });
        }
    };

    return (
        <Modal
            open={open && data !== null}
            swipeable
            padding="8px 0"
            maxHeight="85vh"
            onClose={handleClose}
            customHeader={<ModalHeader />}
            width={scales?.isMobile ? '100%' : '450px'}
            positionMode={scales?.isMobile ? 'mobile' : 'default'}
            borderRadius={scales?.isMobile ? '24px 24px 0 0' : '24px'}
            // @ts-ignore
            style={{ rowGap: 10 }}
        >
            <Box padding="0px 18px">
                <Box width="80%" m="0 auto">
                    <Carousel
                        showDots
                        carouselType="magnetic"
                        activeDotsColor="#AAADB2"
                        defaultDotsColor="#bebeb666"
                        slidesToShowGroup={{ mobile: 1, tablet: 1, desktop: 1 }}
                    >
                        {(data?.image_urls || []).map((image: string) => (
                            <Image key={image} src={image} effect="fadeIn" />
                        ))}
                    </Carousel>
                </Box>

                <FlexBox alignItems="center" justifyContent="space-between" mb="12px" mt="24px">
                    <FlexBox gap="6px" alignItems="center">
                        {Boolean(data?.label?.bonusLabel) && (
                            <ProductLabel
                                height="30px"
                                variant="bonus"
                                fontSize="16px"
                                padding="5px 10px"
                                iconSize={{
                                    small: '26px',
                                }}
                            >
                                {data?.label?.bonusLabel?.text?.replace('Б', '')}
                            </ProductLabel>
                        )}
                        <FlexBox
                            py="5px"
                            px="12px"
                            gap="4px"
                            height="30px"
                            alignItems="center"
                            bg="block.secondary"
                            borderRadius="999rem"
                        >
                            <Icon icon="star-filled" width="16px" height="16px" />
                            <Text fontSize="16px" fontWeight={500} lineHeight="20.48px">
                                {data?.avgRate ? Number(data?.avgRate).toFixed(1) : 0}
                            </Text>
                            {!!data?.totalRecords && (
                                <Text
                                    fontSize="16px"
                                    fontWeight={500}
                                    lineHeight="16px"
                                    color="text.secondary"
                                >
                                    ({data?.totalRecords})
                                </Text>
                            )}
                        </FlexBox>
                    </FlexBox>
                    <WishListToggler
                        product_id={Number(data?.productId) || Number(data?.offer_id)}
                        price={data?.discountPrice_formatted || data?.price_formatted}
                        productName={data?.productName}
                        link={data?.url}
                        width="25px"
                        height="22px"
                    />
                </FlexBox>

                <Text fontSize="22px" fontWeight="700" lineHeight="28.16px">
                    {data?.productName}
                </Text>
            </Box>
            <Divider />
            {data?.productId ? (
                <FlexBox padding="0px 18px" mt="-22px" flexDirection="column">
                    <OthersBuyTogether
                        product_id={String(data?.productId)}
                        product_name={data?.productName}
                        boxProps={{ mb: '0px' }}
                    />
                    <SimilarProducts
                        count={3}
                        minWidth={106}
                        appearance="slide"
                        cardLayout="small"
                        product_id={String(data?.productId)}
                        product_name={data?.productName}
                        title="similar_goods"
                    />
                </FlexBox>
            ) : null}
            <Space height="64px" />
            <AddToCartFooter
                discountPrice={data?.discountPrice_formatted}
                price={data?.price_formatted}
                sendMetric={addToCartMetric}
                data={data}
            />
        </Modal>
    );
};

const ModalHeader = () => (
    <FlexBox justifyContent="center">
        <Box bg="block.disabled" width="42px" height="5px" borderRadius="999rem" />
    </FlexBox>
);

const Divider = () => (
    <FlexBox gap="6px" bg="block.secondary" flexDirection="column" justifyContent="space-between">
        <Box bg="block.main" height="24px" borderRadius="0 0 24px 24px" />
        <Box height="24px" />
    </FlexBox>
);

export default ProductDetailModal;
