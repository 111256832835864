import { all, call, takeLatest } from 'redux-saga/effects';
import { sendMail } from 'modules/Mail/Mail.actions';
import { sendMailService } from 'modules/Mail/Mail.services';
import { AppToaster } from 'plugins/app-toaster';
import { Loader } from 'plugins/loader';

function* handler(_action: any) {
    Loader.mail.on({ global: false });
    try {
        yield call(sendMailService, _action.payload);
        AppToaster.success({ body: 'request_sent_await_sms' });
    } catch (e) {
        AppToaster.error({ body: e?.data?.data?.message || 'error_message' });
    }
    Loader.mail.off();
}

export default function* () {
    yield all([takeLatest(sendMail, handler)]);
}
