import styled, { css } from 'styled-components';
import { space, SpaceProps } from 'styled-system';
import { Box } from 'pyatka-ui';

const ProductAmountStyle = css`
    border: none;
    line-height: 12px;
    vertical-align: middle;
    font-size: 12px;
`;

export const CartProductAmountLabel = styled(Box)`
    ${ProductAmountStyle};
    padding: 7px 12px;
`;

export const ProductSectionWrapper = styled('div')<{ isLast?: boolean } & SpaceProps>`
    ${space};
    display: flex;
    border-bottom: 1.5px solid;
    border-color: ${(props) => (props?.isLast ? 'transparent' : '#f6f6f5')};
    padding-bottom: ${(props) => (props?.isLast ? '18px' : '12px')};
    padding-top: 12px;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    //align-items: center;
`;

export const ProductSectionInfoWrapper = styled('div')<{ isActual?: boolean }>`
    display: flex;
    flex-direction: row;
    align-items: center;
    filter: ${(props) => (props?.isActual ? 'opacity(100%)' : 'opacity(25%)')};
    flex: 1;
`;
