import React from 'react';
import { SwitchToggleLayoutProps } from './types';
import styles from './SwitchToggle.module.scss';

export const SwitchToggleLayout: React.FC<SwitchToggleLayoutProps> = (props) => {
    const [isToggled, setIsToggled] = React.useState(props.initialValue);
    const onToggle = () => setIsToggled(!isToggled);
    const onChange = (_e: any) => {
        props?.onClick && props?.onClick(!isToggled);
    };
    return (
        <React.Fragment>
            <label className={styles.toggleSwitch}>
                <input type="checkbox" checked={isToggled} onChange={onToggle} onClick={onChange} />
                <span className={styles.switch} />
            </label>
        </React.Fragment>
    );
};
