import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { validates } from 'hooks/useForm/helper';
import { FormDataFields, UseFormConfigs, Validation } from 'hooks/useForm/types';
import { useI18n } from 'hooks/useI18n';

dayjs.extend(customParseFormat);

export default (initialData: FormDataFields, config: UseFormConfigs) => {
    const [form, setForm] = useState<FormDataFields>(initialData);
    const [errors, setErrors] = useState<Record<string, any>>({});
    const [isValid, setIsValid] = useState(false);
    const { $t } = useI18n();
    // error state
    useEffect(() => {
        setForm(initialData);
    }, [initialData]);

    useEffect(() => {
        if (Object.keys(errors).length === 0) {
            setIsValid(true);
        }
    }, []);

    const flushErrors = () => {
        setErrors({});
    };

    const pipeRules = (configs: Validation) => (data: any) => {
        const { value } = data;
        const type = typeof value;
        if (configs.required) {
            if (
                (type === 'string' && value.replace(/\s/g, '') === '') ||
                (type === 'object' && value === null) ||
                value === undefined
            ) {
                return { error: $t('field_is_required') };
            }
        }
        if (configs.rules === undefined) {
            return value;
        }

        const rules = Array.isArray(configs.rules) ? configs.rules : [configs.rules];

        return rules.reduce((val, rule) => {
            if (val === undefined) {
                return;
            }
            if (typeof rule === 'string' && rule in validates) {
                if (validates[rule].isValid(val.value)) {
                    return val;
                }
                return { error: $t(validates[rule].msg), value: val.value };
            }
            if (rule.isValid(val)) {
                return val;
            }
            return { error: $t(rule.msg) };
        }, data);
    };

    const formSubmitHandler = (e: any) => {
        e.preventDefault();

        const fields = Object.keys(form);
        const localErrors: any = {};
        fields.map((key) => {
            const field = {
                name: key,
                value: form[key.toString()],
            };
            if (!config.validators[key.toString()]) {
                return;
            }
            const result = pipeRules(config.validators[key.toString()])(field);
            if (result.error) {
                localErrors[key.toString()] = result.error;
            }
        });

        if (form.password1 && form.password2) {
            if (form.password1 !== form.password2) {
                localErrors.password1 = $t('passwords_do_not_match');
                localErrors.password2 = $t('passwords_do_not_match');
            }
        }

        setErrors({ ...errors, ...localErrors });

        if (Object.keys(localErrors).length === 0) {
            config.submit(form);
        }
    };

    const formChangeHandler = (e: any) => {
        const result = pipeRules(config.validators[e.name])(e);
        if (result.error) {
            setErrors({ ...errors, [e.name]: result.error });
        } else {
            const errs: any = { ...errors };
            delete errs[e.name];
            setErrors({ ...errs });
        }
        if (Object.keys(errors).length === 0) {
            setIsValid(true);
        } else {
            setIsValid(false);
        }
        const { name, value } = e;
        const oldForm = { ...form };
        const oldValue = oldForm[name.toString()];
        if (value !== oldValue) {
            const newForm = { ...oldForm };
            newForm[name.toString()] = value;
            setForm({ ...newForm });
        }
    };
    return {
        form,
        setForm,
        formChangeHandler,
        formSubmitHandler,
        flushErrors,
        errors,
        isValid,
    };
};
