import React from 'react';
import { FlexBox, Text } from 'pyatka-ui';
import { useParams } from 'react-router';
import { useI18n } from 'hooks/useI18n';
import { ProductLabel, WithDiscount } from 'components';
import priceFormatter from 'utils/priceFormatter';
import { signs } from 'utils/symbols';
import { isNative } from 'utils/platform';
import { AppMetricEvents } from 'AppMetric';
import { useAppRouter } from 'router/context';
import { AlgoliaEvents } from 'metrics';
import { IOfferFormat, OfferProps } from '../types';
import AddToCartButton from './AddToCartButton';

const OfferPrice: React.FC<OfferProps> = (props) => {
    const { offer, time, query_id } = props;
    const { queryParams } = useAppRouter();
    const { product_id } = useParams<any>();

    const hasDiscount = Boolean(offer?.discount);

    // Click в веб версии
    const handleClick = () => {
        if (!isNative()) {
            const category1 =
                offer?.category_ids && offer?.category_ids?.length > 0
                    ? offer?.category_ids[0]
                    : null;

            AppMetricEvents.jmart_web_cart_click({ item: offer });
            AppMetricEvents.jmart_add_to_cart_click({
                source: 'product',
                data: {
                    product_id: offer?.product_id,
                    product_name: offer?.product,
                    price: offer?.discountPrice,
                    category1,
                },
            });
            if (query_id || queryParams?.query_id) {
                const objectIDs = product_id ? product_id : offer?.master_product_id || '';

                AlgoliaEvents.sendEvent({
                    eventType: 'conversion',
                    eventName: 'Product Purchased',
                    objectIDs: [objectIDs.toString()],
                    queryID: String(query_id || queryParams?.query_id),
                });
            }
        }
    };

    return (
        <FlexBox justifyContent="space-between">
            <FlexBox flexDirection="column" gap="4px">
                <FlexBox alignItems={{ default: 'center', md: 'flex-end' }} gap="8px">
                    <Price
                        price={offer?.discountPrice}
                        priceType={hasDiscount ? 'base-price' : 'price'}
                    />
                    {hasDiscount && (
                        <WithDiscount>
                            <Price price={offer?.price} priceType="discount" />
                        </WithDiscount>
                    )}
                </FlexBox>
                <PaymentLabel offer={offer} time={time} />
            </FlexBox>
            <AddToCartButton onClick={handleClick} {...props} />
        </FlexBox>
    );
};

export default OfferPrice;

// --------- Offer price text
interface IPrice {
    price?: string;
    priceType?: 'price' | 'discount' | 'base-price';
}

const PRICE_STYLE = {
    fontSize: { default: '22px', md: '26px' },
    fontWeight: 700,
    lineHeight: '26px',
};

const DISCOUNT_STYLE = {
    fontSize: { default: '14px', md: '18px' },
    fontWeight: 700,
    lineHeight: '16px',
};

export const Price: React.FC<IPrice> = (props) => {
    const { price, priceType } = props;

    const wrapperStyle = priceType === 'discount' ? DISCOUNT_STYLE : PRICE_STYLE;

    const priceColor =
        priceType === 'discount'
            ? 'text.secondary'
            : priceType === 'price'
            ? 'text.main'
            : 'text.brand';

    const tgColor = priceType === 'base-price' ? 'text.brand' : 'text.placeholder';

    return (
        <FlexBox gap="2px" alignItems="center" {...wrapperStyle}>
            <Text lineClamp={1} color={priceColor}>
                {price}
            </Text>
            <Text color={tgColor}>{signs.tg}</Text>
        </FlexBox>
    );
};

// -------- Payment Label => (кредит или рассрочка)
interface IPaymentLabel {
    offer: IOfferFormat;
    time: string;
}
const PaymentLabel: React.FC<IPaymentLabel> = ({ offer, time }) => {
    const { $t } = useI18n();

    // const hasPaymentType = Boolean(offer?.payment_types[String(time)]);

    const payment_time = offer.payment_types[String(time)];

    const getPaymentLabelInfo = React.useMemo(() => {
        if (payment_time.term_type === 'loan') {
            return { label: 'loan', text: 'on_loan' };
        }
        return { label: 'price', text: 'by_instalments' };
    }, [payment_time]);

    // if (payment_time?.term_type === 'loan') {
    //     return null;
    // }

    // if (!(payment_time?.price && payment_time?.months)) {
    //     return null;
    // }

    if (!payment_time.term_type && payment_time?.bonus === null) {
        return null;
    }

    if (!payment_time.term_type && payment_time?.bonus !== null) {
        return (
            <FlexBox alignItems="center" gap="4px">
                <ProductLabel variant="bonus">{payment_time?.bonus}</ProductLabel>
                <Text color="text.main" variant="h7">
                    при оплате картой
                </Text>
            </FlexBox>
        );
    }

    return (
        <FlexBox alignItems="center" gap="4px">
            {payment_time?.bonus !== null && (
                <ProductLabel variant="bonus">{payment_time?.bonus}</ProductLabel>
            )}
            <Text color="text.main" variant="h7">
                {$t(getPaymentLabelInfo.text).toLowerCase()}
            </Text>

            <ProductLabel variant={getPaymentLabelInfo.label as 'loan' | 'price'}>
                {priceFormatter(payment_time?.price)}{' '}
                <span style={{ color: 'rgba(255, 255, 255, 0.65)' }}>x {payment_time?.months}</span>
            </ProductLabel>
        </FlexBox>
    );
};
