import { AppRouteMiddlewareLayer, AppRouteMiddlewareLayerConfig } from 'router/types';

export { default as AuthorizationMiddleware } from './authorization';
export { default as CheckoutPageMiddleware } from './checkout';
export { default as RedirectPageMiddleware } from './redirect';
export { default as ScrollToTop } from './scrollToTop';
export { default as isErrorPage } from './error';
export { default as CheckoutDebugMiddleware } from './checkoutDebug';
export { default as SwipeMiddleware } from './swipe';

export const middlewarePipe =
    (functions: AppRouteMiddlewareLayer[]) => (config: AppRouteMiddlewareLayerConfig) => {
        return functions.reduce((curValue, curFunc) => {
            return curFunc(curValue);
        }, config);
    };
