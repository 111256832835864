import React, { useState } from 'react';
import { Button, FlexBox, Icon, Text, Box, Modal, Space } from 'pyatka-ui';
import { VerificationModal } from 'components';
import { isNative } from 'utils/platform';
import {
    ActionButton,
    ActionsBlock,
    ProfileAddressItem,
    StyledCheckbox,
} from './ProfileAddressList.styles';
import { ProfileAddressItemProps } from './types';

export const ProfileAddressListLayoutItem: React.FC<ProfileAddressItemProps> = (props) => {
    const {
        editExistAddress,
        deleteExistAddress,
        address,
        onSetActiveAddress,
        activeAddress,
        isMobile,
    } = props;
    const [showActionBtns, setShowActionBtns] = useState(false);
    const [isClearModalOpen, setIsClearModalOpen] = useState(false);

    const onClickEditAddress = () => {
        editExistAddress(address?.id);
    };

    const openClearModal = () => {
        if (isNative()) {
            deleteExistAddress(address?.id);
            return;
        }
        setIsClearModalOpen(true);
    };

    const closeClearModal = () => {
        setIsClearModalOpen(false);
    };

    const handleAccept = () => {
        deleteExistAddress(address?.id);
        setIsClearModalOpen(false);
    };

    const handleReject = () => {
        setIsClearModalOpen(false);
    };

    return (
        <>
            <ProfileAddressItem showBlock={showActionBtns} gap={'10px'} height={'60px'}>
                <FlexBox width={'100%'} justifyContent={'space-between'}>
                    <StyledCheckbox
                        checked={address.id === activeAddress?.id}
                        onChange={() => {
                            onSetActiveAddress(address);
                        }}
                        style={{ flex: 'none', width: '90%' }}
                        enterKeyHint={undefined}
                        css={undefined}
                    >
                        <Box>
                            <Text
                                color={'text.secondary'}
                                lineClamp={1}
                                lineHeight="15px"
                                fontSize={{ default: 12, md: 16 }}
                            >
                                {address?.city}
                            </Text>
                            <Text
                                color={'text.main'}
                                className="truncate-text"
                                lineClamp={2}
                                lineHeight="22px"
                                fontSize={{ default: 16, md: 20 }}
                            >
                                {address?.description}
                            </Text>
                        </Box>
                    </StyledCheckbox>

                    <FlexBox justifyContent="end" gap="18px" alignItems={'center'}>
                        <Button
                            onClick={() => setShowActionBtns((v) => !v)}
                            border="none"
                            padding={0}
                        >
                            <Icon
                                icon={'more'}
                                width={isMobile ? 24 : 32}
                                height={isMobile ? 24 : 32}
                            />
                        </Button>
                    </FlexBox>
                </FlexBox>

                <ActionsBlock showBlock={showActionBtns} alignItems={'center'}>
                    <ActionButton onClick={onClickEditAddress} backgroundColor={'block.secondary'}>
                        <Icon
                            fill={'block.placeholder'}
                            icon={'edit-pen'}
                            width={isMobile ? 28 : 32}
                            height={isMobile ? 28 : 32}
                        />
                    </ActionButton>
                    <ActionButton onClick={openClearModal} backgroundColor={'block.brand_variant'}>
                        <Icon
                            fill={'#fff'}
                            icon={'bin'}
                            width={isMobile ? 28 : 32}
                            height={isMobile ? 28 : 32}
                        />
                    </ActionButton>
                </ActionsBlock>
            </ProfileAddressItem>
            <Modal
                padding={'20px 0 0 0'}
                width={'fit-content'}
                open={isClearModalOpen}
                onClose={closeClearModal}
            >
                <VerificationModal onAccept={handleAccept} onReject={handleReject}>
                    <Box padding={'0 25px 0 25px'}>
                        <Text
                            textAlign={'center'}
                            fontWeight={'700'}
                            fontSize={'26px'}
                            lineHeight={'33px'}
                        >
                            {'Удалить адрес?'}
                        </Text>
                        <Space height={'10px'} />
                        <Text textAlign={'center'} fontSize={'18px'} lineHeight={'23px'}>
                            Вы уверены, что хотите удалить адрес?
                        </Text>
                    </Box>
                </VerificationModal>
            </Modal>
        </>
    );
};
