import { AuthorizationInitialState } from 'modules/Authorization/types';

const state: AuthorizationInitialState = {
    token: '',
    ttl: 0,
    otp: { status: 'not-started', expire: 0, phone: '' },
    errors: {},
};

export default state;
