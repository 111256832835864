import React, { useMemo } from 'react';
import { Box, FlexBox, Icon, Text } from 'pyatka-ui';
import {
    Card,
    StatusItemTitle,
    StatusItemWrapper,
} from 'components/ClientOrderDetailCard/ClientOrderDetailCard.Styles';
import { ClientOrderDetailCardConnectProps } from 'components/ClientOrderDetailCard/ClientOrderDetailCard.Connect';
import { useI18n } from 'hooks/useI18n';

type StatusInfoBlockProps = {
    statuses: ClientOrderDetailCardConnectProps['statuses'];
    hasShipping: boolean;
    isMobile: boolean;
};
const StatusInfoBlock = (props: StatusInfoBlockProps) => {
    const {
        statuses: { statuses, delivery_info },
        hasShipping,
        isMobile,
    } = props;
    const { $t } = useI18n();
    const statusIcons: { [key: string]: string } = useMemo(
        () => ({
            C: 'tennge', // оплачено +
            O: 'tennge', // ожидается оплата +
            F: 'tennge', // ошибка оплаты +
            D: 'cancelOrder', // отклонен продавцом +
            B: 'back', // к возврату +
            I: 'cancelOrder', // отклонен покупателем +
            Y: 'box-t', // тестовый заказ +
            H: 'clock', // в обработке у продавца +
            Q: 'group_3', // доставлено +
            R: 'tennge', // оплачено в кредит/рассрочку +
            G: hasShipping ? 'vann' : 'tick2', // готов к доставке + vann
            M: hasShipping ? 'vann-tick' : 'tick2', // осуществляется доставка/v postamate +
            Z: 'document-approve', // создана кредитная линия +
            V: 'document-approve', // одобрено в кредит/рассрочку +
            E: 'document-reject', // отказ в кредитовании +
            S: 'back-partial', // частичный возврат обработан +
            K: 'back', // полный возврат обработан +
            GP: 'vann', //
        }),
        [hasShipping]
    );
    const current_status_index = useMemo(
        () => (statuses.length > 0 ? statuses.findIndex((item) => item.is_current) : -1),

        [statuses]
    );
    const singleStatus = useMemo(() => statuses.length === 1, [statuses]);
    return (
        <Card
            borderRadius={isMobile ? '0 0 26px 26px' : '0px'}
            padding={'24px 24px 74px'}
            alignItems={'center'}
            paddingBottom={statuses.length > 1 ? '74px' : '20px'}
        >
            {(delivery_info.title || delivery_info.description) && (
                <FlexBox
                    flexDirection={delivery_info.reverse ? 'column-reverse' : 'column'}
                    justifyContent={'center'}
                    gap={'4px'}
                    mb={{ default: '12px', md: '22px' }}
                >
                    {delivery_info.title && (
                        <Text
                            textAlign={'center'}
                            fontWeight={600}
                            fontSize={{ default: '18px', md: '20px' }}
                            lineHeight={{ default: '23px', md: '26px' }}
                        >
                            {$t(delivery_info.title)}
                        </Text>
                    )}
                    {delivery_info.description && (
                        <Text
                            textAlign={'center'}
                            fontWeight={500}
                            color={'text.placeholder'}
                            fontSize={{ default: '14px', md: '16px' }}
                            lineHeight={{ default: '18px', md: '20px' }}
                        >
                            {$t(delivery_info.description)}
                        </Text>
                    )}
                </FlexBox>
            )}

            {statuses?.length > 0 && (
                <FlexBox
                    paddingX={'10px'}
                    width={'100%'}
                    alignItems={'center'}
                    justifyContent={singleStatus ? 'flex-start' : 'center'}
                >
                    {statuses.map((item, i) => (
                        <FlexBox key={item?.status} alignItems={'center'}>
                            {statusIcons[item.status] && (
                                <FlexBox
                                    flexDirection={singleStatus ? 'row' : 'column'}
                                    position={'relative'}
                                    alignItems={'center'}
                                    justifyContent={'center'}
                                    gap={'16px'}
                                >
                                    <StatusItemWrapper background={item?.styles?.bgColor}>
                                        <Icon
                                            fill={item?.styles?.iconColor}
                                            icon={
                                                item.status === 'B' && item.refund_reason
                                                    ? 'back-canceled'
                                                    : statusIcons[item.status]
                                            }
                                        />
                                    </StatusItemWrapper>
                                    {singleStatus ? (
                                        <Box>
                                            <Text
                                                textAlign={'left'}
                                                key={statuses[0].status}
                                                fontWeight={500}
                                                fontSize={{ default: '18px', md: '20px' }}
                                                lineHeight={'26px'}
                                                color={'text.main'}
                                                lineClamp={2}
                                            >
                                                {$t(statuses[0].short || statuses[0].title || '')}
                                            </Text>
                                            {statuses[0].description && (
                                                <Text
                                                    color={'text.secondary'}
                                                    fontSize={{ default: '14px', md: '16px' }}
                                                    fontWeight={500}
                                                    lineHeight={'20px'}
                                                    textAlign={'left'}
                                                >
                                                    {$t(statuses[0].description)}
                                                </Text>
                                            )}
                                        </Box>
                                    ) : (
                                        <StatusItemTitle
                                            width={
                                                statuses?.length - 1 === i ? '80px' : 'fit-content'
                                            }
                                            textAlign={'center'}
                                            key={item.status}
                                            fontWeight={500}
                                            fontSize={{ default: '14px', md: '16px' }}
                                            lineHeight={'20px'}
                                            paddingTop={'14px'}
                                            color={
                                                item?.is_current ? 'text.main' : 'text.placeholder'
                                            }
                                            lineClamp={2}
                                        >
                                            {$t(item.short || item.title || '')}
                                        </StatusItemTitle>
                                    )}
                                </FlexBox>
                            )}

                            {statuses?.length - 1 !== i && (
                                <Box
                                    width={'51px'}
                                    height={'7px'}
                                    background={i < current_status_index ? '#9BD050' : '#F6F6F5'}
                                />
                            )}
                        </FlexBox>
                    ))}
                </FlexBox>
            )}
        </Card>
    );
};

export default StatusInfoBlock;
