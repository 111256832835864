import React from 'react';
import { Button, Text, usePyatka } from 'pyatka-ui';
import { useI18n } from 'hooks/useI18n';

interface IAddToCartButton {
    isLoading: boolean;
    addToCartHandler: (e: React.MouseEvent<HTMLElement>) => void;
}

const AddToCartButton: React.FC<IAddToCartButton> = ({ isLoading, addToCartHandler }) => {
    const { $t } = useI18n();
    const { scales } = usePyatka();

    return (
        <Button
            variant="brand"
            styleType="contained"
            borderRadius="999rem"
            onClick={addToCartHandler}
            width={isLoading ? '60%' : 'auto'}
            height={{ default: '34px', md: '43px' }}
            padding={{ default: '8px 16px', md: '10px 20px' }}
            bg={scales?.isMobile ? 'block.brand' : 'block.brand_variant'}
        >
            <Text
                fontWeight={600}
                skeleton={isLoading}
                fontSize={{ default: '14px', md: '18px' }}
                lineHeight={{ default: '17.92px', md: '23.4px' }}
                skeletonProps={{
                    width: '100%',
                    height: '1em',
                    borderRadius: '12px',
                }}
            >
                {$t('to_cart').toCapitalizeFirstLetter()}
            </Text>
        </Button>
    );
};

export default AddToCartButton;
