import React from 'react';
import { Box, Icon, Input, Text } from 'pyatka-ui';
import { useI18n } from 'hooks/useI18n';

interface IFilterSearch {
    input: string;
    updateInput: (value: string | { name: string | undefined; value: string }) => void;
    isEmpty: boolean;
}

const FilterSearch: React.FC<IFilterSearch> = ({ input, updateInput, isEmpty }) => {
    const { $t } = useI18n();

    return (
        <Box bg="block.main" mt={{ default: 0, md: '16px' }}>
            <Input
                value={input}
                color="text.main"
                borderRadius="999rem"
                onChange={updateInput}
                height={{ md: '44px' }}
                placeholder={$t('search')}
                backgroundColor="block.secondary"
                fontSize={{ default: '15px', md: '16px' }}
                p={{ default: '9px 11px 9px 16px', lg: '12px 4px 12px 16px' }}
                suffix={
                    <Box pr="14px">
                        <Icon width="17px" icon="search" fill="text.secondary" />
                    </Box>
                }
            />
            {isEmpty && (
                <Box textAlign="center" color="text.secondary" paddingTop="16px">
                    <Text>{$t('not_found').toCapitalizeFirstLetter()}</Text>
                </Box>
            )}
        </Box>
    );
};

export default React.memo(FilterSearch);
