import React from 'react';
import { Modal, usePyatka, Text, FlexBox, Button, Icon } from 'pyatka-ui';
import styled from 'styled-components';
import { useI18n } from 'hooks/useI18n';

interface TableSizeProps {
    category_ids: number[];
}

enum Category {
    ManID = 43706, // мужская обувь
    KidID = 43737, // детская обувь
    WomanID = 43723, // женская обувь
}

const TableSize: React.FC<TableSizeProps> = ({ category_ids = [] }) => {
    const [modal, setModal] = React.useState(false);
    const { scales } = usePyatka();
    const { $t } = useI18n();

    const onClose = () => setModal(false);
    const onOpen = () => setModal(true);

    const sizes: Record<string, ISize[]> = {
        [Category.ManID]: mensSizeTable,
        [Category.KidID]: kidsSizeTable,
        [Category.WomanID]: womensSizeTable,
    };

    const category3 = React.useMemo(() => {
        const [, , category3] = category_ids || [];
        return Number(category3 || 0);
    }, [category_ids]);

    const showTableSize = Boolean(category3) && Object.values(Category).includes(category3);

    return (
        <>
            {showTableSize && (
                <Button
                    p={{ default: '6px 10px', md: '10px 15px' }}
                    mt={{ default: '18px', md: '25px' }}
                    height={{ md: '44px' }}
                    borderRadius="999rem"
                    styleType="contained"
                    variant="secondary"
                    onClick={onOpen}
                >
                    <Icon icon="size" />
                    <Text
                        fontSize={{ default: '13px', md: '16px' }}
                        color="text.main"
                        fontWeight={500}
                        ml="6px"
                    >
                        {$t('shoes_size_tablet')}
                    </Text>
                </Button>
            )}
            <Modal
                swipeable
                padding="0"
                open={modal}
                onClose={onClose}
                positionMode="mobile"
                width={scales?.isMobile ? '100%' : '450px'}
            >
                <FlexBox
                    width="42px"
                    height="5px"
                    margin="6px auto"
                    borderRadius="50px"
                    bg="block.disabled"
                />

                <FlexBox p="12px 18px 24px 18px" flexDirection="column" gap="6px">
                    <Text fontSize={{ default: '22px' }} fontWeight={700}>
                        {$t('shoes_size_tablet')}
                    </Text>
                    <Text fontSize={{ default: '14px' }} fontWeight={500} color="text.secondary">
                        {$t('how_to_know_size')}
                    </Text>
                    <Text fontSize={{ default: '14px' }} fontWeight={500}>
                        {$t('how_to_know_size_instr')}
                    </Text>
                </FlexBox>

                <table style={{ width: '100%' }}>
                    <thead>
                        <StyledTr style={{ backgroundColor: '#f6f6f5' }}>
                            <th style={{ width: '18%', padding: '10px', paddingLeft: '24px' }}>
                                {' RU '}
                            </th>
                            <th style={{ width: '18%', padding: '10px' }}> EU </th>
                            <th style={{ width: '18%', padding: '10px' }}> US </th>
                            <th style={{ width: '18%', padding: '10px' }}> UK </th>
                            <th style={{ width: '28%', padding: '10px', paddingRight: '24px' }}>
                                {$t('foot_size')}
                            </th>
                        </StyledTr>
                    </thead>
                    <tbody>
                        {sizes[String(category3)]?.map((size) => (
                            <StyledTr key={size.id}>
                                <td style={{ padding: '10px', paddingLeft: '24px' }}>
                                    {` ${size.ru} `}
                                </td>
                                <td style={{ padding: '10px' }}> {size.eu} </td>
                                <td style={{ padding: '10px' }}> {size.us} </td>
                                <td style={{ padding: '10px' }}> {size.uk} </td>
                                <td style={{ padding: '10px', paddingRight: '24px' }}>
                                    {size.length}
                                </td>
                            </StyledTr>
                        ))}
                    </tbody>
                </table>
            </Modal>
        </>
    );
};

const StyledTr = styled.tr`
    font-size: 14px;
    white-space: nowrap;
    :nth-child(even) {
        background-color: #f6f6f5;
    }
`;

interface ISize {
    id: number;
    ru: number;
    eu: number;
    us: number;
    uk: number;
    length: number;
}

const womensSizeTable = [
    { id: 1, ru: 34.5, eu: 35.5, us: 5, uk: 2.5, length: 22 },
    { id: 2, ru: 35, eu: 36, us: 5.5, uk: 3, length: 22.4 },
    { id: 3, ru: 35.5, eu: 36.5, us: 6, uk: 3.5, length: 22.9 },
    { id: 4, ru: 36.5, eu: 37.5, us: 6.5, uk: 4, length: 23.3 },
    { id: 5, ru: 37, eu: 38, us: 7, uk: 4.5, length: 23.7 },
    { id: 6, ru: 37.5, eu: 38.5, us: 7.5, uk: 5, length: 24.1 },
    { id: 7, ru: 38, eu: 39, us: 8, uk: 5.5, length: 24.5 },
    { id: 8, ru: 39, eu: 40, us: 8.5, uk: 6, length: 25 },
    { id: 9, ru: 39.5, eu: 40.5, us: 9, uk: 6.5, length: 25.4 },
    { id: 10, ru: 40, eu: 41, us: 9.5, uk: 7, length: 25.8 },
    { id: 11, ru: 41, eu: 42, us: 10, uk: 7.5, length: 26.2 },
    { id: 12, ru: 41.5, eu: 42.5, us: 10.5, uk: 8, length: 26.7 },
    { id: 13, ru: 42, eu: 43, us: 11, uk: 8.5, length: 27.1 },
    { id: 14, ru: 43, eu: 44, us: 11.5, uk: 9, length: 27.5 },
    { id: 15, ru: 43.5, eu: 44.5, us: 12, uk: 9.5, length: 27.9 },
];

const mensSizeTable = [
    { id: 1, ru: 34.5, eu: 45.5, us: 3.5, uk: 3, length: 21.6 },
    { id: 2, ru: 35, eu: 36, us: 4, uk: 3.5, length: 22 },
    { id: 3, ru: 35.5, eu: 36.5, us: 4.5, uk: 4, length: 22.4 },
    { id: 4, ru: 36.5, eu: 37.5, us: 5, uk: 4.5, length: 22.9 },
    { id: 5, ru: 37, eu: 38, us: 5.5, uk: 5, length: 23.3 },
    { id: 6, ru: 37.5, eu: 38.5, us: 6, uk: 5.5, length: 23.7 },
    { id: 7, ru: 38, eu: 39, us: 6.5, uk: 6, length: 24.1 },
    { id: 8, ru: 39, eu: 40, us: 7, uk: 6, length: 24.5 },
    { id: 9, ru: 39.5, eu: 40.5, us: 7.5, uk: 6.5, length: 25 },
    { id: 10, ru: 40, eu: 41, us: 8, uk: 7, length: 25.4 },
    { id: 11, ru: 41, eu: 42, us: 8.5, uk: 7.5, length: 25.8 },
    { id: 12, ru: 41.5, eu: 42.5, us: 9, uk: 8, length: 26.2 },
    { id: 13, ru: 42, eu: 43, us: 9.5, uk: 8.5, length: 26.7 },
    { id: 14, ru: 43, eu: 44, us: 10, uk: 9, length: 27.1 },
    { id: 15, ru: 43.5, eu: 44.5, us: 10.5, uk: 9.5, length: 27.5 },
    { id: 16, ru: 44, eu: 45, us: 11, uk: 10, length: 27.9 },
    { id: 17, ru: 44.5, eu: 45.5, us: 11.5, uk: 10.5, length: 28.3 },
    { id: 18, ru: 45, eu: 46, us: 12, uk: 11, length: 28.8 },
    { id: 19, ru: 46, eu: 46.5, us: 12.5, uk: 11.5, length: 29.2 },
    { id: 20, ru: 46.5, eu: 47.5, us: 13, uk: 12, length: 29.6 },
    { id: 21, ru: 47, eu: 48, us: 13.5, uk: 12.5, length: 30 },
    { id: 22, ru: 47.5, eu: 48.5, us: 14, uk: 13, length: 30.5 },
    { id: 23, ru: 48.5, eu: 49.5, us: 15, uk: 14, length: 31.3 },
    { id: 24, ru: 49.5, eu: 50.5, us: 16, uk: 15, length: 32.2 },
    { id: 25, ru: 50, eu: 51.5, us: 17, uk: 16, length: 33 },
    { id: 26, ru: 51.5, eu: 52.5, us: 18, uk: 17, length: 33.9 },
];

const kidsSizeTable = [
    { id: 1, ru: 16, eu: 16, us: 0.5, uk: 0, length: 8.3 },
    { id: 2, ru: 17, eu: 17, us: 2, uk: 1, length: 9.5 },
    { id: 3, ru: 18, eu: 18, us: 3, uk: 2, length: 10.5 },
    { id: 4, ru: 19, eu: 19, us: 4, uk: 3, length: 11.7 },
    { id: 5, ru: 20, eu: 20, us: 5, uk: 4, length: 12.5 },
    { id: 6, ru: 21, eu: 21, us: 5.5, uk: 4.5, length: 13 },
    { id: 7, ru: 22, eu: 22, us: 6, uk: 5, length: 13.3 },
    { id: 8, ru: 23, eu: 23, us: 7, uk: 6, length: 14 },
    { id: 9, ru: 24, eu: 24, us: 8, uk: 7, length: 14.6 },
    { id: 10, ru: 25, eu: 25, us: 9, uk: 8, length: 15.2 },
    { id: 11, ru: 26, eu: 26, us: 9.5, uk: 8.5, length: 15.9 },
    { id: 12, ru: 27, eu: 27, us: 10, uk: 9, length: 16.5 },
    { id: 13, ru: 28, eu: 28, us: 11, uk: 10, length: 17.1 },
    { id: 14, ru: 29, eu: 29, us: 12, uk: 11, length: 17.8 },
    { id: 15, ru: 30, eu: 30, us: 12.5, uk: 11.5, length: 18.1 },
];

export default TableSize;
