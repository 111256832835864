import {
    IndicatorManagerConfig,
    IndicatorManagerPayload,
    IndicatorManagers,
    indicators,
    IndicatorState,
} from './types';

export const LOADER_EVENT_KEY = 'manage-Loader';

const manageLoader = (payload: IndicatorManagerPayload) => {
    if (__BROWSER__) {
        const open = new CustomEvent(LOADER_EVENT_KEY, {
            detail: payload,
        });
        document.dispatchEvent(open);
        return;
    }
    return;
};

export const indicatorsState: IndicatorState = indicators.reduce(
    (prev: IndicatorState | {}, now) => ({
        ...prev,
        [now]: {
            active: false,
            global: false,
            status: false,
        },
    }),
    {}
) as IndicatorState;

export const Loader: IndicatorManagers = indicators.reduce(
    (prev, now) => ({
        ...prev,
        [now]: {
            on: (config: IndicatorManagerConfig) =>
                manageLoader({ global: false, indicator: now, status: true, ...config }),
            off: () => manageLoader({ global: false, indicator: now, status: false }),
            indicator: now,
        },
    }),
    {}
) as IndicatorManagers;
