import {
    Product,
    ProductsCheckBoxFilter,
    ProductsFilter,
    ProductsPriceFilter,
} from 'modules/ProductsList/types';
import priceFormatter from 'utils/priceFormatter';
import { filterMerchant } from 'pages/ProductsList/utils';

export const FILTER_STYLE_CHECKBOX = 'checkbox';
export const FILTER_STYLE_PRICE = 'price';
export const FILTER_INDEX_PRICE = 'price';
export const FILTER_VARIANT_KEY = 'feature_variants';
export const FILTER_MERCHANT_ID = 'company';
export const FILTER_COMPANY_IDS = 'company_ids';
export const FILTER_BEST_PRICE = 'best_price';
export const FILTER_HAS_DISCOUNT = 'has_discount';

const FilterBaseParser = (data: any): ProductsFilter => ({
    id: data && data.feature_id,
    title: data && data?.filter,
    filter_type:
        data && data?.filter_style === FILTER_STYLE_CHECKBOX
            ? FILTER_STYLE_CHECKBOX
            : FILTER_STYLE_PRICE,
});

const FilterPriceParser = (data: any): ProductsPriceFilter => ({
    ...FilterBaseParser(data),
    variants: { min: data && data.min, max: data && data.max },
});

const FilterCheckboxParser = (data: any): ProductsCheckBoxFilter => ({
    ...FilterBaseParser(data),
    variants:
        data &&
        data.variants &&
        data.variants.map((item: any) => {
            return {
                id: `${item.variant_id}`,
                title: item.variant,
                count: item.count,
            };
        }),
});

export const ProductsFilterParser = (
    filters: any = []
): (ProductsPriceFilter & ProductsCheckBoxFilter)[] => {
    return filters.map((filter: any) => {
        const filter_style = filter && filter?.filter_style;
        if (filter_style === FILTER_STYLE_CHECKBOX) {
            return FilterCheckboxParser(filter);
        }
        return FilterPriceParser(filter);
    });
};
export const addCompressedSuffix = (url: string) => {
    return url?.length > 0 ? url.replace(/(\.\w+)$/, '_compressed$1') : url;
};
export const ProductsParser = (products: any, by_master: boolean = false): Product[] =>
    (products || []).map((product: any) => parseProduct(product, by_master));

export function parseProduct(product: any, byMasterId: boolean = false): Product {
    const discountPrice = product.price !== product.base_price ? product.price : null;
    const percent = product.discount ? (1 - discountPrice / product.base_price) * 100 : 0;
    return {
        ...product,
        image: product.image_url ? product.image_url : product.imageUrl,
        image_preload: addCompressedSuffix(product.image_url || product.imageUrl),
        image_urls: product.image_urls || [],
        productName: processProductName(product?.product || product?.title || ''),
        price: product.base_price || product?.price,
        base_price: product.base_price,
        price_formatted: priceFormatter(product.base_price || product?.price) || '',
        gift_exist: product.gift_exist,
        discount_only: product.discount,
        discountPrice,
        discountPrice_formatted: priceFormatter(discountPrice) || '',
        merchant_name: product.brand_name,
        category_name: product.category_name,
        category_ids: product.category_ids,
        company_id: product.company_id,
        master_product_id: byMasterId ? product.master_product_id : product.product_id,
        productId: product.product_id || product?.productId,
        product_id: byMasterId ? product.master_product_id : product.product_id,
        offers: product.offers || [],
        offer_id: byMasterId ? product.product_id : product.min_price_product_id,
        type: product.product_types || product?.type || product?.productType,
        label: {
            installment: getInstallmentPrice(product.installment_price),
            ...generateLabels(product.labels),
        },
        url: {
            name: 'product-detail-page',
            params: {
                product_id: product.product_id || product?.productId,
                product_type:
                    product.product_types ||
                    product.type ||
                    product?.product_type ||
                    product?.productType ||
                    'none',
            },
            ...(product?.company_ids
                ? {
                      query: {
                          ...filterMerchant(
                              null,
                              product?.company_ids?.reduce((a, v) => ({ ...a, [v]: v }), {})
                          ),
                      },
                  }
                : {}),
        },
        urlMasterId: {
            name: 'product-detail-page',
            params: {
                product_id: product.master_product_id,
                product_type: product.product_types || product.type || 'none',
            },
        },
        amount: product?.amount,
        bonuses_price: product?.bonuses,
        isActual: product?.is_actual || false,
        shippingInfo: product?.shipping_info,
        discount_percent: getDiscountLabel(percent, product.base_price - product.price || 0),
    };
}

export const getInstallmentPrice = (installment: any) => {
    if (installment && installment.price && installment.months) {
        return {
            price: installment.price,
            price_formatted: priceFormatter(installment.price),
            term: installment.months,
        };
    }
    return null;
};

const labelsDict = {
    bonusLabel: isBonusLabel,
    discountLabel: isDiscountLabel,
    installmentLabel: isInstallmentLabel,
};

export const generateLabels = (labels: any) => {
    const createLabel = ({ label_text, label_color, label_color_text }: any) => {
        return {
            bgColor: label_color,
            text: label_text,
            color: label_color_text,
        };
    };
    const result: { [key: string]: any } = {};
    for (const [key, value] of Object.entries(labelsDict)) {
        const label = labels?.find((label: any) => value(label?.label_text));
        if (label) {
            result[key] = createLabel(label);
        } else {
            result[key] = null;
        }
    }
    return result;
};

/* TODO: fix rewrite  */
function isBonusLabel(text: string) {
    return text.includes('Б');
}

function isDiscountLabel(text: string) {
    const discountRegExp = new RegExp('^[-]\\d{1,2}[%]$');
    discountRegExp.lastIndex = 0;
    return discountRegExp.test(text);
}

function isInstallmentLabel(text: string) {
    let isValid = false;
    const regExp = new RegExp('^\\d{1,2}[-]\\d{1,2}[-]\\d{1,2}$');
    text.split(' ').forEach((str) => {
        if (regExp.test(str)) {
            isValid = true;
        }
    });
    return isValid;
}

export const generateAppLinkConfig = (type: string, id: string | number) => {
    const params = {
        product: { name: 'product-detail-page', params: { product_id: id } },
        company: { name: 'store-detail', params: { store_id: id }, exact: true },
    };
    // @ts-ignore
    return params[type];
};

const processProductName = (productName?: string) => {
    return productName?.replace('&nbsp;', '');
};

export const getDiscountLabel = (discount: number, _diff: number = 0) => {
    const diff = Number(_diff);

    if (diff === 0 || isNaN(diff)) {
        return null;
    }

    if (discount < 3) {
        return `${priceFormatter(diff)} ₸`;
    }

    // if (discount === 11.11) {
    //     return '11.11 %';
    // }

    return `${Math.round(discount)} %`;
};
