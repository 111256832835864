import React, { useMemo } from 'react';
import { Box, FlexBox, Icon, Text, usePyatka } from 'pyatka-ui';
import { Card, StyledImage } from 'components/ClientOrderDetailCard/ClientOrderDetailCard.Styles';
import { AppMetricEvents } from 'AppMetric';
import { isNative } from 'utils/platform';
import { nativeCall } from 'hybrid';
import { AppLink, Rating } from 'components';
import { useI18n } from 'hooks/useI18n';

type CompanyInfoBlockProps = {
    company?: string;
    formatted_merchant_phone?: string | null;
    company_info: any;
    company_id?: number;
    shipping_info?: any;
    delivery_type?: string;
};
const CompanyInfoBlock = (props: CompanyInfoBlockProps) => {
    const {
        company,
        formatted_merchant_phone,
        company_info,
        company_id,
        shipping_info,
        delivery_type,
    } = props;
    const { $t } = useI18n();
    const {
        scales: { isMobile },
    } = usePyatka();
    const onPhoneClick = (phone: string) => {
        AppMetricEvents.call_merchant();
        if (isNative()) {
            nativeCall({
                type: 'call',
                payload: {
                    phone,
                },
            });
        }
    };
    const deliveryTypeDostavka = useMemo(
        () => delivery_type === 'shipping_jpost' || delivery_type === 'shipping_merchant',
        [delivery_type]
    );
    const hasShippingTime = useMemo(
        () =>
            shipping_info?.shipping_date ||
            shipping_info?.shipping_interval ||
            shipping_info?.schedule_desc,
        [
            shipping_info?.schedule_desc,
            shipping_info?.shipping_date,
            shipping_info?.shipping_interval,
        ]
    );
    const showTime = useMemo(
        () => !deliveryTypeDostavka && hasShippingTime,
        [deliveryTypeDostavka, hasShippingTime]
    );
    return (
        <Card isMobile={isMobile}>
            <FlexBox
                justifyContent={'space-between'}
                alignItems={'center'}
                mb={{ default: '16px', md: '19px' }}
            >
                <Text fontSize={{ default: '16px', md: '18px' }} fontWeight={600}>
                    {$t('seller')}
                </Text>
                {!showTime && (
                    <Box onClick={() => onPhoneClick(formatted_merchant_phone!)}>
                        <a href={`tel:${formatted_merchant_phone}`}>
                            <Icon
                                icon={'phone'}
                                width={isMobile ? '36px' : '40px'}
                                height={isMobile ? '36px' : '40px'}
                            />
                        </a>
                    </Box>
                )}
            </FlexBox>

            <Box>
                <FlexBox
                    gap={'16px'}
                    alignItems={showTime ? 'flex-start' : 'center'}
                    mb={showTime ? 11 : 0}
                >
                    <Box>
                        <StyledImage
                            isMobile={isMobile}
                            src={company_info?.logos?.theme?.image_path}
                            borderRadius={'50%'}
                            hideOnError={true}
                        />
                    </Box>
                    <FlexBox
                        gap={'2px'}
                        pb={showTime ? '17px' : 0}
                        flex={1}
                        flexDirection={'column'}
                        borderBottom={showTime ? '2px solid #BEBEB633' : 'none'}
                    >
                        <FlexBox gap={'12px'} alignItems={'center'}>
                            <AppLink
                                to={{
                                    name: 'merchant',
                                    query: {
                                        company_id,
                                    },
                                }}
                            >
                                <Text
                                    color={'text.main'}
                                    fontWeight={600}
                                    fontSize={{ default: '16px', md: '18px' }}
                                >
                                    {company}
                                </Text>
                            </AppLink>
                            <Rating rating={company_info?.rating / 100} />
                        </FlexBox>
                        {shipping_info?.address && (
                            <Text
                                color={'text.secondary'}
                                fontWeight={500}
                                fontSize={{ default: '14px', md: '16px' }}
                            >
                                {shipping_info?.address}
                            </Text>
                        )}
                    </FlexBox>
                </FlexBox>
                {showTime && (
                    <FlexBox gap={'16px'} alignItems={'center'}>
                        <Box onClick={() => onPhoneClick(formatted_merchant_phone!)}>
                            <a href={`tel:${formatted_merchant_phone}`}>
                                <Icon
                                    icon={'phone'}
                                    width={isMobile ? '36px' : '40px'}
                                    height={isMobile ? '36px' : '40px'}
                                />
                            </a>
                        </Box>
                        <Text
                            color={'text.main'}
                            fontWeight={500}
                            fontSize={{ default: '14px', md: '16px' }}
                            lineHeight={'20px'}
                        >
                            {shipping_info?.shipping_date} {shipping_info?.shipping_interval}{' '}
                            {shipping_info.schedule_desc}
                        </Text>
                    </FlexBox>
                )}
            </Box>
        </Card>
    );
};

export default CompanyInfoBlock;
