import React, { useCallback, useMemo } from 'react';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import { useDispatch, useSelector } from 'react-redux';
import {
    AppCartItemState,
    AppCartLoadingState,
    AppCartServiceInstance,
    AppCartState,
    AppCartStateData,
    AppCartStateMain,
    AppCartSyncConfig,
    CART_STATUSES,
} from 'contexts/useCartService/types';
import { AppModulesStorage, getFingerprint } from 'data';
import { AppCartSyncServices } from 'contexts/useCartService/services';
import { AppToaster } from 'plugins/app-toaster';
import { isUserAuth } from 'modules/Authorization/Authorization.selectors';
import {
    AppCartBasePrepare,
    AppGroupCartBasePrepare,
    AppSyncResponseFormatter,
    AppSyncResponseFormatterV2Put,
    cartPlainDataRevisionProducts,
} from 'contexts/useCartService/helper';
import { cartServiceDataFlush, cartServiceDataSave } from 'modules/CartService/CartService.actions';
import {
    getCartServiceDataNew,
    getCartServicePlainData,
    getCartTempRemoves,
} from 'modules/CartService/CartService.selectors';
import { AppMetricEvents } from 'AppMetric';
import { useLoader } from 'contexts/useLoader';
import { Loader } from 'plugins/loader';
import { GtmEvents } from 'metrics';
import { useAppRouter } from 'router/context';

const CONTEXT_MODULE_NAME = 'cartService-updated-v3';
const CART_STATUS_INITIAL = CART_STATUSES.initial;
const CART_AUTH_STATUS_TOKEN = 'auth-by-token';
const CART_AUTH_STATUS_APPSFLYER = 'auth-by-appsflyer';
const CART_AUTH_RESTORE_HASH = 'auth-restore';
const AppCartServiceContext = React.createContext<AppCartServiceInstance | undefined>(undefined);

const AppCartServiceProvider: React.FC<any> = (props) => {
    // ---------------------------------------------------------------- NEEDS
    const { indicators } = useLoader();
    const { location, appRedirect } = useAppRouter();
    const redux_data = useSelector(getCartServiceDataNew);
    const fingerprint_id = getFingerprint();
    const redux_plain_data = useSelector(getCartServicePlainData);
    const cartTempRemovable = useSelector(getCartTempRemoves);
    const dispatch = useDispatch();
    const isReduxAuth = useSelector(isUserAuth);
    const isAuth = useMemo(() => {
        console.log(fingerprint_id);
        if (isReduxAuth) {
            return CART_AUTH_STATUS_TOKEN;
        }
        if ((__BROWSER__ && window?.__HYBRIDCONFIG__?.appsflyer_id) || fingerprint_id) {
            return CART_AUTH_STATUS_APPSFLYER;
        }
        return false;
    }, [fingerprint_id, isReduxAuth]);

    // ---------------------------------------------------------------- STATE MANAGEMENT
    // Cart State
    const [cartState, setCartState] = React.useState<AppCartStateMain>({
        status: CART_STATUS_INITIAL,
        data: {},
        mutationIds: [],
        preselected_cart_hash: '',
    }); // state

    const reduxState = React.useMemo((): any[] => {
        const res: any[] = [];
        redux_data.forEach((group) => {
            group.products.forEach((item) => {
                res.push({
                    amount: item.amount as number,
                    product_id: item.product_id,
                    offer_id: item.offer_id,
                });
            });
        });
        return res;
    }, [redux_data]);

    // ---------------------------------------------------------------- Cart Memo

    const cart_loadings = React.useMemo((): AppCartLoadingState => {
        return indicators.cart.status
            ? {
                  initial_loading: indicators.cart.payload?.initial_loading,
                  mutation_loading: indicators.cart.payload?.mutation_loading,
              }
            : {
                  initial_loading: false,
                  mutation_loading: [],
              };
    }, [indicators]);

    const cart = React.useMemo(
        (): AppCartState => ({
            data: cartState.data,
            status: cartState.status,
        }),
        [cartState]
    );

    const cartMutationsIds = React.useMemo(
        (): AppCartStateMain['mutationIds'] => cartState.mutationIds,
        [cartState.mutationIds]
    );

    const cartSelectedHash = React.useMemo(
        (): AppCartStateMain['preselected_cart_hash'] => cartState.preselected_cart_hash,
        [cartState.preselected_cart_hash]
    );

    const setCart = useCallback(
        (
            new_cart: AppCartStateMain & any = {
                status: CART_STATUSES.synced,
                data: {},
                mutationIds: [],
                preselected_cart_hash: '',
            }
        ) => {
            setCartState((prevState) => {
                const res = {
                    ...prevState,
                    ...new_cart,
                };
                if (__BROWSER__) {
                    try {
                        AppModulesStorage.set(CONTEXT_MODULE_NAME, {
                            cartData: isAuth ? {} : res.data,
                            // cartData: res.data,
                        });
                    } catch (e) {
                        console.log(e);
                    }
                }
                console.log('setCart:', res);
                return res;
            });
        },
        [isAuth]
    );

    const cartItemsByProduct = React.useCallback(
        (id: AppCartItemState['product_id']) =>
            Object.values(cart.data || {}).filter((item) => item.product_id === id),
        [cart]
    );

    const cartItemByOffer = React.useCallback(
        (id: AppCartItemState['offer_id']) => (cart?.data || {})[id.toString()],
        [cart]
    );

    const cartCounter = React.useMemo(() => {
        const data = Object.values(cart.data || {});
        return data.filter((i) => i.amount > 0 && !cartTempRemovable.includes(i.offer_id)).length;
    }, [cart, cartTempRemovable]);

    // ---------------------------------------------------------------- Cart Restore
    const restoreCart = React.useCallback(() => {
        if (__BROWSER__) {
            const storage = AppModulesStorage.get(CONTEXT_MODULE_NAME) as {
                cartData: AppCartStateData;
            };
            const { cartData: restoredCartData = {} } =
                storage === null ? { cartData: {} } : storage;
            if (isAuth) {
                console.log('restoreCart > isAuth >', cart.status, cartSelectedHash);
                setCart({
                    status: CART_STATUSES.restore,
                    // mutationIds: [],
                    mutationIds:
                        cart.status === CART_STATUSES.initial || isAuth === CART_AUTH_STATUS_TOKEN
                            ? []
                            : Object.keys(cart.data),
                    preselected_cart_hash: cartSelectedHash
                        ? cartSelectedHash
                        : cart.status === CART_STATUSES.synced
                        ? CART_AUTH_RESTORE_HASH
                        : '',
                    // data: {},
                });
                return;
            }

            console.log(
                restoredCartData,
                typeof restoredCartData === 'object',
                isEmpty(restoredCartData)
            );
            setCart({
                status: CART_STATUSES.restore,
                mutationIds: isEmpty(restoredCartData) ? [] : Object.keys(restoredCartData),
                data: isEmpty(restoredCartData) ? {} : restoredCartData,
            });
        }
    }, [cart.data, cart.status, cartSelectedHash, isAuth, setCart]);

    // ---------------------------------------------------------------- Sync Cart Response Resolver
    const syncCartResolver = React.useCallback(
        (props: {
            cart_hash: AppCartStateMain['preselected_cart_hash'];
            cart_snapshot: AppCartStateData | undefined;
            cart_update: AppCartStateData;
            is_flush?: boolean;
        }) => {
            const { cart_update, is_flush = false, cart_hash = '' } = props;
            const cartDataPrepared: AppCartStateData = is_flush
                ? {}
                : isAuth
                ? { ...cart_update }
                : { ...cart_update };

            if (cart_hash && cart_hash !== CART_AUTH_RESTORE_HASH) {
                appRedirect({
                    name: 'checkout-delivery',
                    params: { checkout_type: 'cart', checkout_id: cart_hash },
                });
            }
            setCart({
                status:
                    location.pathname === '/cart' ? CART_STATUSES.synced : CART_STATUSES.presync,
                data: cartDataPrepared,
                mutationIds: [],
                preselected_cart_hash: '',
            });
        },
        [appRedirect, isAuth, setCart]
    );

    // ----------------------------------------------------------------  Sync Cart with Backend

    const syncWithBackend = useCallback(
        async (config: AppCartSyncConfig) => {
            console.log('syncWithBackend : ', config);
            const service = AppCartSyncServices[config?.method.toString()];
            const isResponseGroups =
                config?.method === 'get' ||
                config.method === 'postByProducts' ||
                config.method === 'putAmount';

            if (service === undefined) {
                return false;
            }

            try {
                Loader.cart.on({
                    payload: config?.loading_config,
                });
                const resPayload = {
                    data: { products: config?.payload_data || [] },
                    id_param: config?.payload_data?.id_param,
                };

                console.log('the res payload : ', resPayload);

                const res = await service(resPayload);
                const { data, success } = res?.data;

                if (success) {
                    if (isResponseGroups) {
                        if (config.method === 'putAmount') {
                            const cart_groups = cartPlainDataRevisionProducts(
                                { ...redux_plain_data, ...data?.groups },
                                config.cart_snapshot || {}
                            );
                            dispatch(
                                cartServiceDataSave({
                                    cartData: cart_groups,
                                    products: data.products,
                                })
                            );
                            const cart_update = AppSyncResponseFormatter(cart_groups);
                            syncCartResolver({
                                cart_hash: config?.cart_hash,
                                cart_snapshot: config?.cart_snapshot,
                                cart_update,
                                is_flush: false,
                            });
                            return true;
                        }
                        dispatch(cartServiceDataSave({ cartData: data, products: data.products }));
                        const cart_update = AppSyncResponseFormatter(data);
                        syncCartResolver({
                            cart_hash: config?.cart_hash,
                            cart_snapshot: config?.cart_snapshot,
                            cart_update,
                            is_flush: config?.method === 'clear',
                        });
                        return true;
                    }
                    const cart_update = AppSyncResponseFormatterV2Put(data);
                    console.log('AppSyncResponseFormatterV2Put', cart_update);
                    console.log('AppSyncResponseFormatterV2Put', data);
                    syncCartResolver({
                        cart_hash: config?.cart_hash,
                        cart_snapshot: config?.cart_snapshot,
                        cart_update,
                        is_flush: config?.method === 'clear',
                    });
                    return true;
                }
                throw new Error('Cart Get error');
            } catch (e) {
                console.log(e);
                Loader.cart.off();
                return false;
            } finally {
                Loader.cart.off();
            }
        },
        [dispatch, redux_plain_data, syncCartResolver]
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const syncDebounce = useCallback(debounce(syncWithBackend, 600), [syncWithBackend]);

    // ---------------------------------------------------------------- Flush Cart
    const flushAppCart = useCallback(() => {
        const items = Object.values({ ...cart.data } || {});
        GtmEvents.removeFromCart(items);
        if (isAuth) {
            syncDebounce({
                cart_hash: '',
                cart_snapshot: cart.data,
                method: 'clear',
                loading_config: { initial_loading: true, mutation_loading: [] },
                payload_data: [],
            });
            return;
        }
        setCart({
            status: CART_STATUSES.synced,
            data: {},
            mutationIds: [],
        });
        dispatch(cartServiceDataFlush());
        AppMetricEvents.jmart_cart_clear();
    }, [isAuth, setCart, dispatch, syncDebounce, cart.data]);

    // ----------------------------------------------------------------- Delete Cart Group by hash
    const deleteCartGroupByHash = useCallback(
        (params) => {
            // if (isAuth) {
            //     AppCartSyncServices.clear({ params });
            // }

            const cart_snapshot: AppCartStateMain = { ...cartState };

            const { cart_items } = params;
            const update_items = cart_items.map((item: AppCartItemState) => ({
                ...item,
                amount: 0,
            }));

            update_items.forEach((item: AppCartItemState) => {
                cart_snapshot.data[item.offer_id] = item;
            });

            const offer_ids = update_items.map(
                (update_item: AppCartItemState) => update_item.offer_id
            );

            const mutationIds = [...cart_snapshot?.mutationIds, ...offer_ids];

            cart_snapshot.mutationIds = mutationIds;

            setCart({
                ...cart_snapshot,
                status: CART_STATUSES.updating,
            });
        },
        [cart, syncDebounce]
    );

    // -------------------------------------------------------- Update Cart Process
    const updateCartItem = useCallback(
        (cart: AppCartStateMain, update_item: AppCartItemState) => {
            const cart_snapshot: AppCartStateMain = { ...cart };
            const cart_item = cart_snapshot.data[update_item.offer_id];

            if (cart_item === undefined) {
                AppToaster.success({ body: 'product_added_to_cart' });
            }

            AppMetricEvents.jmart_add_to_cart({
                product_id: update_item?.offer_id,
                type:
                    cart_item === undefined
                        ? 'add_to_cart'
                        : update_item?.amount === 0
                        ? 'remove_from_cart'
                        : update_item.amount !== cart_item.amount
                        ? 'update'
                        : '',
                source: 'productCard',
            });

            if (update_item?.amount > (cart_item?.amount || 0)) {
                GtmEvents.addToCart({ ...update_item });
            }

            if (!update_item?.amount) {
                GtmEvents.removeFromCart({ ...update_item });
            }
            //
            cart_snapshot.mutationIds.push(update_item.offer_id);
            cart_snapshot.data[update_item.offer_id] = update_item;
            setCart({
                ...cart_snapshot,
                status: CART_STATUSES.updating,
            });
        },
        [setCart]
    );
    // -------------------------------------------------------- Checkout Sync

    const cartCheckoutSync = useCallback(
        (hash: string) => {
            setCart({
                preselected_cart_hash: hash,
            });
        },
        [setCart]
    );

    // -------------------------------------------------------- Edit Cart Item

    const cartEdit = useCallback(
        (payload: AppCartItemState) => {
            const product_id =
                typeof payload.product_id === 'number'
                    ? payload.product_id.toString()
                    : payload.product_id;

            const offer_id =
                typeof payload.offer_id === 'number'
                    ? payload.offer_id.toString()
                    : payload.offer_id;

            updateCartItem(cartState, { product_id, offer_id, amount: payload.amount });
            return;
        },
        [cartState, updateCartItem]
    );

    const cartSnapshotEdit: AppCartServiceInstance['cartSnapshotEdit'] = useCallback(
        (itemList) => {
            const cart_snapshot = { ...cartState };
            itemList.map((newItem) => {
                const exist_item = cart_snapshot.data[newItem.offer_id];
                if (newItem.amount > 0 && exist_item === undefined) {
                    cart_snapshot.mutationIds.push(newItem.offer_id);
                    cart_snapshot.data[newItem.offer_id] = {
                        amount: newItem?.amount,
                        product_id: newItem?.product_id.toString(),
                        offer_id: newItem?.offer_id.toString(),
                    };
                }
            });
            setCart({
                ...cart_snapshot,
                status: CART_STATUSES.updating,
            });
        },
        [cartState, setCart]
    );

    // Cart Sync
    const syncCart = useCallback(
        (cart: AppCartState, ids: AppCartStateMain['mutationIds'], hash: string) => {
            // console.log(reduxState);
            const is_cart_page = location.pathname === '/cart';
            console.log('syncCart - ', 'is_cart:', is_cart_page, redux_plain_data);
            const cart_payload_data_prepare = isAuth
                ? ids.reduce((prev, now) => ({ ...prev, [now]: cart.data[now.toString()] }), {})
                : cart.data;
            const payload_data = AppCartBasePrepare(cart_payload_data_prepare, !isAuth);
            const group_payload_data =
                is_cart_page && isAuth
                    ? AppGroupCartBasePrepare({
                          reduxItems: redux_plain_data,
                          payloadItems: payload_data,
                      })
                    : payload_data;
            console.log(is_cart_page, group_payload_data);
            const is_redux_empty = redux_data.length === 0;
            const page_loading = is_cart_page
                ? is_redux_empty
                    ? true
                    : hash.length > 0
                    ? true
                    : reduxState.length !== Object.keys(cart.data).length
                : false;
            const syncConfig: AppCartSyncConfig = {
                method: 'put',
                cart_hash: hash,
                cart_snapshot: cart.data,
                payload_data: group_payload_data,
                loading_config: {
                    initial_loading: page_loading,
                    mutation_loading: (payload_data || []).map((item) => item.product_id),
                },
            };

            Loader.cart.on({
                payload: { ...syncConfig.loading_config },
            });

            console.log('is_auth:', Boolean(isAuth));

            if (isAuth) {
                if (!is_cart_page) {
                    if (payload_data && payload_data.length > 0) {
                        syncDebounce({
                            ...syncConfig,
                            method: 'put',
                        });
                        return;
                    }
                    syncDebounce({
                        ...syncConfig,
                        method: 'getCount',
                    });
                } else {
                    if (payload_data && payload_data.length > 0) {
                        syncDebounce({
                            ...syncConfig,
                            method: 'putAmount',
                        });
                        return;
                    }
                    syncDebounce({
                        ...syncConfig,
                        loading_config: { initial_loading: true, mutation_loading: [] },
                        method: 'get',
                    });
                }
                return;
            }

            // regular edit
            if (payload_data.length > 0) {
                if (is_cart_page) {
                    syncDebounce({
                        ...syncConfig,
                        method: 'postByProducts',
                    });
                    return;
                }
                setCart({ status: is_cart_page ? CART_STATUSES.synced : CART_STATUSES.presync });
                Loader.cart.off();
                return;
            }
            flushAppCart();
            Loader.cart.off();

            // // NO AUTH USERS ...
            // if (cart.deleteId) {
            //     deleteAppCartItem(cart, cart.deleteId);
            //     return;
            // }
            //
        },
        [
            isAuth,
            location.pathname,
            reduxState.length,
            redux_data.length,
            redux_plain_data,
            setCart,
            syncDebounce,
        ]
    );
    // ---------------------------------------------------------------- USE EFFECT

    React.useEffect(() => {
        console.log('=========================================');
        console.log('useEffect-1', cart.status, Object.keys(cart.data).length, reduxState.length);
        if (
            cart.status === CART_STATUSES.presync &&
            location.pathname === '/cart'
            // && Object.keys(cart.data).length !== reduxState.length
        ) {
            setCart({ status: CART_STATUSES.updating });
        }
    }, [cart.data, cart.status, isAuth, location.pathname, reduxState.length, setCart]);

    React.useEffect(() => {
        console.log('useEffect-1-restoreCart', isAuth);
        if (cart.status !== CART_STATUSES.initial && isAuth === CART_AUTH_STATUS_TOKEN) {
            // console.log('')
            restoreCart();
        }
    }, [isAuth]);

    React.useEffect(() => {
        console.log('useEffect-2-restoreCart-syncCart', cartSelectedHash);
        if (cart.status === CART_STATUSES.initial) {
            restoreCart();
            return;
        }
        if (cart.status === CART_STATUSES.restore || cart.status === CART_STATUSES.updating) {
            syncCart(cart, cartMutationsIds, cartSelectedHash);
            return;
        }
        // код вызываемый каждый раз при изминение корзины
    }, [cart, cartMutationsIds, restoreCart]);

    const value = {
        // state - selectors
        cart,
        cart_loadings,
        cartItemByOffer,
        cartItemsByProduct,
        cartCounter,
        // methods
        flushAppCart,
        deleteCartGroupByHash,
        //
        cartEdit,
        cartSnapshotEdit,
        cartCheckoutSync,
    };
    return (
        <AppCartServiceContext.Provider value={value}>
            {props.children}
        </AppCartServiceContext.Provider>
    );
};

const useCartService = () => React.useContext(AppCartServiceContext) as AppCartServiceInstance;

export { AppCartServiceProvider, useCartService };
