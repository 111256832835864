import { CaseReducer, PayloadAction, current } from '@reduxjs/toolkit';
import {
    IAddToCollectionPayload,
    ICollectionEditProps,
    ICollectionOverlayFormProps,
    ICollectionProducts,
    IFetchProductsPayload,
    IWishlistCollection,
    IWishlistPayload,
    IWishlistProductId,
    WishlistInitialState,
} from 'modules/Wishlist/types';

const wishlistDataSave: CaseReducer<
    WishlistInitialState,
    PayloadAction<{ products: any; pagination: any; isFlush?: boolean }>
> = (state, action) => {
    const { payload } = action;
    const { products, pagination = {}, isFlush } = payload;

    if (isFlush) {
        state.wishlistProducts = [];
        state.pagination = {};
    }

    // const normalizedProducts = products?.map((product: any) => ({
    //     category_ids: product?.categoryIds,
    //     company_id: product?.companyId,
    //     image_url: product?.imageUrl,
    //     price: product?.price,
    //     base_price: product?.basePrice,
    //     product_id: product?.productId,
    //     product_type: product?.productType,
    //     title: product?.title,
    // }));

    if (!isFlush && products) {
        const current_state = current(state);
        const oldProducts = current_state?.wishlistProducts || [];

        const saveProducts =
            current_state?.pagination?.current_page !== pagination?.current_page ? products : [];

        state.wishlistProducts = [...oldProducts, ...saveProducts];
        state.pagination = pagination;
    }
};
const flushWishlist: CaseReducer<WishlistInitialState> = (_state, _action): any => {};
const clearReduxWishlist: CaseReducer<
    WishlistInitialState,
    PayloadAction<{
        clearAll?: boolean;
    }>
> = (state, action) => {
    const {
        payload: { clearAll = false },
    } = action;
    state.products = [];
    state.pagination = {};
    clearAll && (state.wishlistProductIds = {});
};

const fetchWishlistData: CaseReducer<WishlistInitialState, PayloadAction<any>> = (
    _state,
    _action
): any => {};

const setWishlistModalState: CaseReducer<WishlistInitialState, PayloadAction<any>> = (
    state,
    action
) => {
    const { payload } = action;
    state.wishlistClearModal = payload;
};
const toggleItemInWishlist: CaseReducer<WishlistInitialState, PayloadAction<IWishlistPayload>> = (
    _state,
    _action
): any => {};
const toggleItemInWishlistSucceed: CaseReducer<
    WishlistInitialState,
    PayloadAction<{
        isInFavorite: boolean;
        product: IWishlistPayload;
    }>
> = (state, action): any => {
    const {
        payload: { isInFavorite, product },
    } = action;
    if (isInFavorite) {
        delete state.wishlistProductIds[product?.product_id];
        return;
    }
    state.wishlistProductIds = {
        [product?.product_id]: { product_id: product?.product_id },
        ...state.wishlistProductIds,
    };
    state.lastEditedItem = product?.product_id;
};
const fetchWishlistProductIds: CaseReducer<WishlistInitialState> = (_state): any => {};
const fetchWishlistProductIdsSucceed: CaseReducer<
    WishlistInitialState,
    PayloadAction<IWishlistProductId[]>
> = (state, action): any => {
    state.wishlistProductIds = action.payload?.reduce(
        (accumulator: any, currentValue: any) => ({
            ...accumulator,
            [currentValue?.productId]: { product_id: currentValue?.productId },
        }),
        {}
    );
};

// ==========================================================================================================
const getWishlistData: CaseReducer<
    WishlistInitialState,
    PayloadAction<{
        full?: boolean;
    }>
> = (_state): any => {};
const getCollectionProducts: CaseReducer<
    WishlistInitialState,
    PayloadAction<IFetchProductsPayload>
> = (_state): any => {};

const fetchCollectionsSucceed: CaseReducer<
    WishlistInitialState,
    PayloadAction<{ collections: IWishlistCollection[] }>
> = (state, action) => {
    const {
        payload: { collections },
    } = action;
    state.collections = collections;
    const defaultCollection = collections?.find((collection) => collection.is_default);
    state.wishlistProductIds =
        collections?.length > 0 && defaultCollection
            ? defaultCollection?.product_ids?.reduce(
                  (accumulator: any, currentValue: any) => ({
                      ...accumulator,
                      [currentValue]: { product_id: currentValue },
                  }),
                  {}
              )
            : {};
};
const fetchProductsSucceed: CaseReducer<
    WishlistInitialState,
    PayloadAction<{ collection_id: string; data: any; products: ICollectionProducts }>
> = (state, action) => {
    const {
        payload: { collection_id, data },
    } = action;

    // state.products = { [collection_ids]: data.products };

    state.products =
        state.products?.length === 0 || data.pagination.currentPage === 1
            ? data.products
            : [...state.products, ...data.products];
    state.pagination = data.pagination;
};
const collectionFormMobileOverlaySet: CaseReducer<
    WishlistInitialState,
    PayloadAction<ICollectionOverlayFormProps>
> = (_state, _action) => {
    _state.mobile_collection_from_overlay = _action.payload;
};

const createNewCollection: CaseReducer<
    WishlistInitialState,
    PayloadAction<{ name: string; pre_created?: boolean; product_id?: number }>
> = (_state, _action) => {};
const addItemToCollection: CaseReducer<
    WishlistInitialState,
    PayloadAction<IAddToCollectionPayload>
> = (_state, _action): any => {};
const setToBeAddedItem: CaseReducer<WishlistInitialState, PayloadAction<number>> = (
    _state,
    _action
) => {
    _state.pendingItem = _action.payload;
};
const editWishlistCollection: CaseReducer<
    WishlistInitialState,
    PayloadAction<ICollectionEditProps>
> = (_state, _action) => {};
const deleteWishlistCollection: CaseReducer<
    WishlistInitialState,
    PayloadAction<{
        ids: string[];
    }>
> = (_state, _action) => {};
export default {
    wishlistDataSave,
    clearReduxWishlist,
    fetchWishlistData,
    setWishlistModalState,
    toggleItemInWishlist,
    fetchWishlistProductIds,
    fetchWishlistProductIdsSucceed,
    flushWishlist,
    toggleItemInWishlistSucceed,
    //-----------------------//
    getWishlistData,
    collectionFormMobileOverlaySet,
    createNewCollection,
    addItemToCollection,
    setToBeAddedItem,
    getCollectionProducts,
    fetchCollectionsSucceed,
    fetchProductsSucceed,
    editWishlistCollection,
    deleteWishlistCollection,
};
