/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import {
    Box,
    Display,
    FlexBox,
    Icon,
    Image,
    LoadingIndicator,
    Space,
    Text,
    usePyatka,
} from 'pyatka-ui';
import { IProductReview } from 'modules/ReviewService/types';
import { AppLink, Rating } from 'components';
import { useIntersectionObserver } from 'pages/ProductsList/useIntersectionObserver';
import { useLoader } from 'contexts/useLoader';
import { Loader } from 'plugins/loader';
import { useI18n } from 'hooks/useI18n';
import LightBoxModal from 'pages/NewProductDetailPage/components/LightBoxModal';
import { ImagesWrapper } from 'components/ReviewModal/ReviewModal.LayoutProduct';

// export const generateReviewsTitle = (reviewsCount: number) => {
//     const exceptions = [0, 11, 12, 13, 14];
//     const res = `${reviewsCount} отзыв`;
//
//     if (exceptions.includes(reviewsCount)) {
//         return res + 'ов';
//     }
//
//     const last_digit = String(reviewsCount).slice(-1);
//
//     if (last_digit === '1') {
//         return res;
//     }
//
//     if (Number(last_digit) >= 2 && Number(last_digit) <= 4) {
//         return res + 'a';
//     }
//
//     return res + 'ов';
// };

const INTERSECTION_CONFIG = {
    root: null,
    rootMargin: '0px',
    threshold: 0.25,
};

//TODO: убрать scroll ref с мобилки
const ReviewBlock: React.FC<any> = (props) => {
    //TODO: change default values to more default :D
    const {
        pagination,
        reviews,
        reviewInfo: info,
        getProductReviews,
        product_id,
        product_type,
        reviewScroll,
        image_collection = [],
    } = props;

    const { scales } = usePyatka();

    const isMobile = scales?.matched?.some((match: any) => match?.name === 'md');

    const displayReviews: IProductReview[] = React.useMemo(() => {
        if (Array.isArray(reviews)) {
            return isMobile ? reviews.slice(0, 3) : reviews;
        }
        return [];
    }, [isMobile, reviews]);

    const { lastPage } = pagination;

    const scrollRef = React.useRef(null);

    const isIntersecting = useIntersectionObserver(scrollRef, INTERSECTION_CONFIG);

    const [curPage, setCurPage] = React.useState(1);

    const [lightboxData, setLightboxData] = React.useState<any>({
        images: [],
        isOpen: false,
        imgIdx: 0,
    });

    const { isLoading } = useLoader();
    const { $t } = useI18n();
    const isReviewLoading = isLoading(Loader.productReviews.indicator);

    React.useEffect(() => {
        if (isIntersecting && !isReviewLoading && displayReviews.length && curPage < lastPage) {
            setCurPage((p) => p + 1);
        }
    }, [isIntersecting]);

    //TODO !!! RE CHECK
    React.useEffect(() => {
        if (curPage > 1) {
            getProductReviews({ id: product_id, params: { page: curPage } });
        }
    }, [curPage]);

    const { reviewsCount, avgRate } = info;

    const showSkeleton = !Array.isArray(reviews) && curPage < 2;
    const showLoadingIndicator = curPage !== 1 && isReviewLoading;

    const showAllReviewsButton = isMobile && reviews?.length > 3;
    const hasReviews = Number(reviewsCount) > 0;

    const showImageCollection = Boolean(image_collection?.length);

    return (
        <FlexBox
            ref={reviewScroll?.ref}
            bg="block.main"
            alignItems="center"
            flexDirection="column"
            padding={{ default: '18px', sm: '36px', md: '36px 0' }}
            borderRadius={{ default: '0px', sm: '32px' }}
            margin={{ default: '0px -18px', sm: '0px' }}
        >
            <LightBoxModal
                is_open={lightboxData.isOpen}
                handleClose={() => setLightboxData({ ...lightboxData, isOpen: false })}
                galleryPhotos={lightboxData.images}
                first_open={lightboxData.imgIdx}
                show_dots={false}
                is_bounded={true}
            />
            <Box width="100%">
                <FlexBox justifyContent="space-between" alignItems="center" mb="12px">
                    <Text
                        // @ts-ignore
                        as="h2"
                        m={0}
                        fontWeight="700"
                        color="text.main"
                        skeleton={showSkeleton}
                        fontSize={{ default: '18px', sm: '21px', md: '26px' }}
                        lineHeight={{ default: '23.4px', sm: 'auto' }}
                        skeletonProps={{
                            width: '120px',
                            height: '20px',
                            marginRight: '20px',
                            borderRadius: '15px',
                        }}
                    >
                        {$t('reviews')}
                    </Text>

                    {showAllReviewsButton && (
                        <Display sm={true} xxl={false}>
                            <AppLink
                                to={{ name: 'reviews-page', params: { product_id, product_type } }}
                            >
                                <FlexBox
                                    py="0px"
                                    pr="4px"
                                    pl="10px"
                                    gap="4px"
                                    width="100%"
                                    height="26px"
                                    alignItems="center"
                                    borderRadius="999rem"
                                    justifyContent="center"
                                    backgroundColor="block.secondary"
                                >
                                    <Text
                                        fontSize="12px"
                                        fontWeight={500}
                                        color="text.main"
                                        lineHeight="15.36px"
                                    >
                                        {$t('see_all')}
                                    </Text>
                                    <Icon icon="arrowRight" width="16px" />
                                </FlexBox>
                            </AppLink>
                        </Display>
                    )}
                </FlexBox>

                <FlexBox
                    pt={{ default: '0px', sm: '8px' }}
                    pb={{ default: '6px', sm: '12px' }}
                    justifyContent="space-between"
                    flexDirection="row"
                    alignItems="center"
                >
                    <FlexBox
                        flex={1}
                        width="100%"
                        flexDirection="column"
                        maxWidth={{ default: 'auto', sm: '168px' }}
                    >
                        <FlexBox alignItems="center" gap="4px" mb={{ default: '3px', sm: '8px' }}>
                            <Icon icon="star-filled" width="21px" height="21px" />
                            <Text
                                ml="2px"
                                color={Number(avgRate) === 0 ? 'text.secondary' : 'text.main'}
                                fontSize={{ default: '26px', sm: '40px' }}
                                lineHeight={{ default: '33.28px', sm: '40px' }}
                                fontWeight={700}
                                itemScope={hasReviews}
                                itemProp={hasReviews ? 'aggregateRating' : undefined}
                                itemType={
                                    hasReviews ? 'https://schema.org/AggregateRating' : undefined
                                }
                            >
                                {Number(avgRate) === 0 ? 0 : Number(avgRate).toFixed(1) || ''}

                                {hasReviews && (
                                    <>
                                        <meta itemProp="reviewCount" content={reviewsCount} />
                                        <meta
                                            itemProp="ratingValue"
                                            content={Number(avgRate).toFixed(1)}
                                        />
                                    </>
                                )}
                            </Text>
                            <Text
                                fontSize={{ default: '16px', sm: '18px', md: '20px' }}
                                lineHeight={{ default: '20.48px', sm: '16px' }}
                                fontWeight={500}
                                color="text.placeholder"
                            >
                                {' / 5'}
                            </Text>
                        </FlexBox>
                        <Text
                            fontWeight={500}
                            color="text.placeholder"
                            fontSize={{ default: '14px', sm: '18px' }}
                            lineHeight={{ default: '18.2px', sm: '24px' }}
                        >
                            {$t('reviews').toLowerCase()}: {reviewsCount}
                        </Text>
                    </FlexBox>

                    {showImageCollection && (
                        <Box my="12px" overflow="auto" maxWidth={{ default: '204px', sm: '414px' }}>
                            <ImagesWrapper pt="0px">
                                {image_collection.map((image: string, imgIdx: number) => (
                                    <Image
                                        key={image}
                                        src={image}
                                        width="64px"
                                        height="64px"
                                        borderRadius="16px"
                                        backgroundSize="cover"
                                        onClick={() =>
                                            setLightboxData({
                                                isOpen: true,
                                                images: image_collection,
                                                imgIdx,
                                            })
                                        }
                                    />
                                ))}
                            </ImagesWrapper>
                        </Box>
                    )}
                </FlexBox>

                {!displayReviews.length && (
                    <Text color="text.secondary" marginTop={{ default: '22px', sm: '26px' }}>
                        {$t('no_reviews_left')}
                    </Text>
                )}

                {displayReviews.map((review: IProductReview, index: number) => (
                    <ProductReview key={index} {...review} setLightboxData={setLightboxData} />
                ))}
            </Box>

            <Display sm={false} xxl={true}>
                <div
                    ref={scrollRef}
                    style={{
                        height: '0',
                        width: '100%',
                    }}
                />
            </Display>

            {showLoadingIndicator && (
                <>
                    <Space height="10px" />
                    <LoadingIndicator />
                    <Space height="40px" />
                </>
            )}
        </FlexBox>
    );
};

const itemProps = (name: string) => {
    const itemProp = {
        dignity: 'positiveNotes',
        limitations: 'negativeNotes',
    };
    return itemProp[name as keyof typeof itemProp] || undefined;
};

interface ProductReviewProps extends IProductReview {
    setLightboxData: React.Dispatch<any>;
    images?: string[];
}

export const ProductReview: React.FC<ProductReviewProps> = ({
    author = '',
    date = '',
    rate = 0,
    values = [],
    company,
    images = [],
    setLightboxData,
}) => {
    const { $t } = useI18n();
    const { scales } = usePyatka();

    const ICON_PX = scales?.isMobile ? 28 : 36;

    return (
        <FlexBox
            itemScope
            itemProp="review"
            itemType="https://schema.org/Review"
            py={{ default: '14px', sm: '26px' }}
            px={{ default: '18px', sm: '26px', md: 0 }}
            flexDirection="column"
            bg={{ default: 'block.secondary', md: 'block.main' }}
            borderRadius={{ default: '24px', md: 0 }}
            borderTop={{ md: '2px solid rgba(190, 190, 182, 0.20)' }}
            mt="12px"
        >
            {images.length > 0 && (
                <ImagesWrapper pt="0px" pb="12px" marginLeft={scales?.isMobile ? '0' : '0px'}>
                    {images.map((image: string, imgIdx: number) => (
                        <Image
                            key={image}
                            src={image}
                            width="90px"
                            height="90px"
                            borderRadius="12px"
                            backgroundSize="cover"
                            onClick={() => setLightboxData({ isOpen: true, images, imgIdx })}
                        />
                    ))}
                </ImagesWrapper>
            )}

            <ReviewHeader author={author} date={date} rate={rate} company={company} />

            <FlexBox
                width="100%"
                alignItems="flex-start"
                justifyContent="space-between"
                mt={values.length > 0 ? '12px' : 0}
            >
                <Box>
                    {values.map(({ name, icon, value }, idx: number) => (
                        <FlexBox
                            key={idx}
                            flexDirection="column"
                            itemProp={itemProps(name)}
                            itemScope={Boolean(itemProps(name))}
                            marginTop={idx !== 0 ? { default: '6px', sm: '10px' } : 0}
                            itemType={itemProps(name) ? 'https://schema.org/ItemList' : undefined}
                        >
                            <FlexBox
                                gap="16px"
                                itemScope={Boolean(itemProps(name))}
                                itemProp={itemProps(name) ? 'itemListElement' : undefined}
                                alignItems="center"
                                itemType={
                                    itemProps(name) ? 'https://schema.org/ListItem' : undefined
                                }
                            >
                                {itemProps(name) && (
                                    <>
                                        <meta itemProp="position" content="1" />
                                        <meta itemProp="name" content={String(value)} />
                                    </>
                                )}

                                {name === 'is_recommended' && (
                                    <Icon icon={icon} height={ICON_PX} width={ICON_PX} />
                                )}
                                <Box
                                    fontSize={{ default: '14px', sm: '18px' }}
                                    lineHeight={{ default: '25.6px', sm: '26px' }}
                                    fontWeight={500}
                                    color="text.main"
                                >
                                    {name !== 'is_recommended' && (
                                        <Text
                                            fontWeight="600"
                                            lineHeight="18.2px"
                                            // @ts-ignore
                                            as="span"
                                            style={{
                                                display: scales?.isMobile ? 'block' : 'initial',
                                            }}
                                        >
                                            {$t(name)}:{' '}
                                        </Text>
                                    )}
                                    {!scales?.isMobile && <br />}
                                    {$t(value?.toString())}
                                </Box>
                            </FlexBox>
                        </FlexBox>
                    ))}
                </Box>
            </FlexBox>
        </FlexBox>
    );
};

const UserInfo: React.FC<Pick<IProductReview, 'author' | 'company'>> = ({ author, company }) => {
    const { $t } = useI18n();
    return (
        <FlexBox flexDirection="column" gap="4px">
            <Text
                lineClamp={1}
                color="text.main"
                fontWeight={600}
                fontSize={{ default: '16px', sm: '22px' }}
                lineHeight={{ default: '20.48px', sm: '28px' }}
                itemType="https://schema.org/Person"
                itemProp="author"
                itemScope
            >
                {author}
                <meta itemProp="name" content={author} />
            </Text>
            {/* TODO: add functionality: turn on filter by company onClick */}
            <Text
                fontWeight={500}
                color="text.secondary"
                lineHeight={{ default: '18.2px' }}
                fontSize={{ default: '14px', sm: '16px' }}
            >
                {$t('purchased_in')} {company?.name}
            </Text>
        </FlexBox>
    );
};

const ReviewHeader: React.FC<Pick<IProductReview, 'author' | 'date' | 'rate' | 'company'>> = ({
    author,
    date,
    rate,
    company,
}) => (
    <FlexBox width="100%" justifyContent="space-between">
        <FlexBox width="100%">
            <UserInfo author={author} company={company} />
        </FlexBox>
        <FlexBox gap="8px" alignItems="flex-end" flexDirection="column">
            <Rate rate={Number(rate)} />
            <Text
                lineHeight="18.2px"
                color="text.secondary"
                fontWeight={{ default: 500, md: 600 }}
                fontSize={{ default: '14px', md: '16px' }}
            >
                {date}
            </Text>
        </FlexBox>
    </FlexBox>
);

const Rate: React.FC<{ rate: number }> = ({ rate }) => {
    const { scales } = usePyatka();

    const ICON_PX = (scales?.isMobile ? 16 : 24) + 'px';

    return (
        <Box itemProp="reviewRating" itemType="https://schema.org/Rating" itemScope>
            <Rating
                rating={rate}
                width={ICON_PX}
                height={ICON_PX}
                secondaryColor="rgba(190, 190, 182, 0.40)"
            />
            <meta itemProp="bestRating" content="5" />
        </Box>
    );
};

export default ReviewBlock;
