import { createSelector } from 'reselect';
import { AppRootState } from 'store/types';
import { formatterPromotionDetails } from 'modules/Promotions/utils';

const promotionsReducer = (state: AppRootState) => state.PromotionsReducer;

const selectorPromotionsState = createSelector([promotionsReducer], (state) => state);

export const selectorPromotionsList = createSelector(
    [selectorPromotionsState],
    (state) => state.promotions
);
export const selectorPromotionsDetails = createSelector([selectorPromotionsState], (state) =>
    formatterPromotionDetails(state.promotionDetails)
);

export const selectorPromotionModal = createSelector(
    [promotionsReducer],
    (state) => state.promotion_modal_data
);
