import React from 'react';
import { Box, FlexBox, Image, Space, Text } from 'pyatka-ui';
import { useI18n } from 'hooks/useI18n';
import appstore from 'shared/assets/appstore.png';
import playmarket from 'shared/assets/playmarket.png';
import IphoneJusanWrapper from './components/IphoneJusanWrapper';
import QrWrapper from './components/QrWrapper';

const color = '255,72,16';

export const QrRedirectLayout: React.FC<{ qr: string; title: string }> = ({ qr, title = '' }) => {
    const { $t } = useI18n();

    return (
        <React.Fragment>
            <IphoneJusanWrapper>
                <FlexBox flexDirection={'column'}>
                    {title ? (
                        <React.Fragment>
                            <Text
                                color={'text.main'}
                                variant={'h1'}
                                dangerouslySetInnerHTML={{ __html: title }}
                            />
                        </React.Fragment>
                    ) : (
                        <Text color={'text.main'} fontWeight={'bold'} variant={'h1'}>
                            {$t('buy_in_jusan_mobile_app')}
                        </Text>
                    )}
                    <Space height={'30px'} />
                    <QrWrapper color={color} src={qr} width="50%" height="auto" />
                    <Space height={'45px'} />
                </FlexBox>
                <FlexBox
                    flexDirection={'column'}
                    backgroundColor={'#eaeaea'}
                    borderRadius={'15px'}
                    // m={'-15px'}
                    p={'20px'}
                >
                    <Text fontSize={'16px'} color={'#737373'}>
                        {$t('please_download_jusan_app_to_continue_shopping')}
                    </Text>
                    <Space height={'15px'} />
                    <FlexBox gap={'5px'}>
                        <Box style={{ minWidth: '135px', maxHeight: '40px' }}>
                            <a
                                href="https://apps.apple.com/kz/app/jusan/id1441936010"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <Image src={appstore} appearance={'29.6%'} />
                            </a>
                        </Box>
                        <Box style={{ minWidth: '135px', maxHeight: '40px' }}>
                            <a
                                href="https://play.google.com/store/apps/details?id=kz.tsb.app24&hl=ru&gl=US"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <Image src={playmarket} appearance={'29.6%'} />
                            </a>
                        </Box>
                    </FlexBox>
                </FlexBox>
            </IphoneJusanWrapper>
        </React.Fragment>
    );
};
