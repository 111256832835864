import { ApiGwRequest } from 'services';
import { CITIES_GET_REQ_INDICATOR } from 'keys';

export const getCities = () => {
    return ApiGwRequest({
        indicator: CITIES_GET_REQ_INDICATOR,
        url: 'company/v1/cities',
        method: 'get',
    });
};
export const getCityPolygon = (cityId: number) => {
    return ApiGwRequest({
        // indicator: CITIES_GET_REQ_INDICATOR,
        url: `dictionary/v1/cities/${cityId}`,
        method: 'get',
    });
};
