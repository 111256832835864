import { combineReducers } from 'redux';
import { all, fork } from 'redux-saga/effects';
import { state } from 'modules/initialState';
import * as reducers from './rootReducer';
import * as watchers from './rootSaga';

export const rootReducer = combineReducers(reducers);

/*
 *  Saga watchers Collector
 * */
export const getInitialState = (pathname = '/') => {
    return {
        ...state,
    };
};

export function* rootSaga() {
    yield all(
        Object.entries(watchers).map(([_key, watcher]) => {
            if (watcher) {
                return fork(watcher);
            }
        })
    );
}
