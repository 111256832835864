import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import {
    IAuthEmailPasswordPayload,
    IAuthInitPayload,
    IAuthOtpPayload,
} from 'modules/Authorization/v2/types';
import { Loader } from 'plugins/loader';

import { getAuthData } from 'modules/Authorization/v2/utils';
import { AppToaster } from 'plugins/app-toaster';
import {
    authInitiateOtpService,
    authVerifyOtpService,
    authWithEmailPasswordService,
} from 'modules/Authorization/v2/Authorization.services';
import { Dialog } from 'plugins/dialog';
import { saveGWUserProfileData } from 'modules/UserProfileService/UserProfileService.actions';
import { isNative } from 'utils/platform';
import { getUserData } from 'modules/Authorization/v2/Authorization.selectors';
import { saveUserAuthData } from 'data';
import { RemoveApiInstancesHeader } from 'services';
import {
    AuthorizationDataFlush,
    AuthorizationFailed,
    AuthorizationInit,
    AuthorizationOtpFailed,
    AuthorizationOtpSucceed,
    AuthorizationVerifyOtp,
    AuthorizationDataSave,
    AuthorizationLogout,
    AuthorizationDataFetch,
} from 'modules/Authorization/v2/Authorization.actions';
import { nativeCall } from 'hybrid';

function* authInitHandler(action: { payload: IAuthInitPayload }) {
    const { type = '', params } = action.payload;
    if ('token' in params && params?.token) {
        yield put(
            AuthorizationDataSave({
                ...params,
                ttl: 587255,
                errors: {},
            })
        );
    }
    Loader.authBasic.on({ global: false });
    try {
        switch (type) {
            case 'otp': {
                yield call(initOtpHandler, params as IAuthOtpPayload);
                break;
            }
            case 'email': {
                yield call(authByEmailHandler, params as IAuthEmailPasswordPayload);
                break;
            }
            case 'mobile': {
                // @ts-ignore
                const res = yield call(nativeCall, { type: 'auth', payload: {} });
                if (res?.response?.token) {
                    yield put(
                        AuthorizationDataSave({
                            token: res.response.token,
                            ttl: 587255,
                            errors: {},
                        })
                    );
                }
                break;
            }
            default: {
                break;
            }
        }
    } catch (e) {
        AppToaster.warning({
            body: 'Ошибка авторизации',
        });
    } finally {
        Loader.authBasic.off();
    }
}

function* initOtpHandler(params: IAuthOtpPayload) {
    try {
        const { data } = yield call(authInitiateOtpService, params);
        yield put(AuthorizationOtpSucceed(data?.data));
    } catch (e) {
        console.log(e);
        yield put(AuthorizationOtpFailed(e));
    }
}

function* verifyOtpHandler(action: { payload: IAuthOtpPayload }) {
    console.log('### verifyOtpHandler', action);

    const { payload } = action;
    Loader.authBasic.on({ global: false });
    try {
        const {
            data: { data },
        } = yield call(authVerifyOtpService, payload);
        yield put(AuthorizationDataSave({ ...data?.tokens?.auth, onAuthEvent: true }));
    } catch (e) {
        yield put(AuthorizationDataFlush());
        yield put(AuthorizationFailed(e));
    } finally {
        Loader.authBasic.off();
    }
}

function* authByEmailHandler(params: IAuthEmailPasswordPayload) {
    try {
        const {
            data: { data },
        } = yield call(authWithEmailPasswordService, params);
        yield put(AuthorizationDataSave({ ...data?.tokens?.auth, onAuthEvent: true }));
        Dialog.closeDialog();
    } catch (e) {
        yield put(AuthorizationDataFlush());
        yield put(AuthorizationFailed(e));
    }
}

function* authDataSaveHandler(action: any) {
    const {
        payload: { fetchData = true },
    } = action;
    if (fetchData) {
        yield put(AuthorizationDataFetch());
    }
    // yield put(getGWProfileData());
    if (!isNative() && __BROWSER__) {
        const userAuthData = yield select(getUserData);
        saveUserAuthData(userAuthData);
    }
}

function* authLogoutHandler(_action: any) {
    RemoveApiInstancesHeader('Authorization');
    yield put(AuthorizationDataFlush());
    yield put(saveGWUserProfileData({ user: {}, profiles: [] }));
}

function* authRestoreHandler() {
    const authData = getAuthData();
    console.log('### authRestoreHandler', authData);
    if (authData?.token) {
        yield put(AuthorizationDataSave(authData));
    }
}

export default function* () {
    yield all([
        takeLatest(AuthorizationInit, authInitHandler),
        takeLatest(AuthorizationDataSave, authDataSaveHandler),
        takeLatest(AuthorizationVerifyOtp, verifyOtpHandler),
        takeLatest(AuthorizationLogout, authLogoutHandler),
        // takeLatest(Aut)
        // takeLatest(AuthorizationRestore, authRestoreHandler),
        // takeLatest(AuthorizationDataFetch, )
    ]);
}
