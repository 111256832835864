import React from 'react';
import { Button, Text } from 'pyatka-ui';
import { useI18n } from 'hooks/useI18n';
import { ProductCardLayoutProps } from 'components/ProductCard/types';
import { useAppRouter } from 'router/context';

export const SimilarProductsButton: React.FC<Pick<ProductCardLayoutProps, 'category_ids'>> = (
    props
) => {
    const { category_ids = [] } = props;
    const { $t } = useI18n();
    const { appRedirect } = useAppRouter();
    const appLink = {
        name: 'home-page',
        query: { category_id: category_ids[category_ids.length - 1] },
        exact: true,
    };
    return (
        <Button
            onClick={() => appRedirect(appLink)}
            styleType="contained"
            borderRadius="999rem"
            width={'auto'}
            border={0}
            height={'34px'}
            bg={'block.secondary'}
            padding={{ default: '8px 16px', md: '6px 8px' }}
        >
            <Text
                fontSize={{
                    default: '3',
                    md: '0.9375em',
                }}
                lineHeight={{ default: '17.92px', md: '23.4px' }}
                fontWeight={600}
                color={'text.main'}
                skeletonProps={{
                    width: '100%',
                    height: '1em',
                    borderRadius: '12px',
                }}
            >
                {$t('similar').toCapitalizeFirstLetter()}
            </Text>
        </Button>
    );
};
