import React from 'react';
import { Box, Button, FlexBox, Space, Text, usePyatka } from 'pyatka-ui';
import { useSelector } from 'react-redux';
import { ProductsListLayoutFilterProps } from 'pages/ProductsList/types';
import {
    FILTER_INDEX_PRICE,
    FILTER_STYLE_CHECKBOX,
} from 'modules/ProductsList/ProductsList.helpers';
import { ProductsListFilters } from 'modules/ProductsList/ProductsList.selectors';
import { SearchServiceFilters } from 'modules/SearchService/SearchService.selectors';
import { getMerchantFilters } from 'modules/Merchants/Merchants.selectors';
import { useI18n } from 'hooks/useI18n';
import { useDebounce } from 'hooks';
import { AppMetricEvents } from 'AppMetric';
import { StyledSticky } from './ProductsList.styles';
import { FilterCheckboxVariant, FilterPrice, FilterSearch } from './components';
import { addRemoveFeature } from './utils';

export const ProductsListMobileFilters: React.FC<ProductsListLayoutFilterProps> = ({
    feature_id = '',
    filter_style,
    variants: initial_variants,
    handlePrice,
    updateFilters,
    closeButtonHandler,
    is_search_page = false,
    is_merchant_page = false,
}) => {
    const isFirstRun = React.useRef(true);
    const rangePrice = React.useRef<number[]>([]);
    const isCheckBox = filter_style === FILTER_STYLE_CHECKBOX;

    /* --------------------------------- Filters -------------------------------- */
    const { activeFilters = {} }: any = useSelector(
        is_search_page
            ? SearchServiceFilters
            : is_merchant_page
            ? getMerchantFilters
            : ProductsListFilters
    );

    const [input, setInput] = React.useState('');
    const [modalFilters, setModalFilters] = React.useState(activeFilters);

    /* ------------------ Memoize variants from the input value ----------------- */
    const variants = React.useMemo(
        () =>
            isCheckBox
                ? initial_variants.filter((f: any) =>
                      f.variant.toString().toLowerCase().includes(input.toLowerCase())
                  )
                : initial_variants,
        [initial_variants, input, isCheckBox]
    );

    /* --------------------------- Update search input -------------------------- */
    const updateInput = (value: string) => setInput(value);

    const debouncedFilters: any = useDebounce<any>(modalFilters, 750);

    /* ---------------------------- Debounce filters ---------------------------- */
    React.useMemo(() => {
        !isFirstRun.current && updateFilters(debouncedFilters);

        if (isFirstRun.current) {
            isFirstRun.current = false;
        }
    }, [debouncedFilters, updateFilters]);

    const onCheckboxClick = (option: any, isActive?: boolean) => {
        setModalFilters((prev: any) => addRemoveFeature(option, feature_id, prev));
        if (!isActive) {
            AppMetricEvents.jmart_filter_option_click({
                feature_id,
                variant: option?.variant,
                variant_id: option?.variant_id,
            });
        }
    };

    const resetFeature = () => {
        setModalFilters(({ [feature_id]: _, ...newFilters }) => newFilters);
    };

    const onApplyPrice = () => {
        if (rangePrice.current.length === 2) {
            handlePrice(Number(rangePrice.current[0]), Number(rangePrice.current[1]));
        }
        closeButtonHandler();
    };

    return (
        <Box minHeight={isCheckBox ? '70vh' : 'initial'}>
            {/* Input search in variants */}
            {isCheckBox && (
                <>
                    <Box
                        pt="15px"
                        left={18}
                        right={18}
                        height="57px"
                        position="absolute"
                        backgroundColor="block.main"
                    >
                        <FilterSearch
                            input={input}
                            isEmpty={!variants.length}
                            // @ts-ignore
                            updateInput={updateInput}
                        />
                    </Box>
                    <Space height="57px" />
                </>
            )}

            <FlexBox mt="23px" gap="22px" flexDirection="column">
                {isCheckBox ? (
                    variants?.map((option: any) => (
                        <FilterCheckboxVariant
                            key={option?.variant_id}
                            option={option}
                            inStock={Boolean(option?.count)}
                            isChecked={Boolean(
                                modalFilters[`${feature_id}`] &&
                                    modalFilters[`${feature_id}`][option?.variant_id]
                            )}
                            onCheckboxClick={onCheckboxClick}
                        />
                    ))
                ) : (
                    <FilterPrice
                        isMobile
                        rangePrice={rangePrice}
                        handlePrice={handlePrice}
                        activePrice={
                            activeFilters[`${FILTER_INDEX_PRICE}`]
                                ? Object.values(activeFilters[`${FILTER_INDEX_PRICE}`])
                                : [variants.min, variants.max]
                        }
                        variants={variants}
                    />
                )}
            </FlexBox>
            <Space height="30px" />

            <ApplyButton onApply={onApplyPrice} onReset={resetFeature} />
        </Box>
    );
};

const ApplyButton: React.FC<{
    onApply: () => void;
    onReset: () => void;
}> = ({ onApply, onReset }) => {
    const { $t } = useI18n();
    const { theme } = usePyatka();
    return (
        <StyledSticky theme={theme} isSticky>
            <FlexBox flex="1" gap="12px">
                <Button
                    py="18px"
                    height="55px"
                    onClick={onReset}
                    bg="block.secondary"
                    borderRadius="22px"
                    styleType="contained"
                    style={{ flex: '50%' }}
                >
                    <Text fontSize="16px" fontWeight={500} lineHeight="20.48px" color="text.main">
                        {$t('reset')}
                    </Text>
                </Button>

                <Button
                    py="18px"
                    height="55px"
                    variant="brand"
                    onClick={onApply}
                    borderRadius="22px"
                    styleType="contained"
                    style={{ flex: '50%' }}
                >
                    <Text fontSize="16px" fontWeight={600} lineHeight="20.48px">
                        {$t('apply')}
                    </Text>
                </Button>
            </FlexBox>
        </StyledSticky>
    );
};
