import React from 'react';
import { VerificationModalLayout } from './VerificationModal.Layout';
import { VerificationModalProps } from './types';

export const VerificationModalContainer: React.FC<VerificationModalProps> & {
    Layout: typeof VerificationModalLayout;
} = (props) => {
    const { children, ...layoutProps } = props;
    return (
        <React.Fragment>
            <VerificationModalLayout {...layoutProps}>{children}</VerificationModalLayout>
        </React.Fragment>
    );
};

VerificationModalContainer.Layout = VerificationModalLayout;
