import { AxiosInstance } from 'axios';
import { RequestAxiosModel } from 'services/types';
import Interceptors from 'services/interceptors';
import { createApiInstance, headers } from './instance';

const ApiGw: AxiosInstance = createApiInstance({ baseURL: __API_GW_URL__ });
ApiGw.interceptors.request.use(Interceptors.onRequest, Interceptors.onRequestError);
ApiGw.interceptors.response.use(Interceptors.onResponse, Interceptors.onResponseError);

const ApiBase: AxiosInstance = createApiInstance({ baseURL: __BASE_URL__ });
ApiBase.interceptors.request.use(Interceptors.onRequest, Interceptors.onRequestError);
ApiBase.interceptors.response.use(Interceptors.onResponse, Interceptors.onResponseError);

const ApiFormDataInstance = createApiInstance({
    baseURL: __API_GW_URL__,
    headers: { ...headers, 'Content-Type': 'multipart/form-data' },
});
ApiFormDataInstance.interceptors.request.use(Interceptors.onRequest, Interceptors.onRequestError);
ApiFormDataInstance.interceptors.response.use(
    Interceptors.onResponse,
    Interceptors.onResponseError
);

export const ReSetApiInstanceHeaders = (headers: object) => {
    ApiGw.defaults.headers = { ...ApiGw.defaults.headers, ...headers };
    ApiBase.defaults.headers = { ...ApiBase.defaults.headers, ...headers };
    ApiFormDataInstance.defaults.headers = { ...ApiFormDataInstance.defaults.headers, ...headers };
};

export const SetApiInstancesHeader = (key: string, value: string) => {
    ApiGw.defaults.headers[`${key}`] = value;
    ApiBase.defaults.headers[`${key}`] = value;
    ApiFormDataInstance.defaults.headers[`${key}`] = value;
};

export const RemoveApiInstancesHeader = (key: string) => {
    delete ApiGw.defaults.headers[`${key}`];
    delete ApiBase.defaults.headers[`${key}`];
    delete ApiFormDataInstance.defaults.headers[`${key}`];
};

const ApiGwRequest = (config: RequestAxiosModel) => {
    return ApiGw(config);
};

const ApiBaseRequest = (config: RequestAxiosModel) => {
    return ApiBase(config);
};

const ApiFormDataRequest = (config: RequestAxiosModel) => {
    return ApiFormDataInstance(config);
};

export { ApiGwRequest, ApiBaseRequest, ApiFormDataRequest };
