import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import {
    AppErrorInfo,
    AppI18n,
    AppI18nResponse,
    AppInitialState,
    IAppInitPayload,
} from 'modules/App/types';
import { saveSelectedLanguageStorage, setAgeVerify } from 'data';
import { isNative } from 'utils/platform';
import InitialState from './App.state';

const AppInit: CaseReducer<AppInitialState, PayloadAction<IAppInitPayload>> = () => {};

const SetAuthModalStatus: CaseReducer<
    AppInitialState,
    PayloadAction<{
        open: AppInitialState['authModal']['open'];
        page_case?: AppInitialState['authModal']['page_case'];
    }>
> = (state, action) => {
    const { payload } = action;
    state.authModal.open = payload.open;
    state.authModal.page_case = payload.page_case || 'otp';
};

const SetAppError: CaseReducer<AppInitialState, PayloadAction<AppErrorInfo>> = (state, action) => {
    const { payload } = action;
    state.appError = { ...payload, status: payload?.status ? payload?.status : 1 };
};

const CleanAppError: CaseReducer<AppInitialState> = (state) => {
    state.appError = InitialState.appError;
};

const SetAgeVerificationModalStatus: CaseReducer<
    AppInitialState,
    PayloadAction<{
        age_limit: AppInitialState['ageVerificationModal']['age_limit'];
        age_warning_message: AppInitialState['ageVerificationModal']['age_warning_message'];
    }>
> = (state, action) => {
    //age_verification
    const { payload } = action;
    state.ageVerificationModal.age_limit = payload.age_limit;
    state.ageVerificationModal.age_warning_message = payload.age_warning_message;
};

const SetAgeVerification: CaseReducer<AppInitialState, PayloadAction<{}>> = (state, action) => {
    const { payload } = action;
    state.ageVerification = payload;
};
const deleteAgeVerificationData: CaseReducer<AppInitialState> = (state) => {
    state.ageVerification = {
        userVerified: state.ageVerification.userVerified,
    };
};

const SetUserVerified: CaseReducer<AppInitialState, PayloadAction<{}>> = (state, action) => {
    const { payload } = action;
    state.ageVerification.userVerified = payload;
};

const SetCategoryVerification: CaseReducer<AppInitialState, PayloadAction<{ category_id: any }>> = (
    state,
    action
) => {
    const { payload } = action;
    const { category_id } = payload;
    // eslint-disable-next-line security/detect-object-injection
    state.categoryVerification[category_id] = true;
};

const UnSetCategoryVerification: CaseReducer<
    AppInitialState,
    PayloadAction<{ category_id: any }>
> = (state, action) => {
    const { payload } = action;
    const { category_id } = payload;
    // eslint-disable-next-line security/detect-object-injection
    state.categoryVerification[category_id] = false;
};

const setLoading: CaseReducer<AppInitialState, PayloadAction<{}>> = (state, action) => {
    const { payload } = action;

    if (__BROWSER__) {
        //@ts-ignore
        state.loading = payload;
    }
};

const setI18nActiveLanguage: CaseReducer<
    AppInitialState,
    PayloadAction<{ language: AppI18n['activeLanguage'] }>
> = (state, action) => {
    const { payload } = action;
    state.i18n.activeLanguage = payload?.language || 'ru';
    if (__BROWSER__) {
        saveSelectedLanguageStorage(payload?.language);
        !isNative() && window.location.reload();
    }
};

const setI18nTranslationsSucceed: CaseReducer<
    AppInitialState,
    PayloadAction<{ data: AppI18nResponse }>
> = (state, action) => {
    const { payload } = action;
    const translations: AppI18n['translations'] = {
        [<string>state.i18n.activeLanguage]: {
            words: payload.data.data,
        },
    };

    state.i18n.translations = { ...state.i18n.translations, ...translations };
};

const setDialogIsOpen: CaseReducer<AppInitialState, PayloadAction<boolean>> = (state, action) => {
    const { payload } = action;
    state.isDialogOpen = payload;
};

const setIsClosingJmart: CaseReducer<AppInitialState, PayloadAction<boolean>> = (state, action) => {
    const { payload } = action;
    state.isClosingJmart = payload;
};

const setIsAgeModal: CaseReducer<AppInitialState, PayloadAction<AppInitialState['isAgeModal']>> = (
    state,
    action
) => {
    const { payload } = action;
    state.isAgeModal = payload;
};

const setVerifyAge: CaseReducer<AppInitialState, PayloadAction<AppInitialState['verifyAge']>> = (
    state,
    action
) => {
    const { payload } = action;
    if (payload) {
        setAgeVerify(payload);
        state.verifyAge = payload;
    }
};

export default {
    AppInit,
    SetAuthModalStatus,
    SetAgeVerificationModalStatus,
    SetAgeVerification,
    SetUserVerified,
    deleteAgeVerificationData,
    SetCategoryVerification,
    UnSetCategoryVerification,
    SetAppError,
    CleanAppError,
    setLoading,
    setI18nActiveLanguage,
    setI18nTranslationsSucceed,
    setDialogIsOpen,
    setIsClosingJmart,
    setIsAgeModal,
    setVerifyAge,
};
