import React, { useCallback } from 'react';
import { Image, Text } from 'pyatka-ui';
import { AppLink } from 'components';
import { CategoryCardLayoutProps } from 'components/CategoryCard/types';
import { AppMetricEvents } from 'AppMetric';
import css from './CategoryCard.module.scss';

export const CategoryCardLayout: React.FC<CategoryCardLayoutProps> = ({
    icon,
    title,
    cId,
    url,
    customTo,
}) => {
    const customToLink = useCallback(() => {
        if (customTo !== undefined) {
            return customTo;
        }
        return false;
    }, [customTo]);

    const generateLink = () => {
        const customLink = customToLink();
        const mainLink = cId
            ? { name: 'home-page', query: { category_id: cId }, exact: true }
            : url || '';
        return customLink ? customLink : mainLink;
    };

    const isBlackFriday = (id: any) => {
        return Number(id) === 29090;
    };

    const dispatchCategoryMetric = () => {
        cId && AppMetricEvents.jmart_category({ category_id: cId, category_name: title });
    };

    return (
        <div className={css.wrapper}>
            <AppLink dark to={generateLink()}>
                <div
                    className={`${css.cardWrapper} ${isBlackFriday(cId) ? css.blackFriday : ''}`}
                    //style={categoryCardStyle()}
                    onClick={dispatchCategoryMetric}
                >
                    <div className={'m-0 '} style={{ position: 'relative', paddingRight: '40%' }}>
                        <div className={css.titleWrapper}>
                            <Text as="p" fontWeight="normal" className={css.title}>
                                {title}
                            </Text>
                        </div>
                        <div className={css.imageWrapper}>
                            <Image src={icon} style={{ height: '70px' }} />
                        </div>
                    </div>
                    {/*<div>*/}
                    {/*    <Image height={'70%'} src={!isBlackFriday(cId) ? icon : watermelon} />*/}
                    {/*</div>*/}
                    {/*<div className={'d-flex align-items-center m-0 align-items-center'}>*/}
                    {/*    <div style={{ border: '1px solid' }}>*/}
                    {/*        <Text as={'p'} className={css.title}>*/}
                    {/*            {title}*/}
                    {/*        </Text>*/}
                    {/*    </div>*/}
                    {/*    <div style={{ border: '1px solid' }}>*/}
                    {/*        <Image height={'70%'} src={!isBlackFriday(cId) ? icon : watermelon} />*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>
            </AppLink>
        </div>
    );
};
