import React from 'react';
import { Box } from 'pyatka-ui';
import { useSelector } from 'react-redux';
import { getVerifyAge } from 'modules/App/App.selectors';
import { ProductCardContainerProps } from './types';
import BlurOverlay from './components/BlurOverlay';

type IWithBlur = {
    isBlurred: boolean;
};

export function withBlur<P extends ProductCardContainerProps>(
    WrappedComponent: React.ComponentType<P & IWithBlur>
) {
    const BlurredComponent = (props: P) => {
        const isBlurred = useSelector((state) =>
            getVerifyAge(state, [props.is_for_adult, props.is_degree_high, props.is_degree_low])
        );

        if (isBlurred) {
            return (
                <Box position="relative">
                    <WrappedComponent {...props} isBlurred={isBlurred} />
                    <BlurOverlay
                        is_for_adult={props.is_for_adult}
                        is_degree_low={props.is_degree_low}
                        is_degree_high={props.is_degree_high}
                    />
                </Box>
            );
        }

        return <WrappedComponent {...props} isBlurred={isBlurred} />;
    };

    return BlurredComponent;
}

export default withBlur;
