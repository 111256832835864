import React from 'react';
import { Box, Button, FlexBox, Icon, LoadingIndicator, Modal, Text, Textarea } from 'pyatka-ui';
import styled, { keyframes } from 'styled-components';
import { ApiBaseRequest } from 'services';
import { useI18n } from 'hooks/useI18n';
import { nativeConfigs } from 'utils/platform';
import { AppMetricEvents } from 'AppMetric';
import { chatGTPView } from 'data';

interface ChatGPTProps {
    inView: boolean;
    show: boolean;
    title: string;
    image: string;
    product_id: string;
}

// [JCTEAM-5314] настроить отображение чата только для списка appsflyer_id
// const test_users = [
//     '1677077582072-6956592',
//     '1653404053123-5823879',
//     '1635160946680-7120969',
//     '1659430001495-4774607',
//     '1691654792136-9009211340684312415',
//     '1678449230590-8279216976140896903',
//     '1660809682099-5417644126561107309',
//     '1699421898652-1696052902754040628',
//     '1688956283841-8861951429840153891',
//     '1690769283775-5532557',
//     '1688992039110-2218322434965321836',
//     '1675161254368-5092442',
//     '1690452919820-0586612',
//     '1687239955094-6940245074845665556',
//     // '',
// ];

const ChatGPT: React.FC<ChatGPTProps> = ({ inView, show, product_id, title = '', image }) => {
    const [isOpen, setIsOpen] = React.useState(false);
    const [message, setMessage] = React.useState('');
    const [{ isLoading, ref, messages, resetChat }, sendMsg] = useChatGPT();
    const [key, setKey] = React.useState(0);

    const isBtnDisabled = message.trim().length <= 3 || isLoading;
    const config = nativeConfigs();
    const session_id = String(config?.appsflyer_id || '');

    // const isSegmentB = config?.appsflyer_segment === 'B';
    // const hasUser = test_users.some((el) => el === session_id);

    const handleSend = () => {
        sendMsg({ message, product_id, session_id });
        AppMetricEvents.jmart_ai_chat({ type: 'send', payload: { product_id } });
        setMessage('');
        setKey(Date.now());
    };

    const handleClose = () => {
        setIsOpen(false);
        AppMetricEvents.jmart_ai_chat({ type: 'close', payload: { product_id } });
        resetChat();
        setKey(Date.now());
    };

    const openChat = () => {
        setIsOpen(true);
        AppMetricEvents.jmart_ai_chat({ type: 'open', payload: { product_id } });
    };

    if (!show) {
        return null;
    }

    return (
        <>
            <FlexBox
                zIndex={10}
                cursor="pointer"
                onClick={openChat}
                minWidth="48px"
                position="fixed"
                overflow="hidden"
                alignItems="center"
                justifyContent="flex-end"
                bottom={inView ? 68 : 135}
                right={{ default: 18, md: 18 }}
                borderRadius="0 999rem 999rem 0"
                style={{ transition: 'bottom 0.3s' }}
            >
                {!chatGTPView() && (
                    <AITooltip
                        mr="-100%"
                        p="8px 10px"
                        pr="40px"
                        color="white"
                        bg="#9BD050"
                        fontSize="12px"
                        lineHeight="13.2px"
                        borderRadius="20px"
                        onAnimationEnd={() => {
                            chatGTPView('done');
                        }}
                    >
                        Привет! Я - AI консультант <br />
                        Отвечу на любые вопросы о товаре
                    </AITooltip>
                )}
                <Icon width="48px" height="48px" icon="chat-gpt" />
            </FlexBox>
            <Modal
                fullScreen
                padding="0"
                open={isOpen}
                swipeable={false}
                onClose={handleClose}
                positionMode="mobile"
                contentBackground="block.secondary"
                width="100%"
            >
                <FlexBox flexDirection="column" height="100%">
                    <FlexBox bg="block.main" padding="4px 8px" alignItems="center">
                        <Button
                            onClick={handleClose}
                            padding="10px"
                            border="none"
                            width="44px"
                            height="44px"
                        >
                            <Icon icon="arrowLeft" />
                        </Button>
                        <img src={image} width="40px" height="40px" alt="" />
                        <Box ml="12px" pr="18px">
                            <Text
                                lineClamp={1}
                                fontSize="16px"
                                fontWeight={600}
                                lineHeight="20.48px"
                            >
                                {title}
                            </Text>
                        </Box>
                    </FlexBox>
                    <FlexBox flexDirection="column" flex="1" bg="block.secondary" overflowY="auto">
                        <Box height="8px" borderRadius="0 0 16px 16px" bg="block.main" />
                        <FlexBox
                            flex="1"
                            flexDirection="column"
                            justifyContent="flex-end"
                            padding="16px 12px"
                        >
                            {messages.map((item, index) => (
                                <MessageItem key={index} mine={item.mine}>
                                    {item.children}
                                </MessageItem>
                            ))}
                            <div ref={ref} />
                        </FlexBox>
                    </FlexBox>
                    <FlexBox bg="block.main" padding="12px" alignItems="center" gap="8px">
                        <Textarea
                            placeholder="Сообщение"
                            focusOutlineColor="none"
                            maxRows={2}
                            // @ts-ignore
                            width="100%"
                            fontSize="18px"
                            padding="8px 12px"
                            borderRadius="16px"
                            bg="block.secondary"
                            id="msg-text"
                            value={message}
                            key={key}
                            onChange={({ value }) => setMessage(value)}
                        />
                        <Button
                            border="none"
                            bg="block.brand_variant"
                            borderRadius="999rem"
                            padding="10px"
                            width="40px"
                            height="40px"
                            disabled={isBtnDisabled}
                            onClick={handleSend}
                        >
                            {isLoading ? <LoadingIndicator /> : <Icon icon="send" />}
                        </Button>
                    </FlexBox>
                </FlexBox>
            </Modal>
        </>
    );
};

const chatHintAnim = keyframes`
    0% {
        margin-right: -100%;
    }
    10% {
        margin-right: -35px;
    }
    90% {
        margin-right: -35px;
    }
    100% {
        margin-right: -100%;
    }
`;

const AITooltip = styled(Box)`
    animation: ${chatHintAnim} 8.8s ease-in-out;
`;

interface IUseChatGPT {
    // isSent: boolean;
    isLoading: boolean;
    messages: MessageItemProps[];
    resetChat: () => void;
    ref: React.RefObject<HTMLDivElement>;
}

type IHandleSend = (arg: {
    message: string;
    session_id: string;
    product_id: string;
}) => Promise<void>;

const useChatGPT = (): [IUseChatGPT, IHandleSend] => {
    const { $t } = useI18n();
    const defaultMessage = {
        children: $t('chat_greeting'),
        mine: false,
    };

    const ref = React.useRef<HTMLDivElement>(null);
    // const [isSent, setIsSent] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const [messages, setMessages] = React.useState<MessageItemProps[]>([defaultMessage]);

    React.useEffect(() => {
        ref.current?.scrollIntoView({ behavior: 'smooth' });
    }, [messages]);

    const resetChat = () => {
        // setIsSent(false);
        setIsLoading(false);
        setMessages([defaultMessage]);
        // if (__BROWSER__) {
        //     const msg: HTMLTextAreaElement | null = document.querySelector('#msg-text>textarea');
        //     if (msg) {
        //         msg.disabled = false;
        //     }
        // }
    };

    const handleSend: IHandleSend = async ({ message, session_id, product_id }) => {
        setIsLoading(true);
        setMessages((res) => [...res, { children: message, mine: true }]);

        try {
            const { data } = await ApiBaseRequest({
                url: '/chat',
                method: 'POST',
                data: {
                    session_id,
                    message,
                    master_product_id: product_id,
                },
            });
            // setIsSent(true);
            setMessages((res) => [...res, { children: data, mine: false }]);

            // if (__BROWSER__) {
            //     const msg: HTMLTextAreaElement | null =
            //         document.querySelector('#msg-text>textarea');
            //     if (msg) {
            //         msg.disabled = true;
            //     }
            // }
        } catch (e) {
        } finally {
            setIsLoading(false);
        }
    };

    return [
        {
            // isSent,
            isLoading,
            messages,
            resetChat,
            ref,
        },
        handleSend,
    ];
};

interface MessageItemProps {
    children: React.ReactNode;
    mine?: boolean;
}

const MessageItem: React.FC<MessageItemProps> = ({ children, mine = false }) => (
    <FlexBox py="12px" justifyContent={mine ? 'flex-end' : 'flex-start'}>
        <Box
            maxWidth="85%"
            padding="16px"
            bg={mine ? 'block.brand_variant' : 'block.main'}
            color={mine ? 'text.inverse' : 'text.main'}
            borderRadius={mine ? '18px 18px 0px 18px' : '18px 18px 18px 0px'}
            style={{ wordBreak: 'break-word' }}
        >
            {children}
        </Box>
    </FlexBox>
);

export default ChatGPT;
