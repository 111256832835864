import React, { useMemo, useState } from 'react';
import {
    Box,
    FlexBox,
    Icon,
    Image,
    LoadingIndicator,
    Space,
    Text,
    Modal,
    Col,
    Row,
    Button,
} from 'pyatka-ui';
import { Helmet } from 'react-helmet-async';
import priceFormatter from 'utils/priceFormatter';
import { signs } from 'utils/symbols';
import { ReviewButton } from 'pages/ClientOrderDetailPage/components/ReviewButton';
import { ProductSection } from 'components';
import { useI18n } from 'hooks/useI18n';
import { useAppRouter } from 'router/context';
import { PostomatModal } from 'pages/ClientOrderDetailPage/components/PostomatModal';
import CompanyInfo from 'pages/ClientOrderDetailPage/components/CompanyInfo';
import BonusInfo from 'pages/ClientOrderDetailPage/components/BonusInfo';
import PaymentMethodInfo from 'pages/ClientOrderDetailPage/components/PaymentMethodInfo';
import ActionButton from 'components/ClientOrderDetailCard/components/ActionButton';
import { nativeCall } from 'hybrid';
import { isNative } from 'utils/platform';
import { ClientOrderDetailPageLayoutProps, StatusWrapperProps } from './types';
import { ButtonWrapper } from './ClientOrderDetailPage.styles';
import { statusColors } from './consts';

import {
    CancelOrderButton,
    DeliveryInfoWrapper,
    PriceWrapper,
    ReorderButton,
    StatusWrapper,
} from './ClientOrderWrapper.Layout';

export const QRButtonBlock = (props) => {
    const { openPostomatModal, client_debug } = props;

    return (
        <FlexBox alignItems={'flex-start'} justifyContent={'space-between'} gap={'20px'}>
            {isNative() && (
                <ActionButton
                    flexDirection={'row'}
                    width={'95px'}
                    onClick={() => openPostomatModal('qr')}
                    icon={'qr'}
                    iconBackgroundColor={'#FF7348'}
                    title={'Отсканировать QR'}
                />
            )}
            <ActionButton
                width={'72px'}
                flexDirection={'row'}
                onClick={() => openPostomatModal('code')}
                icon={'lock'}
                iconBackgroundColor={'#FF7348'}
                title={'Ввести код вручную'}
            />
            {/*<FlexBox gap={'10px'} alignItems={'center'} onClick={() => openPostomatModal('code')}>*/}
            {/*    <Icon height={'46px'} width={'46px'} fill={'#FF7348'} icon={'postomat1'} />*/}
            {/*    <Box>*/}
            {/*        <Text fontWeight={'600'} fontSize={'15px'} lineHeight={'22px'}>*/}
            {/*            {' '}*/}
            {/*            Ввести код постамата*/}
            {/*        </Text>*/}
            {/*        /!*<Text*!/*/}
            {/*        /!*    fontSize={'16px'}*!/*/}
            {/*        /!*    lineHeight={'16px'}*!/*/}
            {/*        /!*    color={'#FF7348'}*!/*/}

            {/*        /!*>*!/*/}
            {/*        /!*    /!*<span style={{ color: '#898D94' }}>или </span>*!/*!/*/}
            {/*        /!*    Ввести код постамата*!/*/}
            {/*        /!*</Text>*!/*/}
            {/*    </Box>*/}
            {/*</FlexBox>*/}

            {/*<Button*/}
            {/*    padding={'5px 12px'}*/}
            {/*    backgroundColor={'#FF7348'}*/}
            {/*    styleType={'contained'}*/}
            {/*    borderRadius={'15px'}*/}
            {/*    onClick={() => openPostomatModal('qr')}*/}
            {/*>*/}
            {/*    <Text fontSize={'12px'} lineHeight={'15px'}>*/}
            {/*        Сканировать{' '}*/}
            {/*    </Text>{' '}*/}
            {/*</Button>*/}
        </FlexBox>
    );
};

export const ClientOrderDetailPageLayout: React.FC<ClientOrderDetailPageLayoutProps> = (props) => {
    const {
        order,
        order_id,
        repeatOrder,
        isRepeatOrderLoading,
        onCancelClick,
        statuses,
        OrdersServiceInit,
        isRefundStatus,
        isClientDebug,
    } = props;
    const {
        company,
        products,
        can_canceled,
        shipping,
        can_be_paid,
        isCancelled,
        deliveryDate,
        shippingInfo,
        formatted_merchant_phone,
        status,
        cancelReasonInfo,
        company_id,
    } = order;
    const { $t } = useI18n();

    const { appRedirect } = useAppRouter();

    const onCanBePainClick = () => {
        appRedirect({
            name: 'checkout-delivery',
            params: {
                checkout_type: 'order',
                checkout_id: order.order_id,
            },
        });
    };

    const showQRBlock = status && status === 'M' && order?.delivery_type_name === 'postomat';

    /* ------------------------------------------ POSTOMAT MODAL ----------------------------------- */
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalView, setModalView] = useState<'qr' | 'code'>('qr');

    const onOpenPostomatModal = (view: 'code' | 'qr') => {
        switch (view) {
            case 'code':
                setIsModalOpen(true);
                setModalView(view);
                break;
            case 'qr':
                if (isNative()) {
                    const res = nativeCall({
                        type: 'app_qr',
                    });
                    if (res?.status === 'success') {
                        OrdersServiceInit({ order_id });
                    }
                }
        }
    };

    const closePostomatModal = () => {
        setIsModalOpen(false);
    };

    const modalProps = {
        modalView,
        closePostomatModal,
        order_id,
        OrdersServiceInit,
    };
    const statusBProps = useMemo((): StatusWrapperProps => {
        if (order.status === 'B' && order?.refundFailedInfo) {
            return {
                icon: 'back-canceled',
                backgroundColor: 'transparent',
                textColor: 'text.main',
                text: 'К возврату',
                textWeight: 600,
                description: order?.refundFailedInfo,
                descriptionColor: 'text.main',
                descriptionFontSize: '13px',
                fontSize: '16px',
            };
        }
        return {
            icon: 'back',
            backgroundColor: 'status.warning',
            textColor: 'text.secondary',
            text: 'Возврат в процессе',
            fontSize: '15px',
        };
    }, [order.status]);
    /* --------------------------------------------------------------------------------------------- */
    return (
        <React.Fragment>
            <Helmet>
                <title>{order_id ? `Заказ: ${order_id}` : ''}</title>
                <meta name="robots" content="noindex" />
            </Helmet>
            {
                <Modal
                    open={isModalOpen}
                    onClose={() => setIsModalOpen(false)}
                    fullScreen={true}
                    showCloseButton={true}
                >
                    <PostomatModal {...modalProps} />
                </Modal>
            }

            {props.isFetching && (
                <FlexBox justifyContent={'center'} alignItems={'center'} height={'80vh'}>
                    <LoadingIndicator size={'45px'} />
                </FlexBox>
            )}
            {!props.isFetching && (
                <Box>
                    <Box bg={'block.main'} borderRadius={'0 0 24px 24px'} p={'21px'}>
                        <FlexBox justifyContent={'space-between'} alignItems={'center'}>
                            {!isCancelled &&
                                props.canStatusesBeShown &&
                                statuses?.mergedArr?.map((status: any, _idx: number) => {
                                    return (
                                        // eslint-disable-next-line react/jsx-key
                                        <StatusWrapper
                                            icon={props.statusIcons?.[status?.status]}
                                            backgroundColor={statusColors?.[status?.type]}
                                            text={status?.short || status?.title}
                                            textColor={statusColors?.[status?.type]}
                                            fontSize={'11px'}
                                            width={'calc( ( 100% - 40px ) / 3 )'}
                                            flexDirection={'column'}
                                            lineClamp={1}
                                            key={status?.status}
                                        />
                                    );
                                })}
                            {!isCancelled &&
                                !isRefundStatus &&
                                !statuses?.currentStatusDone &&
                                props.isEmptyStatus &&
                                props.emptyStatusLogs.map(
                                    (emptyStatus: {
                                        color: string;
                                        icon: string;
                                        title: string;
                                    }) => (
                                        // eslint-disable-next-line react/jsx-key
                                        <StatusWrapper
                                            icon={emptyStatus.icon}
                                            backgroundColor={emptyStatus.color}
                                            text={emptyStatus.title}
                                            textColor={emptyStatus.color}
                                            fontSize={'11px'}
                                            width={'calc( ( 100% - 40px ) / 3 )'}
                                            lineClamp={1}
                                            flexDirection={'column'}
                                            key={emptyStatus.title}
                                        />
                                    )
                                )}
                        </FlexBox>

                        <FlexBox alignItems={'center'} justifyContent={'space-between'}>
                            {order?.status === 'Q' && (
                                <StatusWrapper
                                    icon={'group_3'}
                                    backgroundColor={'status.success'}
                                    text={'delivered'}
                                    textColor={'status.success'}
                                    fontSize={'13px'}
                                />
                            )}
                            {isCancelled && (
                                <>
                                    <FlexBox alignItems={'center'} gap={'16px'} width={'70%'}>
                                        <Box>
                                            <Icon icon={'cancelOrder'} />
                                        </Box>
                                        <Box style={{ wordWrap: 'break-word' }} width={'70%'}>
                                            <Text
                                                color={'text.main'}
                                                fontSize={{ default: '16px' }}
                                                fontWeight={600}
                                            >
                                                {order?.status === 'D'
                                                    ? $t('order_rejected')
                                                    : $t('order_cancelled')}
                                            </Text>
                                            {cancelReasonInfo.cancelReasonText && (
                                                <Text
                                                    color={'text.secondary'}
                                                    fontSize={{ default: '12px' }}
                                                >
                                                    {cancelReasonInfo.cancelReasonText}
                                                </Text>
                                            )}
                                        </Box>
                                    </FlexBox>
                                </>
                            )}
                            {order?.status === 'S' && (
                                <StatusWrapper
                                    icon={'tennge'}
                                    backgroundColor={'status.success'}
                                    textColor={'status.success'}
                                    text={'partial_return'}
                                    fontSize={'13px'}
                                />
                            )}
                            {order?.status === 'K' && (
                                <StatusWrapper
                                    icon={'group_3'}
                                    backgroundColor={'block.brand'}
                                    textColor={'block.brand'}
                                    text={'full_return'}
                                    fontSize={'13px'}
                                />
                            )}
                            {order?.status === 'B' && <StatusWrapper {...statusBProps} />}
                            {(statuses?.currentStatusDone || isCancelled) && (
                                <Box>
                                    <ReorderButton
                                        isRepeatOrderLoading={isRepeatOrderLoading}
                                        repeatOrder={repeatOrder}
                                    />
                                </Box>
                            )}
                        </FlexBox>
                        <Space height={'12px'} />

                        {props.hasStatuses && <Space height={'15px'} />}
                        <CompanyInfo
                            company_id={company_id}
                            company={company}
                            formatted_merchant_phone={formatted_merchant_phone}
                            company_info={order.company_info}
                        />
                    </Box>

                    {showQRBlock ? (
                        <Box p={'20px'}>
                            <QRButtonBlock
                                openPostomatModal={onOpenPostomatModal}
                                client_debug={isClientDebug}
                            />
                        </Box>
                    ) : (
                        <Space height={'18px'} />
                    )}

                    <Box
                        bg={'block.main'}
                        borderRadius={'24px'}
                        p={'21px'}
                        pt={'9px'}
                        id="shipping_info"
                    >
                        {shippingInfo && (
                            <Box pt={'10px'}>
                                <FlexBox justifyContent={'space-between'}>
                                    <Text
                                        color={'text.main'}
                                        fontSize={'16px'}
                                        fontWeight={'400'}
                                        lineHeight={'22px'}
                                    >
                                        {/*{shipping?.has_shipping ? $t('delivery') : $t('pickup')}{' '}*/}
                                        <span style={{ fontWeight: 600 }}>
                                            {shippingInfo?.title} &nbsp;
                                        </span>
                                        {shippingInfo?.shipping_date
                                            ? shippingInfo?.shipping_date
                                            : ''}
                                    </Text>

                                    {shipping?.has_shipping && shippingInfo?.shipping_interval && (
                                        <Box
                                            p={'4px 8px'}
                                            backgroundColor={'block.secondary'}
                                            borderRadius={'10px'}
                                        >
                                            <Text
                                                fontSize={{ default: '11px' }}
                                                fontWeight={'700'}
                                                color={'#AAADB2'}
                                            >
                                                {shippingInfo?.shipping_interval}
                                            </Text>
                                        </Box>
                                    )}
                                </FlexBox>
                                <Row style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Col xs={6}>
                                        {shippingInfo?.address && (
                                            <Text
                                                fontSize={'14px'}
                                                lineHeight={'18px'}
                                                color={'text.secondary'}
                                            >
                                                {shippingInfo?.address}
                                            </Text>
                                        )}
                                    </Col>
                                    <Col xs={6}>
                                        {shippingInfo?.schedule_desc && (
                                            <Text
                                                display={'inline'}
                                                textAlign={'right'}
                                                fontSize={'14px'}
                                                lineHeight={'18px'}
                                            >
                                                <Box
                                                    display={'inline-flex'}
                                                    borderRadius={'50%'}
                                                    backgroundColor={'#9BD050'}
                                                    width={'10px'}
                                                    height={'10px'}
                                                    marginRight={'4px'}
                                                />
                                                {shippingInfo?.schedule_desc}
                                            </Text>
                                        )}
                                    </Col>
                                </Row>
                                <Space height={'10px'} />
                            </Box>
                        )}

                        <FlexBox flexDirection={'column'}>
                            {products?.map((elem, idx: string | number | null | undefined) => {
                                const productSectionProps = {
                                    key: idx,
                                    price: elem?.price,
                                    title: elem?.productName,
                                    image: elem?.image,
                                    cartAmount: elem?.amount,
                                    product_id: elem?.product_id,
                                    url: elem.urlMasterId,
                                    // cartEditable: false,
                                    isLast: idx === products.length - 1,
                                    //task/CT-698: Скрыть функционал отзывов
                                    element: elem?.review ? (
                                        <ReviewButton review_info={elem.review} />
                                    ) : null,
                                };
                                return (
                                    <Box key={idx}>
                                        <ProductSection {...productSectionProps} />
                                    </Box>
                                );
                            })}
                        </FlexBox>
                        <Space height={'18px'} />
                        <FlexBox
                            flexDirection={'column'}
                            alignItems={'center'}
                            justifyContent={'center'}
                        >
                            <Box>
                                <Text
                                    fontSize={{
                                        default: '13px',
                                    }}
                                    color={'#AAADB3'}
                                >
                                    {$t('total_amount')}
                                </Text>
                            </Box>
                            <Box>
                                <Text
                                    color={'text.main'}
                                    fontSize={{
                                        default: '40px',
                                    }}
                                    fontWeight={'bold'}
                                >
                                    {priceFormatter(order?.total)}{' '}
                                    <span style={{ opacity: '0.5' }}>{signs.tg}</span>
                                </Text>
                            </Box>
                        </FlexBox>

                        {props.hasToShowPriceBlock && (
                            <>
                                <Space height={'9px'} />
                                <FlexBox justifyContent={'center'} textAlign={'center'}>
                                    {props.filteredPriceBlock.map((priceItem: any[], _idx: any) => {
                                        return (
                                            // eslint-disable-next-line react/jsx-key
                                            <PriceWrapper
                                                title={priceItem[0]}
                                                price={priceItem[1]}
                                            />
                                        );
                                    })}
                                </FlexBox>
                            </>
                        )}
                        <Space height={'18px'} />
                        <Box p={3} bg={'block.secondary'} borderRadius={'24px'}>
                            {Boolean(order?.payment_method?.payment) && (
                                <PaymentMethodInfo payment_method={order.payment_method} />
                            )}

                            {/*<Space height={'9px'} />*/}
                            {/*<DeliveryInfoWrapper*/}
                            {/*    firstTitle={'delivery'}*/}
                            {/*    secondTitle={shipping?.has_shipping ? 'by_courier' : 'pickup'}*/}
                            {/*/>*/}

                            {/*<Space height={'9px'} />*/}
                            {shipping?.has_shipping && (
                                <Box>
                                    <Space height={'9px'} />
                                    <DeliveryInfoWrapper
                                        firstTitle={'address'}
                                        secondTitle={order?.address}
                                        firstTextAlign={'start'}
                                        secondTextAlign={'end'}
                                        width={'70%'}
                                    />
                                </Box>
                            )}
                        </Box>
                        {Boolean(order?.showBonuses) && (
                            <BonusInfo
                                bonus_text={order?.bonusText}
                                bonuses_total={order?.bonuses_total}
                            />
                        )}
                        {/*{can_be_paid ? (*/}
                        {/*    <Box pt={'20px'}>*/}
                        {/*        <Button*/}
                        {/*            buttonSize={'large'}*/}
                        {/*            styleType={'contained'}*/}
                        {/*            variant={'success'}*/}
                        {/*            borderRadius={'10px'}*/}
                        {/*            onClick={onCanBePainClick}*/}
                        {/*            width={'100%'}*/}
                        {/*        >*/}
                        {/*            Продолжить покупку*/}
                        {/*        </Button>*/}
                        {/*    </Box>*/}
                        {/*) : (*/}
                        {/*    !statuses?.currentStatusDone &&*/}
                        {/*    !isCancelled && (*/}
                        {/*        <>*/}
                        {/*            <Space height={'24px'} />*/}
                        {/*            <ButtonWrapper gap={'10px'}>*/}
                        {/*                {can_canceled && (*/}
                        {/*                    <CancelOrderButton onCancelClick={onCancelClick} />*/}
                        {/*                )}*/}
                        {/*                <ReorderButton*/}
                        {/*                    isRepeatOrderLoading={isRepeatOrderLoading}*/}
                        {/*                    repeatOrder={repeatOrder}*/}
                        {/*                    width={'100%'}*/}
                        {/*                />*/}
                        {/*            </ButtonWrapper>*/}
                        {/*        </>*/}
                        {/*    )*/}
                        {/*)}*/}
                        {!statuses?.currentStatusDone && !isCancelled && (
                            <>
                                <Space height={'24px'} />
                                <ButtonWrapper gap={'10px'}>
                                    {/*{props.isClientDebug ? 'DEBUG' : 'FALSE'}*/}
                                    {can_canceled && (
                                        <CancelOrderButton onCancelClick={onCancelClick} />
                                    )}
                                    <ReorderButton
                                        isRepeatOrderLoading={isRepeatOrderLoading}
                                        repeatOrder={repeatOrder}
                                        width={'100%'}
                                    />
                                </ButtonWrapper>
                            </>
                        )}
                    </Box>
                </Box>
            )}
        </React.Fragment>
    );
};
