import React from 'react';
import { usePyatka } from 'pyatka-ui';
import styled from 'styled-components';
import { ProductLabel } from 'components';
import { Product } from 'modules/ProductsList/types';

interface IProductLabels {
    label: Product['label'];
    isLoading: boolean;
    show: boolean;
}

const ProductLabels: React.FC<IProductLabels> = ({
    show,
    label: { bonusLabel } = {},
    isLoading,
}) => {
    const { scales } = usePyatka();

    if (!show || isLoading) {
        return null;
    }

    return (
        <Labels>
            {!isLoading && bonusLabel && (
                <ProductLabel
                    variant="bonus"
                    fontSize="11px"
                    height="14px"
                    padding={scales?.isMobile ? '0px 4px' : '3px 6px'}
                    iconSize={{
                        small: '10px',
                    }}
                >
                    {bonusLabel?.text?.replace('Б', '')}
                </ProductLabel>
            )}
        </Labels>
    );
};

const Labels = styled.div`
    & [data-testid='text-wrapper'] {
        margin-right: 0;
        gap: 2px;
    }
`;

export default ProductLabels;
