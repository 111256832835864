import React, { useState } from 'react';
import { Box, FlexBox, Icon, Image, Modal, Text, usePyatka } from 'pyatka-ui';
import { useDispatch } from 'react-redux';
import {
    ModalHeader,
    ToggleStatus,
} from 'pages/WishListServicePage/v2/components/AddToCollectionModal';
import { IWishlistCollection } from 'modules/Wishlist/types';
import { useI18n } from 'hooks/useI18n';
import { Overlay } from 'pages/WishListServicePage/v2/WishlistServicePage.Styles';
import DeleteCollectionConfirmModal from 'pages/WishlistCollectionPage/components/DeleteCollectionConfirmModal';
import {
    deleteWishlistCollection,
    editWishlistCollection,
} from 'modules/Wishlist/Wishlist.actions';
import { useAppRouter } from 'router/context';
import CollectionFormModal from 'pages/WishListServicePage/v2/components/CollectionFromModal';
import EditCollectionLayout from 'pages/WishListServicePage/v2/components/EditCollectionLayout';
import { AppMetricEvents } from 'AppMetric';

interface ICollectionEditModalProps {
    open: boolean;
    onClose: () => void;
    collection: IWishlistCollection;
}

const CollectionEditModal = (props: ICollectionEditModalProps) => {
    const { open, onClose, collection } = props;
    const { scales } = usePyatka();
    const isMobile = (scales.matched || []).find((el: any) => el.name === 'md');
    const { $t } = useI18n();
    const dispatch = useDispatch();
    const { appRedirect } = useAppRouter();

    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [openRenameModal, setOpenRenameModal] = useState(false);
    const actions = [
        {
            icon: 'edit_pen',
            title: 'rename',
            isVisible: true,
            onClick: () => {
                // dispatch(
                //     collectionFormMobileOverlaySet({
                //         open: true,
                //         config: { layout_type: 'edit', collection_title: collection.name },
                //     })
                // );
                setOpenRenameModal(true);
                // dispatch(collectionFormMobileOverlaySet({ open: true, isEditCollection: true }));
            },
        },
        {
            icon: collection.pinned ? 'unpin' : 'pin',
            title: collection.pinned ? 'unpin_collection' : 'pin_collection',
            isVisible: !collection.pre_created,
            iconStyles: {
                width: '17px',
                height: '17px',
            },
            onClick: () => {
                dispatch(
                    editWishlistCollection({
                        id: collection.id,
                        pinned: !collection.pinned,
                        name: collection.name,
                    })
                );
                appRedirect({ name: 'wishlist-page' });
            },
        },
        {
            icon: 'bin',
            title: 'delete_collection',
            isVisible: !collection.pre_created,
            onClick: () => {
                setOpenDeleteModal(true);
            },
        },
    ];

    const handleDeleteAction = () => {
        setOpenDeleteModal(false);
        AppMetricEvents.jmart_delete_collection();
        dispatch(deleteWishlistCollection({ ids: [collection.id] }));
        appRedirect({ name: 'wishlist-page' });
    };
    const handleRenameAction = (name: string) => {
        dispatch(
            editWishlistCollection({
                id: collection.id,
                pinned: collection.pinned,
                name,
            })
        );
        appRedirect({ name: 'wishlist-page' });
    };
    const handleClose = () => {
        onClose();
        setOpenRenameModal(false);
        setOpenDeleteModal(false);
    };
    return (
        <>
            <Modal
                open={open}
                onClose={handleClose}
                positionMode={isMobile ? 'mobile' : 'default'}
                width={isMobile ? '100%' : '50%'}
                customHeader={isMobile ? <ModalHeader /> : undefined}
                padding={isMobile ? '8px 0 0' : '20px 0 '}
                borderRadius={isMobile ? '24px 24px 0 0' : '24px'}
                swipeable
                // contentBackground={'#F6F6F5'}
            >
                {!isMobile && openRenameModal ? (
                    <Box px={20}>
                        <EditCollectionLayout
                            layout_type={'edit'}
                            collection_title={collection.name}
                            onClose={handleClose}
                            onSubmitForm={handleRenameAction}
                        />
                    </Box>
                ) : (
                    <FlexBox
                        flexDirection={'column'}
                        gap={'8px'}
                        backgroundColor={'block.secondary'}
                    >
                        <FlexBox
                            backgroundColor={'block.main'}
                            borderRadius={'0 0 24px 24px'}
                            padding={'0px 24px 24px'}
                            gap={'20px'}
                            alignItems={'center'}
                        >
                            <FlexBox
                                position={'relative'}
                                alignItems={'center'}
                                justifyContent={'center'}
                                width={'80px'}
                                height={'80px'}
                                padding={'10px'}
                                borderRadius={'14px'}
                                backgroundColor={
                                    collection.pre_created || collection.productCount === 0
                                        ? '#bebeb61a'
                                        : 'none'
                                }
                            >
                                <Image
                                    src={collection?.image!}
                                    width={collection.pre_created ? '60px' : '100%'}
                                    height={collection.pre_created ? '60px' : '100%'}
                                />
                                {!collection.pre_created && collection.productCount !== 0 && (
                                    <Overlay />
                                )}
                            </FlexBox>
                            <Box>
                                <Text fontWeight={700} fontSize={'18px'} lineHeight={'18px'} mb={1}>
                                    {collection?.name}
                                </Text>
                                <Text
                                    color={'text.secondary'}
                                    fontWeight={500}
                                    fontSize={'12px'}
                                    lineHeight={'15px'}
                                >
                                    {$t('personal_collection').toCapitalizeFirstLetter()}
                                </Text>
                            </Box>
                        </FlexBox>
                        {/*<Box height={'8px'} backgroundColor={'block.secondary'} />*/}
                        <FlexBox
                            backgroundColor={'block.main'}
                            borderRadius={'24px 24px 0 0'}
                            padding={'24px 24px 24px'}
                            cursor={'pointer'}
                            flexDirection={'column'}
                            gap={'16px'}
                            // onClick={}
                        >
                            {actions.map(
                                (action) =>
                                    action.isVisible && (
                                        <FlexBox
                                            key={action.icon}
                                            width={'100%'}
                                            alignItems={'center'}
                                            gap={'20px'}
                                            padding={'5px 0'}
                                            onClick={action.onClick}
                                        >
                                            <FlexBox
                                                alignItems={'center'}
                                                justifyContent={'center'}
                                                backgroundColor={'block.secondary'}
                                                width={'32px'}
                                                height={'32px'}
                                                borderRadius={'12px'}
                                            >
                                                <Icon
                                                    fill={'#AAADB2'}
                                                    icon={action.icon}
                                                    {...action.iconStyles}
                                                />
                                            </FlexBox>
                                            <Text
                                                fontWeight={500}
                                                fontSize={'16px'}
                                                lineHeight={'21px'}
                                            >
                                                {$t(action.title).toCapitalizeFirstLetter()}
                                            </Text>
                                            <FlexBox
                                                alignItems={'center'}
                                                justifyContent={'center'}
                                                backgroundColor={'block.secondary'}
                                                width={'26px'}
                                                height={'26px'}
                                                borderRadius={'50px'}
                                                marginLeft={'auto'}
                                            >
                                                <Icon icon={'arrow-right'} />
                                            </FlexBox>
                                        </FlexBox>
                                    )
                            )}
                        </FlexBox>
                    </FlexBox>
                )}
            </Modal>
            <DeleteCollectionConfirmModal
                openModal={openDeleteModal}
                closeModal={() => setOpenDeleteModal(false)}
                handleReject={() => setOpenDeleteModal(false)}
                handleAccept={handleDeleteAction}
            />
            <CollectionFormModal
                open={isMobile && openRenameModal}
                layout={'edit'}
                collection_title={collection.name}
                onClose={() => setOpenRenameModal(false)}
                onSubmitForm={handleRenameAction}
            />
        </>
    );
};

export default CollectionEditModal;
