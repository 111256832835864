/* eslint-disable react/display-name */
import React, { FC } from 'react';
import { Box, Collapse, FlexBox } from 'pyatka-ui';
import { ShowMoreButton } from 'components';
import { ISpec } from 'pages/NewProductDetailPage/types';
import { IUseScrollTo } from 'hooks/useScrollTo';
import { useI18n } from 'hooks/useI18n';
import { SpecList } from '../components/ProductDescription';

const ProductSpecs: FC<IProductSpecs> = ({ specifications = [], scrollHelper, isLoading }) => {
    const [showSpec, setShowSpec] = React.useState(false);
    const specRef = React.useRef<HTMLDListElement>(null);
    const { $t, language } = useI18n();

    const toggleSpec = () => setShowSpec((prev) => !prev);

    const all_spec = language === 'ru' ? 'Все характеристики' : 'Барлық сипаттамалар';

    return (
        <Box
            ref={scrollHelper?.ref}
            bg="block.main"
            borderRadius={{ default: 0, md: '24px' }}
            padding={{ default: '0 18px', md: 4 }}
            margin={{ default: '0px -18px', md: '18px 0 0 0' }}
        >
            <Collapse
                open={showSpec}
                initialHeight={`${showSpec ? specRef.current?.clientHeight : 210}px`}
            >
                <SpecList
                    isLoading={isLoading}
                    ref={specRef}
                    list={
                        isLoading
                            ? new Array(6).fill('1')
                            : showSpec
                            ? specifications
                            : specifications.slice(0, 4)
                    }
                />
            </Collapse>
            {!isLoading && (
                <ShowMore collapsed={showSpec} toggleCollapse={toggleSpec}>
                    {!showSpec ? all_spec : $t('hide')}
                </ShowMore>
            )}
        </Box>
    );
};

// Show more button
const ShowMore: FC<{ collapsed: boolean; toggleCollapse: () => void }> = ({
    collapsed,
    toggleCollapse,
    children,
}) => (
    <FlexBox
        justifyContent="center"
        mt={{
            default: 3,
            md: '26px',
        }}
    >
        <ShowMoreButton bg="block.secondary" collapsed={collapsed} toggleCollapse={toggleCollapse}>
            {children}
        </ShowMoreButton>
    </FlexBox>
);

interface IProductSpecs {
    specifications: ISpec[];
    scrollHelper?: IUseScrollTo;
    isLoading: boolean;
}

export default ProductSpecs;
