import styled from 'styled-components/macro';
import { FlexBox } from 'pyatka-ui';

export const ButtonWrapper = styled(FlexBox)`
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    // background-color: white;
    z-index: 1000;
    border-radius: 26px 26px 0 0;
    justify-content: space-between;
`;
