import { AlgoliaEvents } from 'metrics';
import { ApiGwRequest } from 'services';

export const getSupermarkets = (data: any) => {
    return ApiGwRequest({
        url: 'company/v1/supermarkets',
        method: 'get',
        params: {
            ...data,
        },
    });
};

export const getSupermarket = (data: any, id: string) => {
    return ApiGwRequest({
        url: `catalog/v1/supermarkets/${id}`,
        method: 'get',
        params: {
            ...data,
        },
    });
};

export const getSupermarketSearch = ({ query }: { query: string }) => {
    const userToken = AlgoliaEvents.getToken();
    return ApiGwRequest({
        url: 'listing/v1/search/products-in-stores',
        method: 'get',
        headers: {
            [AlgoliaEvents.TOKEN_HEADER]: userToken,
        },
        params: { query },
    });
};

export const getFmcgRecommendations = ({ store_id, appsflyer_id }: any) => {
    return ApiGwRequest({
        url: `appsflyer/v1/fmcg-recommendations/${appsflyer_id}/${store_id}`,
        method: 'get',
    });
};

export const getHotPrice = ({ store_id }: any) => {
    return ApiGwRequest({
        url: `listing/v1/supermarket/categories/${store_id}`,
        method: 'get',
    });
};

export const getTestSuper = (payload: any) => {
    return ApiGwRequest({
        url: 'company/v2/company/by-ids',
        method: 'post',
        data: payload,
    });
};
