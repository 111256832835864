import React from 'react';
import { Box, FlexBox, Icon, Text } from 'pyatka-ui';
import {
    ProductItemInfoWrapper,
    StyledImage,
} from 'components/ClientOrderDetailCard/ClientOrderDetailCard.Styles';
import { useI18n } from 'hooks/useI18n';

interface IProductGiftProps {
    isMobile?: boolean;
    imageUrl: string;
    description: string;
    gift_count: number;
    isLast: boolean;
}

const ProductGift = (props: IProductGiftProps) => {
    const { isMobile, imageUrl, description, gift_count, isLast } = props;
    const { $t } = useI18n();

    return (
        <FlexBox mt={10} gap={'16px'} width={'100%'} alignItems={'center'}>
            <FlexBox alignItems={'center'} justifyContent={'center'} width={'40px'} height={'40px'}>
                {imageUrl && <StyledImage isMobile={isMobile} src={imageUrl} borderRadius={12} />}
            </FlexBox>
            <ProductItemInfoWrapper
                flexDirection={'column'}
                flex={1}
                isActual={true}
                isLast={isLast}
                gap={'10px'}
            >
                <FlexBox
                    width={'100%'}
                    gap={'5px'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                >
                    <FlexBox flexDirection={'column'} gap={'2px'}>
                        <Text
                            color={'text.secondary'}
                            fontSize={{ default: 13 }}
                            lineHeight={'17px'}
                            fontWeight={'500'}
                        >
                            {$t('gift_to_order').toCapitalizeFirstLetter()}
                        </Text>
                        <Text
                            lineClamp={2}
                            color={'text.main'}
                            fontSize={{ default: '14px', md: '16px' }}
                            lineHeight={'20px'}
                            fontWeight={500}
                        >
                            {$t(description)}
                        </Text>

                        {gift_count && (
                            <Text
                                fontSize={{ default: '13px', md: '14px' }}
                                color={'text.secondary'}
                                fontWeight={500}
                                lineHeight={'17px'}
                            >
                                {gift_count} {$t('sht')}
                            </Text>
                        )}
                    </FlexBox>

                    <Box>
                        <Icon width={'22px'} height={'22px'} icon={'gift-round'} />
                    </Box>
                </FlexBox>
            </ProductItemInfoWrapper>
        </FlexBox>
    );
};

export default ProductGift;
