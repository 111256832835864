import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { getIsAgeModal } from 'modules/App/App.selectors';
import { setIsAgeModal, setVerifyAge } from 'modules/App/App.actions';
import { PromptModal } from 'components';

const MainAppAgeModal = () => {
    const { isOpen, age } = useSelector(getIsAgeModal);
    const dispatch = useDispatch();

    const handleClose = () => {
        dispatch(setIsAgeModal({ isOpen: false, age: 0 }));
    };

    const handleOk = () => {
        handleClose();
        dispatch(setVerifyAge({ age, date: dayjs().format('YYYY-MM-DD') }));
    };

    return (
        <PromptModal
            open={isOpen}
            noTxt="age_no"
            yesTxt="age_yes"
            onSubmit={handleOk}
            onClose={handleClose}
            description="category_age_verification"
            title={age === 18 ? 'age_eighteen' : 'age_twenty_one'}
        />
    );
};

export default React.memo(MainAppAgeModal);
