import React from 'react';
import { Box, FlexBox, Space } from 'pyatka-ui';
import { ProductCard, SectionTitle } from 'components';
import { Product } from 'modules/ProductsList/types';
import { SectionTitleProps } from 'components/SectionTitle/types';
import { ProductCardListProps } from './types';
import { ProductSlider } from './ProductSlider.styles';

const ProductCardList: React.FC<ProductCardListProps> = ({
    buttonConfig = {
        buttonText: '',
        url: {},
    },
    buttonColor,
    btnStyle = 'default',
    source,
    gap,
    count,
    sectionTitle,
    appearance,
    isLoading,
    products,
    wrapperStyle,
    sliderStyle,
    cardLayout,
    desktopCardLayout,
    metric,
    labels,
    minWidth,
    titleStyle,
    show_overlay = true,
}) => {
    const sectionTitleProps: SectionTitleProps = {
        ...buttonConfig,
        sectionText: sectionTitle,
        isLoading,
        labels,
        style: titleStyle,
    };

    return (
        <FlexBox flexDirection="column">
            <SectionTitle {...sectionTitleProps} buttonColor={buttonColor} />
            <Space mb={{ default: '6px', md: '0px' }} />

            <ProductSlider
                gap={gap}
                count={count}
                minWidth={minWidth}
                appearance={appearance}
                wrapperStyle={wrapperStyle}
                sliderStyle={sliderStyle}
            >
                {products.map((product: Product, index: number) => (
                    <Box key={product?.product_id || index}>
                        <ProductCard
                            {...product}
                            index={index}
                            isLoading={Boolean(isLoading)}
                            btnStyle={btnStyle}
                            layout={cardLayout}
                            desktopLayout={desktopCardLayout}
                            appearance={appearance}
                            metric={metric}
                            show_overlay={show_overlay}
                            source={source}
                        />
                    </Box>
                ))}
            </ProductSlider>
        </FlexBox>
    );
};

export default React.memo(ProductCardList);
