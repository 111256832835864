import React from 'react';
import { Text } from 'pyatka-ui';
import { ProfileSelectLayout } from 'components/ProfileAddress/ProfileAddress.NewLayout';
import { YMaps } from 'components';
import { CheckoutDataHookOutput } from '../types';
import defaultPost from '../assets/defaultPost.svg';
import activePost from '../assets/activePost.svg';
import WarningBlock from './WarningBlock';

interface PostomatBlockProps {
    checkout_data?: CheckoutDataHookOutput['checkout_data'];
    setPostomatId?: (id: string | number) => void;
}

const PostomatBlock: React.FC<PostomatBlockProps> = (props) => {
    const { checkout_data, setPostomatId } = props;

    const postomatId = checkout_data?.postomat_selected?.postomat_id;

    const postomatVariants = React.useMemo(() => {
        const variants = checkout_data?.postomat?.variants || [];

        return variants.map((v) => ({
            title: `${v?.address?.street}, ${v?.address?.building_number}`,
            subtitle: v?.description || '',
            time: v?.schedule?.desc || '',
            value: String(v?.postomat_id),
            is_selected: v?.postomat_id === postomatId,
            location: v?.location,
        }));
    }, [checkout_data?.postomat?.variants, postomatId]);

    const activePostomat = React.useMemo(() => {
        return postomatVariants?.find((v) => v?.value === postomatId);
    }, [postomatVariants, postomatId]);

    const [modal, setModal] = React.useState(false);
    const openModal = () => setModal(true);
    const closeModal = () => setModal(false);

    if (postomatVariants?.length < 1) {
        return <WarningBlock text={'Нет точек постомат'} />;
    }

    return (
        <React.Fragment>
            <ProfileSelectLayout icon="profile-address-pin" onClick={openModal}>
                <Text lineClamp={1} style={{ wordWrap: 'break-word' }}>
                    {activePostomat?.title}
                </Text>
                <Text lineClamp={1} style={{ wordWrap: 'break-word' }} color="text.secondary">
                    {activePostomat?.subtitle}
                </Text>
            </ProfileSelectLayout>

            <YMaps
                open={modal}
                onClose={closeModal}
                points={postomatVariants}
                selectPoint={setPostomatId}
                collapseIcon="postomat_active"
                defaultIcon={defaultPost}
                activeIcon={activePost}
                type={'postamat'}
            />
        </React.Fragment>
    );
};

export default PostomatBlock;
