export const loadingProducts = Array(12).fill({
    image: '',
    productName: '',
    price: '',
    price_formatted: '',
    discountPrice: '',
    product_id: '',
    offer_id: '',
    type: '',
    label: {},
    url: {},
    amount: '',
});
