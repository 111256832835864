import React from 'react';
import { Box, FlexBox, Skeleton, Text } from 'pyatka-ui';
import { ProductLabel, WithDiscount } from 'components';
import { Product } from 'modules/ProductsList/types';
import { signs } from 'utils/symbols';
import { ProductCardLayoutProps } from 'components/ProductCard/types';

const ProductPrice: React.FC<IProductPrice & Pick<ProductCardLayoutProps, 'isZeroPrice'>> = ({
    price,
    discountPrice,
    label = {},
    isLoading,
    isZeroPrice,
}) => {
    const hasPaymentTimes = Boolean(label.installmentLabel);

    return (
        <FlexBox
            gap="8px"
            width="100%"
            mt={discountPrice ? '3px' : '4px'}
            mb={discountPrice ? { default: '3px', md: '6px' } : { default: '5px', md: '8px' }}
            alignItems={discountPrice ? 'center' : 'flex-start'}
            justifyContent="space-between"
        >
            <Box position={'relative'}>
                {discountPrice && !isLoading && (
                    <WithDiscount>
                        <Text
                            fontWeight="600"
                            fontSize={{ default: 3 }}
                            color={discountPrice ? 'text.placeholder' : 'text.placeholder'}
                        >
                            {price} {signs.tg}
                        </Text>
                    </WithDiscount>
                )}
                <Text
                    fontWeight="700"
                    skeleton={isLoading}
                    fontSize={{ default: '15px', md: '18px' }}
                    color={
                        isZeroPrice ? 'text.secondary' : discountPrice ? 'text.brand' : 'text.main'
                    }
                    skeletonProps={{
                        animation: true,
                        width: '8em',
                        height: '1.2em',
                        borderRadius: '12px',
                    }}
                >
                    {isZeroPrice ? 'Нет в наличии' : `${discountPrice || price} ${signs.tg}`}
                </Text>

                {isLoading && (
                    <Skeleton
                        animation
                        width="100%"
                        height="1.4em"
                        borderRadius={12}
                        style={{ marginTop: '12px' }}
                    />
                )}
            </Box>
            {hasPaymentTimes && !isLoading && (
                <ProductLabel
                    variant="credit"
                    fontSize={{ default: '8px', md: '12px' }}
                    padding={{ default: '3px 4px', md: '4px 6px' }}
                >
                    {label?.installmentLabel!.text}
                </ProductLabel>
            )}
        </FlexBox>
    );
};

export default ProductPrice;

interface IProductPrice {
    isLoading: boolean;
    label: Product['label'];
    price: Product['price'];
    discountPrice: Product['discountPrice'];
}
