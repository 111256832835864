import React from 'react';
import { FlexBox, Icon } from 'pyatka-ui';
import { useAppRouter } from 'router/context';
import { IconProps } from '../types';

export const GoBackBtn: React.FC<IconProps> = (props) => {
    const { fill } = props;
    const { history } = useAppRouter();
    const goBack = () => (history.length < 2 ? history.push('/') : history.goBack());

    return (
        <FlexBox onClick={goBack} alignItems="center" justifyContent="center" pr={2}>
            <Icon icon="arrowLeft" height="24px" fill={fill} />
        </FlexBox>
    );
};
