import React from 'react';
import { Col, FlexBox, Row } from 'pyatka-ui';
import { HeaderUserLocation, LanguageSelector, SearchBar } from 'components';
import UserNavigation from 'components/UserNavigation';
import DesktopMenuIcon from 'components/NewHeader/components/DesktopMenuIcon';
import { BrandNavigation, CatalogButton } from './BrandNavigation';
import { useSelector } from 'react-redux';
import { isUserAuth } from 'modules/Authorization/Authorization.selectors';

const flexZero = { flex: 0 };

const DesktopLayouts = ({
    wishListCount,
    cartTotalCount,
}: {
    wishListCount: number;
    cartTotalCount: number;
}) => {
    const isLoggedIn = useSelector(isUserAuth);

    return (
        <Row
            style={{
                padding: '0 6px',
                alignItems: 'center',
                justifyContent: 'space-between',
            }}
        >
            <Col style={flexZero}>
                <BrandNavigation />
            </Col>
            <Col style={{ padding: '0 4px', maxWidth: '780px' }}>
                <FlexBox alignItems="center" gap="10px">
                    <CatalogButton />
                    {/* @ts-ignore */}
                    <SearchBar style={{ flex: 1 }} />
                </FlexBox>
            </Col>
            <Col style={flexZero}>
                <FlexBox alignItems="center" gap="20px">
                    {/* @ts-ignore */}
                    <LanguageSelector />
                    <DesktopMenuIcon
                        icon={'favorite_filled'}
                        count={wishListCount}
                        link={'wishlist-page'}
                        title={'wishlist'}
                        showCounter={isLoggedIn && Boolean(wishListCount)}
                    />
                    <DesktopMenuIcon
                        icon={'cube'}
                        count={0}
                        link={'profile-page'}
                        title={'orders'}
                        showCounter={false}
                    />
                    <DesktopMenuIcon
                        icon={'cart'}
                        count={cartTotalCount}
                        link={'cart-page'}
                        title={'cart'}
                        showCounter={Boolean(cartTotalCount)}
                    />
                    {/* @ts-ignore */}
                    <UserNavigation />
                    <HeaderUserLocation />
                </FlexBox>
            </Col>
        </Row>
    );
};

export default React.memo(DesktopLayouts);
