import slice from 'modules/Wishlist/Wishlist.slice';

export const {
    wishlistDataSave,
    clearReduxWishlist,
    fetchWishlistData,
    setWishlistModalState,
    toggleItemInWishlist,
    fetchWishlistProductIds,
    fetchWishlistProductIdsSucceed,
    flushWishlist,
    toggleItemInWishlistSucceed,
    // ----------------------/
    getWishlistData,
    collectionFormMobileOverlaySet,
    createNewCollection,
    addItemToCollection,
    setToBeAddedItem,
    getCollectionProducts,
    fetchCollectionsSucceed,
    fetchProductsSucceed,
    editWishlistCollection,
    deleteWishlistCollection,
} = slice.actions;
