import React, { useCallback, useEffect } from 'react';
import { Display, FlexBox, Icon, Text } from 'pyatka-ui';
// import { ProfileAddress } from 'components';
import { Dialog } from 'plugins/dialog';
import { useCartService } from 'contexts/useCartService';
import { isNative } from 'utils/platform';
import { nativeAlert, nativeCall, nativeList } from 'hybrid/index';
import { AppMetricEvents } from 'AppMetric';
import { getOptionStyles } from 'pages/ProductsList/utils';
import { getSelectedCityStorage } from 'data';
import { useI18n } from 'hooks/useI18n';
import { useAppRouter } from 'router/context';
import CartClearConfirmModal from 'components/HeaderUserLocation/components/CartClearConfirmModal';
import { HeaderUserLocationLayoutProps } from './types';
import { MainGeo } from './components';

export const HeaderUserLocationLayout: React.FC<HeaderUserLocationLayoutProps> = (props) => {
    const {
        selectedCityData,
        selectedCityId,
        citiesOptions = [],
        setSelectedCity,
        city_names,
        layout = 'default',
    } = props;

    const { $t } = useI18n();
    const { location } = useAppRouter();
    const { cart, flushAppCart }: any = useCartService();

    const cartCounter = React.useMemo(() => {
        const filtered_list = Object.keys(cart.data).filter(
            (itemKey: any) => cart?.data[`${itemKey}`].amount > 0
        );
        return filtered_list.length;
    }, [cart]);

    const setCity = useCallback(
        (id: number) => {
            const c = citiesOptions.find(
                (c: { option: string; value: string }) => c?.value === id.toString()
            );
            setSelectedCity({
                id: c?.value,
                name: c?.option,
                // @ts-ignore
                flush: Number(selectedCityId) !== id,
            });
        },
        [citiesOptions, selectedCityId, setSelectedCity]
    );

    const setCityTrigger = useCallback(
        (city: { id: string; name: string; flush: boolean }) => {
            const { flush = true } = city;
            setCity(parseInt(city?.id));
            if (isNative()) {
                nativeCall({
                    type: 'citySaved',
                    payload: {
                        id: city?.id,
                        name: city?.name,
                    },
                });
            }
            if (cartCounter > 0 && flush) {
                flushAppCart();
            }
        },
        [cartCounter, flushAppCart, setCity]
    );

    const onChange = useCallback(
        (id: number) => {
            Dialog.closeDialog();
            AppMetricEvents.jmart_city_change({ city_id: id.toString() });
            const cityPayload = {
                name: city_names[id.toString()] as string,
                id: id.toString(),
                flush: true,
            };
            if (Object.keys(city_names).includes(id.toString())) {
                if (String(selectedCityId) !== String(id)) {
                    // has reason to change
                    if (cartCounter > 0) {
                        if (isNative()) {
                            const res: any = nativeAlert({
                                closable: false,
                                title: $t('current_cart_will_be_cleared_continue'),
                                message: $t('clear_cart'),
                                actions: [
                                    {
                                        title: $t('yes'),
                                        value: 'yes',
                                    },
                                    {
                                        title: $t('no'),
                                        value: 'no',
                                    },
                                ],
                            });
                            const result = res?.response.action_result;
                            if (result === 'yes') {
                                setCityTrigger(cityPayload);
                            }
                            return;
                        }
                        Dialog.openDialog({
                            body: CartClearConfirmModal,
                            props: {
                                onClose: () => {
                                    Dialog.closeDialog();
                                },
                                handleAccept: () => {
                                    setCityTrigger(cityPayload);
                                    Dialog.closeDialog();
                                },
                                handleReject: () => {
                                    Dialog.closeDialog();
                                },
                            },
                            isOwnModal: true,
                        });
                        return;
                    }
                    setCityTrigger(cityPayload);
                    return;
                }
                setCityTrigger({ ...cityPayload, flush: false });
            }
        },
        [$t, cartCounter, city_names, selectedCityId, setCityTrigger]
    );

    const formatOptions = (opts: any, selected: any) => {
        return Object.keys(opts).map((key: string) => {
            return {
                title: opts[key.toString()].option,
                value: opts[key.toString()].value,
                is_selected: opts[key.toString()].value === selected,
            };
        });
    };

    const cityClick = useCallback(() => {
        if (!(citiesOptions?.length > 0)) {
            return;
        }
        if (isNative()) {
            const res: any = nativeList({
                title: $t('choose_city'),
                closable: false,
                data: formatOptions(citiesOptions, selectedCityId),
            });
            const selected = res?.response?.selection_result;
            if (selected) {
                onChange(selected);
                return;
            }
            setSelectedCity({ id: '443' });
            return;
        }

        Dialog.openDialog({
            key: 'citySelect',
            title: $t('choose_city'),
            element: (
                <ModalBody
                    onChange={onChange}
                    cities={citiesOptions}
                    selectedCityId={selectedCityId}
                />
            ),
        });
    }, [citiesOptions, $t, onChange, selectedCityId, setSelectedCity]);

    useEffect(() => {
        const cityLocalStorage = getSelectedCityStorage();
        if (cityLocalStorage === null || cityLocalStorage?.id === null) {
            __BROWSER__ && cityClick();
        }
    }, [cityClick, selectedCityId]);

    const handleMetric = () => {
        if (location?.pathname === '/') {
            AppMetricEvents.jmart_city_change_click();
        }
    };

    if (layout === 'main') {
        return <MainGeo metric={handleMetric} onClickGeo={cityClick} />;
    }

    return (
        <React.Fragment>
            <FlexBox gap="10px" justifyContent={['end', 'start']} onClick={handleMetric}>
                <FlexBox
                    onClick={cityClick}
                    cursor="pointer"
                    alignItems="center"
                    justifyContent={{
                        default: 'none',
                        md: 'end',
                    }}
                >
                    <Display md={false}>
                        <Icon icon="geo" height="32px" />
                    </Display>
                    <Display md={true} xxl={false}>
                        <FlexBox p="7px" width="33px" justifyContent="center" alignItems="center">
                            <Icon icon="ic_geo" height="22px" fill={'text.secondary'} />
                        </FlexBox>
                    </Display>

                    <Text
                        lineClamp={1}
                        ml={{ default: '1px', md: '11px' }}
                        maxWidth={['25vw', 'auto']}
                        fontSize={{ md: '18px' }}
                        fontWeight={{ md: 500 }}
                        lineHeight={{ md: '23.4px' }}
                        color="text.secondary"
                    >
                        {selectedCityData?.cityName}
                    </Text>
                </FlexBox>
            </FlexBox>
        </React.Fragment>
    );
};

// Модалка Выбор города
const ModalBody: React.FC<{
    cities: any[];
    selectedCityId: string;
    onChange: (id: number) => void;
}> = ({ selectedCityId, cities, onChange }) => (
    <FlexBox flexDirection="column">
        {cities.map((city: any) => {
            const active = city?.value === selectedCityId;
            const styleProps = getOptionStyles(active);
            return (
                <FlexBox
                    {...styleProps}
                    key={city?.option}
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                        onChange(city?.value);
                    }}
                >
                    <Text lineClamp={1}>{city?.option}</Text>
                    {active && <Icon width="18px" fill="text.brand" icon="icon-selected" />}
                </FlexBox>
            );
        })}
    </FlexBox>
);
