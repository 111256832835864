import React, { useCallback, useEffect } from 'react';
import { Box, Button, FlexBox, Modal, Text } from 'pyatka-ui';
import YMap from 'components/YMaps/Map';
import { StyledGeoSearchResultItem } from 'components/ProfileAddressForm/v2/ProfileAddressForm.styles';
import { useI18n } from 'hooks/useI18n';
import { useScrollTo } from 'hooks';
import { YMapsProps } from 'components/YMaps/types';
import DeliveryTypeFilter from 'components/YMaps/components/DeliveryTypeFilter';

const YMapsDesktopLayout = (props: YMapsProps) => {
    const { open, onClose, points, pickupDeliveryTypes, activeFilters, toggleFilter, selectPoint } =
        props;
    const { $t } = useI18n();
    const [selectedPoint, setSelectedPoint] = React.useState<any>();
    const scrollHelper = useScrollTo();
    const handleSelectPoint = useCallback((point: any) => {
        setSelectedPoint(point);
    }, []);
    const handleClose = useCallback(() => {
        // setSelectedPoint(null);
        onClose();
    }, [onClose]);
    useEffect(() => {
        if (selectedPoint) {
            scrollHelper.scrollIntoView();
        }
    }, [scrollHelper, selectedPoint]);
    return (
        <Modal open={open} onClose={handleClose} padding={'10px'} width={'86%'}>
            <FlexBox height={'80vh'} justifyContent={'space-between'}>
                <Box p={'20px 18px 20px 30px'} width={'43%'}>
                    <Box pr={22} mb={30}>
                        <Text fontSize={'36px'} fontWeight={'700'} mb={'2px'} lineHeight={'46px'}>
                            {$t('select_pickup_point')}
                        </Text>
                        <Text
                            color={'text.secondary'}
                            fontWeight={500}
                            fontSize={'20px'}
                            lineHeight={'26px'}
                        >
                            {$t('select_point_desc')}
                        </Text>
                    </Box>
                    <DeliveryTypeFilter
                        pickupDeliveryTypes={pickupDeliveryTypes}
                        activeFilters={activeFilters}
                        toggleFilter={toggleFilter}
                    />
                    <FlexBox
                        mt={14}
                        overflowY={'auto'}
                        pr={'10px'}
                        flexDirection={'column'}
                        height="calc( 100% - 160px )"
                        gap={'1px'}

                        // maxHeight={'calc(100%-100px)'}
                    >
                        {points?.map((postomat: any) => (
                            <div
                                key={postomat?.value}
                                ref={
                                    postomat.value === selectedPoint?.value
                                        ? scrollHelper.ref
                                        : null
                                }
                            >
                                <PostomatItem
                                    key={postomat?.value}
                                    {...postomat}
                                    onItemClick={() => {
                                        selectPoint && selectPoint(postomat);
                                        setSelectedPoint(postomat);
                                    }}
                                    isActive={postomat.value === selectedPoint?.value}
                                />
                            </div>
                        ))}
                    </FlexBox>
                </Box>
                <Box width={'57%'} position="relative" overflow="hidden">
                    <YMap
                        {...props}
                        onClose={handleClose}
                        onPlacemarkClick={(point) => handleSelectPoint(point)}
                        selectedPoint={selectedPoint}
                    />
                </Box>
            </FlexBox>
        </Modal>
    );
};

export default YMapsDesktopLayout;
const PostomatItem = (props: any) => {
    const { title, subtitle, onItemClick, time, isActive } = props;
    const { $t } = useI18n();
    return (
        <StyledGeoSearchResultItem
            className={isActive ? 'active' : ''}
            p={'20px 20px 20px 30px'}
            gap="12px"
            width="100%"
            justifyContent={'space-between'}
            alignItems={'flex-start'}
            onClick={() => onItemClick(props)}
            flexDirection={'column'}
        >
            <FlexBox justifyContent={'space-between'} width={'100%'}>
                <Box width="80%">
                    <FlexBox gap="4px" flexDirection={'column'}>
                        <Text
                            lineClamp={1}
                            style={{ wordWrap: 'break-word' }}
                            fontSize={{ default: 17, md: 22 }}
                            fontWeight="500"
                            lineHeight={{ default: '20px', md: '28px' }}
                        >
                            {title}
                        </Text>
                        <Text
                            fontSize={{ default: 14, md: 18 }}
                            color="text.secondary"
                            lineHeight={{ default: '18px', md: '23px' }}
                            style={{ wordWrap: 'break-word' }}
                        >
                            {subtitle}
                        </Text>
                    </FlexBox>
                </Box>
                <Button
                    id={'postomat-btn'}
                    onClick={onItemClick}
                    borderRadius={'30px'}
                    padding={'18px 24px'}
                    fontSize={'18px'}
                    height={'54px'}
                >
                    {$t('select')}
                </Button>
            </FlexBox>
            <FlexBox alignItems="center" gap="6px">
                <Box
                    height="10px"
                    minWidth="10px"
                    backgroundColor="#9BD050"
                    borderRadius="50%"
                    mr="5px"
                />
                <Text fontSize="18px" lineHeight="17px">
                    {time}
                </Text>
            </FlexBox>
        </StyledGeoSearchResultItem>
    );
};
