import React, { useMemo } from 'react';
import { Box, Container, Display, usePyatka } from 'pyatka-ui';
import { useSelector } from 'react-redux';
import { useCartService } from 'contexts/useCartService';
import { wishlistProductsCount } from 'modules/Wishlist/Wishlist.selectors';
import { MobileLayout } from './Mobile.Layouts';
import DesktopLayouts from './Desktop.Layouts';
import { NewHeaderProps } from './types';
import styles from './NewHeader.module.scss';

export const NewHeaderLayout: React.FC<NewHeaderProps> = ({ route, parentCategory }) => {
    const { scales } = usePyatka();
    const headerLayout = route?.headerLayout || 'default';
    const { cartCounter }: any = useCartService();
    // const { wishlistCount } = useWishlist();
    const wishlistCount = useSelector(wishlistProductsCount);
    const isMobile = (scales.matched || []).find((el: any) => el.name === 'md');
    const isMobileHeaderLayout = useMemo(
        () => headerLayout === 'profile' && isMobile,
        [headerLayout, isMobile]
    );
    // const bgColor = headerLayout === 'profile' && isMobile ? 'block.secondary' : 'block.main';
    const bgColor = 'block.main';

    return (
        <Box
            bg={{
                default: bgColor,
                md: 'block.main',
            }}
            id="new-header"
            className={styles.header}
            borderBottom={isMobile ? 'none' : '2px solid #F6F6F5'}
            borderRadius={isMobileHeaderLayout ? '0 0 24px 24px' : 0}
        >
            <Container>
                {/* MOBILE */}
                <Display md={true} xxl={false}>
                    <MobileLayout route={route} parentCategory={parentCategory} />
                </Display>

                {/* DESKTOP */}
                <Display md={false}>
                    <DesktopLayouts wishListCount={wishlistCount} cartTotalCount={cartCounter} />
                </Display>
            </Container>
        </Box>
    );
};
