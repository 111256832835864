// ============================================================================================================================
// ------------------------------------------------------------ Request log indicators ----------------------------------------
// ============================================================================================================================

// ------------------------------------------------------------- Auth
export const AUTH_BASIC_REQ_INDICATOR = 'auth-basic';
// ------------------------------------------------------------- _NewMainPage
export const NEW_MAIN_PAGE_GET_REQ_INDICATOR = 'get-new-main-page';
// ------------------------------------------------------------- Categories
export const CATEGORIES_GET_REQ_INDICATOR = 'get-categories';
// ------------------------------------------------------------- Product Detail
export const PRODUCT_DETAILS_GET_REQ_INDICATOR = 'get-product-details';
export const PRODUCT_DETAILS_SEO_GET_REQ_INDICATOR = 'get-product-details-seo';
// ------------------------------------------------------------- Cities
export const CITIES_GET_REQ_INDICATOR = 'get-cities';
// ------------------------------------------------------------- Order
export const MY_ORDERS_GET_REQ_INDICATOR = 'get-my-orders';
export const REPEAT_ORDER_REQ_INDICATOR = 'repeat_order';
