import { useEffect, useRef } from 'react';

const HEADER_MOBILE_HEIGHT = 130;

export interface IUseScrollTo {
    ref: any;
    scrollTo: (offset?: number) => void;
    scrollIntoView: () => void;
}

export default (scrollCallBack?: (ref: any) => void): IUseScrollTo => {
    let ref: any = useRef(null);

    let offsetTop = 0;

    const scrollTo = (offset = HEADER_MOBILE_HEIGHT) => {
        while (ref) {
            offsetTop += ref.current?.offsetTop || ref?.offsetTop;
            ref = ref.current?.offsetParent || ref?.offsetParent;
        }
        window.scroll(0, offsetTop - offset);
    };

    const scrollIntoView = () => {
        ref?.current?.scrollIntoView({ block: 'center' });
    };

    const scrollHandler = () => {
        scrollCallBack && scrollCallBack(ref);
    };

    useEffect(() => {
        scrollHandler();
        window.addEventListener('scroll', scrollHandler);
        return () => window.removeEventListener('scroll', scrollHandler);
    }, []);

    return {
        ref,
        scrollTo,
        scrollIntoView,
    };
};
