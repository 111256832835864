import React, { FC, useMemo } from 'react';
import { Box, FlexBox, Icon, Image, Skeleton, Text } from 'pyatka-ui';
import { useParams } from 'react-router';

import { BoxProps } from 'pyatka-ui/dist/components-shared/Box/types';
import { useAppRouter } from 'router/context';
import { EMPTY_VARIANT } from './constants';

const Variations: FC<ILayout> = ({ variation, product_id, isLoading }) => {
    const { appRedirect }: any = useAppRouter();
    const { slug, product_type }: any = useParams();

    const { description, variants = [] } = variation;

    /* --------------------- Get choosen option for segment --------------------- */
    const choosen: IVariant = useMemo(
        () =>
            variants.find((item: IVariant) => item.product_id.toString() === product_id) ||
            EMPTY_VARIANT,
        [variants, product_id]
    );

    /* -------------------------- prepare Segment items ------------------------- */
    const segmentItems = useMemo(
        () =>
            Object.fromEntries(
                variants.map((item: IVariant) => [item.product_id, createSegmentItem(item.variant)])
            ),
        [variants]
    );

    const handleClick: IHandleClick = (value) => {
        if (!segmentItems[`${value}`] || String(value) === String(product_id)) {
            return;
        }

        appRedirect({
            name: 'product-detail-page',
            isReplace: true,
            params: {
                // @ts-ignore
                product_id: value,
                slug,
                product_type,
            },
        });
    };

    return (
        <Box>
            <FlexBox width={isLoading ? '65%' : '100%'} gap="10px" alignItems="center">
                <Text
                    color="text.secondary"
                    skeleton={isLoading}
                    skeletonProps={{ animation: true, mr: 2 }}
                    fontSize={{ default: '13px', md: '18px' }}
                    lineHeight={{ md: '23.4px' }}
                    fontWeight={600}
                >
                    {description}
                </Text>
                <Text
                    fontSize={{ default: '13px', md: '18px' }}
                    lineHeight={{ md: '23.4px' }}
                    skeleton={isLoading}
                    color="text.main"
                >
                    {choosen.variant}
                </Text>
            </FlexBox>
            <VariationsSkeleton isLoading={isLoading}>
                {description === 'Цвет' ? (
                    <ImageSegmentWrapper
                        choosen={choosen}
                        handleClick={handleClick}
                        variants={variants}
                    />
                ) : (
                    <DefaultSegmentWrapper
                        choosen={choosen}
                        handleClick={handleClick}
                        variants={variants}
                    />
                )}
            </VariationsSkeleton>
        </Box>
    );
};

const createSegmentItem = (text: string) => (
    <Text p={{ default: 0, md: '1px' }} fontSize={{ default: 3, md: 5 }} fontWeight={600}>
        {text}
    </Text>
);

// Skeleton animation
const VariationsSkeleton: FC<{ isLoading: boolean; children: any }> = ({ isLoading, children }) => {
    if (isLoading) {
        return (
            <FlexBox gap="12px" maxWidth={350}>
                <Skeleton width="100%" style={{ aspectRatio: '1/1' }} animation borderRadius={8} />
                <Skeleton width="100%" style={{ aspectRatio: '1/1' }} animation borderRadius={8} />
                <Skeleton width="100%" style={{ aspectRatio: '1/1' }} animation borderRadius={8} />
                <Skeleton width="100%" style={{ aspectRatio: '1/1' }} animation borderRadius={8} />
            </FlexBox>
        );
    }

    return children;
};

interface VariantWrapperProps extends Omit<BoxProps, 'color'> {
    variant?: any;
    choosen?: boolean;
    children: React.ReactNode;
    handleClick: IHandleClick;
}

const VariantWrapper: React.FC<VariantWrapperProps> = (props) => {
    const { variant, children, choosen, handleClick, ...rest } = props;
    return (
        <FlexBox
            cursor="pointer"
            textAlign="center"
            position="relative"
            alignItems="center"
            justifyContent="center"
            p={{ default: 2, md: 2 }}
            borderRadius={{ default: '18px', md: '20px' }}
            onClick={() => handleClick(variant?.product_id)}
            bg={choosen ? 'block.brand_variant' : 'block.secondary'}
            {...rest}
        >
            {children}
        </FlexBox>
    );
};

const DefaultSegmentWrapper: FC<IDefaultSegmentWrapper> = ({ choosen, handleClick, variants }) => (
    <FlexBox pt={{ default: '14px', md: '20px' }} gap="6px" scrollWidth="100%" hideScrollBar>
        {variants?.map((v) => {
            const active = choosen?.product_id === v?.product_id;
            return (
                <VariantWrapper
                    key={v?.product_id}
                    variant={v}
                    handleClick={handleClick}
                    choosen={active}
                >
                    <FlexBox
                        minWidth="36px"
                        maxWidth="100%"
                        minHeight="36px"
                        p={{ md: '10px' }}
                        alignItems="center"
                        justifyContent="center"
                        fontWeight={{ md: 600 }}
                        lineHeight={{ md: '23.4px' }}
                        fontSize={{ default: '14px', md: '18px' }}
                        color={active ? 'white' : 'text.primary'}
                    >
                        {v?.variant}
                    </FlexBox>
                </VariantWrapper>
            );
        })}
    </FlexBox>
);

const ImageSegmentWrapper: FC<IImageSegmentWrapper> = ({ choosen, handleClick, variants }) => (
    <FlexBox pt={{ default: '14px', md: '20px' }} gap="6px" scrollWidth="100%" hideScrollBar>
        {variants.map((v) => (
            <VariantWrapper
                key={v?.product_id}
                variant={v}
                handleClick={handleClick}
                width={{ default: '52px', md: '62px' }}
                height={{ default: '52px', md: '62px' }}
            >
                {choosen?.product_id === v?.product_id && (
                    <Box position="absolute" top="-5px" right="-5px" zIndex={10}>
                        <Icon icon="choosen-variant" />
                    </Box>
                )}
                <Image
                    key={v?.product_id}
                    src={v?.product?.image_url}
                    backgroundSize="cover"
                    width="100%"
                    backgroundPosition="center center"
                />
            </VariantWrapper>
        ))}
    </FlexBox>
);

interface ILayout {
    variation: IVariation;
    product_id?: string;
    isLoading: boolean;
}

type IHandleClick = (value: string | number | null | undefined) => void;

interface IDefaultSegmentWrapper {
    choosen: any;
    handleClick: IHandleClick;
    variants: any[];
}

interface IImageSegmentWrapper {
    choosen: any;
    handleClick: IHandleClick;
    variants: any[];
}

export interface IVariation {
    description: string;
    feature_id: number;
    variants: IVariant[];
}

export interface IVariant {
    product_id: number;
    variant: string;
    variant_id: number;
}

export default Variations;
