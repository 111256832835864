import React, { useMemo } from 'react';
import { Box, FlexBox, usePyatka } from 'pyatka-ui';
import { ClientOrderDetailCardLayoutProps } from 'components/ClientOrderDetailCard/types';
import StatusInfoBlock from 'components/ClientOrderDetailCard/components/StatusInfoBlock';
import CompanyInfoBlock from 'components/ClientOrderDetailCard/components/CompanyInfoBlock';

import ProductsInfoBlock from 'components/ClientOrderDetailCard/components/ProductsInfoBlock';
import TotalPriceInfoBlock from 'components/ClientOrderDetailCard/components/TotalPriceInfoBlock';

import ActionButton from 'components/ClientOrderDetailCard/components/ActionButton';
import { QRButtonBlock } from 'pages/ClientOrderDetailPage/ClientOrderDetailPage.Layout';

const ClientOrderDetailCardLayout = (props: ClientOrderDetailCardLayoutProps) => {
    const {
        order,
        statuses,
        hasToShowPriceBlock,
        filteredPriceBlock,
        repeatOrder,
        isRepeatOrderLoading,
        onCanBePainClick,
        onCancelClick,
        showQRBlock,
        onOpenPostomatModal,
        isClientDebug,
    } = props;
    const { scales } = usePyatka();
    const isMobile = (scales.matched || []).find((el: any) => el.name === 'md');

    const showActionsBlock = useMemo(
        () =>
            ((statuses?.currentStatusDone || order.isCancelled) &&
                order.order_source !== 'QR_LITE') ||
            order.can_be_paid ||
            (!statuses?.currentStatusDone && !order.isCancelled),
        [order.can_be_paid, order.isCancelled, order.order_source, statuses?.currentStatusDone]
    );
    return (
        <Box
            backgroundColor={'#F6F6F5'}
            borderRadius={isMobile ? '0 0 26px 26px' : '30px'}
            width={isMobile ? '100%' : 413}
            maxHeight={isMobile ? '100%' : '84vh'}
            overflowY={'hidden'}
        >
            <FlexBox
                flexDirection={'column'}
                gap={'8px'}
                overflowY={'auto'}
                width={'100%'}
                maxHeight={isMobile ? '100%' : '84vh'}
            >
                <StatusInfoBlock
                    statuses={statuses}
                    hasShipping={Boolean(order?.shipping?.has_shipping)}
                    isMobile={isMobile}
                />
                {showActionsBlock && (
                    <FlexBox
                        py={'16px'}
                        px={'105px'}
                        justifyContent={'center'}
                        alignItems={'flex-start'}
                        gap={'34px'}
                    >
                        {showQRBlock && (
                            <Box>
                                <QRButtonBlock openPostomatModal={onOpenPostomatModal} />
                            </Box>
                        )}
                        {(statuses?.currentStatusDone || order.isCancelled) &&
                            order.order_source !== 'QR_LITE' && (
                                <ActionButton
                                    flexDirection={'row'}
                                    onClick={repeatOrder}
                                    icon={'repeat'}
                                    isLoading={isRepeatOrderLoading}
                                    title={'reorder_button'}
                                />
                            )}
                        {isClientDebug && order.can_be_paid ? (
                            <ActionButton
                                flexDirection={'row'}
                                onClick={onCanBePainClick}
                                icon={'play'}
                                title={'continue_order'}
                            />
                        ) : (
                            !statuses?.currentStatusDone &&
                            !order.isCancelled && (
                                <>
                                    {order.order_source !== 'QR_LITE' && (
                                        <ActionButton
                                            flexDirection={'column'}
                                            onClick={repeatOrder}
                                            icon={'repeat'}
                                            title={'reorder_button'}
                                            isLoading={isRepeatOrderLoading}
                                            width={'50%'}
                                        />
                                    )}
                                    {order.can_canceled && (
                                        <ActionButton
                                            flexDirection={'column'}
                                            onClick={onCancelClick}
                                            icon={'cancel'}
                                            title={'cancel_order_button'}
                                            width={'50%'}
                                        />
                                    )}
                                </>
                            )
                        )}
                    </FlexBox>
                )}
                <CompanyInfoBlock
                    company_id={order?.company_id}
                    company={order?.company}
                    formatted_merchant_phone={order?.formatted_merchant_phone}
                    company_info={order?.company_info}
                    shipping_info={order?.shippingInfo}
                    delivery_type={order?.delivery_type_name}
                />
                <ProductsInfoBlock
                    created_at={order?.created_at}
                    order_id={order?.order_id!}
                    products={order?.products}
                    order_source={order.order_source}
                    total_price={order.total || ''}
                />
                <TotalPriceInfoBlock
                    order={order}
                    hasToShowPriceBlock={hasToShowPriceBlock}
                    filteredPriceBlock={filteredPriceBlock}
                />
            </FlexBox>
        </Box>
    );
};

export default ClientOrderDetailCardLayout;
