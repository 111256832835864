import React from 'react';
import loadable from '@loadable/component';
import { FlexBox, LoadingIndicator } from 'pyatka-ui';
import { ErrorBoundary } from 'components';
import { ProfileAddressListContainerProps } from 'components/ProfileAddressList/ProfileAddressListV2/types';

const ProfileAddressForm = loadable(() => import('./index'));

export const ProfileAddressFormAsync: React.FC<ProfileAddressListContainerProps> = (props) => (
    <ErrorBoundary>
        <ProfileAddressForm
            {...props}
            fallback={
                <FlexBox height="200px" flex="grid" justifyContent="center" alignItems="center">
                    <LoadingIndicator size="40px" />
                </FlexBox>
            }
        />
    </ErrorBoundary>
);
