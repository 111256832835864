/**
 * Страница поиска по всем супермаркетам? Да/нет
 * @param pathname
 * @returns boolean
 */
export const isSuperSearch = (pathname: string) => {
    if (pathname === '/store' || pathname === '/supermarkets_search') {
        return true;
    }
    return false;
};
