import React from 'react';
import { Space, usePyatka } from 'pyatka-ui';
import { MobileTabNavigation } from 'components';
import { EmptyLayoutLayout } from './EmptyLayout.Layout';
import { EmptyLayoutProps } from './types';

export const EmptyLayoutContainer: React.FC<EmptyLayoutProps> & {
    Layout: typeof EmptyLayoutLayout;
} = ({ children, route }) => {
    const { hasNav = false } = route;
    const { scales } = usePyatka();

    const showNav = scales?.isMobile && hasNav;

    return (
        <React.Fragment>
            <EmptyLayoutLayout>{children}</EmptyLayoutLayout>
            {showNav && (
                <>
                    <Space height="32px" />
                    <MobileTabNavigation />
                </>
            )}
        </React.Fragment>
    );
};

EmptyLayoutContainer.Layout = EmptyLayoutLayout;
