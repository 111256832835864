import { ProductDetailInitialState } from 'modules/ProductDetail/types';

const state: ProductDetailInitialState = {
    product_details: {},
    product_offers: [],
    product_payments: {},
    error: {},
    selected_payments: {},
    product_seo: null,
    product_seo_error: {},
    //TODO: initial state
    product_review_data: {
        reviews: {},
    },
    product_review_info: {},
    labels: [],
    isDetailModalOpen: false,
    productDetail: null,
    min_pirce_info: {
        id: null,
        price: null,
        discount_price: null,
    },
    similar_products: [],
};
export default state;
