import { Button, Checkbox, FlexBox } from 'pyatka-ui';
import styled from 'styled-components/macro';

export const StickyAddNewAddressWrapper = styled('div')`
    position: absolute;
    width: 100%;
    //backdrop-filter: blur(10px);
    //-webkit-backdrop-filter: blur(10px);
    background-color: #ffffff;
    z-index: 100001;
    bottom: 0;
    padding: 15px 24px;
    border-radius: 4px;
    left: 0;
    //transform: translateY(-10px);
    @media (min-width: 1200px) {
        padding-left: 39px;
        padding-right: 39px;
    }
`;

export const ProfileAddressItem = styled(FlexBox)<{
    showBlock?: boolean;
}>`
    //display: fffffflex;
    align-items: center;
    // position: relative;
    //justify-content: space-between;
    cursor: pointer;
    gap: 10px;
    transition: transform 0.3s ease-in-out;
    transform: translateX(${(props) => (props.showBlock ? '-210px' : '0')});
`;
export const StyledCheckbox = styled(Checkbox)<{
    showBlock?: boolean;
}>`
    margin: 0;
    padding: 0;
    gap: 8px;

    div {
        padding: 0;
    }
`;

export const ActionButton = styled(Button)`
    border: none;
    border-radius: 0;
    padding: 14px 33.5px;
`;
export const ActionsBlock = styled(FlexBox)<{
    showBlock?: boolean;
}>`
    position: absolute;
    right: 0;
    //display: inline-block;
    transition: transform 0.3s ease-in-out;
    transform: translateX(${(props) => (props.showBlock ? '111%' : '220%')});
`;
