import React, { FC, useState } from 'react';
import { Box, Carousel, Display, FlexBox, Icon, Image, Skeleton, Text, usePyatka } from 'pyatka-ui';
import ReactImageMagnify from 'react-image-magnify';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { getSimilarProducts } from 'modules/ProductDetail/ProductDetail.selectors';
import { useI18n } from 'hooks/useI18n';
import { LightBoxModal } from '.';

const MOBILE_SCALES = ['xs', 'sm', 'md'];
const MAX_WIDTH = {
    default: '100%',
    md: '538px',
    // md: '400px',
};

const ProductSlider: FC<IProductSlider> = ({
    isLoading = false,
    galleryPhotos,
    scrollToSimilar,
    overlaySlot,
}) => {
    const { $t } = useI18n();
    const products = useSelector(getSimilarProducts) || [];
    const [is_lightbox_open, setLightBoxOpen] = useState<boolean>(false);
    const [active, setActive] = useState(0);
    const { scales } = usePyatka();
    const showDots =
        scales?.matched && scales.matched.some((scale: any) => MOBILE_SCALES.includes(scale.name));

    const handleOpen = () => setLightBoxOpen(true);
    const handleClose = () => setLightBoxOpen(false);

    return (
        <Wrapper>
            <FlexBox flexDirection="column" position="relative" width="100%" maxWidth={MAX_WIDTH}>
                <LightBoxModal
                    first_open={active}
                    handleClose={handleClose}
                    is_open={is_lightbox_open}
                    galleryPhotos={galleryPhotos}
                    show_dots
                />
                {isLoading ? (
                    <Skeleton
                        animation
                        // @ts-ignore
                        maxWidth={MAX_WIDTH}
                        borderRadius={24}
                        style={{ aspectRatio: '1/1' }}
                    />
                ) : (
                    <Box position="relative">
                        {overlaySlot}
                        {products?.length > 0 && (
                            <FlexBox
                                gap="6px"
                                alignItems="center"
                                cursor="pointer"
                                padding="13px 16px 13px 13px"
                                bg="block.secondary"
                                position="absolute"
                                bottom={36}
                                right={{ default: 0, md: 18 }}
                                zIndex={10}
                                borderRadius="31px"
                                onClick={scrollToSimilar}
                            >
                                <Icon
                                    width="11px"
                                    height="10px"
                                    icon="chevron-down"
                                    fill="text.main"
                                />

                                <Text fontSize={{ default: '13px', md: '16px' }} fontWeight={600}>
                                    {$t('similar')}
                                </Text>
                            </FlexBox>
                        )}
                        <StyledCarousel>
                            <Carousel
                                position={active}
                                showDots={showDots}
                                carouselType="swipe"
                                handlePosition={setActive}
                                activeDotsColor="block.brand_variant"
                                defaultDotsColor="block.disabled"
                                positionDots="outside"
                                slidesToShowGroup={{ mobile: 1, tablet: 1, desktop: 1 }}
                            >
                                {galleryPhotos.length > 0 ? (
                                    galleryPhotos.map((url) => (
                                        <Box key={url} onClick={handleOpen}>
                                            {scales?.isMobile ? (
                                                <Image
                                                    src={url}
                                                    maxWidth="100%"
                                                    backgroundSize="contain"
                                                    backgroundPosition="center center"
                                                    borderRadius="18px"
                                                />
                                            ) : (
                                                <ReactImageMagnify
                                                    enlargedImagePortalId="magnifyImgId"
                                                    enlargedImageContainerStyle={{
                                                        border: '8px solid #F6F6F5',
                                                        borderRadius: '32px',
                                                    }}
                                                    enlargedImageContainerDimensions={{
                                                        width: '150%',
                                                        height: '150%',
                                                    }}
                                                    smallImage={{
                                                        alt: url,
                                                        isFluidWidth: true,
                                                        src: url,
                                                    }}
                                                    largeImage={{
                                                        src: url,
                                                        width: 1000,
                                                        height: 1000,
                                                    }}
                                                    lensStyle={{
                                                        backgroundColor: 'rgba(0,0,0,.2)',
                                                    }}
                                                />
                                            )}
                                        </Box>
                                    ))
                                ) : (
                                    <Box>
                                        <Image
                                            src=""
                                            maxWidth="100%"
                                            backgroundSize="contain"
                                            backgroundPosition="center center"
                                            borderRadius="18px"
                                        />
                                    </Box>
                                )}
                            </Carousel>
                        </StyledCarousel>
                    </Box>
                )}
                {!scales?.isMobile && <div id="magnifyImgId" className="portal" />}
                <Display md={false}>
                    <FlexBox
                        mt={4}
                        gap="10px"
                        hideScrollBar
                        scrollWidth="100%"
                        justifyContent="center"
                    >
                        {isLoading && [1, 2, 3, 4].map((idx) => <Thumbnail isLoading key={idx} />)}
                        {galleryPhotos.map((url, index) => (
                            <Thumbnail
                                key={index}
                                url={url}
                                isLoading={isLoading}
                                isActive={active === index}
                                onClick={() => setActive(index)}
                            />
                        ))}
                    </FlexBox>
                </Display>
            </FlexBox>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    width: 100%;
    & .portal {
        z-index: 99999;
        position: absolute;
        left: 100%;
        top: 0;
    }
`;

// Slider item thumbnail
export const Thumbnail: FC<IThumbnail> = ({ url, onClick, isLoading, isActive }) => (
    <FlexBox
        onClick={onClick}
        p={1}
        border={1}
        flex="0 0 66px"
        borderRadius={12}
        borderColor={isActive && !isLoading ? 'block.brand' : 'transparent'}
        transition="200ms ease"
        style={{
            cursor: 'pointer',
        }}
    >
        <Image
            src={url || ''}
            width="100%"
            backgroundSize="cover"
            backgroundPosition="center center"
            skeleton={isLoading}
        />
    </FlexBox>
);

const StyledCarousel = styled.div`
    & div[data-testid='dots'] {
        margin: 18px;
        & div {
            width: 8px;
            height: 8px;
            margin: 0 3px;
            aspect-ratio: 1/1;
        }
    }
`;

interface IProductSlider {
    isLoading: boolean;
    galleryPhotos: string[];
    scrollToSimilar: () => void;
    overlaySlot?: React.ReactNode;
}

interface IThumbnail {
    isLoading: boolean;
    isActive?: boolean;
    onClick?: () => void;
    url?: string;
}

export default ProductSlider;
