import styled from 'styled-components/macro';
import { Box } from 'pyatka-ui';
import iphoneJusan from '../assets/jusan-qr-iphone.png';

const IphoneJusanWrapper = styled(Box)`
    width: 100%;
    @media (max-width: 450px) {
        padding: 0px;
    }
    padding: 40px;
    //
    background-image: url('${iphoneJusan}');
    background-repeat: no-repeat;
    background-position: 130% 70%;
    background-size: 55%;
`;

export default IphoneJusanWrapper;
