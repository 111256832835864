import React from 'react';
import { Box, FlexBox, Image, Text } from 'pyatka-ui';
import { SpaceProps } from 'styled-system';
import { AppLink, WithDiscount } from 'components';
import { signs } from 'utils/symbols';
import { useI18n } from 'hooks/useI18n';
import { Price } from 'components/OfferBlocks/components/OfferPrice';
import {
    CartProductAmountLabel,
    ProductSectionInfoWrapper,
    ProductSectionWrapper,
} from './ProductSection.styles';
import { ProductSectionProps } from './types';

export const ProductSection: React.FC<ProductSectionProps & SpaceProps> = (props) => {
    const {
        price,
        title,
        image,
        image_preload,
        cartAmount = 0,
        showAmount = true,
        isActual = true,
        url,
        element,
        hasDiscount,
        discountPrice,
        giftBlock,
    } = props;
    const { $t } = useI18n();
    return (
        <ProductSectionWrapper {...props} style={{ flexDirection: 'column' }}>
            <FlexBox>
                <Box minWidth={'53px'} height={'53px'}>
                    <AppLink to={url}>
                        <Image preloadSrc={image_preload} src={image} borderRadius={12} />
                    </AppLink>
                </Box>
                <ProductSectionInfoWrapper isActual={isActual}>
                    <FlexBox flexDirection={'column'} px={'16px'} gap={'6px'} flex={1}>
                        <AppLink to={url}>
                            <Text
                                lineClamp={2}
                                color={'text.main'}
                                // variant={'h7'}
                                fontSize={{ default: 12, md: 18 }}
                                style={{ overflowWrap: 'anywhere' }}
                            >
                                {title}
                            </Text>
                        </AppLink>
                        <AppLink to={url}>
                            <FlexBox flexWrap={'wrap'} alignItems={'center'}>
                                <Text
                                    // variant={'h5'}
                                    fontSize={{ default: 16, md: 26 }}
                                    lineHeight={{ default: '20px', md: '33px' }}
                                    color={'text.main'}
                                    fontWeight={'bold'}
                                    marginRight={'8px'}
                                >
                                    {hasDiscount ? discountPrice : price} {signs.tg}
                                </Text>

                                {hasDiscount && (
                                    <WithDiscount>
                                        <Price price={price} priceType={'discount'} />
                                    </WithDiscount>
                                )}
                            </FlexBox>
                        </AppLink>
                        {element}
                    </FlexBox>
                </ProductSectionInfoWrapper>
                <FlexBox height={'53px'} alignItems={'center'}>
                    {showAmount && (
                        <Box backgroundColor={'block.secondary'} borderRadius={'38px'}>
                            <CartProductAmountLabel color={'text.secondary'}>
                                <Text color={'text.secondary'} fontSize={{ default: 12, md: 18 }}>
                                    {cartAmount} {$t('sht')}
                                </Text>
                            </CartProductAmountLabel>
                        </Box>
                    )}
                </FlexBox>
            </FlexBox>
            {giftBlock}
        </ProductSectionWrapper>
    );
};
