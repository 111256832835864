import React from 'react';
import styled from 'styled-components';
import { Text, usePyatka } from 'pyatka-ui';
import { useDispatch } from 'react-redux';
import { useI18n } from 'hooks/useI18n';
import { Product } from 'modules/ProductsList/types';
import { setIsAgeModal } from 'modules/App/App.actions';

interface BlurOverlayProps
    extends Pick<Product, 'is_for_adult' | 'is_degree_high' | 'is_degree_low'> {}

const BlurOverlay: React.FC<BlurOverlayProps> = ({
    is_for_adult,
    is_degree_high,
    is_degree_low,
}) => {
    const { scales } = usePyatka();
    const { $t } = useI18n();
    const dispatch = useDispatch();

    const handleClick = () => {
        dispatch(
            setIsAgeModal({
                isOpen: true,
                age: is_degree_high || is_degree_low ? 21 : is_for_adult ? 18 : 18,
            })
        );
    };

    return (
        <Overlay onClick={handleClick}>
            <VisibilityOffIcon isMobile={scales?.isMobile} />
            <Text
                color="white"
                fontWeight={500}
                textAlign="center"
                fontSize={{ default: '12px', md: '18px' }}
                lineHeight={{ default: '16.8px', md: '23.4px' }}
            >
                {$t('adult_product')}
            </Text>
        </Overlay>
    );
};

const Overlay = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
    background: rgba(255, 87, 35, 0.5);
    padding: 5px;
    /* margin-bottom: -10px; */

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 4px;

    border-radius: 14px;
    backdrop-filter: blur(20px);
    cursor: pointer;
`;

const VisibilityOffIcon: React.FC<{ isMobile: boolean }> = ({ isMobile }) =>
    isMobile ? (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M12 6.49969C14.76 6.49969 17 8.73969 17 11.4997C17 12.0097 16.9 12.4997 16.76 12.9597L19.82 16.0197C21.21 14.7897 22.31 13.2497 23 11.4897C21.27 7.10969 17 3.99969 12 3.99969C10.73 3.99969 9.51 4.19969 8.36 4.56969L10.53 6.73969C11 6.59969 11.49 6.49969 12 6.49969ZM2.71 3.15969C2.32 3.54969 2.32 4.17969 2.71 4.56969L4.68 6.53969C3.06 7.82969 1.77 9.52969 1 11.4997C2.73 15.8897 7 18.9997 12 18.9997C13.52 18.9997 14.97 18.6997 16.31 18.1797L19.03 20.8997C19.42 21.2897 20.05 21.2897 20.44 20.8997C20.83 20.5097 20.83 19.8797 20.44 19.4897L4.13 3.15969C3.74 2.76969 3.1 2.76969 2.71 3.15969ZM12 16.4997C9.24 16.4997 7 14.2597 7 11.4997C7 10.7297 7.18 9.99969 7.49 9.35969L9.06 10.9297C9.03 11.1097 9 11.2997 9 11.4997C9 13.1597 10.34 14.4997 12 14.4997C12.2 14.4997 12.38 14.4697 12.57 14.4297L14.14 15.9997C13.49 16.3197 12.77 16.4997 12 16.4997ZM14.97 11.1697C14.82 9.76969 13.72 8.67969 12.33 8.52969L14.97 11.1697Z"
                fill="white"
            />
        </svg>
    ) : (
        <svg
            width="37"
            height="37"
            viewBox="0 0 37 37"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M18.5 10.2495C22.64 10.2495 26 13.6095 26 17.7495C26 18.5145 25.85 19.2495 25.64 19.9395L30.23 24.5295C32.315 22.6845 33.965 20.3745 35 17.7345C32.405 11.1645 26 6.49953 18.5 6.49953C16.595 6.49953 14.765 6.79953 13.04 7.35453L16.295 10.6095C17 10.3995 17.735 10.2495 18.5 10.2495ZM4.565 5.23953C3.98 5.82453 3.98 6.76953 4.565 7.35453L7.52 10.3095C5.09 12.2445 3.155 14.7945 2 17.7495C4.595 24.3345 11 28.9995 18.5 28.9995C20.78 28.9995 22.955 28.5495 24.965 27.7695L29.045 31.8495C29.63 32.4345 30.575 32.4345 31.16 31.8495C31.745 31.2645 31.745 30.3195 31.16 29.7345L6.695 5.23953C6.11 4.65453 5.15 4.65453 4.565 5.23953ZM18.5 25.2495C14.36 25.2495 11 21.8895 11 17.7495C11 16.5945 11.27 15.4995 11.735 14.5395L14.09 16.8945C14.045 17.1645 14 17.4495 14 17.7495C14 20.2395 16.01 22.2495 18.5 22.2495C18.8 22.2495 19.07 22.2045 19.355 22.1445L21.71 24.4995C20.735 24.9795 19.655 25.2495 18.5 25.2495ZM22.955 17.2545C22.73 15.1545 21.08 13.5195 18.995 13.2945L22.955 17.2545Z"
                fill="white"
            />
        </svg>
    );

export default BlurOverlay;
