import { AuthorizationLogout } from 'modules/Authorization/v2/Authorization.actions';
import { InterceptorLayer } from 'services/types';
import { AppRedux } from 'store';

const onErrResponse: InterceptorLayer = (config) => {
    const { res } = config;

    if (res?.status === 401) {
        AppRedux.store.dispatch(AuthorizationLogout());
    }

    return config;
};
export default { onErrResponse };
