import React from 'react';
import { Box } from 'pyatka-ui';
import { SearchBar } from 'components';
import { HeaderWrapper, GoBackBtn } from '../components';

const MainHeader: React.FC = () => (
    <HeaderWrapper overflow="visible" gap="10px">
        <GoBackBtn />
        <Box width="100%">
            <SearchBar dumb_layout secondary />
        </Box>
    </HeaderWrapper>
);

export default React.memo(MainHeader);
