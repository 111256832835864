import React, { useState } from 'react';
import { FlexBox, Icon, Text } from 'pyatka-ui';
import { useI18n } from 'hooks/useI18n';
import { YMaps } from 'components';
import defaultPost from '../assets/defaultPost.svg';
import activePost from '../assets/activePost.svg';
import defaultPickup from '../assets/defaultPickup.svg';
import activePickup from '../assets/activePickup.svg';
import { CheckoutDataHookOutput } from '../types';
import WarningBlock from './WarningBlock';

interface PickUpBlockProps {
    checkout_data?: CheckoutDataHookOutput['checkout_data'];
    setStoreLocationId?: (id: string | number) => void;
    setPostomatId?: (id: string | number) => void;
}

const PickUpPointsBlock: React.FC<PickUpBlockProps> = (props) => {
    const { checkout_data, setStoreLocationId, setPostomatId, setDeliveryType } = props;
    const { $t } = useI18n();
    const [filters, setFilters] = useState<string[]>([]);

    const toggleFilter = (filter: string) => {
        setFilters(
            (prevFilters) =>
                prevFilters.includes(filter) ? prevFilters.filter((f) => f !== filter) : [filter]
            // : [...prevFilters, filter]
        );
    };

    // =================Самовывоз=================================================================
    const pickUpId = checkout_data?.pickup_selected?.store_location_id;

    const pickUpVariants = React.useMemo(() => {
        const variants = checkout_data?.pickup?.variants || [];

        return variants?.map((v) => ({
            type: 'pickup',
            subtitle: v?.name,
            title: v?.pickup_address,
            value: v?.store_location_id,
            time: v?.pickup_time,
            is_selected: v?.store_location_id === pickUpId,
            location: {
                latitude: v?.latitude,
                longitude: v?.longitude,
            },
            mapParams: {
                collapseIcon: 'market',
                defaultIcon: defaultPickup,
                activeIcon: activePickup,
            },
        }));
    }, [pickUpId, checkout_data?.pickup?.variants]);

    const activePickUp = React.useMemo(() => {
        return pickUpVariants?.find((v) => v?.value === pickUpId);
    }, [pickUpId, pickUpVariants]);

    // =================Постомат==============================================================

    const postomatId = checkout_data?.postomat_selected?.postomat_id;
    const postomatVariants = React.useMemo(() => {
        const variants = checkout_data?.postomat?.variants || [];

        return variants.map((v) => ({
            type: 'postomat',
            title: `${v?.address?.street}, ${v?.address?.building_number}`,
            subtitle: v?.description || '',
            time: v?.schedule?.desc || '',
            value: String(v?.postomat_id),
            is_selected: v?.postomat_id === postomatId,
            location: v?.location,
            mapParams: {
                collapseIcon: 'postomat_active',
                defaultIcon: defaultPost,
                activeIcon: activePost,
            },
        }));
    }, [checkout_data?.postomat?.variants, postomatId]);

    const activePostomat = React.useMemo(() => {
        return postomatVariants?.find((v) => v?.value === postomatId);
    }, [postomatVariants, postomatId]);

    // ====================================================================================

    const allPickUpPoints = [...pickUpVariants, ...postomatVariants];
    const availablePickupTypes = React.useMemo(
        () => checkout_data?.filtered_delivery_types.filter((item) => item !== 'shipping'),
        [checkout_data?.filtered_delivery_types]
    );
    const filteredVariants = allPickUpPoints.filter(
        (variant) => filters.length === 0 || filters.includes(variant.type)
    );

    const [modal, setModal] = React.useState(false);
    const openModal = () => setModal(true);
    const closeModal = () => setModal(false);

    const onSelectPoint = (point) => {
        setDeliveryType(point.type);
        switch (point.type) {
            case 'pickup':
                setStoreLocationId && setStoreLocationId(point.value);
                break;
            case 'postomat':
                setPostomatId && setPostomatId(point.value);
                break;
        }
    };
    if (allPickUpPoints?.length < 1) {
        return <WarningBlock text={$t('no_pickup_points')} />;
    }

    return (
        <React.Fragment>
            <PointSelectLayout onClick={openModal}>
                {checkout_data?.delivery_type === 'pickup_points' ? (
                    <Text color={'text.secondary'} fontSize={16}>
                        {$t('select_pickup_point')}
                    </Text>
                ) : (
                    <FlexBox flexDirection={'column'} gap={'6px'}>
                        <Text
                            lineClamp={1}
                            style={{ wordWrap: 'break-word' }}
                            color={'text.secondary'}
                            fontSize={12}
                        >
                            {checkout_data?.delivery_type === 'postomat' &&
                                $t('postamat_at_address')}
                            {checkout_data?.delivery_type === 'pickup' && $t('store_at_address')}
                        </Text>
                        <Text style={{ wordWrap: 'break-word' }} fontSize={16}>
                            {checkout_data?.delivery_type === 'postomat' && activePostomat?.title}
                            {checkout_data?.delivery_type === 'pickup' && activePickUp?.title}
                        </Text>
                    </FlexBox>
                )}
            </PointSelectLayout>

            <YMaps
                open={modal}
                onClose={closeModal}
                points={filteredVariants}
                pickupDeliveryTypes={availablePickupTypes}
                toggleFilter={toggleFilter}
                activeFilters={filters}
                selectPoint={onSelectPoint}
                collapseIcon="pickup_active"
                defaultIcon={defaultPickup}
                activeIcon={activePickup}
                type={'pickup'}
            />
        </React.Fragment>
    );
};
const PointSelectLayout: React.FC<{
    icon?: string;
    onClick: () => void;
}> = (props) => {
    const { $t } = useI18n();
    return (
        <FlexBox
            gap="10px"
            width="100%"
            alignItems="center"
            justifyContent="space-between"
            onClick={props?.onClick}
            cursor={'pointer'}
            py={'7.5px'}
        >
            <FlexBox maxWidth="calc(100% - 29px)" gap="12px" alignItems={'center'}>
                {props?.icon && (
                    <FlexBox minWidth="30px" justifyContent="center">
                        <Icon icon={props?.icon} />
                    </FlexBox>
                )}
                <FlexBox gap="5px" color="text.main" flexDirection="column">
                    {props?.children}
                </FlexBox>
            </FlexBox>
            <FlexBox
                background={'#FF7348'}
                p={'5.5px 10px'}
                color={'#fff'}
                borderRadius={'99px'}
                fontWeight={600}
                fontSize={'12px'}
                lineHeight={'15.36px'}
            >
                {$t('select')}
                {/*<Icon icon={'orderArrow'} width={26} height={26} />*/}
            </FlexBox>
        </FlexBox>
    );
};

export default PickUpPointsBlock;
