import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import {
    AuthUserData,
    IAuthInitPayload,
    IAuthOtpPayload,
    AuthorizationInitialState,
    AuthResponse,
} from 'modules/Authorization/v2/types';
import { flushUserAuthData, removeForgottenModalShown } from 'data';
import { setAuthorizationHeader } from 'modules/Authorization/v2/utils';
import { AppToaster } from 'plugins/app-toaster';
import defaultState from 'modules/Authorization/v2/Authorization.state';

const AuthorizationInit: CaseReducer<AuthorizationInitialState, PayloadAction<IAuthInitPayload>> = (
    _state,
    _action
) => {};
const AuthorizationDataFetch: CaseReducer<AuthorizationInitialState> = (_state, _action) => {};

const AuthorizationDataSave: CaseReducer<AuthorizationInitialState, PayloadAction<AuthUserData>> = (
    _state,
    action
) => {
    console.log('### AuthorizationDataSave', action);
    const { payload } = action;
    const { ttl, token } = payload;
    setAuthorizationHeader(token);
    const nowDate = new Date();
    const expireDate = new Date();
    expireDate.setSeconds(expireDate.getSeconds() + ttl);
    _state.authorizeExpires = expireDate.getTime();
    _state.authorizedDateTime = nowDate.getTime();
    _state.token = token;
    _state.ttl = ttl;
    removeForgottenModalShown();
    // _state.otp = { status: 'send' };
    // Loader.closeLoader();

    // console.log('AuthorizationDataSave', payload);
};

const AuthorizationLogout: CaseReducer<AuthorizationInitialState, PayloadAction> = () => {};
//
const AuthorizationFailed: CaseReducer<AuthorizationInitialState, PayloadAction<AuthResponse>> = (
    _state,
    _action
) => {
    const { payload } = _action;
    if (payload.config.url === 'auth_by_otp') {
        _state.errors = { otp: 'Неправильный СМС код' };
    } else if (payload.config.url === 'auth_tokens' && payload.status === 404) {
        AppToaster.error({ body: 'Неправильный email или пароль' });
    } else if (payload.config.url.includes('sign-in-by-otp')) {
        AppToaster.error({ body: payload?.data?.data?.message || 'Неправильный SMS код' });
    } else {
        AppToaster.error({ body: payload?.data?.data?.message || 'Неправильные данные' });
    }
};
const AuthorizationDataFlush: CaseReducer<AuthorizationInitialState> = (state, _action) => {
    state.token = defaultState.token;
    state.ttl = defaultState.ttl;
    state.authorizeExpires = defaultState.authorizedDateTime;
    state.authorizedDateTime = defaultState.authorizeExpires;
    flushUserAuthData();
    removeForgottenModalShown();
};

const AuthorizationVerifyOtp: CaseReducer<
    AuthorizationInitialState,
    PayloadAction<IAuthOtpPayload>
> = () => {};

const AuthorizationOtpSucceed: CaseReducer<
    AuthorizationInitialState,
    PayloadAction<AuthorizationInitialState['otp']>
> = (state, action) => {
    state.otp = action.payload;
};

const AuthorizationOtpFailed: CaseReducer<AuthorizationInitialState, PayloadAction<any>> = (
    state,
    action
) => {
    state.otp.error = action.payload.data;
};
const OtpClear: CaseReducer<AuthorizationInitialState> = (state) => {
    state.otp = { status: 'not-started', expire: 0, phone: '' };
};
export default {
    AuthorizationInit,
    AuthorizationDataSave,
    AuthorizationOtpSucceed,
    AuthorizationOtpFailed,
    AuthorizationFailed,
    AuthorizationDataFlush,
    OtpClear,
    AuthorizationVerifyOtp,
    AuthorizationLogout,
    AuthorizationDataFetch,
};
