import styled from 'styled-components';

const HEIGHT = '4rem';

export const CategoriesWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 1.25rem; // 20px
    flex-wrap: nowrap;
    flex: 0 0 auto;
    overflow-x: auto;
    height: ${HEIGHT}; // 64px
    scroll-behavior: smooth;

    /* hide scrollbar */
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
        display: none;
    }
`;

export const CategoryWrapper = styled.div<{ isLoading: boolean }>`
    cursor: pointer;
    color: ${(props) => (props.isLoading ? 'transparent' : '#201f1f')};
    background-color: #f6f6f5;
    padding: 1.25rem; // 20px
    border-radius: 1.5rem; // 24px
    font-size: 1.125rem; // 18px
    line-height: 1.5rem; // 24px
    font-weight: 500;
    white-space: nowrap;

    &:hover {
        background: #ededed;
        transition: 0.3s ease-in-out;
    }
`;

export const SelectedCategory = styled.h1`
    color: #201f1f;
    font-size: 1.75rem; // 28px
    line-height: 2.25rem; /* 36px */
    height: ${HEIGHT}; // 64px
    font-weight: 700;
    padding: 0;
    margin: 0;
`;

export const UnstyledBtn = styled.button<{ isLeft?: boolean }>`
    right: ${(props) => (props.isLeft ? 'auto' : 0)};
    left: ${(props) => (props.isLeft ? 0 : 'auto')};
    position: absolute;
    top: calc((100% - ${HEIGHT}) / 2);
    transform: ${(props) => (props.isLeft ? 'scaleX(-1)' : 'none')};
    outline: none;
    border: none;
    padding: 0;
    margin: 0;
    background-color: transparent;
    font: inherit;
    cursor: pointer;
    outline: inherit;
`;
