import { ApiGwRequest } from 'services';
import { ProductsListReqPayload } from 'modules/ProductsList/types';

export const ProductsListServices = (payload: ProductsListReqPayload) => {
    return ApiGwRequest({
        url: 'listing/v1/products',
        method: 'get',
        params: { ...payload.params, show_totals: true },
        signal: payload?.signal,
    });
};

export const ProductsListSeoServices = (id: string) => {
    return ApiGwRequest({
        url: `seo/v1/render`,
        method: 'post',
        data: {
            object_type: 'C',
            object_id: id,
        },
    });
};
