import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, FlexBox, Icon } from 'pyatka-ui';
import { ErrorBoundary, SearchBar } from 'components';
import { useAppRouter } from 'router/context';
import { handleSwipe } from 'utils/swipeControl';
import { SearchServiceMobileOverlaySet } from 'modules/SearchService/SearchService.actions';
import { SearchServiceMobileOverlay } from 'modules/SearchService/SearchService.selectors';
import { SuggestionsAndHistory } from 'components/SearchBar/components';

import styles from './SearchDialog.module.scss';

const SearchDialogModal = () => {
    const isOpen = useSelector(SearchServiceMobileOverlay);
    const [visible, setVisible] = React.useState<boolean>(false);
    const timeoutId = React.useRef<any>(null);
    const { location } = useAppRouter();
    const dispatch = useDispatch();

    const closeMobileSearch = React.useCallback(() => {
        handleSwipe(location?.pathname, true);
        dispatch(SearchServiceMobileOverlaySet(false));
    }, [dispatch, location?.pathname]);

    React.useEffect(() => {
        closeMobileSearch();
    }, [location.search]);

    // why useMemo? write me (tg: @k4zhymukhan)
    React.useMemo(() => {
        if (isOpen) {
            handleSwipe(location?.pathname, false);
            setVisible(true);
            return;
        }

        if (!isOpen) {
            timeoutId.current = setTimeout(() => setVisible(false), 200);
        }
    }, [isOpen]);

    React.useEffect(() => {
        return () => {
            !isOpen && clearTimeout(timeoutId.current);
        };
    }, [isOpen]);

    const className = styles[isOpen ? 'a-fadeInRight' : 'a-fadeOutRight'];

    if (!visible) {
        return null;
    }

    return (
        <ErrorBoundary>
            <Box bg="block.main" className={`${styles['search-overlay']} ${className}`}>
                <Box className={styles['search-overlay__content']} p={3}>
                    <FlexBox alignItems="center">
                        <Box onClick={closeMobileSearch}>
                            <Icon
                                width="28px"
                                height="28px"
                                icon="arrowLeft"
                                fill="block.inverse"
                            />
                        </Box>
                        <SearchBar secondary autoFocus width="100%" />
                    </FlexBox>

                    <SuggestionsAndHistory />
                </Box>
            </Box>
        </ErrorBoundary>
    );
};

const SearchDialog = () => (
    <ErrorBoundary>
        <SearchDialogModal />
    </ErrorBoundary>
);

export default React.memo(SearchDialog);
