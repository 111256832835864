import React, { useMemo, useState } from 'react';
import { Box, Button, FlexBox, Icon, Modal, Text, usePyatka } from 'pyatka-ui';
import YMapsDesktopLayout from 'components/YMaps/YMaps.DesktopLayout';
import YMap from 'components/YMaps/Map';
import { YMapsCollapse } from './YMapsCollapse';
import { YMapsProps } from './types';
import DeliveryTypeFilter from './components/DeliveryTypeFilter';

export const YMaps: React.FC<YMapsProps> = (props) => {
    const { open, onClose, points, selectPoint, pickupDeliveryTypes, toggleFilter, activeFilters } =
        props;
    const { scales } = usePyatka();
    const isMobile = (scales.matched || []).find((el: any) => el.name === 'md');

    // ------------- COLLAPSE CONTROL ------------- //
    const [selectedPoint, setSelectedPoint] = React.useState();
    const [collapse, setCollapse] = React.useState<any>(null);
    const openCollapse = (place: any) => {
        setCollapse(place);
        setSelectedPoint(place);
        setLayout('map');
    };
    const closeCollapse = () => {
        setCollapse(null);
    };

    const selectMarker = (postId: string | number) => {
        onClose();
        closeCollapse();
        selectPoint && selectPoint(postId);
    };

    const closeModal = () => {
        onClose();
        setLayout('map');
        closeCollapse();
    };
    // ------------- POSTOMAT LIST MODAL ------------- //

    const handlePostomatClick = (point: any) => {
        setLayout('map');
        setSelectedPoint(point);
        setCollapse(point);
    };

    const [layout, setLayout] = useState<'list' | 'map'>('map');

    //TODO: replace by $t()
    const layoutValuesDict = {
        list: 'Списком',
        map: 'Карта',
    };

    const changeLayout = () => {
        if (layout === 'list') {
            setLayout('map');
        } else {
            setLayout('list');
            closeCollapse();
        }
    };

    const layoutButtonTitle = useMemo(() => {
        switch (layout) {
            case 'map':
                return 'list';
            default:
                return 'map';
        }
    }, [layout]);

    if (!isMobile) {
        return <YMapsDesktopLayout {...props} selectPoint={selectMarker} />;
    }
    return (
        <>
            <Modal fullScreen open={open} onClose={closeModal} padding={'0px'}>
                <Box position="relative" width={'100%'} height={'100%'} overflowX={'hidden'}>
                    <YMap
                        {...props}
                        onPlacemarkClick={openCollapse}
                        onClose={closeModal}
                        selectedPoint={selectedPoint}
                    />
                    {layoutButtonTitle !== 'list' && (
                        <Box style={layoutIconStyle}>
                            <Button
                                backgroundColor={'white'}
                                styleType={'contained'}
                                color={'text.secondary'}
                                borderRadius={'20px'}
                                border={'4px solid rgba(190, 190, 182, 0.2)'}
                                padding={'4px 15px'}
                                onClick={changeLayout}
                            >
                                <Icon icon={layoutButtonTitle} />
                                <Text marginLeft={'5px'} fontWeight={'400'} fontSize={'15px'}>
                                    {layoutValuesDict[layoutButtonTitle]}
                                </Text>
                            </Button>
                        </Box>
                    )}
                    <Box style={layoutBottomStyle}>
                        <DeliveryTypeFilter
                            pickupDeliveryTypes={pickupDeliveryTypes}
                            activeFilters={activeFilters}
                            toggleFilter={toggleFilter}
                            layoutButtonTitle={layoutValuesDict[layoutButtonTitle]}
                            layoutButtonIcon={layoutButtonTitle}
                            changeLayout={changeLayout}
                        />
                    </Box>
                    {closeCollapse && (
                        <YMapsCollapse
                            collapse={collapse}
                            closeCollapse={closeCollapse}
                            selectPoint={selectMarker}
                        />
                    )}
                </Box>
            </Modal>
            <Modal
                clickableBackground={isMobile}
                positionMode={isMobile ? 'mobile' : 'default'}
                padding={'8px 18px 18px'}
                overlayBackground="transparent"
                open={layout === 'list'}
                maxHeight={'80vh'}
                width={'100%'}
                height={'100%'}
                style={{ overflowY: 'hidden !important' }}
                swipeable={true}
                onClose={() => setLayout('map')}
                customHeader={
                    <>
                        <FlexBox justifyContent="center">
                            <Box bg="#BEBEB633" width="42px" height="5px" borderRadius="999rem" />
                        </FlexBox>
                        <Box>
                            <DeliveryTypeFilter
                                pickupDeliveryTypes={pickupDeliveryTypes}
                                activeFilters={activeFilters}
                                toggleFilter={toggleFilter}
                            />
                        </Box>
                    </>
                }
            >
                <Box height="calc(100% - 80px)" overflowY={'auto'}>
                    <FlexBox flexDirection={'column'}>
                        {points?.map((postomat: any) => (
                            <PostomatItem
                                key={postomat?.value}
                                type={postomat?.type}
                                {...postomat}
                                onPostomatClick={handlePostomatClick}
                            />
                        ))}
                    </FlexBox>
                </Box>
            </Modal>
        </>
    );
};
export const PostomatItem = (props: any) => {
    const { title, subtitle, onPostomatClick, time, type } = props;
    return (
        <FlexBox
            p={'14px 3px 16px 0'}
            gap="12px"
            width="100%"
            flexDirection="column"
            borderBottom="1.5px solid #BEBEB633"
            onClick={() => onPostomatClick(props)}
        >
            <FlexBox width={'100%'} alignItems="center" justifyContent="space-between" gap="5px">
                <Box width="calc(100% - 40px)">
                    <Text
                        lineClamp={1}
                        style={{ wordWrap: 'break-word' }}
                        fontSize="16px"
                        fontWeight="700"
                        lineHeight="20px"
                        mb={'4px'}
                    >
                        {title}
                    </Text>
                    <Text
                        fontSize="14px"
                        color="text.secondary"
                        lineHeight="18px"
                        fontWeight={500}
                        style={{ wordWrap: 'break-word' }}
                    >
                        {subtitle}
                    </Text>
                </Box>
                <Box minWidth="31px">
                    <Icon width="31px" height="31px" icon="arrow-right-frame" />
                </Box>
            </FlexBox>
            <FlexBox alignItems="center" gap="10px">
                <Box
                    height="10px"
                    minWidth="10px"
                    backgroundColor="#9BD050"
                    borderRadius="50%"
                    mr="5px"
                />
                <Text fontSize="14px" lineHeight="17px">
                    {time}
                </Text>
            </FlexBox>
        </FlexBox>
    );
};

// ------------- Wrapper Styles ------------- //

const layoutIconStyle: React.CSSProperties = {
    position: 'absolute',
    top: '18px',
    left: '20px',
};
const layoutBottomStyle: React.CSSProperties = {
    position: 'absolute',
    width: '100%',
    background: '#fff',
    bottom: '0',
    padding: '12px 18px',
};
