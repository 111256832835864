import React from 'react';
import { Box, Button, FlexBox, Icon, LoadingIndicator, Text, usePyatka } from 'pyatka-ui';
import { ButtonProps } from 'pyatka-ui/dist/components-shared/Button/types';
import { CartProductCounterLayoutProps } from './types';

export const CartProductCounterLayoutNew: React.FC<CartProductCounterLayoutProps> = (props) => {
    const {
        fullWidth = false,
        amount,
        isLoading,
        disabled,
        increaseAmount,
        decreaseAmount,
        bg,
    } = props;
    const { theme } = usePyatka();

    const fillColor = theme?.colors?.text?.main;

    const btnProps: ButtonProps = {
        width: '45px',
        height: '45px',
        border: 0,
        p: '16px',
    };

    return (
        <FlexBox
            height="45px"
            fontSize="11px"
            alignItems="center"
            borderRadius="16px"
            bg={bg || 'block.secondary'}
            justifyContent="space-between"
            width={fullWidth ? '100%' : 'fit-content'}
        >
            <Button {...btnProps} onClick={decreaseAmount}>
                <Icon icon="counter-minus" width="16px" fill={fillColor} />
            </Button>
            <Box style={{ flex: 1 }}>
                <Text
                    fontSize="18px"
                    fontWeight="600"
                    textAlign="center"
                    lineHeight="23.4px"
                    color={isLoading ? 'text.secondary' : 'text.main'}
                    className={isLoading || Number(amount) === 0 ? 'blinkingHeart' : ''}
                    // @ts-ignore
                    justifyContent="center"
                >
                    {Number(amount) === 0 ? (
                        <FlexBox justifyContent="center">
                            <LoadingIndicator size="12px" />
                        </FlexBox>
                    ) : (
                        Number(amount)
                    )}
                </Text>
            </Box>
            <Button {...btnProps} disabled={disabled} onClick={increaseAmount}>
                <Icon icon="counter-plus" width="14px" height="14px" fill={fillColor} />
            </Button>
        </FlexBox>
    );
};
