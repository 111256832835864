import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAppRouter } from 'router/context';
import { getDetailedSupermarket } from 'modules/Supermarkets/Supermarkets.selectors';
import { fetchSupermarketsDetail } from 'modules/Supermarkets/Supermarkets.actions';
import { useLoader } from 'contexts/useLoader';
import { Loader } from 'plugins/loader';

const useSupermarket = () => {
    const { queryParams = {}, getParams } = useAppRouter();

    const { isLoading } = useLoader();

    const { params } = getParams();
    const store_id: string = String(params?.store_id || queryParams?.company_id || '');

    const dispatch = useDispatch();
    const supermarket = useSelector(getDetailedSupermarket);

    React.useEffect(() => {
        if (!supermarket?.company && store_id && !isLoading(Loader.supermarketDetail.indicator)) {
            dispatch(fetchSupermarketsDetail({ id: store_id, from: 'hook' }));
        }
    }, [supermarket, store_id, dispatch, Loader.supermarketDetail.indicator]);

    return { supermarket, store_id };
};

export default useSupermarket;
