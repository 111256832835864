import React from 'react';
import { Box, FlexBox, Icon, Text } from 'pyatka-ui';
import { AppLink, WithDiscount } from 'components';
import { signs } from 'utils/symbols';
import { useI18n } from 'hooks/useI18n';
import { ProductSectionProps } from 'components/ProductSection/types';
import {
    ProductItemInfoWrapper,
    StyledImage,
} from 'components/ClientOrderDetailCard/ClientOrderDetailCard.Styles';

const ProductItem: React.FC<ProductSectionProps> = (props) => {
    const {
        price,
        title,
        image,
        cartAmount = 0,
        showAmount = true,
        isActual = true,
        url,
        element,
        hasDiscount,
        discountPrice,
        isLast,
        isMobile,
    } = props;
    const { $t } = useI18n();
    return (
        <>
            <FlexBox gap={'16px'} width={'100%'} alignItems={'center'}>
                <FlexBox
                    alignItems={'center'}
                    justifyContent={'center'}
                    width={'40px'}
                    height={'40px'}
                >
                    {image === 'qr_lite' ? (
                        <Icon
                            icon={'qr_lite'}
                            width={isMobile ? '36px' : '40px'}
                            height={isMobile ? '36px' : '40px'}
                        />
                    ) : (
                        <AppLink to={url}>
                            {image && (
                                <StyledImage isMobile={isMobile} src={image} borderRadius={12} />
                            )}
                        </AppLink>
                    )}
                </FlexBox>
                <ProductItemInfoWrapper
                    flexDirection={'column'}
                    flex={1}
                    isActual={isActual}
                    isLast={isLast}
                    gap={'10px'}
                >
                    <FlexBox
                        width={'100%'}
                        gap={'5px'}
                        justifyContent={'space-between'}
                        alignItems={'center'}
                    >
                        <FlexBox flexDirection={'column'} gap={'2px'}>
                            <AppLink to={url}>
                                <Text
                                    lineClamp={2}
                                    color={'text.main'}
                                    fontSize={{ default: '14px', md: '16px' }}
                                    lineHeight={'20px'}
                                    fontWeight={500}
                                >
                                    {$t(title)}
                                </Text>
                            </AppLink>
                            {showAmount && (
                                <Text
                                    fontSize={{ default: '13px', md: '14px' }}
                                    color={'text.secondary'}
                                    fontWeight={500}
                                    lineHeight={'18px'}
                                >
                                    {cartAmount} {$t('sht')}
                                </Text>
                            )}
                        </FlexBox>
                        <FlexBox
                            flexWrap={'wrap'}
                            flexDirection={'column'}
                            alignItems={'flex-end'}
                            gap={'1px'}
                            justifyContent={'center'}
                        >
                            <Box>
                                <Text
                                    fontSize={{ default: '16px', md: '18px' }}
                                    lineHeight={'23px'}
                                    color={'text.main'}
                                    fontWeight={500}
                                    width={'max-content'}
                                >
                                    {hasDiscount ? discountPrice : price} {signs.tg}
                                </Text>
                            </Box>

                            {hasDiscount && (
                                <WithDiscount size={'small'}>
                                    <Text
                                        fontSize={'13px'}
                                        lineHeight={'16px'}
                                        color={'text.secondary'}
                                        fontWeight={500}
                                    >
                                        {price} {signs.tg}
                                    </Text>
                                </WithDiscount>
                            )}
                        </FlexBox>
                    </FlexBox>
                    {element && <FlexBox>{element}</FlexBox>}
                </ProductItemInfoWrapper>
            </FlexBox>
        </>
    );
};
export default ProductItem;
