import React from 'react';
import { Text, Box, FlexBox, Space } from 'pyatka-ui';
import { useI18n } from 'hooks/useI18n';
import { VerificationModalLayoutProps } from './types';

export const VerificationModalLayout: React.FC<VerificationModalLayoutProps> = (props) => {
    const { children, onReject, onAccept } = props;

    const { $t } = useI18n();

    return (
        <FlexBox flexDirection={'column'} justifyItems={'center'} alignItems={'center'}>
            {children}
            <Space height={'10px'} />
            <Box height={'2px'} width={'100%'} backgroundColor={'#BEBEB633'} />

            <FlexBox width={'100%'} justifyContent={'space-between'}>
                <FlexBox
                    paddingTop={'10px'}
                    width={'50%'}
                    alignContent={'center'}
                    justifyContent={'center'}
                    cursor={'pointer'}
                    onClick={onReject}
                >
                    <Text fontSize={'18px'}>{$t('no')}</Text>
                </FlexBox>
                <Box height={'50px'} width={'2px'} backgroundColor={'#BEBEB633'} />
                <FlexBox
                    paddingTop={'10px'}
                    width={'50%'}
                    alignContent={'center'}
                    justifyContent={'center'}
                    cursor={'pointer'}
                    onClick={onAccept}
                >
                    <Text fontSize={'18px'} color={'text.brand'}>
                        {$t('yes')}
                    </Text>
                </FlexBox>
            </FlexBox>
        </FlexBox>
    );
};
