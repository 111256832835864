export const colors = {
    status: {
        error: '#FF0000' /*DONE*/,
        warning: '#FFD65F' /*DONE*/,
        success: '#9BD050' /*DONE*/,
        info: '#357edd' /*DONE*/,
    },
    text: {
        main: '#FFFFFF' /*DONE*/,
        secondary: '#898D94',
        brand: '#FF4810' /*DONE*/,
        placeholder: '#AAADB2',
        disabled: '#CDCFD1',
        inverse: '#201F1F' /*DONE*/,
        brand_variant: '#FF7348' /*DONE*/,
    },
    block: {
        secondary: '#111114' /*DONE*/,
        inverse: '#FFFFFF' /*DONE*/,
        main: '#1F1F22' /*DONE*/,
        brand: '#FF4810' /*DONE*/,
        placeholder: '#AAADB2',
        brand_variant: '#FF7348' /*DONE*/,
        secondary_variant: '#E5E5E2',
        disabled: '#BEBEB633',
    },
};
