import React from 'react';
import { useSelector } from 'react-redux';
import { TabLink } from 'modules/App/types';
import { useCartService } from 'contexts/useCartService';
// import { useWishListService } from 'contexts/useWishListService';
import { wishlistProductsCount } from 'modules/Wishlist/Wishlist.selectors';
import { NewTabNavigationLayout } from './NewTabNavigation.Layout';
import { NewTabNavigationProps } from './types';

export const NewTabNavigationContainer: React.FC<NewTabNavigationProps> & {
    Layout: typeof NewTabNavigationLayout;
} = (props) => {
    const { cartCounter }: any = useCartService();
    // const { wishListCount }: any = useWishListService();

    // const { wishlistCount } = useWishlist();
    const wishlistCount = useSelector(wishlistProductsCount);
    const { appearance } = props;

    const tabs = React.useCallback(
        (appear: TabLink['appearance']): TabLink[] => {
            const tabs = props.tabLinks(appear);
            return tabs.map((tab) => {
                if (tab.slug === 'cart') {
                    const labelCount = cartCounter || 0;
                    return { ...tab, labelCount };
                } else if (tab.slug === 'wishlist') {
                    const labelCount = wishlistCount;
                    return { ...tab, labelCount };
                }

                return tab;
            });
        },
        [props, cartCounter, wishlistCount]
    );

    const layoutProps = { ...props, tabs: tabs(appearance) };

    return <NewTabNavigationLayout {...layoutProps} />;
};

NewTabNavigationContainer.Layout = NewTabNavigationLayout;
