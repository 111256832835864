import React, { useMemo } from 'react';
import { Box, FlexBox, Icon, Text, usePyatka } from 'pyatka-ui';
import {
    CheckoutDeliveryPageData,
    CheckoutDeliveryPageLayoutProps,
} from 'pages/CheckoutDeliveryV2Page/types';
import { ProductSection, ProfileAddress } from 'components';
import { useI18n } from 'hooks/useI18n';
import DeliveryImage from '../../assets/delivery-image.jpg';
import { ShippingBlock, PostomatBlock, PickUpBlock, PickUpPointsBlock } from './components';

interface DeliverySegmentTypes {
    [key: string]: any;
}

type DeliverySegmentType = keyof DeliverySegmentTypes;

const DELIVERY_TYPES = {
    shipping: { title: 'courier', block: ShippingBlock },
    pickup_points: { title: 'pick_up_points', block: PickUpPointsBlock },
    pickup: { title: 'pick_up_points', block: PickUpPointsBlock },
    postomat: { title: 'pick_up_points', block: PickUpPointsBlock },
};

interface DeliveryOptionProps {
    text: string;
    price: string;
    active: boolean;
    value: string;
    onClick: any;
}

const DeliveryOption: React.FC<DeliveryOptionProps> = (props) => {
    const { value, text, price, active, onClick } = props;
    const { scales } = usePyatka();
    const isMobile = (scales.matched || []).find((el: any) => el.name === 'md');
    const onOptionClick = () => {
        onClick(value);
    };

    const iconName = value === 'shipping' ? 'delivery' : 'pickup';
    const iconSize = useMemo(() => (isMobile ? '22px' : '28px'), [isMobile]);
    const showPrice = price !== null && price !== undefined && String(price).length > 0;

    return (
        <FlexBox
            width={'100%'}
            borderRadius={isMobile ? '18px' : '22px'}
            // border={active ? '2px solid #FEF6F4' : '2px solid #F6F6F5'}
            // flexDirection={isMobile ? 'column' : 'row'}
            flexDirection={'row'}
            gap={isMobile ? '12px' : '12px'}
            backgroundColor={active ? '#FFF2ED' : 'block.secondary'}
            padding={isMobile ? '19px 12px 17px' : '19px 22px 17px'}
            minWidth={'104px'}
            onClick={onOptionClick}
            // mr={'10px'}
            cursor={'pointer'}
            alignItems={'center'}
        >
            <Box>
                <Icon
                    fill={active ? '' : '#898D94'}
                    icon={`${iconName}${active ? '_active' : ''}`}
                    width={iconName === 'delivery' ? (isMobile ? '29px' : '35px') : iconSize}
                    height={iconSize}
                />
            </Box>
            <Box>
                <Text
                    fontSize={{ default: '16px', md: '18px' }}
                    lineHeight={{ default: '20px', md: '21px' }}
                    color={active ? 'text.main' : 'text.secondary'}
                >
                    {text}
                </Text>
                {/*<Text*/}
                {/*    fontSize={{ default: '18px', md: '20px' }}*/}
                {/*    lineHeight={{ default: '24px', md: '27px' }}*/}
                {/*>*/}
                {/*    {showPrice ? `${price}₸` : ''}*/}
                {/*</Text>*/}
            </Box>
        </FlexBox>
    );
};

const DeliverySegment: React.FC<CheckoutDeliveryPageLayoutProps> = (props) => {
    const { checkout_data } = props;
    const { $t } = useI18n();
    const segmentInfo = useMemo((): {
        value: DeliverySegmentType;
        items: DeliverySegmentTypes;
        prices: any;
    } => {
        const items: any = {};

        const pickup_points = ['pickup', 'postomat', 'sdek'];
        (checkout_data?.filtered_delivery_types || []).forEach(
            (type: CheckoutDeliveryPageData['delivery_type']) => {
                if (type !== null) {
                    if (pickup_points.includes(type)) {
                        items['pickup_points'] = $t(DELIVERY_TYPES['pickup_points']?.title);
                        return;
                    }
                    items[type.toString()] = $t(DELIVERY_TYPES[type]?.title);
                }
            }
        );
        return {
            items,
            value: pickup_points.includes(checkout_data?.delivery_type)
                ? 'pickup_points'
                : (checkout_data?.delivery_type as string),
            prices: {
                shipping: checkout_data?.shipping_selected?.price,
                pickup_points: 0,
                pickup: 0,
                postomat: 0,
            },
        };
    }, [checkout_data]);

    const deliveryTypeChangeHandler = (value: any) => props?.setDeliveryType(value);

    return (
        <>
            {/*<Segment*/}
            {/*    value={segmentInfo.value}*/}
            {/*    items={segmentInfo?.items}*/}
            {/*    onChange={deliveryTypeChangeHandler}*/}
            {/*/>*/}
            <FlexBox gap={'8px'}>
                {Object.keys(segmentInfo?.items).map((item) => (
                    <DeliveryOption
                        key={item}
                        active={item === segmentInfo.value}
                        text={segmentInfo.items[item]}
                        value={item}
                        price={segmentInfo.prices[item]}
                        onClick={deliveryTypeChangeHandler}
                    />
                ))}
            </FlexBox>
        </>
    );
};

export const DeliveryBlock: React.FC<CheckoutDeliveryPageLayoutProps> = (props) => {
    const { checkout_data } = props;
    const { scales } = usePyatka();
    const isMobile = (scales.matched || []).find((el: any) => el.name === 'md');

    if (checkout_data === null || !checkout_data?.shipping_required) {
        return <></>;
    }

    if (!checkout_data?.is_delivery_valid_address && checkout_data?.shipping_required) {
        return (
            <Box p={{ default: '18px', md: '0 0 24px' }} backgroundColor={'block.main'}>
                <ProfileAddress withBtnLayout />
            </Box>
        );
    }

    const DeliveryBlock = checkout_data?.delivery_type
        ? DELIVERY_TYPES[checkout_data?.delivery_type]?.block
        : null;

    return (
        <>
            <FlexBox
                flexDirection="column"
                p={isMobile ? '18px' : '0'}
                bg={'white'}
                gap={'24px'}
                borderRadius={'0 0 24px 24px'}
                mb={{ default: '0', md: '37px' }}
            >
                <DeliverySegment {...props} />
            </FlexBox>

            {DeliveryBlock ? (
                <FlexBox
                    flexDirection={'column'}
                    gap={'12px'}
                    p={isMobile ? '12px 18px' : '0 0 40px'}
                    bg={'white'}
                    mt={'12px'}
                >
                    <DeliveryBlock {...props} />
                </FlexBox>
            ) : (
                ''
            )}
        </>
    );
};

export const DeliveryProductSection: React.FC<CheckoutDeliveryPageLayoutProps> = (props) => {
    const { $t } = useI18n();
    const price: string = (props?.checkout_data?.shipping_selected?.price || 0).toString() || '';
    return (
        <ProductSection
            isLast={true}
            price={price}
            title={$t('delivery')}
            image={DeliveryImage}
            cartAmount={0}
            showAmount={false}
            product_id={''}
            offer_id={''}
            element={null}
        />
    );
};
