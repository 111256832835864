import React, { createContext, useContext, useEffect, useLayoutEffect, useState } from 'react';
import { indicatorsState, LOADER_EVENT_KEY } from 'plugins/loader';
import { Indicator, IndicatorManagerPayload, IndicatorState } from 'plugins/loader/types';
import { AppLoaderInstance } from './types';

const useIsomorphicLayoutEffect = __BROWSER__ ? useLayoutEffect : useEffect;

const AppLoaderContext = createContext<AppLoaderInstance | null>(null);

const AppLoaderProvider: React.FC<any> = (props) => {
    /* ----------------------------------- React: useState ---------------------------------- */
    const [indicators, setIndicators] = useState<IndicatorState>(indicatorsState);

    const indicatorMutation = React.useCallback((payload: IndicatorManagerPayload | undefined) => {
        if (payload === undefined) {
            return;
        }

        setIndicators((prev) => ({
            ...prev,
            [payload.indicator]: {
                global: payload.global,
                status: payload.status,
                payload: payload?.payload || null,
            },
        }));
    }, []);

    useIsomorphicLayoutEffect(() => {
        if (__BROWSER__ && window) {
            // @ts-ignore
            document.addEventListener(LOADER_EVENT_KEY, (e) => indicatorMutation(e?.detail), false);
        }
        return () => {
            document.removeEventListener(LOADER_EVENT_KEY, () => {});
        };
    }, []);

    // ------------------------------------------------------------------------------- Memos

    const isLoading: AppLoaderInstance['isLoading'] = React.useCallback(
        // eslint-disable-next-line security/detect-object-injection

        (key: Indicator) => indicators[key].status,
        [indicators]
    );

    const isGlobalLoading: AppLoaderInstance['isGlobalLoading'] = React.useMemo(
        () => Object.values(indicators).filter((value) => value.global).length > 0,
        [indicators]
    );

    // ------------------------------------------------------------------------------- Provider
    const value: AppLoaderInstance = {
        isLoading,
        indicators,
        isGlobalLoading,
    };

    return <AppLoaderContext.Provider value={value}>{props.children}</AppLoaderContext.Provider>;
};

const useLoader = () => useContext(AppLoaderContext) as AppLoaderInstance;

export { AppLoaderProvider, useLoader };
