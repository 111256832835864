import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AppWishListServiceInstance } from 'contexts/useWishListService/types';
import { isUserAuth } from 'modules/Authorization/Authorization.selectors';
import { AppWishlistServices } from 'contexts/useWishlist/services';
import { nativeCall } from 'hybrid';
import { authFromMobile } from 'modules/Authorization/Authorization.actions';
import { AppModulesStorage } from 'data';
import {
    fetchWishlistData,
    fetchWishlistProductIds,
    flushWishlist,
    toggleItemInWishlist,
    wishlistDataSave,
} from 'modules/Wishlist/Wishlist.actions';
import { Loader } from 'plugins/loader';
import { useLoader } from 'contexts/useLoader';
import { isNative } from 'utils/platform';
import { Dialog } from 'plugins/dialog';
import AuthorizationModal from 'components/AuthorizationModal';
import { AuthorizationInit } from 'modules/Authorization/v2/Authorization.actions';
import { wishlistProductsCount } from 'modules/Wishlist/Wishlist.selectors';

const CONTEXT_MODULE_NAME = 'WISHLIST_V3_FAVORITE';

const AppWishlistContext = createContext<AppWishListServiceInstance | {}>({});

const AppWishlistProvider: React.FC<any> = (props) => {
    const [wishlistProducts, setWishlistProducts] = useState<any>({});

    const [wishlistProductIds, setWishlistProductIds] = useState<any>({});

    const dispatch = useDispatch();

    const isAuth = useSelector(isUserAuth);

    //TODO: callback, remove snap_products
    const isInFavorites = (toggle_product_id: any) => {
        //TODO: replace by { ...wishlistProductIds };
        // const wishlist_snapshot = { ...wishlistProducts };
        // const wishlist_snapshot = { ...wishlistProductIds };
        //
        // return Object.keys(wishlist_snapshot).find(
        //     (product_id) => String(product_id) === String(toggle_product_id)
        // );
    };

    //TODO: replace by wishlistProductIds
    // const wishlistCount = useMemo(
    //     // () => Object.keys(wishlistProducts)?.length || 0,
    //     () => Object.keys(wishlistProductIds)?.length || 0,
    //
    //     [wishlistProductIds]
    // );
    const wishlistCount = useSelector(wishlistProductsCount);
    const setWishlist = (products: any) => {
        // setWishlistProducts({ ...products });
        setWishlistProductIds({ ...products });

        //TODO: SAVE TO STORAGE
        __BROWSER__ && AppModulesStorage.set(CONTEXT_MODULE_NAME, { ...products });
    };

    const { indicators } = useLoader();
    const isFavoriteLoading = indicators.favorite.status;

    const syncWishlist = async (params: { page: number; isFlush?: boolean }) => {
        console.log('syncWishlist');
        const { page, isFlush } = params;
        dispatch(fetchWishlistData({ page }));

        // if (!isFavoriteLoading) {
        //     Loader.favorite.on();

        // try {
        // const { data } = await AppWishlistServices.get({ page });
        //
        // const { products, pagination } = data?.data;
        //
        // const parsedProducts = products.reduce(
        //     (accumulator: any, currentValue: any) => ({
        //         ...accumulator,
        //         [currentValue?.productId]: { product_id: currentValue?.productId },
        //     }),
        //     {}
        // );
        //
        // dispatch(wishlistDataSave({ products, pagination, isFlush }));
        //
        // isFlush && setWishlist({});

        // const stateProducts = isFlush
        //     ? { ...parsedProducts }
        //     : { ...wishlistProducts, ...parsedProducts };

        // setWishlist({ ...stateProducts });
        // console.log('the result products: ', parsedProducts);
        // } catch {
        //     console.log('ERROR IN GET');
        // }

        //     Loader.favorite.off();
        // }

        //helper to parsing
    };

    const getWishlistIds = async () => {
        console.log('getWishlistIds');
        dispatch(fetchWishlistProductIds());
        // try {
        //     const { data } = await AppWishlistServices.getProductIds();
        //
        //     const parsedProductIds = data?.data?.products?.reduce(
        //         (accumulator: any, currentValue: any) => ({
        //             ...accumulator,
        //             [currentValue?.productId]: { product_id: currentValue?.productId },
        //         }),
        //         {}
        //     );
        //     console.log('parsedProductIds', parsedProductIds);
        //     setWishlistProductIds(parsedProductIds);
        // } catch {
        //     console.log('ERROR IN GET IDS');
        // }
    };

    const toggleWishlistItem = async (toggle_product: any) => {
        dispatch(toggleItemInWishlist(toggle_product));
        // if (!isAuth) {
        //     if (!isNative()) {
        //         Dialog.openDialog({
        //             body: AuthorizationModal,
        //             props: {
        //                 onClose: () => {
        //                     Dialog.closeDialog();
        //                 },
        //                 onAuthDone: () => toggleHelper(toggle_product),
        //             },
        //             isOwnModal: true,
        //         });
        //         return;
        //     }
        //
        //     try {
        //         const res: any = nativeCall({
        //             type: 'auth',
        //             payload: {},
        //         });
        //
        //         if (res.response?.token) {
        //             dispatch(authFromMobile(res.response?.token));
        //         }
        //
        //         await toggleHelper(toggle_product);
        //     } catch {
        //         console.log('ERROR IN TOGGLE');
        //     }
        // }
        // if (isAuth) {
        //     await toggleHelper(toggle_product);
        //     // check is in favorites:
        // }
    };

    const toggleHelper = async (toggle_product: any) => {
        //TODO: replace by wishlistProductIds

        // let wishlist_snapshot = { ...wishlistProducts };
        let wishlist_snapshot = { ...wishlistProductIds };

        const isFavorite = isInFavorites(toggle_product?.product_id);

        if (isFavorite) {
            //DELETE
            // wishlist_snapshot?.filter(
            //     (product) => String(product?.product_id) !== String(toggle_product?.product_id)

            delete wishlist_snapshot[String(toggle_product?.product_id)];

            setWishlist(wishlist_snapshot);

            await deleteItem(toggle_product?.product_id);
            //DELETE SERVICE
        } else {
            //ADD
            wishlist_snapshot = {
                [toggle_product?.product_id]: { product_id: toggle_product?.product_id },
                ...wishlist_snapshot,
            };

            setWishlist(wishlist_snapshot);

            await addItem(toggle_product?.product_id);

            //POST SERVICE
        }
    };

    const deleteItem = async (product_id: any) => {
        try {
            const res = await AppWishlistServices?.delete({ product_id });
        } catch {}
    };

    const addItem = async (product_id: any) => {
        try {
            const payload = { favorite: [{ product_id }] };
            const res = await AppWishlistServices?.post(payload);
        } catch {}
    };

    const clearWishlist = async () => {
        dispatch(flushWishlist());
        // try {
        //     const res = await AppWishlistServices?.clear();
        //     await syncWishlist({ page: 1, isFlush: true });
        // } catch {
        //     console.log('ERROR IN CLEAR WISHLIST');
        // }
    };

    const restoreWishlist = () => {
        const storageProducts = __BROWSER__ ? AppModulesStorage.get(CONTEXT_MODULE_NAME) || {} : {};

        // setWishlistProducts(storageProducts);
        setWishlistProductIds(storageProducts);
    };

    useEffect(() => {
        console.log(isAuth);
        // isAuth && syncWishlist({ page: 1 });

        // isAuth && getWishlistIds();

        // !isAuth && restoreWishlist();
    }, [isAuth]);

    const value = {
        toggleWishlistItem,
        isInFavorites,
        wishlistProducts,
        wishlistProductIds,
        wishlistCount,
        syncWishlist,
        clearWishlist,
        getWishlistIds,
    };

    return (
        <AppWishlistContext.Provider value={value}>{props.children}</AppWishlistContext.Provider>
    );
};

const useWishlist = (): any => {
    const context = useContext(AppWishlistContext) as AppWishListServiceInstance;
    if (context === undefined) {
        console.error('useWishListService not initialized yet');
    }
    return context;
};

export { AppWishlistProvider, useWishlist };
