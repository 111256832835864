import { usePyatka } from 'pyatka-ui';
import { useLayoutEffect, useMemo, useState } from 'react';

type Option = {
    count: number;
    gap: number;
};

interface UseResponsiveVirtualGridParams {
    [key: string]: Option;
}

export const useResponsiveVirtualGrid = (options: UseResponsiveVirtualGridParams) => {
    const { theme, windowSize } = usePyatka();

    const [count, setCount] = useState(2);
    const [gap, setGap] = useState(7);

    const sortedOptions = useMemo(() => {
        const arr = Object.entries(options).reduce((acc: any[], [key, option]) => {
            const width = parseInt(theme.breakpoints[`${key}`]);
            return [...acc, { ...option, width }];
        }, []);
        return [...arr].sort((a, b) => b.width - a.width);
    }, [options, theme.breakpoints]);

    useLayoutEffect(() => {
        if (
            sortedOptions.length === 0 ||
            windowSize.width < sortedOptions[sortedOptions.length - 1].width
        ) {
            setCount(options.default.count);
            setGap(options.default.gap);
        }
        for (const option of sortedOptions) {
            if (windowSize.width >= option.width) {
                setCount(option.count);
                setGap(option.gap);
                break;
            }
        }
    }, [options.default.count, options.default.gap, sortedOptions, windowSize.width]);

    return { count, gap };
};
