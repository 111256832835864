import styled from 'styled-components/macro';

export const LoaderBody = styled('div')`
    position: relative;
    left: 50%;
    top: 50%;
    margin-left: -20px;
    margin-top: -20px;
    width: fit-content;
`;
