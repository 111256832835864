import { OrdersServiceInitialState } from 'modules/OrdersService/types';

const state: OrdersServiceInitialState = {
    orderItems: [],

    pagination: {},
    // data: {},
    orders: [],
    order: {},
    orderItem: {},
    cancelReason: {},
};
export default state;
