import React, { FC } from 'react';
import { Box, Display, FlexBox, Icon, Text, usePyatka } from 'pyatka-ui';
import { useSelector } from 'react-redux';
import { IProduct, NewProductDetailPageLayoutProps } from 'pages/NewProductDetailPage/types';
import { getProductLabels } from 'modules/ProductDetail/ProductDetail.selectors';
import { useAppRouter } from 'router/context';
import { generateLabels } from 'modules/ProductsList/ProductsList.helpers';
import { TableSize } from '../MobileComponents';
import ProductLabels from './ProductLabels';
import { IVariation } from './Variations';
import { EMPTY_PRODUCT } from './constants';
import { Variations } from '.';

const MAX_WIDTH = {
    default: '100%',
    md: 'calc(100% - 400px)',
};

const ProductSelector: FC<IProductSelector> = ({
    product = EMPTY_PRODUCT,
    product_id,
    isLoading = false,
    children,
    reviewInfo,
    scrollToReviews,
}) => {
    const { title, brand, feature_variations = [] } = product;
    const { scales } = usePyatka();
    const ratingSize = scales?.isMobile ? '14px' : '18px';

    const {
        location: { state },
    } = useAppRouter();
    const product_labels = useSelector(getProductLabels);

    const labels = React.useMemo(() => {
        const { bonusLabel, discountLabel, installmentLabel } = product_labels;
        if (bonusLabel || discountLabel || installmentLabel) {
            return product_labels;
        }
        return generateLabels(state?.labels);
    }, [state?.labels, product_labels]);

    const reviews = React.useMemo(() => {
        if (reviewInfo?.avgRate && reviewInfo?.totalCount) {
            return {
                avgRate: reviewInfo?.avgRate,
                totalRecords: reviewInfo?.totalCount,
            };
        }
        return {
            avgRate: state?.avgRate,
            totalRecords: state?.totalRecords,
        };
    }, [state?.avgRate, state?.totalRecords, reviewInfo]);

    return (
        <Box width="100%" maxWidth={MAX_WIDTH}>
            <FlexBox
                gap={scales?.isMobile ? '12px' : '25px'}
                flexDirection={{ default: 'column', md: 'column-reverse' }}
            >
                <FlexBox alignItems="flex-start" justifyContent="space-between">
                    <Display md={false} lg xl xxl>
                        <Box
                            width="100%"
                            display="grid"
                            gridGap="10px"
                            gridTemplateColumns="1fr 1fr 1fr"
                        >
                            <ProductLabels
                                isDesktop
                                labels={labels}
                                reviews={reviews}
                                clickRating={scrollToReviews}
                            />
                        </Box>
                    </Display>
                    <Display xs sm md lg={false} xl={false} xxl={false}>
                        <ProductLabels labels={labels} />
                        <FlexBox
                            py="4px"
                            px="8px"
                            gap="4px"
                            height={{ default: '26px', md: '38px' }}
                            alignItems="center"
                            bg="block.secondary"
                            borderRadius="999rem"
                            onClick={scrollToReviews}
                        >
                            <Icon icon="star-filled" width="16px" height="16px" />
                            <Text fontSize={ratingSize} fontWeight={500} lineHeight="20.48px">
                                {reviews?.avgRate ? Number(reviews.avgRate).toFixed(1) : 0}
                            </Text>
                            {!!reviews?.totalRecords && (
                                <Text
                                    fontSize={ratingSize}
                                    fontWeight={500}
                                    lineHeight="20.48px"
                                    color="text.secondary"
                                >
                                    ({reviews.totalRecords})
                                </Text>
                            )}
                        </FlexBox>
                    </Display>
                </FlexBox>
                <FlexBox flexDirection="column">
                    {brand && (
                        <Text
                            color="text.secondary"
                            fontSize={4}
                            skeleton={isLoading}
                            skeletonProps={{
                                animation: true,
                                marginTop: '8px',
                                width: '60%',
                                height: '16px',
                            }}
                        >
                            {brand}
                        </Text>
                    )}
                    <Text
                        // @ts-ignore
                        as="h1"
                        fontSize={{ default: '18px', md: '26px' }}
                        lineHeight={{ default: '26px', md: '32px' }}
                        fontWeight={700}
                        lineClamp={3}
                        skeleton={isLoading}
                        skeletonProps={{
                            animation: true,
                            marginTop: '8px',
                            width: '100%',
                            height: '32px',
                        }}
                        color="text.main"
                    >
                        {title}
                    </Text>
                </FlexBox>
            </FlexBox>

            <TableSize category_ids={product?.category_ids || []} />

            {feature_variations?.length > 0 && (
                <FlexBox my={{ default: '18px', md: '25px' }} flexDirection="column" gap="25px">
                    {feature_variations.map((variation: IVariation, idx: number) => (
                        <Variations
                            isLoading={isLoading}
                            variation={variation}
                            product_id={product_id}
                            key={`${variation.feature_id}-${idx}`}
                        />
                    ))}
                </FlexBox>
            )}
            <Display md={false} xl xxl>
                {children}
            </Display>
        </Box>
    );
};

interface IProductSelector {
    product: IProduct;
    product_id?: string;
    scrollToReviews: () => void;
    isLoading: boolean;
    reviewInfo?: NewProductDetailPageLayoutProps['reviewInfo'];
    children?: React.ReactNode;
}

export default ProductSelector;
