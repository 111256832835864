import { connect, ConnectedProps } from 'react-redux';
import { isUserAuth } from 'modules/Authorization/Authorization.selectors';
// import { authFromMobile } from 'modules/Authorization/Authorization.actions';
import { AuthorizationInit } from 'modules/Authorization/v2/Authorization.actions';

const mapState = (state) => ({
    isUserAuth: isUserAuth(state),
});
const mapDispatch = {
    // authFromMobile,
    AuthorizationInit,
};

export const AppReAuthConnector = connect(mapState, mapDispatch);
export type AppReAuthPageConnectProps = ConnectedProps<typeof AppReAuthConnector>;
