import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { MailInitialState } from 'modules/Mail/types';

const sendMail: CaseReducer<MailInitialState, PayloadAction<{}>> = (_state, _action) => {};

const setFormData: CaseReducer<MailInitialState, PayloadAction<any>> = (_state, _action) => {
    const { payload } = _action;
    _state.formData = payload;
};

export default { sendMail, setFormData };
