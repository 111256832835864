import React from 'react';
import { FlexBox, Text } from 'pyatka-ui';
import { AppLink, Rating } from 'components';

interface IMerchInfoBlockProps {
    company_id: number;
    company: string;
    rating: number;
}

const MerchInfoBlock = (props: IMerchInfoBlockProps) => {
    const { company, company_id, rating } = props;
    return (
        <FlexBox gap={'10px'} alignItems={'center'}>
            <AppLink
                to={{
                    name: 'merchant',
                    query: {
                        company_id,
                    },
                }}
            >
                <Text
                    color={'text.main'}
                    fontWeight={600}
                    fontSize={{ default: '16px', md: '22px' }}
                >
                    {company}
                </Text>
            </AppLink>
            <Rating rating={rating / 100} />
        </FlexBox>
    );
};

export default MerchInfoBlock;
