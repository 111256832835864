import { SortValueType } from './types';

export const sortOptions: Record<SortValueType, string> = {
    'popularity-desc': 'popularity_desc',
    'price-asc': 'price_asc',
    'price-desc': 'price_desc',
    'product-asc': 'product_asc',
    'timestamp-desc': 'timestamp_desc',
    'discount-desc': 'discount_desc',
};
