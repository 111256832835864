import React from 'react';
import { FlexBox, Text } from 'pyatka-ui';
import { SwitchToggle } from 'components';

interface FilterSwitchProps {
    active?: boolean;
    switchToggler: () => void;
    name?: string;
    description?: string;
}

const FilterSwitch: React.FC<FilterSwitchProps> = ({ active = false, switchToggler, name, description }) => {
    return (
        <FlexBox my="32px" alignItems="center" justifyContent="space-between">
            <FlexBox flexDirection="column" gap="6px">
                <FlexBox gap="6px" alignItems="center">
                    <Text fontWeight={600} fontSize="16px" lineHeight="20.48px" color="text.main">
                        {name}
                    </Text>
                    <svg
                        width="39"
                        height="20"
                        viewBox="0 0 39 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <rect width="39" height="20" rx="10" fill="#9BD050" />
                        <path
                            d="M12.144 11.096V5.6H13.8V14H12.228L8.496 8.504V14H6.84V5.6H8.412L12.144 11.096ZM17.1927 10.508V12.368H21.3447V14H15.4767V5.6H21.2247V7.232H17.1927V8.924H20.7687V10.508H17.1927ZM29.5852 11.012L31.1812 5.6H32.8972L30.2572 14H28.8652L27.3772 8.864L25.8892 14H24.4732L21.8452 5.6H23.6092L25.2052 11.012L26.7532 5.6H28.0372L29.5852 11.012Z"
                            fill="white"
                        />
                    </svg>
                </FlexBox>
                <Text color="text.secondary" fontSize="14px" lineHeight="18.9px" fontWeight={500}>
                    {description}
                </Text>
            </FlexBox>
            <div style={{ flex: 1 }}>
                <SwitchToggle initialValue={active} onClick={switchToggler} />
            </div>
        </FlexBox>
    );
};

export default FilterSwitch;
