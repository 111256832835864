import { connect, ConnectedProps } from 'react-redux';
import { AppRootState } from 'store/types';
import { getOrderStatuses, getSingleOrder } from 'modules/OrdersService/OrdersService.selectors';
import { isClientDebug } from 'modules/UserProfileService/UserProfileService.selectors';
import {
    OrderDetailClear,
    OrdersDetailInit,
    OrdersServiceInit,
} from 'modules/OrdersService/OrdersService.actions';

const mapState = (state: AppRootState) => ({
    order: getSingleOrder(state),
    isClientDebug: isClientDebug(state),
    statuses: getOrderStatuses(state),
});

const mapDispatch = {
    OrdersServiceInit,
    OrdersDetailInit,
    OrderDetailClear,
};

export const ClientOrderDetailCardConnector = connect(mapState, mapDispatch);
export type ClientOrderDetailCardConnectProps = ConnectedProps<
    typeof ClientOrderDetailCardConnector
>;
