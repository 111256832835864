import React from 'react';
import { Button, Input } from 'pyatka-ui';
import { useLoader } from 'contexts/useLoader';
import { Loader } from 'plugins/loader';
import { useForm } from 'hooks';

const formInitial = {
    login: '',
    password: '',
};

interface IAuthBaseFormComponent {
    onBaseFormSubmit: (e: any) => void;
}

const AuthBaseFormComponent = (props: IAuthBaseFormComponent) => {
    const { onBaseFormSubmit } = props;

    const { isLoading } = useLoader();
    const isLoadingAuthBasic = isLoading(Loader.authBasic.indicator);

    const formSubmit = (_e: any) => {
        onBaseFormSubmit(form);
    };

    const { form, formChangeHandler, formSubmitHandler, errors } = useForm(formInitial, {
        validators: {
            login: {
                required: true,
                rules: ['onEmail'],
            },
            password: {
                required: true,
                rules: ['onPassword'],
            },
        },
        submit: formSubmit,
    });

    return (
        <form onSubmit={formSubmitHandler}>
            <Input
                p={3}
                py={3}
                name="login"
                type="email"
                color={'text.main'}
                fontSize={[2]}
                borderRadius={17}
                value={form?.login || ''}
                onChange={formChangeHandler}
                errorLabel={errors?.login || ''}
                placeholder="Ваш электронный адрес"
                bg={{ default: 'block.main', md: 'block.main' }}
            />
            <Input
                p={3}
                py={3}
                fontSize={[2]}
                name="password"
                type="password"
                color={'text.main'}
                borderRadius={17}
                placeholder="Ваш пароль"
                value={form?.password || ''}
                onChange={formChangeHandler}
                errorLabel={errors?.password || ''}
                bg={{ default: 'block.main', md: 'block.main' }}
            />
            <Button
                width="100%"
                borderRadius={8}
                variant="brand"
                styleType="contained"
                disabled={isLoadingAuthBasic || Object.keys(errors).length !== 0}
            >
                Войти
            </Button>
        </form>
    );
};

export default AuthBaseFormComponent;
