import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
    getProductReviews,
    getProductReviewsFailed,
    getProductReviewsSucceed,
    SubmitReview,
} from 'modules/ReviewService/ReviewService.actions';
import {
    createMerchReview,
    createProductReview,
    editMerchReview,
    editProductReview,
    fetchProductReviews,
    createPhotoProductReview,
} from 'modules/ReviewService/ReviewService.services';
import { Dialog } from 'plugins/dialog';
import { AppToaster } from 'plugins/app-toaster';
import { OrdersDetailInit } from 'modules/OrdersService/OrdersService.actions';
import { Loader } from 'plugins/loader';

const reviewServices: {
    [key: string]: any;
} = {
    'merch-create': createMerchReview,
    'merch-edit': editMerchReview,
    'product-create': createProductReview,
    'product-edit': editProductReview,
};

/* TODO : fix the type */
function* submitReviewHandler({ payload }: any) {
    Loader.review.on();
    try {
        const { serviceName, review, order_id, client_debug } = payload;

        if (client_debug) {
            yield call(createPhotoProductReview, review);
        } else {
            yield call(reviewServices[serviceName], review);
        }

        yield put(OrdersDetailInit({ order_id: order_id }));
        AppToaster.success({
            body: 'review_successfully_left',
        });
    } catch (e) {
        AppToaster.warning({
            body: 'failed_to_create_review',
        });
        console.log(e);
    }
    Loader.review.off();
    Dialog.closeDialog();
}

//TODO: type of payload action
function* handleGetProductReviews(_action: any) {
    try {
        const { payload } = _action;

        Loader.productReviews.on({ global: false });

        const { data } = yield call(fetchProductReviews, payload);

        yield put(getProductReviewsSucceed(data));
    } catch (e) {
        getProductReviewsFailed();
    }
    Loader.productReviews.off();
}

export default function* () {
    yield all([
        takeLatest(SubmitReview, submitReviewHandler),
        takeLatest(getProductReviews, handleGetProductReviews),
    ]);
}
