import React from 'react';
import { useParams } from 'react-router';
import { Icon } from 'pyatka-ui';
import { useDispatch, useSelector } from 'react-redux';
import { selectIsInFavorites } from 'modules/Wishlist/Wishlist.selectors';
import { toggleItemInWishlist } from 'modules/Wishlist/Wishlist.actions';
import { IconProps } from '../types';

export const FavoriteBtn: React.FC<IconProps> = () => {
    const { product_id }: any = useParams();
    const dispatch = useDispatch();

    // const { toggleWishlistItem, isInFavorites, wishlistProductIds } = useWishlist();
    const isFavorite = useSelector((state) => selectIsInFavorites(state, product_id));
    const { collection_id }: any = useParams();

    // const isFavorite = React.useMemo(() => isInFavorites(product_id), [wishlistProductIds]);

    const onClickFavorite = () => {
        dispatch(toggleItemInWishlist({ product_id: product_id, collection_id }));

        // toggleWishlistItem({ product_id: String(product_id) });
    };

    if (isFavorite) {
        return <Icon onClick={onClickFavorite} icon="favorite_filled" />;
    }
    return <Icon onClick={onClickFavorite} icon="favorite_outlined" />;
};
