import React from 'react';
import { FlexBox, Carousel, Modal, Image, usePyatka, Container, Row, Col, Box } from 'pyatka-ui';
import QuickPinchZoom, { make3dTransformValue } from 'react-quick-pinch-zoom';
import styled from 'styled-components';
import { CloseBtn } from 'components/Modal/Modal.Layout';
import { Thumbnail } from './ProductSlider';

interface LightBoxModalProps {
    is_open: boolean;
    handleClose: () => void;
    first_open: number;
    galleryPhotos: string[];
    // fullscreen?: boolean;
    show_dots?: boolean;
    is_bounded?: boolean;
}

const LightBoxModal: React.FC<LightBoxModalProps> = ({
    is_open = false,
    handleClose,
    first_open = 0,
    galleryPhotos = [],
    // fullscreen = false,
    show_dots = false,
    is_bounded = false,
}) => {
    const { scales } = usePyatka();
    const isMobile = scales?.isMobile;
    const [active, setActive] = React.useState(first_open);

    if (!isMobile) {
        return (
            <Modal fullScreen showCloseButton maxHeight="100%" open={is_open} onClose={handleClose}>
                <Container style={{ maxWidth: 1036 }}>
                    <FlexBox alignItems="flex-start" justifyContent="center" gap="48px">
                        <FlexBox
                            gap="10px"
                            hideScrollBar
                            flexBasis="100px"
                            scrollWidth="100%"
                            flexDirection="column"
                            height="350px"
                        >
                            {galleryPhotos.map((url, index) => (
                                <Thumbnail
                                    key={url}
                                    url={url}
                                    isLoading={false}
                                    isActive={active === index}
                                    onClick={() => setActive(index)}
                                />
                            ))}
                        </FlexBox>
                        <Box style={{ flex: 1 }} maxWidth="60%">
                            <Carousel
                                showDots={false}
                                carouselType="swipe"
                                position={active}
                                showNavButtons={false}
                                slidesToShowGroup={{ mobile: 1, tablet: 1, desktop: 1 }}
                                isBounded={is_bounded}
                            >
                                {galleryPhotos.map((url) => (
                                    <Image
                                        key={url}
                                        src={url}
                                        width="100%"
                                        maxWidth="100%"
                                        backgroundSize="contain"
                                        backgroundPosition="center center"
                                    />
                                ))}
                            </Carousel>
                        </Box>
                    </FlexBox>
                </Container>
            </Modal>
        );
    }

    return (
        <Modal
            fullScreen
            open={is_open}
            onClose={handleClose}
            showCloseButton={false}
            maxHeight="100%"
            customHeader={
                <FlexBox position="absolute" zIndex={10} padding="10px" right={0}>
                    <CloseBtn closeButtonHandler={handleClose} />
                </FlexBox>
            }
            padding="0px"
        >
            <FlexBox height="100%" alignItems="center" justifyContent="center">
                <ImageCrop>
                    <Carousel
                        position={first_open}
                        showDots={show_dots}
                        carouselType="swipe"
                        showNavButtons={false}
                        slidesToShowGroup={{ mobile: 1, tablet: 1, desktop: 1 }}
                        isBounded={is_bounded}
                    >
                        {galleryPhotos.map((url) => (
                            <Image
                                key={url}
                                src={url}
                                appearance="small"
                                maxWidth="100%"
                                backgroundSize="contain"
                                backgroundPosition="center center"
                                heigth="100%"
                                width="100%"
                            />
                        ))}
                    </Carousel>
                </ImageCrop>
            </FlexBox>
        </Modal>
    );
};

export const ImageCrop: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const imgRef = React.useRef<HTMLDivElement>(null);

    const onUpdate = React.useCallback(({ x, y, scale }: any) => {
        if (imgRef.current) {
            const value = make3dTransformValue({ x, y, scale });
            imgRef.current?.style.setProperty('transform', value);
        }
    }, []);

    return (
        <QuickPinchZoom
            containerProps={{
                style: {
                    touchAction: 'auto',
                    width: '100%',
                    height: '100%',
                },
            }}
            onUpdate={onUpdate}
            tapZoomFactor={1}
            doubleTapZoomOutOnMaxScale={true}
            zoomOutFactor={1}
            draggableUnZoomed={false}
            shouldInterceptWheel={() => false}
            maxZoom={2}
        >
            <Wrapper ref={imgRef}>{children}</Wrapper>
        </QuickPinchZoom>
    );
};

const Wrapper = styled.div`
    & div[data-testid='dots'] {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 5%;
    }
`;

export default LightBoxModal;
