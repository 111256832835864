import { useCallback, useState } from 'react';
import {
    CheckoutDeliveryData,
    CheckoutDeliveryPageData,
    InitialCheckoutDeliveryPageData,
} from 'pages/CheckoutDeliveryV2Page/types';

export const CheckoutDeliveryPageHookShippingData = () => {
    const [delivery_type, setDeliveryType] =
        useState<CheckoutDeliveryPageData['delivery_type']>(null);
    const [shipping_selected_company, setShippingCompany] =
        useState<CheckoutDeliveryData['shipping_selected']['company']>(null);
    const [shipping_selected_price, setShippingPrice] =
        useState<CheckoutDeliveryData['shipping_selected']['price']>(null);
    const [shipping_selected_interval_date, setShippingIntervalDate] =
        useState<CheckoutDeliveryData['shipping_selected']['interval_date']>(null);
    const [shipping_selected_interval_time, setShippingIntervalTime] =
        useState<CheckoutDeliveryData['shipping_selected']['interval_time']>(null);
    const setIntervalData = useCallback(
        (
            date: CheckoutDeliveryData['shipping_selected']['interval_date'],
            time: CheckoutDeliveryData['shipping_selected']['interval_time']
        ) => {
            setShippingIntervalDate(date);
            setShippingIntervalTime(time);
        },
        []
    );

    const [pickup_selected_store_location_id, setStoreLocationId] =
        useState<CheckoutDeliveryData['pickup_selected']['store_location_id']>(0);

    const [postomat_selected_id, setPostomatId] =
        useState<CheckoutDeliveryData['postomat_selected']['postomat_id']>('');

    const [postomat_selected_provider, setPostomatProvider] =
        useState<CheckoutDeliveryData['postomat_selected']['postomat_provider']>(null);

    const shipping_selected_data = useCallback(
        (data: InitialCheckoutDeliveryPageData): CheckoutDeliveryData['shipping_selected'] => {
            const result: CheckoutDeliveryData['shipping_selected'] = {
                price: shipping_selected_price,
                company: shipping_selected_company,
                interval_date: shipping_selected_interval_date,
                interval_time: shipping_selected_interval_time,
            };
            if (
                data?.delivery_types.includes('shipping') &&
                data?.shipping.variants !== null &&
                Array.isArray(data?.shipping.variants) &&
                data?.shipping.variants.length > 0
            ) {
                const [default_company] = data?.shipping.variants;
                const [default_date = null] = default_company?.intervals || [];
                const [default_time = null] = default_date?.intervals || [];
                if (shipping_selected_price === null) {
                    setShippingPrice(default_company?.price);
                    return result;
                }
                if (shipping_selected_company === null) {
                    setShippingCompany(default_company?.id);
                    return result;
                }
                // Means Company has been selected
                if (shipping_selected_interval_date === null) {
                    result.company = shipping_selected_company;
                    setShippingIntervalDate(default_date && default_date.value);
                    return result;
                }
                // Means Date has been selected
                if (shipping_selected_interval_time === null) {
                    result.interval_date = shipping_selected_interval_date;
                    const [selected_time] = default_company?.intervals.find(
                        (interval) => interval.value === shipping_selected_interval_date
                    )?.intervals;
                    setShippingIntervalTime(
                        selected_time ? selected_time.value : default_time && default_time.value
                    );
                    return result;
                }
                return result;
            }
            return result;
        },
        [
            shipping_selected_price,
            shipping_selected_company,
            shipping_selected_interval_date,
            shipping_selected_interval_time,
        ]
    );

    const pickup_selected_data = useCallback(
        (data: InitialCheckoutDeliveryPageData): CheckoutDeliveryData['pickup_selected'] => {
            const result: CheckoutDeliveryData['pickup_selected'] = {
                store_location_id: pickup_selected_store_location_id,
            };
            if (
                data?.delivery_types.includes('pickup') &&
                data?.pickup.variants !== null &&
                data?.pickup?.variants.length > 0
            ) {
                const [default_location_id] = data?.pickup?.variants;
                if (pickup_selected_store_location_id === 0) {
                    setStoreLocationId(default_location_id?.store_location_id);
                    return result;
                }
            }
            return result;
        },
        [pickup_selected_store_location_id]
    );

    const postomat_selected_data = useCallback(
        (data: InitialCheckoutDeliveryPageData): CheckoutDeliveryData['postomat_selected'] => {
            const result: CheckoutDeliveryData['postomat_selected'] = {
                postomat_id: postomat_selected_id,
                postomat_provider: postomat_selected_provider,
            };

            if (
                data?.delivery_types?.includes('postomat') &&
                data?.postomat?.variants !== null &&
                data?.postomat?.variants.length > 0
            ) {
                const [default_postomat] = data.postomat.variants;
                if (postomat_selected_id?.length < 1) {
                    setPostomatId(default_postomat?.postomat_id);
                    setPostomatProvider(default_postomat?.postomat_provider);
                    return result;
                }
            }

            return result;
        },
        [postomat_selected_id]
    );

    return {
        delivery_type,
        shipping_selected_data,
        shipping_selected_price,
        pickup_selected_data,
        postomat_selected_data,

        setDeliveryType,
        setShippingCompany,
        setShippingIntervalDate,
        setShippingIntervalTime,
        setStoreLocationId,
        setPostomatId,

        setIntervalData,
    };
};
