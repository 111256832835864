import { MailInitialState } from 'modules/Mail/types';

const state: MailInitialState = {
    formData: {
        bin: '',
        contact_phone: '',
        contact_email: '',
        agreement_accepted: 1,
    },
};
export default state;
