import React from 'react';
import { Box, Button, FlexBox, LoadingIndicator, Text } from 'pyatka-ui';
import { signs } from 'utils/symbols';
import { WithDiscount } from 'components';
import { useI18n } from 'hooks/useI18n';

const AddToCartBlock = (props) => {
    const { $t } = useI18n();
    const {
        productAmount,
        discountPrice = '',
        price = 0,
        sendMetric,
        handleAddToCart,
        isDisabledAddToCart = false,
        data,
        ...rest
    } = props;
    const showDiscount = Boolean(discountPrice);
    const handleClick = () => {};
    return (
        <Box
            // borderTopWidth={2}
            // borderTopStyle="solid"
            // borderTopColor="block.secondary"
            style={{
                boxShadow: '0px 4px 24px 0px #00000014',
            }}
            borderRadius={'24px 24px 0 0'}
            padding="12px 18px"
            position="fixed"
            bg="block.main"
            zIndex={100}
            bottom={'56px'}
            right={0}
            left={0}
            {...rest}
        >
            <FlexBox mb={18} gap={'6px'}>
                <Text fontSize={'14px'} fontWeight={600}>
                    {$t('in_collection')}:
                </Text>
                <Text color={'text.secondary'} fontWeight={500} fontSize={'14px'}>
                    {productAmount}
                </Text>
            </FlexBox>
            <FlexBox
                alignItems="center"
                justifyContent="space-between"
                backgroundColor={'block.secondary'}
                padding={'8px 18px'}
                borderRadius={'24px'}
            >
                <FlexBox justifyContent="center" gap="4px" flexDirection={'column'}>
                    <FlexBox gap={'4px'}>
                        <Text color={'text.secondary'} fontSize={'14px'} lineHeight={'18.2px'}>
                            {$t('total')}
                        </Text>
                        <WithDiscount show={showDiscount}>
                            <Text
                                fontSize="14px"
                                fontWeight="700"
                                color={showDiscount ? 'text.placeholder' : 'transparent'}
                            >
                                {price} <span style={{ opacity: '0.5' }}>{signs.tg}</span>
                            </Text>
                        </WithDiscount>
                    </FlexBox>

                    <Text
                        fontSize="20px"
                        fontWeight={700}
                        lineHeight="26px"
                        color={showDiscount ? 'text.brand' : 'text.main'}
                    >
                        {discountPrice || price} <span style={{ opacity: '0.5' }}>{signs.tg}</span>
                    </Text>
                </FlexBox>

                <Box minWidth="104px">
                    <Button
                        width="100%"
                        height="34px"
                        variant="brand"
                        padding="8px 5px"
                        borderRadius="20px"
                        onClick={handleAddToCart}
                        disabled={isDisabledAddToCart}
                        styleType="contained"
                    >
                        {props.isRepeatOrderLoading ? (
                            <LoadingIndicator />
                        ) : (
                            <Text fontSize="14px" fontWeight={600} lineHeight="17.92px">
                                {$t('to_cart').toCapitalizeFirstLetter()}
                            </Text>
                        )}
                    </Button>
                </Box>
            </FlexBox>
        </Box>
    );
};

export default AddToCartBlock;
