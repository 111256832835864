import slice from 'modules/Cities/Cities.slice';

export const {
    fetchCities,
    fetchCitiesSucceed,
    fetchCitiesFailed,
    setSelectedCity,
    fetchCityPolygon,
    fetchCityPolygonSucceed,
    fetchCityPolygonFailed,
} = slice.actions;
