import React from 'react';
import { FlexBox, Icon } from 'pyatka-ui';
// import { AppLink } from 'components';

const ArrowIcon = (props: { orderId?: number }) => {
    const { orderId } = props;
    return (
        <FlexBox alignItems={'center'} justifyContent="center">
            {/*<AppLink to={{ name: 'client-order-detail-page', params: { order_id: orderId } }}>*/}
            <Icon icon={'orderArrow'} />
            {/*</AppLink>*/}
        </FlexBox>
    );
};

export default ArrowIcon;
