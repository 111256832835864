import { createSelector } from 'reselect';
import { AppRootState } from 'store/types';
import { AppCity } from 'modules/Cities/types';

export const getCitiesReducer = (state: AppRootState) => {
    return state.CitiesReducer;
};

export const getCitiesState = createSelector([getCitiesReducer], (state) => state);

export const getSelectedCityId = createSelector([getCitiesState], (state): string =>
    String(state.selectedCity)
);

export const getCitiesList = createSelector([getCitiesState], (state) => state.cities || []);

export const getCities = createSelector([getCitiesList], (cities): AppCity[] => {
    return cities.map((city: any, _idx: number) => ({
        cityName: city.city,
        c: city.c,
        cityId: String(city.city_id),
    }));
});

export const getSelectedCityData = createSelector(
    [getCities, getSelectedCityId],
    (cities, selectedCity) => {
        const city = cities.find((city: AppCity) => city.cityId === selectedCity);
        return city !== undefined ? city : null;
    }
);
export const getCityPolygon = createSelector(
    [getCitiesState],
    (state) => state.cityPolygonData?.polygon || []
);
