import styled from 'styled-components/macro';
import { FlexBox, Input, Text } from 'pyatka-ui';

export const Title = styled(Text)<{ isDesktop?: boolean }>`
    font-size: ${(props) => (props.isDesktop ? '28px' : '18px')};
    font-weight: 700;
    line-height: 23.4px;
`;
export const CollectionsWrapper = styled(FlexBox)`
    //align-items: center;
    flex-wrap: nowrap;
    flex: 0 0 auto;
    overflow-x: auto;
    //height: 100px; // 64px
    scroll-behavior: smooth;
    margin: 0 -18px;
    padding: 0 18px;

    /* hide scrollbar */
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
        display: none;
    }
`;
export const Overlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 23.44px;
    background-color: #bebeb61a;
    pointer-events: none;
    z-index: 10;
`;
export const StyledInput = styled(Input)<{ isMobile?: boolean }>`
    border: none;
    border-bottom: 2px solid #aaadb2;
    padding: 0 10px 4px;
    width: ${(props) => (!props.isMobile ? '160px' : '100px')};

    height: ${(props) => (!props.isMobile ? '43px' : '36px')};

    input,
    input::placeholder {
        text-align: center;
    }
`;
