import slice from 'modules/OrdersService/OrdersService.slice';

export const {
    OrdersServiceInit,
    OrdersServiceSucceed,
    OrdersServiceFailed,
    OrdersServiceClear,
    CancelReasonInit,
    CancelReasonSucceed,
    CancelReasonClear,
    OrderDetailClear,
    OrdersDetailInit,
} = slice.actions;
