import React from 'react';
import { Icon } from 'pyatka-ui';
import { AvatarProps } from 'components/UserNavigation/types';
import { AvatarWrapper } from 'components/UserNavigation/UserNavigation.Styles';

export const Avatar = (props: AvatarProps) => {
    const { icon, onAvatarClick, isLoggedIn, cursor } = props;
    return (
        <AvatarWrapper
            cursor={cursor}
            onClick={onAvatarClick}
            onMouseEnter={() => (isLoggedIn ? onAvatarClick() : {})}
        >
            <Icon icon={icon} />
        </AvatarWrapper>
    );
};

export default Avatar;
