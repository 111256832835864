import React from 'react';

const useSlide = (length: number) => {
    const ref = React.useRef<HTMLDivElement>(null);

    const [scrollX, setscrollX] = React.useState(0);
    const [scrolEnd, setscrolEnd] = React.useState(false);

    const showLeft = scrollX !== 0;
    const showRight = !scrolEnd;

    React.useEffect(() => {
        setscrollX(0);
        setscrolEnd(false);
        return () => {
            setscrollX(0);
            setscrolEnd(false);
        };
    }, [length]);

    const slide = React.useCallback(
        (shift: number) => {
            if (ref.current) {
                ref.current.scrollLeft += shift;
                setscrollX(scrollX + shift);

                if (
                    Math.floor(ref.current.scrollWidth - ref.current.scrollLeft) <=
                    ref.current.offsetWidth
                ) {
                    setscrolEnd(true);
                } else {
                    setscrolEnd(false);
                }
            }
        },
        [scrollX]
    );

    const goNext = React.useCallback(() => {
        slide(+500);
    }, [slide]);

    const goPrev = React.useCallback(() => {
        slide(-500);
    }, [slide]);

    const scrollCheck = React.useCallback(() => {
        if (ref.current) {
            setscrollX(ref.current.scrollLeft);
            if (
                Math.floor(ref.current.scrollWidth - ref.current.scrollLeft) <=
                ref.current.offsetWidth
            ) {
                setscrolEnd(true);
            } else {
                setscrolEnd(false);
            }
        }
    }, []);
    return { ref, scrollCheck, goNext, goPrev, showLeft, showRight };
};

export default useSlide;
