import { createSelector } from 'reselect';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import {
    DeliveryInfo,
    IStatus,
    OrderItem,
    OrdersServiceInitialState,
} from 'modules/OrdersService/types';
import { AppRootState } from 'store/types';
import { ProductsParser } from 'modules/ProductsList/ProductsList.helpers';
import { Status } from 'pages/ClientOrderDetailPage/components/ReviewButton';
import { DEFAULT_REVIEW_VALUES } from 'modules/ReviewService/ReviewService.state';
import { Product } from 'modules/ProductsList/types';
import { isClientDebug } from 'modules/UserProfileService/UserProfileService.selectors';
import { isInterval1BeforeInterval2, prepareDeliveryInfo } from 'modules/OrdersService/helper';
import initialState from './OrdersService.state';

dayjs.extend(customParseFormat);

const DeliveryTypes = {
    O: 'postomat',
    Q: 'pickup',
    J: 'shipping_jpost',
    L: 'shipping_merchant',
    N: 'without_delivery',
};

const getOrdersRaw = (state: AppRootState) => {
    return state.OrdersServiceReducer || initialState;
};

const getOrdersState = createSelector([getOrdersRaw], (state: OrdersServiceInitialState) => {
    return state;
});

export const getListOfOrders = createSelector([getOrdersState], (items) => {
    const { orders } = items;
    return (orders || [])
        .filter((order) => order.order_source !== 'QR')
        .map((order: OrderItem) => ({
            orderId: order.order_id,
            orderStatus: order.status,
            orderStatusText: order.status_short_description || order.status_description,
            timestamp:
                order.timestamp && dayjs(new Date(order.timestamp * 1000)).format('DD.MM.YYYY'),
            products: ProductsParser(order?.products, true),
            can_be_paid: Boolean(order.can_be_paid),
            total: order.total,
            order_source: order.order_source,
            createdAt: dayjs(new Date(order?.created_at)).format('DD.MM.YYYY'),
        }));
});

export const isCanceled = createSelector([getOrdersState], (cancelData) => {
    const { cancelReason = {}, orderItem = {} } = cancelData;
    return (
        cancelReason?.data?.changed_status === 'I' ||
        cancelReason?.data?.changed_status === 'D' ||
        orderItem?.status === 'I' ||
        orderItem?.status === 'D'
    );
});

export const getStatuses = createSelector([getOrdersState], (statuses) => {
    const { orderItem } = statuses;
    const { status_logs } = orderItem;
    const allowed = ['pre_status', 'current_status', 'next_status'];
    const filteredFields =
        status_logs &&
        Object.keys(status_logs)
            .filter((key) => allowed.includes(key))
            .reduce((obj: any, key) => {
                // eslint-disable-next-line security/detect-object-injection
                obj[key] = status_logs[key];
                return obj;
            }, {});

    const result: any = {};

    Object.entries(filteredFields || {}).forEach(
        ([key, value]) =>
            // eslint-disable-next-line security/detect-object-injection
            (result[key] = Array.isArray(value)
                ? value.map((val) => ({ ...val, type: key }))
                : Object.assign({}, value, { type: key }))
    );
    // @ts-ignore
    const { current_status, next_status = [], pre_status = [] } = result;
    const nextStatus =
        next_status.length &&
        next_status?.filter((status: { status: string }) => status?.status !== 'Q');

    let mergedArr;
    if (current_status && Array.isArray(pre_status) && Array.isArray(nextStatus)) {
        mergedArr = [...pre_status, current_status, ...nextStatus];
        console.log('### mergedArr', mergedArr);
    } else {
        mergedArr = [pre_status, current_status, nextStatus];
    }
    return { mergedArr, currentStatusDone: current_status?.status === 'Q' };
});

// @ts-ignore
export const getSingleOrder = createSelector(
    [getOrdersState, isClientDebug, isCanceled],
    (state: OrdersServiceInitialState, _client_debug: boolean, isCancelled: boolean): OrderItem => {
        const { orderItem = {} } = state;
        // @ts-ignore
        const {
            products = [],
            review = {},
            company_id,
            order_id,
            status,
            shipping_date,
            bonuses_state,
            bonuses_total,
            company_info,
            delivery_type,
            delivery,
        } = orderItem;

        const { can_send_review, left_reviews: reviews } = review;

        const delivery_type_name = delivery_type && DeliveryTypes[delivery_type];

        const deliveryInfo = delivery && delivery[delivery_type_name];

        const shippingInfo = prepareDeliveryInfo(delivery_type_name, deliveryInfo);

        /* ---------------------------------- DELIVERY TIME ------------------------------------- */

        // const parseShippingDate = (shipping_date: string = '') => {
        //     const [date, time] = shipping_date?.split(' ');
        //     return {
        //         date: dayjs(date, 'YYYY-MM-DD').format('D MMMM'),
        //         time,
        //     };
        // };
        //
        // const parseDeliveryInterval = (delivery_interval: string = '') => {
        //     const [date, time] = delivery_interval?.split(',');
        //
        //     return {
        //         date: dayjs(date, 'DD.MM.YYYY').format('D MMMM'),
        //         time: time?.replace('-', 'до'),
        //     };
        // };
        //
        // const isValidString = (date: string) => {
        //     return typeof date === 'string' && Boolean(date.length);
        // };
        //
        // const deliveryDate = isValidString(shipping_date)
        //     ? parseShippingDate(shipping_date)
        //     : isValidString(shipping?.client_time_interval_for_delivery)
        //     ? parseDeliveryInterval(shipping?.client_time_interval_for_delivery)
        //     : { date: '', time: '' };

        /* ----------------------------------- BONUSES -------------------------------------------*/

        const showBonuses =
            status !== 'K' && status !== 'I' && status !== 'D' && Boolean(bonuses_total);

        let bonusText = 'will_be_credited_after_receiving_products';

        if (showBonuses) {
            if (!shipping_date) {
                bonusText = 'will_be_credited_14_days_after_receiving_products';
            } else {
                const formatted_shipping_date = dayjs(shipping_date, 'YYYY-MM-DD hh:mm:ss');
                const daysDiff = dayjs(new Date()).diff(formatted_shipping_date, 'days');
                if (daysDiff >= 14) {
                    bonusText = 'have_been_credited';
                } else {
                    const bonusDate = dayjs(shipping_date).add(15, 'days').format('DD.MM.YYYY');
                    bonusText = `will_be_credited ${bonusDate}`;
                }
            }
        }

        /* -------------------------------------------------------------------------------------- */

        /* ---------------------------------- Merchant Phone ------------------------------------ */

        const merchant_phone = company_info?.phone_for_client;

        const getFormattedPhone = (phone: string | undefined | null) => {
            if (typeof phone === 'string') {
                if (phone.length) {
                    if (phone.charAt(0) === '+') {
                        return phone;
                    }
                    return '+' + phone;
                }
            }

            return null;
        };

        const formatted_merchant_phone = getFormattedPhone(merchant_phone);
        /* -------------------------------------------------------------------------------------- */
        const refundFailedInfo =
            status === 'B' && orderItem?.status_logs?.current_status?.refund_status === 'canceled'
                ? orderItem?.status_logs?.current_status?.refund_reason
                : '';

        const parsedProducts = ProductsParser(products);

        const rawOrder = {
            ...orderItem,
            bonusText,
            showBonuses,
            formatted_merchant_phone,
            shippingInfo,
            delivery_type_name,
            refundFailedInfo,
        };
        // Products in orders can be reviewed only if their status is: "Q" (Доставлено)
        // task/CT-698: Скрыть функционал отзывов
        // if (client_debug) {
        if (status === 'Q' || status === 'S') {
            const productsWithReviews = addReviewsToProducts(
                parsedProducts,
                reviews,
                company_id,
                order_id,
                can_send_review
            );
            return { ...rawOrder, products: productsWithReviews };
        }
        // }

        return { ...rawOrder, products: parsedProducts, isCancelled };
    }
);

const addReviewsToProducts = (
    products: Product[],
    reviews: any[],
    company_id: number,
    order_id: number,
    can_send_review?: boolean
) => {
    const getProductReviewsDict = (review: any[]) => {
        const products_reviews_by_id: {
            [key: number]: any;
        } = {};

        return review
            .filter((review: any) => Boolean(review?.product_id))
            .reduce((prevVal, curVal) => {
                return {
                    ...prevVal,
                    [curVal.product_id]: curVal,
                };
            }, products_reviews_by_id);
    };

    // const productReviews = review.filter((review: any) => review.type_code === 'P');
    const productReviewsDict = getProductReviewsDict(reviews);

    const generateReview = (
        review: any,
        company_id: number,
        order_id: number,
        product: Product,
        can_send_review?: boolean
    ) => {
        const ReviewStatuses: {
            [key: number]: Status;
        } = {
            0: 'on_moderation',
            1: 'processing',
            2: 'accepted',
            4: 'declined',
            6: 'complaint_processing',
            7: 'complaint_processed',
        };

        if (review) {
            return {
                values: {
                    status:
                        review.rate === null ? 'create_review' : ReviewStatuses[review?.state_code],
                    company_id,
                    order_id: review?.order_id,
                    product_id: review?.product_id,
                    rate: review.rate || 0,
                    limitations: review.limitations || '',
                    dignity: review.dignity || '',
                    comment: review.comment || '',
                    is_recommended: review.is_recommended || false,
                    comment_admin: review.comment_admin || '',
                    type: review.type_code || 'P',
                    images: review?.images || [],
                    product_name: product?.productName,
                    product_image: product?.image,
                },
            };
        }

        if (can_send_review) {
            return {
                values: {
                    ...DEFAULT_REVIEW_VALUES,
                    company_id,
                    order_id,
                    product_id: product?.product_id,
                    type: product?.type,
                    product_name: product?.productName,
                    product_image: product?.image,

                    // photos: review?.images || [],
                },
            };
        }

        return null;
    };

    return products.map((product: Product) => ({
        ...product,
        review: generateReview(
            productReviewsDict[product.product_id],
            company_id,
            order_id,
            product,
            can_send_review
        ),
    }));
};

export const getOrdersPagination = createSelector([getOrdersState], (items) => {
    const { pagination } = items;
    return {
        currPage: pagination?.current_page,
        lastPage: pagination?.last_page,
        from: pagination?.from,
        to: pagination?.to,
        perPage: pagination?.per_page,
        maxPages: pagination?.total,
    };
});

export const getOrderStatuses = createSelector(
    [getOrdersState, getSingleOrder],
    (state, formattedOrder) => {
        const { orderItem } = state;
        const { status_logs } = orderItem;
        const pre_status = Array.isArray(status_logs?.pre_status)
            ? status_logs?.pre_status
            : [status_logs?.pre_status];
        const next_status = Array.isArray(status_logs?.next_status)
            ? status_logs?.next_status
            : [status_logs?.next_status];

        const interval2 =
            orderItem?.transport_company?.length > 0
                ? `${orderItem.transport_company[0].delivery_date}, ${orderItem.transport_company[0].delivery_intervals} `
                : '';
        const isDeliveryLate = orderItem?.shipping?.client_time_interval_for_delivery
            ? isInterval1BeforeInterval2(
                  orderItem?.shipping?.client_time_interval_for_delivery,
                  interval2
              )
            : false;
        let delivery_info: DeliveryInfo = {};

        const generateTitleAndDescription = (
            isDeliveryLate: boolean,
            formattedOrder: any,
            orderItem: any
        ): DeliveryInfo => {
            const description = !isDeliveryLate
                ? 'expected_delivery_date'
                : 'delivery_delayed_new_date';

            const title = !isDeliveryLate
                ? `${formattedOrder.shippingInfo?.shipping_date}  ${formattedOrder.shippingInfo?.shipping_interval}`
                : `${dayjs(orderItem?.transport_company[0]?.delivery_date).format(
                      'D MMMM'
                  )} ${orderItem?.transport_company[0]?.delivery_intervals?.replace('-', 'до')}`;

            return { title, description };
        };
        const hasShippingData: DeliveryInfo = {
            title: generateTitleAndDescription(isDeliveryLate, formattedOrder, orderItem).title,
            description: generateTitleAndDescription(isDeliveryLate, formattedOrder, orderItem)
                .description,
            reverse: true,
        };
        const deliveryStatusLookup: Record<string, Record<string, DeliveryInfo>> = {
            O: {
                N: { title: '', description: '' },
                default: { title: 'order_not_paid', description: 'waiting_for_payment' },
            },
            C: {
                Q: { title: 'order_paid', description: 'take_to_work_soon' },
                J: hasShippingData,
                L: hasShippingData,
                // O: { title: 'Будет вложен в постамат', description: '' },
            },
            R: {
                Q: { title: 'order_paid', description: 'take_to_work_soon' },
                J: hasShippingData,
                L: hasShippingData,
                // O: { title: 'Будет вложен в постамат', description: '' },
            },
            H: {
                Q: { title: 'order_in_process', description: 'available_pickup_soon' },
                J: hasShippingData,
                L: hasShippingData,
                // O: { title: 'Будет вложен в постамат', description: '' },
            },
            G: {
                Q: { title: 'ready_for_pickup', description: 'pickup_from_point' },
                J: hasShippingData,
                L: hasShippingData,
            },
            M: {
                O: { title: 'order_in_postamat', description: 'pickup_from_postamat' },
                J: hasShippingData,
                L: hasShippingData,
            },
        };

        const statusInfo =
            deliveryStatusLookup[orderItem.status]?.[orderItem.delivery_type] ||
            deliveryStatusLookup[orderItem.status]?.default;
        if (statusInfo) {
            delivery_info = statusInfo;
        }

        const is_valid_current_status =
            typeof status_logs?.current_status === 'object' &&
            !Array.isArray(status_logs?.current_status) &&
            status_logs?.current_status !== null;
        const status_desc = () => {
            switch (orderItem.status) {
                case 'D':
                    return 'order_rejected';
                case 'I':
                    return 'order_cancelled';
                case 'S':
                    return 'partial_return';
                case 'K':
                    return 'full_return';
                case 'Q':
                    return 'order_delivered';
                case 'B':
                    return orderItem?.refundFailedInfo ? 'to_return' : 'refund_in_progress';
                default:
                    return '';
            }
        };
        let statuses: IStatus[];
        const current_status = is_valid_current_status
            ? status_logs?.current_status
            : orderItem.status
            ? { status: orderItem.status, short: status_desc() }
            : null;

        switch (orderItem.status) {
            case 'O':
                statuses = [
                    ...pre_status.map((item) => ({
                        ...item,
                        styles: {
                            iconColor: 'text.secondary',
                            bgColor: '#F6F6F5',
                        },
                    })),
                ];
                break;
            case 'F':
            case 'E':
                statuses = [
                    {
                        ...current_status,
                        is_current: true,
                        styles: {
                            iconColor: '#fff',
                            bgColor: '#FF7348',
                        },
                    },
                ];
                break;
            case 'I':
            case 'D':
            case 'Y':
                statuses = [
                    {
                        status: orderItem.status,
                        short: status_desc() || orderItem.status_description,
                        is_current: true,
                        description: orderItem.cancelReasonInfo?.cancelReasonText,
                    },
                ];
                break;
            case 'S':
                statuses = [
                    {
                        status: orderItem.status,
                        short: status_desc() || orderItem.status_description,
                        description: 'some_items_missing_replaced',
                        is_current: true,
                        styles: {
                            iconColor: '#fff',
                            bgColor: '#9BD050',
                        },
                    },
                ];
                break;
            case 'K':
            case 'V':
                statuses = [
                    {
                        status: orderItem.status,
                        short: status_desc() || orderItem.status_description,
                        is_current: true,
                        styles: {
                            iconColor: '#fff',
                            bgColor: '#9BD050',
                        },
                    },
                ];
                break;
            case 'Q':
                statuses = [
                    {
                        status: orderItem.status,
                        short: status_desc() || orderItem.status_description,
                        description: orderItem.shipping_date
                            ? dayjs(orderItem.shipping_date).format('DD MMMM в HH:mm')
                            : '',
                        is_current: true,
                        styles: {
                            iconColor: '#fff',
                            bgColor: '#9BD050',
                        },
                    },
                ];
                break;

            case 'B':
                if (current_status?.refund_status === 'canceled') {
                    statuses = [
                        {
                            ...current_status,
                            description: current_status?.refund_reason,
                            is_current: true,
                        },
                    ];
                } else {
                    statuses = [
                        {
                            ...current_status,
                            is_current: true,
                            short: 'refund_in_progress',
                            styles: {
                                iconColor: '#fff',
                                bgColor: '#FFD65F',
                            },
                        },
                    ];
                }
                break;
            default:
                statuses = current_status
                    ? [
                          ...pre_status?.map((item) => ({
                              ...item,
                              styles: {
                                  iconColor: '#fff',
                                  bgColor: '#9BD050',
                              },
                          })),
                          {
                              ...current_status,
                              is_current: true,
                              styles: {
                                  iconColor: '#fff',
                                  bgColor: isDeliveryLate ? '#FF7348' : '#9BD050',
                              },
                          },
                          ...next_status.map((item) => ({
                              ...item,
                              styles: {
                                  iconColor: 'text.secondary',
                                  bgColor: '#F6F6F5',
                              },
                          })),
                      ]
                    : [];
                break;
        }

        return { statuses, currentStatusDone: current_status?.status === 'Q', delivery_info };
    }
);
