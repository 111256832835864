import { connect, ConnectedProps } from 'react-redux';
import { AppRootState } from 'store/types';

import { getSelectedCityData, getSelectedCityId } from 'modules/Cities/Cities.selectors';
import {
    getProfileServiceData,
    isClientDebug,
} from 'modules/UserProfileService/UserProfileService.selectors';

const mapDispatch = {};

const mapState = (_state: AppRootState) => ({
    city_id: getSelectedCityId(_state),
    profileServiceData: getProfileServiceData(_state),
    selectedCityData: getSelectedCityData(_state),
    isClientDebug: isClientDebug(_state),
});

export const CheckoutDeliveryPageConnector = connect(mapState, mapDispatch);
export type CheckoutDeliveryPageConnectProps = ConnectedProps<typeof CheckoutDeliveryPageConnector>;
