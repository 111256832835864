import React from 'react';
import { Box, Text, usePyatka } from 'pyatka-ui';
import { ProductCardLayoutProps } from 'components/ProductCard/types';

interface IProductDescription extends Pick<ProductCardLayoutProps, 'isZeroPrice'> {
    productName: string;
    lineClamp: number;
    isLoading: boolean;
}

const ProductDescription: React.FC<IProductDescription> = ({
    productName,
    lineClamp,
    isLoading,
    isZeroPrice,
}) => {
    const { scales } = usePyatka();
    const line_height = scales?.isMobile ? 17 : 24;
    const line_clamp = scales?.isMobile ? 2 : lineClamp;
    const height = `${line_height * line_clamp}px`;

    return (
        <Box
            width="100%"
            mt={{ default: '6px', md: '8px' }}
            mb={{ default: '4px', md: '12px' }}
            height={height}
        >
            <Text
                // @ts-ignore
                as="h3"
                fontWeight="500"
                color={isZeroPrice ? 'text.secondary' : 'text.main'}
                fontSize={{ default: '12px', md: '16px' }}
                lineHeight={`${line_height}px`}
                letterSpacing="0.12px"
                lineClamp={line_clamp}
                skeleton={isLoading}
                title={productName}
                margin="0px"
                skeletonProps={{
                    animation: true,
                    width: '100%',
                    borderRadius: '8px',
                    height,
                }}
            >
                {productName}
            </Text>
        </Box>
    );
};

export default ProductDescription;
