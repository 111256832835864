import React from 'react';
import { ReviewModalLayoutProps } from './types';
// import styles from './ReviewModal.module.scss';

const MERCH_REVIEW_INSTRUCTIONS = [''];

export const ReviewModalLayoutMerch: React.FC<ReviewModalLayoutProps> = (props) => {
    const { view } = props;

    // view = 'edit' | 'new' | 'view'

    return <React.Fragment>ReviewModalLayout Merch </React.Fragment>;
};
