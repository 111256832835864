import React from 'react';
import { ToastContainer, Zoom } from 'react-toastify';
import { FlexBox, Icon, Text } from 'pyatka-ui';
import { useI18n } from 'hooks/useI18n';
import { AppToasterProps, ToasterProps } from './types';
import 'react-toastify/dist/ReactToastify.css';
import styles from './AppToaster.module.scss';

export const AppToasterContainer: React.FC<ToasterProps> = () => {
    return (
        <>
            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                newestOnTop={false}
                className={styles.toastContainer}
                toastClassName={styles.toastCustom}
                transition={Zoom}
            />
        </>
    );
};

export const AppToasterTemplate: React.FC<AppToasterProps> = (props) => {
    const { head = '', body = '', icon = '', isCustom = false, children } = props;
    const { $t } = useI18n();
    if (isCustom) {
        return <>{children}</>;
    }
    return (
        <div className={styles.toastContent}>
            <FlexBox alignItems={'center'} gap={'15px'}>
                {icon && <Icon icon={icon} width={'30px'} />}
                <FlexBox flexDirection={'column'}>
                    {head && (
                        <Text
                            color={'text.placeholder'}
                            fontWeight={'bold'}
                            variant={'h6'}
                            mb={'2px'}
                        >
                            {$t(head)}
                        </Text>
                    )}
                    {body && (
                        <Text color={'text.placeholder'} variant={head ? 'h7' : 'h6'}>
                            {$t(body)}
                        </Text>
                    )}
                </FlexBox>
            </FlexBox>
        </div>
    );
};
