import React from 'react';
import { FlexBox } from 'pyatka-ui';
import { useI18n } from 'hooks/useI18n';
import DeliveryTypeBlock from './DeliveryTypeBlock';

interface DeliveryTypeFilterProps {
    pickupDeliveryTypes: any;
    activeFilters: string[];
    toggleFilter: () => void;
    layoutButtonTitle?: string;
    layoutButtonIcon?: string;
    changeLayout?: () => void;
}

const DeliveryTypeFilter = (props: DeliveryTypeFilterProps) => {
    const {
        pickupDeliveryTypes,
        activeFilters,
        toggleFilter,
        layoutButtonTitle,
        layoutButtonIcon,
        changeLayout,
    } = props;
    const { $t } = useI18n();

    const deliveryTypesParams = {
        postomat: {
            icon: 'postomat-info',
            title: 'postamat',
        },
        pickup: {
            icon: 'market',
            title: 'shop',
        },
        sdek: {
            icon: 'sdek',
            title: 'sdek_title',
        },
    };
    return (
        <FlexBox width={'100%'} justifyContent={'space-between'} gap={'6px'}>
            {pickupDeliveryTypes.map((type) => (
                <DeliveryTypeBlock
                    key={type}
                    icon={deliveryTypesParams[type].icon}
                    title={$t(deliveryTypesParams[type].title)}
                    isActive={activeFilters?.includes(type)}
                    onClick={() => {
                        toggleFilter(type);
                    }}
                />
            ))}
            {changeLayout && (
                <DeliveryTypeBlock
                    icon={layoutButtonIcon}
                    title={layoutButtonTitle}
                    onClick={changeLayout}
                />
            )}
        </FlexBox>
    );
};

export default DeliveryTypeFilter;
