import { Box, Button, Container, FlexBox, Image, Text } from 'pyatka-ui';
import React from 'react';
import NotFound from 'pages/NotFound/assets/not_found.png';
import { AppLink } from 'components';
import { useI18n } from 'hooks/useI18n';

export const CheckoutDeliveryPageLayoutError = () => {
    const { $t } = useI18n();
    return (
        <>
            <Container>
                <FlexBox
                    textAlign="center"
                    margin="0 auto"
                    width="100%"
                    maxWidth={{ default: '250px', md: '400px' }}
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    height="calc( 100vh - 160px )"
                >
                    <Box
                        width={{ default: '140px', md: '192px' }}
                        height={{ default: '140px', md: '192px' }}
                    >
                        <Image src={NotFound} alt="" />
                    </Box>
                    <Text
                        my={{ default: '12px', md: '16px' }}
                        fontSize={{ default: 5, md: 8 }}
                        fontWeight="700"
                    >
                        {$t('checkout_error_title')}
                    </Text>
                    <Text
                        mb={{ default: '12px', md: '16px' }}
                        fontSize={{ default: 4, md: 5 }}
                        color="text.placeholder"
                    >
                        {$t('checkout_error_description')}
                    </Text>
                    <FlexBox gap={'5px'}>
                        <AppLink to={{ name: 'cart-page' }}>
                            <Button
                                styleType="contained"
                                variant={'block.secondary'}
                                borderRadius="24px"
                                px={'25px'}
                            >
                                {$t('cart')}
                            </Button>
                        </AppLink>
                        <AppLink to={{ name: 'profile-page' }}>
                            <Button
                                styleType="contained"
                                variant={'brand'}
                                borderRadius="24px"
                                px={'25px'}
                            >
                                {$t('my_orders')}
                            </Button>
                        </AppLink>
                    </FlexBox>
                </FlexBox>
            </Container>
        </>
    );
};
