import React, { useState, useMemo, useEffect } from 'react';
import { Text, Input, FlexBox, Space, Button, LoadingIndicator, Icon } from 'pyatka-ui';
import { IPostomatModal } from 'pages/ClientOrderDetailPage/types';
import { OpenPostomatLocker } from 'pages/ClientOrderDetailPage/services';
import { AppToaster } from 'plugins/app-toaster';
import { Loader } from 'plugins/loader';
import { useLoader } from 'contexts/useLoader';

const LOCKER_CODE_LENGTH = 6;

export const PostomatModal = (props: IPostomatModal) => {
    const { closePostomatModal, modalView, order_id, OrdersDetailInit } = props;

    const [lockerCode, setLockerCode] = useState<string>('');

    const submitLockerCode = async () => {
        Loader.postomatOpen.on();

        try {
            await OpenPostomatLocker({
                order_id,
                locker_code: lockerCode || '',
            });
            closePostomatModal();
            AppToaster.success({ body: 'Код введен верно' });
            OrdersDetailInit({ order_id });
        } catch {
            AppToaster.error({ body: 'Произошла ошибка, попробуйте снова' });
            console.error('Error while opening locker');
        }

        Loader.postomatOpen.off();
        setLockerCode('');
    };

    const { isLoading } = useLoader();

    const isLockerLoading = isLoading(Loader.postomatOpen.indicator);

    const changeLockerCode = (code: string) => {
        setLockerCode(code);
    };

    const isLockerCodeValid = useMemo(() => {
        return lockerCode && lockerCode?.length === LOCKER_CODE_LENGTH;
    }, [lockerCode]);

    useEffect(() => {
        if (isLockerCodeValid && modalView === 'qr') {
            submitLockerCode();
        }
    }, [modalView, isLockerCodeValid, lockerCode]);

    const viewProps = {
        lockerCode,
        changeLockerCode,
        submitLockerCode,
        isLockerLoading,
        isLockerCodeValid,
    };

    return (
        <div>
            {modalView === 'qr' && <QRScanView {...viewProps} />}
            {modalView === 'code' && <CodeEnterView {...viewProps} />}
        </div>
    );
};

const QRScanView = (props: any): JSX.Element => {
    const { changeLockerCode, isLockerLoading, lockerCode } = props;

    const [result, setResult] = useState(null);

    // TODO:
    useEffect(() => {
        if (
            Boolean(result) &&
            typeof result === 'string' &&
            result !== lockerCode &&
            result?.length > 6
        ) {
            const postomat_code = result.substr(result?.length - 6);
            changeLockerCode(postomat_code);
        }
    }, [result]);

    return (
        <React.Fragment>
            <FlexBox
                flexDirection={'column'}
                alignItems={'center'}
                paddingTop={'50px'}
                justifyContent={'center'}
            >
                <Text> Наведите на QR код </Text>
                <Space height={'12px'} />
                <Icon icon={'arrow_down'} width={'8px'} height={'9px'} />
                <Space height={'20px'} />

                {!isLockerLoading && <> </>}
            </FlexBox>
        </React.Fragment>
    );
};

const CodeEnterView = (props: any): JSX.Element => {
    const { changeLockerCode, lockerCode, submitLockerCode, isLockerLoading, isLockerCodeValid } =
        props;

    const handleInputChange = (e: any) => {
        if (e?.length <= 6) {
            changeLockerCode(e?.toUpperCase());
        }
        return;
    };

    return (
        <FlexBox paddingTop={'80px'} flexDirection={'column'} alignItems={'center'}>
            <Text fontSize={'22px'} lineHeight={'30px'} fontWeight={'700'}>
                {' '}
                Введите код{' '}
            </Text>
            <Space height={'18px'} />
            <Text textAlign={'center'} fontSize={'16px'}>
                {' '}
                Введите 6-значный код для открытия ячейки{' '}
            </Text>
            <Space height={'40px'} />
            <Input
                fontSize={'21px'}
                border={'none'}
                borderBottom={'2px solid rgba(190, 190, 182, 0.4)'}
                placeholder={'Код'}
                value={lockerCode}
                textAlignValue={'center'}
                padding={'0'}
                onChange={handleInputChange}
            />
            <Space height={'32px'} />
            <Button
                padding={'18px 16px'}
                variant={isLockerCodeValid ? 'brand' : 'secondary'}
                color={isLockerCodeValid ? 'text.reverse' : 'text.secondary'}
                disabled={!isLockerCodeValid}
                styleType={'contained'}
                borderRadius={'22px'}
                width={'100%'}
                onClick={submitLockerCode}
            >
                {isLockerLoading && <LoadingIndicator size={'22px'} />}
                {!isLockerLoading && (
                    <Text fontSize={'16px'} lineHeight={'22px'}>
                        {' '}
                        Открыть ячейку{' '}
                    </Text>
                )}
            </Button>
        </FlexBox>
    );
};
