import { ArticlesInitialState } from 'modules/Articles/types';

const state: ArticlesInitialState = {
    content: {},
    staticPagesContent: {
        title: '',
        slug: '',
        link: {
            name: '',
            params: { slug: '' },
        },
    },
    singleStaticPage: {
        title: '',
        description: '',
    },
};
export default state;
