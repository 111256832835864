import React from 'react';
import { Box, FlexBox, Icon, Image, Text } from 'pyatka-ui';
import { AppLink } from 'components';
import { useI18n } from 'hooks/useI18n';
// images
import playmarket from 'shared/assets/playmarket.png';
import appstore from 'shared/assets/appstore.png';
import qr from './assets/qrCode.png';
import phoneBonus from './assets/phoneBonus.png';
// const, types
import { TermsLinksProps } from './types';
import { phoneLinks, socialLinks } from './consts';

export const AppLinks: React.FC = () => {
    const { $t } = useI18n();

    return (
        <FlexBox alignItems="center" justifyContent="space-between" px="22px">
            <Box
                p="8px"
                mt="8px"
                borderRadius="16px"
                border="8px solid #F6F6F5"
                style={{ minWidth: '125px' }}
            >
                <Image src={qr} borderRadius="16px" />
            </Box>
            <FlexBox flexDirection="column" gap="30px" mt="16px" ml="8px">
                <Text color="text.main" fontSize="22px" fontWeight={500}>
                    {$t('order_in_jusan')}
                </Text>
                <FlexBox gap="20px" justifyContent="flex-start">
                    <Box style={{ minWidth: '122px', maxHeight: '40px' }}>
                        <a
                            target="_blank"
                            rel="noreferrer"
                            aria-label="AppStore"
                            href="https://apps.apple.com/kz/app/jusan/id1441936010"
                        >
                            <Image src={appstore} appearance="33.6%" />
                        </a>
                    </Box>
                    <Box style={{ minWidth: '135px', maxHeight: '40px' }}>
                        <a
                            rel="noreferrer"
                            aria-label="Google Play"
                            href="https://play.google.com/store/apps/details?id=kz.tsb.app24&hl=ru&gl=US"
                        >
                            <Image src={playmarket} appearance="29.6%" />
                        </a>
                    </Box>
                </FlexBox>
            </FlexBox>
            <Box style={{ minWidth: '185px' }} mr="26px">
                <Image src={phoneBonus} />
            </Box>
        </FlexBox>
    );
};

export const TermsLinks: React.FC<TermsLinksProps> = ({ linkList }) => {
    const { $t } = useI18n();
    return (
        <FlexBox
            px="30px"
            height="58px"
            textAlign="center"
            alignItems="center"
            bg="block.secondary"
            borderRadius="999rem"
            justifyContent="space-between"
        >
            {linkList.map((link) => (
                <AppLink key={link.id} to={link.link} ariaLabel={$t(link.title)}>
                    <Text color="text.main" fontWeight={500} lineHeight="20.48px">
                        {$t(link.title)}
                    </Text>
                </AppLink>
            ))}
        </FlexBox>
    );
};

export const ContactLinks: React.FC = () => (
    <FlexBox alignItems="center" justifyContent="space-between" mb="35px">
        <FlexBox gap="32px">
            {phoneLinks.map((phoneLink) => (
                <Box key={phoneLink.link}>
                    <a target="_blank" href={phoneLink.link} rel="noreferrer noopener">
                        <FlexBox alignItems="center" gap="10px" color="text.secondary">
                            <Icon icon={phoneLink.icon} />
                            <Text fontWeight={500} lineHeight="18px">
                                {phoneLink.text}
                            </Text>
                        </FlexBox>
                    </a>
                </Box>
            ))}
        </FlexBox>
        <FlexBox gap="20px">
            {socialLinks.map((socialLink) => (
                <Box key={socialLink.link} color="text.secondary">
                    <a
                        target="_blank"
                        href={socialLink.link}
                        rel="noreferrer noopener"
                        aria-label={socialLink.icon}
                    >
                        <Icon icon={socialLink.icon} width="28px" height="28px" />
                    </a>
                </Box>
            ))}
        </FlexBox>
    </FlexBox>
);
