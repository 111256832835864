export const CONST_SUPS = [
    {
        title: 'supermarkets',
        ids: [
            '127',
            '417',
            '1857',
            'TOIMART',
            '340850',
            '340850', // Алмакеир
            // Astana
            '27', // Астыкжан
            // Karaganda
            '1332', // Супермаркет CITY MARKET
            // Kostanay
            '67', // Астыкжан Костанай
            '340711', // Солнечный
            // Atyrau
            '337498', // "Family stories"
        ],
    },
    {
        title: 'exclusive_shop',
        ids: [
            '340414',
            '53',
            '9592',
            '338239',
            'NATIGE',
            '497',
            '334975',
            '328339',
            '247', // Elitalco

            // Не Алматы
            '336446', // DEP
            '305438', // Flamingo
            '309787', // JIDEK.kz
            '51', // Овощной
            '54', // Продукция Цесна
            '413', // Шымбулак Water
            '328419', // SYILA
            '339998', // Ягоды Карелии
            '310776', // Bahcha
            '315426', // Дәмді ет
            '114', // Мясная лавка №1
            '338435', // "Мясная лавка №1"
        ],
    },
    { title: 'pharmacies_and_bas', ids: ['324497', '776', '340154', '2025'] },
    // { title: 'alcohol_goods', ids: ['497'] },
];

export const OBJECT_SUPS: Record<string, any> = {
    // Супермаркеты Алматы
    '127': {
        category_id: 4644,
        company_id: 127,
        partner: 'A-Store',
        company_name: 'ТОО "АлмаСтор"',
        city: 'Almaty',
        section: 'Супермаркеты и продукты',
        period: '10.00-22.00',
        bonus: '10%',
    },
    '417': {
        category_id: 7054,
        company_id: 417,
        partner: 'Carefood',
        company_name: 'ТОО “GRAND RETAIL”',
        city: 'Almaty',
        section: 'Супермаркеты и продукты',
        period: '10.00-20.00',
        bonus: '10%',
    },
    '1857': {
        category_id: 33892,
        company_id: 1857,
        partner: 'Small',
        company_name: 'ТОО «Скиф Трейд»',
        city: 'Almaty',
        section: 'Супермаркеты и продукты',
        period: '10.00-22.00',
        bonus: '10%',
    },
    '340850': {
        category_id: 58892,
        company_id: 340850,
        partner: 'ИП « АЛМАКЕИР',
        company_name: 'ИП « АЛМАКЕИР »',
        city: 'Almaty',
        section: 'Супермаркеты и продукты',
        period: '10.00-16.00',
        bonus: '10%',
    },
    // TOIMART
    // Эксклюзивные магазины Алматы
    '340414': {
        category_id: 57900,
        company_id: 340414,
        partner: 'Raw to go',
        company_name: 'ТОО "ВКУСНО И ПОЛЕЗНО"',
        city: 'Almaty',
        section: 'Супермаркеты и продукты',
        period: '10.00-20.00',
        bonus: '10%',
    },
    '53': {
        category_id: 3326,
        company_id: 53,
        partner: 'Мясной Boszhan',
        company_name: 'ТОО "Первомайские деликатесы"',
        city: 'Almaty',
        section: 'Мясные магазины',
        period: '10.00-20.00',
        bonus: '10%',
    },
    '9592': {
        category_id: 49036,
        company_id: 9592,
        partner: 'Mfood',
        company_name: 'ИП «ЗУЛЕЕВА»',
        city: 'Almaty',
        section: 'Супермаркеты и продукты',
        period: '11.00-20.00',
        bonus: '10%',
    },
    '338239': {
        category_id: 53079,
        company_id: 338239,
        partner: 'Mangosteen',
        company_name: 'ТОО «MANGOSTEEN TW»',
        city: 'Almaty, Astana',
        section: 'Супермаркеты и продукты',
        period: '10.00-16.00',
        bonus: '10%',
    },
    // NATIGE
    '497': {
        category_id: 10515,
        company_id: 497,
        partner: 'Ароматный мир',
        company_name: 'ТОО «Absolut Trade»',
        city: 'Almaty',
        section: 'Алкоголь',
        period: '10.00-20.00',
        bonus: '10%',
    },
    '334975': {
        category_id: 49036,
        company_id: 334975,
        partner: 'Livefood.kz',
        company_name: 'ИП «ЗУЛЕЕВА»',
        city: 'Almaty',
        section: 'Супермаркеты и продукты',
        period: '12.00-20.00',
        bonus: '10%',
    },
    '328339': {
        category_id: 47424,
        company_id: 328339,
        partner: 'TALHIZ',
        company_name: 'ТОО «ShakhFood»',
        city: 'Almaty',
        section: 'Мясные магазины',
        period: '10.00-16.00',
        bonus: '10%',
    },
    '247': {
        category_id: 47424,
        company_id: 247,
        partner: 'Elitalco',
        company_name: 'ТОО «Elitalco.kz»',
        city: 'Almaty',
        section: 'Эксклюзивные магазины',
        period: '10.00-20.00',
        bonus: '10%',
    },
    // Аптеки Алматы
    '324497': {
        category_id: 46467,
        company_id: 324497,
        partner: 'Europharma',
        company_name: 'ТОО «AK NIET GROUP»',
        city: 'Almaty',
        section: 'Аптеки',
        period: '10.00-22.00',
        bonus: '10%',
    },
    '776': {
        category_id: 16571,
        company_id: 776,
        partner: 'Аптека SMART',
        company_name: 'ТОО «SMART Аптека»',
        city: 'Almaty',
        section: 'Аптеки',
        period: '10.00-18.00',
        bonus: '10%',
    },
    '2025': {
        category_id: 16571,
        company_id: 2025,
        partner: 'Аптека Алма',
        company_name: 'ТОО «ALMA 2»»',
        city: 'Almaty',
        section: 'Аптеки',
        period: '10.00-20.00',
        bonus: '10%',
    },
    // ======================================================================
    '27': {
        category_id: 16249,
        company_id: 27,
        partner: 'Астыкжан',
        company_name: 'ТОО «Центр Торговли «Астыкжан»',
        city: 'Astana',
        section: 'Супермаркеты и продукты',
        period: '10.00-20.00',
        bonus: '10%',
    },
    '51': {
        category_id: 2874,
        company_id: 51,
        partner: 'Овощной',
        company_name: 'ИП "Тастанды"',
        city: 'Almaty',
        section: 'Супермаркеты и продукты',
        period: '10.00-18.00',
        bonus: '',
    },
    '54': {
        category_id: 2924,
        company_id: 54,
        partner: 'Продукция Цесна',
        company_name: 'TOO JFood distribution',
        city: 'Almaty',
        section: 'Супермаркеты и продукты',
        period: '10.00-16.00',
        bonus: '10%',
    },
    '67': {
        category_id: 3038,
        company_id: 67,
        partner: 'Гипермаркет Астыкжан (Костанай)',
        company_name: 'ТОО "Астыкжан-Костанай"',
        city: 'Kostanay',
        section: 'Супермаркеты и продукты',
        period: '10.00-20.00',
        bonus: '10%',
    },
    '340711': {
        category_id: 58482,
        company_id: 340711,
        partner: 'Супермракет Солнечный',
        company_name: 'ТОО « СУПЕРМАРКЕТ СОЛНЕЧНЫЙ»',
        city: 'Kostanay',
        section: 'Супермаркеты и продукты',
        period: '10.00-22.00',
        bonus: '10%',
    },
    '114': {
        category_id: 3288,
        company_id: 114,
        partner: 'Мясная лавка №1',
        company_name: 'ТОО «MEAT SHOP №1»',
        city: 'Astana',
        section: 'Мясные магазины',
        period: '10.00-18.00',
        bonus: '10%',
    },
    '413': {
        category_id: 8556,
        company_id: 413,
        partner: 'Шымбулак Water',
        company_name: 'ТОО «Wtr plus»',
        city: 'Almaty',
        section: 'Супермаркеты и продукты',
        period: '10.00-22.00',
        bonus: '10%',
    },
    '1332': {
        category_id: 28492,
        company_id: 1332,
        partner: 'Супермаркет CITY MARKET',
        company_name: 'ИП "CITY MARKET"',
        city: 'Karaganda',
        section: 'Супермаркеты и продукты',
        period: '10.00-18.00',
        bonus: '10%',
    },
    '305438': {
        category_id: 39866,
        company_id: 305438,
        partner: 'Flamingo',
        company_name: 'ТОО "Flamingo15',
        city: 'Almaty',
        section: 'Супермаркеты и продукты',
        period: '10.00-22.00',
        bonus: '10%',
    },
    '309787': {
        category_id: 42980,
        company_id: 309787,
        partner: 'JIDEK.KZ',
        company_name: 'ИП «Компания IKtech»',
        city: 'Astana',
        section: 'Супермаркеты и продукты',
        period: '10.00-20.00',
        bonus: '10%',
    },
    '310776': {
        category_id: 43595,
        company_id: 310776,
        partner: 'Bahcha',
        company_name: 'ТОО AVETA DISTRIBUTION',
        city: 'Almaty',
        section: 'Мясные магазины',
        period: '10.00-22.00',
        bonus: '10%',
    },
    '315426': {
        category_id: 45272,
        company_id: 315426,
        partner: 'Дәмді ет',
        company_name: 'ТОО «Торговый дом "Ордабасы-кус Алматы"»',
        city: 'Almaty',
        section: 'Мясные магазины',
        period: '10.00-16.00',
        bonus: '10%',
    },
    '340154': {
        category_id: 58039,
        company_id: 340154,
        partner: 'ТОО « МФК "ГИППОКРАТ»',
        company_name: 'ТОО « МФК "ГИППОКРАТ»',
        city: 'Astana',
        section: 'Аптеки',
        period: '10.00-22.00',
        bonus: '10%',
    },
    '328419': {
        category_id: 47443,
        company_id: 328419,
        partner: 'SYILA',
        company_name: 'ИП «Кисибаева»',
        city: 'Almaty',
        section: 'Супермаркеты и продукты',
        period: '10.00-20.00',
        bonus: '10%',
    },
    '336446': {
        category_id: 47910,
        company_id: 336446,
        partner: 'DEP',
        company_name: 'ИП «Алиметова»',
        city: 'Almaty',
        section: 'Супермаркеты и продукты',
        period: '10.00-22.00',
        bonus: '10%',
    },
    '337498': {
        category_id: 53079,
        company_id: 337498,
        partner: 'Family stories',
        company_name: 'ИП «Кульжанова С.Д.»',
        city: 'Atyrau',
        section: 'Супермаркеты и продукты',
        period: '10.00-20.00',
        bonus: '10%',
    },
    '338435': {
        category_id: 55560,
        company_id: 338435,
        partner: 'Мясная лавка №1',
        company_name: 'ИП «Т&М»',
        city: 'Astana',
        section: 'Мясные магазины',
        period: '10.00-18.00',
        bonus: '10%',
    },
    '339998': {
        category_id: 58482,
        company_id: 339998,
        partner: 'Ягоды Карелии',
        company_name: 'ИП «ЗИМА-ЛЕТО»',
        city: 'Almaty',
        section: 'Супермаркеты и продукты',
        period: '10.00-20.00',
        bonus: '10%',
    },
};
